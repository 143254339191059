import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useState,useRef,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation,useNavigate } from "react-router";
import TodoViewArea from "@/pages/mobile/plan/todo/commponent/view";

function TodoViewPage(){
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [todo_info,set_todo_info]=useState(state.todo_info);
  let back_press_url=state.back_press_url?state.back_press_url:"/mobile/home_work";
  let is_go_start=state.is_go_start?true:false;

  let iam_mcomp_seq="";
  let iam_mcomp_user_seq = "";
  let comp_api_key="";
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_seq=user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key=user.select_mcomp_link["comp_api_key"];
  }

  const goBackUrl=()=>{
    let send_data:any={};
    if(state.list_opt){
      send_data["list_opt"]=state.list_opt;
    }
    navigate(back_press_url,{
      state:send_data
    });
  };

  const goWritePage=()=>{
    let send_data={
      "back_press_url":back_press_url,
      "isUpdate":"1",
      "rowData":todo_info,
      "is_comp_todo":false,
    };
    let write_url="/mobile/todo/todo_write";
    
    if(todo_info.a_writer_mcomp_seq==iam_mcomp_seq&&todo_info.a_writer_mcomp_user_seq==iam_mcomp_user_seq){
      write_url="/mobile/todo/todo_write";
      send_data["is_comp_todo"]=true;
    }
    navigate(write_url,
      { state: send_data });
  };

  return (
    <MobileLayout container_style={{background:"linear-gradient(203deg, #C8D376 -5.34%, #ABC118 25.87%, #86A609 61.19%)",marginTop:0}} 
      container_con_style={{width:"100%"}} 
      has_header={false} >
      
      <TodoViewArea
        todo_info={todo_info}
        is_go_start={is_go_start}
        on_go_back={goBackUrl}
        on_go_write_page={goWritePage}
      ></TodoViewArea>

    </MobileLayout>
  );
}

export default TodoViewPage;