import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from "./area/search";
import CalendarTableArea from "./area/calendar";
import AttendListArea from "./area/attend_list";
import DeskUseListArea from "./area/desk_use_list";

function AttendStuCalendarArea(props:any) {
  let myProps={
    stu_seq: "",
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },
    ...props,
  };
  let iam_mcomp_seq = myProps.user_data.iam_mcomp_seq;
  let iam_mcomp_user_seq = myProps.user_data.iam_mcomp_user_seq;
  let comp_api_key = myProps.user_data.comp_api_key;
  let stu_seq=myProps.stu_seq;

  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 100,
    'order_id': 'a_ymd DESC',
    'order_type': '',
    's_date_type': 'a_ymd',
    's_start_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    's_end_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    "is_add_idx_info": "1",
    "s_addon_user": "1",

    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });

  const CalendarTableAreaRef = useRef<any>(null);
  const AttendListAreaRef = useRef<any>(null);
  const [select_date, set_select_date] = useState(DateFunc.get_date_format(new Date(), "Y-m-d"));
  const [infoArr, setInfoArr] = useState([]);
  const [lecture_arr, set_lecture_arr] = useState([]);
  const [desk_use_arr,set_desk_use_arr]=useState([]);
  const [teacher_arr, set_teacher_arr] = useState([]);

  useEffect(() => {
    list({});
    get_lecture_arr_by_ajax({});
    get_teacher_by_ajax();
  }, [stu_seq]);

  const list = (inOptObj: any) => {
    let tmp_list_opt = { ...listOpt, ...inOptObj };
    setListOpt(tmp_list_opt);

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(stu_seq)) {
      return false;
    }
    tmp_list_opt["s_user_seq"] = stu_seq;
    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    let tmp_select_date = select_date;
    let tmp_select_first_date = DateFunc.get_date_format(new Date(tmp_select_date), "Y-m-01");
    let pre_month_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_select_first_date), 'month', -1), "Y-m-01");
    let after_month_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_select_first_date), 'month', +1), "Y-m-t");

    tmp_list_opt["s_start_date"] = pre_month_date_str;
    tmp_list_opt["s_end_date"] = after_month_date_str;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          get_desk_use_by_ajax({
            "s_start_date":tmp_list_opt["s_start_date"],
            "s_end_date":tmp_list_opt["s_end_date"],
          });
        } else {

        }
      });
  };

  const get_desk_use_by_ajax = (inData: any) => {
    let opt_obj={
      s_start_date:"",
      s_end_date:"",
      ...inData
    };

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(stu_seq)) {
      return false;
    }

    let form_json_data:any={
      "s_user_seq":stu_seq,
      "s_date_type":"a_date",
      "s_start_date": opt_obj["s_start_date"],
      "s_end_date": opt_obj["s_end_date"],
      "s_addon_edu_room": "1",
      "order_id":"a_start_h, a_start_m",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/desku/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_desk_use_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const delete_attend_by_ajax = (inData: any) => {
    let opt_obj={
      "attend_info":{},
      "sort_name":"등원",
      ...inData
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(stu_seq)) {
      return false;
    }
    let confirm_str=opt_obj["sort_name"]+"을 취소 하시겠습니까?";
    if(!confirm(confirm_str)){
      return false;
    }
    let form_json_data:any = {
      "data_arr":[opt_obj["attend_info"]],
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_lecture_arr_by_ajax = (inOptObj: any) => {
    let list_form_json_data = {
      "order_id": "a_order_num",
      "s_lecture_seq": "",
      "s_state": "ing",
      "s_teacher_like": "",
      "s_teacher_seq": "",
      "s_room_seq": "",
      "s_name_like": "",
      "s_addon_time": "1",
      "s_addon_user": "1",
      "s_addon_user_time": "1",
      "s_addon_user_s_state": "ing",
      "s_addon_user_s_user_seq": "empty",
      "s_addon_time_union": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...inOptObj
    };
    if(strFunc.is_empty(myProps["stu_seq"])){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if (strFunc.is_empty(stu_seq)) {
      return false;
    }
    list_form_json_data["api_key"]=comp_api_key;
    list_form_json_data["api_user_seq"]=iam_mcomp_user_seq;
    list_form_json_data["s_addon_user_s_user_seq"]=stu_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list',
      list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_by_ajax = () => {
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const onSearchDateChange = (inData: any) => {
    set_select_date(inData);
    if (CalendarTableAreaRef.current) {
      CalendarTableAreaRef.current.setInitData({
        select_date: inData
      });
    }
    list({});
  };

  const onClickTdTag = (inData: any) => {
    set_select_date(inData.select_date_str);
  };

  return (
    <div>
      <div className="mt-2">
        <SearchArea
          select_date={select_date}
          set_select_date={onSearchDateChange}
        ></SearchArea>
      </div>
      <CalendarTableArea
        ref={CalendarTableAreaRef}
        select_date={select_date}
        attend_info_arr={infoArr}
        lecture_arr={lecture_arr}
        desk_use_arr={desk_use_arr}
        onClickTdTag={onClickTdTag}
      ></CalendarTableArea>
      <AttendListArea
        ref={AttendListAreaRef}
        infoArr={infoArr}
        select_date={select_date}
        delete_attend_by_ajax={delete_attend_by_ajax}
      ></AttendListArea>
      <DeskUseListArea
        select_date={select_date}
        desk_use_arr={desk_use_arr}
      ></DeskUseListArea>
    </div>
  );
};
export default AttendStuCalendarArea;