import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import TopTabArea from "./area/top_tab";
import CharListArea from "./area/char_list";
import CharHeader from "@/pcomponents/common/content/char/popup/area/header";
import BackGroundSkyArea from "@/pcomponents/common/content/char/popup/area/back_sky";

function InvenPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const [char_opt, set_char_opt] = useState({
    a_gold: "0",
    a_select_char_seq: "0",
  });
  const [select_tab, set_select_tab] = useState("char_list");
  const [tab_arr, set_tab_arr] = useState([
    { "value": "char_list", "text": "씨앗" },
    { "value": "item_list", "text": "아이템" },
  ]);

  useEffect(() => {
    
    get_char_opt_by_ajax();
  }, [user]);

  const get_char_opt_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }

    let form_json_data = {
      "s_user_seq": user.user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_char_opt(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  return (
    <div style={{ width: "100%", height: "100%", background: "#D3DB90", position: "relative" }} >
      <BackGroundSkyArea></BackGroundSkyArea>
      <CharHeader
        closePopup={myProps.closePopup}
        char_opt={char_opt}
        title={"인벤"}
      ></CharHeader>
      <div style={{padding:10,paddingTop:80}}>
        <TopTabArea
          select_tab={select_tab}
          set_select_tab={set_select_tab}
          tab_arr={tab_arr}
        ></TopTabArea>
        {select_tab == "char_list" &&
          <CharListArea
            char_opt={char_opt}
            refresh_data={() => {
              get_char_opt_by_ajax();
              myProps.callback();
            }}
          ></CharListArea>
        }
      </div>
    </div>
  );
};
export default InvenPopup;