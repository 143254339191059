import strFunc from "@/lib/lyg/string";
import file_ico from "@/img/ico/parent_menu/par_notice.png";
import Style from "./css/style.module.css";
import { useState } from 'react';

function FileSlideArea(props: any) {
  let myProps = {
    file_info_arr: [],
    select_file_idx: 0,
    ...props
  };

  const [file_info_arr, set_file_info_arr] = useState(myProps.file_info_arr);
  const [select_file_idx, set_select_file_idx] = useState(myProps.select_file_idx);

  const on_change_img_size = (inData: any) => {
    let opt_obj = {
      row_idx: 0,
      size_per: 100,
      ...inData
    };
    let row_idx = opt_obj.row_idx;
    let change_file_info_arr = [...file_info_arr];
    if (change_file_info_arr[row_idx]) {
      change_file_info_arr[row_idx].size_per = opt_obj.size_per;
    }
    set_file_info_arr(change_file_info_arr);
  };

  const get_file_list_tags = () => {
    let file_list_tags = file_info_arr.map((item: any, idx: number) => {
      let file_info = item;
      let icon_img = file_ico;
      if (file_info.is_image == "1") {
        icon_img = file_info.img_url;
      }
      let size_per = file_info.size_per;
      if (strFunc.is_empty(size_per)) {
        size_per = 100;
      }
      size_per = parseInt(strFunc.uncomma(size_per));
      let row_img_max_width=100;
      if (size_per > 100) {
        row_img_max_width = size_per;
      }
      let img_style:any = {
        width: size_per + "%",
        maxWidth: row_img_max_width + "%"
      };

      return (
        <div key={idx} className={Style.box_wrap}>
          <div className="text-center">
            <button className="btn btn-dark" onClick={() => {
              let change_size_per = size_per - 50;
              if (change_size_per <= 50) {
                change_size_per = 50;
              }
              on_change_img_size({
                row_idx: idx,
                size_per: change_size_per
              });
            }}>-</button>
            <span className="ml-1">{size_per}%</span>
            <button className="btn btn-dark ml-1" onClick={() => {
              let change_size_per = size_per + 50;
              if (change_size_per >=1000) {
                change_size_per = 1000;
              }
              on_change_img_size({
                row_idx: idx,
                size_per: change_size_per
              });
            }}>+</button>
          </div>
          <div className={Style.img_box_wrap}>
            <img src={icon_img} className={Style.img_box_img} alt={file_info.a_oriname} 
              style={img_style}/>
          </div>
          <div>{file_info.a_oriname}</div>
        </div>
      );
    });

    if (strFunc.is_empty(file_list_tags)) {
      file_list_tags = (
        <div className="text-center" style={{ color: "#999", lineHeight: "30px" }}>
          내용이 없습니다.
        </div>
      );
    }

    return file_list_tags;
  };

  return (
    <div>
      {get_file_list_tags()}
    </div>
  );
};
export default FileSlideArea;