import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputSearch} from '@/pcomponents/common/date_picker/custom_input';

const SearchArea=(props:any)=>{
  let myProps={
    listOpt:{
      'order_id':'a_create_date DESC',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
    },
    list:(inData:any)=>{},
    ...props,
  };

  const onChangeDatePicker=(key:string,date:Date)=>{
    let date_str=DateFunc.get_date_format(date,"Y-m-d");
    myProps.list({
      "now_page":"1",
      [key]:date_str,
    });
  };

  return (
    <div>
      <select className="ml-1" value={myProps.listOpt.order_id} onChange={(e:any)=>{
        myProps.list({"order_id":e.target.value,"now_page":1});
      }}>
        <option value="a_create_date DESC">최신순</option>
        <option value="a_create_date">오래된순</option>
        <option value="a_read_date, a_create_date DESC">읽음뒤로</option>
      </select>
      <div className="ml-1" style={{display:"inline-block","width":90}}>
        <DatePicker 
          wrapperClassName="w-24 pl-1"
          selected={myProps.listOpt.s_start_date!=""?new Date(myProps.listOpt.s_start_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_start_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>
      ~
      <div style={{display:"inline-block","width":90}}>
        <DatePicker 
          wrapperClassName="w-24 pl-1"
          selected={myProps.listOpt.s_end_date!=""?new Date(myProps.listOpt.s_end_date):null} 
          onChange={(date:Date) => {
            onChangeDatePicker("s_end_date",date);
          }}
          locale={ko} 
          dateFormat="yyyy-MM-dd"
          customInput={<CustomInputSearch />}
          renderCustomHeader={customHeaderFunc}
        />
      </div>
    </div>
  );
};
export default SearchArea;