import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get_char_img } from "@/pcomponents/common/content/char/data/char_data";

function ViewCharPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    char_info: {},
    ...props
  };
  let char_info = myProps.char_info;
  let user = useSelector((state: any) => state.user);
  const [char_opt, set_char_opt] = useState({
    a_gold: "0",
    a_select_char_seq: "0",
  });
  const [write_char_row, set_write_char_row] = useState({
    char_name: "",
  });
  const [duplicate_name_data,set_duplicate_name_data]=useState({
    is_able:false,
    color:"green",
    msg:"",
  });

  useEffect(() => {
    
    get_char_opt_by_ajax();
  }, [user]);

  const on_change_write_char_row = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let change_char_row: any = { ...write_char_row };

    if(name=="char_name"){
      value=strFunc.cut_str(value,20,"");
    }
    change_char_row[name] = value;

    set_write_char_row(change_char_row);
  };

  const get_char_opt_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }

    let form_json_data = {
      "s_user_seq": user.user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_char_opt(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  const check_dulplicate_char_name_by_ajax=(inData:any)=>{
    let opt_obj={
      name:"",
      ...inData
    };
    if(strFunc.is_empty(opt_obj["name"])){
      set_duplicate_name_data({
        is_able:false,
        color:"orange",
        msg:"이름 입력 필요.",
      });
      return false;
    }
    let form_json_data = {
      "s_name": opt_obj.name,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_user/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length == 0) {
            set_duplicate_name_data({
              is_able:true,
              color:"green",
              msg:"사용가능.",
            });
          }else{
            set_duplicate_name_data({
              is_able:false,
              color:"red",
              msg:"이미 있는 이름입니다.",
            });
          }
        } else {

        }
      });
  };

  const go_buy_char_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }

    let my_gold = parseInt(strFunc.uncomma(char_opt.a_gold));
    let char_cost = parseInt(strFunc.uncomma(char_info["a_cost"]));
    if (my_gold < char_cost) {
      alert("양분이 부족합니다.");
      return false;
    }

    if(strFunc.is_empty(write_char_row["char_name"])){
      alert("이름입력 필요.");
      return false;
    }

    if(duplicate_name_data.is_able!=true){
      alert("중복된 이름은 등록 할 수 없습니다.");
      return false;
    }

    if (!confirm(char_info["a_name"] + " 씨앗이를 " + strFunc.comma(char_cost) + " 양분으로 구매 하시겠습니까?")) {
      return false;
    }

    let form_json_data = {
      "buy_user_seq": user.user_seq,
      "char_id": char_info["a_id"],
      "char_name": write_char_row["char_name"],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_user/buy_char_one', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("구매 되었습니다.");
          myProps.callback();
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  let img_src = get_char_img({ "id": char_info["a_id"] });

  return (
    <div style={{ width: "100%", height: "100%", background: "#D3DB90", position: "relative" }} >
      <div className="text-center" style={{ height: "40px", lineHeight: "40px", background: "#E4EFE9" }} >
        <span style={{ color: "#7A9E1D" }}>씨앗이 선택</span>
        <button className="btn btn-gray" onClick={myProps.closePopup}
          style={{ position: "absolute", right: 5, top: 5, background: "#7A9E1D" }}
        >X</button>
      </div>
      <div className="text-center" style={{ padding: 20 }}>
        <div>
          내양분:
          <span className="mx-1" style={{ color: "blue" }}>
            {strFunc.comma(char_opt.a_gold)}
          </span>
          점
        </div>
        <div>
          <img src={img_src} style={{ height: "200px" }} />
        </div>
        <div className="mt-2" style={{ fontSize: "20px", fontWeight: 600 }}>
          {char_info["a_name"]} 씨앗이
        </div>
        <div className="mt-2" style={{ color: "green" }}>
          {char_info["a_sub_name"]}
        </div>
        <div className="mt-2" style={{ fontSize: "18px", color: "#000" }}>
          가격:
          <span className="mx-1" style={{ color: "red" }}>
            {strFunc.comma(char_info["a_cost"])}
          </span>
          점
        </div>
        <div className="mt-2">
          <input type="text" value={write_char_row.char_name} name="char_name"
            onChange={(e:any)=>{
              on_change_write_char_row(e);
              let value=strFunc.cut_str(e.target.value,20,"");
              check_dulplicate_char_name_by_ajax({
                name:value
              });
            }}
            style={{ width: "100px", textAlign: "center" }}
            placeholder="씨앗이 이름" />
          {!strFunc.is_empty(duplicate_name_data.msg)&&
            <p style={{color:duplicate_name_data.color,fontSize:"16px"}}>
              {duplicate_name_data.msg}
            </p>
          }
        </div>
        <div className="mt-2" >
          <button className="btn btn-dark" onClick={() => { go_buy_char_by_ajax(); }}>구매</button>
          <button className="btn btn-gray ml-2" onClick={myProps.closePopup}>닫기</button>
        </div>
      </div>
    </div>
  );
};
export default ViewCharPopup;