import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';

function CharNameArea(props: any) {
  let myProps = {
    char_user_info: {},
    char_opt: {},
    user:{},
    refresh_data: (inData: any) => { },
    ...props
  };
  let char_user_info = myProps.char_user_info;
  let char_opt=myProps.char_opt;//a_gold
  let user=myProps.user;
  const [is_show_change_name, set_is_show_change_name] = useState(false);
  const [change_name_data, set_change_name_data] = useState({
    a_name: char_user_info["a_name"],
    is_able: false,
    check_msg: "",
    check_color: "red",
  });

  const on_change_name_input = (inData: any) => {
    let opt_obj = {
      name: "",
      ...inData
    };
    let tmp_change_name_data = { ...change_name_data };
    tmp_change_name_data["a_name"] = opt_obj["name"];

    if (strFunc.is_empty(opt_obj.name)) {
      tmp_change_name_data["is_able"] = false;
      tmp_change_name_data["check_msg"] = "이름을 입력해주세요.";
      tmp_change_name_data["check_color"] = "red";
      set_change_name_data(tmp_change_name_data);
      return false;
    }

    check_char_name_duplicate_by_ajax(opt_obj);
  };

  const check_char_name_duplicate_by_ajax = (inData:any) => {
    let opt_obj={
      name:"",
      ...inData
    };
    if(opt_obj["name"]==""){
      return false;
    }
    if(strFunc.is_empty(char_user_info["a_seq"])){
      return false;
    }

    let tmp_change_name_data = { ...change_name_data };
    tmp_change_name_data["a_name"] = opt_obj["name"];

    let form_json_data = {
      "s_name": opt_obj["name"],
      "s_except_seq": char_user_info["a_seq"],
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_user/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            set_change_name_data({
              ...tmp_change_name_data,
              is_able: false,
              check_msg: "이미 사용중인 이름입니다.",
              check_color: "red",
            });
          }else{
            set_change_name_data({
              ...tmp_change_name_data,
              is_able: true,
              check_msg: "사용가능.",
              check_color: "blue",
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const use_gold_for_change_name_by_ajax = (inData:any) => {
    if(!change_name_data.is_able){
      return false;
    }
    if(strFunc.is_empty(char_user_info["a_seq"])){
      return false;
    }

    let now_gold=parseInt(strFunc.uncomma(char_opt["a_gold"]));
    if(now_gold<100){
      alert("양분이 부족합니다. (100양분 필요.)");
      return false;
    }
    if(!confirm("이름을 변경하시겠습니까?")){
      return false;
    }

    let form_json_data = {
      "row_data": {
        a_main_user_seq: user.user_seq,
        a_user_name: user.user_name,
        a_in_out: "out",
        a_msg: "이름변경",
        a_par_mcomp_seq: user.comp_seq,
        a_par_id: "char_name",
        a_par_seq: "",
        a_amount: 100,
      },
      "is_able_duplicate": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/add_gold_one', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          change_name_by_ajax();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const change_name_by_ajax = () => {
    if(!change_name_data.is_able){
      return false;
    }
    if(strFunc.is_empty(char_user_info["a_seq"])){
      return false;
    }
    
    let change_char_row_data={
      "a_seq": char_user_info["a_seq"],
      "a_name": change_name_data.a_name,
    };
    let form_json_data = {
      data_arr:[change_char_row_data],
      is_update: "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_user/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_is_show_change_name(false);
          myProps.refresh_data({ a_name: change_name_data.a_name });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div className="mt-2" style={{ fontSize: "20px", fontWeight: 600, position: "relative" }}>
      {is_show_change_name == false &&
        <span>
          {change_name_data.a_name}

          <button className="btn-s btn-gray ml-2" onClick={() => { set_is_show_change_name(true); }}
            style={{ position: "absolute", right: 5, background: "green" }}>이름변경</button>
        </span>
      }
      {is_show_change_name &&
        <div>
          <input type="text" style={{ width: 100 }} value={change_name_data.a_name}
            onChange={(e: any) => {
              on_change_name_input({ name: e.target.value });
            }} />
          <button className="btn btn-dark ml-1" onClick={use_gold_for_change_name_by_ajax}>변경</button>
          <button className="btn btn-gray ml-1" onClick={() => { set_is_show_change_name(false); }}
            style={{ background: "green" }} >취소</button>

          {change_name_data.check_msg &&
            <div style={{ fontSize: 13, marginTop: 5, color: change_name_data.check_color }}>
              {change_name_data.check_msg}
            </div>
          }
          <div>
            <span style={{color:"#555",fontSize:15}}>이름 변경비용: <span className="mr-1" style={{color:"red"}}>100</span>양분</span>
          </div>
        </div>
      }
    </div>
  );
};
export default CharNameArea;