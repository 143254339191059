import strFunc from "@/lib/lyg/string";

function BottomContentArea(props:any){
  let myProps={
    Style:{},
    todo_info:{},
    ...props
  };
  let Style=myProps.Style;
  let todo_info=myProps.todo_info;

  const get_par_con_tag=()=>{
    let par_con_tag:any="";
    if(todo_info["a_par_id"]=="study_list"&&!strFunc.is_empty(todo_info["a_par_seq"])){
      if(todo_info.study_info){
        let study_info=todo_info.study_info;
        let study_title_str="";
        if(study_info.title_info){
          study_title_str=study_info.title_info["a_title"];
          if(study_info.section_info){
            study_title_str+=" > "+study_info.section_info["a_title"];
          }
        }
        par_con_tag=(
          <div>
            <p style={{fontWeight:"bold"}}>[교재] {study_title_str}</p>
            <div>
              {study_info["a_title"]}
            </div>
            <div>
              {
                study_info.a_content && study_info.a_content.split('\n').map((line: string, idx: number) => {
                  return (<span key={idx}>{line}<br /></span>)
                })
              }
            </div>
          </div>
        );
      }
    }

    return par_con_tag;
  };
  
  return (
    <div className={Style.bottom_wrap+" noto_sans"}>
      <span className={Style.bottom_right_subject}>
        {myProps.todo_info.a_subject==""?"기타":myProps.todo_info.a_subject}
      </span>
      <div className={Style.bottom_title}>
        {myProps.todo_info.a_title}
      </div>
      <div style={{lineHeight:"23px"}}>
        {
          myProps.todo_info.a_content && myProps.todo_info.a_content.split('\n').map((line: string, idx: number) => {
            return (<span key={idx}>{line}<br /></span>)
          })
        }
      </div>
      <div>
        {get_par_con_tag()}
      </div>
      <div className={Style.bottom_con_table}>
        <table>
          <colgroup>
            <col width={"30%"}></col>
            <col width={"70%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>등록자</th>
              <td>
                {myProps.todo_info.main_comp_title?myProps.todo_info.main_comp_title:""}
              </td>
            </tr>
            <tr>
              <th>예정일</th>
              <td>
                { myProps.todo_info.a_reserve_start_date.substring(0,10) }
              </td>
            </tr>
            <tr>
              <th>시작</th>
              <td>
                { myProps.todo_info.a_start_date }
              </td>
            </tr>
            <tr>
              <th>종료</th>
              <td>
                { myProps.todo_info.a_end_date }
              </td>
            </tr>
            <tr>
              <th>사진필요</th>
              <td>
                { (myProps.todo_info.a_is_need_photo=="1")?
                <span style={{color:"blue"}}>예</span>
                :"아니오" }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default BottomContentArea;