import { useNavigate } from 'react-router-dom';

function TodoTodayArea(props: any) {
  let myProps = {
    Style: {},
    comp_main_data: {
      today_todo_stat: null,
      today_eval_stat: null,
      today_plantalk_stat: null,
      today_counsel_stat: null,
      month_prescribe_stat: null,
    },
    ...props
  };
  let Style = myProps.Style;
  const navigate = useNavigate();

  let default_today_todo_stat = {
    a_reserve_start_date: "2023-12-11",
    homework_cnt: 0,
    row_tot: 0,
    study_cnt: 0,
    success_cnt: 0,
    video_cnt: 0,
  };
  let today_todo_stat = {
    ...default_today_todo_stat,
    ...myProps.comp_main_data.today_todo_stat
  };
  let todo_percent = 0;
  if (today_todo_stat.success_cnt != 0 && today_todo_stat.row_tot != 0) {
    todo_percent = Math.floor((today_todo_stat.success_cnt / today_todo_stat.row_tot) * 100);
  }

  let default_today_eval_stat = {
    row_tot: 0
  };
  let today_eval_stat = {
    ...default_today_eval_stat,
    ...myProps.comp_main_data.today_eval_stat
  };

  let default_today_plantalk_stat = {
    row_tot: 0
  };
  let today_plantalk_stat = {
    ...default_today_plantalk_stat,
    ...myProps.comp_main_data.today_plantalk_stat
  };

  let default_today_counsel_stat = {
    row_tot: 0,
    success_cnt: 0
  };
  let today_counsel_stat = {
    ...default_today_counsel_stat,
    ...myProps.comp_main_data.today_counsel_stat
  };
  let month_prescribe_stat={
    row_tot: 0,
    success_cnt: 0,
    ...myProps.comp_main_data.month_prescribe_stat
  };
  let counsel_tot_str="0";
  if(today_counsel_stat.row_tot != 0){
    counsel_tot_str=today_counsel_stat.success_cnt+"/"+today_counsel_stat.row_tot;
  }

  return (
    <div className={Style.todo_today_wrap}>
      <div className={Style.todo_today_con}>
        <div>
          오늘의 과제 달성률 ({today_todo_stat.success_cnt+"/"+today_todo_stat.row_tot})
        </div>
        <div style={{ fontSize: "18px", fontWeight: 500 }} onClick={()=>{navigate("/mobile/manage/plan/list");}}>
          <span style={{ color: "#3FAC96", marginRight: 5 }}>{today_todo_stat.success_cnt}명이</span>
          완료했어요!
        </div>
        <div>
          <table style={{ width: "100%" }}>
            <colgroup>
              <col width={"*"}></col>
              <col width={"40px"}></col>
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <div className={Style.todo_today_progress_bar_wrap}>
                    <div className={Style.todo_today_progress_bar} style={{ width: todo_percent + "%" }} ></div>
                  </div>
                </td>
                <td style={{ textAlign: "right" }}>
                  <span style={{ color: "#3FAC96" }}>{todo_percent}%</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={Style.todo_today_bottom}>
          <div className={Style.tot_div} onClick={()=>{navigate("/mobile/manage/month_prescribe/card_list");}} >
            {month_prescribe_stat.success_cnt}/{month_prescribe_stat.row_tot}개
            <div className={Style.sub_text}>학습처방</div>
          </div>
          <div className={Style.tot_div} onClick={()=>{navigate("/mobile/chat/room_list");}} >
            {today_plantalk_stat.row_tot}개
            <div className={Style.sub_text}>플랜톡</div>
          </div>
          <div className={Style.tot_div} onClick={()=>{navigate("/mobile/manage/counsel_todo/list");}} >
            {counsel_tot_str}건
            <div className={Style.sub_text}>상담완료/예정</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TodoTodayArea;