import Style from "./css/style.module.css";
import CardBox from "./card_box";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import DateFunc from '@/lib/lyg/date_func';

function CardListArea(props:any){
   let myProps={
    info_arr:[],
    open_counsel_view_popup:(inData:any)=>{},
    ...props
  };
  let info_arr=myProps.info_arr;
  let select_stu_grade_arr=XcolumnFunc.getSortByTableKey({'table':'user','key':'a_stu_grade'});
  const now_date_json = DateFunc.get_date_json(new Date());
  let now_date_time = new Date(now_date_json.Y + "-" + now_date_json.m + "-" + now_date_json.d).getTime();
  let select_arr_eval_score = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];
  
  const get_cardbox_tags=()=>{
    let cardbox_tags=info_arr.map((item:any,idx:number)=>{
      return (
        <CardBox
          key={idx}
          idx={idx}
          info={item}
          now_date_time={now_date_time}
          Style={Style}
          select_stu_grade_arr={select_stu_grade_arr}
          select_arr_eval_score={select_arr_eval_score}
          open_counsel_view_popup={myProps.open_counsel_view_popup}
        ></CardBox>
      );
    });
    return cardbox_tags;
  };

  return (
  <div className={Style.list_wrap}>
    {get_cardbox_tags()}
  </div>
  );
};
export default CardListArea;