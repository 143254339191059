import NoticeBoxArea from "./notice_box";
import Style from "./css/style.module.css";

function ListArea(props:any){
   let myProps={
    infoArr: [],
    countInfo: { "tot": 0 },
    listOpt: {},
    list: (inData:any)=>{},
    openViewPopup: (inData:any)=>{},
    ...props
  };

  const get_notice_box_tags=()=>{
    let notice_box_tags:any=myProps.infoArr.map((item:any,idx:number)=>{
      return (
        <NoticeBoxArea
          key={idx}
          info={item}
          Style={Style}
          openViewPopup={myProps.openViewPopup}
        ></NoticeBoxArea>
      );
    });

    if(myProps.infoArr.length===0){
      let empty_div_style={lineHeight:"40px",color:"#777",border:"1px solid #ddd",borderRadius:"5px"};
      notice_box_tags=(
        <div className="text-center" style={empty_div_style}>
          <div>내용이 없습니다.</div>
        </div>
      );
    }

    return notice_box_tags;
  };
  
  return (
  <div className={Style.notice_list+" noto_sans"}>
    {get_notice_box_tags()}
  </div>
  );
};
export default ListArea;