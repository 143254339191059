import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import Style from "./css/style.module.css";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useState,useRef,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';
import MobileScript from "@/lib/mobile/web_script";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import TitleLogoArea from "./area/title_logo";

function LoginMain(){
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginData,setLoginData]=useState({
    comp_id:"",
    user_id:"",
    user_pw:"",
  });
  const [isCompLogin,setIsCompLogin]=useState(true);

  useEffect(()=>{
    
    localStorage.removeItem("tmp_comp_user_info");
    localStorage.removeItem("tmp_comp_id");
    setPreID();
  },[]);

  const setPreID=()=>{
    if(isCompLogin==false){
      return false;
    }
    if(localStorage.login_comp_id){
      let tmp_login_comp_id=strFunc.base64Decode(localStorage.login_comp_id);
      if(isCompLogin){
        tmp_login_comp_id="";
      }
      setLoginData({
        ...loginData,
        comp_id:tmp_login_comp_id,
        user_id:strFunc.base64Decode(localStorage.login_id),
      });
    }
  };

  const setCompLoginDisplay=()=>{
    if(isCompLogin){
      setLoginData({
        ...loginData,
        ...{comp_id:"main_user"}
      });
    }else{
      setLoginData({
        ...loginData,
        ...{comp_id:""}
      });
    }
    setIsCompLogin(!isCompLogin);
  };

  const onChangInput=(e:any)=>{
    setLoginData({
      ...loginData,
      ...{[e.target.name]:e.target.value}
    });
  };

  const goLogin=()=>{
    if(loginData.comp_id==""){
      alert("학원코드 입력 필요.");
      return false;
    }
    if(loginData.user_id==""){
      alert("아이디 입력 필요.");
      return false;
    }
    if(loginData.user_pw==""){
      alert("비밀번호 입력 필요.");
      return false;
    }

    let login_form_data={
      comp_id:loginData.comp_id,
      id:loginData.user_id,
      pw:loginData.user_pw
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/basic/user/login',login_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.setItem('login_comp_id', strFunc.base64Encode(loginData.comp_id));
        localStorage.setItem('login_id', strFunc.base64Encode(loginData.user_id));
        if(response.data.data["token_id"]){
          localStorage.setItem('intoyou_token', response.data.data["token_id"]);
          localStorage.login_token_create_date=DateFunc.get_date_format(new Date(),"Y-m-d h");
          MobileScript.set_login_token(response.data.data["token_id"]);
          var client_info=response.data.data["client_info"];
          dispatch(setUser(client_info));
          localStorage.sort_data_arr=JSON.stringify(client_info.sort_data);
          setTimeout(()=>{
            navigate("/mobile");
          },200);
        }else if(response.data.data["comp_user_info"]){
          localStorage.tmp_comp_user_info=JSON.stringify(response.data.data["comp_user_info"]);
          localStorage.tmp_comp_id=loginData.comp_id;
          navigate("/mobile/join");
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <MobileLayout has_header={false} has_footer={false} isGoToMainAtLogin={true}>
      <div className={Style.login_wrap}>
        <div className={Style.login_con+" noto_sans"}>
          <TitleLogoArea></TitleLogoArea>
          {isCompLogin&&
          <div>
            <div className={Style.login_input_title}>
              학원코드(code)
            </div>
            <div className={Style.login_input_wrap}>
              <input type="text" placeholder="학원코드" autoComplete="off" 
                value={loginData.comp_id} name="comp_id" onChange={onChangInput} />
            </div>
          </div>
          }
          <div className={Style.login_input_title}>아이디(ID)</div>
          <div className={Style.login_input_wrap}>
            <input type="text" placeholder="아이디" autoComplete="off" 
              value={loginData.user_id} name="user_id" onChange={onChangInput} />
          </div>
          <div className={Style.login_input_title}>비밀번호(PW)</div>
          <div className={Style.login_input_wrap}>
            <input type="password" placeholder="비밀번호" autoComplete="off" 
              value={loginData.user_pw} name="user_pw" onChange={onChangInput}
              onKeyUp={(e)=>{if(e.key === 'Enter'){goLogin();}}} />
          </div>
          <div>
            <button className={Style.login_btn} onClick={()=>{goLogin();}} >로그인</button>
          </div>
          <div className={Style.login_bottom_btn_wrap}>
            <div className={Style.login_bottom_btn} onClick={()=>{setCompLoginDisplay();}}>
              {isCompLogin==true?
              <>
                앱로그인
              </>
              :
              <>
                학원로그인
              </>
              }
            </div>
            <div className={Style.login_bottom_btn} onClick={()=>{navigate("/mobile/invite");}}>
              초대코드입력
            </div>
          </div>
          <div className={Style.login_bottom_btn} onClick={()=>{navigate("/mobile/join");}} style={{marginTop:5}}>
            회원가입
          </div>
        </div>
      </div>
    </MobileLayout>
  );
}

export default LoginMain;