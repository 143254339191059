import StuFooter from "./stu_footer";
import FooterManage from "./manage_footer";

const Footer=(props:any)=>{
  let myProps={
    is_home_body:false,
    user:{},
    ...props
  };
  let user=myProps.user;
  let user_grade="student";
  if(user.select_mcomp_link&&user.select_mcomp_link["a_user_grade"]){
    user_grade=user.select_mcomp_link["a_user_grade"];
  }

  const get_footer_tag=()=>{
    let footer_tag:any="";
    if(user_grade=="student"){
      footer_tag=(
        <StuFooter
        is_home_body={myProps.is_home_body}
        user={myProps.user}
      ></StuFooter>
      );
    }else if(user_grade=="master"||user_grade=="teacher"){
      footer_tag=(
        <FooterManage
          is_home_body={myProps.is_home_body}
          user={myProps.user}
        ></FooterManage>
      );
    }else{
      footer_tag=(
        <StuFooter
        is_home_body={myProps.is_home_body}
        user={myProps.user}
      ></StuFooter>
      );
    }
    return footer_tag;
  };
  
  return (
    <>
      {get_footer_tag()}
    </>
  );
};

export default Footer;