import strFunc from '@/lib/lyg/string';
import { useState,useRef,useEffect } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import ParViewArea from "./par_view";

function ViewPopupContent(props:any) {
  let myProps={
    rowData:{
      
    },
    list_refresh:()=>{},
    closePopup:()=>{},
    ...props,
  };
  
  let other_user_name="";
  if(myProps.rowData.a_is_receive){
    if(myProps.rowData.sender_info){
      other_user_name=myProps.rowData.sender_info["a_user_name"];
    }
  }else{
    if(myProps.rowData.receiver_info){
      other_user_name=myProps.rowData.receiver_info["a_user_name"];
    }
  }

  useEffect(()=>{
    if(myProps.rowData.a_seq&&myProps.rowData.a_is_read!="1"){
      checkUnReadPush();
    }
  },[]);

  const checkUnReadPush=()=>{
    let push_pri_val=myProps.rowData.a_ymd+","+myProps.rowData.a_seq;
    let unread_push_form_data={
      "push_seq_arr":[push_pri_val]
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/main/basic/push/check_unread',unread_push_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list_refresh();
      }else{
        
      }
    });
  };
  
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>구분</th>
              <td>
                {myProps.rowData.a_is_receive=="1"?"받음":"보냄"}
              </td>
              <th>상대방</th>
              <td>
                {other_user_name}
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3} >
                {myProps.rowData.a_title}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {
                  myProps.rowData.a_content && myProps.rowData.a_content.split('\n').map( (line:string,idx:number) => {
                    return (<span key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <ParViewArea
          push_info={myProps.rowData}
          closePopup={myProps.closePopup}
        ></ParViewArea>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{myProps.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
