import strFunc from "@/lib/lyg/string";
import { useEffect } from 'react';

function SoundArea(props: any) {
  let myProps = {
    sound_ref: null,
    ...props
  };
  let sound_ref = myProps.sound_ref

  let sound_data = {
    bgm_play: strFunc.get_storage("playgound_bgm_play", "1") == "1" ? true : false,
    bgm_volume: strFunc.get_storage("playgound_bgm_volume", "0.5"),
    effect_play: strFunc.get_storage("playgound_effect_play", "1") == "1" ? true : false,
    effect_volume: strFunc.get_storage("playgound_effect_volume", "1"),
  };
  const effect_sound_src_json: any = {
    gbm: process.env.REACT_APP_API_URL + "/sound/mobile/game/math_sound/school_hero2.mp3",
    success: process.env.REACT_APP_API_URL + "/sound/mobile/game/math_sound/success.mp3",
    fail: process.env.REACT_APP_API_URL + "/sound/mobile/game/math_sound/fail.mp3",
    error: process.env.REACT_APP_API_URL + "/sound/mobile/game/math_sound/error.mp3",
  };

  useEffect(() => {
    if (sound_ref.current.bgm) {
      sound_ref.current.bgm.volume = sound_data.bgm_volume;
      if (sound_data.bgm_play) {
        sound_ref.current.bgm.play();
      }
    }
    if(sound_data.effect_play==false){
      if(sound_ref.current.success){
        sound_ref.current.success.volume = 0;
        sound_ref.current.fail.volume = 0;
        sound_ref.current.error.volume = 0;
      }
    }else{
      if (sound_ref.current.success) {
        sound_ref.current.success.volume = sound_data.effect_volume;
      }
      if (sound_ref.current.fail) {
        sound_ref.current.fail.volume = sound_data.effect_volume;
      }
      if (sound_ref.current.error) {
        sound_ref.current.error.volume = sound_data.effect_volume;
      }
    }
  }, []);

  return (
    <div style={{ display: "none" }}>
      <audio controls loop ref={(el) => { sound_ref.current.bgm = el; }}>
        <source src={effect_sound_src_json.gbm} />
      </audio>
      <audio controls ref={(el) => { sound_ref.current.success = el; }}>
        <source src={effect_sound_src_json.success} />
      </audio>
      <audio controls ref={(el) => { sound_ref.current.error = el; }}>
        <source src={effect_sound_src_json.error} />
      </audio>
      <audio controls ref={(el) => { sound_ref.current.fail = el; }}>
        <source src={effect_sound_src_json.fail} />
      </audio>
    </div>
  );
};
export default SoundArea;