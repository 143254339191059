import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import { useState, useRef, useEffect } from 'react';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import Style from "./css/style.module.css";
import StuListArea from './area/stu_area';

function AddGoldStuPopup(props:any){
   let myProps={
    main_stu_seq_arr:[],
    closePopup: () => { },
    refresh_data: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data=UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq:myProps.select_mcomp_seq
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  const [main_stu_info_arr,set_main_stu_info_arr]=useState([]);
  const [add_gold_num,set_add_gold_num]=useState({
    sort:"5",
    value:"5",
  });
  const select_gold_opt_arr=[
    {value:5,text:"5양분"},
    {value:10,text:"10양분"},
    {value:20,text:"20양분"},
    {value:"custom",text:"직접입력"},
  ];

  useEffect(()=>{
    get_main_stu_info_arr_by_ajax();
  },[]);

  const get_main_stu_info_arr_by_ajax=()=>{
    if(myProps.main_stu_seq_arr.length==0){
      return false;
    }
    let from_json_data={
      "s_pri_arr":myProps.main_stu_seq_arr,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_main_stu_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const add_gold_to_stu_by_ajax=()=>{
    if(add_gold_num.value==""){
      alert("양분을 입력해주세요.");
      return false;
    }
    if(main_stu_info_arr.length==0){
      alert("선택된 학생이 없습니다.");
      return false;
    }
    let row_data_arr=[];
    for(let i=0;i<main_stu_info_arr.length;i++){
      let main_stu_info:any=main_stu_info_arr[i];
      row_data_arr.push({
        a_main_user_seq: main_stu_info.a_seq,
        a_user_name: main_stu_info.a_user_name,
        a_in_out: "in",
        a_msg: "양분주기",
        a_par_mcomp_seq: user.comp_seq,
        a_par_id: "teacher_water",
        a_par_seq: user.user_seq,
        a_amount: add_gold_num.value,
      });
    }
    let form_json_data = {
      "row_data_arr": row_data_arr,
      "is_able_duplicate": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/add_gold_multy', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("양분을 "+add_gold_num.value+" 주었습니다.");
          myProps.refresh_data();
          myProps.closePopup();
        } else {

        }
      }
    );
  };

  return (
  <div>
    <div>
      학생 양분 주기
    </div>
    <div>
      <StuListArea
        main_stu_info_arr={main_stu_info_arr}
        set_main_stu_info_arr={set_main_stu_info_arr}
        Style={Style}
      ></StuListArea>
    </div>
    <div>
      <div>양분</div>
      <div>
        <SelectBoxBar
          valueTextArr={select_gold_opt_arr}
          value={add_gold_num.sort}
          name="add_gold_num"
          on_change={(inData:any)=>{
            let value=inData.value;
            if(inData.value=="custom"){
              value=add_gold_num.value;
            }
            set_add_gold_num({
              sort:inData.value,
              value:value,
            });
          }}
        ></SelectBoxBar>
      </div>
      <div className="mt-1 text-center">
        {add_gold_num.sort=="custom"&&
          <input type="text" value={add_gold_num.value} onChange={(e)=>{
            let value=strFunc.uncomma(e.target.value);
            set_add_gold_num({
              sort:"custom",
              value:value,
            });
          }} 
          onClick={(e:any)=>{
            e.target.select();
          }}
          style={{width:50,textAlign:"center"}}/>
        }
      </div>
    </div>
    <div>
      <div className="text-center mt-2">
        <button className="btn btn-blue" onClick={() => { add_gold_to_stu_by_ajax(); }}>점수주기</button>
        <button className="btn btn-dark ml-2" onClick={() => { myProps.closePopup(); }}>취소</button>
      </div>
    </div>
  </div>
  );
};
export default AddGoldStuPopup;