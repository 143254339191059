import { useRef } from 'react';

function SearchArea(props:any){
   let myProps={
    listOpt:{},
    setListOpt:(inData:any)=>{},
    list:(inData:any)=>{},
    Style:{},
    ...props
  };
  let listOpt=myProps.listOpt;
  let Style=myProps.Style;

  const search_time_out_ref=useRef<any>(null);

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const onSearchChange=(inData:any)=>{
    let opt_obj={
      name:"",
      value:"",
      ...inData
    };
    let name=opt_obj.name;
    let value=opt_obj.value;
    let search_json_data:any={};
    search_json_data[name]=value;
    
    myProps.setListOpt({ ...myProps.listOpt, ...search_json_data });
    go_search_list({ now_page: 1, ...search_json_data });
  };
  
  return (
  <div className={Style.search_wrap}>
    <input type="text" className={Style.search_stu_name_input} value={listOpt.s_user_name_like} name="s_user_name_like"
      onChange={(e:any)=>{
        onSearchChange({
          name:e.target.name,
          value:e.target.value
        });
      }} />
    <button className={Style.search_wrap_search_btn} 
      onClick={()=>{myProps.list({"now_page":"1"});}}>검색</button>
  </div>
  );
};
export default SearchArea;