import MobileDefault from "@/pages/mobile/main/main";
import MainCharPage from "@/pages/mobile/main/main_char";
import IntroMain from "@/pages/mobile/main/intro";
import TestMain from "@/pages/mobile/test";
import LoginMain from "@/pages/mobile/login/main";
import JoinMain from "@/pages/mobile/login/join";
import InviteMain from "@/pages/mobile/login/invite_code";
import LoginIntro from "@/pages/mobile/login/intro";
import LoginByUrl from "@/pages/mobile/login/login_by_url";
import ChatMain from "@/pages/mobile/chat/main";
import ChatRoomList from "@/pages/mobile/chat/room_list";
import ChatView from "@/pages/mobile/chat/chat_view";
import ChatRoomWrite from "@/pages/mobile/chat/room_write";
import HomeWorkMain from "@/pages/mobile/plan/home_work";
import HomeWorkFile from "@/pages/mobile/plan/home_work_file";
import MyPlanPage from "@/pages/mobile/plan/my_plan";
import TodoViewPage from "@/pages/mobile/plan/todo/todo_view";
import TodoViewVideoPage from "@/pages/mobile/plan/todo/todo_view_video";
import TodoWritePage from "@/pages/mobile/plan/todo/todo_write";
import PlanMain from "@/pages/mobile/plan/main";
import ScheMain from "@/pages/mobile/sche/main";
import NoticeMain from "@/pages/mobile/notice/main";
import SettingMain from "@/pages/mobile/setting/main";
import MyPage from "@/pages/mobile/setting/mypage";
import MyPageComp from "@/pages/mobile/setting/mypage_comp";
import LeaveUserPage from "@/pages/mobile/setting/leave_user";
import PushList from "@/pages/mobile/push/list";
import AttendMainPage from "@/pages/mobile/edu/attend/main";
import AppDownPage from "@/pages/mobile/setting/app_down";

import VideoMyList from "@/pages/mobile/plan/video/my_list";
import VideoMyListView from "@/pages/mobile/plan/video/my_list_view";
import VideoCorseList from "@/pages/mobile/plan/video/corse_list";

import ManagePlanListPage from "@/pages/mobile/manage/plan/list";
import ManageEvalListPage from "@/pages/mobile/manage/eval/list";
import ManageCounselTodoListPage from "@/pages/mobile/manage/counsel_todo/list";
import ManageCounselWritePage from "@/pages/mobile/manage/counsel_todo/write";
import DailyReportCardListPage from "@/pages/mobile/manage/edu/daily_report/card_list";
import StudyPrescribeCardListPage from "@/pages/mobile/manage/edu/study_prescribe/card_list";
import StudyDiaryCardListPage from "@/pages/mobile/manage/edu/study_diary/card_list";
import MonthPrescribeCardListPage from "@/pages/mobile/manage/edu/month_prescribe/card_list";

import stuRouterArr from "./stu";
import ParNoticeListPage from "./parent";
import manageRouterArr from "./manage";

const routerArr:object[] = [
  {
    path: "/mobile",
    element: <MobileDefault></MobileDefault>,
  },
  {
    path: "/mobile/main/char",
    element: <MainCharPage></MainCharPage>,
  },
  {
    path: "/mobile/main/intro",
    element: <IntroMain></IntroMain>,
  },
  {
    path: "/mobile/test",
    element: <TestMain></TestMain>,
  },
  {
    path: "/mobile/login",
    element: <LoginMain></LoginMain>,
  },
  {
    path: "/mobile/join",
    element: <JoinMain></JoinMain>,
  },
  {
    path: "/mobile/invite",
    element: <InviteMain></InviteMain>,
  },
  {
    path: "/mobile/intro",
    element: <LoginIntro></LoginIntro>,
  },
  {
    path: "/mobile/login_by_url",
    element: <LoginByUrl></LoginByUrl>,
  },
  {
    path: "/mobile/chat",
    element: <ChatMain></ChatMain>,
  },
  {
    path: "/mobile/chat/room_list",
    element: <ChatRoomList></ChatRoomList>,
  },
  {
    path: "/mobile/chat/chat_view",
    element: <ChatView></ChatView>,
  },
  {
    path: "/mobile/chat/chat_room_write",
    element: <ChatRoomWrite></ChatRoomWrite>,
  },
  {
    path: "/mobile/home_work",
    element: <HomeWorkMain></HomeWorkMain>,
  },
  {
    path: "/mobile/home_work_file",
    element: <HomeWorkFile></HomeWorkFile>,
  },
  {
    path: "/mobile/my_plan",
    element: <MyPlanPage></MyPlanPage>,
  },
  {
    path: "/mobile/todo/todo_view",
    element: <TodoViewPage></TodoViewPage>,
  },
  {
    path: "/mobile/todo/todo_view_video",
    element: <TodoViewVideoPage></TodoViewVideoPage>,
  },
  {
    path: "/mobile/todo/todo_write",
    element: <TodoWritePage></TodoWritePage>,
  },
  {
    path: "/mobile/plan",
    element: <PlanMain></PlanMain>,
  },
  {
    path: "/mobile/sche",
    element: <ScheMain></ScheMain>,
  },
  {
    path: "/mobile/notice",
    element: <NoticeMain></NoticeMain>,
  },
  {
    path: "/mobile/setting",
    element: <SettingMain></SettingMain>,
  },
  {
    path: "/mobile/setting/mypage",
    element: <MyPage></MyPage>,
  },
  {
    path: "/mobile/setting/mypage_comp",
    element: <MyPageComp></MyPageComp>,
  },
  {
    path: "/mobile/setting/leave_user",
    element: <LeaveUserPage></LeaveUserPage>,
  },
  {
    path: "/mobile/setting/app_down",
    element: <AppDownPage></AppDownPage>,
  },
  {
    path: "/mobile/push/list",
    element: <PushList></PushList>,
  },
  {
    path: "/mobile/edu/attend/main",
    element: <AttendMainPage></AttendMainPage>,
  },
  {
    path: "/mobile/plan/video/my_list",
    element: <VideoMyList></VideoMyList>,
  },
  {
    path: "/mobile/plan/video/my_list_view",
    element: <VideoMyListView></VideoMyListView>,
  },
  {
    path: "/mobile/plan/video/corse_list",
    element: <VideoCorseList></VideoCorseList>,
  },
  {
    path: "/mobile/manage/plan/list",
    element: <ManagePlanListPage></ManagePlanListPage>,
  },
  {
    path: "/mobile/manage/eval/list",
    element: <ManageEvalListPage></ManageEvalListPage>,
  },
  {
    path: "/mobile/manage/counsel_todo/list",
    element: <ManageCounselTodoListPage></ManageCounselTodoListPage>,
  },
  {
    path: "/mobile/manage/counsel_todo/write",
    element: <ManageCounselWritePage></ManageCounselWritePage>,
  },
  {
    path: "/mobile/manage/daily_report/card_list",
    element: <DailyReportCardListPage></DailyReportCardListPage>,
  },
  {
    path: "/mobile/manage/study_prescribe/card_list",
    element: <StudyPrescribeCardListPage></StudyPrescribeCardListPage>,
  },
  {
    path: "/mobile/manage/study_diary/card_list",
    element: <StudyDiaryCardListPage></StudyDiaryCardListPage>,
  },
  {
    path: "/mobile/manage/month_prescribe/card_list",
    element: <MonthPrescribeCardListPage></MonthPrescribeCardListPage>,
  },
  ...stuRouterArr,
  ...ParNoticeListPage,
  ...manageRouterArr
];

export default routerArr;