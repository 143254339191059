import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import Style from "./css/style.module.css";
import BackGroundArea from "./area/background";
import TopNameArea from "./area/top_name";
import RightButtonArea from "./area/right_button";
import CenterCharacter from "./area/center_char";
import BottomArea from "./area/bottom_area";
import HomeWorkBoxArea from "./area/home_work_box";
//import HomeWorkChartArea from "./area/home_work_chart";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import BuyPopup from "@/pages/mobile/char/char/popup/buy_popup";
import InvenPopup from "@/pages/mobile/char/char/popup/inven_popup";
import GoldRankPopup from "@/pages/mobile/char/char/popup/gold_rank_popup";
import RankCharPopup from "@/pages/mobile/char/char/popup/rank_char_popup";
import AddAttendPopup from "@/pages/mobile/edu/attend/popup/add_attend";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import LygMath from '@/lib/lyg/math';

function StuArea(props: any) {
  let myProps = {
    ...props
  };

  const CenterCharacterRef = useRef<any>(null);
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  useEffect(() => {
    if (user.user_seq) {
      //get_unread_data_by_ajax();
      get_stu_main_data_by_ajax();
      get_char_opt_by_ajax();
      get_pre_water_his_by_ajax();
    }
  }, [user.user_seq]);

  const [stu_main_data,set_stu_main_data]=useState<any>({
    attend_arr:[],
    lecture_arr:[],
    desk_use_arr:[],
  });
  const [unread_data, set_unread_data] = useState({
    chat: 0,
    home_work: 0,
    plan: 0,
    my_plan: 0,
    sche: 0,
    push: 0,
  });
  const [char_opt, set_char_opt] = useState({
    a_gold: "0",
    a_select_char_seq: "0",
  });
  const [char_user_info, set_char_user_info] = useState<any>(null);

  const [pre_water_cnt, set_pre_water_cnt] = useState(0);
  const [is_water_cool_time, set_is_water_cool_time] = useState(false);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "buy_popup",//gold_rank,add_attend
    title: "상점",
    attend_sort: "in",
    attend_sort_detail: "attend",
    y: "0",
    width: "100%",
    height: "100%",
    is_border_radius: false,
  });

  const get_unread_data_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let unread_form_json_data = {
      
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/front/main/get_unread', unread_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_unread_data({
            ...unread_data,
            ...response.data["data"]["unread_data"],
          });
        } else {

        }
      });
  };
  const get_stu_main_data_by_ajax=()=>{
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let form_json_data={
      s_mcomp_seq:iam_mcomp_seq,
      s_mcomp_user_seq:iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/front/main/get_stu_main_data', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_stu_main_data({
            ...stu_main_data,
            ...response.data["data"],
          });
        } else {

        }
      });
  };

  const get_char_opt_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let form_json_data = {
      "s_user_seq": user.user_seq,
      "s_addon_select_char": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_char_opt(response.data["data"]["info_arr"][0]);
            if (response.data["data"]["info_arr"][0]["char_user_info"]) {
              set_char_user_info(response.data["data"]["info_arr"][0]["char_user_info"]);
            }
          }
        } else {

        }
      });
  };

  const get_pre_water_his_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }

    let form_json_data = {
      "s_date_type": "a_date",
      "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
      "s_main_user_seq": user.user_seq,
      "s_par_id": "add_water",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/gold_history/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_pre_water_cnt(response.data["data"]["info_arr"].length);
        } else {

        }
      });
  };

  const open_buy_popup = (inData: any) => {
    let opt_obj = {
      ...inData,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "buy_popup",
    });
  };

  const add_water_for_char = () => {
    if (is_water_cool_time) {
      return false;
    }
    set_is_water_cool_time(true);
    setTimeout(() => {
      set_is_water_cool_time(false);
    }, 2000);

    let add_amount = 20;
    let rand_able_num = 0;
    let is_able_add = true;
    let rand_able_num_max = 20;
    if (pre_water_cnt > 0) {
      if (pre_water_cnt > 5) {
        rand_able_num_max = 50;
      } else if (pre_water_cnt > 15) {
        rand_able_num_max = 200;
      } else if (pre_water_cnt > 20) {
        rand_able_num_max = 400;
      }
      is_able_add = false;
      rand_able_num = LygMath.random(1, rand_able_num_max);
      if (rand_able_num == 1) {
        is_able_add = true;
      }
    }
    if (pre_water_cnt > 0) {
      add_amount = LygMath.random(1, 7);
    }

    if (CenterCharacterRef.current) {
      let water_msg_arr = [
        "시원해요~",
        "감사합니다~",
        "물이 최고야!",
        "고마워요~!",
        "와우~!",
        "좋아요~♥",
      ];
      let water_msg_color_arr = [
        "blue", "orange", "green", "purple"
      ];
      let msg_random_idx = LygMath.random(0, water_msg_arr.length - 1);
      let msgc_random_idx = LygMath.random(0, water_msg_color_arr.length - 1);
      let tmp_msg = water_msg_arr[msg_random_idx];
      if (is_able_add != true) {
        let able_per_num = (100 - Math.round(((rand_able_num) / rand_able_num_max) * 100));
        if (rand_able_num != 0 && rand_able_num_max != 0) {
          able_per_num = (100 - Math.round((rand_able_num / rand_able_num_max) * 100));
        }
        tmp_msg += " (꽝!)" + able_per_num + "%";
      } else {
        tmp_msg += " (" + add_amount + "획득!)";
      }
      CenterCharacterRef.current.show_char_talk_msg({
        msg: tmp_msg,
        color: water_msg_color_arr[msgc_random_idx],
      });
      if (is_able_add == false) {
        setTimeout(() => {
          CenterCharacterRef.current.show_char_talk_msg({
            msg: water_msg_arr[msg_random_idx],
            color: water_msg_color_arr[msgc_random_idx],
          });
        }, 1000);
      }
    }

    if (is_able_add) {
      let form_json_data = {
        "row_data": {
          a_main_user_seq: user.user_seq,
          a_user_name: user.user_name,
          a_in_out: "in",
          a_msg: "물주기",
          a_par_mcomp_seq: user.comp_seq,
          a_par_id: "add_water",
          a_par_seq: "",
          a_amount: add_amount,
        },
        "is_able_duplicate": "1",
      };
      my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/add_gold_one', form_json_data, get_axios_data())
        .then((response) => {
          if (response.data["result"] === "true") {
            get_char_opt_by_ajax();
            get_pre_water_his_by_ajax();
          } else {
            alert(response.data["msg"]);
          }
        });
    }
  };

  const open_gold_rank_popup = (inData: any) => {
    let opt_obj = {
      ...inData,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "gold_rank",
    });
  };
  const open_char_rank_popup = (inData: any) => {
    let opt_obj = {
      ...inData,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "char_rank",
    });
  };

  const open_inven_popup = (inData: any) => {
    let opt_obj = {
      ...inData,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "inven",
    });
  };

  const open_add_attend_popup = (inData: any) => {
    let opt_obj = {
      attend_sort: "in",
      attend_sort_detail: "attend",
      ...inData,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "add_attend",
      attend_sort: opt_obj.attend_sort,
      attend_sort_detail: opt_obj.attend_sort_detail,
    });
  };

  return (
    <MobileLayout container_style={{ background: "#D0DB8C" }}
      container_con_style={{ width: "100%" }}
      is_home_body={true}
      has_header={false}
      has_footer={true}>
      <div className={Style.main_wrap}>
        <BackGroundArea></BackGroundArea>
        <TopNameArea
          Style={Style}
          char_opt={char_opt}
          attend_arr={stu_main_data.attend_arr}
          lecture_arr={stu_main_data.lecture_arr}
          desk_use_arr={stu_main_data.desk_use_arr}
          open_add_attend_popup={open_add_attend_popup}
        ></TopNameArea>
        <CenterCharacter
          ref={CenterCharacterRef}
          Style={Style}
          unread_data={unread_data}
          char_user_info={char_user_info}
        ></CenterCharacter>
        <RightButtonArea
          open_buy_popup={open_buy_popup}
        ></RightButtonArea>
        <BottomArea
          Style={Style}
          add_water_for_char={add_water_for_char}
          open_inven_popup={open_inven_popup}
          pre_water_cnt={pre_water_cnt}
        ></BottomArea>
        <HomeWorkBoxArea
        ></HomeWorkBoxArea>
        <div className="text-center" style={{ padding: 20 }}>
          <button className="btn btn-gray" onClick={open_gold_rank_popup}
            style={{ background: "#b36109", fontSize: "16px" }}>양분랭킹</button>
          <button className="btn btn-gray ml-2" onClick={open_char_rank_popup}
            style={{ background: "#b36109", fontSize: "16px" }}>씨앗랭킹</button>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius}
          has_header={false} content_div_style={{ padding: 0 }}>
          {popupData.sort === "buy_popup" &&
            <BuyPopup
              callback={() => { get_char_opt_by_ajax(); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></BuyPopup>
          }
          {popupData.sort === "gold_rank" &&
            <GoldRankPopup
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></GoldRankPopup>
          }
          {popupData.sort === "inven" &&
            <InvenPopup
              callback={() => { get_char_opt_by_ajax(); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></InvenPopup>
          }
          {popupData.sort === "char_rank" &&
            <RankCharPopup
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></RankCharPopup>
          }
          {popupData.sort === "add_attend" &&
            <AddAttendPopup
              attend_sort={popupData.attend_sort}
              attend_sort_detail={popupData.attend_sort_detail}
              refresh_data={() => { get_stu_main_data_by_ajax(); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></AddAttendPopup>
          }
        </LayerPopup>
      }
    </MobileLayout>
  );
};
export default StuArea;