import Style from "./css/style.module.css";
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";

function StuNameBarArea(props: any) {
  let myProps = {
    row_data: {},
    info_arr: [],
    select_idx: 0,
    isUpdate: false,
    select_arr_stu_grade: [],
    set_select_idx: (inData: any) => { },
    open_find_stu_popup: () => { },
    on_left_click: () => { },
    on_right_click: () => { },
    ...props
  };
  
  let row_data = myProps.row_data;
  let select_idx = parseInt(strFunc.uncomma(myProps.select_idx));
  let info_arr = myProps.info_arr;

  const on_left_right_click = (sort: "left" | "right") => {
    let tmp_select_idx = select_idx;
    if (sort == "left") {
      tmp_select_idx--;
      if (tmp_select_idx < 0) {
        tmp_select_idx = 0;
      }
    } else {
      tmp_select_idx++;
      if (tmp_select_idx >= info_arr.length) {
        tmp_select_idx = info_arr.length - 1;
      }
    }
    myProps.set_select_idx(tmp_select_idx);
  };

  let stu_grade_str = ""
  if (myProps.select_arr_stu_grade && row_data.stu_info) {
    for (let i = 0; i < myProps.select_arr_stu_grade.length; i++) {
      if (myProps.select_arr_stu_grade[i]["value"] == row_data.stu_info["a_stu_grade"]) {
        stu_grade_str = myProps.select_arr_stu_grade[i]["text"];
      }
    }
  }

  return (
    <div className={Style.header + " noto_sans"} >
      <div className={Style.header_con}>
        {myProps.isUpdate &&
          <span className={Style.header_left}
            onClick={() => { on_left_right_click("left"); }} >
            <img src={left_arrow_ico} style={{ height: 17 }} />
          </span>
        }
        {row_data.a_stu_name}
        {!strFunc.is_empty(stu_grade_str) &&
          <span className="ml-1">{stu_grade_str}</span>
        }
        {myProps.isUpdate == false &&
          <button className="btn btn-dark ml-2"
            onClick={() => { myProps.open_find_stu_popup(); }} >찾기</button>
        }
        {myProps.isUpdate &&
          <span className={Style.header_right}
            onClick={() => { on_left_right_click("right"); }} >
            <img src={right_arrow_ico} style={{ height: 17 }} />
          </span>
        }
      </div>
    </div>
  );
};
export default StuNameBarArea;