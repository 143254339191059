//import ChildDataFunc from "@/pcomponents/common/content/parent/func/child_data";

class ChildDataFunc
{
  static get_child_arr_by_login_user(user:any){
    let group_child_arr:any=[];
    let comp_link_arr:any=[];
    if(user.comp_link_arr){
      comp_link_arr=user.comp_link_arr;
    }
    //학원별, 자녀별 나누기
    let default_comp_stu_row={
      "stu_grade":"",
      "school_name":"",
      "main_user_seq":"",
      "main_user_name":"",
      "mcomp_seq":"",
      "par_mcomp_user_seq":"",
      "comp_name":"",
      "comp_api_key":"",
      "is_select":"",
      "is_select_stu":"",
    };
    let comp_stu_row_arr=[];
    for(let i=0;i<comp_link_arr.length;i++){
      let comp_link=comp_link_arr[i];
      if(comp_link["child_arr"]){
        for(let j=0;j<comp_link["child_arr"].length;j++){
          let is_select_stu="";
          let child_info=comp_link["child_arr"][j];
          if(comp_link["a_is_select"]=="1"){
            if(comp_link["a_select_child_mcomp_user_seq"]==child_info["user_seq"]){
              is_select_stu="1";
            }
          }
          comp_stu_row_arr.push({
            ...default_comp_stu_row,
            ...child_info,
            "mcomp_seq":comp_link["a_mcomp_seq"],
            "par_mcomp_user_seq":comp_link["a_mcomp_user_seq"],
            "comp_name":comp_link["comp_name"],
            "comp_api_key":comp_link["comp_api_key"],
            "is_select":comp_link["a_is_select"],
            "is_select_stu":is_select_stu,
          });
        }
      }
    }
    let select_stu_row_idx=0;
    for(let i=0;i<comp_stu_row_arr.length;i++){
      let comp_stu_info=comp_stu_row_arr[i];
      if(comp_stu_info["is_select"]=="1"){
        if(comp_stu_info["is_select_stu"]=="1"){
          select_stu_row_idx=i;
        }
      }
    }

    return {
      comp_stu_row_arr:comp_stu_row_arr,
      select_stu_row_idx:select_stu_row_idx,
      select_comp_stu_row:comp_stu_row_arr[select_stu_row_idx]?comp_stu_row_arr[select_stu_row_idx]:null,
    };
  }
}
export default ChildDataFunc;