export interface Icon_sort_data {
  key: string;
  d: string;
  w:number;
  h:number;
}

let default_icon_sort_data={
  key:"",
  d:"",
  w:24,
  h:24,
};

export const icon_data_arr:Icon_sort_data[]=[
  {
    ...default_icon_sort_data,
    key:"home",
    d:"M10.0001 19V14H14.0001V19C14.0001 19.55 14.4501 20 15.0001 20H18.0001C18.5501 20 19.0001 19.55 19.0001 19V12H20.7001C21.1601 12 21.3801 11.43 21.0301 11.13L12.6701 3.59997C12.2901 3.25997 11.7101 3.25997 11.3301 3.59997L2.9701 11.13C2.6301 11.43 2.8401 12 3.3001 12H5.0001V19C5.0001 19.55 5.4501 20 6.0001 20H9.0001C9.5501 20 10.0001 19.55 10.0001 19Z",
  },
  {
    ...default_icon_sort_data,
    key:"chat",
    d:"M18 4H17V12C17 12.55 16.55 13 16 13H4V14C4 15.1 4.9 16 6 16H16L20 20V6C20 4.9 19.1 4 18 4ZM15 9V2C15 0.9 14.1 0 13 0H2C0.9 0 0 0.9 0 2V15L4 11H13C14.1 11 15 10.1 15 9Z",
    w:20,
    h:17
  },
  {
    ...default_icon_sort_data,
    key:"work",
    d:"M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM6.29 15.29L3.7 12.7C3.31 12.31 3.31 11.68 3.7 11.29C4.09 10.9 4.72 10.9 5.11 11.29L7 13.17L12.88 7.29C13.27 6.9 13.9 6.9 14.29 7.29C14.68 7.68 14.68 8.31 14.29 8.7L7.7 15.29C7.32 15.68 6.68 15.68 6.29 15.29Z",
    w:18,
    h:20
  },
  {
    ...default_icon_sort_data,
    key:"study_prescribe",
    d:"M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM6.29 15.29L3.7 12.7C3.31 12.31 3.31 11.68 3.7 11.29C4.09 10.9 4.72 10.9 5.11 11.29L7 13.17L12.88 7.29C13.27 6.9 13.9 6.9 14.29 7.29C14.68 7.68 14.68 8.31 14.29 8.7L7.7 15.29C7.32 15.68 6.68 15.68 6.29 15.29Z",
    w:18,
    h:20
  },
  {
    ...default_icon_sort_data,
    key:"month_prescribe",
    d:"M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM6.29 15.29L3.7 12.7C3.31 12.31 3.31 11.68 3.7 11.29C4.09 10.9 4.72 10.9 5.11 11.29L7 13.17L12.88 7.29C13.27 6.9 13.9 6.9 14.29 7.29C14.68 7.68 14.68 8.31 14.29 8.7L7.7 15.29C7.32 15.68 6.68 15.68 6.29 15.29Z",
    w:18,
    h:20
  },
  {
    ...default_icon_sort_data,
    key:"study_diary",
    d:"M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM6.29 15.29L3.7 12.7C3.31 12.31 3.31 11.68 3.7 11.29C4.09 10.9 4.72 10.9 5.11 11.29L7 13.17L12.88 7.29C13.27 6.9 13.9 6.9 14.29 7.29C14.68 7.68 14.68 8.31 14.29 8.7L7.7 15.29C7.32 15.68 6.68 15.68 6.29 15.29Z",
    w:18,
    h:20
  },
  {
    ...default_icon_sort_data,
    key:"my_plan_pre",
    d:"M16 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V2C18 0.9 17.1 0 16 0ZM10.56 4.53C10.85 4.24 11.33 4.24 11.62 4.53L12.5 5.41L13.38 4.53C13.67 4.24 14.15 4.24 14.44 4.53C14.73 4.82 14.73 5.3 14.44 5.59L13.56 6.47L14.44 7.35C14.73 7.64 14.73 8.12 14.44 8.41C14.15 8.7 13.67 8.7 13.38 8.41L12.5 7.54L11.62 8.42C11.33 8.71 10.85 8.71 10.56 8.42C10.27 8.13 10.27 7.65 10.56 7.36L11.44 6.48L10.56 5.6C10.26 5.3 10.26 4.82 10.56 4.53ZM4 5.72H7.5C7.91 5.72 8.25 6.06 8.25 6.47C8.25 6.88 7.91 7.22 7.5 7.22H4C3.59 7.22 3.25 6.88 3.25 6.47C3.25 6.06 3.59 5.72 4 5.72ZM7.75 14H6.5V15.25C6.5 15.66 6.16 16 5.75 16C5.34 16 5 15.66 5 15.25V14H3.75C3.34 14 3 13.66 3 13.25C3 12.84 3.34 12.5 3.75 12.5H5V11.25C5 10.84 5.34 10.5 5.75 10.5C6.16 10.5 6.5 10.84 6.5 11.25V12.5H7.75C8.16 12.5 8.5 12.84 8.5 13.25C8.5 13.66 8.16 14 7.75 14ZM14.25 15.25H10.75C10.34 15.25 10 14.91 10 14.5C10 14.09 10.34 13.75 10.75 13.75H14.25C14.66 13.75 15 14.09 15 14.5C15 14.91 14.66 15.25 14.25 15.25ZM14.25 12.75H10.75C10.34 12.75 10 12.41 10 12C10 11.59 10.34 11.25 10.75 11.25H14.25C14.66 11.25 15 11.59 15 12C15 12.41 14.66 12.75 14.25 12.75Z",
    w:18,
    h:20
  },
  {
    ...default_icon_sort_data,
    key:"my_plan",
    d:"M15.4545 0H1.54545C0.695454 0 0 0.688235 0 1.52941V11.4706C0 12.3118 0.695454 13 1.54545 13H15.4545C16.3045 13 17 12.3118 17 11.4706V1.52941C17 0.688235 16.3045 0 15.4545 0ZM15.4545 10.7059C15.4545 11.1265 15.1068 11.4706 14.6818 11.4706H8.5V1.52941H14.6818C15.1068 1.52941 15.4545 1.87353 15.4545 2.29412V10.7059ZM14.1023 4.20588H9.85227C9.53545 4.20588 9.27273 4.46588 9.27273 4.77941C9.27273 5.09294 9.53545 5.35294 9.85227 5.35294H14.1023C14.4191 5.35294 14.6818 5.09294 14.6818 4.77941C14.6818 4.46588 14.4191 4.20588 14.1023 4.20588ZM14.1023 6.11765H9.85227C9.53545 6.11765 9.27273 6.37765 9.27273 6.69118C9.27273 7.00471 9.53545 7.26471 9.85227 7.26471H14.1023C14.4191 7.26471 14.6818 7.00471 14.6818 6.69118C14.6818 6.37765 14.4191 6.11765 14.1023 6.11765ZM14.1023 8.02941H9.85227C9.53545 8.02941 9.27273 8.28941 9.27273 8.60294C9.27273 8.91647 9.53545 9.17647 9.85227 9.17647H14.1023C14.4191 9.17647 14.6818 8.91647 14.6818 8.60294C14.6818 8.28941 14.4191 8.02941 14.1023 8.02941Z",
    w:18,
    h:20
  },
  {
    ...default_icon_sort_data,
    key:"plan",
    d:"M17.1111 0H1.99997C0.961084 0 0.111084 0.85 0.111084 1.88889V15.1111C0.111084 16.15 0.961084 17 1.99997 17H17.1111C18.15 17 19 16.15 19 15.1111V1.88889C19 0.85 18.15 0 17.1111 0ZM6.7222 13.2222H3.88886C3.36942 13.2222 2.94442 12.7972 2.94442 12.2778C2.94442 11.7583 3.36942 11.3333 3.88886 11.3333H6.7222C7.24164 11.3333 7.66664 11.7583 7.66664 12.2778C7.66664 12.7972 7.24164 13.2222 6.7222 13.2222ZM6.7222 9.44444H3.88886C3.36942 9.44444 2.94442 9.01944 2.94442 8.5C2.94442 7.98056 3.36942 7.55556 3.88886 7.55556H6.7222C7.24164 7.55556 7.66664 7.98056 7.66664 8.5C7.66664 9.01944 7.24164 9.44444 6.7222 9.44444ZM6.7222 5.66667H3.88886C3.36942 5.66667 2.94442 5.24167 2.94442 4.72222C2.94442 4.20278 3.36942 3.77778 3.88886 3.77778H6.7222C7.24164 3.77778 7.66664 4.20278 7.66664 4.72222C7.66664 5.24167 7.24164 5.66667 6.7222 5.66667ZM15.8833 7.66889L12.8894 10.6628C12.5211 11.0311 11.9166 11.0311 11.5483 10.6628L10.2166 9.32167C9.84831 8.95333 9.84831 8.35833 10.2166 7.99C10.585 7.62167 11.18 7.62167 11.5483 7.99L12.2189 8.66056L14.5516 6.32778C14.92 5.95944 15.515 5.95944 15.8833 6.32778L15.8928 6.33722C16.2516 6.70556 16.2516 7.31 15.8833 7.66889Z",
    w:19,
    h:17
  },
  {
    ...default_icon_sort_data,
    key:"sche",
    d:"M11.9118 1.36362H11.25V0.681805C11.25 0.306805 10.9522 -1.31758e-05 10.5882 -1.31758e-05C10.2243 -1.31758e-05 9.92647 0.306805 9.92647 0.681805V1.36362H3.30882V0.681805C3.30882 0.306805 3.01103 -1.31758e-05 2.64706 -1.31758e-05C2.28309 -1.31758e-05 1.98529 0.306805 1.98529 0.681805V1.36362H1.32353C0.595588 1.36362 0 1.97726 0 2.72726V13.6364C0 14.3864 0.595588 15 1.32353 15H11.9118C12.6397 15 13.2353 14.3864 13.2353 13.6364V2.72726C13.2353 1.97726 12.6397 1.36362 11.9118 1.36362ZM11.25 13.6364H1.98529C1.62132 13.6364 1.32353 13.3295 1.32353 12.9545V4.77271H11.9118V12.9545C11.9118 13.3295 11.614 13.6364 11.25 13.6364Z",
    w:14,
    h:15
  },
  {
    ...default_icon_sort_data,
    key:"counsel_todo",
    d:"M9.17 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.42L10.58 0.59C10.21 0.21 9.7 0 9.17 0ZM8 8C9.1 8 10 8.9 10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10C6 8.9 6.9 8 8 8ZM12 16H4V15.43C4 14.62 4.48 13.9 5.22 13.58C6.07 13.21 7.01 13 8 13C8.99 13 9.93 13.21 10.78 13.58C11.52 13.9 12 14.62 12 15.43V16Z",
    w:16,
    h:20
  },
  {
    ...default_icon_sort_data,
    key:"home_work",
    d:"M10.2941 1.42857H7.95588C7.64706 0.6 6.83823 0 5.88235 0C4.92647 0 4.11765 0.6 3.80882 1.42857H1.47059C0.661765 1.42857 0 2.07143 0 2.85714V13.5714C0 14.3571 0.661765 15 1.47059 15H5.96323C5.52941 14.5929 5.17647 14.1071 4.91912 13.5714H1.47059V2.85714H2.94118V3.57143C2.94118 4.35714 3.60294 5 4.41176 5H7.35294C8.16176 5 8.82353 4.35714 8.82353 3.57143V2.85714H10.2941V6.48571C10.8162 6.55714 11.3088 6.70714 11.7647 6.91429V2.85714C11.7647 2.07143 11.1029 1.42857 10.2941 1.42857ZM5.88235 2.85714C5.47794 2.85714 5.14706 2.53571 5.14706 2.14286C5.14706 1.75 5.47794 1.42857 5.88235 1.42857C6.28676 1.42857 6.61765 1.75 6.61765 2.14286C6.61765 2.53571 6.28676 2.85714 5.88235 2.85714ZM9.55882 7.85714C7.52941 7.85714 5.88235 9.45714 5.88235 11.4286C5.88235 13.4 7.52941 15 9.55882 15C11.5882 15 13.2353 13.4 13.2353 11.4286C13.2353 9.45714 11.5882 7.85714 9.55882 7.85714ZM10.5074 12.8571L9.29412 11.6786C9.22794 11.6143 9.18382 11.5214 9.18382 11.4286V9.65C9.18382 9.45 9.34559 9.29286 9.55147 9.29286C9.75735 9.29286 9.91912 9.45 9.91912 9.65V11.2857L11.0221 12.3571C11.1691 12.5 11.1691 12.7214 11.0221 12.8643C10.8824 13 10.6544 13 10.5074 12.8571Z",
    w:14,
    h:15
  },
];
export const get_icon_data_by_key=(key:string,icon_data_arr:Icon_sort_data[]):Icon_sort_data=>{
  let icon_data:Icon_sort_data={
    ...default_icon_sort_data
  };

  let icon_data_arr_len=icon_data_arr.length;
  for(let i=0;i<icon_data_arr_len;i++){
    if(icon_data_arr[i].key==key){
      icon_data=icon_data_arr[i];
    }
  }

  return icon_data;
};