import Style from "./css/style.module.css";
import PlusMatterArea from "./area/plus";
import MinusMatterArea from "./area/minus";
import MulmultiplyMatterArea from "./area/multiply";
import DivideMatterArea from "./area/divide";

function MatterArea(props: any) {
  let myProps = {
    matter: null,
    is_show_answer:false,
    is_show_correct:false,
    ...props
  };
  let matter = myProps.matter;

  const get_matter_tag = () => {
    if (matter == null) {
      return "";
    }
    if (matter.type == "plus") {
      return (
        <PlusMatterArea
          Style={Style}
          matter={matter}
          is_show_answer={myProps.is_show_answer}
          is_show_correct={myProps.is_show_correct}
        ></PlusMatterArea>
      );
    }else if(matter.type=="minus"){
      return (
        <MinusMatterArea
          Style={Style}
          matter={matter}
          is_show_answer={myProps.is_show_answer}
          is_show_correct={myProps.is_show_correct}
        ></MinusMatterArea>
      );
    }else if(matter.type=="multiply"){
      return (
        <MulmultiplyMatterArea
          Style={Style}
          matter={matter}
          is_show_answer={myProps.is_show_answer}
          is_show_correct={myProps.is_show_correct}
        ></MulmultiplyMatterArea>
      );
    }else if(matter.type=="divide"){
      return (
        <DivideMatterArea
          Style={Style}
          matter={matter}
          is_show_answer={myProps.is_show_answer}
          is_show_correct={myProps.is_show_correct}
        ></DivideMatterArea>
      );
    }
    return (
      <div>
        없는 타입의 문제 형태 입니다.
      </div>
    );
  };
  return (
    <div className={Style.matter_wrap}>
      {get_matter_tag()}
    </div>
  );
};
export default MatterArea;