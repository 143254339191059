import strFunc from "@/lib/lyg/string";
import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";
import StuTimeTableFunc from "@/pcomponents/common/content/edu/lecture/time_table/stu_time/func/stu_time_func";

class GetCalendarTdDataFunc {
  static td_data_arr_of_attend(inData: any) {
    let opt_obj = {
      td_data_arr: [],
      attend_info_arr: [],
      lecture_arr:[],
      desk_use_arr:[],
      ...inData
    };
    let td_data_arr=opt_obj.td_data_arr;
    let this_obj=this;

    //stu_union_time_arr
    let stu_union_time_arr=[];
    for(let lec_i=0;lec_i<opt_obj.lecture_arr.length;lec_i++){
      let tmp_lecture=opt_obj.lecture_arr[lec_i];
      if(tmp_lecture.stu_union_time_arr&&tmp_lecture.stu_union_time_arr.length){
        for(let i=0;i<tmp_lecture.stu_union_time_arr.length;i++){
          let tmp_union_time=tmp_lecture.stu_union_time_arr[i];
          tmp_union_time["lecture_name"]=tmp_lecture["a_name"];
          tmp_union_time["room_name"]=tmp_lecture["a_room_name"];
          stu_union_time_arr.push(tmp_union_time);
        }
      }
    }
    stu_union_time_arr=StuTimeTableFunc.get_ordered_stu_union_time_arr({
      stu_union_time_arr:stu_union_time_arr
    });

    //content
    for(let td_i=0;td_i<td_data_arr.length;td_i++){
      let td_data=td_data_arr[td_i];
      
      let row_attend_arr=[];
      for(let i=0;i<opt_obj["attend_info_arr"].length;i++){
        let tmp_attend_info=opt_obj["attend_info_arr"][i];
        if(td_data["date_str"]==tmp_attend_info["a_ymd"]){
          row_attend_arr.push(tmp_attend_info);
        }
      }
      let in_out_info_data=AttendStateFunc.get_in_out_attend_info_data({
        attend_info_arr:row_attend_arr
      });
      let attend_data=AttendStateFunc.get_attend_data_by_in_out_info(in_out_info_data);
      let td_time_table_str=this_obj.get_td_time_table_str({
        td_data:td_data,
        stu_union_time_arr:stu_union_time_arr
      });
      let attend_td_str:any="";
      if(attend_data.text!="미등원"){
        let attend_conten_style:any={
          textAlign:"center",
          background:attend_data.color,
          color:"#fff",
          padding:"1px 2px",
          borderRadius:20,
          display:"inline-block",
          fontWeight:500
        };
        attend_td_str=(
          <div className="text-center">
            <div style={attend_conten_style}>
              {attend_data.text}
            </div>
          </div>
        );
      }
      //desk
      let td_desk_use_str=this_obj.get_td_desk_use_str({
        td_data:td_data,
        desk_use_arr:opt_obj.desk_use_arr
      });
      td_data_arr[td_i]["content"]=(
        <div className="text-center" style={{minHeight:60,lineHeight:"17px"}}>
          {td_time_table_str}
          {attend_td_str}
          {td_desk_use_str}
        </div>
      );
    }

    return td_data_arr;
  };
  static get_td_time_table_str(inData: any) {
    let opt_obj={
      td_data:{},
      stu_union_time_arr:[],
      ...inData
    };
    let td_time_table_str:any="";
    let td_data=opt_obj.td_data;
    let stu_union_time_arr=opt_obj.stu_union_time_arr;
    let time_table_str_arr:any=[];
    //day_of_week
    for(let i=0;i<stu_union_time_arr.length;i++){
      let tmp_stu_union_time=stu_union_time_arr[i];
      if(tmp_stu_union_time["a_day_num"]==td_data["day_of_week"]){
        let start_time_str=strFunc.HourMinuteToTime(tmp_stu_union_time["a_start_h"],tmp_stu_union_time["a_start_m"]);
        let time_table_str=tmp_stu_union_time["lecture_name"].substring(0,2)+" "+start_time_str;
        time_table_str_arr.push(time_table_str);
      }
    }

    let time_table_row_tags=time_table_str_arr.map((item:any,idx:number)=>{
      return(
        <div key={idx} style={{fontSize:"11px"}}>
          {item}
        </div>
      );
    });
    td_time_table_str=(
      <div>
        {time_table_row_tags}
      </div>
    );

    return td_time_table_str;
  };
  static get_td_desk_use_str(inData: any) {
    let opt_obj={
      td_data:{},
      desk_use_arr:[],
      ...inData
    };
    let td_desk_use_str:any="";
    let td_desk_use_arr:any=[];
    for(let i=0;i<opt_obj.desk_use_arr.length;i++){
      let tmp_desk_use=opt_obj.desk_use_arr[i];
      if(tmp_desk_use["a_date"]==opt_obj.td_data["date_str"]){
        td_desk_use_arr.push(tmp_desk_use);
      }
    }
    td_desk_use_str=td_desk_use_arr.map((item:any,idx:number)=>{
      let desk_use_str="";
      let room_name="";
      if(item["edu_room_info"]){
        room_name=item["edu_room_info"]["a_name"];
      }
      let use_sec=parseInt(strFunc.uncomma(item["a_target_time_sec"]));
      let use_min=Math.floor(use_sec/60);
      desk_use_str=room_name+" ("+use_min+"분)";
      return(
        <div key={idx} style={{fontSize:"11px"}}>
          {desk_use_str}
        </div>
      );
    });
    return td_desk_use_str;
  };
}
export default GetCalendarTdDataFunc;