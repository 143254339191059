import ViewVideoArea from "@/pages/mobile/plan/todo/commponent/view_video";

function ViewTodoVideoPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    todo_info: {},
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },
    ...props
  };

  return (
    <div>
      <ViewVideoArea
        todo_info={myProps.todo_info}
        on_go_back={() => { myProps.callback();myProps.closePopup(); }}
        back_btn_title="닫기"
        user_data={myProps.user_data}
      ></ViewVideoArea>
    </div>
  );
};
export default ViewTodoVideoPopup;