import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import DefaultTableData from "./data/default_data";
import CollisionFunc from "@/pcomponents/common/content/edu/lecture/time_table/list_one_day/func/collision/collision_func";

class TimeTableFunc {
  static get_table_option_by_lecture_arr = (inData: any) => {
    let opt_obj = {
      lecture_arr: [],
      teacher_arr: [],
      room_arr: [],
      is_teacher_nickname: false,
      ...inData
    };
    let table_option: any = {
      start_time_m: 60 * 17,//분
      end_time_m: 60 * 18,//분
      split_term: 30,//분리간격 분
      split_time_m_arr: [],//[30,60,..] 분단위
      td_data_arr: [],//default_td_data_row
      day_num_arr: [0, 1, 2, 3, 4, 5, 6],
      group_day_time_td_data_arr: {},//{[day_num,range_time_str]:{day_num:0,range_time_str:"",td_data_arr:[]}..}
      week_day_td_size_arr: {},//{0:1,2:1..}
      table_th_arr: [],//[{x,y,col_span,row_span,is_view,title},..]
      table_tr_td_arr: [],//[[{x,y,col_span,row_span,td_data,is_view},..]]
    };
    opt_obj["table_option"] = table_option;
    table_option = this.get_td_data_arr_table_option(opt_obj);
    table_option = this.get_min_max_time_table_option({
      table_option: table_option
    });
    table_option = this.get_table_tr_td_arr({
      table_option: table_option,
    });

    return table_option;
  }
  static get_td_data_arr_table_option = (inData: any) => {
    let opt_obj = {
      lecture_arr: [],
      teacher_arr: [],
      room_arr: [],
      is_teacher_nickname: false,
      table_option: {},
      ...inData
    };
    let this_obj = this;
    let table_option = opt_obj.table_option;

    //td_data_arr 얻기
    let td_data_arr = [];
    for (let lec_i = 0; lec_i < opt_obj["lecture_arr"].length; lec_i++) {
      let lecture_info = opt_obj["lecture_arr"][lec_i];
      if (lecture_info["stu_union_time_arr"] && lecture_info["stu_union_time_arr"].length > 0) {
        for (let union_i = 0; union_i < lecture_info["stu_union_time_arr"].length; union_i++) {
          let union_time = lecture_info["stu_union_time_arr"][union_i];
          let room_info: any = null;
          let room_name = "";
          if (strFunc.is_empty(union_time["a_room_seq"])) {
            union_time["a_room_seq"] = lecture_info["a_room_seq"];
          }
          if (!strFunc.is_empty(union_time["a_room_seq"])) {
            room_info = this_obj.get_info_by_arr(opt_obj.room_arr, union_time["a_room_seq"], "a_seq");
            if (!strFunc.is_empty(room_info)) {
              room_name = room_info["a_name"];
            }
          }
          let teacher_info: any = null;
          let teacher_name = "";
          if (strFunc.is_empty(union_time["a_teacher_seq"])) {
            union_time["a_teacher_seq"] = lecture_info["a_main_teacher_seq"];
          }
          if (!strFunc.is_empty(union_time["a_teacher_seq"])) {
            teacher_info = this_obj.get_info_by_arr(opt_obj.teacher_arr, union_time["a_teacher_seq"], "a_seq");
            if (!strFunc.is_empty(teacher_info)) {
              teacher_name = teacher_info["a_user_name"];
              if (opt_obj["is_teacher_nickname"]) {
                if (!strFunc.is_empty(teacher_info["a_user_nickname"])) {
                  teacher_name = teacher_info["a_user_nickname"];
                }
              }
            }
          }

          let row_start_time_m = parseInt(strFunc.uncomma(union_time["a_start_h"])) * 60;
          row_start_time_m += parseInt(strFunc.uncomma(union_time["a_start_m"]));
          let row_end_time_m = parseInt(strFunc.uncomma(union_time["a_end_h"])) * 60;
          row_end_time_m += parseInt(strFunc.uncomma(union_time["a_end_m"]));

          //hm얻기
          let row_start_hm = strFunc.HourMinuteToTime(union_time["a_start_h"], union_time["a_start_m"]);
          let row_end_hm = strFunc.HourMinuteToTime(union_time["a_end_h"], union_time["a_end_m"]);
          let row_time_str = row_start_hm + "~" + row_end_hm;

          let add_td_data = {
            ...DefaultTableData.default_td_data_row,
            lecture_seq: lecture_info["a_seq"],
            lecture_name: lecture_info["a_name"],
            name: union_time["a_name"],
            start_time_m: row_start_time_m,
            end_time_m: row_end_time_m,
            time_str: row_time_str,
            start_h: union_time["a_start_h"],
            start_m: union_time["a_start_m"],
            end_h: union_time["a_end_h"],
            end_m: union_time["a_end_m"],
            room_seq: union_time["a_room_seq"],
            room_name: room_name,
            teacher_seq: union_time["a_teacher_seq"],
            teacher_name: teacher_name,
            day_num: union_time["a_day_num"],
            stu_union_time_info: union_time,
          };
          td_data_arr.push(add_td_data);
        }
      }
    }
    table_option.td_data_arr = td_data_arr;

    //범위정보 얻기
    let range_row_json_arr: any = {};//{start_end:{"start":0,"end":0,"day_num":0}}
    for (let td_i = 0; td_i < table_option["td_data_arr"].length; td_i++) {
      let row_td_data = table_option["td_data_arr"][td_i];
      let range_row: any = {
        start: row_td_data["start_time_m"],
        end: row_td_data["end_time_m"],
        day_num: row_td_data["day_num"],
      };

      //있는지검사
      let is_exist_range = false;
      for (let key in range_row_json_arr) {
        let ab_line_data = {
          a_start: range_row_json_arr[key]["start"],
          a_end: range_row_json_arr[key]["end"],
          b_start: range_row["start"],
          b_end: range_row["end"],
        };
        if (range_row_json_arr[key]["day_num"] == range_row["day_num"]) {
          let row_is_exist_range = CollisionFunc.is_line_collsion(ab_line_data);
          //붙어있으면 무효
          if (row_is_exist_range) {
            is_exist_range = row_is_exist_range;
          }
        }
      }

      if (is_exist_range == false) {
        //다른td돌리면서 범위추가
        for (let i = 0; i < table_option["td_data_arr"].length; i++) {
          let row_td_data2 = table_option["td_data_arr"][i];
          if (range_row["day_num"] == row_td_data2["day_num"]) {
            let ab_line_data = {
              a_start: row_td_data2["start_time_m"],
              a_end: row_td_data2["end_time_m"],
              b_start: range_row["start"],
              b_end: range_row["end"],
            };
            let row_is_exist_range = CollisionFunc.is_line_collsion(ab_line_data);
            if (row_is_exist_range) {
              if (range_row["start"] > row_td_data2["start_time_m"]) {
                range_row["start"] = row_td_data2["start_time_m"];
              }
              if (range_row["end"] < row_td_data2["end_time_m"]) {
                range_row["end"] = row_td_data2["end_time_m"];
              }
            }
          }
        }

        //등록
        let range_key = range_row["day_num"] + "," + range_row["start"] + "~" + range_row["end"];
        range_row_json_arr[range_key] = range_row;
      }
    }
    //범위정보 추가
    for (let td_i = 0; td_i < table_option["td_data_arr"].length; td_i++) {
      let row_td_data = table_option["td_data_arr"][td_i];
      for (let key in range_row_json_arr) {
        let range_row = range_row_json_arr[key];
        let ab_line_data = {
          a_start: range_row["start"],
          a_end: range_row["end"],
          b_start: row_td_data["start_time_m"],
          b_end: row_td_data["end_time_m"],
        };
        if (range_row["day_num"] == row_td_data["day_num"]) {
          let row_is_exist_range = CollisionFunc.is_line_collsion(ab_line_data);
          if (row_is_exist_range) {
            table_option["td_data_arr"][td_i]["range_start_time_m"] = range_row["start"];
            table_option["td_data_arr"][td_i]["range_end_time_m"] = range_row["end"];
            table_option["td_data_arr"][td_i]["range_time_str"] = range_row["start"] + "~" + range_row["end"];
          }
        }
      }
    }

    return table_option;
  };
  static get_info_by_arr = (arr: [], pri_val: any, pri_key: string) => {
    let info = null;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][pri_key] == pri_val) {
        info = arr[i];
      }
    }
    return info;
  };
  static get_min_max_time_table_option = (inData: any) => {
    let opt_obj = {
      table_option: {},
      ...inData
    };
    let this_obj = this;
    let table_option = opt_obj.table_option;

    let min_start_m = -1;
    let max_end_m = -1;
    let exist_day_num_arr: any = [];
    for (let i = 0; i < table_option.td_data_arr.length; i++) {
      let td_data = table_option.td_data_arr[i];
      if (min_start_m == -1) {
        min_start_m = td_data["start_time_m"];
      }
      if (max_end_m == -1) {
        max_end_m = td_data["end_time_m"];
      }
      if (min_start_m > td_data["start_time_m"]) {
        min_start_m = td_data["start_time_m"];
      }
      if (max_end_m < td_data["end_time_m"]) {
        max_end_m = td_data["end_time_m"];
      }
      td_data["day_num"] = parseInt(strFunc.uncomma(td_data["day_num"]));
      if (strFunc.str_in_array(td_data["day_num"], exist_day_num_arr) == -1) {
        exist_day_num_arr.push(td_data["day_num"]);
      }
    }
    if (min_start_m != -1) {
      table_option["start_time_m"] = min_start_m;
    }
    if (max_end_m != -1) {
      table_option["end_time_m"] = max_end_m;
    }
    exist_day_num_arr.sort();
    table_option["day_num_arr"] = exist_day_num_arr;

    let split_time_m_arr = [];
    for (let ing_m = min_start_m; ing_m < max_end_m; ing_m += table_option.split_term) {
      split_time_m_arr.push(ing_m);
    }
    table_option["split_time_m_arr"] = split_time_m_arr;

    //일,시간 그룹 td_data_arr
    let group_day_time_td_data_arr: any = {};
    for (let i = 0; i < table_option.td_data_arr.length; i++) {
      let td_data = table_option.td_data_arr[i];
      let tmp_group_key_arr = [
        td_data["day_num"],
        td_data["range_time_str"],
      ];
      let tmp_group_key = tmp_group_key_arr.join(",");
      if (group_day_time_td_data_arr[tmp_group_key] == undefined) {
        group_day_time_td_data_arr[tmp_group_key] = {
          day_num: td_data["day_num"],
          range_time_str: td_data["range_time_str"],
          td_data_arr: [],
        };
      }
      group_day_time_td_data_arr[tmp_group_key]["td_data_arr"].push(td_data);
    }
    table_option["group_day_time_td_data_arr"] = group_day_time_td_data_arr;

    //주의 td 크기
    let week_day_td_size_arr: any = {};
    for (let day_i = 0; day_i < table_option["day_num_arr"].length; day_i++) {
      let day_num = table_option["day_num_arr"][day_i];
      for (let group_key in group_day_time_td_data_arr) {
        let group_day_time_td_data = group_day_time_td_data_arr[group_key];
        if (group_day_time_td_data["day_num"] == day_num) {
          if (week_day_td_size_arr[day_num] == undefined) {
            week_day_td_size_arr[day_num] = 1;
          }
          if (group_day_time_td_data["td_data_arr"].length > week_day_td_size_arr[day_num]) {
            week_day_td_size_arr[day_num] = group_day_time_td_data["td_data_arr"].length;
          }
        }
      }
    }
    table_option["week_day_td_size_arr"] = week_day_td_size_arr;

    return table_option;
  };
  static get_table_tr_td_arr = (inData: any) => {
    let opt_obj = {
      table_option: {},
      ...inData,
    };
    let table_option = opt_obj.table_option;
    let table_th_arr: any = [];//[{x,y,col_span,row_span,is_view,title},..]
    let table_tr_td_arr: any = [];//[[{x,y,col_span,row_span,td_data,is_view},..]]

    //table_th_arr 얻기
    let th_x = 0;
    for (let day_num in table_option.week_day_td_size_arr) {
      let max_col_span = table_option.week_day_td_size_arr[day_num];
      for (let i = 0; i < max_col_span; i++) {
        let th_is_view = true;
        let th_col_span = max_col_span;
        if (i != 0) {
          th_is_view = false;
          th_col_span = 0;
        }
        let th_title = DateFunc.get_day_str_by_day_num(day_num);
        table_th_arr.push({
          x: th_x,
          y: 0,
          col_span: th_col_span,
          is_view: th_is_view,
          title: th_title,
        });
        th_x++;
      }
    }
    table_option["table_th_arr"] = table_th_arr;

    //tr_td_arr 얻기
    for (let row_num = 0; row_num < table_option.split_time_m_arr.length; row_num++) {
      let split_m = table_option.split_time_m_arr[row_num];
      table_tr_td_arr.push([]);
      let row_x = 0;
      for (let day_num in table_option.week_day_td_size_arr) {
        let max_col_span = table_option.week_day_td_size_arr[day_num];
        let row_range_data_arr = [];
        for (let td_i = 0; td_i < table_option.td_data_arr.length; td_i++) {
          let td_data = table_option.td_data_arr[td_i];
          if (td_data["day_num"] == day_num) {
            if (td_data["range_start_time_m"] <= split_m && split_m < td_data["range_end_time_m"]) {
              row_range_data_arr.push(td_data);
            }
          }
        }
        for (let col_i = 0; col_i < max_col_span; col_i++) {
          let tr_td_row: any = {
            x: row_x,
            y: row_num,
            col_span: 1,
            row_span: 1,
            td_data: null,
            is_view: true,
          };
          //td_data 얻기

          if (row_range_data_arr[col_i] != undefined) {
            if (row_range_data_arr[col_i]["start_time_m"] == split_m) {
              tr_td_row["td_data"] = row_range_data_arr[col_i];
            }
          }
          if (tr_td_row["td_data"] != null) {
            let row_span_num = 1;
            let diff_time_m = tr_td_row["td_data"]["end_time_m"] - tr_td_row["td_data"]["start_time_m"];
            row_span_num = Math.round(diff_time_m / table_option.split_term);
            tr_td_row["row_span"] = row_span_num;
          }
          //is_view 얻기 (이전 row들 중 row_span이 있다면 false)
          for (let pre_row = 0; pre_row < row_num; pre_row++) {
            let remain_row_num = table_tr_td_arr[pre_row][row_x]["row_span"];
            remain_row_num = remain_row_num - (row_num - pre_row);
            if (remain_row_num > 0) {
              tr_td_row["is_view"] = false;
            }
          }

          table_tr_td_arr[row_num].push(tr_td_row);
          row_x++;
        }
      }
    }
    table_option.table_tr_td_arr = table_tr_td_arr;

    return table_option;
  };
}
export default TimeTableFunc;