import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function ReportWriteComponent(props: any) {
  let myProps = {
    row_data: {},
    set_row_data: (inData: any) => { },
    is_view_mode: false,
    num_text: <span style={{ color: "#fff",background:"orange" }}>신규</span>,
    manage_area: "",
    ...props
  };
  let row_data = myProps.row_data;
  let is_view_mode = myProps.is_view_mode;

  let select_abc_num_arr = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  const on_change_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    on_change_by_key_val({
      name: name,
      value: value
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    on_change_by_key_val({
      name: key,
      value: date_str
    });
  };
  const on_change_by_key_val = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      ...inData
    };
    let name = opt_obj["name"];
    let value = opt_obj["value"];

    if (myProps.is_view_mode) {
      return false;
    }

    row_data[name] = value;

    myProps.set_row_data(row_data);
  };

  return (
    <div>
      <div className="write_table_small">
        <table>
          <colgroup>
            <col width={"31%"}></col>
            <col width={"30%"}></col>
            <col width={"31%"}></col>
            <col width={"8%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <td colSpan={2}>
                <span>
                  {myProps.num_text}
                </span>
                <span className="ml-1" >
                  {is_view_mode ?
                    row_data["a_date"]
                    :
                    <div style={{ display: "inline-block", width: 80 }}>
                      <DatePicker
                        wrapperClassName="pl-1"
                        selected={row_data["a_date"] != "" ? new Date(row_data["a_date"]) : null}
                        onChange={(date: Date) => {
                          onChangeDatePicker("a_date", date);
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputSearch />}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </div>
                  }
                </span>
                <select name="a_subject" value={row_data["a_subject"]} className="ml-1"
                  onChange={on_change_input} style={{ border: "1px solid #ddd", width: 45 }}>
                  <option value="" >과목</option>
                  {select_subject_option_arr.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.value} >{item.text}</option>
                    );
                  })}
                </select>
                <label>
                  <input type="checkbox" name={"a_is_success"} value="1"
                    className="ml-1"
                    checked={row_data["a_is_success"] == "1"}
                    onChange={(e: any) => {
                      on_change_by_key_val({
                        name: e.target.name,
                        value: e.target.checked ? "1" : "",
                      });
                    }} />
                  <span className="ml-1">완료</span>
                </label>
              </td>
              <td>
                <SelectBoxBar
                  valueTextArr={select_abc_num_arr}
                  value={row_data["a_exam_main_num"]}
                  name={"a_exam_main_num"}
                  on_change={on_change_by_key_val}
                  style_type={"small"}
                ></SelectBoxBar>
              </td>
              <td>
                <span className="ml-1" style={{ fontSize: 12 }}>
                  {row_data["a_writer"]}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                {is_view_mode ?
                  <MultyLineTextView
                    text={row_data["a_progress_main"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_progress_main"}
                    value={row_data["a_progress_main"]}
                    onChange={on_change_input}
                    placeholder={"진도"}
                  ></TextAreaComponent>
                }
              </td>
              <td>
                {is_view_mode ?
                  <MultyLineTextView
                    text={row_data["a_homework"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_homework"}
                    value={row_data["a_homework"]}
                    onChange={on_change_input}
                    placeholder={"숙제"}
                  ></TextAreaComponent>
                }
              </td>
              <td>
                {is_view_mode ?
                  <MultyLineTextView
                    text={row_data["a_comment"]}
                  ></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_comment"}
                    value={row_data["a_comment"]}
                    onChange={on_change_input}
                    placeholder={"코멘트"}
                  ></TextAreaComponent>
                }
              </td>
              <td>
                {myProps.manage_area}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ReportWriteComponent;