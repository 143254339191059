import strFunc from '@/lib/lyg/string';
import { useState,useRef,useEffect,useCallback } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import getXcolumnJson from "./xcolumn/list";
import SearchArea from "./area/search";
import Paging from "@/pcomponents/common/crud/list/paging/paging";

function InviteUserPopup(props:any){
   let myProps={
    list_opt:{
      "s_grade":"student",
      "s_except_user_seq":""
    },
    room_info:{},
    memInfoArr:[],
    closePopup:()=>{},
    calback:()=>{},
    ...props
  };

  let user=useSelector((state:any) => state.user);

  let iam_mcomp_seq="";
  let iam_mcomp_user_seq="";
  let comp_api_key="";
  let is_manager=false;
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_seq=user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key=user.select_mcomp_link["comp_api_key"];
    if(user.select_mcomp_link["a_user_grade"]=="master"
      ||user.select_mcomp_link["a_user_grade"]=="teacher"){
      is_manager=true;
    }
  }

  const xColumnArr=getXcolumnJson();
  const ListComponentRef=useRef<any>(null);
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  let default_list_opt:any={
    'now_page': 1,
    'num_per_page': 300,
    "order_id": "a_user_name",
    "s_is_login":"1",
    "s_grade":["teacher","master"],
    "s_stu_state":"now",
    "s_mcomp_seq":"",
    "s_mcomp_user_seq":"",
    s_user_name_like:"",
    s_school_name_like:"",
    s_addon_comp_link:"1",
    s_addon_class_user:"1",
    ...myProps.list_opt,
  };
  const [pageData, setPageData] = useState({
    listOpt: default_list_opt
  });

  useEffect(()=>{
    
  },[user]);

  const list=(inOptObj:any)=>{
    let tmp_listOpt:any={
      ...pageData.listOpt,
      ...inOptObj
    };
    if(inOptObj!=undefined){
      setPageData({
        ...pageData,
        listOpt:tmp_listOpt
      });
    }

    tmp_listOpt["s_mcomp_seq"]=iam_mcomp_seq;
    tmp_listOpt["s_mcomp_user_seq"]=iam_mcomp_user_seq;
    if(strFunc.is_empty(tmp_listOpt["s_mcomp_seq"])
      ||strFunc.is_empty(tmp_listOpt["s_mcomp_user_seq"])){
        return false;
    }

    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/basic/comp_user/list',tmp_listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const inviteMember=()=>{
    if(ListComponentRef.current==null){
      return false;
    }
    let select_row_arr=ListComponentRef.current.getSelectedRows();

    if(select_row_arr.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      alert("학원선택이 없습니다.");
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_seq)){
      alert("학원선택이 없습니다.2");
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원선택이 없습니다.3");
      return false;
    }
    if(!confirm("초대 하시겠습니까?")){
      return false;
    }

    let add_mem_row_arr=[];
    for(let i=0;i<select_row_arr.length;i++){
      let tmp_u_info=select_row_arr[i];
      let a_main_user_seq="";
      if(!strFunc.is_empty(tmp_u_info["comp_link_info"])){
        a_main_user_seq=tmp_u_info["comp_link_info"]["a_main_user_seq"];
      }
      add_mem_row_arr.push({
        "a_room_seq":myProps.room_info["a_seq"],
        "a_user_mcomp_seq":user.comp_seq,
        "a_user_seq":tmp_u_info["a_seq"],
        "a_main_user_seq":a_main_user_seq,
        "a_user_name":tmp_u_info["a_user_name"],
        "a_is_owner":"",
      });
    }
    let add_mem_opt={
      "data_arr":add_mem_row_arr,
      "is_default_val":"1",
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/member/write',add_mem_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if((myProps.memInfoArr.length+add_mem_row_arr.length)>=3){
          //더이상 1:1방이 아니다
          changeRoomState();
        }else{
          myProps.callback();
          myProps.closePopup();
        }
        
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const changeRoomState=()=>{
    let changeRoomInfo={
      "a_seq":myProps.room_info.a_seq,
      "a_is_one":""
    };

    if(strFunc.is_empty(comp_api_key)){
      alert("학원선택이 없습니다.");
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_seq)){
      alert("학원선택이 없습니다.2");
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원선택이 없습니다.3");
      return false;
    }
    
    let formJsonData={
      "data_arr":[changeRoomInfo],
      "is_update":"1",
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/room/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        props.callback();
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };
  
  const customCellRenderer= useCallback( (params:any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key=params.key;
    var render_str=params.value;
    if(key=="row_view_class_name"){
      if(params.rowData.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.rowData.class_arr.length;i++){
          class_name_arr.push(params.rowData.class_arr[i]["class_name"]);
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_school_grade"){
      let stu_grade_str="";
      if(xColumnArr.select_arr["a_stu_grade"]){
        for(let i=0;i<xColumnArr.select_arr["a_stu_grade"].length;i++){
          let tmp_row_opt=xColumnArr.select_arr["a_stu_grade"][i];
          if(tmp_row_opt["value"]==params.rowData.a_stu_grade){
            stu_grade_str=tmp_row_opt["text"];
          }
        }
      }
      render_str=(
        <div className="text-center">
          <p>{params.rowData.a_school_name}</p>
          <p>{stu_grade_str}</p>
        </div>
      );
    }else if(key=="a_stu_num"){
      render_str=strFunc.getNumber2(render_str);
    }else if(key=="row_view_is_app"){
      if(params.rowData.comp_link_info){
        render_str=(<div className="text-green-500">가입</div>);
      }
      if(params.rowData.comp_link_info){
        render_str=(<div className="text-gray-500" title={"로그아웃"}>가입</div>);
        if(params.rowData.comp_link_info.main_u_info&&params.rowData.comp_link_info.main_u_info.a_push_token){
          render_str=(<div className="text-green-500">가입</div>);
        }
      }
    }else if(key=="a_user_name"){
      render_str=strFunc.cut_str(render_str,4,"..");
      if(params.rowData.comp_link_info){
        if(params.rowData.comp_link_info.main_u_info&&params.rowData.comp_link_info.main_u_info.a_push_token){
          render_str=(<span className="text-green-500" >{render_str}</span>);
        }else{
          render_str=(<span className="text-gray-500" >{render_str}</span>);
        }
      }
    }else if(key=="a_school_name"){
      render_str=strFunc.cut_str(render_str,6,"..");
    }
    
    return render_str;
  }, []);

  return (
  <div>
    <div className="btn-box-center my-2">
      <button className="btn btn-line-gray" onClick={()=>{inviteMember();}}>초대</button>
      <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
    </div>
    <SearchArea
      listOpt={pageData.listOpt}
      list={list}
      user={user}
    ></SearchArea>
    {infoArr.length} 명
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={xColumnArr}
        gridOpt={{
          is_add_idx_num:false,
          is_add_checkbox:true,
          td_align:"center",
          height:"52vh",
          onGridReady:()=>{
            list({});
          },
          customCellRenderer:customCellRenderer,
        }}
      ></ListComponent>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
  </div>
  );
};
export default InviteUserPopup;