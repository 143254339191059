import strFunc from "@/lib/lyg/string";
import CheckBoxGreen from "@/pcomponents/common/content/list/element/checkbox_green";

function ListArea(props:any){
   let myProps={
    info_arr:[],
    Style:{},
    select_seq_arr:[],
    set_select_seq_arr:(inData:any)=>{},
    select_stu_grade_option_arr:[],
    is_select_one:true,
    ...props
  };
  let info_arr=myProps.info_arr;
  let Style=myProps.Style;
  let select_stu_grade_option_arr=myProps.select_stu_grade_option_arr;

  const on_click_row=(info:any,idx:number)=>{
    //체크박스
    let is_checked=false;
    if(strFunc.str_in_array(info.a_seq,myProps.select_seq_arr)!=-1){
      is_checked=true;
    }

    if(is_checked){
      //빼기
      let new_select_seq_arr=[];
      for(let i=0;i<myProps.select_seq_arr.length;i++){
        if(myProps.select_seq_arr[i]==info.a_seq){

        }else{
          new_select_seq_arr.push(myProps.select_seq_arr[i]);
        }
      }
      myProps.set_select_seq_arr(new_select_seq_arr);
    }else{
      //선택
      if(myProps.is_select_one){
        myProps.set_select_seq_arr([info.a_seq]);
      }else{
        myProps.select_seq_arr.push(info.a_seq);
        myProps.set_select_seq_arr(myProps.select_seq_arr);
      }
    }
  };
  
  const get_tr_tags=()=>{
    let tr_tags=info_arr.map((item:any,idx:number)=>{
      let stu_grade_str=item.a_stu_grade;
      if(select_stu_grade_option_arr.length>0){
        for(let i=0;i<select_stu_grade_option_arr.length;i++){
          if(select_stu_grade_option_arr[i]["value"]==item.a_stu_grade){
            stu_grade_str=select_stu_grade_option_arr[i]["text"];
          }
        }
      }

      let is_checked=false;
      if(strFunc.str_in_array(item.a_seq,myProps.select_seq_arr)!=-1){
        is_checked=true;
      }

      let row_class_name=Style.stu_row_tr;
      if(is_checked){
        row_class_name+=" "+Style.active;
      }

      let counsel_cnt_str:any="";
      let tot_span_style:any={
        display:"inline-block",
        textAlign:"center",
        lineHeight:"26px",
        height:"26px",
        padding:"0px 8px",
        background:"gray",
        color:"#fff",
        borderRadius:"5px"
      };
      if(item.ctodo_tot_info["tot"]>0){
        tot_span_style["background"]="#db6c58";
        if(item.ctodo_tot_info["success_tot"]>0){
          tot_span_style["background"]="#eab358";
          if(item.ctodo_tot_info["tot"]==item.ctodo_tot_info["success_tot"]){
            tot_span_style["background"]="#a2b854";
          }
        }
      }
      counsel_cnt_str=(
        <div style={tot_span_style}>
          {item.ctodo_tot_info["success_tot"]+"/"+item.ctodo_tot_info["tot"]}
        </div>
      );

      return (
        <tr key={idx} 
          className={row_class_name}
          onClick={()=>{on_click_row(item,idx);}}>
          <td>
            {item.a_user_name}
          </td>
          <td>
            {strFunc.cut_str(item.a_school_name,5,"..")}
          </td>
          <td>
            {stu_grade_str}
          </td>
          <td>
            {counsel_cnt_str}
          </td>
          <td style={{textAlign:"center"}}>
            <CheckBoxGreen
              checked={is_checked}
              on_change={(e:any)=>{
                
              }}
            ></CheckBoxGreen>
          </td>
        </tr>
      );
    });

    return tr_tags;
  };

  return (
  <div className={Style.stu_list_table_wrap}>
    <table className={Style.stu_list_table}>
      <colgroup>
        <col width={"80px"}></col>
        <col width={"90px"}></col>
        <col width={"50px"}></col>
        <col width={"*"}></col>
        <col width={"40px"}></col>
      </colgroup>
      <tbody>
        {get_tr_tags()}
      </tbody>
    </table>
  </div>
  );
};
export default ListArea;