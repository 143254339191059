import { useNavigate } from 'react-router-dom';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import LoadingComponent from "@/pcomponents/common/content/loading";
import right_arrow from "@/img/ico/arrow/right_arrow.png";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import TStuWritePopup from "@/pages/mobile/manage/tea/tstu_todo/popup/write";
import ListArea from "./area/list";

function TeacherStuTodoArea(props: any) {
  let myProps = {
    Style: {},
    user: {},
    ...props
  };
  const navigate = useNavigate();
  let Style = myProps.Style;
  let user = myProps.user;
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;

  let pre_month_y_m_d = DateFunc.get_date_format(
    DateFunc.get_change_date(new Date(DateFunc.get_date_format(new Date(), "Y-m-01")), 'month', -1)
    , "Y-m-01");
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 10,
    'order_id': 'a_is_success, a_reserve_date DESC, a_ymd DESC, a_seq DESC',
    'order_type': '',
    "is_add_idx_info": "1",
    s_addon_comp_user: "1",
    s_date_type:"a_reserve_date",
    s_start_date: pre_month_y_m_d,
    s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    s_owner_mcomp_user_seq: "",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: iam_mcomp_user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
    "max_limit_num": "8",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [load_data,set_load_data]=useState({
    is_display: false,
    text: "로딩중..",
  });
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "add_attend",
    is_update: false,
    row_data: {},
    title: "팝업",
    width: "500px",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inData: any) => {
    let tmp_list_opt: any = {
      ...listOpt,
      ...inData
    };
    setListOpt(tmp_list_opt);
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_grade)) {
      return false;
    }

    tmp_list_opt["s_mcomp_seq"] = iam_mcomp_seq;
    tmp_list_opt["s_public_mcomp_user_seq"] = iam_mcomp_user_seq;
    tmp_list_opt["s_public_user_grade"] = iam_mcomp_user_grade;
    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const go_save_success_by_ajax=(inData:any)=>{
    let opt_obj={
      write_info_arr:[],
      ...inData
    };
    if(opt_obj.write_info_arr.length==0){
      return false;
    }
    let form_json_data:any={
      data_arr:opt_obj.write_info_arr,
    };
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
          set_load_data({
            is_display: true,
            text: "저장 되었습니다.",
          });
          setTimeout(() => {
            set_load_data({
              is_display: false,
              text: "로딩중..",
            });
          }, 600);
        } else {

        }
      });
  };

  const openWritePopup = (inData: any) => {
    let opt_obj = {
      is_update: false,
      row_data: {},
      ...inData
    };
    let is_update = opt_obj.is_update;
    let row_data = opt_obj.row_data;

    let pop_title = "등록";
    if (is_update) {
      pop_title = "수정";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "write",
      "title": pop_title,
      "is_update": is_update,
      "row_data": row_data,
    });
  };

  return (
    <div>
      <div className={Style.top_title_wrap} style={{marginTop:15}}>
        <div style={{ position: "relative",fontWeight:"500",fontSize:"15px" }}>
          To do List
          <span style={{verticalAlign:"text-top"}}>
            <button 
              className="round_plus_btn_green ml-2"
              style={{width:20,height:20,lineHeight:"20px",fontSize:13}}
              onClick={()=>{openWritePopup({});}}>
              +
            </button>
          </span>
          <a style={{ position: "absolute", right: 10, top: 0, fontSize: "14px", color: "#777", fontWeight: 400 }}
            onClick={() => { navigate("/mobile/manage/tea/tstu_todo/list"); }}>
            <img src={right_arrow} style={{ height: "15px",marginBottom:4 }} alt="" />
          </a>
        </div>
      </div>
      <div className={Style.main_box_wrap}>
        <div className={Style.main_box_con} style={{paddingTop:15}}>
          <ListArea
            infoArr={infoArr}
            Style={Style}
            user={user}
            go_save_success_by_ajax={go_save_success_by_ajax}
          ></ListArea>
        </div>
      </div>
      <LoadingComponent is_display={load_data.is_display} text={load_data.text}></LoadingComponent>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "write" &&
            <TStuWritePopup 
              is_update={popupData.is_update}
              row_data={popupData.row_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></TStuWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default TeacherStuTodoArea;