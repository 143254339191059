import strFunc from "@/lib/lyg/string";

function HeaderArea(props: any) {
  let myProps = {
    closePopup: () => { },
    char_opt:{},
    title:"상점",
    ...props
  };

  let head_wrap_style:any={
    position:"absolute",
    top:0,left:0,
    width:"100%",
    height:"40px",
    textAlign:"center",
    lineHeight:"22px",
    background:"transparent",
    zIndex:1,
  };

  return (
    <div style={head_wrap_style}>
      <span style={{fontWeight:600,color:"#7A9E1D",fontSize:"20px"}}>{myProps.title}</span>
      <p style={{fontSize:"16px"}}>
        양분 
        <span className="mx-1" style={{color:"blue",fontWeight:700}}>
          {strFunc.comma(myProps.char_opt["a_gold"])}
        </span>
        점
      </p>
      <button className="btn btn-gray" onClick={myProps.closePopup}
        style={{ position: "absolute", right: 5, top: 5,background:"#7A9E1D" }}
      >X</button>
    </div>
  );
};
export default HeaderArea;