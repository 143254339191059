import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"user",
    list_id:"user_staff_find_multy",
    x_column_list_arr:[
      // {"key":"row_view_manage","name":"관리","width":"80","is_show":"1"},
      {"key":"a_user_name","name":"이름","width":"100","is_show":"1"},
      {"key":"a_user_nickname","name":"닉네임","width":"100","is_show":"1"},
      // {"key":"row_view_par_phone","name":"학부모/학생 번호","width":"160","is_show":"1"},
      // {"key":"a_user_id","name":"아이디","width":"120","is_show":"1"},
      // {"key":"a_user_phone","name":"휴대폰","width":"120","is_show":"1"},
      {"key":"a_stu_num","name":"출결번호","width":"110","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':100,
      'order_id':'a_user_name',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
      "is_add_idx_info":"1",
      "s_search_text_type":"name",
      "s_search_text":"",
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;