import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import MonthPrescribeViewFunc from "@/pcomponents/common/content/edu/month_prescribe/view/view_func";

function ViewPopupContent(props:any) {
  let myProps={
    is_view_write_btn:true,
    ...props
  };
  //props.rowData.a_user_name;
  const [info,set_info]=useState(props.rowData);
  let row_data={...info};
  let stu_grade_str = "";
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  if(info.stu_info){
    row_data["a_stu_name"]=info.stu_info["a_user_name"];
    stu_grade_str = row_data.stu_info.a_stu_grade;
    for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
      if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
        stu_grade_str = select_stu_grade_option_arr[i]["text"];
      }
    }
  }

  let text_str=MonthPrescribeViewFunc.get_view_text_str({info:row_data});
  
  // let week_i_str:any=parseInt(strFunc.uncomma(row_data.week_i));
  // let month_num=parseInt(strFunc.uncomma(row_data.month_num));
  // week_i_str++;
  // if(week_i_str==0){
  //   week_i_str="전달 마지막";
  // }else{
  //   week_i_str=month_num+"월 "+week_i_str;
  // }
  // let init_content_title_str = "학생명: "+row_data["a_stu_name"];
  // if(row_data.stu_info){
  //   init_content_title_str+=" ("+strFunc.cut_str(row_data.stu_info["a_school_name"], 3, "")+" "+stu_grade_str+")";
  // }
  // init_content_title_str+="\n";
  // init_content_title_str+=week_i_str+" 주차 학습처방 내용 \n\n";
  // init_content_title_str+="1.진도전략:\n";
  // init_content_title_str+="  "+row_data.a_progress_reason+"\n\n";
  // init_content_title_str += "2.현재진도:\n";
  // init_content_title_str+="  "+row_data.a_progress+"\n\n";
  // init_content_title_str += "3.성장한부분:\n";
  // init_content_title_str+="  "+row_data.a_grow+"\n\n";
  // init_content_title_str += "4.발견:\n";
  // init_content_title_str+="  "+row_data.a_lack+"\n\n";
  // init_content_title_str += "5.학습처방:\n";
  // init_content_title_str+="  "+row_data.a_content+"\n";
  const [content_title_str,set_content_title_str]=useState(text_str);
  const [copy_msg,set_copy_msg]=useState("");

  const copy_str_to_clipboard=(str:string)=>{
    navigator.clipboard.writeText(str);
    set_copy_msg("복사됨.");
    setTimeout(()=>{
      set_copy_msg("");
    },2000);
  }
  return (
    <div>
      <div className="view-table-div mt-2 noto_sans">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>원생</th>
              <td colSpan={3}>
                {row_data.a_stu_name}
                {row_data.stu_info&&
                <span className="ml-1" style={{ color: "#737373" }} >
                  ({strFunc.cut_str(row_data.stu_info["a_school_name"], 3, "")} {stu_grade_str})
                </span>
                }
              </td>
            </tr>
            <tr>
              <th>날짜</th>
              <td colSpan={3}>
                {row_data.a_reserve_date}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <TextAreaComponent
                  name={"content_title_str"}
                  value={content_title_str}
                  onChange={(e:any)=>{
                    set_content_title_str(e.target.value);
                  }}
                ></TextAreaComponent>
              </td>
            </tr>
            <tr>
              <td colSpan={4} style={{textAlign:"center"}}>
                <button className="btn btn-sky" onClick={()=>{copy_str_to_clipboard(content_title_str);}}>복사</button>
                <span className="ml-1" style={{color:"blue"}}>{copy_msg}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        {/* {myProps.is_view_write_btn&&
        <button className="btn btn-line-gray" onClick={()=>{
            props.closePopup();
            props.openOnePopup("write",true);
          }}>수정</button>
        } */}
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
