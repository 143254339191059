import TodoViewArea from "@/pages/mobile/plan/todo/commponent/view";
import { useEffect } from 'react';

function TodoViewPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    on_go_write_page: () => { },
    todo_info: {},
    is_go_start: false,
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },
    ...props
  };

  useEffect(() => {
    let popup_class_obj:any=document.getElementsByClassName("popup-content-div");
    if(popup_class_obj.length>0){
      popup_class_obj[0].style.background="linear-gradient(203deg, #C8D376 -5.34%, #ABC118 25.87%, #86A609 61.19%)";
    }
  }, []);

  return (
    <div>
      <TodoViewArea
        todo_info={myProps.todo_info}
        is_go_start={myProps.is_go_start}
        user_data={myProps.user_data}
        on_go_back={()=>{
          myProps.callback();
          myProps.closePopup();
        }}
        on_go_write_page={myProps.on_go_write_page}
        back_btn_title="닫기"
      ></TodoViewArea>
    </div>
  );
}
export default TodoViewPopup;