import strFunc from '@/lib/lyg/string';

class SetPropsFunc{
  geDataByProps(inData:any){
    let opt_obj={
      width:'300px',
      height:'300px',
      marginTop:"0",
      x:"",
      y:"",
      is_win_max_width:false,
      is_win_max_height:false,
      ...inData
    };

    let popup_width=this.getWdithByData(opt_obj.width);
    let popup_height=this.getHeightByData(opt_obj.height);
    let popup_x=this.getXByData(opt_obj.x,popup_width);
    let popup_y=this.getYByData(opt_obj.y,popup_height);
    
    var win_w=(window.innerWidth || document.documentElement.clientWidth);
    var win_h=(window.innerHeight || document.documentElement.clientHeight);
    if(opt_obj.is_win_max_width){
      if(popup_width>win_w){
        popup_x=0;
        popup_width=win_w;
      }
    }
    if(opt_obj.is_win_max_height){
      if(popup_height>win_h){
        popup_y=0;
        popup_height=win_h;
      }
    }

    let data={
      width:popup_width,
      height:popup_height,
      x:popup_x,
      y:popup_y,
    };

    return data;
  }

  getWdithByData(in_width:any){
    let width:any=300;
    let win_w:number=(window.innerWidth || document.documentElement.clientWidth);
    in_width=in_width+"";
    if(in_width.indexOf("px")!=-1){
      width=strFunc.uncomma(in_width.split("px")[0]);
      width=parseInt(width);
    }else if(in_width.indexOf("%")!=-1){
      width=strFunc.uncomma(in_width.split("%")[0]);
      width=parseInt(width);
      width=Math.round((width/100)*win_w);
    }else{
      width=strFunc.uncomma(in_width.split("%")[0]);
      width=parseInt(width);
    }
    return width;
  }
  getHeightByData(in_height:any){
    let height:any=300;
    let win_h:number=(window.innerHeight || document.documentElement.clientHeight);
    in_height=in_height+"";
    if(in_height.indexOf("px")!=-1){
      height=strFunc.uncomma(in_height.split("px")[0]);
      height=parseInt(height);
    }else if(in_height.indexOf("%")!=-1){
      height=strFunc.uncomma(in_height.split("%")[0]);
      height=parseInt(height);
      height=Math.round((height/100)*win_h);
    }else{
      height=strFunc.uncomma(in_height.split("%")[0]);
      height=parseInt(height);
    }
    return height;
  }

  getXByData(in_x:any,width:number){
    let x:any=0;
    let win_w:number=(window.innerWidth || document.documentElement.clientWidth);
    if(in_x==""){
      in_x=(win_w/2)-(width/2);
    }
    in_x=strFunc.uncomma(in_x+"");
    if(in_x.indexOf("px")==-1){
      x=strFunc.uncomma(in_x.split("px")[0]);
      x=parseInt(x);
    }else if(in_x.indexOf("%")==-1||in_x.indexOf("vw")==-1){
      x=strFunc.uncomma(in_x.split("%")[0]);
      x=parseInt(x);
      x=Math.round((x/100)*win_w);
    }else{
      x=strFunc.uncomma(in_x.split("%")[0]);
      x=parseInt(x);
    }
    
    return x;
  }

  getYByData(in_y:any,height:number){
    let y:any=0;
    let win_h:number=(window.innerHeight || document.documentElement.clientHeight);
    if(in_y==""){
      in_y=(win_h*0.05);
    }
    in_y=strFunc.uncomma(in_y+"");
    if(in_y.indexOf("px")==-1){
      y=strFunc.uncomma(in_y.split("px")[0]);
      y=parseInt(y);
    }else if(in_y.indexOf("%")==-1||in_y.indexOf("vh")==-1){
      y=strFunc.uncomma(in_y.split("%")[0]);
      y=parseInt(y);
      y=Math.round((y/100)*win_h);
    }else{
      y=strFunc.uncomma(in_y.split("%")[0]);
      y=parseInt(y);
    }
    
    return y;
  }
}
let setPropsFunc=new SetPropsFunc();

export default setPropsFunc;