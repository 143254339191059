function BottomButtonArea(props:any){
   let myProps={
    Style:{},
    back_btn_title:"목록으로",
    todo_info:{},
    on_click_back:()=>{},
    completeOne:()=>{},
    ...props
  };
  let Style=myProps.Style;
  let todo_info=myProps.todo_info;
  
  return (
    <div className={Style.bottom_btn_wrap}>
      <button className={Style.bottom_btn} onClick={myProps.on_click_back} >{myProps.back_btn_title}</button>
      {todo_info.a_is_success!="1"&&
        <button className={Style.bottom_btn} onClick={myProps.completeOne} style={{background:"#B9CA42"}}>완료하기</button>
      }
    </div>
  );
};
export default BottomButtonArea;