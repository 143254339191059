//import {CustomInputWrite} from 'components/common/date_picker/custom_input';
//customInput={<CustomInputWrite />}
import { forwardRef } from 'react';

export const CustomInputWrite = forwardRef((props, ref) => {
  const myProps={
    onClick:(e)=>{},
    value:"",
    ...props
  };
  return (
    <button className="date_picker_write" readOnly onClick={myProps.onClick} ref={ref}>
      &nbsp;{myProps.value}
    </button>
  )
});

export const CustomInputSearch = forwardRef(({ value, onClick }, ref) => (
  <button className="search-input w-full border" readOnly onClick={onClick} ref={ref}>
    &nbsp;{value}
  </button>
));