import { useState, useEffect,useRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

const SearchArea = (props: any) => {
  let myProps = {
    select_mcomp_seq:"",
    listOpt: {},
    setListOpt: (inData: any) => { },
    xColumnArr: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data=UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq:myProps.select_mcomp_seq
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  const [classSelectArr, setClassSelectArr] = useState<any>([]);
  const [schoolNameSelectArr, setSchoolNameSelectArr] = useState<any>([]);
  const search_time_out_ref=useRef<any>(null);

  useEffect(() => {
    getClassFrontArr();
  }, []);

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {

    }
    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const goSearch = () => {
    myProps.list({ ...listOpt });
  };

  const getClassFrontArr = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let class_list_form: any = {
      "s_add_school_arr": "1"
    };
    class_list_form["api_key"] = comp_api_key;
    class_list_form["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/front_class_arr', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setClassSelectArr(response.data["data"]["class_arr"]);
          setSchoolNameSelectArr(response.data["data"]["school_name_arr"]);
        } else {

        }
      });
  };

  return (
    <div className="search-box relative">
      <SearchLectureSelect
        select_mcomp_seq={myProps.select_mcomp_seq}
        name="s_stu_lecture_seq"
        value={listOpt.s_stu_lecture_seq}
        on_change_input={(inData: any) => {
          let name = inData.name;
          let value = inData.value;
          myProps.list({ now_page: 1, [name]: value });
        }}
        wrap_style={{ width: 60, display: "inline-table" }}
        select_title_style={{ lineHeight: "25px", height: 25, overflow: "hidden" }}
      ></SearchLectureSelect>
      {/* <select className="search_input" name="s_class_seq" value={listOpt.s_class_seq}
        onChange={handleInputChange} style={{ width: 70 }}>
        <option value="">클래스</option>
        {
          classSelectArr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select> */}
      <select className="search_input ml-1" name="s_school_name" value={listOpt.s_school_name}
        onChange={handleInputChange} style={{ width: 50 }} >
        <option value="">학교</option>
        <option value="elementary">초</option>
        <option value="middle">중</option>
        <option value="high">고</option>
        {
          schoolNameSelectArr.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search_input" name="s_stu_grade" value={listOpt.s_stu_grade}
        onChange={handleInputChange} style={{ width: 50 }} >
        <option value="">학년</option>
        {
          myProps.xColumnArr.select_arr.a_stu_grade.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      <select className="search_input" name="s_stu_state" value={listOpt.s_stu_state}
        onChange={handleInputChange} style={{ width: 50 }} >
        <option value="">현황</option>
        <option value="now,ready">대기,재원</option>
        {
          myProps.xColumnArr.select_arr.a_stu_state.map((item: any, idx: number) => {
            return (
              <option value={item.value} key={idx}>{item.text}</option>
            );
          })
        }
      </select>
      {/* <select className="search_input" name="s_is_par_join" value={listOpt.s_is_par_join} onChange={handleInputChange}>
        <option value="">학부모 가입</option>
        <option value="1">가입</option>
        <option value="empty">미가입</option>
      </select> */}
      <span className="basic_search_item_book_mark"
        onClick={() => {
          let change_s_stu_bookmark = listOpt.s_stu_bookmark;
          if (change_s_stu_bookmark == "1") {
            change_s_stu_bookmark = "";
          } else {
            change_s_stu_bookmark = "1";
          }
          strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
          myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
        }}>
        {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
      </span>
      {/* <select className="search_input" name="s_search_text_type" value={listOpt.s_search_text_type}
        onChange={handleInputChange} style={{ width: 60 }} >
        <option value="name">이름</option>
        <option value="phone">핸드폰</option>
      </select> */}
      <input type="text" className="search-input" name="s_search_text"
        style={{ width: 45 }} placeholder="검색어 입력" value={listOpt.s_search_text}
        onChange={handleInputChange} onKeyUp={(e: any) => { if (e.key === 'Enter') { goSearch(); } }} />
      <button className="btn btn-dark ml-1" onClick={() => { goSearch(); }} >검색</button>
    </div>
  );
};

export default SearchArea;