import TStuWriteArea from "@/pages/mobile/manage/tea/tstu_todo/common/write";
function TStuWritePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    row_data: {},
    is_update: false,
    ...props
  };

  return (
    <div>
      <TStuWriteArea
        is_update={myProps.is_update}
        row_data={myProps.row_data}
        callback={() => {
          myProps.callback();
          myProps.closePopup();
        }}
        close={() => { 
          myProps.closePopup(); }
        }
      ></TStuWriteArea>
    </div>
  );
};
export default TStuWritePopup;