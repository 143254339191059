import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useLocation, useNavigate } from "react-router";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUnreadTot } from '@/store/chat/chat';
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import ChatHeader from "./area/header";
import ChatListArea from "./area/chat_list";
import ChatSendArea from "./area/send_area";
import MemberListArea from "./area/mem_list";
import io from 'socket.io-client';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import InviteUserPopup from "./popup/invite_user";

function ChatView() {
  const navigate = useNavigate();
  const dispatch=useDispatch();
  let user = useSelector((state: any) => state.user);
  const { state } = useLocation();
  const [is_open_mem_list,set_is_open_mem_list]=useState(false);
  const [room_info, set_room_info] = useState(state.room_info);
  const [mem_info_arr, set_mem_info_arr] = useState<any>([]);
  const [refresh,set_refresh]=useState(false);
  //memNowArr
  const mem_now_arr_ref=useRef<any[]>([]);
  const [socket, setSocket] = useState<any>();
  const ChatListAreaRef=useRef<any>();
  const msg_info_arr_ref=useRef<any[]>([]);
  let room_mcomp_seq = room_info.a_mcomp_seq;
  let iam_mcomp_user_seq = "";
  let comp_api_key="";
  
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key=user.select_mcomp_link["comp_api_key"];
  }

  useEffect(() => {
    if (strFunc.is_empty(room_info.a_seq)) {
      alert("채팅방 정보가 없습니다.");
      navigate("/mobile/chat/room_list");
    }else if(strFunc.is_empty(iam_mcomp_user_seq)){
      alert("로그인 정보가 없습니다.");
      navigate("/mobile/chat/room_list");
    } else {
      setInitSocketIo();
      setTimeout(()=>{
        list_member();
        list_message();
      },500);
    }
  }, [user]);
  
  useEffect(() => {
    return () => {
      if(socket) { socket.disconnect(); }
    };
  }, [socket]);
  
  const setInitSocketIo=()=>{
    if(socket) { socket.disconnect(); }

    let cors_opt:any={
      cors: {
        origin: "*",
      },
      secure: true
    };
    const socketIo = io(process.env.REACT_APP_Chat_SERVER_URL as string,cors_opt);
    msg_info_arr_ref.current=[];
    dispatch(setUnreadTot({"time":""}));
    
    socketIo.emit("set_user_data",{
      "comp_seq":room_mcomp_seq,
      "room_seq":room_info["a_seq"],
      "user_name":user.user_name,
      "user_seq":iam_mcomp_user_seq,
    });
    socketIo.on('greet', (data) => {
      //console.log(data);
    });
    socketIo.on('chat_msg', (data) => {
      msg_info_arr_ref.current.push(data);
      ChatListAreaRef.current.set_chat_info_arr(ChatListAreaRef.current);
      setTimeout(()=>{
        ChatListAreaRef.current.setScrollBottom();
      },100);
    });

    socketIo.emit("get_room_users",{
      //"comp_room_id":"",
    });
    socketIo.on('get_room_users', (data) => {
      mem_now_arr_ref.current=data;
    });

    socketIo.on('user_disconnect', (data:any) => {
      //console.log(data);
    });
    
    setSocket(socketIo);
  };

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "inivite_user",//
    s_except_user_seq:[],
    s_grade:"student",
    title: "초대",
    y:"0",
    width: "500px",
    height: "100%",
    is_border_radius:false,
  });

  const open_invite_user_popup=()=>{
    if(room_info==null){
      return false;
    }
    let s_except_user_seq:any=[];
    for(let i=0;i<mem_info_arr.length;i++){
      let mem_info=mem_info_arr[i];
      s_except_user_seq.push(mem_info.a_user_seq);
    }
    setPopupData({
      ...popupData,
      "isOpen":true,
      s_except_user_seq:s_except_user_seq,
    });
  };

  const list_member = () => {
    if (room_info == null) {
      return false;
    }
    let mem_list_opt = {
      "s_room_seq": room_info["a_seq"],
      "s_addon_user": "1",
      "s_addon_user_name_by_info": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/chat/member/list', mem_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_mem_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_message = (inOptObj?: any) => {
    if (inOptObj == undefined) { inOptObj = {}; }
    if (room_info == null) {
      return false;
    }
    let mem_list_opt = {
      "s_room_seq": room_info["a_seq"],
      "is_check_unread": "1",
      "check_unread_room_seq": room_info["a_seq"],
      "check_unread_user_seq": iam_mcomp_user_seq,
      "s_start_date": room_info["a_create_date"].substring(0, 10),
      "s_msg": "",
      "s_addon_file": "1",
      "order_id": "a_create_date DESC",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "max_limit_num": "1000",
      ...inOptObj
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/chat/chat/list', mem_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          msg_info_arr_ref.current=response.data["data"]["info_arr"].reverse();
          ChatListAreaRef.current.set_chat_info_arr(msg_info_arr_ref.current);
          set_refresh(!refresh);
          setTimeout(()=>{
            ChatListAreaRef.current.setScrollBottom();
          },100);
        } else {

        }
      });
  };

  const sendChatMessage=(inData:any)=>{
    let opt_obj={
      "msg":"",
      ...inData
    };
    if(room_info==null){
      return false;
    }
    let send_user_name=user.user_name;
    for(let i=0;i<mem_info_arr.length;i++){
      let mem_info=mem_info_arr[i];
      if(mem_info.a_user_mcomp_seq==room_mcomp_seq&&mem_info.a_user_seq==iam_mcomp_user_seq){
        send_user_name=mem_info.a_user_name;
      }
    }
    let add_chat_row={
      "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
      "a_seq":"",
      "a_room_seq":room_info["a_seq"],
      "a_writer":send_user_name,
      "a_writer_seq":iam_mcomp_user_seq,
      "a_main_writer_seq":user.main_user_seq,
      "a_content":opt_obj.msg,
    };
    let now_user_seq_arr:any=[];
    for(let i=0;i<mem_now_arr_ref.current.length;i++){
      now_user_seq_arr.push(mem_now_arr_ref.current[i].user_seq);
    }
    
    let chat_w_opt={
      "chat_row":add_chat_row,
      "now_user_seq_arr":now_user_seq_arr,
      "mcomp_seq":room_mcomp_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/chat/add_chat',chat_w_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        // if(fileInput.current&&(fileInput.current as HTMLInputElement).value!=""){
        //   writeFileAppend(response.data["data"][0]);
        // }else{
        //   if(socket){
        //     socket.emit("chat_msg",response.data["data"][0]);
        //   }
        // }
        if(socket){
          socket.emit("chat_msg",response.data["data"][0]);
        }
      }else{
        
      }
    });
  };

  const go_out_room=()=>{
    if (strFunc.is_empty(room_info.a_seq)) {
      alert("채팅방 정보가 없습니다.");
      return false;
    }
    if (strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)) {
      alert("로그인 정보가 없습니다.");
      return false;
    }
    if(!confirm("채팅방을 나가시겠습니까?")){
      return false;
    }

    let out_room_opt={
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
      "room_seq":room_info.a_seq,
      "mcomp_seq":room_mcomp_seq,
      "user_seq":iam_mcomp_user_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/room/out_room',out_room_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("채팅방을 나갔습니다.");
        navigate("/mobile/chat/room_list");
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <MobileLayout 
      container_style={{marginTop:0,padding:0}}
      container_con_style={{ width: "100%", height: "100%", overflow: "hidden" }} has_header={false} has_footer={false}>
      <div className="chat_view_wrap">
        <ChatHeader
          room_info={room_info}
          user={user}
          onClickBack={() => { navigate("/mobile/chat/room_list"); }}
          onClickMember={() => { set_is_open_mem_list(true); }}
        ></ChatHeader>
        <ChatListArea
          ref={ChatListAreaRef}
          room_info={room_info}
          mem_info_arr={mem_info_arr}
          user={user}
          chat_info_arr={msg_info_arr_ref.current}
          iam_mcomp_user_seq={iam_mcomp_user_seq}
        ></ChatListArea>
        <ChatSendArea
          room_info={room_info}
          go_send_msg={sendChatMessage}
        ></ChatSendArea>
        <MemberListArea
          user={user}
          room_info={room_info}
          comp_api_key={comp_api_key}
          room_mcomp_seq={room_mcomp_seq}
          iam_mcomp_user_seq={iam_mcomp_user_seq}
          is_active={is_open_mem_list}
          mem_info_arr={mem_info_arr}
          mem_now_arr={mem_now_arr_ref.current}
          go_out_room={()=>{go_out_room();}}
          open_invite_user_popup={open_invite_user_popup}
          close={()=>{set_is_open_mem_list(false);}}
          list_member ={list_member}
        ></MemberListArea>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        y={popupData.y}
        is_border_radius={popupData.is_border_radius}
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="inivite_user"&&
            <InviteUserPopup 
              list_opt={{
                s_except_user_seq:popupData.s_except_user_seq,
                s_grade:popupData.s_grade,
              }}
              room_info={room_info}
              memInfoArr={mem_info_arr}
              callback={()=>{list_member();}} 
              closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></InviteUserPopup>
          }
      </LayerPopup>
      }
    </MobileLayout>
  );
}

export default ChatView;