import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import { useSelector } from 'react-redux';
import ChildDataFunc from "@/pcomponents/common/content/parent/func/child_data";
import StuOneSupplyArea from "@/pcomponents/common/content/supply/stu_one";
import { useLocation,useNavigate } from "react-router";

function SupplyParOneStuPage() {
  const navigate = useNavigate();
  let user = useSelector((state: any) => state.user);
  let group_child_data = ChildDataFunc.get_child_arr_by_login_user(user);
  let comp_stu_row_arr = group_child_data.comp_stu_row_arr;
  let select_stu_row_idx = group_child_data.select_stu_row_idx;
  let select_comp_stu_row = group_child_data.select_comp_stu_row;
  
  let user_data = {
    iam_mcomp_seq: select_comp_stu_row?select_comp_stu_row.mcomp_seq:"",
    iam_mcomp_user_seq: select_comp_stu_row?select_comp_stu_row.par_mcomp_user_seq:"",
    comp_api_key: select_comp_stu_row?select_comp_stu_row.comp_api_key:"",
  };

  const on_click_pay = (inData: any) => {
    let opt_obj={
      supply_money:0,
      supply_seq_arr:[],
      supply_name:"학원비",
      ...inData
    };
    if(opt_obj.supply_money==0){
      alert("결제 금액이 없습니다.");
      return false;
    }
    let send_data={
      ...opt_obj
    };
    let view_url="/mobile/parent/stu/supply_pay_init";
    navigate(view_url,
      { state: send_data });
  };

  return (
    <MobileLayout has_header={false} has_footer={false} container_con_style={{ width: "100%" }}>
      <HeaderSimple title={"청구내역"}></HeaderSimple>
      {user_data.iam_mcomp_user_seq &&
        <StuOneSupplyArea
          stu_seq={select_comp_stu_row.user_seq}
          user_data={user_data}
          on_click_pay={on_click_pay}
        ></StuOneSupplyArea>
      }
    </MobileLayout>
  );
};
export default SupplyParOneStuPage;