//import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
const RadioListArea=(props:any)=>{
  let myProps={
    valueTextArr:[],//[{"value":"","text":""}]
    value:"",
    name:"",
    handleInputChange:(e:any)=>{},
    ...props
  };
  const handleInputChange=(event:any)=>{
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if(target.type === 'checkbox'||target.type === 'radio'){
      value="";
      if(target.checked){
        value = target.value;
      }
    }
    myProps.handleInputChange(event);
  };

  let radiosTag=myProps.valueTextArr.map((item:any,idx:number)=>{
    let bChecked=false;
    let text_style:any={};
    if(item.value==myProps.value){
      bChecked=true;
      text_style["color"]="#3FAC96";
    }
    return (
      <label key={idx} className="mx-2" style={text_style}>
        <input type="radio" name={myProps.name} value={item.value} onChange={handleInputChange} checked={bChecked} className="mr-1" />
        {item.text}
      </label>
    );
  });

  return (
    <>
      {radiosTag}
    </>
  );
}

export default RadioListArea;