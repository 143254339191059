import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation,useNavigate } from "react-router";
import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import getXcolumnJson from "./xcolumn/list";
import video_check1_img from "@/img/ico/video_check1.png";
import video_check2_img from "@/img/ico/video_check2.png";
import VideoTabArea from "@/pages/mobile/plan/video/common/tab";
import YouTubeArea from "@/pcomponents/common/content/video/player/youtube";
import VimeoArea from  "@/pcomponents/common/content/video/player/vimeo";

function ViewVideoArea(props:any){
  let myProps={
    row_data:null,
    user_data:{
      iam_mcomp_seq:"",
      iam_mcomp_user_seq:"",
      comp_api_key:"",
    },
    on_go_back: (inData:any) => { },
    back_btn_title: "강의실로",
    ...props
  };
  let user=useSelector((state:any) => state.user);
  let iam_mcomp_seq = "";
  let iam_mcomp_user_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  if(!strFunc.is_empty(myProps.user_data.iam_mcomp_user_seq)){
    iam_mcomp_seq=myProps.user_data.iam_mcomp_seq;
    iam_mcomp_user_seq=myProps.user_data.iam_mcomp_user_seq;
    comp_api_key=myProps.user_data.comp_api_key;
  }
  const navigate = useNavigate();
  const xColumnArr=getXcolumnJson();

  const [row_data,set_row_data]= useState(myProps.row_data);
  const [video_info,set_video_info]=useState<any>(null);
  const [open_sections,set_open_sections]=useState<any>([]);
  const [is_play,set_is_play]=useState(false);
  const [detail_arr,set_detail_arr]=useState<any>([]);

  useEffect(() => {
    set_init_data({});
  }, []);

  useEffect(() => {
    const timerId=setInterval(setVideoTimeCount,1000);
    return () => clearInterval(timerId);
  },[video_info,is_play,detail_arr]);

  const setVideoTimeCount=()=>{
    if(video_info&&is_play){
      let detail_row=getUseDetailRowByVideo(video_info);
      let total_sec=parseInt(strFunc.uncomma(video_info.a_total_sec));
      let complete_sec=parseInt(strFunc.uncomma(video_info.a_complete_sec));
      let watch_sec=parseInt(strFunc.uncomma(detail_row.a_watch_sec));
      if(watch_sec>total_sec){
        //watch_sec=total_sec;
      }else{
        watch_sec++;
      }

      detail_row.a_watch_sec=watch_sec;
      if(detail_row.a_watch_sec>total_sec){
        detail_row.a_watch_sec=total_sec;
      }
      detail_row.a_total_sec=total_sec;
      if(watch_sec>=complete_sec){
        if(detail_row.a_video_is_complete!="1"){
          detail_row.a_video_is_complete="1";
          detail_row.a_end_date=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
        }
      }

      let tmp_detail_arr=[...detail_arr];
      let tmp_row_i=-1;
      for(let i=0;i<tmp_detail_arr.length;i++){
        if(tmp_detail_arr[i].a_video_seq==detail_row.a_video_seq){
          tmp_row_i=i;
        }
      }
      if(tmp_row_i==-1){
        tmp_detail_arr.push(detail_row);
      }else{
        tmp_detail_arr[tmp_row_i]=detail_row;
      }

      if(watch_sec<=total_sec){
        set_detail_arr([
          ...tmp_detail_arr
        ]);
        if(watch_sec%5==0){
          saveWatchTimeOfVideo();
        }else if(watch_sec>=complete_sec){
          saveWatchTimeOfVideo();
        }
      }

    }
  };
  const getNowVideoData=()=>{
    //현재 비디오 데이터 얻기
    let video_result_data:any={
      idx:0,
      total_sec:0,
      complete_sec:0,
      watch_sec:0,
      video_time_str:"00:00",
      watch_time_str:"00:00",
      per_num:0,
    };
    if(video_info){
      if(row_data.video_arr){
        for(let i=0;i<row_data.video_arr.length;i++){
          if(row_data.video_arr[i].a_seq==video_info.a_seq){
            video_result_data.idx=i;
          }
        }
      }
      let detail_row=getUseDetailRowByVideo(video_info);
      video_result_data.total_sec=parseInt(strFunc.uncomma(video_info.a_total_sec));
      video_result_data.complete_sec=parseInt(strFunc.uncomma(video_info.a_complete_sec));
      video_result_data.watch_sec=parseInt(strFunc.uncomma(detail_row.a_watch_sec));
      let video_time_obj:any=strFunc.secondsToTimeJson(strFunc.uncomma(video_info.a_total_sec));
      let video_time_str=video_time_obj.i+":"+video_time_obj.s;
      if(video_time_obj.h!="00"){
        video_time_str=video_time_obj.h+":"+video_time_obj.i+":"+video_time_obj.s;
      }
      video_result_data.video_time_str=video_time_str;

      if(detail_row){
        let per_num:any=0;
        if(detail_row.a_total_sec!=0&&detail_row.a_watch_sec!=0){
          per_num=strFunc.uncomma((detail_row.a_watch_sec/detail_row.a_total_sec)*100);
          per_num=parseInt(per_num);
        }
        if(detail_row.a_video_is_complete=="1"){
          per_num=100;
        }
        video_result_data.per_num=per_num;

        let watch_time_str="00:00";
        let watch_time_obj:any=strFunc.secondsToTimeJson(strFunc.uncomma(detail_row.a_watch_sec));
        watch_time_str=watch_time_obj.i+":"+watch_time_obj.s;
        if(watch_time_obj.h!="00"){
          watch_time_str=watch_time_obj.h+":"+watch_time_obj.i+":"+watch_time_obj.s;
        }
        video_result_data.watch_time_str=watch_time_str;
      }
    }
    return video_result_data;
  };
  
  const set_init_data=(inOptObj:any)=>{
    let optObj={
      row_data:row_data,
      ...inOptObj
    };

    if(optObj["row_data"].video_arr&&optObj["row_data"].video_arr.length>0){
      set_video_info_action(optObj["row_data"].video_arr[0]);
      let tmp_section_seq_arr=[];
      for(let i=0;i<optObj["row_data"].video_arr.length;i++){
        tmp_section_seq_arr.push(optObj["row_data"].video_arr[i].a_section_seq);
      }
      set_open_sections([
        ...tmp_section_seq_arr
      ]);
      getUseDetailList(optObj["row_data"]);
    }
  };

  const getUseDetailList=(cose_info:any)=>{
    if(cose_info.a_seq==undefined||cose_info.a_seq==""){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_seq)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    let detail_form_json:any={
      "select_mcomp_seq":iam_mcomp_seq,
      "select_mcomp_user_seq":iam_mcomp_user_seq,
      "s_corse_seq":cose_info.a_seq,
      "s_stu_seq":iam_mcomp_user_seq,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    detail_form_json["api_key"]=comp_api_key;
    detail_form_json["user_seq"]=iam_mcomp_user_seq;
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use_det/list',detail_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_detail_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };
  const getUseDetailRowByVideo=(video_info:any)=>{
    let detail_row=null;
    for(let i=0;i<detail_arr.length;i++){
      if(detail_arr[i]["a_video_seq"]==video_info.a_seq){
        detail_row=detail_arr[i];
      }
    }
    //없으면 생성
    if(detail_row==null){
      if(strFunc.is_empty(iam_mcomp_user_seq)){
        return false;
      }
      detail_row={
        "select_mcomp_seq":iam_mcomp_seq,
        "select_mcomp_user_seq":iam_mcomp_user_seq,
        "a_corse_seq":row_data.a_seq,
        "a_stu_seq":iam_mcomp_user_seq,
        "a_video_seq":video_info.a_seq,
        "a_video_is_complete":"",
        "a_watch_sec":0,
        "a_total_sec":video_info.a_total_sec,
        "a_start_date":DateFunc.get_date_format(new Date(),"Y-m-d h:i:s"),
        "a_end_date":"",
      };
    }
    return detail_row;
  };

  const saveWatchTimeOfVideo=()=>{
    if(detail_arr.length==0){
      return false;
    }
    if(row_data.a_seq==undefined||row_data.a_seq==""){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_seq)){
      return false;
    }
    //데이터세팅
    let save_detail_arr=[...detail_arr];
    for(let i=0;i<save_detail_arr.length;i++){
      save_detail_arr[i]["a_corse_seq"]=row_data.a_seq;
      save_detail_arr[i]["a_stu_seq"]=iam_mcomp_user_seq;
    }
    //저장
    let save_detail_form_json:any={
      "select_mcomp_seq":iam_mcomp_seq,
      "select_mcomp_user_seq":iam_mcomp_user_seq,
      "detail_arr":save_detail_arr,
      "corse_seq":row_data.a_seq,
    };
    save_detail_form_json["api_key"]=comp_api_key;
    save_detail_form_json["user_seq"]=iam_mcomp_user_seq;
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use_det/save_video_detail',
    save_detail_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const set_video_info_action=(in_video_info:any)=>{
    in_video_info.section_name="";
    if(row_data.section_arr){
      for(let i=0;i<row_data.section_arr.length;i++){
        if(row_data.section_arr[i]["a_seq"]==in_video_info.a_section_seq){
          in_video_info.section_name=row_data.section_arr[i]["a_title"];
        }
      }
    }
    set_video_info(in_video_info);
  };

  const onClickVideoRow=(in_video_info:any)=>{
    set_is_play(false);
    set_video_info_action(in_video_info);
    let stu_container=document.getElementById("stu_container");
    if(stu_container){
      stu_container.scrollTop=0;
    }
  };
  const onPreNextVideoClick=(arrow_str:"pre"|"after")=>{
    let now_video_idx=0;
    let move_idx=0;
    if(row_data.video_arr&&video_info){
      for(let i=0;i<row_data.video_arr.length;i++){
        if(row_data.video_arr[i].a_seq==video_info.a_seq){
          now_video_idx=i;
        }
      }
    }
    move_idx=now_video_idx;
    if(arrow_str=="pre"){
      move_idx--;
      if(move_idx<0){
        move_idx=0;
      }
    }else if(arrow_str=="after"){
      move_idx++;
      if(move_idx>=row_data.video_arr.length){
        move_idx=row_data.video_arr.length-1;
      }
    }

    if(row_data.video_arr){
      onClickVideoRow({...row_data.video_arr[move_idx]});
    }
  };
  const onClickSectionHead=(in_section_info:any)=>{
    if(strFunc.str_in_array(in_section_info.a_seq,open_sections)==-1){
      set_open_sections([
        ...open_sections,
        ...[in_section_info.a_seq]
      ]);
    }else{
      let tmp_open_sections=[];
      for(let i=0;i<open_sections.length;i++){
        if(open_sections[i]!=in_section_info.a_seq){
          tmp_open_sections.push(open_sections[i]);
        }
      }
      set_open_sections(tmp_open_sections);
    }
  };

  //비디오태그
  const getVideoListTag=(section_seq:number)=>{
    let video_tags:any="";
    let video_arr=[];
    if(row_data.video_arr){
      for(let i=0;i<row_data.video_arr.length;i++){
        if(row_data.video_arr[i]["a_section_seq"]==section_seq){
          video_arr.push(row_data.video_arr[i]);
        }
      }
    }
    video_tags=video_arr.map((item:any,idx:number)=>{
      let is_select_video=false;
      if(video_info["a_seq"]==item.a_seq){
        is_select_video=true;
      }
      let row_video_item_style:any={};
      let active_class_str="";
      if(is_select_video){
        active_class_str+=" "+Style.video_item_active;
      }
      let video_time_obj:any=strFunc.secondsToTimeJson(strFunc.uncomma(item.a_total_sec));
      let video_time_str=video_time_obj.i+":"+video_time_obj.s;
      if(video_time_obj.h!="00"){
        video_time_str=video_time_obj.h+":"+video_time_obj.i+":"+video_time_obj.s;
      }

      //detail
      let detail_row=null;
      for(let i=0;i<detail_arr.length;i++){
        if(detail_arr[i]["a_video_seq"]==item.a_seq){
          detail_row=detail_arr[i];
        }
      }
      let per_num:any=0;
      let watch_time_str="00:00";
      let complete_class_str="";
      if(detail_row!=null){
        detail_row.a_total_sec=parseInt(strFunc.uncomma(detail_row.a_total_sec));
        detail_row.a_watch_sec=parseInt(strFunc.uncomma(detail_row.a_watch_sec));
        if(detail_row.a_total_sec!=0&&detail_row.a_watch_sec!=0){
          per_num=strFunc.uncomma((detail_row.a_watch_sec/detail_row.a_total_sec)*100);
          per_num=parseInt(per_num);
        }
        if(detail_row.a_video_is_complete=="1"){
          per_num=100;
        }
        if(per_num==100){
          complete_class_str=" "+Style.video_complete;
        }
        let watch_time_obj:any=strFunc.secondsToTimeJson(strFunc.uncomma(detail_row.a_watch_sec));
        watch_time_str=watch_time_obj.i+":"+watch_time_obj.s;
        if(watch_time_obj.h!="00"){
          watch_time_str=watch_time_obj.h+":"+watch_time_obj.i+":"+watch_time_obj.s;
        }
      }
      let start_end_date_str="";
      if(detail_row!=null){
        if(!strFunc.is_empty(detail_row.a_start_date)){
          start_end_date_str=detail_row.a_start_date.substring(0,10);
        }
        if(!strFunc.is_empty(detail_row.a_start_date)){
          start_end_date_str+="~"+detail_row.a_end_date.substring(0,10);
        }
      }
      let video_check_img=video_check2_img;
      if(is_select_video){
        video_check_img=video_check1_img;
      }
      return (
        <li key={idx} className={Style.video_item_style+complete_class_str+active_class_str} onClick={()=>{onClickVideoRow(item);}}>
          <div className={Style.video_item_title_wrap}>
            {idx+1+". "} {item.a_title} 
          </div>
          <div className={Style.video_item_per_wrap}>
            <div className={Style.video_item_per_bar} style={{width:per_num+"%"}}></div>
          </div>
          <span className={Style.video_item_per_text_pre_ico}>
            <img src={video_check_img} style={{width:"100%"}} />
          </span>
          <span className={Style.video_item_per_text}>
            {per_num}%
          </span>
          <span className={Style.video_item_total_time}>
            {watch_time_str} / {video_time_str}
          </span>
          <span className={Style.video_item_start_end_date}>
            {start_end_date_str}
          </span>
        </li>
      );
    });

    return video_tags;
  };

  //섹션
  let section_tags="";
  if(row_data.section_arr){
    section_tags=row_data.section_arr.map((item:any,idx:number)=>{
      let is_view_section=false;
      if(strFunc.str_in_array(item.a_seq,open_sections)!=-1){
        is_view_section=true;
      }
      return (
        <div key={idx} className={Style.section_wrap_style} >
          <div className={Style.section_head_style} onClick={()=>{onClickSectionHead(item);}}>
            {item.a_title}
          </div>
          <ul>
            {is_view_section&&
              getVideoListTag(item.a_seq)
            }
          </ul>
        </div>
      );
    });
  }

  let video_url="";
  let video_memo:any="";
  let video_url_sort="";
  let video_detail_row=null;
  let init_seek_data={start:0,end:1};
  if(video_info!=null){
    video_url=video_info.a_url;
    video_memo=video_info.a_memo;
    video_url_sort=video_info.a_url_sort;
    video_detail_row=getUseDetailRowByVideo(video_info);
    init_seek_data.end=parseInt(strFunc.uncomma(video_info.a_total_sec));
    if(video_detail_row!=null){
      init_seek_data.start=parseInt(strFunc.uncomma(video_detail_row.a_watch_sec));
      if(init_seek_data.start>=(init_seek_data.end-3)){
        init_seek_data.start=0;
      }
    }
  }

  let state_str="";
  if(xColumnArr.select_arr["a_state"]){
    let state_select_arr=xColumnArr.select_arr["a_state"];
    for(let i=0;i<state_select_arr.length;i++){
      if(state_select_arr[i]["value"]==row_data.a_state){
        state_str=state_select_arr[i]["text"];
      }
    }
  }

  let now_video_data=getNowVideoData();

  return (
    <div>
      <VideoTabArea></VideoTabArea>
      <div className="stu_con_wrap">
        <div className={Style.category_title_div}>
          {row_data.a_category}
          <button className={Style.top_back_btn} onClick={myProps.on_go_back}>{myProps.back_btn_title}</button>
        </div>
        <div className={Style.content_style} >
          {
            row_data.a_content && row_data.a_content.split('\n').map( (line:string,idx:number) => {
              return (<span key={idx}>{line}<br/></span>)
            })
          }
        </div>
        <div className={Style.left_right_wrap_style+" mt-1"} >
          <div style={{flexGrow:1}}>
            {video_url_sort=="youtube"&&
              <YouTubeArea 
                video_url={video_url}
                init_seek_data={init_seek_data}
                set_is_play={set_is_play}
              />
            }
            {video_url_sort=="vimeo"&&
              <VimeoArea
                video_url={video_url}
                init_seek_data={init_seek_data}
                set_is_play={set_is_play}
              ></VimeoArea>
            }
            <div className={Style.video_title}>
              {row_data.a_title}
              {
                video_info&&video_info.section_name&&
                <span className="ml-1" style={{color:"#aaa"}}>({video_info.section_name})</span>
              }
              <div className={Style.video_title_select_video}>
                <span className={Style.video_title_select_video_pre}>현재: </span>
                {video_info&&
                  (now_video_data.idx+1)+". "+video_info.a_title
                }
              </div>
              <span className={Style.video_title_pre_after_btn_span}>
                <span>
                  (
                  {now_video_data.per_num}%
                  <span className={Style.video_state_span}>{state_str}</span>
                  )
                </span>
                {/* <button className="btn-s btn-dark ml-1" onClick={()=>{onPreNextVideoClick("pre");}}>이전</button>
                <button className="btn-s btn-dark ml-1" onClick={()=>{onPreNextVideoClick("after");}} >다음</button> */}
              </span>
              <span className={Style.video_title_now_time_span}>
                {now_video_data.watch_time_str} / {now_video_data.video_time_str}
              </span>
            </div>
          </div>
          <div style={{minWidth:250,maxWidth:"99vw",marginLeft:1}}>
            {section_tags}
          </div>
        </div>
        <div>
          {
            row_data.a_content && row_data.a_content.split('\n').map( (line:string,idx:number) => {
              return (<span key={idx}>{line}<br/></span>)
            })
          }
        </div>
        <div>
          {
            video_memo && video_memo.split('\n').map( (line:string,idx:number) => {
              return (<span key={idx}>{line}<br/></span>)
            })
          }
        </div>
      </div>
    </div>
  );
}

export default ViewVideoArea;