import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState,useRef,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewCharPopup from "./popup/view_char";
import CharBox from "./char_box";

function BuyCharShopArea(props: any) {
  let myProps = {
    refresh_data:(inData:any)=>{},
    ...props
  };

  let user=useSelector((state:any) => state.user);
  const [char_info_arr,set_char_info_arr]=useState([]);
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "view_char",
    title: "씨앗선택",
    char_info:{},
    y: "0",
    width: "100%",
    height: "100%",
    is_border_radius: false,
  });

  useEffect(() => {
    
    get_char_baseInfo_arr();
  }, [user]);

  const get_char_baseInfo_arr=()=>{
    let form_json_data={
      "order_id":"a_order_num",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/char/char_base/list',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          set_char_info_arr(response.data["data"]["info_arr"]);
        }
      }else{
        
      }
    });
  };

  const open_view_char_popup = (inData: any) => {
    let opt_obj = {
      char_info:{},
      ...inData,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "view_char",
      char_info:opt_obj["char_info"],
    });
  };

  const get_char_box_tags = () => {
    let char_box_tags = char_info_arr.map((item: any, idx: number) => {
      return (
        <CharBox
          key={idx}
          char_info={item}
          open_view_char_popup={open_view_char_popup}
        ></CharBox>
      );
    });
    return char_box_tags;
  };

  let char_list_wrap_style: any = {
    display: "flex",
    gap: 15,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  };

  return (
    <div className="mt-2">
      <div style={{ color: "#7A9E1D" }}>씨앗상점</div>
      <div style={char_list_wrap_style}  >
        {get_char_box_tags()}
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius}
          has_header={false} content_div_style={{ padding: 0 }}>
          {popupData.sort === "view_char" &&
            <ViewCharPopup
              char_info={popupData.char_info}
              callback={myProps.refresh_data}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewCharPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default BuyCharShopArea;