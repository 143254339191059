import MobileLayout from "@/pcomponents/mobile/layout/layout";

function PlanMain(){
  return (
    <MobileLayout>
      맞춤플랜
    </MobileLayout>
  );
}

export default PlanMain;