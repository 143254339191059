import Style from "./css/style.module.css";
import BackCircle from "./back_circle";

function BackGroundArea(props:any){
   let myProps={
    ...props
  };
  var win_w=(window.innerWidth || document.documentElement.clientWidth);
  let back_circle_data_arr=[
    {x:-100,y:-100,w:300,h:300,borderRadius:260,border:65},
    {x:win_w-85,y:120,w:180,h:180,borderRadius:180,border:60},
    {x:170,y:300,w:300,h:300,borderRadius:200,border:70},
  ];
  
  const get_back_circle_tags=()=>{
    let back_circle_tags=back_circle_data_arr.map((item:any,idx:number)=>{
      return (
        <BackCircle key={idx}
          Style={Style}
          circle_data={item}
        ></BackCircle>
      );
    });
    return back_circle_tags;
  };

  return (
  <div className={Style.back_wrap}>
    <div className={Style.back_wrap_con}>
      {get_back_circle_tags()}
      <div className={Style.back_main_con_div}></div>
    </div>
  </div>
  );
};
export default BackGroundArea;