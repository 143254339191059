import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import { useState,useRef,useEffect } from 'react';
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";
import TopArea from "./area/top_area";
import VideoBoxArea from "./area/video_box";
import BottomConArea from "./area/bottom_con";
import BottomButtonArea from "./area/bottom_btn";

function ViewVideoArea(props:any){
   let myProps={
    todo_info:null,
    user_data:{
      iam_mcomp_seq:"",
      iam_mcomp_user_seq:"",
      comp_api_key:"",
    },
    on_go_back:(inData:any)=>{},
    back_btn_title:"목록으로",
    ...props
  };
  let user=useSelector((state:any) => state.user);
  let iam_mcomp_seq = "";
  let iam_mcomp_user_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  if(!strFunc.is_empty(myProps.user_data.iam_mcomp_user_seq)){
    iam_mcomp_seq=myProps.user_data.iam_mcomp_seq;
    iam_mcomp_user_seq=myProps.user_data.iam_mcomp_user_seq;
    comp_api_key=myProps.user_data.comp_api_key;
  }
  const [todo_info,set_todo_info]=useState(myProps.todo_info);
  const [refresh,set_refresh]=useState(false);
  const [is_refreshed,set_is_refreshed]=useState(false);
  const VideoBoxAreaRef=useRef<any>(null);
  
  useEffect(()=>{
    if(strFunc.is_empty(todo_info)){
      myProps.on_go_back();
    }else{
      goRefresh();
    }
  },[user]);

  const goBackUrl=()=>{
    myProps.on_go_back();
  };

  const completeOne = ()=>{
    if(!confirm("완료처리 하시겠습니까?")){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(todo_info.a_seq)){
      alert("데이터가 적절하지 않습니다.");
      return false;
    }
    let row_pri_val=todo_info.a_ymd+","+todo_info.a_seq;
    let json_form_data={
      "todo_seq_arr":[row_pri_val],
      "api_key":comp_api_key,
      "user_seq":iam_mcomp_user_seq
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/complete',json_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        goBackUrl();
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goRefresh=()=>{

    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }

    let row_pri_val=todo_info.a_ymd+","+todo_info.a_seq;
    let form_json_data={
      "s_pri_arr":[row_pri_val],
      "s_addon_excute_arr":"1",
      "s_addon_mcomp_title":"1",
      "s_addon_file_arr":"1",
      "s_addon_par_content":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
      "api_key":comp_api_key,
      "user_seq":iam_mcomp_user_seq
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data["data"]["info_arr"].length>0){
          let tmp_todo_info=response.data["data"]["info_arr"][0];
          //비디오가 아니던가 데이터가 없다면 뒤로가기
          if(tmp_todo_info["a_par_id"]!="video_list"){
            alert("동영상 강의가 아닙니다.");
            goBackUrl();
            return false;
          }
          if(strFunc.is_empty(tmp_todo_info["video_info"])){
            alert("동영상 강의정보가 없습니다.");
            goBackUrl();
            return false;
          }

          //진행시간 업데이트
          let a_time_sec=parseInt(strFunc.uncomma(tmp_todo_info.a_time_sec));
          tmp_todo_info.a_time_sec=a_time_sec;
          set_todo_info(tmp_todo_info);
          set_is_refreshed(true);
          setTimeout(()=>{
            if(VideoBoxAreaRef.current){
              VideoBoxAreaRef.current.set_init_data({
                todo_info:tmp_todo_info
              });
            }
          },100);
        }else{
          alert("조회 내용이 없습니다.");
          goBackUrl();
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const goWriteTodoInfo=(inData:any)=>{
    let opt_obj={
      todo_info:todo_info,
      ...inData
    };

    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }

    let form_json_data={
      data_arr:[opt_obj.todo_info],
      is_update:"1",
      "api_key":comp_api_key,
      "user_seq":iam_mcomp_user_seq
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/write',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        save_detail_video_by_todo_ajax();
        set_todo_info(opt_obj.todo_info);
        set_refresh(!refresh);
      }else{
        alert(response.data["msg"]);
        goBackUrl();
      }
    });
  };

  const save_detail_video_by_todo_ajax=()=>{
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }

    let form_json_data={
      "todo_seq":todo_info["a_ymd"]+","+todo_info["a_seq"],
      "api_key":comp_api_key,
      "user_seq":iam_mcomp_user_seq
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/use_det/save_detail_video_by_todo',
    form_json_data,get_axios_data())
    .then((response) => {
      
    });
  };

  return (
  <MobileLayout container_style={{background:"#FFF",marginTop:0}} 
    container_con_style={{width:"100%"}} 
    has_header={false} >

    <div className={Style.video_page+" noto_sans"}>
      <TopArea
        todo_info={todo_info}
        on_click_back={()=>{goBackUrl();}}
        Style={Style}
      ></TopArea>
      {is_refreshed&&
        <VideoBoxArea
          ref={VideoBoxAreaRef}
          todo_info={todo_info}
          set_todo_info={set_todo_info}
          goWriteTodoInfo={goWriteTodoInfo}
          Style={Style}
        ></VideoBoxArea>
      }
      <BottomConArea
        Style={Style}
        todo_info={todo_info}
      ></BottomConArea>
      <BottomButtonArea
        Style={Style}
        back_btn_title={myProps.back_btn_title}
        on_click_back={()=>{goBackUrl();}}
        completeOne={completeOne}
        todo_info={todo_info}
      ></BottomButtonArea>
    </div>
  </MobileLayout>
  );
};
export default ViewVideoArea;