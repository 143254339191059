import { useState,useRef,useEffect } from 'react';
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

function CenterText(props:any){
  let myProps={
    Style:{},
    todo_info:{},
    elapse_time_sec:0,
    goWriteTodoInfo:(inData:any)=>{},
    onStart:()=>{},
    onStop:()=>{},
    ...props
  };
  const target_amount_input_ref=useRef<HTMLInputElement>(null);
  const target_amount_span_ref=useRef<HTMLSpanElement>(null);
  
  let Style=myProps.Style;
  let todo_info=myProps.todo_info;
  let elapse_time_obj:any=strFunc.secondsToTimeJson(strFunc.uncomma(myProps.elapse_time_sec));
  let amount_sort="개";
  let amount_key="a_amount_num";
  let amount_num=0;
  let max_amount=0;
  if(todo_info.a_success_sort=="time"){
    amount_sort="분";
    amount_key="a_time_sec";
    max_amount=parseInt(strFunc.uncomma(todo_info["a_success_time_sec"]));
    todo_info[amount_key]=myProps.elapse_time_sec;
  }else if(todo_info.a_success_sort=="amount"){
    amount_sort=todo_info.a_amount_sort;
    max_amount=parseInt(strFunc.uncomma(todo_info["a_success_amount"]));
  }
  amount_num=todo_info[amount_key];

  const update_up_down_amount=(up_down:"up"|"down")=>{
    amount_num=parseInt(strFunc.uncomma(amount_num));
    let change_amount=amount_num;
    if(up_down=="up"){
      change_amount=amount_num+1;
    }else{
      change_amount=amount_num-1;
    }
    update_amount_of_todo_info(change_amount);
  };

  const on_change_target_amount=(e:any)=>{
    let value=e.target.value;
    value=parseInt(strFunc.uncomma(value));
    update_amount_of_todo_info(value);
  };

  const update_amount_of_todo_info=(change_amount:number)=>{
    todo_info[amount_key]=change_amount;
    if(amount_num>0){
      if(todo_info["a_is_success"]=="1"){
        todo_info["a_state"]="stop";
        todo_info["a_is_success"]="";
      }
    }

    if(todo_info["a_start_date"].substring(0,10)=="0000-00-00"){
      todo_info["a_start_date"]=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
    }

    if(change_amount>=max_amount){
      todo_info["a_state"]="complete";
      todo_info["a_is_success"]="1";
      todo_info["a_end_date"]=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
      if(todo_info["a_state"]=="ing"){
        myProps.onStop();
      }
    }

    myProps.goWriteTodoInfo({
      todo_info:todo_info
    });
  };

  const display_target_span_input_change=(is_input_display:boolean)=>{
    if(is_input_display){
      if(target_amount_span_ref.current){
        target_amount_span_ref.current.style.display="none";
      }
      if(target_amount_input_ref.current){
        target_amount_input_ref.current.style.display="";
        target_amount_input_ref.current.select();
      }
    }else{
      if(target_amount_span_ref.current){
        target_amount_span_ref.current.style.display="inline-block";
      }
      if(target_amount_input_ref.current){
        target_amount_input_ref.current.style.display="none";
      }
    }
  };

  return (
    <div className={Style.time_center_wrap+" noto_sans"}>
      <div className={Style.time_center_title}>
          <button className="btn-s btn-dark mr-1 px-2" onClick={()=>{update_up_down_amount("down");}}>◁</button>
          목표달성
          <button className="btn-s btn-dark ml-1 px-2" onClick={()=>{update_up_down_amount("up");}}>▷</button>
      </div>
      <div className={Style.time_center_target_text} >
        <span className={Style.time_center_target_amount_span} ref={target_amount_span_ref} 
          onClick={()=>{display_target_span_input_change(true);}}>
          {strFunc.comma(amount_num)}
        </span>
        <input type="text" value={strFunc.comma(amount_num)} className={Style.time_center_target_amount_input}
          onChange={on_change_target_amount} ref={target_amount_input_ref}
          onBlur={()=>{display_target_span_input_change(false);}} style={{display:"none"}}/>
        <span>
          /{max_amount} {amount_sort}
        </span>
      </div>
      <div className={Style.time_center_title}>경과시간</div>
      <div className={Style.time_center_elapse_text}>
        {elapse_time_obj.h+":"+elapse_time_obj.i+":"+elapse_time_obj.s}
      </div>
    </div>
  );
}
export default CenterText;