import LygMath from '@/lib/lyg/math';

class PlusFunc
{
  static get_matter=(inData:any)=>{
    let opt_obj = {
      level:1,
      default_matter:{},
      ...inData
    };
    let default_matter=opt_obj.default_matter;
    let level=opt_obj.level;
    let matter:any = {
      ...default_matter,
      level: level,
      val_arr:[],
      answer:0,
    };
    let val_arr=[];
    if(level==1){
      val_arr.push(LygMath.random(1,10));
      val_arr.push(LygMath.random(1,10));
    }else{
      val_arr.push(LygMath.random(1,Math.pow(10,level)));
      val_arr.push(LygMath.random(1,Math.pow(10,level)));
    }
    let answer=0;
    for(let i=0;i<val_arr.length;i++){
      answer+=val_arr[i];
    }

    matter["val_arr"]=val_arr;
    matter["answer"]=answer;

    return matter;
  };
}
export default PlusFunc;