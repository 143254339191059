import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import Style from "./css/style.module.css";
import avocado_img from "@/img/mobile/char/avocado.png";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';

const PushListArea = forwardRef((props: any, ref) => {
  let myProps = {
    infoArr: [],
    onRowClick: (inRowData: any) => { },
    checked_seq_arr: [],
    set_checked_seq_arr: (inData: any) => { },
    ...props
  };
  let checked_seq_arr = myProps.checked_seq_arr;

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언

  }));

  let infoArr = myProps.infoArr;
  let now_ymd = DateFunc.get_date_format(new Date(), "Y-m-d");

  const add_check_of_arr=(inData:any)=>{
    let opt_obj={
      row_data:{},
      ...inData
    };
    let row_data=opt_obj["row_data"];
    let row_pri_val=row_data["a_ymd"]+","+row_data["a_seq"];
    let new_checked_seq_arr=[...checked_seq_arr];
    if(strFunc.str_in_array(row_pri_val,checked_seq_arr)==-1){
      new_checked_seq_arr.push(row_pri_val);
    }
    myProps.set_checked_seq_arr(new_checked_seq_arr);
  };

  const remove_check_of_arr=(inData:any)=>{
    let opt_obj={
      row_data:{},
      ...inData
    };
    let row_data=opt_obj["row_data"];
    let row_pri_val=row_data["a_ymd"]+","+row_data["a_seq"];
    let new_checked_seq_arr=[];
    for(let i=0;i<checked_seq_arr.length;i++){
      if(checked_seq_arr[i]==row_pri_val){

      }else{
        new_checked_seq_arr.push(checked_seq_arr[i]);
      }
    }
    myProps.set_checked_seq_arr(new_checked_seq_arr);
  };

  const getMsgTags = () => {
    let msg_tags = infoArr.map((item: any, idx: number) => {

      let new_tag: any = (
        <div>
          <span className={Style.msg_new}>N</span>
        </div>
      );
      if(item.a_is_receive!="1"){
        new_tag = (
          <div>
            <span className={Style.msg_new} style={{ opacity:0 }}>v</span>
          </div>
        );
      }else if (item.a_is_read == "1") {
        new_tag = (
          <div>
            <span className={Style.msg_new} style={{ background:"green" }}>v</span>
          </div>
        );
      }

      let sender_name = "";
      let sender_img = avocado_img;
      let receiver_name = "";
      let receiver_img = avocado_img;
      if (item.sender_info) {
        sender_name = item.sender_info.a_user_name;
        if (item.sender_info.profile_arr && item.sender_info.profile_arr.length > 0) {
          if (item.sender_info.profile_arr[0]["is_image"] == "1") {
            //sender_img=item.sender_info.profile_arr[0]["thum_img_url"];
          }
        }
      }
      if (item.receiver_info) {
        receiver_name = item.receiver_info.a_user_name;
        if (item.receiver_info.profile_arr && item.receiver_info.profile_arr.length > 0) {
          if (item.receiver_info.profile_arr[0]["is_image"] == "1") {
            //receiver_img=item.receiver_info.profile_arr[0]["thum_img_url"];
          }
        }
      }

      let profile_img_src = sender_img;
      let user_name: any = (
        <span>
          <span style={{ color: "blue" }}>[보냄]</span>
          {sender_name}
        </span>
      );
      let other_user_name:any = (
        <span className="ml-1">
          <span style={{ color: "gray" }}>[받음]</span>
          {receiver_name}
        </span>
      );
      if (item.a_is_receive == "1") {
        profile_img_src = receiver_img;
        user_name = (
          <span>
            <span style={{ color: "gray" }}>[받음]</span>
            <span>{receiver_name}</span>
          </span>
        );
        other_user_name = "";
      }

      let row_is_check = false;
      let row_pri_val = item.a_ymd + "," + item.a_seq;
      if (strFunc.str_in_array(row_pri_val, checked_seq_arr) != -1) {
        row_is_check = true;
      }

      return (
        <div className={Style.msg_row} key={idx}>
          <label>
            <div className={Style.msg_profile_wrap}>
              <input type="checkbox" checked={row_is_check} 
                onChange={()=>{
                  if(row_is_check){
                    remove_check_of_arr({row_data:item});
                  }else{
                    add_check_of_arr({row_data:item});
                  }
                }}
                style={{ height: 20, width: 20 }} />
            </div>
          </label>
          <div className={Style.msg_content_wrap} onClick={() => { myProps.onRowClick(item); }}>
            <div>
              {user_name}
              {other_user_name}
            </div>
            <div style={{fontSize:12}}>
              {item.a_title.substring(0, 30)}
            </div>
            <div className={Style.msg_content}>
              {item.a_content.substring(0, 15)}
            </div>
          </div>
          <div className={Style.msg_right_wrap}>
            {new_tag}
            <div className={Style.msg_time} >
              {
                now_ymd == item.a_create_date.substring(0, 10) ?
                  <span>{DateFunc.getTimeFormatOfPretty(item.a_create_date)}</span>
                  :
                  <span>{item.a_create_date.substring(0, 10)}</span>
              }
            </div>
          </div>
        </div>
      );
    });
    return msg_tags;
  };

  let msgTags: any = getMsgTags();
  if (msgTags == "") {
    msgTags = (
      <div className="text-center" style={{ lineHeight: "100px", height: 100 }}>
        검색 결과가 없습니다.
      </div>
    );
  }

  return (
    <div className={Style.msg_wrap}>
      {msgTags}
    </div>
  );
});
export default PushListArea;