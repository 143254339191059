function FindHeader(props:any){
   let myProps={
    closePopup:()=>{},
    go_callback:()=>{},
    Style:{},
    ...props
  };
  let Style=myProps.Style;

  return (
  <div className={Style.header_wrap}>
    <button className={Style.header_wrap_btn} onClick={()=>{myProps.closePopup();}}>X</button>
    <div className={Style.header_wrap_center}>학생선택</div>
    <button className={Style.header_wrap_btn} onClick={()=>{myProps.go_callback();}}
      style={{color:"#3FAC96"}}>확인</button>
  </div>
  );
};
export default FindHeader;