import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import TimeHisInput from "@/pcomponents/common/date/time_his";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function TodoWriteArea(props: any) {
  let myProps = {
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    isUpdate: false,
    rowData: {},
    is_comp_todo: false,
    is_comp_stu: false,
    comp_stu_info: null,
    todo_sort: "",
    goBackUrl: () => { },
    callback:()=>{},
    back_btn_text: "뒤로",
    ...props
  };
  let user = useSelector((state: any) => state.user);

  const pageData = {
    "write_btn_text": "등록",
    "confirm_msg": "등록 하시겠습니까?",
  };
  if (myProps.isUpdate) {
    pageData["write_btn_text"] = "수정";
    pageData["confirm_msg"] = "수정 하시겠습니까?";
  }

  let iam_mcomp_seq = "";
  let iam_mcomp_user_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  let default_todo_data_row = {
    "a_ymd": strFunc.str_replace("-", "", myProps.select_date),
    "a_seq": "",
    "a_is_mcomp_public": "1",
    "a_par_mcomp_seq": user.comp_seq,
    "a_sort": "etc",
    "a_par_id": "",
    "a_par_seq": "",
    "a_writer_mcomp_seq": user.comp_seq,
    "a_writer_mcomp_user_seq": user.user_seq,
    "a_writer_seq": user.user_seq,
    "a_writer_name": user.user_name,
    "a_stu_mcomp_seq": user.comp_seq,
    "a_stu_mcomp_user_seq": user.user_seq,
    "a_stu_seq": user.user_seq,
    "a_stu_name": user.user_name,
    "a_start_date": "",
    "a_end_date": "",
    "a_is_reserve": "1",
    "a_reserve_start_date": myProps.select_date,
    "a_reserve_end_date": myProps.select_date,
    "a_success_sort": "amount",
    "a_amount_sort": "개",
    "a_amount_num": 0,
    "a_success_amount": 10,
    "a_success_time_sec": 3600,
    "a_time_sec": 0,
    "a_is_success": "",
    "a_state": "ready",
    "a_subject": "기타",
    "a_title": "할일",
    "a_content": "",
    "a_result_memo": "",
    "a_is_need_photo": "",
  };
  if (myProps.is_comp_todo) {
    default_todo_data_row["a_par_mcomp_seq"] = iam_mcomp_seq;
    default_todo_data_row["a_sort"] = "homework";
    default_todo_data_row["a_writer_mcomp_seq"] = iam_mcomp_seq;
    default_todo_data_row["a_writer_mcomp_user_seq"] = iam_mcomp_user_seq;
    default_todo_data_row["a_stu_mcomp_seq"] = iam_mcomp_seq;
    default_todo_data_row["a_stu_mcomp_user_seq"] = iam_mcomp_user_seq;
  }
  if (!strFunc.is_empty(myProps.todo_sort)) {
    default_todo_data_row["a_sort"] = myProps.todo_sort;
  }
  if (myProps.is_comp_stu && myProps.comp_stu_info) {
    default_todo_data_row["a_stu_mcomp_seq"] = iam_mcomp_seq;
    default_todo_data_row["a_stu_mcomp_user_seq"] = myProps.comp_stu_info["a_seq"];
    default_todo_data_row["a_stu_seq"] = "";
    default_todo_data_row["a_stu_name"] = myProps.comp_stu_info["a_user_name"];
  }
  if (localStorage.todo_default_a_subject) {
    default_todo_data_row["a_subject"] = localStorage.todo_default_a_subject;
    default_todo_data_row["a_title"] = localStorage.todo_default_a_title;
  }
  const [rowData, setRowData] = useState(default_todo_data_row);

  useEffect(() => {
    
    if (myProps.isUpdate) {
      let changeRowData: any = {};
      for (let key in rowData) {
        if (myProps.rowData[key] != undefined) {
          changeRowData[key] = myProps.rowData[key];
        }
      }

      setRowData({
        ...rowData,
        ...changeRowData
      });
    }
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }

    if (name == "a_success_amount") {
      //value=strFunc.uncomma(value);
    }

    setRowData({
      ...rowData,
      ...{ [name]: value }
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_row_data = {
      ...rowData,
      ...{ [key]: date_str }
    };
    if (key == "a_reserve_start_date") {
      change_row_data["a_reserve_end_date"] = date_str;
    }
    setRowData(change_row_data);
  };

  const goWrite = (e: any) => {
    let wRowData = { ...rowData };
    wRowData.a_success_amount = strFunc.uncomma(wRowData.a_success_amount);

    if (wRowData.a_title == "") {
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if (wRowData.a_success_sort == "amount") {
      if (strFunc.is_empty(wRowData.a_success_amount)) {
        alert("수량 입력이 필요합니다.");
        return false;
      }
    }

    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    localStorage.setItem('todo_default_a_subject', wRowData.a_subject);
    localStorage.setItem('todo_default_a_title', wRowData.a_title);

    let formJsonData: any = {
      "data_arr": [wRowData],
      "is_default_val": "1",
      "is_update": "",
    };
    if (myProps.isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    if (myProps.is_comp_todo) {
      if (strFunc.is_empty(comp_api_key)) {
        alert("학원 선택 필요.");
        return false;
      }
      if (strFunc.is_empty(iam_mcomp_user_seq)) {
        alert("학원 선택 필요2.");
        return false;
      }
      formJsonData["api_key"] = comp_api_key;
      formJsonData["api_user_seq"] = iam_mcomp_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          myProps.callback();
          myProps.goBackUrl();
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  return (
    <div>
      <div className="view-table-div mt-1">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            {myProps.is_comp_stu &&
              <tr>
                <th>학생명</th>
                <td colSpan={3}>
                  {rowData.a_stu_name}
                </td>
              </tr>
            }
            {myProps.is_comp_todo &&
              <tr>
                <th>구분</th>
                <td>
                  <select className="row-input" name="a_sort" value={rowData.a_sort} onChange={handleInputChange} >
                    <option value="homework">숙제</option>
                    <option value="study">학습</option>
                    <option value="video">강의</option>
                    <option value="etc">기타</option>
                  </select>
                </td>
                <th>공개</th>
                <td>
                  <select className="row-input" name="a_is_mcomp_public" value={rowData.a_is_mcomp_public} onChange={handleInputChange} >
                    <option value="">비공개</option>
                    <option value="1">공개</option>
                  </select>
                </td>
              </tr>
            }
            <tr>
              <th>과목</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_subject" value={rowData.a_subject}
                  onChange={handleInputChange} placeholder="주제" style={{ width: 90 }} />
                <select className="row-input ml-1" style={{ width: 80 }} onChange={(e) => {
                  setRowData({
                    ...rowData,
                    ...{ "a_subject": e.target.value }
                  });
                }} >
                  <option value="">선택</option>
                  <option value="수학">수학</option>
                  <option value="영어">영어</option>
                  <option value="국어">국어</option>
                  <option value="역사">역사</option>
                  <option value="할일">할일</option>
                  <option value="기타">기타</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_title" value={rowData.a_title}
                  onChange={handleInputChange} placeholder="제목"
                  style={{ width: "100%" }} />
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name={"a_content"}
                  value={rowData.a_content}
                  onChange={handleInputChange}
                  placeholder={"내용"}
                ></TextAreaComponent>
              </td>
            </tr>
            {/* <tr>
                <th>목표구분</th>
                <td>
                  <select className="row-input" name="a_success_sort" value={rowData.a_success_sort} onChange={handleInputChange} >
                    <option value="">없음</option>
                    <option value="time">시간</option>
                    <option value="amount">분량</option>
                  </select>
                </td>
              </tr> */}
            <tr>
              <th>목표량</th>
              <td colSpan={3}>
                <input type="number" className="row-input text-center" name="a_success_amount"
                  value={rowData.a_success_amount} onChange={handleInputChange}
                  onClick={(e: any) => { e.target.select(); }}
                  placeholder="분량" style={{ width: 70 }} />
                <select className="row-input ml-1" name="a_amount_sort"
                  value={rowData.a_amount_sort} onChange={handleInputChange} style={{ width: 70 }} >
                  <option value="페이지">페이지</option>
                  <option value="챕터">챕터</option>
                  <option value="개">개수</option>
                  <option value="문제">문제</option>
                  <option value="단원">단원</option>
                </select>
                <span className="ml-2">
                  <button className="btn-s btn-dark px-3" onClick={() => {
                    let a_success_amount = parseInt(strFunc.uncomma(rowData.a_success_amount));
                    a_success_amount++;
                    setRowData({
                      ...rowData,
                      "a_success_amount": a_success_amount
                    });
                  }}>▲</button>
                  <button className="btn-s btn-dark px-3 ml-1" onClick={() => {
                    let a_success_amount = parseInt(strFunc.uncomma(rowData.a_success_amount));
                    a_success_amount--;
                    if (a_success_amount < 0) {
                      a_success_amount = 0;
                    }
                    setRowData({
                      ...rowData,
                      "a_success_amount": a_success_amount
                    });
                  }}>▼</button>
                </span>
              </td>
            </tr>
            {/* <tr>
                <th>목표시간</th>
                <td>
                  <TimeHisInput
                    is_after_name={true}
                    value={rowData.a_success_time_sec}
                    onChangeData={(value: any) => {
                      handleInputChange({
                        target: {
                          name: "a_success_time_sec",
                          value: value,
                          type: "text",
                          checked: false
                        }
                      });
                    }}
                    input_width={27}
                  ></TimeHisInput>
                </td>
              </tr> */}
            <tr>
              <th>예정일</th>
              <td colSpan={3}>
                <DatePicker
                  selected={rowData.a_reserve_start_date != "" ? new Date(rowData.a_reserve_start_date) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_reserve_start_date", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                />
              </td>
            </tr>
            <tr>
              <th>결과메모</th>
              <td colSpan={3}>
                <TextAreaComponent
                  name={"a_result_memo"}
                  value={rowData.a_result_memo}
                  onChange={handleInputChange}
                  placeholder={"결과메모"}
                ></TextAreaComponent>
              </td>
            </tr>
            {/* <tr>
                <th>마감일</th>
                <td>
                  <DatePicker
                    selected={rowData.a_reserve_end_date != "" ? new Date(rowData.a_reserve_end_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_reserve_end_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </td>
              </tr>
              <tr>
                <th>사진필요</th>
                <td>
                  <select className="row-input" name="a_is_need_photo" value={rowData.a_is_need_photo} onChange={handleInputChange} >
                    <option value="">아니오</option>
                    <option value="1">예</option>
                  </select>
                </td>
              </tr> */}
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.goBackUrl(); }}>{myProps.back_btn_text}</button>
      </div>
    </div>
  );
}
export default TodoWriteArea;