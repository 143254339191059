//import GameScoreFunc from "@/pages/mobile/playground/math/common/func/score_func";
import strFunc from '@/lib/lyg/string';

class GameScoreFunc {
  static get_score_by_game_data = (inData: any) => {
    let opt_obj = {
      game_data: {},
      ...inData
    };
    let game_data = opt_obj.game_data;
    let score = 0;
    let tmp_matter_arr = game_data.matter_arr;
    for (let i = 0; i < tmp_matter_arr.length; i++) {
      let tmp_matter = tmp_matter_arr[i];
      if (tmp_matter.input_answer == tmp_matter.answer) {
        score += tmp_matter.level * 110;
      }
    }

    if (game_data.elapse_msec > 0) {
      let elapse_sec = game_data.elapse_msec / 1000;
      if (elapse_sec > 0) {
        let divide_sec_num=elapse_sec;
        let fail_cnt=game_data.matter_total-game_data.matter_correct_cnt;
        if(fail_cnt>0){
          divide_sec_num=divide_sec_num+fail_cnt*10;
        }
        score = Math.round(score / divide_sec_num);
      } else {
        score = 0;
      }
    } else {
      score = 0;
    }
    return score;
  };
  static get_score_by_char_opt = (inData: any) => {
    let opt_obj = {
      char_opt: {},
      game_data: {},
      ...inData
    };
    let char_opt = opt_obj.char_opt;
    let game_data = opt_obj.game_data;
    let score = 0;
    let key_str = this.get_score_key_of_char_opt({ game_data: game_data });

    if (char_opt[key_str]) {
      score = char_opt[key_str];
    }
    score = parseInt(strFunc.uncomma(score));
    return score;
  };
  static get_score_key_of_char_opt = (inData: any) => {
    let opt_obj = {
      game_data: {},
      ...inData
    };
    let game_data = opt_obj.game_data;
    let key_str = "a_math_plus_score";
    if (game_data.sort == "minus") {
      key_str = "a_math_minus_score";
    } else if (game_data.sort == "multiply") {
      key_str = "a_math_multiply_score";
    } else if (game_data.sort == "divide") {
      key_str = "a_math_divide_score";
    }
    return key_str;
  };
  static get_score_name_of_char_opt = (inData: any) => {
    let opt_obj = {
      game_data: {},
      ...inData
    };
    let game_data = opt_obj.game_data;
    let score_name = "더하기";
    if (game_data.sort == "minus") {
      score_name = "빼기";
    } else if (game_data.sort == "multiply") {
      score_name = "곱하기";
    }else if (game_data.sort == "divide") {
      score_name = "나누기";
    }
    return score_name;
  };
}
export default GameScoreFunc;