import {  useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const SearchArea = (props: any) => {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    xColumnArr: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  const search_time_out_ref=useRef<any>(null);

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const goSearch = () => {
    myProps.list({ ...listOpt });
  };

  return (
    <div className="search-box relative text-center">
      <input type="text" className="search-input" name="s_search_text"
        style={{ width: 130 }} placeholder="이름검색" value={listOpt.s_search_text}
        onChange={handleInputChange} />
      <button className="btn btn-dark ml-1" onClick={() => { goSearch(); }} >검색</button>
    </div>
  );
};

export default SearchArea;