const math_menu_arr:any=[
  {
    name:'더하기',
    url:'/playground/math/plus/basic',
  },
  {
    name:'빼기',
    url:'/playground/math/minus/basic',
  },
  {
    name:'곱하기',
    url:'/playground/math/multy/basic',
  },
  {
    name:'나누기',
    url:'/playground/math/divide/basic',
  }
];

export default math_menu_arr;