import {icon_data_arr,get_icon_data_by_key} from "./data/icon_sort_data";

function SvgIcon(props:any){
   let myProps={
    icon_key:"home",
    is_active:false,
    fill_color:"#8F8F8F",
    active_fill_color:"#3FAC96",
    width:24,
    height:24,
    ...props
  };

  let icon_data=get_icon_data_by_key(myProps.icon_key,icon_data_arr);
  
  let fill_color=myProps.fill_color;
  if(myProps.is_active){
    fill_color=myProps.active_fill_color;
  }

  let view_box_str="0 0 "+icon_data.w+" "+icon_data.h;
  
  let svg_icon_wrap_style:any={
    "display":"inline-block",
    "width":myProps.width,
    "height":myProps.height,
    "textAlign":"center",
    "lineHeight":myProps.height+"px"
  };
  let svg_icon_style={
    "display":"inline-block"
  };

  return (
    <div style={svg_icon_wrap_style}>
      <svg xmlns="http://www.w3.org/2000/svg" 
        width={icon_data.w} 
        height={icon_data.h} 
        viewBox={view_box_str} 
        fill="none"
        style={svg_icon_style} >
        <path 
          fillRule="evenodd" clipRule="evenodd"
          d={icon_data.d} 
          fill={fill_color} fillOpacity="0.8"/>
      </svg>
  </div>
  );
};
export default SvgIcon;
