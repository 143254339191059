import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';

interface TdData {
  is_this_month: boolean;
  year:string,
  month:string,
  day:number,
  day_of_week:number,
  content?:any,
  todo_arr?:any[],
};
function ScheCalendar(props:any){
  let myProps={
    "select_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    set_select_date:(inData:any)=>{},
    onClickTdTag:(inData:any)=>{},
    todo_arr:[],
    ...props
  };
  let select_date=myProps.select_date;
  let select_month_obj=new Date(select_date);
  let todo_arr=myProps.todo_arr;

  const now_date_json=DateFunc.get_date_json(new Date());
  let day_num_list=[0,1,2,3,4,5,6];
  let day_name_list=["일","월","화","수","목","금","토"];
  let pre_month_obj=DateFunc.get_change_date(new Date(select_date.substring(0,7)+"-01"),'month',-1);
  let after_month_obj=DateFunc.get_change_date(new Date(select_date.substring(0,7)+"-01"),'month',+1);
  let pre_month_date=DateFunc.get_date_format(pre_month_obj,"Y-m-01");
  let after_month_date=DateFunc.get_date_format(after_month_obj,"Y-m-01");
  let cal_data:any={
    select_month_json:DateFunc.get_date_json(select_month_obj),
    pre_month_json:DateFunc.get_date_json(pre_month_obj),
    after_month_json:DateFunc.get_date_json(after_month_obj),
    select_month_week_len:5,
  };
  cal_data.select_month_week_len=Math.ceil((cal_data.select_month_json.first_day_of_week+cal_data.select_month_json.last_day)/7);

  const get_col_tags=()=>{
    let col_tags=day_num_list.map((item:any,idx:number)=>{
      return (
        <col key={idx} width="14%"></col>
      );
    });
    return col_tags;
  };
  
  const get_th_tags=()=>{
    let th_tags=day_num_list.map((item:number,idx:number)=>{
      let th_title=day_name_list[item];
      let th_text_class_name="";
      if(idx==0){
        th_text_class_name+=" sche_table_th_text_sun";
      }else if(idx==6){
        th_text_class_name+=" sche_table_th_text_sat";
      }
      return (
        <th key={idx}>
          <div className={th_text_class_name}>{th_title}</div>
        </th>
      );
    });
    return th_tags;
  };

  const get_td_data_arr=()=>{
    let td_data_arr:TdData[]=[];
    let day_num=0;
    let pre_day_num=cal_data.pre_month_json.t-cal_data.select_month_json.first_day_of_week+1;
    let after_day_num=1;
    for(let week_i=0;week_i<cal_data.select_month_week_len;week_i++){
      for(let td_i=0;td_i<7;td_i++){
        if(day_num==0){
          if(cal_data.select_month_json.first_day_of_week==td_i){
            day_num=1;
          }
        }
        //전달
        if(day_num<=0){
          td_data_arr.push({
            "is_this_month":false,
            "year":cal_data.pre_month_json.Y,
            "month":cal_data.pre_month_json.m,
            "day":pre_day_num,
            "day_of_week":td_i,
          });
          pre_day_num++;
        }
        //이번달
        if(day_num>0&&day_num<=cal_data.select_month_json.t){
          td_data_arr.push({
            "is_this_month":true,
            "year":cal_data.select_month_json.Y,
            "month":cal_data.select_month_json.m,
            "day":day_num,
            "day_of_week":td_i,
          });
          day_num++;
        }
        //다음달
        if(day_num>cal_data.select_month_json.t){
          td_data_arr.push({
            "is_this_month":false,
            "year":cal_data.after_month_json.Y,
            "month":cal_data.after_month_json.m,
            "day":after_day_num,
            "day_of_week":td_i,
          });
          after_day_num++;
        }
      }
    }
    
    return td_data_arr;
  };

  const setting_at_td_data_arr=(td_data_arr:any)=>{

    let td_data_len=td_data_arr.length;
    for(let todo_i=0;todo_i<todo_arr.length;todo_i++){
      let todo_info=todo_arr[todo_i];
      let todo_res_start=new Date(todo_info["a_reserve_start_date"].substring(0,10)).getTime();
      let todo_res_end=new Date(todo_info["a_reserve_end_date"].substring(0,10)+" 23:59:59").getTime();
      for(let i=0;i<td_data_len;i++){
        let td_data=td_data_arr[i];

        let td_date_time=new Date(td_data["year"]+"-"+DateFunc.get_digit_str(td_data["month"])+"-"+DateFunc.get_digit_str(td_data["day"])).getTime();
        if(todo_res_start<=td_date_time&&td_date_time<=todo_res_end){
          if(strFunc.is_empty(td_data_arr[i]["todo_arr"])){
            td_data_arr[i]["todo_arr"]=[];
          }
          td_data_arr[i]["todo_arr"]?.push(todo_info);
        }
      }
    }
    for(let i=0;i<td_data_len;i++){
      let td_data=td_data_arr[i];
      let select_date_str=td_data["year"]+"-"+DateFunc.get_digit_str(td_data["month"])+"-"+DateFunc.get_digit_str(td_data["day"]);
      let tmp_select_date_time=new Date(select_date_str).getTime();
      let now_date_time=new Date().getTime();
      let tmp_todo_arr=td_data_arr[i]["todo_arr"];
      if(tmp_todo_arr){
        let tmp_row_tot_data={
          "tot":0,
          "success":0
        };
        for(let j=0;j<tmp_todo_arr.length;j++){
          tmp_row_tot_data["tot"]++;
          if(tmp_todo_arr[j].a_is_success=="1"){
            tmp_row_tot_data["success"]++;
          }
        }
        let tmp_tot_color="#E18685";
        if(tmp_row_tot_data["success"]>0){
          tmp_tot_color="#E0BA7B";
        }
        if(tmp_row_tot_data["success"]==tmp_row_tot_data["tot"]){
          tmp_tot_color="#B0BA3F";
        }
        td_data_arr[i]["content"]=(
          <div style={{position:"relative",color:tmp_tot_color}}>
            {tmp_todo_arr.length>0&&
              tmp_row_tot_data["success"]+"/"+tmp_row_tot_data["tot"]
            }
          </div>
        );
      }
    }

    return td_data_arr;
  };

  const get_td_tags=(inData:any)=>{
    let opt_obj={
      "td_week_data_arr":[],
      ...inData
    };

    let td_tags=opt_obj["td_week_data_arr"].map((item:any,idx:number)=>{
      let td_tag_class_name="sche_table_td";
      if(item.is_this_month
          &&cal_data.select_month_json.m==parseInt(item.month)
          &&cal_data.select_month_json.Y==parseInt(item.year)
          &&cal_data.select_month_json.d==parseInt(item.day)){
            
        td_tag_class_name+=" sche_table_td_title_select_date";
      }
      if(item.is_this_month
          &&cal_data.select_month_json.m==now_date_json.m
          &&cal_data.select_month_json.Y==now_date_json.Y
          &&item.day==parseInt(now_date_json.d)){
          
        td_tag_class_name+=" sche_table_td_title_now_date";
      }

      let td_class_name="sche_table_td_title";
      if(item.is_this_month){
        td_class_name+=" sche_table_td_title_select_month";
      }
      if(item.day_of_week==0){
        td_class_name+=" sche_table_td_title_sun";
      }else if(item.day_of_week==6){
        td_class_name+=" sche_table_td_title_sat";
      }

      let select_date_str=item["year"]+"-"+DateFunc.get_digit_str(item["month"])+"-"+DateFunc.get_digit_str(item["day"]);

      return (
        <td key={idx} className={td_tag_class_name} 
          onClick={()=>{myProps.onClickTdTag({
            ...item,
            select_date_str:select_date_str
          });}}>
          <div className={td_class_name} >
            {item.day}
          </div>
          <div className="sche_table_td_content_wrap">
            {item.content}
          </div>
        </td>
      );
    });
    return td_tags;
  };

  const get_tr_tags=()=>{
    let td_data_arr=get_td_data_arr();
    td_data_arr=setting_at_td_data_arr(td_data_arr);
    let tmp_td_week_arr=[];
    let td_week_arr=[];
    for(let i=0;i<td_data_arr.length;i++){
      if(i%7==0&&i!=0){
        td_week_arr.push([
          ...tmp_td_week_arr
        ]);
        tmp_td_week_arr=[];
      }
      tmp_td_week_arr.push(td_data_arr[i]);
    }
    let tr_tags=td_week_arr.map((item:any,idx:number)=>{
      let td_tags=get_td_tags({
        "td_week_data_arr":item
      });
      return (
        <tr key={idx}>
          {td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div>
      <table className="sche_table">
        <colgroup>
          {get_col_tags()}
        </colgroup>
        <thead>
          <tr>
            {get_th_tags()}
          </tr>
        </thead>
        <tbody>
          {get_tr_tags()}
        </tbody>
      </table>
    </div>
  );
}
export default ScheCalendar;