import strFunc from "@/lib/lyg/string";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function TabArea(props: any) {
  let myProps = {
    page_tab: "daily",
    set_page_tab: (inData: any) => { },
    ...props
  };
  
  return (
    <span className="text-center">
      <SelectBoxBar
        valueTextArr={[
          { value: "daily", text: "일일보고" },
          { value: "counsel", text: "상담" },
        ]}
        value={myProps.page_tab}
        on_change={(inData: any) => {
          if(strFunc.is_empty(inData.value)){
            inData.value=myProps.page_tab;
          }
          myProps.set_page_tab(inData.value);
          strFunc.set_storage("counsel_daily_tab", inData.value);
        }}
      ></SelectBoxBar>
    </span>
  );
};
export default TabArea;