import { useState, useRef, useEffect } from 'react';
import Style from "./css/style.module.css";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector } from 'react-redux';
import StuNameBarArea from "./area/stu_name_bar";
import TodoArea from "./area/todo_area";
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from "./area/search";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import TodoWritePopup from "@/pages/mobile/plan/todo/popup/todo_write";

function ViewTodoStuPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    select_idx: 0,
    info_arr: [],
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    go_write_page: (inData: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const [select_idx, set_select_idx] = useState(myProps.select_idx);
  const [info_arr, set_info_arr] = useState(myProps.info_arr);
  const [todo_search_opt, set_todo_search_opt] = useState({
    "s_date_type": "",
    "s_reserve_start_date": myProps.select_date,
    "s_reserve_end_date": myProps.select_date,
    "s_stu_mcomp_seq": "",
    "s_stu_user_seq": "",
    "order_id": "",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [todo_info_arr, set_todo_info_arr] = useState([]);
  const [refresh, set_refresh] = useState(false);
  let row_data: any = {};
  if (select_idx >= 0 && select_idx < info_arr.length) {
    row_data = info_arr[select_idx];
  }

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "add_attend",

    isUpdate: false,
    rowData: {},
    todo_sort: "",
    select_date: "",
    comp_stu_info: {},

    title: "팝업",
    width: "500px",
    height: "100%",
    y: "0",
    is_border_radius: false,
    select_idx: 0,
    stu_info_arr: [],
  });

  useEffect(() => {
    get_stu_todo_list_by_ajax({});
  }, [user]);

  const go_write_page = (inData: any) => {
    // myProps.go_write_page(inData);
    // myProps.closePopup();
    go_write_plan_page(inData);
  };

  const go_write_plan_page = (inData: any) => {
    let opt_obj = {
      "is_update": false,
      "rowData": null,
      "stu_info_arr": [row_data],
      "todo_sort": "homework",
      "select_date": myProps.select_date,
      ...inData
    };
    let stu_info_arr: any = opt_obj["stu_info_arr"];
    if (opt_obj["is_update"]) {
      if (strFunc.is_empty(opt_obj["rowData"])) {
        alert("수정할 데이터 없음.");
        return false;
      }
    } else {
      if (stu_info_arr.length == 0) {
        alert("학생 선택이 필요합니다.");
        return false;
      }
      if (stu_info_arr.length != 1) {
        alert("한명만 선택해 주세요.");
        return false;
      }
    }

    let comp_stu_info = stu_info_arr[0];
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "write_todo",
      isUpdate: opt_obj["is_update"],
      rowData: opt_obj["rowData"],
      todo_sort: opt_obj["todo_sort"],
      select_date: opt_obj["select_date"],
      comp_stu_info: comp_stu_info,
    });
  };

  const on_change_todo = (inData: any) => {
    let opt_obj = {
      "value": "",
      "name": "",
      "todo_pri_val": "",
      ...inData
    };
    let name = opt_obj["name"];
    let value = opt_obj["value"];

    let tmp_info_arr: any = [...todo_info_arr];
    let info_arr_len = tmp_info_arr.length;
    for (let i = 0; i < info_arr_len; i++) {
      let info = tmp_info_arr[i];
      let row_pri_val = info["a_ymd"] + "," + info["a_seq"];
      if (row_pri_val == opt_obj["todo_pri_val"]) {
        if (name == "a_time_sec" || name == "a_amount_num") {
          value = strFunc.uncomma(value);
        }

        tmp_info_arr[i][name] = value;

        //상태검사
        let row_per: any = TodoStateFunc.get_percent_by_todo_info(info);
        //a_state 상태(ready:준비,ing:하는중,stop:중지,complete:성공)
        let change_state = "";
        let change_is_success = "";
        if (row_per == 0) {
          change_state = "ready";
        } else if (row_per > 0) {
          change_state = "stop";
        }
        if (tmp_info_arr[i]["a_state"] == "stop") {
          change_state = "stop";
        }
        if (row_per >= 100) {
          change_state = "complete";
          change_is_success = "1";
        }

        tmp_info_arr[i]["a_state"] = change_state;
        tmp_info_arr[i]["a_is_success"] = change_is_success;
      }
    }
    set_todo_info_arr(tmp_info_arr);
    // if(info_arr[select_idx]){
    //   let tmp_user_info_arr=info_arr;
    //   tmp_user_info_arr[select_idx].todo_arr=tmp_info_arr;
    //   set_info_arr(tmp_user_info_arr);
    //   set_refresh(!refresh);
    // }
  };

  const get_stu_todo_list_by_ajax = (inData: any) => {
    let search_opt = {
      ...todo_search_opt,
      "s_stu_mcomp_seq": iam_mcomp_seq,
      "s_stu_user_seq": row_data["a_seq"],
      ...inData,
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
    };
    set_todo_search_opt(search_opt);
    if (strFunc.is_empty(row_data["a_seq"])) {
      set_todo_info_arr([]);
      return false;
    }
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      set_todo_info_arr([]);
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/list', search_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_todo_info_arr(response.data["data"]["info_arr"]);
          get_stu_info_select_by_ajax({
            stu_seq:row_data["a_seq"],
            select_date:search_opt.s_reserve_end_date,
          });
        } else {

        }
      });
  };

  const get_stu_info_select_by_ajax=(inData: any)=>{
    let opt_obj={
      stu_seq:"",
      select_date:"",
      ...inData,
    };
    
    let search_opt = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,

      "s_pri_arr":[opt_obj["stu_seq"]],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      s_addon_home_work_file: "1",
      s_addon_home_work_file_start_date: opt_obj["select_date"],
      s_addon_home_work_file_end_date: opt_obj["select_date"],
      s_addon_plan_study_file: "1",
      s_addon_plan_study_file_start_date: opt_obj["select_date"],
      s_addon_plan_study_file_end_date: opt_obj["select_date"],
    };
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', search_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            let select_u_info=response.data["data"]["info_arr"][0];
            let new_user_info_arr=[...info_arr];
            for(let i=0;i<new_user_info_arr.length;i++){
              let tmp_info=new_user_info_arr[i];
              if(tmp_info["a_seq"]==select_u_info["a_seq"]){
                new_user_info_arr[i]={...select_u_info};
              }
            }
            set_info_arr(new_user_info_arr);
          }
        } else {
          
        }
      });
  };

  const on_delete_todo = (inData: any) => {
    let opt_obj = {
      todo_info: null,
      ...inData
    };
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      alert("학원 로그인 정보 없음.");
      return false;
    }
    if (strFunc.is_empty(opt_obj["todo_info"])) {
      alert("데이터가 없습니다.");
      return false;
    }
    if (!confirm("해당 플랜을 삭제 하시겠습니까?")) {
      return false;
    }

    let del_form_json = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
      "data_arr": [opt_obj["todo_info"]],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/delete', del_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          get_stu_todo_list_by_ajax({});
          // myProps.callback({});
          // props.closePopup();
        } else {
          alert(response.data["msg"]);
          props.closePopup();
        }
      });

  };

  const save_todo_arr_by_ajax = () => {
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      alert("학원 로그인 정보 없음.");
      return false;
    }
    if (todo_info_arr.length == 0) {
      alert("저장 할 데이터가 없습니다.");
      return false;
    }
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    let save_form_json = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
      "is_update": "1",
      "data_arr": todo_info_arr,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', save_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          get_stu_todo_list_by_ajax({});
          // myProps.callback({});
          // props.closePopup();
        } else {
          alert("저장 중 오류." + response.data["msg"]);
        }
      });
  };

  const get_td_sort_data_arr = () => {
    let td_sort_data_json: any = {
      "study": {
        "sort": "study",
        "title": "학습",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "homework": {
        "sort": "homework",
        "title": "숙제",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "video": {
        "sort": "video",
        "title": "강의",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "etc": {
        "sort": "etc",
        "title": "기타",
        "percent": 0,
        "count": 0,
        "per_arr": [],
        "todo_info_arr": [],
      }
    };

    let todo_info_arr_len = todo_info_arr.length;
    for (let i = 0; i < todo_info_arr_len; i++) {
      let todo_info = todo_info_arr[i];
      for (let key in td_sort_data_json) {
        if (todo_info["a_sort"] == key) {
          td_sort_data_json[key]["count"]++;
          let row_per: any = TodoStateFunc.get_percent_by_todo_info(todo_info);
          if (todo_info["a_is_success"] == "1") {
            row_per = 100;
          }
          td_sort_data_json[key]["per_arr"].push(row_per);
          //todo_info["percent"]=row_per;
          td_sort_data_json[key]["todo_info_arr"].push(todo_info);
        }
      }
    }
    //평균얻기
    for (let key in td_sort_data_json) {
      let row_sum_per: any = 0;
      let per_arr_len: any = td_sort_data_json[key]["per_arr"].length;
      for (let i = 0; i < per_arr_len; i++) {
        row_sum_per += td_sort_data_json[key]["per_arr"][i];
      }
      if (row_sum_per != 0 && per_arr_len != 0) {
        td_sort_data_json[key]["percent"] = Math.floor(row_sum_per / per_arr_len);
      }
    }

    return td_sort_data_json;
  };

  let td_sort_data_json = get_td_sort_data_arr();
  let td_sort_data_arr: any = [];
  for (let key in td_sort_data_json) {
    if (key == "study" || key == "homework") {
      td_sort_data_arr.push(td_sort_data_json[key]);
    } else if (td_sort_data_json[key]["count"] > 0) {
      td_sort_data_arr.push(td_sort_data_json[key]);
    }
  }

  return (
    <div>
      <StuNameBarArea
        row_data={info_arr[select_idx]}
        info_arr={info_arr}
        select_idx={select_idx}
        set_select_idx={(inData: any) => {
          set_select_idx(inData);
          set_refresh(!refresh);
          if (info_arr[inData]) {
            get_stu_todo_list_by_ajax({ "s_stu_user_seq": info_arr[inData].a_seq });
          } else {
            set_todo_info_arr([]);
          }
        }}
      ></StuNameBarArea>
      <SearchArea
        list_opt={todo_search_opt}
        list={get_stu_todo_list_by_ajax}
      ></SearchArea>

      <TodoArea
        style={Style}
        stu_info={row_data}
        select_date={todo_search_opt["s_reserve_start_date"]}
        callback={() => {
          get_stu_todo_list_by_ajax({});
          myProps.callback({});
        }}
        td_sort_data_arr={td_sort_data_arr}
        on_change_todo={on_change_todo}
        on_delete_todo={on_delete_todo}
        go_write_page={go_write_page}
      ></TodoArea>

      <div className="btn-box-center mt-2">
        {todo_info_arr.length > 0 &&
          <button className="btn btn-dark" onClick={save_todo_arr_by_ajax} >저장</button>
        }
        <button className="btn btn-gray" onClick={() => { myProps.closePopup(); }} >닫기</button>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort == "write_todo" &&
            <TodoWritePopup
              isUpdate={popupData.isUpdate}
              rowData={popupData.rowData}
              todo_sort={popupData.todo_sort}
              select_date={popupData.select_date}
              comp_stu_info={popupData.comp_stu_info}
              is_comp_todo={true}
              is_comp_stu={true}
              callback={() => { get_stu_todo_list_by_ajax({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></TodoWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default ViewTodoStuPopup;