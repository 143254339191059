import strFunc from '@/lib/lyg/string';

function DetailListArea(props: any) {
  let myProps = {
    Style: {},
    trade_info_arr: [],
    count_info: {},
    trade_tot_info: {
      in_debt: 0,
      in_money: 0,
      out_debt: 0,
      out_money: 0,
      remain_money: 0,
    },
    ...props
  };
  let Style = myProps.Style;

  const get_pre_month_remain_money = () => {
    let this_month_remain = parseInt(strFunc.uncomma(myProps.count_info["row_view_remain_total"]));
    let total_month_remain= parseInt(strFunc.uncomma(myProps.trade_tot_info["remain_money"]));
    let pre_month_remain = total_month_remain - this_month_remain;
    return pre_month_remain;
  };

  const get_supply_row_tags = () => {
    let supply_row_tags = myProps.trade_info_arr.map((trade_info: any, idx: number) => {
      let title_pre_str=trade_info["a_subject_detail"];
      let money_color="#15c257";
      let minus_str="";
      if(trade_info["a_inout"]=="in"&&trade_info["a_money_type"]=="money"){
        title_pre_str="납부";
        money_color="blue";
        minus_str="-";
      }
      return (
        <div key={idx} className={Style.supply_row} >
          <div className={Style.supply_row_pre} style={{background:money_color}}>{title_pre_str}</div>
          <div className={Style.supply_row_title}>
            {strFunc.cut_str(trade_info.a_memo, 18, "..")}
          </div>
          <div className={Style.supply_row_money} style={{color:money_color}}>
            {minus_str}
            {strFunc.comma(trade_info.a_money)}
            <span style={{ color: "#666", marginLeft: 2 }}>원</span>
          </div>
        </div>
      );
    });

    return supply_row_tags;
  };

  const get_remain_supply_row_tag = () => {
    let pre_month_remain_money=get_pre_month_remain_money();
    if(pre_month_remain_money==0){
      return "";
    }
    let remain_supply_row_tag = (
      <div className={Style.supply_row} >
        <div className={Style.supply_row_title}>
          전달 이월금액
        </div>
        <div className={Style.supply_row_money} style={{color:"#f00"}}>
          {strFunc.comma(pre_month_remain_money)}
          <span style={{ color: "#666", marginLeft: 2 }}>원</span>
        </div>
      </div>
    );

    return remain_supply_row_tag;
  };

  return (
    <div className={Style.supply_row_list}>
      {get_supply_row_tags()}
      {get_remain_supply_row_tag()}
    </div>
  );
};
export default DetailListArea;