function StudyDetailBox(props: any) {
  let myProps = {
    info: null,
    Style: {},
    section_info: null,
    row_num: 0,
    study_detail: {},
    go_detail_view: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;
  let section_info = myProps.section_info;
  let row_num = myProps.row_num;
  let study_detail = myProps.study_detail;

  let map_detail:any=null;
  if(info.detail_arr&&info.detail_arr.length>0){
    for(let i=0;i<info.detail_arr.length;i++){
      let row_detail = info.detail_arr[i];
      if(section_info["a_title"]==row_detail["a_section_name"]
        &&study_detail["a_title"]==row_detail["a_unit_name"]){
          map_detail=row_detail;
      }
    }
  }

  let right_detail_text="없음";
  let right_detail_style:any={color:"gray"};
  if(map_detail!=null){
    right_detail_text="미완료";
    right_detail_style["color"]="orange";
    if(map_detail["a_is_success"]=="1"){
      right_detail_style["color"]="blue";
      right_detail_text="완료";
    }
  }

  return (
    <div className={Style.study_detail_box} 
      onClick={()=>{
        myProps.go_detail_view({
          section_name:section_info["a_title"],
          unit_name:study_detail["a_title"],
          study_detail_info:study_detail,
        });
      }}>
      {row_num + 1}. {study_detail["a_title"]}
      <div className={Style.study_detail_right}>
        <span style={right_detail_style}>
          {right_detail_text}
        </span>
      </div>
    </div>
  );
};
export default StudyDetailBox;