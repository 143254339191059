import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useSelector } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import AttendStuCalendarArea from "@/pcomponents/common/content/attend/calendar";

function AttendCalendarPage() {
  let user = useSelector((state: any) => state.user);
  let iam_user_data=UserFunc.get_iam_mcomp_data({user:user});

  let user_data = {
    iam_mcomp_seq: iam_user_data.iam_mcomp_seq,
    iam_mcomp_user_seq: iam_user_data.iam_mcomp_user_seq,
    comp_api_key: iam_user_data.comp_api_key,
  };
  return (
    <MobileLayout>
      {user_data.iam_mcomp_user_seq &&
        <AttendStuCalendarArea
          user_data={user_data}
          stu_seq={user_data.iam_mcomp_user_seq}
        ></AttendStuCalendarArea>
      }
    </MobileLayout>
  );
}

export default AttendCalendarPage;