
import { RouterProvider } from "react-router-dom";
import router from "@/router/index";
import "react-datepicker/dist/react-datepicker.css";
import window_global from "@/lib/mobile/i_web_script";

function InitApp() {
  return (
    <RouterProvider router={router} />
  );
}

export default InitApp;
