//import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import strFunc from '@/lib/lyg/string';
import TableHeadArea from "./area/head";
import TableBodyArea from "./area/body";

const ListComponent=forwardRef((props:any, ref) => {
  let myProps:any={
    "infoArr":[],//[{"a_ymd":"20221212","a_seq":"1","a_filename":"파일명","is_active":false}]
    "xColumnArr":{
      "x_column_list_arr":[]//[{"key":"a_ymd","name":"board_ymd","width":"100","is_show":"1"}]
    },
    "gridOpt":{
      
    },
    ...props
  };
  var win_w=(window.innerWidth || document.documentElement.clientWidth);
  const listTableRef=useRef<any>(null);
  const [xColumnArr,setXColumnArrMain]=useState(myProps.xColumnArr);
  const [max_width,set_max_width]= useState(myProps.gridOpt.max_width?myProps.gridOpt.max_width:win_w);
  const [gridOpt,setGridOpt]=useState({
    isGrid:false,
    is_add_idx_num:true,
    is_add_checkbox:true,
    is_fix_width:false,
    width:"100%",
    height:"60vh",
    max_width:max_width,
    is_auto_max_width:false,
    td_align:"left",//center,right
    table_align:"left",//center,right
    onGridReady:()=>{},
    rowOnClick:(rowData:any,idx:number,e:any)=>{},
    onSelectChange:(select_info_arr:any)=>{},
    onClickTh:(key:string,e:any)=>{},
    class_name_json:{
      table_div:"list-table-div",
      td_div:"row-col-div",
      input_check:"chk_row",
      row_input:"row-input",
      data_row_tr:"data_row_tr",
      active_row:"active",
      empty_con_div:"list_empty_content_div",
    },
    "onChangeInput":(params:any)=>{
      /*
      {
        "rowData":rowData,
        "row_num":row_num,
        "key":key,
        "value":row_val,
        "pre_value":"",
      }
      */
     return params;
    },
    "customCellRenderer":(params:any)=>{
      /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "origin_val":origin_val,
      }
      */
      return params.value;
    },
    "customGridCellRenderer":(params:any)=>{
      /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val,
        "origin_val":origin_val,
        "input_tag":input_tag,
        "onChangeInput":onChangeInput,
      }
      */
      return params.input_tag;
    },
    "customThCellRenderer":(params:any)=>{
      /*
      {
        "key":key,
        "name":name,
        "head_str":head_str,
      }
      */
      return params.head_str;
    },
    ...myProps.gridOpt
  });
  const [isGrid,seIsGridReal]=useState(gridOpt.isGrid);
  const TableHeadAreaRef=useRef<any>(null);
  const TableBodyAreaRef=useRef<any>(null);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    getSelectedRows,getRows,setInfoArr,
    seIsGrid,getIsGrid,setXColumnArr,
    setGridOpt,set_active_row_arr,checkAllCustom
  }));

  useEffect(() => {
    gridOpt.onGridReady();
  },[]);

  const seIsGrid=(is_grid:boolean)=>{
    seIsGridReal(is_grid);
    TableBodyAreaRef.current.seIsGrid(is_grid);
  };

  const setXColumnArr=(inData:any)=>{
    setXColumnArrMain(inData);
    TableBodyAreaRef.current.setXColumnArr(inData);
  };

  const setInfoArr=(infoArr:[])=>{
    TableBodyAreaRef.current.setInitInfoArr(infoArr);
  };

  const set_active_row_arr=(inData:any)=>{
    TableBodyAreaRef.current.set_active_row_arr(inData);
  };
  
  const getSelectedRows=()=>{
    return TableBodyAreaRef.current.getSelectedRows();
  };
  const getRows=()=>{
    return TableBodyAreaRef.current.getRows();
  };

  const checkAll=(e:any)=>{
    TableBodyAreaRef.current.checkAll(e.target.checked);
  };
  const checkAllCustom=(is_checked:boolean)=>{
    TableBodyAreaRef.current.checkAll(is_checked);
  };

  const getListColumnDefs=()=>{
    let tmpColDefs=[];
    if(gridOpt.is_add_checkbox){
      tmpColDefs.push({"key":"checkbox",
        "name":"checkbox",
        "width":"30","is_show":"1"});
    }
    if(gridOpt.is_add_idx_num){
      tmpColDefs.push({"key":"idx_num","name":"No.","width":"60","is_show":"1"});
    }
    let addon_defs_list=xColumnArr.x_column_list_arr;
    for(let i=0;i<addon_defs_list.length;i++){
      if(addon_defs_list[i]["is_show"]==undefined){
        addon_defs_list[i]["is_show"]=true;
      }
    }
    tmpColDefs=[
      ...tmpColDefs,
      ...addon_defs_list
    ];
    return tmpColDefs;
  };

  const getIsGrid=()=>{
    return isGrid;
  };

  const onscrollLeft=(left_num:number)=>{
    TableHeadAreaRef.current.setScrollLeft(left_num);
  };

  const updateColumnMaxWidth=(col_max_width_arr:[])=>{
    TableBodyAreaRef.current.setColumnMaxWidth(col_max_width_arr);
  };

  let colDefs=getListColumnDefs();

  return (
    <div className={gridOpt.class_name_json.table_div} ref={listTableRef} style={{maxWidth:gridOpt.max_width}} >
      <TableHeadArea
        ref={TableHeadAreaRef}
        gridOpt={gridOpt}
        colDefs={colDefs}
        checkAll={checkAll}
        customThCellRenderer={gridOpt.customThCellRenderer}
        updateColumnMaxWidth={updateColumnMaxWidth}
      ></TableHeadArea>
      <TableBodyArea
        ref={TableBodyAreaRef}
        gridOpt={gridOpt}
        colDefs={colDefs}
        infoArr={myProps.infoArr}
        isGrid={isGrid}
        xColumnArr={xColumnArr}
        onscrollLeft={onscrollLeft}
        onChangeInput={gridOpt.onChangeInput}
        customCellRenderer={gridOpt.customCellRenderer}
        customGridCellRenderer={gridOpt.customGridCellRenderer}
      ></TableBodyArea>
    </div>
  );
});

export default ListComponent;
