import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useState, useRef, useEffect } from 'react';

function AttendMainPage() {
  const countRef = useRef(0);
  const [count, set_count] = useState(0);

  useEffect(() => {
    const count_loop = setInterval(countInterval, 1000);
    return () => {
      clearInterval(count_loop);
    };
  }, []);

  const countInterval = () => {
    countRef.current++;
    set_count(countRef.current);
  };
  return (
    <MobileLayout>
      <div>
        {/* 카운트 {count} */}
      </div>
    </MobileLayout>
  );
}

export default AttendMainPage;