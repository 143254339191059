import Style from "./css/style.module.css";
import TimeTableFunc from "./func/time_table_func";
import strFunc from "@/lib/lyg/string";
import TdBoxArea from "./content/td_box";

function StuTimeTableArea(props: any) {
  let myProps = {
    lecture_arr: [],
    teacher_arr: [],
    room_arr: [],
    is_teacher_nickname: false,
    split_height: 30,
    is_show_teacher_name: false,
    is_show_time_split: true,
    ...props
  };
  let table_option = TimeTableFunc.get_table_option_by_lecture_arr({
    lecture_arr: myProps.lecture_arr,
    teacher_arr: myProps.teacher_arr,
    room_arr: myProps.room_arr,
    is_teacher_nickname: myProps.is_teacher_nickname,
  });
  let td_box_style = { height: myProps.split_height, lineHeight: myProps.split_height + "px" };

  const getThTrTag = () => {
    let th_tr_tag = (
      <tr>
        {myProps.is_show_time_split &&
          <th>
            <div className={Style.time_table_th_box} >
              시간
            </div>
          </th>
        }
        {
          table_option.table_th_arr.map((th_data: any, day_idx: number) => {
            if (th_data.is_view == false) {
              return "";
            }
            return (
              <th key={day_idx} colSpan={th_data.col_span}>
                <div className={Style.time_table_th_box}>
                  {th_data.title}
                </div>
              </th>
            );
          })
        }
      </tr>
    );
    return th_tr_tag;
  };

  const getBodyTdTag = (inData: any) => {
    let opt_obj = {
      row_time_min: 0,
      row_num: 0,
      ...inData
    };
    let row_num = opt_obj["row_num"];

    let td_tags = table_option.table_tr_td_arr[row_num].map((tr_td_row: any, day_idx: number) => {
      if (tr_td_row["is_view"] == false) {
        return "";
      }
      return (
        <TdBoxArea
          key={day_idx}
          tr_td_row={tr_td_row}
          Style={Style}
          is_show_teacher_name={myProps.is_show_teacher_name}
        ></TdBoxArea>
      );
    });
    return td_tags;
  };

  const getBodyTrTag = () => {
    let split_time_m_arr = table_option.split_time_m_arr;
    let body_tr_tags = split_time_m_arr.map((item: any, idx: number) => {
      let rowt_start_json = strFunc.secondsToTimeJson(item * 60);
      let rowt_end_json = strFunc.secondsToTimeJson((item + table_option.split_term) * 60);
      let td_tags = getBodyTdTag({
        row_time_min: item,
        row_num: idx,
      });
      return (
        <tr key={idx}>
          {myProps.is_show_time_split &&
            <td>
              <div className={Style.time_table_td_time_box} style={td_box_style} >
                {rowt_start_json["h"]}:{rowt_start_json["i"]}
                {/* ~ {rowt_end_json["h"]}:{rowt_end_json["i"]} */}
              </div>
            </td>
          }
          {td_tags}
        </tr>
      );
    });

    return body_tr_tags;
  };

  return (
    <div className={Style.time_table_wrap}>
      <table>
        <thead>
          {getThTrTag()}
        </thead>
        <tbody>
          {getBodyTrTag()}
        </tbody>
      </table>
    </div>
  );
};
export default StuTimeTableArea;