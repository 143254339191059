import { useState,useRef,useEffect } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useNavigate } from 'react-router-dom';
import strFunc from '@/lib/lyg/string';
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';

function LoginByUrl(){
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    
    const params = new URLSearchParams(window.location.search);
    let comp_seq = params.get("comp_seq");
    let user_id = params.get("user_id");
    get_mcomp_info_by_ajax({
      comp_seq:comp_seq,
      user_id:user_id,
    });
  },[]);

  const get_mcomp_info_by_ajax=(inData:any)=>{
    let opt_obj={
      comp_seq:"",
      user_id:"",
      ...inData
    };
    if(strFunc.is_empty(opt_obj["comp_seq"])){
      navigate("/mobile");
      return false;
    }
    if(strFunc.is_empty(opt_obj["user_id"])){
      navigate("/mobile");
      return false;
    }
    let form_json_data={
      "s_pri_arr":[opt_obj["comp_seq"]],
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/mcomp/list_front', form_json_data,get_axios_data())
    .then((response) => {
      if (response.data["result"] == "true") {
        if(response.data["data"]["info_arr"].length==1){
          let mcomp_info=response.data["data"]["info_arr"][0];
          go_login_comp_ajax({
            comp_id:mcomp_info["a_id"],
            user_id:opt_obj["user_id"],
          });
        }else{
          alert(response.data["msg"]);
          navigate("/mobile");
        }
      } else {
        alert(response.data["msg"]);
        navigate("/mobile");
      }
    });
  };

  const go_login_comp_ajax=(inData:any)=>{
    let opt_obj={
      comp_id:"",
      user_id:"",
      ...inData
    };
    let login_form_data={
      comp_id:opt_obj.comp_id,
      id:opt_obj.user_id,
      is_no_pw_login:"1",
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/basic/user/login',login_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        localStorage.setItem('login_comp_id', strFunc.base64Encode(opt_obj.comp_id));
        localStorage.setItem('login_id', strFunc.base64Encode(opt_obj.user_id));
        if(response.data.data["token_id"]){
          localStorage.setItem('intoyou_token', response.data.data["token_id"]);
          var client_info=response.data.data["client_info"];
          dispatch(setUser(client_info));
          localStorage.sort_data_arr=JSON.stringify(client_info.sort_data);
          //savePushToken();
          setTimeout(()=>{
            navigate("/mobile");
          },1500);
        }else if(response.data.data["comp_user_info"]){
          localStorage.tmp_comp_user_info=JSON.stringify(response.data.data["comp_user_info"]);
          localStorage.tmp_comp_id=opt_obj.comp_id;
          navigate("/mobile/join");
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <div>

    </div>
  );
}
export default LoginByUrl;