import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import { useSelector, useDispatch } from 'react-redux';

const SearchArea = forwardRef((props: any, ref) => {
  let myProps = {
    listOpt: {
      'order_id': 'a_create_date DESC',
      'order_type': '',
      's_date_type': '',
      's_start_date': '',
      's_end_date': '',
      "s_is_receive": "",
      "s_title":"",
    },
    list: (inData: any) => { },
    ...props,
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  let listOpt=myProps.listOpt;


  const onChangeSearchData = (key: string, value: string) => {
    myProps.list({
      "now_page": "1",
      [key]: value,
    });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    myProps.list({
      "now_page": "1",
      [key]: date_str,
    });
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    
  }));

  return (
    <div>
      <div className="text-center">
        <select className="border" value={listOpt.s_is_receive} onChange={(e: any) => {
          onChangeSearchData("s_is_receive", e.target.value);
        }}>
          <option value="">전체</option>
          <option value="1">수신</option>
          <option value="empty">전송</option>
        </select>
        <select className="border ml-1" value={listOpt.s_is_read}
          onChange={(e: any) => {
            onChangeSearchData("s_is_read", e.target.value);
          }}>
          <option value="">전체</option>
          <option value="1">읽음</option>
          <option value="empty">안읽음</option>
        </select>
        <div className="ml-1" style={{ display: "inline-block", "width": 80 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", "width": 80 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn btn-dark ml-2" onClick={() => { myProps.list({}); }}>검색</button>
      </div>
      <div className="text-center">
        <input type="text" value={listOpt.s_title} name="s_title"
          onChange={(e:any)=>{
            onChangeSearchData("s_title", e.target.value);
          }} 
          placeholder='제목'
          style={{width:"70px",paddingLeft:2}}/>
        학원:
        <select className="border" value={listOpt.s_mcomp_seq} name="s_mcomp_seq"
          onChange={(e:any)=>{
            onChangeSearchData("s_mcomp_seq", e.target.value);
          }}
          style={{width:"70px"}}>
            <option value="">전체</option>
            <option value={user.comp_seq}>메인</option>
            {user.comp_link_arr.map((item:any,idx:number)=>{
              return (
                <option key={idx} value={item["a_mcomp_seq"]}>{item["comp_name"]}</option>
              );
            })}
        </select>
        구분:
        <select className="border" value={listOpt.s_par_id} name="s_par_id"
          onChange={(e:any)=>{
            onChangeSearchData("s_par_id", e.target.value);
          }}
          style={{width:"60px"}}>
            <option value="">전체</option>
            <option value="attend">출석</option>
            <option value="study_diary">학습일지</option>
            <option value="month_prescribe">학습처방</option>
            <option value="comment">댓글</option>
        </select>
      </div>
    </div>
  );
});
export default SearchArea;