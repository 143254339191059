import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

function ListItem(props: any) {
  let myProps = {
    user: {},
    info: {},
    form_list: [],
    s_form_seq: "",
    select_stu_grade_arr: [],
    Style: {},
    is_update: false,
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;

  let user = myProps.user;
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
  }

  let default_eval_info = {
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_form_seq": myProps.s_form_seq,
    "a_target_id": "study_title",
    "a_target_seq": "",
    "a_target_name": "",
    "a_target_detail": "",
    "a_stu_seq": info.a_seq,
    "a_stu_name": info.a_user_name,
    "a_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_subject_name": "",
    "a_title": "",
    "a_average_score": 0,
    "a_item_amout": 0,
    "a_comment": "",
    "a_writer_seq": iam_mcomp_user_seq,
    "a_writer_name": user.user_name,
    "form_detail_arr": [],
    "detail_arr": [],
  };
  let default_detail_row_data = {
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_detail_seq: "",
    a_title: "",
    a_score: 0,
    a_max_score: 100,
    a_score_letter: "",
    a_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_order_num: "",
  };
  let eval_info:any = {
    ...default_eval_info
  };
  if (info.eval_arr && info.eval_arr.length > 0) {
    eval_info = {
      ...default_eval_info,
      ...info.eval_arr[0],
    };
  }

  const get_row_data_by_form_arr_data = (inData: any) => {
    let opt_obj: any = {
      row_data: {},
      form_list: [],
      ...inData,
    };

    let change_row_data = opt_obj.row_data;
    let form_info = null;
    for (let i = 0; i < opt_obj.form_list.length; i++) {
      if (opt_obj.form_list[i].a_seq == opt_obj.row_data.a_form_seq) {
        form_info = opt_obj.form_list[i];
      }
    }
    let detail_arr = [];
    if (form_info != null) {
      change_row_data["a_title"] = form_info["a_title"];
      change_row_data["a_subject_name"] = form_info["a_subject"];
      change_row_data["a_target_id"] = form_info["a_target_id"];
      change_row_data["a_target_seq"] = form_info["a_target_seq"];
      change_row_data["a_target_name"] = form_info["a_target_name"];
      change_row_data["a_target_detail"] = form_info["a_target_detail"];
    }
    if (form_info != null && !strFunc.is_empty(form_info.detail_arr)) {
      for (let i = 0; i < form_info.detail_arr.length; i++) {
        let form_d_info = form_info.detail_arr[i];
        let detail_idx_num = i + 1;
        detail_arr.push({
          ...default_detail_row_data,
          "a_title": form_d_info["a_title"],
          "a_detail_seq": detail_idx_num,
          "a_order_num": detail_idx_num,
          "a_max_score": form_d_info["a_max_score"],
        });
      }
    }
    change_row_data.detail_arr = detail_arr;
    if (form_info != null) {
      change_row_data.form_detail_arr = form_info.detail_arr;
    }

    return change_row_data;
  };
  if (strFunc.is_empty(eval_info.detail_arr)) {
    eval_info = get_row_data_by_form_arr_data({
      row_data: { ...eval_info },
      form_list: myProps.form_list,
    });
  }

  //평균얻기
  let average_score_str=0;
  let eval_score_per_arr=[];
  let eval_score_sum=0;
  let eval_average_color="#000";
  for(let i=0;i<eval_info.detail_arr.length;i++){
    let a_score=parseInt(strFunc.uncomma(eval_info.detail_arr[i].a_score));
    let a_max_score=parseInt(strFunc.uncomma(eval_info.detail_arr[i].a_max_score));
    let row_score_per=0;
    if(a_score!=0&&a_max_score!=0){
      row_score_per=Math.floor((a_score/a_max_score)*100);
    }
    eval_score_per_arr.push(row_score_per);
    eval_score_sum+=row_score_per;
  }
  if(eval_score_sum!=0&&eval_score_per_arr.length!=0){
    average_score_str=Math.floor(eval_score_sum/eval_score_per_arr.length);
  }
  if(average_score_str>0){
    eval_average_color="#1d5d90";
  }
  if(average_score_str>=40){
    eval_average_color="#1d908c";
  }
  if(average_score_str>=60){
    eval_average_color="#E0BA7B";
  }
  if(average_score_str>=80){
    eval_average_color="#EBBD70";
  }
  if(average_score_str==100){
    eval_average_color="#B7C528";
  }

  let school_name_str = strFunc.cut_str(info.a_school_name, 2, "");
  let stu_grade_str = info.a_stu_grade;
  if (myProps.select_stu_grade_arr) {
    for (let i = 0; i < myProps.select_stu_grade_arr.length; i++) {
      if (myProps.select_stu_grade_arr[i]["value"] == stu_grade_str) {
        stu_grade_str = myProps.select_stu_grade_arr[i]["text"];
      }
    }
  }

  return (
    <div className={Style.list_item}>
      <div className={Style.header}>
        <div className={Style.header_con}>
          {info.a_user_name}
          <span className="ml-2" style={{ color: "#999" }}>
            {school_name_str + " " + stu_grade_str}
          </span>
        </div>
      </div>
      <div className={Style.eval_wrap}>
        <div className={Style.eval_con}>
          <table className={Style.eval_write_table}>
            <colgroup>
              <col width={"100px"}></col>
              <col width={"80px"}></col>
              <col width={"100px"}></col>
              <col width={"80px"}></col>
            </colgroup>
            <tbody>
              <tr>
                <td>
                  {eval_info.a_title}
                </td>
                <td>
                  {eval_info.a_subject_name}
                </td>
                <td style={{color:eval_average_color}}>
                  평균: {average_score_str}%
                </td>
                <td>
                  항목: {eval_info.detail_arr.length}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ListItem;