//import {get_char_img} from "@/pcomponents/common/content/char/data/char_data";
import orange from  "@/img/mobile/char/orange.png";
import peach from  "@/img/mobile/char/peach.png";
import strawberry from  "@/img/mobile/char/strawberry.png";
import grape from  "@/img/mobile/char/grape.png";
import avocado from  "@/img/mobile/char/avocado.png";
import carrot from  "@/img/mobile/char/carrot_150.png";

export const char_info_arr=[
  {id:"carrot",name:"당근",sub_name:"계획,전략가형",img_src:carrot,cost:1000},
  {id:"orange",name:"오랜지",sub_name:"논리,분석형",img_src:orange,cost:1000},
  {id:"peach",name:"복숭아",sub_name:"정의,외교형",img_src:peach,cost:1000},
  {id:"avocado",name:"아보카도",sub_name:"재주,탐험가형",img_src:avocado,cost:1000},
  {id:"strawberry",name:"딸기",sub_name:"용감,관리자형",img_src:strawberry,cost:2000},
  {id:"grape",name:"포도",sub_name:"창의,예술가형",img_src:grape,cost:5000},
];
export const get_char_img=(inData:any)=>{
  let opt_obj={
    id:"carrot",
    ...inData
  };
  let id=opt_obj["id"];
  let img_src=carrot;
  let key_img_src_json:any={
    "carrot":carrot,
    "orange":orange,
    "peach":peach,
    "avocado":avocado,
    "strawberry":strawberry,
    "grape":grape,
  };
  if(key_img_src_json[id]!=undefined){
    img_src=key_img_src_json[id];
  }
  return img_src;
};