function ImgSlideArea(props:any){
   let myProps={
    Style:{},
    file_arr:[],
    ...props
  };
  let Style=myProps.Style;
  
  const get_img_div_tags=()=>{
    let img_div_tags=myProps.file_arr.map((item:any,idx:number)=>{
      let is_image=item.is_image;
      let img_url="";
      if(is_image=="1"){
        img_url=item.img_url;
      }

      return (
        <div key={idx} className={Style.img_slide_item} title={item.a_oriname}>
          {is_image=="1"?
            <img src={img_url} alt={item.a_oriname}/>
          :
            item.a_oriname
          }
        </div>
      );
    });
    return img_div_tags;
  };

  return (
    <div className={Style.img_slide_wrap}>
      {get_img_div_tags()}
    </div>
  );
};
export default ImgSlideArea;