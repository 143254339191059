import { useState, useRef, useEffect } from 'react';
import StuNameBarArea from "./area/stu_name_bar";
import ImgSlideArea from "./area/img_slide";
import Style from "./css/style.module.css";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import strFunc from '@/lib/lyg/string';

function CounselViewPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    select_idx: 0,
    info_arr: [],
    go_write_page: (inData: any) => { },
    ...props
  };

  const [select_idx, set_select_idx] = useState(myProps.select_idx);
  const [info_arr, set_info_arr] = useState(myProps.info_arr);
  const [refresh, set_refresh] = useState(false);
  let row_data: any = {};
  if (select_idx >= 0 && select_idx < info_arr.length) {
    row_data = info_arr[select_idx];
  }

  let select_arr_stu_grade: any = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let select_arr_eval_score = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];

  const go_write_page = () => {
    myProps.go_write_page({
      info: row_data,
      "isUpdate": true
    });
    myProps.closePopup();
  };

  return (
    <div>
      <StuNameBarArea
        row_data={info_arr[select_idx]}
        info_arr={info_arr}
        select_idx={select_idx}
        set_select_idx={(inData: any) => {
          set_select_idx(inData);
          set_refresh(!refresh);
        }}
        select_arr_stu_grade={select_arr_stu_grade}
      ></StuNameBarArea>
      <div className={Style.center_wrap + " noto_sans"}>
        {(row_data.file_arr && row_data.file_arr.length > 0) &&
          <ImgSlideArea
            file_arr={row_data.file_arr}
          ></ImgSlideArea>
        }
        <div className={Style.title_wrap}>
          {row_data.a_title}
        </div>
        <div className={Style.content_wrap}>
          {
            row_data.a_content && row_data.a_content.split('\n').map((line: string, idx: number) => {
              return (<span key={idx}>{line}<br /></span>)
            })
          }
        </div>
        {!strFunc.is_empty(row_data.a_feedback) &&
          <div className={Style.content_wrap}>
            <div className={Style.box_content_title}>
              피드백
            </div>
            {
              row_data.a_feedback && row_data.a_feedback.split('\n').map((line: string, idx: number) => {
                return (<span key={idx}>{line}<br /></span>)
              })
            }
          </div>
        }

        {!strFunc.is_empty(row_data.a_eval_score) &&
          <div className={Style.content_wrap} >
            <div className={Style.box_content_title}>평가</div>
            <SelectBoxBar
              valueTextArr={select_arr_eval_score}
              value={row_data.a_eval_score}
            ></SelectBoxBar>
          </div>
        }

        {!strFunc.is_empty(row_data.a_reference) &&
          <div className={Style.content_wrap}>
            <div className={Style.box_content_title}>
              참고내용
            </div>
            {
              row_data.a_reference && row_data.a_reference.split('\n').map((line: string, idx: number) => {
                return (<span key={idx}>{line}<br /></span>)
              })
            }
          </div>
        }

        <div style={{ fontSize: 13, color: "#888" }}>
          구분: {row_data.a_kind}
        </div>
        <div style={{ fontSize: 13, color: "#888" }}>
          상담사: {row_data.a_counseler}
        </div>
        <div style={{ fontSize: 13, color: "#888" }}>
          상담일: {row_data.a_counsel_date}
        </div>
        <div style={{ fontSize: 13, color: "#888" }}>
          예약일: {row_data.a_reserve_date}
        </div>
      </div>
      <div className={Style.bottom_wrap + " noto_sans"}>
        <div className={Style.content_con}>
          <div className={Style.button_wrap}>
            <div style={{ lineHeight: "22px", flexGrow: "1" }}>
              <p>작성일</p>
              <p>
                {row_data.a_create_date.substring(0, 10)}
              </p>
            </div>
            <button className={Style.button_wrap_btn} onClick={() => { myProps.closePopup(); }} >닫기</button>
            <button className={Style.button_wrap_btn}
              style={{ background: "#3FAC96" }}
              onClick={() => { go_write_page(); }}>수정하기</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CounselViewPopup;