//import mCallBack from "@/pcomponents/mobile/layout/mobile/callback";
interface ImCallBack{
  callBackTest(msg:string): void;
}
declare global {
  interface Window {
    mCallBack:ImCallBack
  }
}
window.mCallBack={} as ImCallBack;
window.mCallBack.callBackTest=(msg:string)=>{
  
};

export default window.mCallBack;