import { useNavigate } from 'react-router-dom';

function Balloon(props:any){
  let myProps={
    Style:{},
    x:0,
    y:0,
    width:100,
    height:100,
    degree:0,
    url:"/mobile",
    balloon_src:"",
    icon_src:"",
    icon_text:"",
    title:"맞춤플랜",
    badge_num:0,
    ...props
  };
  const navigate = useNavigate();
  let Style=myProps.Style;
  let balloon_wrap_style={
    left:myProps.x-myProps.width/2,
    top:myProps.y-myProps.height/2,
    width:myProps.width,
    height:myProps.height,
  };
  let balloon_ico_style={
    rotate:myProps.degree+"deg",
  };

  return (
    <div className={Style.balloon_wrap} style={balloon_wrap_style} onClick={()=>{navigate(myProps.url);}} >
      <img src={myProps.balloon_src} className={Style.balloon_img} style={balloon_ico_style} />
      <img src={myProps.icon_src} className={Style.balloon_ico} />
      <div className={Style.balloon_icon_text} >{myProps.icon_text}</div>
      <div className={Style.balloon_title} >{myProps.title}</div>
      {myProps.badge_num>0&&
        <div className={Style.balloon_badge} >{myProps.badge_num}</div>
      }
    </div>
  );
}
export default Balloon;