import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
function SelectSlide(props:any){
   let myProps={
    select_arr:[],//[{"value":"","text":"","thum_src":"","data":{}}]
    select_value:"",
    on_click_item:(item:any)=>{

    },
    is_add_empty:true,
    ...props
  };

  const get_added_empty_item_select_arr=(in_select_arr:any[])=>{
    let is_empty_exist=false;
    for(let i=0;i<in_select_arr.length;i++){
      if(strFunc.is_empty(in_select_arr[i]["value"])){
        is_empty_exist=true;
      }
    }
    if(is_empty_exist==false&&myProps.is_add_empty){
      in_select_arr.unshift({
        "value":"",
        "text":"선택없음",
        "thum_src":"",
        "data":{},
      });
    }

    return in_select_arr;
  };
  
  const get_slide_tags=()=>{
    let slide_select_arr=get_added_empty_item_select_arr(myProps.select_arr);
    let slide_tags=slide_select_arr.map((item:any,idx:number)=>{
      let slide_class_name=Style.slide_item;
      if(item.value==myProps.select_value){
        slide_class_name+=" "+Style.active;
      }
      return (
        <div key={idx} className={slide_class_name} title={item.text}
          onClick={()=>{myProps.on_click_item(item);}}>
          {strFunc.cut_str(item.text,8,"..")}
        </div>
      );
    });
    return slide_tags;
  };

  return (
  <div className={Style.slide_wrap}>
    {get_slide_tags()}
  </div>
  );
};
export default SelectSlide;