import strFunc from "@/lib/lyg/string";

function RowBoxArea(props: any) {
  let myProps = {
    row_num: 0,
    info: {},
    Style: {},
    go_open_study_map_detail_popup: (inData: any) => {},
    ...props
  };
  let row_num = myProps.row_num;
  let info = myProps.info;
  let map_pri_val = info["a_study_title_seq"]+","+info["a_stu_seq"];
  let Style = myProps.Style;

  let detail_cnt=0;
  if(info.detail_arr){
    detail_cnt=info.detail_arr.length;
  }

  return (
    <div className={Style.row_box}>
      <table style={{width:"100%"}}>
        <colgroup>
          <col width={"*"}></col>
          <col width={"60px"}></col>
        </colgroup>
        <tbody>
          <tr>
            <td>
              <div className={Style.row_box_title}>
                {row_num + 1}.
                <span className="ml-1">
                  {strFunc.cut_str(info["study_title"], 10,"")}
                </span>
                <span className="ml-1">
                  ({info["a_success_cnt"]}/{info["a_total_cnt"]})
                </span>
                <span className="ml-2" style={{color:"gray",fontSize:18}}>
                  작성: {detail_cnt}
                </span>
              </div>
            </td>
            <td className="text-center">
              <button className={Style.row_box_move_btn}
                onClick={()=>{
                  myProps.go_open_study_map_detail_popup({map_pri_val:map_pri_val});
                }}>선택</button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  );
};
export default RowBoxArea;