//import StuTimeTableFunc from "@/pcomponents/common/content/edu/lecture/time_table/stu_time/func/stu_time_func";

class StuTimeTableFunc
{
  static get_ordered_stu_union_time_arr(inData:any){
    let opt_obj={
      stu_union_time_arr:[],
      ...inData,
    };
    let stu_union_time_arr=opt_obj.stu_union_time_arr;
    let time_hi_arr=[];
    for(let i=0;i<stu_union_time_arr.length;i++){
      let tmp_union_time=stu_union_time_arr[i];
      let tmp_hi=tmp_union_time["a_start_h"]+":"+tmp_union_time["a_start_m"];
      if(time_hi_arr.indexOf(tmp_hi)==-1){
        time_hi_arr.push(tmp_hi);
      }
    }
    time_hi_arr.sort();
    let ordered_stu_union_time_arr=[];
    for(let i=0;i<time_hi_arr.length;i++){
      let tmp_hi=time_hi_arr[i];
      for(let j=0;j<stu_union_time_arr.length;j++){
        let tmp_union_time=stu_union_time_arr[j];
        let tmp_hi2=tmp_union_time["a_start_h"]+":"+tmp_union_time["a_start_m"];
        if(tmp_hi==tmp_hi2){
          ordered_stu_union_time_arr.push(tmp_union_time);
        }
      }
    }
    return ordered_stu_union_time_arr;
  };
}
export default StuTimeTableFunc;