import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function TopTabArea(props:any){
   let myProps={
    select_tab:"",
    set_select_tab:(inData:any)=>{},
    tab_arr:[],
    ...props
  };
  
  return (
  <div>
    <SelectBoxBar
      valueTextArr={myProps.tab_arr}
      name={"select_tab"}
      value={myProps.select_tab}
      on_change={(inData:any)=>{
        myProps.set_select_tab(inData.value);
      }}
    ></SelectBoxBar>
  </div>
  );
};
export default TopTabArea;