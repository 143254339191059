import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import DateFunc from '@/lib/lyg/date_func';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

function ListArea(props: any) {
  let myProps = {
    Style: {},
    infoArr: [],
    user: {},
    go_save_success_by_ajax: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let infoArr = myProps.infoArr;
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });

  let user = myProps.user;
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;

  const on_change_success = (inData: any) => {
    let opt_obj = {
      row_data: {},
      key: "",
      value: "",
      ...inData
    };
    let row_data = opt_obj.row_data;
    let key = opt_obj.key;
    let value = opt_obj.value;
    row_data[key] = value;
    if (key == "a_is_success") {
      if (value == "1") {
        row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
        row_data["a_success_mcomp_user_seq"] = iam_mcomp_user_seq;
        row_data["a_success_main_user_seq"] = user.user_seq;
        row_data["a_success_user_name"] = user.user_name;
      }
    }
    myProps.go_save_success_by_ajax({
      write_info_arr: [row_data],
    });
  };

  const get_info_tags = () => {
    let info_tags = infoArr.map((info: any, idx: number) => {
      let row_style: any = {};
      let is_success = false;
      if (info.a_is_success == "1") {
        is_success = true;
        row_style.background = "#e0ffdc";
      }

      let subject_name = "";
      if (!strFunc.is_empty(info.a_subject_name)) {
        subject_name = "[" + info.a_subject_name + "] ";
      }

      let stu_name = info.a_stu_name;
      let stu_grade_str = "";
      if (info.stu_info) {
        stu_name = info.stu_info.a_user_name;
        stu_grade_str = info.stu_info.a_stu_grade;
        for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
          if (select_stu_grade_option_arr[i].value == stu_grade_str) {
            stu_grade_str = select_stu_grade_option_arr[i].text;
          }
        }
      }

      return (
        <div key={idx} style={row_style} className={Style.stodo_card_box}>
          <div>
            <input type="checkbox" checked={is_success} onChange={() => {
              let confirm_msg = "완료 처리 하시겠습니까?";
              if (is_success) {
                confirm_msg = "미완료 처리 하시겠습니까?";
              }
              if (confirm(confirm_msg)) {
                on_change_success({
                  row_data: info,
                  key: "a_is_success",
                  value: is_success ? "" : "1"
                });
              }
            }} />
            <span className="ml-1" >
              {subject_name}{info.a_title}
            </span>
          </div>
          <div className="pl-4" style={{ color: "#777", fontSize: 13 }}>
            <span style={{ color: "#1e4cff" }}>{info.a_stu_name} {stu_grade_str}</span>
            <span className="mx-1">|</span>
            {info.a_reserve_date}
            <span className="mx-1">|</span>
            {info.a_owner_name}
          </div>
          {info.a_memo &&
            <div className="pl-4" style={{ color: "#777", fontSize: 13 }}>
              {info.a_memo}
            </div>
          }
        </div>
      );
    });

    if (strFunc.is_empty(info_tags)) {
      info_tags = (
        <div style={{ lineHeight: "40px", textAlign: "center" }}>
          내용이 없습니다.
        </div>
      );
    }
    return info_tags;
  };

  return (
    <div className={Style.stodo_list_wrap}>
      {get_info_tags()}
    </div>
  );
};
export default ListArea;