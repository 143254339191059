import { useEffect,useState,useRef,forwardRef,useImperativeHandle  } from 'react';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import CircleArea from "./circle";
import CenterText from "./center_text";

const TimeArea = forwardRef((props:any, ref) => {
  let myProps={
    Style:{},
    todo_info:{},
    set_todo_info:(inData:any)=>{},
    completeOne:()=>{},
    goWriteTodoInfo:(inData:any)=>{},
    goRefresh:()=>{},
    onUpdateTime:()=>{},
    onStart:()=>{},
    onStop:()=>{},
    ...props
  };
  let Style=myProps.Style;
  let todo_info=myProps.todo_info;
  let percent=TodoStateFunc.get_percent_by_todo_info(myProps.todo_info);
  const pre_elapse_time_sec_ref=useRef(
    (todo_info.elapse_time_sec==undefined)?todo_info.a_time_sec:todo_info.elapse_time_sec
  );
  const elapse_time_sec_ref=useRef(pre_elapse_time_sec_ref.current);
  const start_time_msec_ref=useRef(0);
  const plus_time_msec_ref=useRef(0);
  const is_refresh_data_ing_ref=useRef<any>(false);
  const [isPlay,setIsPlay]=useState<any>(false);
  const [refresh,set_refresh]=useState<any>(false);

  useEffect(() => {
    if(myProps.todo_info.a_is_success=="1"){
      setIsPlay(false);
    }else{
      if(myProps.todo_info.a_state=="ing"){
        setIsPlay(true);
      }
    }
  },[myProps.todo_info]);

  useEffect(() => {
    //받아온데이터가 시간이 다르면 초기화
    if(pre_elapse_time_sec_ref.current!=todo_info.elapse_time_sec){
      is_refresh_data_ing_ref.current=false;
      pre_elapse_time_sec_ref.current=(todo_info.elapse_time_sec==undefined)?todo_info.a_time_sec:todo_info.elapse_time_sec;
      elapse_time_sec_ref.current=pre_elapse_time_sec_ref.current;
      start_time_msec_ref.current=0;
      plus_time_msec_ref.current=0;
    }
    const tmpTimeInterval=setInterval(goTimeCountInterval,100);
    return () => clearInterval(tmpTimeInterval);
  },[isPlay,myProps.todo_info]);

  //UI 새로고침
  useEffect(() => {
    const tmpTimeInterval=setInterval(()=>{
      set_refresh(!refresh);
    },100);
    return () => clearInterval(tmpTimeInterval);
  },[refresh]);

  const goTimeCountInterval=()=>{
    if(!isPlay){
      //멈춰있을땐 시작시간 초기화
      start_time_msec_ref.current=0;
    }
    if(isPlay&&todo_info){
      if(start_time_msec_ref.current==0){
        start_time_msec_ref.current=new Date().getTime();
      }
      //진행시간 계산 및 수정
      plus_time_msec_ref.current=new Date().getTime()-start_time_msec_ref.current;
      elapse_time_sec_ref.current=Math.floor(pre_elapse_time_sec_ref.current+(plus_time_msec_ref.current/1000));
      if(pre_elapse_time_sec_ref.current!=elapse_time_sec_ref.current){
        //20초마다 서버에 저장.
        if(elapse_time_sec_ref.current%20==0){
          if(is_refresh_data_ing_ref.current==false){
            myProps.onUpdateTime();
          }
          is_refresh_data_ing_ref.current=true;
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    get_elapse_time_sec
  }));

  const get_elapse_time_sec=()=>{
    return elapse_time_sec_ref.current;
  }

  const onStartEndToggle=()=>{
    if(todo_info["a_state"]=="complete"){
      alert("이미 완료되었습니다.");
      return false;
    }
    if(isPlay){
      myProps.onStop();
    }else{
      myProps.onStart();
    }
    //서버에서 시작처리,스탑처리 하고 응답오는시간 벌려고 딜레이준다.
    setTimeout(()=>{
      setIsPlay(!isPlay);
    },300);
  }

  return (
    <div className={Style.time_wrap}>
      <div className={Style.time_con}>
        <div className={Style.time_top_circle_back}></div>
        <CircleArea
          Style={Style}
          per_num={percent}
        ></CircleArea>
        <div className={Style.time_top_per_wrap}>
          <div className={Style.time_top_per_text}>
            {percent}%
          </div>
        </div>
        <div className={Style.time_start_wrap} >
          <div className={Style.time_start_text} onClick={onStartEndToggle}>
            {isPlay?"Stop":"Start"}
          </div>
        </div>
        <CenterText
          Style={Style}
          todo_info={myProps.todo_info}
          elapse_time_sec={elapse_time_sec_ref.current}
          goWriteTodoInfo={myProps.goWriteTodoInfo}
          onStart={myProps.onStart}
          onStop={myProps.onStop}
        ></CenterText>
      </div>
    </div>
  );
});
export default TimeArea;