import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewPopupContent from "../popup/view";

import SearchArea from "./area/search";
import ListArea from "./area/list";

function ParNoticeListPage() {

  let user = useSelector((state: any) => state.user);
  let user_mcomp_seq_arr: any = [];
  if (user.comp_link_arr) {
    for (let i = 0; i < user.comp_link_arr.length; i++) {
      user_mcomp_seq_arr.push(user.comp_link_arr[i]["a_mcomp_seq"]);
    }
  }
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 50,
    'order_id': 'a_date DESC, a_create_date DESC',
    'order_type': '',
    's_date_type': 'a_date',
    's_start_date': '',
    's_end_date': '',
  });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view",
    rowData: {},
    title: "보기",
    width: "100%",
    height: "80%",
    marginTop: "0px",
  });

  useEffect(() => {
    list({});
  }, []);

  const list = (inData: any) => {
    let tmp_list_opt = {
      ...listOpt,
      ...inData
    };
    setListOpt(tmp_list_opt);

    if (strFunc.is_empty(user_mcomp_seq_arr)) {
      return false;
    }
    tmp_list_opt["s_mcomp_seq"] = user_mcomp_seq_arr;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/parnotice/parnotice/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const openViewPopup = (inData: any) => {
    let opt_obj = {
      row_data: {},
      ...inData
    };
    setPopupData({
      ...popupData,
      "rowData": opt_obj.row_data,
      "isOpen": true,
      "sort": "view",
    });
  };

  return (
    <MobileLayout has_header={false} has_footer={false}>
      <HeaderSimple title={"알림장"}></HeaderSimple>
      <div className="mt-2">
        <SearchArea
          listOpt={listOpt}
          list={list}
        ></SearchArea>
      </div>
      <div className="text-right mt-1">
        {infoArr.length} 건 검색됨.
      </div>
      <div className="mt-1">
        <ListArea
          infoArr={infoArr}
          countInfo={countInfo}
          listOpt={listOpt}
          list={list}
          openViewPopup={openViewPopup}
        ></ListArea>
        <div className="mt-2">
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "view" &&
            <ViewPopupContent
              rowData={popupData.rowData}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              list_refresh={() => { list({}); }}
            >
            </ViewPopupContent>
          }
        </LayerPopup>
      }
    </MobileLayout>
  );
};
export default ParNoticeListPage;