import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useSelector } from 'react-redux';
import Style from "./css/style.module.css";
import { useState,useRef,useEffect } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import ChildDataFunc from "@/pcomponents/common/content/parent/func/child_data";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import PlanListFunc from "@/pages/mobile/manage/plan/list/func/plan_list_func";
import BackGroundArea from "./area/background";
import TopTitleArea from "./area/top_title";
import TopStuSelect from "./area/top_stu_select";
import TodoProgressBar from "./area/todo_progress_bar";
import MenuBoxArrArea from "./area/menu_area";
import TodayScheArea from "./area/today_sche";
import StudyRecentGraph from "./area/study_recent";

function ParentArea(props:any){
   let myProps={
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let group_child_data=ChildDataFunc.get_child_arr_by_login_user(user);
  let comp_stu_row_arr=group_child_data.comp_stu_row_arr;
  let select_stu_row_idx=group_child_data.select_stu_row_idx;
  let select_comp_stu_row=group_child_data.select_comp_stu_row;
  const [select_stu_info,set_select_stu_info]=useState<any>({});
  const [main_data,set_main_data]=useState<any>({
    
  });

  useEffect(()=>{
    get_main_data_by_ajax();
  },[user]);

  const get_main_data_by_ajax=()=>{
    get_select_stu_info_by_ajax();
  };
  
  const get_select_stu_info_by_ajax=()=>{
    if(strFunc.is_empty(select_comp_stu_row["comp_api_key"])
      ||strFunc.is_empty(select_comp_stu_row["par_mcomp_user_seq"])
      ||strFunc.is_empty(select_comp_stu_row["user_seq"])){
      return false;
    }
    let now_ymd_str=DateFunc.get_date_format(new Date(),"Y-m-d");
    let from_json_data={
      api_key:select_comp_stu_row["comp_api_key"],
      api_user_seq:select_comp_stu_row["par_mcomp_user_seq"],
      "s_pri_arr":[select_comp_stu_row["user_seq"]],
      "s_addon_profile":"1",
      "s_addon_todo":"1",
      "s_addon_todo_start_date":now_ymd_str,
      "s_addon_todo_end_date":now_ymd_str,
      // "s_addon_lecture_time":"1",
      "s_addon_attend":"1",
      "s_addon_attend_start_date":now_ymd_str,
      "s_addon_attend_end_date":now_ymd_str,
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    //하루전꺼부터 조회
    let tmp_start_date = from_json_data["s_addon_todo_start_date"];
    let tmp_start_date_json = DateFunc.get_date_json(new Date(tmp_start_date));
    let pre_date_change_num = -1;
    if (tmp_start_date_json.day == 1) {
      //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
      pre_date_change_num = -3;
    }
    let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', pre_date_change_num), "Y-m-d");
    from_json_data["s_addon_todo_start_date"] = pre_date_str;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',from_json_data,get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        if(response.data["data"]["info_arr"].length>0){
          let tmp_stu_info=response.data["data"]["info_arr"][0];
          tmp_stu_info.todo_stat_data=TodoStateFunc.get_sort_percent_by_user_info(tmp_stu_info);
          tmp_stu_info.td_sort_data_json=PlanListFunc.get_td_sot_data_arr({
            info: tmp_stu_info,
            base_date_str: from_json_data["s_addon_todo_end_date"],
            pre_date_str: pre_date_str
          });
          set_select_stu_info(tmp_stu_info);
        }
      }
    });
  };
  
  return (
  <MobileLayout container_style={{ background: "linear-gradient(180deg, #6074E2 0%, #5683DB 78.12%)" }}
    container_con_style={{ width: "100%" }}
    is_home_body={true}
    has_header={false}
    has_footer={false}>
      
    <BackGroundArea></BackGroundArea>
    <div className={Style.main_wrap}>
      <TopTitleArea
        Style={Style}
        user={user}
        select_comp_stu_row={select_comp_stu_row}
      ></TopTitleArea>
      <div style={{marginTop:10}}>
        <TopStuSelect
          user={user}
          comp_stu_row_arr={comp_stu_row_arr}
          select_stu_row_idx={select_stu_row_idx}
          select_comp_stu_row={select_comp_stu_row}
          select_stu_info={select_stu_info}
        ></TopStuSelect>
      </div>
      <TodoProgressBar Style={Style} percent={0} select_stu_info={select_stu_info} ></TodoProgressBar>
      <MenuBoxArrArea Style={Style}></MenuBoxArrArea>
      {/* <TodayScheArea Style={Style}
        comp_stu_row_arr={comp_stu_row_arr}
        select_stu_row_idx={select_stu_row_idx}
      ></TodayScheArea> */}
      <StudyRecentGraph
        Style={Style}
        select_stu_info={select_stu_info}
      ></StudyRecentGraph>
    </div>
  </MobileLayout>
  );
};
export default ParentArea;