import Style from "./css/style.module.css";
import BackCircle from "./back_circle";

function BackGroundArea(props:any){
   let myProps={
    ...props
  };
  var win_w=(window.innerWidth || document.documentElement.clientWidth);
  let back_circle_data_arr=[
    {x:-70,y:70,w:180,h:180,borderRadius:180,border:45},
    {x:-100,y:400,w:200,h:200,borderRadius:200,border:50},
    {x:win_w-180,y:-90,w:250,h:250,borderRadius:250,border:50},
  ];
  
  const get_back_circle_tags=()=>{
    let back_circle_tags=back_circle_data_arr.map((item:any,idx:number)=>{
      return (
        <BackCircle key={idx}
          Style={Style}
          circle_data={item}
        ></BackCircle>
      );
    });
    return back_circle_tags;
  };

  return (
  <div className={Style.back_wrap}>
    <div className={Style.back_wrap_con}>
      {get_back_circle_tags()}
      <div className={Style.back_main_con_div}></div>
    </div>
  </div>
  );
};
export default BackGroundArea;