import StateBoxArea from "./state_box";

function StudyRecentGraph(props:any){
   let myProps={
    Style:{},
    select_stu_info:{},
    ...props
  };
  let Style=myProps.Style;
  let select_stu_info=myProps.select_stu_info;
  let attend_data={
    "percent":0,
  };
  if(select_stu_info.attend_in_arr&&select_stu_info.attend_in_arr.length>0){
    let tmp_attend_info=select_stu_info.attend_in_arr[0];
    if(tmp_attend_info["a_sort"]=="in"&&tmp_attend_info["a_sort_detail"]=="attend"){
      attend_data["percent"]=100;
    }
  }
  let todo_state_data={
    "study_str":"",
    "study_per":0,
    "homework_str":"",
    "homework_per":0,
  };
  if(select_stu_info["td_sort_data_json"]){
    if(select_stu_info["td_sort_data_json"]["study"]){
      let tmp_cnt_data=select_stu_info["td_sort_data_json"]["study"];
      if(tmp_cnt_data["count"]>0){
        todo_state_data["study_str"]="("+tmp_cnt_data["success_cnt"]+"/"+tmp_cnt_data["count"]+")";
      }
      todo_state_data["study_per"]=select_stu_info["td_sort_data_json"]["study"]["percent"];
    }
    if(select_stu_info["td_sort_data_json"]["pre_homework"]){
      let tmp_cnt_data=select_stu_info["td_sort_data_json"]["pre_homework"];
      if(tmp_cnt_data["count"]>0){
        todo_state_data["homework_str"]="("+tmp_cnt_data["success_cnt"]+"/"+tmp_cnt_data["count"]+")";
      }
      todo_state_data["homework_per"]=tmp_cnt_data["percent"];
    }
  }
  
  return (
  <div className={Style.recent_study_wrap}>
    <div className={Style.con_wrap}>
      <div className={Style.recent_study_title_wrap}>
        {/* 최근  */}
        학습성취도
      </div>
      <div>
        <StateBoxArea
          title={"출석"}
          Style={myProps.Style}
          percent={attend_data["percent"]}
        ></StateBoxArea>
        <StateBoxArea
          title={"학습"+todo_state_data["study_str"]}
          Style={myProps.Style}
          percent={todo_state_data["study_per"]}
        ></StateBoxArea>
         <StateBoxArea
          title={"과제"+todo_state_data["homework_str"]}
          Style={myProps.Style}
          percent={todo_state_data["homework_per"]}
        ></StateBoxArea>
      </div>
    </div>
  </div>
  );
};
export default StudyRecentGraph;