import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import TStuWritePopup from "@/pages/mobile/manage/tea/tstu_todo/popup/write";
import LoadingComponent from "@/pcomponents/common/content/loading";
import SearchArea from "./area/search";
import CardListArea from "./area/card_list";

function TStuTodoCardList(props: any) {
  let myProps = {
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;
  let now_y_m_d = DateFunc.get_date_format(new Date(), "Y-m-d");
  let pre_month_y_m_d = DateFunc.get_date_format(
    DateFunc.get_change_date(new Date(DateFunc.get_date_format(new Date(), "Y-m-01")), 'month', -1)
    , "Y-m-01");
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 300,
    'order_id': 'a_is_success, a_reserve_date DESC, a_ymd DESC, a_seq DESC',
    'order_type': '',
    "is_add_idx_info": "1",
    s_addon_comp_user: "1",
    s_date_type:"a_reserve_date",
    s_start_date: pre_month_y_m_d,
    s_end_date: now_y_m_d,
    s_owner_mcomp_user_seq: "",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: iam_mcomp_user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_subject:"",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [select_seq_arr, set_select_seq_arr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [load_data,set_load_data]=useState({
    is_display: false,
    text: "로딩중..",
  });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "add_attend",
    is_update: false,
    row_data: {},
    title: "팝업",
    width: "500px",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inData: any) => {
    let tmp_list_opt: any = {
      ...listOpt,
      ...inData
    };
    setListOpt(tmp_list_opt);
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_grade)) {
      return false;
    }

    tmp_list_opt["s_mcomp_seq"] = iam_mcomp_seq;
    tmp_list_opt["s_public_mcomp_user_seq"] = iam_mcomp_user_seq;
    tmp_list_opt["s_public_user_grade"] = iam_mcomp_user_grade;
    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_select_seq_arr([]);
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const go_save_by_ajax=(inData:any)=>{
    let opt_obj={
      write_info_arr:[],
      ...inData
    };
    if(opt_obj.write_info_arr.length==0){
      return false;
    }

    let form_json_data:any={
      data_arr:opt_obj.write_info_arr,
    };
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_select_seq_arr([]);
          list({});
          set_load_data({
            is_display: true,
            text: "저장 되었습니다.",
          });
          setTimeout(() => {
            set_load_data({
              is_display: false,
              text: "로딩중..",
            });
          }, 600);
        } else {

        }
      });
  };

  const go_delete_ajax = (inData: any) => {
    let opt_obj = {
      del_seq_arr: select_seq_arr,
      ...inData
    };

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let del_seq_arr = opt_obj.del_seq_arr;
    if (del_seq_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    let del_info_arr = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info: any = infoArr[i];
      let tmp_pri_val = info.a_ymd + "," + info.a_seq;
      if (strFunc.str_in_array(tmp_pri_val, del_seq_arr) != -1) {
        del_info_arr.push(info);
      }
    }
    if (del_info_arr.length == 0) {
      alert("내용이 없습니다.");
      return false;
    }

    if (!confirm(del_info_arr.length + "개를 삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data: any = {
      data_arr: del_info_arr,
    };
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_select_seq_arr([]);
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const openWritePopup = (inData: any) => {
    let opt_obj = {
      is_update: false,
      row_data: {},
      ...inData
    };
    let is_update = opt_obj.is_update;
    let row_data = opt_obj.row_data;

    let pop_title = "등록";
    if (is_update) {
      pop_title = "수정";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "write",
      "title": pop_title,
      "is_update": is_update,
      "row_data": row_data,
    });
  };

  return (
    <div className="noto_sans">
      <SearchArea
        list_opt={listOpt}
        list={list}
        infoArr={infoArr}
        select_seq_arr={select_seq_arr}
        set_select_seq_arr={set_select_seq_arr}
        go_delete_ajax={go_delete_ajax}
        openWritePopup={openWritePopup}
      ></SearchArea>
      <div className="mt-2">
        <CardListArea
          info_arr={infoArr}
          set_info_arr={setInfoArr}
          select_seq_arr={select_seq_arr}
          set_select_seq_arr={set_select_seq_arr}
          go_save_by_ajax={go_save_by_ajax}
        ></CardListArea>
      </div>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "write" &&
            <TStuWritePopup 
              is_update={popupData.is_update}
              row_data={popupData.row_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></TStuWritePopup>
          }
        </LayerPopup>
      }
      <LoadingComponent is_display={load_data.is_display} text={load_data.text}></LoadingComponent>
    </div>
  );
};
export default TStuTodoCardList;