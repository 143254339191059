import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useState } from 'react';
import CounselTodoListArea from "@/pages/mobile/manage/counsel_todo/common/list";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import DailyReportCardListArea from "@/pages/mobile/manage/edu/daily_report/common/card_list";
import TabArea from "./area/tab";
import strFunc from "@/lib/lyg/string";

function CounselDailyListPage() {
  const [page_tab, set_page_tab] = useState(strFunc.get_storage("counsel_daily_tab", "daily"));//counsel,daily

  return (
    <MobileLayout
      has_header={false}
      container_con_style={{ width: "100%" }}>
      <HeaderSimple title={page_tab=="daily"?"일일보고서":"상담관리"}>
        <span style={{ position: "absolute", right: 2, top: 10 }}>
          <TabArea page_tab={page_tab} set_page_tab={set_page_tab}></TabArea>
        </span>
      </HeaderSimple>
      {(page_tab == "counsel") &&
        <div>
          <CounselTodoListArea></CounselTodoListArea>
        </div>
      }
      {page_tab == "daily" &&
        <div>
          <DailyReportCardListArea></DailyReportCardListArea>
        </div>
      }
    </MobileLayout>
  );
};
export default CounselDailyListPage;