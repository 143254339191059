import strFunc from "@/lib/lyg/string";
import {get_char_img} from "@/pcomponents/common/content/char/data/char_data";

function CharBox(props: any) {
  let myProps = {
    char_info: {},
    open_view_char_popup:(inData:any)=>{},
    ...props
  };
  let char_info=myProps.char_info;

  let char_name_style:any={
    fontSize:"14px",
    color:"#fff",
    background:"green",
    padding:"0px 4px",
    borderRadius:"5px",
    marginTop:"7px"
  };
  let img_src=get_char_img({"id":char_info["a_id"]});
  return (
    <div>
      <div style={{lineHeight:"20px",textAlign:"center"}} 
        onClick={()=>{
          myProps.open_view_char_popup({
            char_info:char_info
          });
        }}>
        <div className="text-center">
          <img src={img_src} style={{ height: 60 }} />
        </div>
        <div style={char_name_style}>
          {char_info.a_name} 씨앗이
        </div>
        <div style={{fontSize:"13px"}}>
          {char_info.a_sub_name}
        </div>
        <div style={{fontSize:"13px",color:"green"}}>
          {strFunc.comma(char_info.a_cost)}G
        </div>
      </div>
    </div>
  );
};
export default CharBox;