import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";

function SearchArea(props: any) {
  let myProps = {
    list_opt: {},
    list: (inData: any) => { },
    go_write_page: () => { },
    ...props
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    myProps.list({
      "now_page": "1",
      ...{ [name]: value }
    });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.list_opt.s_reserve_start_date)) {
      myProps.list_opt.s_reserve_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.list_opt.s_reserve_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_reserve_start_date": change_date_str,
      "s_reserve_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    let change_s_list_json = {
      "now_page": "1",
      [key]: date_str,
    };
    if(key=="s_reserve_start_date"){
      change_s_list_json["s_reserve_end_date"]=date_str;
    }

    myProps.list(change_s_list_json);
  };

  return (
    <div>
      <div className="search_wrap btn-box-right mt-1 noto_sans" >
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.list_opt.s_reserve_start_date != "" ? new Date(myProps.list_opt.s_reserve_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_reserve_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          let tmp_row_y_m_d = DateFunc.get_date_format(new Date(), "Y-m-d");
          myProps.list({
            "now_page": "1",
            "s_reserve_start_date": tmp_row_y_m_d,
            "s_reserve_end_date": tmp_row_y_m_d,
          });
        }}>오늘</button>
        <button className="btn-s btn-dark" style={{ minWidth: 35, fontWeight: 500 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_reserve_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
            "s_reserve_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
          });
        }}>이번달</button>
      </div>
    </div>
  );
};
export default SearchArea;