import plus_glass from "@/img/mobile/ico/plus_glass.png";

function TopArea(props:any){
  let myProps={
    Style:{},
    todo_info:{},
    on_click_back:()=>{},
    back_btn_title:"뒤로",
    ...props
  };
  let Style=myProps.Style;

  let title_str="과제";
  if(myProps.todo_info.a_sort=="study"){
    title_str="학습";
  }

  return (
    <div className={Style.top_area_wrap+" noto_sans"}>
      <span className={Style.top_area_back_btn} onClick={myProps.on_click_back}>
        {myProps.back_btn_title}
      </span>
      {title_str}
      <span className={Style.top_area_plus_btn}>
        <img src={plus_glass} />
      </span>
    </div>
  );
}
export default TopArea;