import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";
import strFunc from '@/lib/lyg/string';

function UserAttendRow(props:any){
   let myProps={
    user_idx:0,
    user_info:{},
    set_user_info:(inData:any)=>{},
    ...props
  };

  let attend_data=AttendStateFunc.get_attend_data_by_user_info(myProps.user_info);
  let attend_in_info=attend_data.attend_in_info;
  let attend_out_info=attend_data.attend_out_info;

  let attend_in_his_arr=["00","00","00"];
  let attend_out_his_arr=["00","00","00"];
  if(attend_in_info!=null){
    attend_in_his_arr=attend_in_info.a_time.split(":");
  }
  if(attend_out_info!=null){
    attend_out_his_arr=attend_out_info.a_time.split(":");
  }

  const onChangeAttendTime=(inData:any)=>{
    let opt_obj={
      in_out:"in",//in,out
      name:"",
      value:"",
      ...inData
    };

    let tmp_user_info=myProps.user_info;
    if(opt_obj.in_out=="in"){
      if(opt_obj.name=="attend_time_h"){
        attend_in_his_arr[0]=opt_obj.value;
      }else if(opt_obj.name=="attend_time_m"){
        attend_in_his_arr[1]=opt_obj.value;
      }else if(opt_obj.name=="attend_time_s"){
        attend_in_his_arr[2]=opt_obj.value;
      }
      tmp_user_info.attend_in_arr[0]["a_time"]=attend_in_his_arr.join(":");
    }else if(opt_obj.in_out=="out"){
      if(opt_obj.name=="attend_time_h"){
        attend_out_his_arr[0]=opt_obj.value;
      }else if(opt_obj.name=="attend_time_m"){
        attend_out_his_arr[1]=opt_obj.value;
      }else if(opt_obj.name=="attend_time_s"){
        attend_out_his_arr[2]=opt_obj.value;
      }
      tmp_user_info.attend_out_arr[0]["a_time"]=attend_out_his_arr.join(":");
    }
    myProps.set_user_info(tmp_user_info);
  };

  let h_select_arr=[];
  for(let i=0;i<24;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2});
    h_select_arr.push(i_str);
  }
  let ms_select_arr=[];
  for(let i=0;i<60;i++){
    let i_str=strFunc.str_pad({"str":i,"pad_length":2});
    ms_select_arr.push(i_str);
  }

  return (
  <div>
    <p style={{fontWeight:"bold"}}>{myProps.user_info.a_user_name}</p>
    {attend_in_info!=null&&
      <div className="write-table-div mt-2">
        <p style={{color:"#666"}}>{attend_data.text} {attend_in_info.a_ymd}</p>
        <table>
          <colgroup>
            <col width="100px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>시간</th>
              <td>
                <select className="row-input" name="attend_time_h" value={attend_in_his_arr[0]} 
                  onChange={(e:any)=>{
                    onChangeAttendTime({
                      name:e.target.name,
                      value:e.target.value,
                      in_out:"in",
                    });
                  }}
                  style={{width:50}}>
                  {h_select_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>:
                <select className="row-input" name="attend_time_m" value={attend_in_his_arr[1]} 
                  onChange={(e:any)=>{
                    onChangeAttendTime({
                      name:e.target.name,
                      value:e.target.value,
                      in_out:"in",
                    });
                  }}
                  style={{width:50}}>
                  {ms_select_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>:
                <select className="row-input" name="attend_time_s" value={attend_in_his_arr[2]} 
                  onChange={(e:any)=>{
                    onChangeAttendTime({
                      name:e.target.name,
                      value:e.target.value,
                      in_out:"in",
                    });
                  }}
                  style={{width:50}}>
                  {ms_select_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    }
    {attend_out_info!=null&&
      <div className="write-table-div mt-2">
        <p style={{color:"#666"}}>{attend_data.out_text} {attend_out_info.a_ymd}</p>
        <table>
          <colgroup>
            <col width="100px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>시간</th>
              <td>
                <select className="row-input" name="attend_time_h" value={attend_out_his_arr[0]} 
                  onChange={(e:any)=>{
                    onChangeAttendTime({
                      name:e.target.name,
                      value:e.target.value,
                      in_out:"out",
                    });
                  }}
                  style={{width:50}}>
                  {h_select_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>:
                <select className="row-input" name="attend_time_m" value={attend_out_his_arr[1]} 
                  onChange={(e:any)=>{
                    onChangeAttendTime({
                      name:e.target.name,
                      value:e.target.value,
                      in_out:"out",
                    });
                  }}
                  style={{width:50}}>
                  {ms_select_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>:
                <select className="row-input" name="attend_time_s" value={attend_out_his_arr[2]} 
                  onChange={(e:any)=>{
                    onChangeAttendTime({
                      name:e.target.name,
                      value:e.target.value,
                      in_out:"out",
                    });
                  }}
                  style={{width:50}}>
                  {ms_select_arr.map((item:any,idx:number)=>{
                    return (
                      <option value={item} key={idx}>{item}</option>
                    )
                  })}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    }
  </div>
  );
};
export default UserAttendRow;