import strFunc from "@/lib/lyg/string";
import angry_seed_ico from "@/img/mobile/game/math/seed/angry_seed.png";
import love_seed_ico from "@/img/mobile/game/math/seed/love_seed.png";
import fine_seed_ico from "@/img/mobile/game/math/seed/file_seed.png";
import LygMath from '@/lib/lyg/math';

function PlusMatterArea(props: any) {
  let myProps = {
    Style: {},
    matter: null,
    is_show_answer: false,
    is_show_correct: false,
    ...props
  };
  let Style = myProps.Style;
  let matter = myProps.matter;

  let val_arr: any = [];
  let answer = 0;
  let matter_input_answer: any = "";
  if (matter) {
    val_arr = matter.val_arr;
    answer = matter.answer;
    matter_input_answer = matter.input_answer;
  }

  let random_ico_idx=LygMath.random(0,1);
  let corrent_ico_src=love_seed_ico;
  if(random_ico_idx==1){
    corrent_ico_src=fine_seed_ico;
  }

  let corrent_answer_text: any = (
    <div className="text-center">
      <img src={corrent_ico_src} style={{ height: "70px" }} />
      <span style={{ color: "green", fontSize: 35, marginLeft: 20 }}>O</span>
    </div>
  );
  if (answer != matter_input_answer) {
    corrent_answer_text = (
      <div className="text-center">
        <img src={angry_seed_ico} style={{ height: "70px" }} />
        <span style={{ color: "red", fontSize: 35, marginLeft: 20 }}>X</span>
      </div>
    );
  }

  const get_plus_num_tags = () => {
    let num_tags: any = "";
    if (val_arr) {
      num_tags = val_arr.join(" + ");
    }
    return num_tags;
  };

  return (
    <div>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              {get_plus_num_tags()}
              =
              <span className={Style.matter_answer_span}>
                {strFunc.is_empty(matter_input_answer) &&
                  <span style={{ color: "#fff" }}>{":"}</span>
                }
                {matter_input_answer}
              </span>
            </td>
            {myProps.is_show_answer &&
              <td rowSpan={2}>
                <div className="text-center mr-4" style={{ fontSize: 22 }}>
                  {corrent_answer_text}
                </div>
              </td>
            }
          </tr>
          {myProps.is_show_correct &&
            <tr>
              <td rowSpan={2} style={{ verticalAlign: "baseline" }}>
                <div style={{ fontSize: 25 }}>정답:
                  <span style={{ color: "green", marginLeft: 10 }}>{strFunc.comma(answer)}</span>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div >
  );
};
export default PlusMatterArea;