import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import { useRef, useEffect, useState } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import no_img_ico from "@/img/ico/noimg.png";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewFileSlidePopup from "@/pages/mobile/manage/basic/file/popup/view_file_slide";
import LoadingComponent from "@/pcomponents/common/content/loading";
import ImgBoxArea from "./img_box";

function ImgArea(props: any) {
  let myProps = {
    stu_info: {},
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    s_par_id:"home_work_file",//plan_study_file
    file_arr: [],
    goDelete: (inData: any) => { },
    callback:()=>{},
    Style: {},
    ...props
  };
  
  let Style = myProps.Style;
  const writeFileForm = useRef<any>(null);
  const fileInput = useRef<any>(null);
  let default_file_input_data = {
    "url": no_img_ico,
    "name": "",
  };
  const [file_input_data, set_file_input_data] = useState(default_file_input_data);
  const [is_display,set_is_display]=useState(false);
  let user = useSelector((state: any) => state.user);

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key="";
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_seq=user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key=user.select_mcomp_link["comp_api_key"];
  }

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view_file_slide",//view_file_slide
    file_info_arr: [],
    select_file_idx: 0,
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    
  }, []);

  const remove_file_input_select=()=>{
    if (fileInput.current) {
      fileInput.current.value = "";
    }
    set_file_input_data(default_file_input_data);
  };
  const goWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {
        
      },
      ...inData
    };
    let default_file_row_data = {
      "a_ymd":strFunc.str_replace("-","",myProps.select_date),
      "a_seq": "",
      "a_comp_seq": iam_mcomp_seq,
      "a_par_id": myProps.s_par_id,
      "a_par_seq": "",
      "a_sort1": "img",
      "a_sort2": "",
      "a_sort3": myProps.stu_info.a_seq,
      "a_writer": myProps.stu_info.a_user_name,
      "a_create_seq": iam_mcomp_user_seq,
      "a_create_date":myProps.select_date+DateFunc.get_date_format(new Date()," h:i:s"),
    };
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput.current == null) {
      return false;
    }
    if (writeFileForm.current == null) {
      return false;
    }

    if (fileInput.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }
    let file_row_data_arr=[];
    for(let i=0;i<fileInput.current.files.length;i++){
      if(!strFunc.is_empty(fileInput.current.files[i]["name"])){
        file_row_data_arr.push({
          ...default_file_row_data,
          ...opt_obj.file_row_data
        });;
      }
    }

    var form = writeFileForm.current;
    var form_json_data = new FormData(form);
    form_json_data.append("data_arr", JSON.stringify(file_row_data_arr));//[file_row_data]
    form_json_data.append("authorization", localStorage.intoyou_token);

    set_is_display(true);
    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        set_is_display(false);
        if (response.data["result"] === "true") {
          remove_file_input_select();
          myProps.callback(response.data["data"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const open_view_file_list_popup = (inData: any) => {
    let opt_obj = {
      file_info_arr: [],
      select_file_idx: 0,
      ...inData
    };

    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": "파일보기",
      "sort": "view_file_slide",
      "file_info_arr": opt_obj.file_info_arr,
      "select_file_idx": opt_obj.select_file_idx,
      width: "800px",
      height: "100%",
    });
  };

  const get_tag_data_arr = () => {
    let select_file_arr = [];
    for (let i = 0; i < myProps.file_arr.length; i++) {
      let file_info = myProps.file_arr[i];
      if (file_info["a_sort1"] == "img") {
        select_file_arr.push(file_info);
      }
    }
    return select_file_arr;
  };
  const get_tag_arr = () => {
    let select_file_arr = get_tag_data_arr();
    let div_tags: any = select_file_arr.map((item: any, idx: number) => {
      return (
        <ImgBoxArea
          key={idx}
          Style={Style}
          file_info={item}
          file_idx={idx}
          goDelete={myProps.goDelete}
          callback={myProps.callback}
          open_view_file_list_popup={(inData:any)=>{
            open_view_file_list_popup({
              "file_info_arr":select_file_arr,
              "select_file_idx":idx
            });
          }}
        ></ImgBoxArea>
      );
    });

    if (strFunc.is_empty(div_tags)) {
      // div_tags = (
      //   <div style={{ width: "100%", textAlign: "center", lineHeight: "30px", color: "#999" }}>
      //     내용이 없습니다.
      //   </div>
      // );
    }

    return div_tags;
  }

  return (
    <div>
      <div className={Style.sub_title}>이미지</div>
      <div className={Style.sub_img_wrap}>
        {get_tag_arr()}
        <div className={Style.sub_img_wrap_item}>
          <form method="post" ref={writeFileForm} onSubmit={() => { return false; }} >
            <img src={file_input_data.url} alt={file_input_data.name}
              style={{ cursor: "pointer", maxWidth: 60, maxHeight: 60, flexShrink: 0 }} 
              onClick={(e:any)=>{
                e.target.parentElement.parentElement.querySelector(".input_file")?.click();
              }} />
            <p>
              {file_input_data.name}
            </p>
            <input type="file" className="input_file" name="input_file[]"
              ref={fileInput} hidden accept="image/*" onChange={(e: any) => {
                if (e.target.files.length > 0) {
                  let file = e.target.files[0];
                  set_file_input_data({
                    url: URL.createObjectURL(file),
                    name: file.name
                  });
                  if (confirm("사진을 등록 하시겠습니까?")) {
                    goWrite({});
                  } else {
                    remove_file_input_select();
                  }
                }
              }} multiple />
          </form>
          {file_input_data.name != "" &&
            <span className="btn-s btn-red px-2"
              style={{ position: "absolute", right: 0, top: 0 }}
              onClick={() => {
                remove_file_input_select();
              }}>x</span>
          }
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} y={"0"} is_border_radius={false} >
          {popupData.sort === "view_file_slide" &&
            <ViewFileSlidePopup
              file_info_arr={popupData.file_info_arr}
              select_file_idx={popupData.select_file_idx}
              callback={(inData: any) => { }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewFileSlidePopup>
          }
        </LayerPopup>
      }
      <LoadingComponent is_display={is_display} text={"업로드중.."}></LoadingComponent>
    </div>
  );
};
export default ImgArea;