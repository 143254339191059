import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderWrapArea from "@/pcomponents/common/content/layout/header_wrap";
import TitleBarArea from "@/pcomponents/common/content/list/title_bar";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from "./area/search";
import Paging from "@/pcomponents/common/crud/list/paging/paging";

function StudyDiaryCardListPage() {
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  const [pageData, setPageData] = useState({
    listOpt: {
      'now_page': 1,
      'num_per_page': 300,
      'order_id': 'a_user_name',
      'order_type': '',
      "is_add_idx_info": "1",
      s_is_login: "1",
      s_stu_state: "now",
      s_grade: ["student"],
      s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
      s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
      s_addon_lecture_time: "1",
      s_user_name_like: "",
      s_addon_prescribe_last: "1",
      s_addon_prescribe: "1",
      s_addon_prescribe_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_prescribe_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_prescribe_writer_seq: iam_mcomp_user_grade == "master" ? "" : iam_mcomp_user_seq,
    }
  });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [stu_class_info_arr, set_stu_class_info_arr] = useState([]);
  const [teacher_info_arr, set_teacher_info_arr] = useState([]);
  const [check_row_val_arr, set_check_row_val_arr] = useState([]);
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {
    
    list({});
    get_stu_class_list_by_ajax();
    get_teacher_list_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    let tmp_list_opt: any = { ...pageData.listOpt };

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }

    tmp_list_opt["s_mcomp_seq"] = iam_mcomp_seq;
    tmp_list_opt["s_mcomp_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/comp_user/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const get_stu_class_list_by_ajax = () => {
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let tmp_list_opt = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
      "order_id": "a_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_stu_class_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_list_by_ajax = () => {
    if (strFunc.is_empty(iam_mcomp_seq) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let tmp_list_opt: any = {
      "order_id": "a_user_name",
      "s_is_login": "1",
      "s_grade": ["master", "teacher", "staff"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    tmp_list_opt["s_mcomp_seq"] = iam_mcomp_seq;
    tmp_list_opt["s_mcomp_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/comp_user/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const on_check_all = (e: any) => {
    if (e.target.checked) {
      let tmp_pri_val_arr: any = [];
      for (let i = 0; i < infoArr.length; i++) {
        let info = infoArr[i];
        let row_pri_val = info["a_seq"];
        tmp_pri_val_arr.push(row_pri_val);
      }
      set_check_row_val_arr(tmp_pri_val_arr);
      set_refresh(!refresh);
    } else {
      set_check_row_val_arr([]);
      set_refresh(!refresh);
    }
  };

  const select_save_study_prescribe = () => {
    let select_info_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let row_pri_val = info["a_seq"];
      if (strFunc.str_in_array(row_pri_val, check_row_val_arr) != -1) {
        select_info_arr.push(info);
      }
    }
    if (select_info_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    save_study_prescribe_by_stu_arr(select_info_arr);
  };
  const select_delete_study_prescribe = () => {
    let select_info_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let row_pri_val = info["a_seq"];
      if (strFunc.str_in_array(row_pri_val, check_row_val_arr) != -1) {
        select_info_arr.push(info);
      }
    }
    if (select_info_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    delete_study_prescribe_by_stu_arr(select_info_arr);
  };

  const save_study_prescribe_by_stu_arr = (select_stu_arr: any[]) => {
    if (select_stu_arr.length == 0) {
      alert("선택이 없습니다..");
      return false;
    }

    //보고서 모으기
    let prescribe_arr: any = [];
    for (let i = 0; i < select_stu_arr.length; i++) {
      let stu_info: any = select_stu_arr[i];
      if (stu_info.prescribe_arr && stu_info.prescribe_arr.length > 0) {
        prescribe_arr = [
          ...prescribe_arr,
          ...stu_info.prescribe_arr
        ];
      }
      if (stu_info.study_prescribe_write_info) {
        prescribe_arr = [
          ...prescribe_arr,
          ...[stu_info.study_prescribe_write_info]
        ];
      }
    }
    if (prescribe_arr.length == 0) {
      alert("저장 할 데이터가 없습니다.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    let w_report_form_json: any = {
      "data_arr": prescribe_arr,
    };
    w_report_form_json["api_key"] = comp_api_key;
    w_report_form_json["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/write', w_report_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_study_prescribe_by_stu_arr = (select_stu_arr: any[]) => {
    if (select_stu_arr.length == 0) {
      alert("선택이 없습니다..");
      return false;
    }

    //보고서 모으기
    let prescribe_arr: any = [];
    for (let i = 0; i < select_stu_arr.length; i++) {
      let stu_info: any = select_stu_arr[i];
      if (stu_info.prescribe_arr && stu_info.prescribe_arr.length > 0) {
        prescribe_arr = [
          ...prescribe_arr,
          ...stu_info.prescribe_arr
        ];
      }
    }
    if (prescribe_arr.length == 0) {
      alert("삭제 할 데이터가 없습니다.");
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    let w_report_form_json: any = {
      "data_arr": prescribe_arr,
    };
    w_report_form_json["api_key"] = comp_api_key;
    w_report_form_json["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/delete', w_report_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <MobileLayout has_header={false}
      container_style={{ marginTop: 165 }}
      container_con_style={{ width: "100%", background: "#F1F4F4" }} >
      <HeaderWrapArea>
        <TitleBarArea title={"학습일지"} is_show_list_sort={false} ></TitleBarArea>
        <SearchArea
          list_opt={pageData.listOpt}
          list={list}
          stu_class_info_arr={stu_class_info_arr}
          teacher_info_arr={teacher_info_arr}
        ></SearchArea>
        <div className="mt-1 ml-1 noto_sans">
          <label>
            <input type="checkbox" onChange={on_check_all} />
            <span className="ml-1">전체</span>
          </label>
          <button className="btn-s btn-green ml-2" onClick={select_save_study_prescribe}>선택저장</button>
          <button className="btn-s btn-green ml-2" onClick={(e: any) => { save_study_prescribe_by_stu_arr(infoArr); }}>일괄저장</button>
          <button className="btn-s btn-red ml-2" onClick={select_delete_study_prescribe} >선택삭제</button>
        </div>
      </HeaderWrapArea>
    </MobileLayout>
  );
};
export default StudyDiaryCardListPage;