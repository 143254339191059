import ReportWriteComponent from "../../component/write";

function ReportItemArea(props: any) {
  let myProps = {
    info: null,
    row_num: 0,
    set_info: (info: any) => { },
    is_view_mode: true,
    Style: {},
    goUpdate: (info: any) => { },
    goDelete: (info: any) => { },
    ...props
  };

  return (
    <div className="mt-1">
      <ReportWriteComponent
        num_text={(myProps.row_num + 1) + "."}
        row_data={myProps.info}
        set_row_data={(inData: any) => { myProps.set_info(inData); }}
        is_view_mode={myProps.is_view_mode}
        manage_area={(
          <div className="text-center">
            <button className="btn-s btn-sky" onClick={() => { myProps.goUpdate([myProps.info]); }} >저장</button>
            <button className="btn-s btn-red ml-1" onClick={() => { myProps.goDelete([myProps.info]); }} >X</button>
          </div>
        )}
      ></ReportWriteComponent>
    </div>
  );
};
export default ReportItemArea;