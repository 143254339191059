import { useState,useRef,useEffect } from 'react';
import StuNameBarArea from "./area/stu_name_bar";
import StuArea from "./area/stu_area";

function ViewStuInfoPopup(props:any){
   let myProps={
    closePopup:()=>{},
    select_idx:0,
    info_arr:[],
    ...props
  };

  const [select_idx,set_select_idx]=useState(myProps.select_idx);
  const [info_arr,set_info_arr]=useState(myProps.info_arr);
  const [refresh,set_refresh]=useState(false);
  let row_data:any={};
  if(select_idx>=0&&select_idx<info_arr.length){
    row_data=info_arr[select_idx];
  }
  
  return (
  <div>
    <StuNameBarArea
      row_data={row_data}
      info_arr={info_arr}
      select_idx={select_idx}
      set_select_idx={(inData:any)=>{
        set_select_idx(inData);
        set_refresh(!refresh);
      }}
    ></StuNameBarArea>
    <StuArea
      row_data={row_data}
    ></StuArea>
    <div className="btn-box-center mt-2">
      <button className="btn btn-dark" onClick={()=>{myProps.closePopup();}}>닫기</button>
    </div>
  </div>
  );
};
export default ViewStuInfoPopup;