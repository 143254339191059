class PlanListFunc {
  static get_attend_btn_data_arr(attend_data: any) {
    let attend_btn_data_arr = [
      { "value": "attend", "text": "출석", "color": "#353535", "background": "#E0E5E5" },
      { "value": "late", "text": "지각", "color": "#353535", "background": "#E0E5E5" },
      { "value": "absent", "text": "결석", "color": "#353535", "background": "#E0E5E5" }
    ];
    if ( attend_data["value"] != "") {
      let new_attend_btn_data_arr = [];
      for (let i = 0; i < attend_btn_data_arr.length; i++) {
        if (attend_btn_data_arr[i]["value"] == attend_data["value"]) {
          new_attend_btn_data_arr.push(attend_btn_data_arr[i]);
        }
      }
      new_attend_btn_data_arr.push(
        { "value": "out", "text": "하원", "color": "#353535", "background": "#E0E5E5" }
      );
      attend_btn_data_arr=new_attend_btn_data_arr;
    }
    for (let i = 0; i < attend_btn_data_arr.length; i++) {
      if (attend_btn_data_arr[i]["value"] == attend_data["value"]) {
        attend_btn_data_arr[i]["color"] = "#fff";
        attend_btn_data_arr[i]["background"] = attend_data["color"];
        if (attend_data.attend_in_info) {
          if(attend_btn_data_arr[i]["value"]!="absent"){
            attend_btn_data_arr[i]["text"] = attend_data.attend_in_info["a_time"].substring(0, 5);
          }
        }
      } else if (attend_btn_data_arr[i]["value"] == attend_data["out_value"]) {
        attend_btn_data_arr[i]["color"] = "#fff";
        attend_btn_data_arr[i]["background"] = attend_data["out_color"];
        if (attend_data.attend_out_info) {
          attend_btn_data_arr[i]["text"] = attend_data.attend_out_info["a_time"].substring(0, 5);
        }
      }
    }
    return attend_btn_data_arr;
  }
}
export default PlanListFunc;