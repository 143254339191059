import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import StuTimeTableArea from "./area/time_table";

function StuOneWeekTimeTable(props: any) {
  let myProps = {
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },
    stu_seq:"",
    is_show_teacher_name:false,
    is_show_time_split:true,
    ...props
  };

  let iam_mcomp_seq=myProps.user_data.iam_mcomp_seq;
  let iam_mcomp_user_seq=myProps.user_data.iam_mcomp_user_seq;
  let comp_api_key=myProps.user_data.comp_api_key;

  const [listOpt, setListOpt] = useState({
    "order_id": "a_order_num",
    "s_lecture_seq": "",
    "s_state": "ing",
    "s_teacher_like": "",
    "s_teacher_seq": "",
    "s_room_seq": "",
    "s_name_like": "",
    "s_addon_time": "1",
    "s_addon_user": "1",
    "s_addon_user_time": "1",
    "s_addon_user_s_state": "ing",
    "s_addon_user_s_user_seq": "empty",
    "s_addon_time_union": "1",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });

  const [stu_info, set_stu_info] = useState<any>({
    a_seq: "",
    a_user_name: "",
  });
  const [lecture_user_arr, set_lecture_user_arr] = useState([]);
  const [lecture_arr, set_lecture_arr] = useState([]);
  const [teacher_arr, set_teacher_arr] = useState([]);
  const [room_arr, set_room_arr] = useState([]);
  const [is_teacher_nickname, set_is_teacher_nickname] = useState(true);
  const [is_show_teacher_name,set_is_show_teacher_name]=useState(myProps.is_show_teacher_name);

  const time_table_wrap_ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(strFunc.is_empty(myProps["stu_seq"])){
      
    }else{
      get_stu_info();
      get_user_and_time_by_ajax({ s_addon_user_s_user_seq: myProps["stu_seq"] });
      get_teacher_by_ajax();
      get_room_by_ajax();
    }
  }, [myProps["stu_seq"]]);
  const get_stu_info=()=>{
    if(strFunc.is_empty(myProps["stu_seq"])){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    let list_form_json_data:any={
      "s_pri_arr":[myProps["stu_seq"]],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',
      list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            set_stu_info(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };
  const get_user_and_time_by_ajax = (inOptObj: any) => {
    let change_list_opt = {
      ...listOpt,
      ...inOptObj,
    };
    setListOpt(change_list_opt);
    if(strFunc.is_empty(myProps["stu_seq"])){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    let list_form_json_data: any = {
      "s_stu_seq": change_list_opt["s_addon_user_s_user_seq"],
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/get_user_and_time',
      list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_user_arr(response.data["data"]["lecture_user_arr"]);
          let lecture_seq_arr: any = [];
          for (let i = 0; i < response.data["data"]["lecture_user_arr"].length; i++) {
            let row_lecture_user_info = response.data["data"]["lecture_user_arr"][i];
            if (strFunc.str_in_array(row_lecture_user_info["a_lecture_seq"], lecture_seq_arr) == -1) {
              lecture_seq_arr.push(row_lecture_user_info["a_lecture_seq"]);
            }
          }
          if (lecture_seq_arr.length > 0) {
            change_list_opt["s_pri_arr"] = lecture_seq_arr;
            get_lecture_arr_by_ajax(change_list_opt);
          } else {
            set_lecture_arr([]);
          }
        } else {

        }
      });

  };
  const get_lecture_arr_by_ajax = (inOptObj: any) => {
    let list_form_json_data = inOptObj;
    if(strFunc.is_empty(myProps["stu_seq"])){
      return false;
    }
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    list_form_json_data["api_key"]=comp_api_key;
    list_form_json_data["api_user_seq"]=iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list',
      list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lecture_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_by_ajax = () => {
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_room_by_ajax = () => {
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    let list_form_json_data = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_room_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div>
      <div ref={time_table_wrap_ref} >
        <div className="ml-2" style={{fontSize:"14px"}}>
          <span style={{color:"#0040ff",fontWeight:"bold"}}>{stu_info["a_user_name"]}</span> 학생 시간표
        </div>
        <div className="mt-1">
          <StuTimeTableArea
            lecture_arr={lecture_arr}
            teacher_arr={teacher_arr}
            room_arr={room_arr}
            is_teacher_nickname={is_teacher_nickname}
            is_show_teacher_name={is_show_teacher_name}
            is_show_time_split={myProps.is_show_time_split}
          ></StuTimeTableArea>
        </div>
      </div>
    </div>
  );
};
export default StuOneWeekTimeTable;