//import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
function SelectBoxBar(props: any) {
  let myProps = {
    valueTextArr: [],//[{"value":"","text":""}]
    value: "",
    name: "",
    on_change: (inData: any) => { },//{name:"",value:"",text:""}
    style_type: "wide",
    ...props
  };
  let class_name_json = {
    wrap: "select_box_bar",
    item: "select_box_bar_item",
    active: "active",
  };
  if (myProps.style_type == "small") {
    class_name_json = {
      wrap: "select_box_bar_small",
      item: "select_box_bar_item_small",
      active: "active",
    };
  } else if (myProps.style_type == "wide") {
    class_name_json = {
      wrap: "select_box_bar_wide",
      item: "select_box_bar_item_wide",
      active: "active",
    };
  }
  const get_box_tags = () => {
    let box_tags = myProps.valueTextArr.map((item: any, idx: number) => {
      let box_class_name = class_name_json.item;
      if (item.value == myProps.value) {
        box_class_name += " " + class_name_json.active;
      }
      return (
        <div key={idx} className={box_class_name}
          onClick={() => {
            let change_value = item.value;
            if (change_value == myProps.value) {
              change_value = "";
            }
            myProps.on_change({
              name: myProps.name,
              value: change_value,
              text: item.text,
            });
          }}>
          {item.text}
        </div>
      );
    });
    return box_tags;
  };

  return (
    <div className={class_name_json.wrap}>
      {get_box_tags()}
    </div>
  );
};
export default SelectBoxBar;