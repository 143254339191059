import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import Style from "./css/style.module.css";
import noimg_img from "@/img/ico/noimg.png";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

const ListCardComponent=forwardRef((props:any, ref) => {
  let myProps:any={
    "infoArr":[],//[{"a_ymd":"20221212","a_seq":"1","a_filename":"파일명","is_active":false}]
    "xColumnArr":{
      "x_column_list_arr":[]//[{"key":"a_ymd","name":"board_ymd","width":"100","is_show":"1"}]
    },
    "gridOpt":{},
    "customCellRenderer":(params:any)=>{},
    "onClickRow":(inOptObj:any)=>{
      let optObj={
        row_num:0,
        data:{},
        event:null,
        ...inOptObj
      };
    },
    "is_show_iam_ing":true,
    ...props
  };

  let user=useSelector((state:any) => state.user);
  let select_user_data=UserFunc.get_select_comp_user(user);
  const [infoArr,setInfoArr]= useState(myProps.infoArr);
  const xColumnArr=myProps.xColumnArr;
  

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInfoArr
  }));

  let card_item_tags=infoArr.map((item:any,idx:number)=>{
    let title_img_src=noimg_img;
    if(item.title_img_url){
      title_img_src=item.title_img_url;
    }

    let state_str="";
    if(xColumnArr.select_arr["a_state"]){
      let state_select_arr=xColumnArr.select_arr["a_state"];
      for(let i=0;i<state_select_arr.length;i++){
        if(state_select_arr[i]["value"]==item.a_state){
          state_str=state_select_arr[i]["text"];
          state_str="("+state_str+")";
        }
      }
    }

    let pre_use_str="";
    let iam_use_info=null;
    if(item.use_stu_arr){
      for(let i=0;i<item.use_stu_arr.length;i++){
        if(item.use_stu_arr[i]["a_stu_seq"]==select_user_data.select_mcomp_user_seq){
          if(myProps.is_show_iam_ing){
            pre_use_str="(수강중)";
          }
          iam_use_info=item.use_stu_arr[i];
        }
      }
    }
    if(pre_use_str==""&&item.request_arr){
      for(let i=0;i<item.request_arr.length;i++){
        if(item.request_arr[i]["a_writer_seq"]==select_user_data.select_mcomp_user_seq){
          pre_use_str="(수강신청중)";
        }
      }
    }

    let complete_per=0;
    if(iam_use_info!=null){
      complete_per=parseInt(strFunc.uncomma(iam_use_info["a_study_per"]));
    }

    return (
      <div key={idx} className={Style.card_item} 
        onClick={(e:any)=>{
          myProps.onClickRow({
            row_num:idx,
            data:item,
            event:e,
          });
        }} 
        >
        <div className={Style.card_item_category_wrap}>
          {item.a_category}
          <span className={Style.card_item_title_right_per}>{complete_per} %</span>
        </div>
        <div className={Style.card_item_title_img_wrap}>
          <img src={title_img_src} />
        </div>
        <div className={Style.card_complete_per_wrap}>
          <div className={Style.card_complete_per_bar} style={{width:complete_per+"%"}}></div>
        </div>
        <div className={Style.card_bottom_box}>
          <div className={Style.card_sub_title}>
            <span className={Style.card_title_span} >{item.a_title}</span>
            <span className={Style.card_title_state}>{pre_use_str}</span>
            <span className={Style.card_title_state_after} >{state_str}</span>
          </div>
        </div>
      </div>
    );
  });

  if(infoArr.length==0){
    card_item_tags=(
      <div className={Style.card_item}>
        <div style={{textAlign:"center",marginTop:20}}>강의없음</div>
      </div>
    );
  }

  return (
    <div className={Style.card_wrap}>
      {card_item_tags}
    </div>
  );
});

export default ListCardComponent;