import strFunc from '@/lib/lyg/string';

const CircleArea = (props:any) => {
  let myProps:any={
    Style:{},
    per_num:0,
    color:"#f4ff8b",
    back_color:"#E2E3DD",
    with:300,
    height:300,
    ...props
  };
  let Style=myProps.Style;

  let per_dig=0;
  if(myProps.per_num>100){
    myProps.per_num=100;
  }
  if(myProps.per_num>0){
    per_dig=(myProps.per_num/100)*360;
  }
  
  let data={
    center_x:myProps.with/2,
    center_y:myProps.height/2,
    r:myProps.with/2-2,
    dig:per_dig,
    x:0,
    y:0,
    per_num:myProps.per_num,
    color:myProps.color,
  };

  const get_xy_by_per=(center_x:number,center_y:number,r:number,dig:number)=>{
    let row_x:number=0;
    let row_y:number=0;

    // 각도를 라디안으로 변환합니다.
    const angleInRadians = (dig * Math.PI) / 180;

    //x구하기
    row_x= r * Math.cos(angleInRadians);
    //y구하기
    row_y = r * Math.sin(angleInRadians);

    row_x=center_x+row_x;
    row_y=center_y+row_y;

    return {x:row_x,y:row_y};
  };

  const on_change_data=()=>{
    let change_data:any={...data};
    let xy_json=get_xy_by_per(change_data.center_x,change_data.center_y,change_data.r,change_data.dig);
    change_data.x=xy_json.x;
    change_data.y=xy_json.y;

    change_data.per_num=0;
    if(change_data.dig>0){
      change_data.per_num=(change_data.dig/360)*100;
    }
    
    data=change_data;
  };
  on_change_data();

  let back_d1="M"+(data.center_x+data.r)+","+(data.center_y);
  back_d1+=" A"+data.r+","+data.r+" 0,1,1";
  back_d1+=" "+(data.center_x+data.r)+","+(data.center_y-0.1)+" ";

  let is_large="0";
  if(data.dig>180){
    is_large="1";
  }

  let circle_d="M"+(data.center_x+data.r)+","+(data.center_y);
  circle_d+=" A"+data.r+","+data.r+" 0,"+is_large+",1";
  circle_d+=" "+data.x+","+(data.y-0.1)+" ";

  return (
    <div className={Style.total_per_circle}>
      <div className={Style.total_per_circle_box}>
        {/* 백그라운드 */}
        <svg width={myProps.width} height={myProps.height} className={Style.total_per_circle_svg} >
          <path d={back_d1} stroke={myProps.back_color} fillOpacity="0" strokeWidth="6" />
        </svg>
        {/* 퍼센트 */}
        <svg width={myProps.width} height={myProps.height} className={Style.total_per_circle_svg} >
          <path d={circle_d} stroke={data.color} fillOpacity="0" strokeWidth="6" />
        </svg>
      </div>
    </div>
  );
};

export default CircleArea;