import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useState, useRef, useEffect } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation,useNavigate } from "react-router";
import SearchArea from "./area/search";
import ScheCalendar from "./area/calendar";
import BottomTodoArea from "./area/bottom_todo";

function ScheMain(){
  let user=useSelector((state:any) => state.user);
  const { state } = useLocation();
  let state_obj={
    list_opt:{
      "s_date_str":DateFunc.get_date_format(new Date(),"Y-m-d")
    },
    ...state
  };
  const [listOpt,setListOpt]=useState({
    "is_need_count":"",
    "is_need_info_arr":"1",
    "is_no_limit":"1",
    "order_id":"a_create_date DESC",
    "s_reserve_start_date":"",
    "s_reserve_end_date":"",
    "s_addon_user":"1",
    "s_is_mcomp_public":"",
    "s_todo_sort":"",
    "s_stu_user_seq":[],
    "s_stu_info_arr":[],
    "s_class_seq_arr":[],
    "s_date_str":DateFunc.get_date_format(new Date(),"Y-m-d"),
    ...state_obj.list_opt,
  });
  const [todo_arr,set_todo_arr]=useState([]);

  useEffect(()=>{
    
    list({});
  },[]);

  const list = (inOptObj:any)=>{
    let list_form_json:any={
      ...listOpt,
      ...inOptObj
    };
    setListOpt(list_form_json);
    
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }

    let select_mcomp_link_val_arr=[];
    select_mcomp_link_val_arr.push(user.comp_seq+","+user.user_seq);//내꺼도
    if (!strFunc.is_empty(user.select_mcomp_link)) {
      select_mcomp_link_val_arr.push(user.select_mcomp_link.a_mcomp_seq+","+user.select_mcomp_link.a_mcomp_user_seq);
    }
    
    if(strFunc.is_empty(select_mcomp_link_val_arr)){
      return false;
    }
    list_form_json.s_user_key=select_mcomp_link_val_arr;

    if(!strFunc.is_empty(list_form_json.s_date_str)){
      let pre_month_obj=DateFunc.get_change_date(new Date(list_form_json.s_date_str),'month',-1);
      let after_month_obj=DateFunc.get_change_date(new Date(list_form_json.s_date_str),'month',+1);
      list_form_json.s_start_date=DateFunc.get_date_format(pre_month_obj,"Y-01-01");
      list_form_json.s_reserve_start_date=DateFunc.get_date_format(pre_month_obj,"Y-m-01");
      list_form_json.s_reserve_end_date=DateFunc.get_date_format(after_month_obj,"Y-m-t");
    }

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',list_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_todo_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };
  return (
    <MobileLayout>
      <SearchArea
        select_date={listOpt.s_date_str}
        set_select_date={(inData:any)=>{
          list({
            ...listOpt,
            s_date_str:inData
          });
        }}
      ></SearchArea>
      <ScheCalendar
        select_date={listOpt.s_date_str}
        set_select_date={(inData:any)=>{
          list({
            ...listOpt,
            s_date_str:inData
          });
        }}
        onClickTdTag={(inData:any)=>{
          setListOpt({
            ...listOpt,
            s_date_str:inData.select_date_str
          });
        }}
        todo_arr={todo_arr}
      ></ScheCalendar>
      <BottomTodoArea
        select_date={listOpt.s_date_str}
        todo_arr={todo_arr}
        list_opt={listOpt}
      ></BottomTodoArea>
    </MobileLayout>
  );
}

export default ScheMain;