import strFunc from "@/lib/lyg/string";
import { get_char_img } from "@/pcomponents/common/content/char/data/char_data";

function CharBox(props: any) {
  let myProps = {
    char_user_info: {},
    is_select_char: false,
    open_view_char_popup: (inData: any) => { },
    ...props
  };
  let char_user_info = myProps.char_user_info;
  let is_select_char = myProps.is_select_char;
  let char_box_style:any={
    lineHeight: "20px", textAlign: "center",
    padding:5,
    borderRadius:"10px"
  };
  let char_name_style: any = {
    fontSize: "15px",
    color: "#000",
    background: "",
    padding: "0px 4px",
    borderRadius: "5px",
    marginTop: "7px"
  };
  if(is_select_char){
    char_name_style["background"]="#c4f9c4";
    char_name_style["color"]="#000";
    char_name_style["fontWeight"]="600";
    char_box_style["border"]="1px solid green";
  }
  let img_src = get_char_img({ "id": char_user_info["a_sort_id"] });
  return (
    <div>
      <div style={char_box_style}
        onClick={() => {
          myProps.open_view_char_popup({
            char_user_info: char_user_info,
            is_select_char:is_select_char
          });
        }}>
        <div className="text-center">
          <img src={img_src} style={{ height: 60 }} />
        </div>
        <div style={char_name_style}>
          {char_user_info.a_name}
        </div>
        <div style={{ fontSize: "13px" }}>
          레벨 {char_user_info.a_level}
        </div>
      </div>
    </div>
  );
};
export default CharBox;