import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import { useState, useEffect,useRef } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  const [teacher_info_arr, set_teacher_info_arr] = useState([]);
  const search_time_out_ref=useRef<any>(null);
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  useEffect(() => {
    get_teacher_list_by_ajax();
  }, []);

  const get_teacher_list_by_ajax = () => {
    if (strFunc.is_empty(iam_mcomp_seq) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let tmp_list_opt: any = {
      "order_id": "a_user_name",
      "s_is_login": "1",
      "s_grade": ["master", "teacher", "staff"],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    tmp_list_opt["s_mcomp_seq"] = iam_mcomp_seq;
    tmp_list_opt["s_mcomp_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/comp_user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    let change_list_opt: any = {
      "now_page": "1",
      ...{ [name]: value }
    };
    if (name == "s_start_date") {
      change_list_opt["s_end_date"] = value;
    }
    myProps.setListOpt({ ...myProps.listOpt, ...change_list_opt });
    go_search_list({ now_page: 1, ...change_list_opt });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(listOpt.s_start_date)) {
      listOpt.s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(listOpt.s_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_start_date": change_date_str,
      "s_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    myProps.list({
      "now_page": "1",
      [key]: date_str,
    });
  };

  return (
    <div style={{ borderBottom: "1px solid #E0E5E5", padding: "10px 0", background: "#fff" }}>
      <div className="search_wrap">
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
            "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
          });
        }}>이번달</button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          let now_month_init_date = DateFunc.get_date_format(new Date(), "Y-m-01");
          myProps.list({
            "now_page": "1",
            "s_start_date": DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-01"),
            "s_end_date": DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-t"),
          });
        }}>지난달</button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
            "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
          });
        }}>오늘</button>
      </div>
      <div className="search_wrap mt-2">
        <input type="text" className="border" name="s_stu_name_like" value={listOpt.s_stu_name_like} 
          style={{width:60}} placeholder="학생명"
          onChange={handleChangeInput} />
        <select className="border" value={listOpt.s_writer_seq}
          name="s_writer_seq"
          onChange={handleChangeInput} style={{ width: 65, fontSize: 13 }}>
          <option value="">작성자</option>
          {
            teacher_info_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.a_seq}>{item.a_user_name}</option>
              );
            })
          }
        </select>
        <select className="border" name="s_is_success" value={listOpt.s_is_success}
          onChange={handleChangeInput}
          style={{ width: "48px" }}>
          <option value="">전체</option>
          <option value="1">완료</option>
          <option value="empty">미완료</option>
        </select>
        <select className="border" name="s_subject" value={listOpt.s_subject}
          onChange={handleChangeInput}
          style={{ width: "48px" }}>
          <option value="">과목</option>
          {select_subject_option_arr.map((item: any, idx: number) => {
            return (
              <option key={idx} value={item["value"]}>{item["text"]}</option>
            );
          })}
        </select>
        <span
          style={{ color: "blue" }}
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <button className="btn-s btn-dark" style={{ minWidth: 40, background: "#3FAC96" }} 
          onClick={() => { myProps.list(); }}>
          검색
        </button>
      </div>
    </div>
  );
};
export default SearchArea;