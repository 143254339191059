import MobileLayout from "@/pcomponents/mobile/layout/layout";
import StudyMapListArea from "@/pages/mobile/plan/study_map/common/study_map_list";

function StudyMapListPage() {

  return (
    <MobileLayout>
      <StudyMapListArea></StudyMapListArea>
    </MobileLayout>
  );
};
export default StudyMapListPage;