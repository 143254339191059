import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

function SearchArea(props: any) {
  let myProps = {
    list_opt: {},
    list: (inData: any) => { },
    stu_class_info_arr: [],
    teacher_info_arr: [],
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);

  useEffect(() => {

    get_lecture_select_arr();
  }, []);

  const get_lecture_select_arr = () => {
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let tmp_list_form = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', tmp_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length == 0) {
            strFunc.set_storage("s_stu_lecture_seq", "");
          }
        } else {

        }
      });
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    let change_list_opt: any = {
      "now_page": "1",
      ...{ [name]: value }
    };
    if (name == "s_addon_prescribe_start_date") {
      change_list_opt["s_addon_prescribe_end_date"] = value;
    }
    myProps.list(change_list_opt);
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.list_opt.s_addon_prescribe_start_date)) {
      myProps.list_opt.s_addon_prescribe_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.list_opt.s_addon_prescribe_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_addon_prescribe_start_date": change_date_str,
      "s_addon_prescribe_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    myProps.list({
      "now_page": "1",
      [key]: date_str,
    });
  };

  return (
    <div style={{ borderBottom: "1px solid #E0E5E5", padding: "10px 0", background: "#fff" }}>
      <div className="search_wrap">
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.list_opt.s_addon_prescribe_start_date != "" ? new Date(myProps.list_opt.s_addon_prescribe_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_prescribe_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_addon_prescribe_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
            "s_addon_prescribe_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
          });
        }}>오늘</button>
      </div>
      <div className="search_wrap mt-2">
        <input type="text" value={myProps.list_opt.s_user_name_like}
          name="s_user_name_like"
          onChange={handleChangeInput} style={{ width: 50 }}
          placeholder="이름" autoComplete="off" />
        <SearchLectureSelect
          name="s_stu_lecture_seq"
          value={myProps.list_opt.s_stu_lecture_seq}
          on_change_input={(inData: any) => {
            let name = inData.name;
            let value = inData.value;
            myProps.list({ now_page: 1, [name]: value });
          }}
          wrap_style={{ width: 90, display: "inline-table" }}
          select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
        ></SearchLectureSelect>
        <select className="ml-1 border" value={myProps.list_opt.s_addon_prescribe_writer_seq}
          name="s_addon_prescribe_writer_seq"
          onChange={handleChangeInput} style={{ width: 65, fontSize: 13 }}>
          <option value="">작성자</option>
          {
            myProps.teacher_info_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item.a_user_seq}>{item.a_user_name}</option>
              );
            })
          }
        </select>
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = myProps.list_opt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {myProps.list_opt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <button className="btn-s btn-dark ml-1" style={{ minWidth: 40, background: "#3FAC96" }} onClick={() => { myProps.list(); }}>
          검색
        </button>
      </div>
    </div>
  );
};
export default SearchArea;