import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import { useState, useRef } from 'react';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    go_add_study_map_popup: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;
  const search_time_out_ref = useRef<any>(null);
  const go_search_list = (inData: any) => {
    if (search_time_out_ref.current) {
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current = setTimeout(() => {
      myProps.list(inData);
    }, 200);
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;

    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.listOpt.s_start_date)) {
      myProps.listOpt.s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.listOpt.s_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_start_date": change_date_str,
      "s_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    let change_s_list_json = {
      "now_page": "1",
      [key]: date_str,
    };

    myProps.list(change_s_list_json);
  };

  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  let start_date_json = DateFunc.get_date_json(new Date(myProps.listOpt.s_start_date));
  let select_day_str = DateFunc.get_day_str_by_day_num(start_date_json.day);
  let is_today_select = false;
  if (now_date_str == myProps.listOpt.s_start_date) {
    is_today_select = true;
  }

  return (
    <div>
      <div className="mt-1 text-right">
        {/* <button className="btn btn-sky" onClick={myProps.go_add_study_map_popup}>추가</button> */}
      </div>
    </div>
  );
};
export default SearchArea;