import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import strFunc from '@/lib/lyg/string';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;

  let listOpt = myProps.listOpt;
  const [subjectArr, setsubjectArr] = useState<any>([]);
  const [subject_main_arr, set_subject_main_arr] = useState<any>([]);
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  useEffect(() => {
    list_subject_by_ajax({});
    get_subject_main_by_ajax({});
  }, []);

  const go_search = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    if(e.target.type === "checkbox"){
      value = e.target.checked ? "1" : "";
    }
    myProps.list({
      "now_page": "1",
      [name]: value
    });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    let change_listOpt: any = {
      [name]: value
    };
    if (name == "s_subject_sort") {
      get_subject_main_by_ajax({ s_sort: value });
      list_subject_by_ajax({ s_sort: value });
      change_listOpt["s_subject_main_seq"] = "";
      change_listOpt["s_subject_seq"] = "";
    } else if (name == "s_subject_main_seq") {
      list_subject_by_ajax({ s_main_seq: value });
      change_listOpt["s_subject_seq"] = "";
    }

    myProps.setListOpt({
      ...listOpt,
      ...change_listOpt
    });
    if (name == "s_search_text") {
      return false;
    }
    myProps.list(change_listOpt);
  };

  const get_subject_main_by_ajax = (inData: any) => {
    let opt_obj = {
      s_sort: listOpt.s_subject_sort,
      ...inData
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let subject_list_form_data:any = {
      "order_id": "a_order_num, a_title",
      "s_sort": opt_obj.s_sort,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    subject_list_form_data["api_key"] = comp_api_key;
    subject_list_form_data["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject_main/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_subject_main_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list_subject_by_ajax = (inData: any) => {
    let opt_obj = {
      s_sort: listOpt.s_subject_sort,
      s_main_seq: listOpt.s_subject_main_seq,
      ...inData
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(opt_obj.s_main_seq)) {
      setsubjectArr([]);
      return false;
    }
    let subject_list_form_data:any = {
      "order_id": "a_order_num, a_title",
      "s_sort": opt_obj.s_sort,
      "s_main_seq": opt_obj.s_main_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    subject_list_form_data["api_key"] = comp_api_key;
    subject_list_form_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setsubjectArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div>
      <select className="search-input_select border" name="s_subject_sort" value={myProps.listOpt.s_subject_sort}
        onChange={go_search} style={{ width: 50 }} >
        <option value="">과목</option>
        {select_subject_option_arr.map((item: any, idx: number) => {
          return (
            <option value={item.value} key={idx}>{item.text}</option>
          );
        })}
      </select>
      
      <select className="search-input_select border ml-1" name="s_subject_main_seq" value={listOpt.s_subject_main_seq}
        onChange={handleInputChange}
        style={{ width: 70 }}>
        <option value="">대분류</option>
        {
          subject_main_arr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_title}</option>
            );
          })
        }
      </select>
      <select className="search-input_select border ml-1" name="s_subject_seq" value={listOpt.s_subject_seq}
        onChange={handleInputChange}
        style={{ width: 70 }}>
        <option value="">분류</option>
        {
          subjectArr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_title}</option>
            );
          })
        }
      </select>
      <input type="text" className="search-input border ml-1" name="s_title_like" value={listOpt.s_title_like}
        onChange={handleInputChange} style={{ width: 70 }}
        placeholder="제목" />

      <button className="btn btn-dark ml-1" onClick={() => { myProps.list({}); }}>검색</button>
    </div>
  );
};
export default SearchArea;