import carrot_smile_100 from "@/img/mobile/char/carrot_smile_100.png";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";

const ChatRowTag = (props: any) => {
  let myProps = {
    key: 0,
    room_info: {},
    mem_info_arr:[],
    chat_info: {},
    user: {},
    iam_mcomp_user_seq: "",
    pre_create_date_str: "",
    is_display_title:true,
    goFileDownLoad: (inData: any) => { },
    ...props
  };

  let is_display_title=myProps.is_display_title;
  let is_iam = false;
  if (myProps.iam_mcomp_user_seq == myProps.chat_info.a_writer_seq) {
    is_iam = true;
  }

  let row_class_name = "chat_view_msg_row";
  if (is_iam) {
    row_class_name += " my_msg_row";
  }

  let top_date_line: any = "";
  if (myProps.chat_info.a_create_date.substring(0, 10) != myProps.pre_create_date_str) {
    top_date_line = (
      <div className="chat_content_row_date">
        <div className="chat_content_row_date_text">
          {myProps.pre_create_date_str}
        </div>
      </div>
    );
  }

  let a_create_date = DateFunc.getTimeFormatOfPretty(myProps.chat_info.a_create_date);
  let unread_span: any = "";
  if (myProps.chat_info.a_unread_cnt != "0") {
    unread_span = (
      <span className="chat_view_msg_row_con_unread">{myProps.chat_info.a_unread_cnt}</span>
    );
  }

  let row_file_tags: any = "";
  if (myProps.chat_info.file_arr && myProps.chat_info.file_arr.length > 0) {
    row_file_tags = myProps.chat_info.file_arr.map((row_file: any, file_idx: number) => {
      return (
        <div key={file_idx}>
          {row_file.is_image == "1" &&
            <img src={row_file.thum_img_url} />
          }
          {row_file.is_image != "1" &&
            <a className="text-blue-700"
              onClick={() => {
                myProps.goFileDownLoad(row_file.a_ymd + "," + row_file["a_seq"], row_file["a_serverurl"]);
              }} >{row_file.a_oriname}</a>
          }
        </div>
      );
    });
  }

  let row_profile_img_src=carrot_smile_100;
  for(let i=0;i<myProps.mem_info_arr.length;i++){
    let mem_info=myProps.mem_info_arr[i];
    if(mem_info["a_user_seq"]==myProps.chat_info.a_writer_seq){
      myProps.chat_info.a_writer=mem_info["a_user_name"];
      if(mem_info["user_info"]["a_user_grade"]!="student"){
        if(!strFunc.is_empty(mem_info["user_info"]["a_user_nickname"])){
          myProps.chat_info.a_writer=mem_info["user_info"]["a_user_nickname"];
        }
      }
      if(mem_info.user_info&&mem_info.user_info.profile_arr.length>0){
        //row_profile_img_src=mem_info.user_info.profile_arr[0]["thum_img_url"];
      }
    }
  }

  return (
    <div className={row_class_name} key={myProps.key}>
      {top_date_line}
      {(is_iam == false&&is_display_title) &&
        <div className="chat_view_msg_row_title_box">
          <span className="chat_view_msg_row_title_profile_img_box">
            <img src={row_profile_img_src} />
          </span>
          <span className="chat_view_msg_row_title" >{myProps.chat_info.a_writer}</span>
        </div>
      }
      <div className="chat_view_msg_row_con_box">
        {is_iam == true &&
          <>
            <div className="chat_view_msg_row_img_box">
              {row_file_tags}
            </div>
            {unread_span}
            <span className="chat_view_msg_row_con_time">{a_create_date}</span>
          </>
        }
        <div className="chat_view_msg_row_con_msg">
          {
          myProps.chat_info.a_content.split('\n').map( (line:string,idx:number) => {
            return (<p  key={idx}>{line}</p>)
          })
          }
        </div>
        {is_iam == false &&
          <>
            <span className="chat_view_msg_row_con_time">{a_create_date}</span>
            {unread_span}
            <div className="chat_view_msg_row_img_box">
              {row_file_tags}
            </div>
          </>
        }
      </div>
    </div>
  );
};
export default ChatRowTag;