import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import MobileScript from "@/lib/mobile/web_script";
import mCallBack from "@/pcomponents/mobile/layout/mobile/callback";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';

function TestMain(){
  const user = useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const [myNum,setMyNum]=useState(0);
  const [backName,setBackName]=useState("");
  const [sendBackName,setSendBackName]=useState("테스트 고고");
  useEffect(()=>{
    
    mCallBack.callBackTest=(msg:string)=>{
      setBackName(msg);
    };
    list({});
  },[backName,sendBackName]);
  const writeFileForm = useRef(null);
  const fileInput = useRef(null);
  const [userArr,setUserArr]=useState([]);

  const list = async (inOptObj:any)=>{
    let listOpt={};
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/user/list',listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setUserArr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const goUploadFile=()=>{
    if(!confirm("파일 업로드 하시겠습니까?")){
      return false;
    }
    let file_row_data={
      "a_ymd":DateFunc.get_date_format(new Date(),"Ymd"),
      "a_seq":"",
      "a_comp_seq":user.comp_seq,
      "a_par_id":"",
      "a_par_seq":"",
      "a_sort1":"mobile",
      "a_sort2":"test",
      "a_sort3":"",
      "a_writer":user.user_name,
      "a_create_seq":user.user_seq,
    };
    if(writeFileForm.current==null){
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data= new FormData(form);
    let file_row_data_arr=[];
    let file_len:any=0;
    if(fileInput.current){
      file_len=(fileInput.current as HTMLInputElement).files?.length;
      if(file_len==undefined){file_len=0;}
    }
    for(let i=0;i<file_len;i++){
      file_row_data_arr.push(file_row_data);
    }
    form_json_data.append("data_arr",JSON.stringify(file_row_data_arr));
    form_json_data.append("authorization",localStorage.intoyou_token);

    my_axios.post( process.env.REACT_APP_FILE_SERVER_URL+'/api/comp/basic/file/file/receive',form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert(response.data["msg"]);
        if(fileInput.current){
          (fileInput.current as HTMLInputElement).value="";
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  return (
    <MobileLayout>
      <h1>테스트메인</h1>
      <p>{userArr.length}</p>
      <p>
        <button className="btn btn-dark" onClick={()=>{alert("경고 입니다!!");}} >경고</button>
      </p>
      <p>
        <button className="btn btn-dark" onClick={()=>{
            if(confirm("하시겠습니까?")){
              setMyNum(1);
            }else{
              setMyNum(0);
            }
          }} >컨펌</button>
        {myNum}
      </p>
      <div className="mt-1">
        <form method="post" ref={writeFileForm} >
          <input type="file" name="input_file[]" ref={fileInput} multiple />
        </form>
        <button className="btn btn-dark" onClick={()=>{goUploadFile();}} >업로드</button>
      </div>
      <p>
        카메라
        <input type="file" accept="image/*"  capture="user" />
      </p>
      <p>
        <button className="btn btn-dark" onClick={()=>{
          MobileScript.toast("안녕하세요?!!",1000);
        }}>토스트</button>
      </p>
      <div>
        <input type="text" value={sendBackName} onChange={(e:any)=>{setSendBackName(e.target.value);}} style={{width:120}}/>
        <button className="btn btn-dark" onClick={()=>{
            MobileScript.callBackTest(sendBackName);
          }}>콜백테스트</button>
          <p style={{color:"red"}}>
            {backName}
          </p>
      </div>
      <p>
        <button className="btn btn-dark" onClick={()=>{
            MobileScript.speechTTSMsg(sendBackName);
          }}>TTS</button>
      </p>
      <p>
        <button className="btn btn-dark" onClick={()=>{navigate("/mobile");}} >홈</button>
      </p>
    </MobileLayout>
  );
}

export default TestMain;