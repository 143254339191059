import Style from "../../css/style.module.css";
import { useState, useRef, useEffect } from 'react';

function JoinInputComponent(props:any){
  let myProps={
    title:"이름이 뭔가요?",
    sub_title:"",
    input_placeholder:"입력",
    btn_title:"계속 >",
    is_pw_input:false,
    input_name:"",
    input_value:"",
    onChangeInput:(value:string)=>{},
    onClickButton:()=>{},
    ...props
  };

  const [input_value,set_input_value]=useState(myProps.input_value);

  const onChangeInput=(e:any)=>{
    set_input_value(e.target.value);
    myProps.onChangeInput(e.target.name,e.target.value);
  };

  return (
    <div>
      <div className={Style.join_title}>{myProps.title}</div>
      <div className={Style.join_sub_title}>
        {myProps.sub_title}
      </div>
      <input type={myProps.is_pw_input?"password":"text"}
        className={Style.join_input} 
        name={myProps.input_name}
        value={input_value} 
        onChange={onChangeInput}
        placeholder={myProps.input_placeholder} />
      <div>
        <button className={Style.join_btn} 
          onClick={()=>{myProps.onClickButton()}}
        >{myProps.btn_title}</button>
      </div>
    </div>
  );
}

export default JoinInputComponent;