import main_mid_back_img from "@/img/mobile/main/main_mid_back.png";

function BackGroundSkyArea(props:any){
   let myProps={
    ...props
  };
  
  let sky_back_wrap_style:any={
    position:"absolute",
    top:0,
    left:0,
    background:"#E5EFE9",
    height:"80px",
    width:"100%",
  };

  return (
  <div style={sky_back_wrap_style}>
    <div style={{width:"100%",height:"100%",position:"relative"}}>
      <img src={main_mid_back_img} style={{position:"absolute",bottom:0,left:0,width:"100%"}}/>
    </div>
  </div>
  );
};
export default BackGroundSkyArea;