import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useNavigate, useLocation } from 'react-router-dom';
import FileFormArea from "./area/file_img";
import FileAudioArea from "./area/file_audio";
import FileVideoArea from "./area/file_video";
import MobileScript from "@/lib/mobile/web_script";

function HomeWorkFilePage() {
  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const { state } = useLocation();
  const state_obj = {
    back_press_url: "/mobile/home_work",
    s_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    s_par_id: "home_work_file",
    list_opt: {},
    is_show_img: true,
    is_show_audio: true,
    is_show_video: true,
    user_data:{
      "iam_mcomp_seq":"",
      "iam_mcomp_user_seq":"",
      "comp_api_key":"",
    },
    ...state
  };
  const [pageData, setPageData] = useState({
    listOpt: {
      'now_page': 1,
      'num_per_page': 1000,
      'order_id': 'a_create_date DESC',
      'order_type': '',
      's_date_type': 'a_create_date',
      's_start_date': state_obj.s_date,
      's_end_date': state_obj.s_date,
      "is_add_idx_info": "1",
      "s_comp_seq": "",
      "s_par_id": state_obj.s_par_id,
      "s_par_seq": "",
      "s_sort1": "",
      "s_sort3": "",
    }
  });

  const [infoArr, setInfoArr] = useState([]);
  const [is_loaded, set_is_loaded] = useState(false);

  let iam_mcomp_seq = "";
  let iam_mcomp_user_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  if(!strFunc.is_empty(state_obj.user_data.iam_mcomp_user_seq)){
    iam_mcomp_seq=state_obj.user_data.iam_mcomp_seq;
    iam_mcomp_user_seq=state_obj.user_data.iam_mcomp_user_seq;
    comp_api_key=state_obj.user_data.comp_api_key;
  }

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    let tmp_list_opt = { ...pageData.listOpt };
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    tmp_list_opt.s_comp_seq = iam_mcomp_seq;
    tmp_list_opt.s_sort3 = iam_mcomp_user_seq;
    set_is_loaded(false);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          set_is_loaded(true);
        } else {

        }
      });
  };

  const goBackUrl = () => {
    let send_data: any = {
      list_opt: state_obj.list_opt
    };
    navigate(state_obj.back_press_url, {
      state: send_data
    });
  };

  let ymd_str = strFunc.str_replace("-", "", pageData.listOpt.s_start_date.substring(0, 10));
  let create_date = pageData.listOpt.s_start_date + " " + DateFunc.get_date_format(new Date(), "h:i:s");
  let app_sort = MobileScript.checkMobile();

  return (
    <MobileLayout container_style={{ background: "#fff" }}
      container_con_style={{ width: "100%" }}
      has_header={true} >
      <h3 className="text-center">
        {state_obj.s_date}
      </h3>
      {is_loaded &&
        <div>
          {state_obj.is_show_img &&
            <FileFormArea
              file_arr={infoArr}
              default_file_row_data={{
                a_ymd: ymd_str,
                a_comp_seq: iam_mcomp_seq,
                a_par_id: state_obj.s_par_id,
                a_sort1: "img",
                a_sort3: iam_mcomp_user_seq,
                a_writer: user.user_name,
                a_create_seq: iam_mcomp_user_seq,
                a_create_date: create_date,
              }}
              callback={() => { list({ "now_page": "1" }); }}
            ></FileFormArea>
          }
          {app_sort != "ios" &&
            <>
              {state_obj.is_show_audio &&
                <FileAudioArea
                  file_arr={infoArr}
                  default_file_row_data={{
                    a_ymd: ymd_str,
                    a_comp_seq: iam_mcomp_seq,
                    a_par_id: state_obj.s_par_id,
                    a_sort1: "audio",
                    a_sort3: iam_mcomp_user_seq,
                    a_writer: user.user_name,
                    a_create_seq: iam_mcomp_user_seq,
                    a_create_date: create_date,
                  }}
                  callback={() => { list({ "now_page": "1" }); }}
                ></FileAudioArea>
              }
              {state_obj.is_show_video &&
                <FileVideoArea
                  file_arr={infoArr}
                  default_file_row_data={{
                    a_ymd: ymd_str,
                    a_comp_seq: iam_mcomp_seq,
                    a_par_id: state_obj.s_par_id,
                    a_sort1: "video",
                    a_sort3: iam_mcomp_user_seq,
                    a_writer: user.user_name,
                    a_create_seq: iam_mcomp_user_seq,
                    a_create_date: create_date,
                  }}
                  callback={() => { list({ "now_page": "1" }); }}
                ></FileVideoArea>
              }
            </>
          }
        </div>
      }
      {(strFunc.is_empty(iam_mcomp_user_seq) || strFunc.is_empty(iam_mcomp_seq)) &&
        <div className="text-center" style={{ lineHeight: "50px" }}>학원선택 필요.</div>
      }
      <div style={{ textAlign: "center" }}>
        <button className="btn btn-dark" onClick={() => { goBackUrl(); }}>뒤로</button>
      </div>
    </MobileLayout>
  );
};
export default HomeWorkFilePage;