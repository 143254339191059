import strFunc from "@/lib/lyg/string";
import CounselBox from "./area/counsel_box";
import { useLocation, useNavigate } from "react-router";

function CounselTodayArea(props: any) {
  let myProps = {
    Style: {},
    comp_main_data: {
      today_counsel_stat: null,
      today_counsel_list: [],
    },
    ...props
  };
  const navigate = useNavigate();
  let Style = myProps.Style;
  let default_today_counsel_stat = {
    row_tot: 0,
  };
  let today_counsel_stat = {
    ...default_today_counsel_stat,
    ...myProps.comp_main_data.today_counsel_stat
  };
  let today_counsel_list: any = [];
  if (!strFunc.is_empty(myProps.comp_main_data.today_counsel_list)) {
    today_counsel_list = myProps.comp_main_data.today_counsel_list;
  }

  const go_write_page = (inData: any) => {
    let opt_obj = {
      "info": {},
      "isUpdate": false,
      ...inData
    };

    let send_data = {
      "row_data": {},
      "info_arr": [],
      "isUpdate": false,
      "back_press_url": "/mobile/manage/counsel_todo/list",
    };
    let view_url = "/mobile/manage/counsel_todo/write";
    navigate(view_url,
      { state: send_data });
  };

  const get_counsel_box_tags = () => {
    let counsel_box_tags = today_counsel_list.map((item: any, idx: number) => {
      return (
        <CounselBox
          key={idx}
          info={item}
          Style={Style}
        ></CounselBox>
      );
    });
    if (strFunc.is_empty(counsel_box_tags)) {
      counsel_box_tags = (
        <div className="text-center" style={{ lineHeight: "40px", width: "100%", fontSize: "13px", color: "#bbb" }}>
          내용이 없습니다.
        </div>
      );
    }
    return counsel_box_tags;
  };

  return (
    <div>
      <div className={Style.counsel_today_wrap}>
        <div className={Style.counsel_today_title}>
          상담
          {!strFunc.is_empty(today_counsel_stat["row_tot"])&&
            <span>(+{today_counsel_stat["row_tot"]})</span>
          }
          <button
            className="round_plus_btn_green ml-1"
            onClick={go_write_page}>
            +
          </button>
        </div>
      </div>
      <div className={Style.counsel_today_list}>
        {get_counsel_box_tags()}
      </div>
    </div>
  );
};
export default CounselTodayArea;