import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TStuTodoBaseFunc from "@/pcomponents/common/content/tea/tstu_todo/func/base_func";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function WriteArea(props: any) {
  let myProps = {
    row_data: {},
    set_row_data: (inData: any) => { },
    is_update: false,
    open_find_stu_popup: (inData: any) => { },
    ...props
  };
  let row_data = myProps.row_data;
  let select_subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;

    let change_row_data = {
      ...myProps.row_data,
      ...{ [name]: value }
    };
    if (name == "a_is_success") {
      if (value == "1") {
        change_row_data["a_state"] = "complete";
        change_row_data["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      } else {
        change_row_data["a_state"] = "ready";
      }
    }

    myProps.set_row_data(change_row_data);
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_row_data = {
      ...myProps.row_data,
      ...{ [key]: date_str }
    };
    myProps.set_row_data(change_row_data);
  };

  return (
    <div>
      <div className="write_table_small">
        <table>
          <colgroup>
            <col width={"20%"}></col>
            <col width={"80%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>학생</th>
              <td>
                {row_data.a_stu_name}
                <button className="btn-s btn-dark ml-1" onClick={myProps.open_find_stu_popup}>찾기</button>
              </td>
            </tr>
            <tr>
              <th>날짜</th>
              <td>
                <div style={{ display: "inline-block", marginLeft: 5, marginRight: 5 }}>
                  <DatePicker
                    selected={row_data.a_reserve_date != "" ? new Date(row_data.a_reserve_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_reserve_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <input type="text" className="row-input" name="a_title" value={row_data.a_title}
                  onChange={handleChangeInput} autoComplete="off" />
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td>
                <input type="text" className="row-input" name="a_memo" value={row_data.a_memo}
                  onChange={handleChangeInput} autoComplete="off" />
              </td>
            </tr>
            <tr>
              <th>선생님</th>
              <td>
                {row_data.a_owner_name}
              </td>
            </tr>
            <tr>
              <th>완료여부</th>
              <td>
                <SelectBoxBar
                  valueTextArr={[
                    { "value": "", "text": "미완료" },
                    { "value": "1", "text": "완료" },
                  ]}
                  value={row_data.a_is_success}
                  name={"a_is_success"}
                  on_change={(inData: any) => {
                    handleChangeInput({
                      target: {
                        name: inData.name,
                        value: inData.value,
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
            <tr>
              <th>공개구분</th>
              <td>
                <SelectBoxBar
                  valueTextArr={TStuTodoBaseFunc.select_arr_json.a_public_sort}
                  value={row_data.a_public_sort}
                  name={"a_public_sort"}
                  on_change={(inData: any) => {
                    handleChangeInput({
                      target: {
                        name: inData.name,
                        value: inData.value,
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
            <tr>
              <th>과목</th>
              <td>
                <SelectBoxBar
                  valueTextArr={select_subject_option_arr}
                  value={row_data.a_subject_name}
                  name={"a_subject_name"}
                  on_change={(inData: any) => {
                    handleChangeInput({
                      target: {
                        name: inData.name,
                        value: inData.value,
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default WriteArea;