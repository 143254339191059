import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Style from "./css/style.module.css";
import TopInfoArea from "./area/top_info";
import SectionArea from "./area/section";
import DetailViewArea from "./area/detail_view";

function StudyMapWriteDetailArea(props: any) {
  let myProps = {
    close_func: () => { },
    close_text: "닫기",
    callback_refresh_data: (inData: any) => { },
    map_pri_val: "",
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  let user_data={
    "iam_mcomp_seq":iam_mcomp_seq,
    "iam_mcomp_user_seq":iam_mcomp_user_seq,
    "comp_api_key":comp_api_key,
  };

  let default_info_data = {
    a_study_title_seq: "",
    a_stu_seq: "",
    a_stu_name: "",
    a_success_cnt: "",
    a_total_cnt: "",
    a_start_date: "",
    a_is_success: "",
    a_success_date: "",
    user_info: null,
    detail_arr: [],
    study_title_info: null,
    study_title: "",
    section_arr: [],
    study_detail_arr: [],
  };
  const [info, setInfo] = useState({ ...default_info_data });
  let tab_arr = [
    { value: "section", text: "리스트" },
    { value: "detail", text: "상세" },
  ];
  const [tab_id, set_tab_id] = useState("section");//detail
  const [select_section_study_name, set_select_section_study_name] = useState({
    section_name: "",
    unit_name: "",
    study_detail_info: null,
  });

  useEffect(() => {
    if (strFunc.is_empty(myProps.map_pri_val)) {
      alert("키가 없습니다.");
      myProps.close_func();
    }
    get_info_by_ajax();
  }, [user]);

  const get_info_by_ajax = () => {
    if (strFunc.is_empty(myProps.map_pri_val)) {
      alert("키가 없습니다.");
      myProps.close_func();
    }

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let form_json_data: any = {
      "s_pri_arr": [myProps.map_pri_val],
      "s_addon_study_title": "1",
      "s_addon_study_detail": "1",
      "s_addon_detail": "1",
      "s_addon_user_info": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            setInfo(response.data["data"]["info_arr"][0]);
          } else {
            alert("검색 데이터가 없습니다.");
            myProps.close_func();
          }
        } else {
          alert("검색 중 오류." + response.data["msg"]);
          myProps.close_func();
        }
      });
  };

  const go_delete_study_map=()=>{
    if(!confirm("삭제하시겠습니까?")){
      return false;
    }

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let del_row={
      a_study_title_seq: info.a_study_title_seq,
      a_stu_seq: info.a_stu_seq,
    };
    let form_json_data: any = {
      "data_arr": [del_row],
    };
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.callback_refresh_data();
          myProps.close_func();
        } else {
          alert("검색 중 오류.");
        }
      });
  };

  const go_detail_view = (inData: any) => {
    let opt_obj = {
      section_name: "",
      unit_name: "",
      study_detail_info: null,
      ...inData
    };
    set_tab_id("detail");
    set_select_section_study_name({
      ...select_section_study_name,
      section_name: opt_obj.section_name,
      unit_name: opt_obj.unit_name,
      study_detail_info: opt_obj.study_detail_info,
    });
  };

  return (
    <div className={Style.total_wrap}>
      <TopInfoArea
        info={info}
        Style={Style}
        tab_id={tab_id}
        set_tab_id={set_tab_id}
        go_delete_study_map={go_delete_study_map}
      ></TopInfoArea>
      <div className="mt-1">
        {tab_id === "section" &&
          <SectionArea
            info={info}
            Style={Style}
            go_detail_view={go_detail_view}
          ></SectionArea>
        }
        {tab_id === "detail" &&
          <DetailViewArea
            user_data={user_data}
            info={info}
            Style={Style}
            select_section_study_name={select_section_study_name}
            set_tab_id={set_tab_id}
            get_info_by_ajax={get_info_by_ajax}
            callback_refresh_data={myProps.callback_refresh_data}
          ></DetailViewArea>
        }
      </div>
    </div>
  );
};
export default StudyMapWriteDetailArea;