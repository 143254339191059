import { useState, useRef } from 'react';
import strFunc from '@/lib/lyg/string';
import StudyTitleListArea from "./area/study_title";
import StudyListArea from "./area/study_list";
import Style from "./css/style.module.css";

function FindStudyTitlePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callbackData: (inData: any) => { },
    row_num: "",
    key_str: "",
    par_info: {},
    is_remove_select_title_btn: false,
    go_remove_select_title: () => { },
    ...props
  };
  const [select_title_info, set_select_title_info] = useState(null);
  const StudyTitleListAreaRef = useRef<any>(null);
  const StudyListAreaRef = useRef<any>(null);

  const onStudyTitleSelect = (selected_row: any) => {
    if (selected_row.length == 1) {
      StudyListAreaRef.current.setInitData({
        rowData: selected_row[0]
      });
      set_select_title_info(selected_row[0]);
    } else {
      StudyListAreaRef.current.setInitData({
        rowData: {},
      });
      set_select_title_info(null);
    }
  };

  const goSelectCallback = () => {
    let select_info_arr = StudyTitleListAreaRef.current.get_select_info_arr();
    if (select_info_arr.length == 0) {
      alert("선택된 항목이 없습니다.");
      return false;
    }

    myProps.callback({
      "info_arr": select_info_arr,
      "row_num": myProps.row_num,
      "key_str": myProps.key_str,
      "par_info": myProps.par_info
    });
    myProps.closePopup();
  };

  return (
    <div>
      <div className="btn-box-center">
        <button className="btn btn-line-gray" onClick={() => { goSelectCallback(); }}>선택</button>
        {myProps.is_remove_select_title_btn &&
          <button className="btn btn-red ml-1" onClick={() => {
            myProps.go_remove_select_title({
              info_arr: [],
              row_num: myProps.row_num,
              key_str: myProps.key_str,
              par_info: myProps.par_info,
            });
            myProps.closePopup();
          }}>교재선택해제</button>
        }
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      <div className="mt-1">
        <StudyTitleListArea
          ref={StudyTitleListAreaRef}
          onStudyTitleSelect={onStudyTitleSelect}
        ></StudyTitleListArea>
      </div>
      {/* <div className={Style.box_wrap + " mt-2"}>
        <div className={Style.left_box}>

        </div>
        
      </div> */}
      <div className={Style.right_box} style={{ display: "none" }}>
        <StudyListArea
          ref={StudyListAreaRef}
        ></StudyListArea>
      </div>
    </div>
  );
}

export default FindStudyTitlePopup;
