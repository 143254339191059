function SearchArea(props:any){
   let myProps={
    listOpt:{
      s_grade:"",
      s_user_name_like:"",
      s_school_name_like:"",
    },
    list:(inData:any)=>{},
    user:{},
    ...props
  };
  
  let user=myProps.user;
  let is_manager=false;
  if(user.select_mcomp_link&&user.select_mcomp_link["a_user_grade"]){
    if(user.select_mcomp_link["a_user_grade"]=="master"
      ||user.select_mcomp_link["a_user_grade"]=="teacher"){
      is_manager=true;
    }
  }

  return (
  <div style={{height:40,fontSize:20}}>
    {is_manager&&
    <select className="search_input" name="s_grade" value={myProps.listOpt.s_grade} onChange={(e:any)=>{
        myProps.list({"now_page":"1","s_grade":e.target.value});
      }}>
      
      <option value="student">학생</option>
      <option value="parent">학부모</option>
      <option value="teacher">선생님</option>
      <option value="master">관리자</option> 
    </select>
    }
    <input type="text" className="search_input ml-1" value={myProps.listOpt.s_user_name_like} placeholder="이름"
      name="s_user_name_like"
      onChange={(e:any)=>{
        myProps.list({"now_page":"1","s_user_name_like":e.target.value});
      }}
      autoComplete="off"
      style={{width:70}}
     />
     <input type="text" className="search_input ml-1" value={myProps.listOpt.s_school_name_like} placeholder="학교"
      name="s_school_name_like"
      onChange={(e:any)=>{
        myProps.list({"now_page":"1","s_school_name_like":e.target.value});
      }}
      autoComplete="off"
      style={{width:60}}
     />
     <button className="btn btn-dark ml-1" 
      onClick={()=>{myProps.list({"now_page":"1"});}}>검색</button>
  </div>
  );
};
export default SearchArea;