import Style from "./css/style.module.css";
import ArrowArea from "./arrow";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import strFunc from "@/lib/lyg/string";
import { setUser, removeUser } from '@/store/user/user';
import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import MobileScript from "@/lib/mobile/web_script";

function TopStuSelect(props: any) {
  let myProps = {
    user: {},
    comp_stu_row_arr: [],
    select_stu_row_idx: 0,
    select_comp_stu_row: null,
    select_stu_info: {},
    arrow_color:"white",//black,white
    title_con_style:{},
    ...props
  };
  let user = myProps.user;
  const dispatch = useDispatch();
  let comp_stu_row_arr = myProps.comp_stu_row_arr;
  let select_stu_row_idx = myProps.select_stu_row_idx;
  let select_comp_stu_row = myProps.select_comp_stu_row;

  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let stu_sub_str_arr = [];
  if (!strFunc.is_empty(select_comp_stu_row.school_name)) {
    stu_sub_str_arr.push(select_comp_stu_row.school_name);
  }
  let stu_grade_str = select_comp_stu_row["stu_grade"];
  for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
    if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
      stu_grade_str = select_stu_grade_option_arr[i]["text"];
    }
  }
  stu_sub_str_arr.push(stu_grade_str);

  const on_change_child_arrow = (inData: any) => {
    let opt_obj = {
      "sort": "right",
      ...inData,
    };

    let change_select_stu_row_idx = parseInt(strFunc.uncomma(select_stu_row_idx));
    if (opt_obj["sort"] == "left") {
      change_select_stu_row_idx--;
    } else {
      change_select_stu_row_idx++;
    }
    if (change_select_stu_row_idx < 0) {
      change_select_stu_row_idx = 0;
    }
    if (change_select_stu_row_idx >= comp_stu_row_arr.length) {
      change_select_stu_row_idx = comp_stu_row_arr.length - 1;
    }
    if (select_stu_row_idx != change_select_stu_row_idx) {
      go_change_child_by_ajax({
        change_comp_stu_row: comp_stu_row_arr[change_select_stu_row_idx]
      });
    }
  };

  const go_change_child_by_ajax = (inData: any) => {
    let opt_obj = {
      change_comp_stu_row: null,
      ...inData
    };
    if (opt_obj["change_comp_stu_row"] == null) {
      return false;
    }
    if (user.user_seq == "") {
      alert("로그인 필요.");
      return false;
    }
    let link_pri_val = opt_obj["change_comp_stu_row"]["mcomp_seq"] + "," + opt_obj["change_comp_stu_row"]["par_mcomp_user_seq"];
    let w_mcomp_link_form = {
      "main_user_seq": user.user_seq,
      "mcomp_linck_pri_val": link_pri_val,
      "is_select_child": "1",
      "select_child_mcomp_user_seq": opt_obj["change_comp_stu_row"]["user_seq"],
      "select_child_name": opt_obj["change_comp_stu_row"]["user_name"],
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/user/select_mcomp_link',
      w_mcomp_link_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          //alert(response.data["msg"]);
          refreshToken({ link_pri_val: link_pri_val });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const refreshToken = (inData: any) => {
    let opt_obj = {
      link_pri_val: "",
      ...inData
    };
    let formJsonData = {
      "token_id": localStorage.intoyou_token
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/login/refresh_token', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.intoyou_token = response.data["data"]["token_id"];
          var client_info: any = response.data.data["client_info"];
          if (client_info.select_mcomp_link == null) {
            client_info.select_mcomp_link = "";
          }
          dispatch(setUser(client_info));
          //location.reload();
          if (client_info.select_mcomp_link) {
            MobileScript.toast(client_info.select_mcomp_link.comp_name, 2000);
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let stu_thum_img_url = "";
  if (myProps.select_stu_info.profile_arr && myProps.select_stu_info.profile_arr.length > 0) {
    stu_thum_img_url = myProps.select_stu_info.profile_arr[0]["thum_img_url"];
  }

  let title_con_style={
    ...myProps.title_con_style
  };

  return (
    <div>
      <div className={Style.stu_select_wrap}>
        <div className={Style.arrow}>
          <ArrowArea
            on_click={on_change_child_arrow}
            arrow_color={myProps.arrow_color}
          ></ArrowArea>
        </div>
        <div className={Style.center_thum_wrap}>
          <div className={Style.center_thum_back_circle}></div>
          {stu_thum_img_url == "" &&
            <div className={Style.center_thum_circle}></div>
          }
          {stu_thum_img_url &&
            <img src={stu_thum_img_url} style={{ borderRadius: "50%", padding: 8 }} />
          }
        </div>
        <div className={Style.arrow}>
          <ArrowArea
            on_click={on_change_child_arrow}
            left_right={"right"}
            arrow_color={myProps.arrow_color}></ArrowArea>
        </div>
        <div className={Style.top_count_wrap}>
          <div className={Style.top_count}>
            {select_stu_row_idx + 1}/{comp_stu_row_arr.length}
          </div>
        </div>
      </div>
      <div className={Style.title_wrap}>
        <div className={Style.title_con} style={title_con_style}>
          {select_comp_stu_row.user_name} ({stu_sub_str_arr.join(" ")})
        </div>
      </div>
    </div>
  );
};
export default TopStuSelect;