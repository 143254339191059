import { Link, useNavigate } from 'react-router-dom';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Style from "./css/style.module.css";
import MobileScript from "@/lib/mobile/web_script";
import strFunc from '@/lib/lyg/string';

function EtcMenuArea() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = useSelector((state: any) => state.user);

  useEffect(() => {
    
  }, []);

  let menu_data_arr: any = [
    { "title": "내정보", "url": "/mobile/setting/mypage" },
    { "title": "공지", "url": "/mobile/notice" },
    { "title": "푸시메세지", "url": "/mobile/push/list" },
    { "title": "플랜톡", "url": "/mobile/chat/room_list" },
    // { "title": "출석", "url": "/mobile/edu/attend/main" },
    { "title": "동영상강의", "url": "/mobile/plan/video/my_list" },
    // { "title": "도움말", "url": "" },
    { "title": "플랜톡", "url": "/mobile/chat/room_list" },
    { "title": "과제", "url": "/mobile/home_work" },
    { "title": "마이플랜", "url": "/mobile/my_plan" },
    { "title": "교과맵핑", "url": "/mobile/plan/study_map/list" },
    { "title": "스케줄", "url": "/mobile/sche" },
    { "title": "시간표", "url": "/mobile/edu/time_table_week" },
    { "title": "출석", "url": "/mobile/edu/attend_calendar" },
    { "title": "씨앗이", "url": "/mobile/main/char" },
  ];
  if (!strFunc.is_empty(user.select_mcomp_link)) {
    menu_data_arr.unshift({
      "title": "내정보(학원)", "url": "/mobile/setting/mypage_comp"
    });
  }

  if (MobileScript.checkMobileApp() == "other") {
    menu_data_arr.push({
      "title": "앱다운", "url": "/mobile/setting/app_down"
    });
  }

  let select_mcomp_link: any = null;
  if (!strFunc.is_empty(user.select_mcomp_link)) {
    select_mcomp_link=user.select_mcomp_link;
  }

  if (select_mcomp_link != null) {
    if (select_mcomp_link["a_user_grade"] == "master") {
      menu_data_arr.push({ "title": "출석페이지", "url": "", "key": "attend" });
    }
    if (strFunc.str_in_array(select_mcomp_link["a_user_grade"],["master","teacher"])!=-1) {
      menu_data_arr.push({ "title": "수업평가서", "url": "/mobile/manage/eval/list" });
      menu_data_arr.push({ "title": "맞춤플랜", "url": "/mobile/manage/plan/list" });
      menu_data_arr.push({ "title": "학습일지", "url": "/mobile/manage/study_diary/card_list" });
      menu_data_arr.push({ "title": "일일보고서", "url": "/mobile/manage/daily_report/card_list" });
      menu_data_arr.push({ "title": "상담관리", "url": "/mobile/manage/counsel_todo/list" });
      menu_data_arr.push({ "title": "TodoList", "url": "/mobile/manage/tea/tstu_todo/list" });
    }
  }

  const moveUrl = (url: string) => {
    if (url == "") {
      return false;
    }
    navigate(url);
  };

  const go_open_attend_activity = () => {
    if (select_mcomp_link == null) { return false; }
    if (strFunc.is_empty(select_mcomp_link["a_mcomp_seq"])) { return false; }
    let api_key = "";
    let user_seq = select_mcomp_link["a_mcomp_user_seq"];

    let comp_api_key_form_data = {
      "s_pri_arr": [select_mcomp_link["a_mcomp_seq"]],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/mcomp/list', comp_api_key_form_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            api_key = response.data["data"]["info_arr"][0]["a_api_key"];
            let comp_name = response.data["data"]["info_arr"][0]["a_name"];
            MobileScript.open_attend_activity(api_key, user_seq, comp_name);
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const getEtcMenuItemTags = () => {
    let item_tags = menu_data_arr.map((item: any, idx: number) => {
      return (
        <div className={Style.etc_menu_item} key={idx}
          onClick={() => {
            if (item.key == "attend") {
              go_open_attend_activity();
            } else {
              moveUrl(item.url);
            }
          }}
        >
          <span className={Style.item_title}>
            {item.title}
          </span>
        </div>
      );
    });
    return item_tags;
  };
  let etcItemTags = getEtcMenuItemTags();

  return (
    <div className={Style.etc_menu_wrap}>
      {etcItemTags}
    </div>
  );
}

export default EtcMenuArea;