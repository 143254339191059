let default_game_data_json:any={};
default_game_data_json["default_game_data"]={
  state: "lobby",//game,result
  sort: "plus",//minus, multiply, divide
  level: 1,
  matter_total: 20,
  limit_msec:0,
  
  score:0,
  matter_arr:[],
  matter_now_idx: 0,
  matter_correct_cnt: 0,
  matter_remain_cnt:0,
  start_msec: 0,
  elapse_msec: 0,
};
default_game_data_json["matter"]={
  type: "plus",//minus, multiply, divide
  level: 1,
  val_arr:[],
  answer:0,
  input_answer: "",
};

export default default_game_data_json;