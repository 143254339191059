import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";
import AttendBoxArea from "./attend_box";
import strFunc from "@/lib/lyg/string";
import MidTodoArea from "./mid_todo";

function CardBox(props:any){
   let myProps={
    Style:{},
    info:{},
    is_check:false,
    base_date_str:"",
    pre_date_str:"",
    on_select_one:(inData:any)=>{},
    openAttendPopup:(inData:any)=>{},
    openAttendModifyPopup:(inData:any)=>{},
    openViewTodoStuPopup:(inData:any)=>{},
    go_write_plan_page:(inData:any)=>{},
    openViewStuPopup:(inData:any)=>{},
    deleteAttend:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let base_date_str=myProps.base_date_str;
  let pre_date_str=myProps.pre_date_str;
  let attend_data=AttendStateFunc.get_attend_data_by_user_info(info);
  let attend_color=attend_data.color;
  if(attend_data.out_value!=""){
    attend_color=attend_data.out_color;
  }

  let par_name="";
  let par_phone="";
  if(info.link_user_arr&&info.link_user_arr.length>0){
    let par_info=null;
    for(let i=0;i<info.link_user_arr.length;i++){
      if(par_info==null){
        if(info.link_user_arr[i]["a_type"]=="parent"){
          par_info=info.link_user_arr[i];
        }
      }
    }
    if(par_info!=null){
      par_name=par_info["a_user_name"];
      if(par_info["par_info"]){
        par_phone=par_info["par_info"]["a_user_phone"]
      }
    }
  }

  let td_sort_data_json=info.td_sort_data_json;
  let td_sort_data_arr:any=[];
  for(let key in td_sort_data_json){
    if(key!="homework"){
      td_sort_data_arr.push(td_sort_data_json[key]);
    }
  }

  let is_char_opt=false;
  let char_gold_num=0;
  if(info.char_opt){
    is_char_opt=true;
    char_gold_num=strFunc.comma(info.char_opt.a_gold);
  }

  return (
  <div className={Style.card_box} >
    <div className={Style.card_box_title_wrap}>
      <div className={Style.card_box_title}>
        <label>
          <input type="checkbox" checked={myProps.is_check} 
            onChange={(e:any)=>{myProps.on_select_one({
              is_check:e.target.checked,
              info:myProps.info
            });}} />
          <span className="ml-1">
            {info.a_user_name}
          </span>
          {info.a_stu_total_code&&
            <span className="ml-1" style={{color:"#3fac96"}}>
              {info.a_stu_total_code}
            </span>
          }
          <span className="ml-1" style={{color:"#a9a9a9"}}>
            {strFunc.cut_str(info.a_school_name,2,"")}
          </span>
          <span className="ml-1" >{info.stu_grade_name}</span>
        </label>
        {is_char_opt&&
          <span className="ml-1" style={{fontSize:"13px"}}>양분 {char_gold_num}</span>
        }
        <button className={Style.card_box_title_right+" ml-1"}
          onClick={()=>{
            myProps.openViewStuPopup(info);
          }} >학생상세+</button>
      </div>
      <div className={Style.card_box_sub_title}>
        {/* {!strFunc.is_empty(par_name)&&
          <span className="ml-1">학부모: {strFunc.cut_str(par_name,7,"..")}</span>
        } */}
      </div>
    </div>
    <MidTodoArea
      info={info}
      Style={Style}
      td_sort_data_arr={td_sort_data_arr}
      base_date_str={base_date_str}
      pre_date_str={pre_date_str}
      openTodoDaySort={(inData:any)=>{
        myProps.openViewTodoStuPopup({
          stu_info:inData.stu_info,
          select_date:inData.select_date
        });
      }}
      go_write_plan_page={myProps.go_write_plan_page}
    ></MidTodoArea>
    <AttendBoxArea
      info={info}
      Style={Style}
      openAttendPopup={myProps.openAttendPopup}
      openAttendModifyPopup={myProps.openAttendModifyPopup}
      deleteAttend={myProps.deleteAttend}
    ></AttendBoxArea>
  </div>
  );
};
export default CardBox;