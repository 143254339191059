//import HeaderWrapArea from "@/pcomponents/common/content/layout/header_wrap";
function HeaderWrapArea(props:any){
   let myProps={
    header_style:{},
    ...props
  };
  
  return (
  <div className="m_header_simple" style={myProps.header_style}>
    {myProps.children}
  </div>
  );
};
export default HeaderWrapArea;