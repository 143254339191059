import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from "@/lib/lyg/string";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {
      's_start_date': '',
      's_end_date': '',
    },
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({
      "now_page": "1",
      [key]: date_str,
    });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(listOpt.s_start_date)) {
      listOpt.s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(listOpt.s_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_start_date": change_date_str,
      "s_end_date": change_date_str,
    });
  };

  return (
    <div className="text-center">
      <div>
        <button onClick={() => { goPrevAfter("prev"); }}>
          ◀
        </button>
        <div className="ml-1" style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_start_date != "" ? new Date(myProps.listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_end_date != "" ? new Date(myProps.listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button onClick={() => { goPrevAfter("after"); }}>
          ▶
        </button>
        <button className="btn-s btn-dark ml-1" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
            "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
          });
        }}>이번달</button>
        <button className="btn btn-dark ml-1" onClick={()=>{myProps.list({});}}>검색</button>
      </div>
    </div>
  );
};
export default SearchArea;