import StuNameBarArea from "./area/stu_name_bar";
import EvalArea from "./area/eval_area";

function CardBox(props:any){
   let myProps={
    user:{},
    info:{},
    info_arr:[],
    show_info_idx:0,
    set_show_info_idx:(inData:any)=>{},
    form_list:[],
    s_form_seq:"",
    select_stu_grade_arr:[],
    Style:{},
    is_update:false,
    set_eval_data:(inData:any)=>{},//{eval_info:{},row_num:0}
    go_write_eval:(inData:any)=>{},
    go_delete:()=>{},
    ...props
  };
  let info=myProps.info;
  let Style=myProps.Style;
  
  return (
  <div className={Style.card_box}>
    <StuNameBarArea
      Style={Style}
      row_data={info}
      info_arr={myProps.info_arr}
      select_idx={myProps.show_info_idx}
      set_select_idx={myProps.set_show_info_idx}
      select_stu_grade_arr={myProps.select_stu_grade_arr}
    ></StuNameBarArea>
    <EvalArea
      Style={Style}
      user={myProps.user}
      user_row_num={myProps.show_info_idx}
      info={info}
      form_list={myProps.form_list}
      s_form_seq={myProps.s_form_seq}
      is_update={myProps.is_update}
      set_eval_data={myProps.set_eval_data}
      go_write_eval={myProps.go_write_eval}
      go_delete={myProps.go_delete}
    ></EvalArea>
  </div>
  );
};
export default CardBox;