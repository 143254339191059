//import LoadingComponent from "@/pcomponents/common/content/loading";
function LoadingComponent(props: any) {
  let myProps = {
    is_display: true,
    text: "로딩중..",
    loading_style: {},
    text_style: {},
    ...props
  };

  let loading_style: any = {
    position: "fixed",
    left: 0,
    top: "30%",
    width: "100%",
    textAlign: "center",
    display: "none",
    zIndex: 99,
    ...myProps.loading_style,
  };
  if (myProps.is_display) {
    loading_style["display"] = "block";
  }
  let text_style: any = {
    display:"inline-block",
    color: "#379d89",
    background: "#fff",
    lineHeight: "32px",
    padding: "0px 10px",
    fontWeight: "bold",
    fontSize: 16,
    border:"1px solid #ddd",
    ...myProps.text_style
  };
  return (
    <div style={loading_style}>
      <span style={text_style}>
        {myProps.text}
      </span>
    </div>
  );
}
export default LoadingComponent;