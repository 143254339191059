import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useLocation, useNavigate } from "react-router";
import DateFunc from '@/lib/lyg/date_func';
import TodoWriteArea from "@/pages/mobile/plan/todo/commponent/write";

function TodoWritePage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  let state_obj = {
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    isUpdate: false,
    rowData: {},
    back_press_url: "/mobile/sche",
    is_comp_todo: false,
    is_comp_stu: false,
    comp_stu_info: null,
    todo_sort: "",
    list_opt: {},
    ...state
  };

  const goBackUrl = () => {
    let send_data: any = {
      list_opt: state_obj.list_opt
    };
    navigate(state_obj.back_press_url, {
      state: send_data
    });
  };

  return (
    <MobileLayout>
      <TodoWriteArea
        select_date={state_obj.select_date}
        isUpdate={state_obj.isUpdate}
        rowData={state_obj.rowData}
        is_comp_todo={state_obj.is_comp_todo}
        is_comp_stu={state_obj.is_comp_stu}
        comp_stu_info={state_obj.comp_stu_info}
        todo_sort={state_obj.todo_sort}
        list_opt={state_obj.list_opt}
        goBackUrl={goBackUrl}
      ></TodoWriteArea>
    </MobileLayout>
  );
}
export default TodoWritePage;