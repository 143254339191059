import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link, useNavigate } from 'react-router-dom';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { setUser, removeUser } from '@/store/user/user';
import { useSelector, useDispatch } from 'react-redux';
import EtcMenuArea from "./area/etc_menu";
import MobileScript from "@/lib/mobile/web_script";
import LogoutByAjax from "./func/logout";
import strFunc from "@/lib/lyg/string";
import SelectSlide from "@/pcomponents/common/content/list/select_slide";
import GroupManagerArea from "./area/group_manager";

declare global {
  interface Window {
    get_keep_screen_on_callback(value:string):void;
  }
}

function SettingMain() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = useSelector((state: any) => state.user);
  let [join_invite_code, set_join_invite_code] = useState("");

  let default_select_mcomp_link_val = "";
  if (!strFunc.is_empty(user.select_mcomp_link)) {
    default_select_mcomp_link_val = user.select_mcomp_link.a_mcomp_seq + "," + user.select_mcomp_link.a_mcomp_user_seq;
  }
  
  const [select_mcomp_link_val,set_select_mcomp_link_val]=useState(default_select_mcomp_link_val);
  const [comp_group_manager_arr,set_comp_group_manager_arr]=useState([]);
  const [keep_screen_state,set_keep_screen_state]=useState("");
  const [is_show_add_code,set_is_show_add_code]=useState(false);
  
  useEffect(() => {
    
    check_keep_screen_state();
    get_comp_group_manager_data();
    window.get_keep_screen_on_callback=(state_str:string)=>{
      set_keep_screen_state(state_str);
    };
  }, [user]);

  const check_keep_screen_state=()=>{
    let tmp_keep_screen_state=MobileScript.get_keep_screen_on();
    set_keep_screen_state(tmp_keep_screen_state);
    return  tmp_keep_screen_state;
  };

  const goLogOut = () => {
    LogoutByAjax({
      my_axios: my_axios,
      dispatch: dispatch,
      removeUser: removeUser,
      navigate: navigate,
    });
  };

  //그룹메니저여부확인
  const get_comp_group_manager_data=()=>{
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let get_comp_group_manager_form={
      "s_user_seq":user.user_seq,
      "s_addon_group_name":"1",
      "s_addon_group_u_comp_link":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/mcomp/group_manager/list', 
    get_comp_group_manager_form,get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          set_comp_group_manager_arr(response.data["data"]["info_arr"]);
        }
      });
  };

  const goInviteCode = () => {
    if (join_invite_code == "") {
      alert("초대코드 입력 필요.");
      return false;
    }
    if (!confirm("초대코드로 학원을 등록 하시겠습니까?")) {
      return false;
    }
    let invite_code_form = {
      "invite_code": join_invite_code
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/user/add_link_by_invite', 
    invite_code_form,get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          location.reload();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const saveSelectMcompLink = (link_pri_val: any) => {
    if (user.user_seq == "") {
      alert("로그인 필요.");
      return false;
    }
    let w_mcomp_link_form = {
      "main_user_seq": user.user_seq,
      "mcomp_linck_pri_val": link_pri_val,
    };
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/user/select_mcomp_link', 
    w_mcomp_link_form,get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          //alert(response.data["msg"]);
          strFunc.set_storage("s_stu_lecture_seq", "");
          strFunc.set_storage("s_stu_bookmark", "");
          set_select_mcomp_link_val(link_pri_val);
          refreshToken({link_pri_val:link_pri_val});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const refreshToken = (inData:any) => {
    let opt_obj={
      link_pri_val:"",
      ...inData
    };
    let formJsonData = {
      "token_id": localStorage.intoyou_token
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/login/refresh_token', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.intoyou_token = response.data["data"]["token_id"];
          var client_info:any = response.data.data["client_info"];
          if(client_info.select_mcomp_link==null){
            client_info.select_mcomp_link="";
          }
          dispatch(setUser(client_info));
          //location.reload();
          if(client_info.select_mcomp_link){
            MobileScript.toast(client_info.select_mcomp_link.comp_name,2000); 
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const set_user_select_mcomp_link_by_val=(link_val:string)=>{
    let update_client_info:any={
      select_mcomp_link:null,
    };
    if(!strFunc.is_empty(link_val)){
      for(let i=0;i<comp_group_manager_arr.length;i++){
        let comp_group_manager:any=comp_group_manager_arr[i];
        if(comp_group_manager["u_comp_link_arr"]){
          for(let j=0;j<comp_group_manager["u_comp_link_arr"].length;j++){
            let u_comp_link=comp_group_manager["u_comp_link_arr"][j];
            let tmp_link_pri_val=u_comp_link["a_mcomp_seq"]+","+u_comp_link["a_mcomp_user_seq"];
            if(tmp_link_pri_val==link_val){
              update_client_info.select_mcomp_link=u_comp_link;
            }
          }
        }
      }
    }
    if(update_client_info.select_mcomp_link){
      MobileScript.toast(update_client_info.select_mcomp_link.comp_name,2000); 
    }
    set_select_mcomp_link_val(link_val);
    dispatch(setUser(update_client_info));
  };

  let select_mcomp_arr = [];
  for (let i = 0; i < user.comp_link_arr.length; i++) {
    let comp_link = user.comp_link_arr[i];
    let link_pri_val = comp_link.a_mcomp_seq + "," + comp_link.a_mcomp_user_seq;
    let row_name = comp_link.comp_name + " " + comp_link.a_mcomp_user_name;
    select_mcomp_arr.push({
      "value": link_pri_val,
      "text": row_name,
      "thum_src": "",
      "data": comp_link
    });
  }

  return (
    <MobileLayout>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="30%" ></col>
            <col width="70%" ></col>
          </colgroup>
          <tbody>
            <tr>
              <th>이름</th>
              <td>
                {user.user_name}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div style={{textAlign:"center",marginBottom:5}}>
                  학원선택 <button className="btn-s2 btn-dark" onClick={()=>{set_is_show_add_code(!is_show_add_code);}} >학원추가</button>
                </div>
                <SelectSlide
                  select_arr={select_mcomp_arr}
                  select_value={select_mcomp_link_val}
                  on_click_item={(inData: any) => {
                    saveSelectMcompLink(inData.value);
                  }}
                ></SelectSlide>
                {is_show_add_code&&
                <div className="p-2 text-center">
                  <input type="text" className="row-input" style={{ width: 110, textAlign: "center" }}
                    value={join_invite_code} placeholder="초대코드"
                    onChange={(e: any) => { set_join_invite_code(e.target.value); }} />
                  <button className="btn btn-dark ml-1" onClick={() => { goInviteCode(); }}>학원등록</button>
                </div>
                }
                {comp_group_manager_arr.length>0&&
                  <GroupManagerArea
                    comp_group_manager_arr={comp_group_manager_arr}
                    select_value={select_mcomp_link_val}
                    on_click_item={(inData: any) => {
                      set_user_select_mcomp_link_by_val(inData.value);
                    }}
                  ></GroupManagerArea>
                }
              </td>
            </tr>
            {/* <tr>
              <th>화면꺼짐방지</th>
              <td>
                <button className="btn btn-dark"
                  style={{background:keep_screen_state=="1"?"green":""}}
                  onClick={()=>{
                    let change_keep_state="1";
                    if(keep_screen_state=="1"){
                      change_keep_state="";
                    }
                    set_keep_screen_state(change_keep_state);
                    MobileScript.set_keep_screen_on(change_keep_state);
                    alert("앱을 껏다켜야 적용됩니다.");
                  }}
                >{keep_screen_state=="1"?"ON":"OFF"}</button>
              </td>
            </tr> */}
            {/* <tr>
              <th>말하기</th>
              <td>
                <button className="btn btn-dark" onClick={()=>{
                  MobileScript.speechTTSMsg("말하기!");
                }}>고고</button>
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <div>
        <EtcMenuArea></EtcMenuArea>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-dark" onClick={() => { MobileScript.finishApp(); }}>앱종료</button>
        <button className="btn btn-dark" onClick={() => { goLogOut(); }}>로그아웃</button>
        {/* <button className="btn btn-dark" onClick={()=>{navigate("/mobile/test")}}>Test</button> */}
        <button className="btn btn-gray" onClick={()=>{MobileScript.set_screen_orientation("landscape");}}>가로모드</button>
        <button className="btn btn-gray" onClick={()=>{MobileScript.set_screen_orientation("portrait");}}>세로모드</button>
      </div>
    </MobileLayout>
  );
}

export default SettingMain;