import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useSelector } from 'react-redux';
import ChildDataFunc from "@/pcomponents/common/content/parent/func/child_data";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import AttendStuCalendarArea from "@/pcomponents/common/content/attend/calendar";

function AttendStuCalendarPage() {
  let user = useSelector((state: any) => state.user);
  let group_child_data = ChildDataFunc.get_child_arr_by_login_user(user);
  let comp_stu_row_arr = group_child_data.comp_stu_row_arr;
  let select_stu_row_idx = group_child_data.select_stu_row_idx;
  let select_comp_stu_row = group_child_data.select_comp_stu_row;

  let stu_seq = select_comp_stu_row ? select_comp_stu_row.user_seq : "";
  let user_data = {
    iam_mcomp_seq: select_comp_stu_row ? select_comp_stu_row.mcomp_seq : "",
    iam_mcomp_user_seq: select_comp_stu_row ? select_comp_stu_row.par_mcomp_user_seq : "",
    comp_api_key: select_comp_stu_row ? select_comp_stu_row.comp_api_key : "",
  };

  return (
    <MobileLayout has_header={false} has_footer={false}>
      <HeaderSimple title={"출결"}></HeaderSimple>
      {stu_seq &&
        <AttendStuCalendarArea
          stu_seq={stu_seq}
          user_data={user_data}
        ></AttendStuCalendarArea>
      }
    </MobileLayout>
  );
};
export default AttendStuCalendarPage;