import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import TodoListArea from "@/pages/mobile/parent/plan/common/list";

function ParTodoStudyList() {
  
  return (
    <MobileLayout has_header={false} has_footer={false}>
      <HeaderSimple title={"맞춤플랜"}></HeaderSimple>
      <TodoListArea
        s_todo_sort="study"
        s_todo_sort_except="homework"
        plan_study_file_id="plan_study_file"
        back_press_url="/mobile/parent/plan/study_list"
      ></TodoListArea>
    </MobileLayout>
  );
};
export default ParTodoStudyList;