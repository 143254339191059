function CorpInfoArea(props: any) {
  let myProps = {
    corp_info: {},
    ...props
  };
  let corp_info = myProps.corp_info;

  return (
    <div>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width={"25%"}></col>
            <col width={"75%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>연락처</th>
              <td>{corp_info.a_company_tel}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CorpInfoArea;