import Style from "./css/style.module.css";
import DateFunc from '@/lib/lyg/date_func';

function BottomArea(props:any){
   let myProps={
    row_data:{},
    go_cancel:()=>{},
    go_write:()=>{},
    go_delete:()=>{},
    isUpdate:false,
    user_grade:"",
    write_text:"수정하기",
    ...props
  };

  let is_able_del=false;
  if(myProps.isUpdate){
    if(myProps.user_grade=="master"){
      is_able_del=true;
    }else{

    }
  }

  let create_date_str=DateFunc.get_date_format(new Date(),"Y-m-d");
  if(myProps.row_data.a_create_date){
    create_date_str=myProps.row_data.a_create_date.substring(0,10);
  }
  
  return (
  <div className={Style.bottom_wrap+" noto_sans"}>
    <div className={Style.content_wrap}>
      <div className={Style.content_con}>
        <div className={Style.button_wrap}>
          <div style={{lineHeight:"22px"}}>
            <p>작성일</p>
            <p>
              {create_date_str}
            </p>
          </div>
          <div style={{flexGrow:"1",textAlign:"right"}}>
            <button className={Style.button_wrap_btn}
              onClick={()=>{myProps.go_cancel();}}>취소</button>
            {is_able_del&&
              <button className={Style.button_wrap_btn}
              style={{background:"#FF2222",marginLeft:5}}
              onClick={()=>{myProps.go_delete();}}>삭제</button>
            }
            <button className={Style.button_wrap_btn}
              style={{background:"#3FAC96",marginLeft:5}}
              onClick={()=>{myProps.go_write();}}>{myProps.write_text}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
export default BottomArea;