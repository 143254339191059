import strFunc from "@/lib/lyg/string";

function ListArea(props:any){
   let myProps={
    infoArr:[],
    listOpt:{
      now_page:1,
      num_per_page:10,
    },
    ...props
  };
  let now_page=parseInt(strFunc.uncomma(myProps.listOpt.now_page));
  let num_per_page=parseInt(strFunc.uncomma(myProps.listOpt.num_per_page));
  const get_char_opt_rank_tags=()=>{
    let char_opt_rank_tags=myProps.infoArr.map((item:any,idx:number)=>{
      
      let user_name="";
      if(item.user_info){
        user_name=item.user_info["a_user_nickname"];
        if(strFunc.is_empty(user_name)){
          user_name=strFunc.cut_str(item.user_info["a_user_name"],1,"**");
        }
      }
      let row_num=idx+1;
      row_num=row_num+((now_page-1)*num_per_page);
      
      let num_color="green";
      if(row_num<=3){
        num_color="blue";
      }else if(row_num<=10){
        num_color="red";
      }else if(row_num<=20){
        num_color="orange";
      }

      return (
        <tr key={idx}>
          <td className="text-center" style={{color:num_color}}>{row_num}</td>
          <td className="text-center">{user_name}</td>
          <td className="text-center">{strFunc.comma(item["a_gold"])}</td>
        </tr>
      );
    });

    if(strFunc.is_empty(char_opt_rank_tags)){
      char_opt_rank_tags=(
        <tr>
          <td colSpan={3}>
            <div className="text-center" style={{lineHeight:"40px",color:"#777",fontSize:"20px"}}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }

    return char_opt_rank_tags;
  };
  
  return (
  <div className="list-table-div" style={{padding:5}}>
    <table>
      <colgroup>
        <col width={"2%"}></col>
        <col width={"20%"}></col>
        <col width={"20%"}></col>
      </colgroup>
      <thead>
        <tr>
          <th>No.</th>
          <th>이름</th>
          <th>점수</th>
        </tr>
      </thead>
      <tbody>
        {get_char_opt_rank_tags()}
      </tbody>
    </table>
  </div>
  );
};
export default ListArea;