export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"mobile",name:"Plan,I",url:"/mobile",msg:"",isShow:true},
  {key:"parent",name:"Plan,I",url:"/mobile",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"mobile_main",name:"Plan,I",url:"/mobile",msg:"",hmenu:"mobile",isShow:true},
  {key:"plan_talk",name:"플랜톡",url:"/mobile/chat",msg:"",hmenu:"mobile",isShow:true},
  {key:"home_work",name:"과제",url:"/mobile/home_work",msg:"",hmenu:"mobile",isShow:true},
  {key:"home_work_file",name:"제출하기",url:"/mobile/home_work_file",msg:"",hmenu:"mobile",isShow:true},
  {key:"my_plan",name:"MY플랜",url:"/mobile/my_plan",msg:"",hmenu:"mobile",isShow:true},
  {key:"todo_view",name:"할일보기",url:"/mobile/todo/todo_view",msg:"",hmenu:"mobile",isShow:true},
  {key:"todo_write_comp",name:"플랜관리",url:"/mobile/todo/todo_write",msg:"",hmenu:"mobile",isShow:true},
  {key:"plan_main",name:"플래너",url:"/mobile/plan",msg:"",hmenu:"mobile",isShow:true},
  {key:"sche_main",name:"스케줄",url:"/mobile/sche",msg:"",hmenu:"mobile",isShow:true},
  {key:"setting_main",name:"설정",url:"/mobile/setting",msg:"",hmenu:"mobile",isShow:true},
  {key:"notice_main",name:"공지사항",url:"/mobile/notice",msg:"",hmenu:"mobile",isShow:true},
  {key:"mypage",name:"내정보",url:"/mobile/setting/mypage",msg:"",hmenu:"mobile",isShow:true},
  {key:"mypage_comp",name:"내정보(학원)",url:"/mobile/setting/mypage_comp",msg:"",hmenu:"mobile",isShow:true},
  {key:"leave_user",name:"회원탈퇴",url:"/mobile/setting/leave_user",msg:"",hmenu:"mobile",isShow:true},
  {key:"app_down",name:"앱다운",url:"/mobile/setting/app_down",msg:"",hmenu:"mobile",isShow:false},
  {key:"push_list",name:"푸시 메세지",url:"/mobile/push/list",msg:"",hmenu:"mobile",isShow:true},
  {key:"plan_talk_room",name:"플랜톡",url:"/mobile/chat/room_list",msg:"",hmenu:"mobile",isShow:true},
  {key:"plan_talk_view",name:"플랜톡",url:"/mobile/chat/chat_view",msg:"",hmenu:"mobile",isShow:true},
  {key:"plan_talk_write",name:"방만들기",url:"/mobile/chat/chat_room_write",msg:"",hmenu:"mobile",isShow:true},
  {key:"attend_main",name:"출석",url:"/mobile/edu/attend/main",msg:"",hmenu:"mobile",isShow:true},
  {key:"attend_calendar",name:"출석",url:"/mobile/edu/attend_calendar",msg:"",hmenu:"mobile",isShow:true},
  {key:"video_my_list",name:"내강의",url:"/mobile/plan/video/my_list",msg:"",hmenu:"mobile",isShow:true},
  {key:"video_my_list_view",name:"강의보기",url:"/mobile/plan/video/my_list_view",msg:"",hmenu:"mobile",isShow:true},
  {key:"video_corse_list",name:"모든과정",url:"/mobile/plan/video/corse_list",msg:"",hmenu:"mobile",isShow:true},
  {key:"login_by_url",name:"로그인",url:"/mobile/login_by_url",msg:"",hmenu:"mobile",isShow:false},
  {key:"main_char",name:"씨앗이",url:"/mobile/main/char",msg:"",hmenu:"mobile",isShow:false},
  {key:"stu_time_table_week",name:"시간표",url:"/mobile/edu/time_table_week",msg:"",hmenu:"mobile",isShow:false},
  {key:"study_map_list",name:"교과맵핑",url:"/mobile/plan/study_map/list",msg:"",hmenu:"mobile",isShow:false},

  {key:"playground",name:"수앤탐 놀이터",url:"/mobile/playground",msg:"",hmenu:"mobile",isShow:false},
  {key:"math_plus_basic",name:"더하기(기본)",url:"/playground/math/plus/basic",msg:"",hmenu:"mobile",isShow:false},
  {key:"math_minus_basic",name:"빼기(기본)",url:"/playground/math/minus/basic",msg:"",hmenu:"mobile",isShow:false},
  {key:"math_multy_basic",name:"곱하기(기본)",url:"/playground/math/multy/basic",msg:"",hmenu:"mobile",isShow:false},
  {key:"math_divide_basic",name:"나누기(기본)",url:"/playground/math/divide/basic",msg:"",hmenu:"mobile",isShow:false},

  {key:"manage_plan_list",name:"맞춤플랜",url:"/mobile/manage/plan/list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_eval_list",name:"수업평가서",url:"/mobile/manage/eval/list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_counsel_todo_list",name:"상담관리",url:"/mobile/manage/counsel_todo/list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_counsel_todo_write",name:"상담등록",url:"/mobile/manage/counsel_todo/write",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_daily_report_card_list",name:"일일보고서",url:"/mobile/manage/daily_report/card_list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_study_prescribe_card_list",name:"학습처방",url:"/mobile/manage/study_prescribe/card_list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_study_diaray_card_list",name:"학습일지",url:"/mobile/manage/study_diary/card_list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_month_prescribe_card_list",name:"월간학습처방",url:"/mobile/manage/month_prescribe/card_list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_tstu_todo_list",name:"Todo List",url:"/mobile/manage/tea/tstu_todo/list",msg:"",hmenu:"mobile",isShow:false},
  {key:"manage_counsel_daily",name:"일일&상담",url:"/mobile/manage/counsel_todo/counsel_daily",msg:"",hmenu:"mobile",isShow:false},

  {key:"parnotice_list",name:"알림장",url:"/mobile/parent/par_notice/list",msg:"",hmenu:"parent",isShow:false},
  {key:"par_homework_list",name:"과제",url:"/mobile/parent/plan/homework_list",msg:"",hmenu:"parent",isShow:false},
  {key:"par_plan_list",name:"맞춤플랜",url:"/mobile/parent/plan/study_list",msg:"",hmenu:"parent",isShow:false},
  {key:"par_attend_stu_list",name:"출결",url:"/mobile/parent/attend/stu_list",msg:"",hmenu:"parent",isShow:false},
  {key:"par_attend_calendar",name:"출결",url:"/mobile/parent/attend/calendar",msg:"",hmenu:"parent",isShow:false},
  {key:"par_manage_stu",name:"자녀관리",url:"/mobile/parent/stu/manage",msg:"",hmenu:"parent",isShow:false},
  {key:"par_stu_time_week",name:"시간표",url:"/mobile/parent/stu/time_table_week",msg:"",hmenu:"parent",isShow:false},
  {key:"par_stu_supply_one",name:"청구내역",url:"/mobile/parent/stu/supply_one_stu",msg:"",hmenu:"parent",isShow:false},
  {key:"par_supply_pay_init",name:"납부하기",url:"/mobile/parent/stu/supply_pay_init",msg:"",hmenu:"parent",isShow:false},
];