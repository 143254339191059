import CardBoxArea from "./card_box";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import Style from "./css/style.module.css";
import { default_daily_report_last_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";
import strFunc from "@/lib/lyg/string";

function CardListArea(props: any) {
  let myProps = {
    infoArr: [],
    openOnePopup: (inData: any) => { },
    list: (inData: any) => { },
    ...props
  };
  let infoArr = myProps.infoArr;
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  const get_last_daily_report_by_info = (inData: any) => {
    let opt_obj = {
      prescribe_info: {},
      ...inData
    };
    let prescribe_info=opt_obj["prescribe_info"];
    let last_daily_report = { ...default_daily_report_last_info };

    if (strFunc.is_empty(prescribe_info["a_seq"])) {
      return last_daily_report;
    }
    if (strFunc.is_empty(prescribe_info["a_stu_seq"])
      || strFunc.is_empty(prescribe_info["a_subject_name"])) {
      return last_daily_report;
    }
    if(prescribe_info.daily_info_arr&&prescribe_info.daily_info_arr.length>0){
      last_daily_report = {...default_daily_report_last_info,...prescribe_info.daily_info_arr[0]};
      return last_daily_report;
    }
    if(prescribe_info.daily_last_info_arr&&prescribe_info.daily_last_info_arr.length>0){
      last_daily_report = {...default_daily_report_last_info,...prescribe_info.daily_last_info_arr[0]};
      return last_daily_report;
    }

    return last_daily_report;
  };

  const get_card_box_tags = () => {
    let card_box_tags = infoArr.map((item: any, idx: number) => {
      let last_daily_report = get_last_daily_report_by_info({
        prescribe_info: item
      });
      return (
        <CardBoxArea key={idx}
          info={item}
          row_num={idx}
          select_stu_grade_option_arr={select_stu_grade_option_arr}
          Style={Style}
          list={myProps.list}
          openOnePopup={myProps.openOnePopup}
          last_daily_report={last_daily_report}
        ></CardBoxArea>
      );
    });
    return card_box_tags;
  };

  return (
    <div className={Style.card_list_wrap + " noto_sans"}>
      {get_card_box_tags()}
    </div>
  );
};
export default CardListArea;