function TitleLogoArea(props:any){
   let myProps={
    ...props
  };

  let logo_svg_style={
    margin:"0 auto",
    marginBottom:25,
    width:140
  };

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="152" height="89" viewBox="0 0 152 89" fill="none" style={logo_svg_style} >
        <g clipPath="url(#clip0_244_1422)">
          <path d="M52.0931 60.9353H16.9063L16.7958 57.4318L51.9826 57.5423L52.0931 60.9353ZM44.9265 52.3975L41.2592 52.508V32.8088L20.4099 31.8797L20.8478 28.323L42.7899 29.3094L45.0329 31.5523L44.9224 52.4016L44.9265 52.3975Z" fill="#9BBA40"/>
          <path d="M64.4824 24.799H85.7124L85.9334 37.9332L66.5084 38.0437L66.6189 45.3782L87.853 44.887L87.9635 48.28L76.8021 48.5543L77.1868 58.6761L93.5503 59.1672L93.4398 62.6134L56.1206 61.627L55.9037 58.234L73.36 58.672L73.0858 48.6566L65.3133 48.8203L62.743 46.5774L62.5792 34.4828L82.1147 34.5933L82.0042 28.0815L64.6011 28.2984L64.4906 24.7949L64.4824 24.799Z" fill="#9BBA40"/>
          <path d="M26.009 8.94713L32.8688 9.09038L32.8319 10.1873L20.5491 9.93352L20.4754 8.85299L24.8139 8.94303L24.7075 5.57456L25.8617 5.53773L26.0049 8.94303L26.009 8.94713ZM30.1347 7.61284H29.0009L28.9641 3.00421L23.1317 2.8405L23.275 1.66992L29.4348 1.85001L30.1715 2.58674L30.1347 7.61284ZM23.455 13.8791C23.455 12.4384 24.642 11.2678 26.9668 11.2678C29.2915 11.2678 30.4785 12.4384 30.4785 13.8791C30.4785 15.3198 29.2915 16.5067 26.9668 16.5067C24.642 16.5067 23.455 15.3362 23.455 13.8791ZM29.2547 13.8791C29.2547 13.0155 28.3911 12.332 26.9668 12.332C25.5424 12.332 24.6788 13.0155 24.6788 13.8791C24.6788 14.7427 25.5056 15.463 26.9668 15.463C28.4279 15.463 29.2547 14.7591 29.2547 13.8791Z" fill="#6F561D" stroke="#6F561D" strokeWidth="0.57" strokeMiterlimit="10"/>
          <path d="M40.899 11.1286L35.0093 11.0017L34.9725 9.86799L47.3986 10.1218L47.4354 11.2023L42.1228 11.1286V15.2174L40.899 15.2543V11.1286ZM37.0271 0.826741V0.683489L38.1813 0.646653L38.3982 3.47486L43.3507 3.25793L43.187 0.646653L44.3412 0.593445L44.7545 7.86656L38.2182 8.13669L37.4446 7.46954L37.0312 0.826741H37.0271ZM43.5267 6.65915L43.4203 4.37121L38.431 4.58813L38.6111 6.76556L43.5267 6.65915Z" fill="#6F561D" stroke="#6F561D" strokeWidth="0.57" strokeMiterlimit="10"/>
          <path d="M48.8761 9.34414L51.8312 4.55543L51.7043 1.36705L52.8912 1.29337L53.0713 4.37125L56.7631 8.16947L56.1328 9.08629L52.6047 5.68098L49.8666 9.96626L48.8761 9.33595V9.34414ZM53.7385 13.7031C53.7385 12.2624 54.9254 11.1082 57.2502 11.1082C59.575 11.1082 60.7619 12.2624 60.7619 13.7031C60.7619 15.1438 59.575 16.3512 57.2502 16.3512C54.9254 16.3512 53.7385 15.197 53.7385 13.7031ZM59.5381 13.7031C59.5381 12.8395 58.6909 12.156 57.2502 12.156C55.8095 12.156 54.9623 12.8395 54.9623 13.7031C54.9623 14.5667 55.8095 15.2871 57.2502 15.2871C58.6909 15.2871 59.5381 14.5831 59.5381 13.7031ZM60.7619 0.826782L60.8356 10.0809H59.6487L59.575 4.94835L55.433 4.98518V3.81461L59.575 3.77777V0.863618L60.7619 0.826782Z" fill="#6F561D" stroke="#6F561D" strokeWidth="0.57" strokeMiterlimit="10"/>
          <path d="M70.0897 1.7804L70.1265 2.89777H67.679L67.6053 4.77232L71.1539 8.32088L70.4499 9.2377L67.065 6.03294L64.2573 9.59787L63.3732 8.68106L66.3611 4.80916L66.4675 2.90186L63.5492 2.9387L63.5124 1.75175L70.0856 1.78859L70.0897 1.7804ZM67.282 13.7031C67.282 12.2624 68.4894 11.1082 70.7937 11.1082C73.098 11.1082 74.3218 12.2624 74.3218 13.7031C74.3218 15.1438 73.1144 16.3512 70.7937 16.3512C68.473 16.3512 67.282 15.197 67.282 13.7031ZM73.0816 13.7031C73.0816 12.8395 72.2344 12.1559 70.7937 12.1559C69.353 12.1559 68.5058 12.8395 68.5058 13.7031C68.5058 14.5667 69.353 15.287 70.7937 15.287C72.2344 15.287 73.0816 14.583 73.0816 13.7031ZM72.9547 0.720337H74.1417V4.48582L76.8799 4.52266V5.69323L74.1417 5.65639L74.068 10.0849H72.8811L72.9547 0.720337Z" fill="#6F561D" stroke="#6F561D" strokeWidth="0.57" strokeMiterlimit="10"/>
          <path d="M86.134 8.17354L86.2404 9.27044L77.9399 8.9471L77.8663 7.86657L79.3438 7.94024L79.9578 2.66447L78.2305 2.5908L78.3738 1.42023L85.6142 1.85407L85.5405 3.00828L84.243 2.9346L84.1366 8.08349L86.134 8.17354ZM81.0751 2.80772L80.6617 7.94024L82.9497 8.01391L83.0397 2.89777L81.0751 2.80772ZM82.172 15.8437L82.2089 11.5215L83.3958 11.5584L83.3221 15.2134L89.4451 15.1765L89.482 16.3103L82.6591 16.4003L82.172 15.8437ZM87.4478 0.720337H88.6347V4.48582L91.3729 4.52266V5.69323L88.6347 5.65639L88.5611 11.1327H87.3741L87.4478 0.72443V0.720337Z" fill="#6F561D" stroke="#6F561D" strokeWidth="0.57" strokeMiterlimit="10"/>
          <path d="M139.289 29.4117L93.3457 33.2632L93.3088 29.1661L138.851 25.3475L139.289 29.4117ZM102.129 38.3834L133.338 36.0422L134.688 50.4575L105.498 52.7823L106.054 58.3855L138.752 55.3281L139.186 59.3268L104.085 62.6339L101.736 60.8617L100.566 48.8531L129.711 46.8025L129.085 40.262L102.567 42.3863L102.133 38.3875L102.129 38.3834ZM130.825 16.0033L131.524 19.9816L99.6979 21.2259L99.1249 17.1698L104.757 17.0143L105.674 5.05068L100.021 4.93608L100.23 0.552566L129.363 0.114624L129.417 4.41219L124.787 4.48995L124.779 16.0483L130.825 15.9992V16.0033ZM110.303 4.91152L109.57 16.6582L120.281 16.122L120.363 4.6905L110.299 4.90743L110.303 4.91152Z" fill="#6F561D" stroke="#6F561D" strokeWidth="0.57" strokeMiterlimit="10"/>
          <path d="M4.33704 65.1101C12.7766 69.7801 21.7974 73.0504 31.0106 75.6412C34.0312 76.513 37.1868 77.2333 40.236 77.9373C42.3561 78.4203 45.0779 78.8623 47.2226 79.3084C49.9567 79.7382 52.6948 80.1679 55.433 80.5363C57.1193 80.7164 59.6323 80.9865 61.3268 81.1748C63.9872 81.4777 68.1129 81.6373 70.7855 81.8583C74.4569 81.9115 78.9877 82.1325 82.6427 82.0016C99.968 81.8092 117.514 79.3575 133.882 73.5047C139.862 71.3027 145.792 68.7651 151.162 65.2861C150.033 66.4567 148.772 67.4799 147.507 68.499C135.151 77.966 119.917 83.1108 104.736 85.9512C103.255 86.1682 98.9939 86.7944 97.4386 87.0154C95.2039 87.2978 92.3593 87.4615 90.1 87.6825C78.7012 88.4479 67.1265 87.9895 55.8341 86.2173C53.407 85.9062 50.9881 85.3496 48.5897 84.8789C46.3426 84.4737 43.6495 83.7206 41.4311 83.1558C35.1403 81.4981 28.9232 79.2184 22.9803 76.5744C21.9079 76.0587 19.6527 74.9699 18.5804 74.4379C17.4548 73.8116 15.4207 72.7598 14.2951 72.0844C10.8489 70.0175 7.34124 67.7869 4.33295 65.106L4.33704 65.1101Z" fill="#9BBA40"/>
          <path d="M11.3072 85.632C11.3072 85.632 11.7861 84.7807 9.87881 82.0016C8.02062 79.2961 7.46399 76.2101 11.827 71.2945C11.827 71.2945 16.4766 75.1623 14.3278 80.9988C12.7807 85.2063 11.3113 85.6279 11.3113 85.6279L11.3072 85.632Z" fill="#9BBA40"/>
          <path d="M0.145847 74.8062C0.145847 74.8062 0.636997 74.2455 3.15414 74.8553C5.6058 75.4529 7.98787 75.0313 10.4027 70.6642C10.4027 70.6642 6.37527 68.409 2.70801 71.4582C0.0639889 73.6602 0.145847 74.8062 0.145847 74.8062Z" fill="#9BBA40"/>
          <path d="M139.182 27.7704C139.182 27.7704 139.972 27.5658 140.578 24.7785C141.163 22.0649 142.727 19.9202 148.211 19.7074C148.211 19.7074 148.514 24.799 143.729 26.9027C140.283 28.4171 139.186 27.7704 139.186 27.7704H139.182Z" fill="#9BBA40"/>
        </g>
      </svg>
    </div>
  );
};
export default TitleLogoArea;