import strFunc from "@/lib/lyg/string";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import DateFunc from '@/lib/lyg/date_func';

function SearchTimeBox(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    show_group_time: {},
    set_group_time: (inData: any) => { },
    able_time_arr_ref: null,
    infoArr: [],
    openAddGoldPopup: (inData:any) => { },
    ...props
  };
  let select_able_time_arr = [];
  if (myProps.able_time_arr_ref) {
    for (let i = 0; i < myProps.able_time_arr_ref.current.length; i++) {
      let item = myProps.able_time_arr_ref.current[i];
      if (!strFunc.is_empty(item)) {
        select_able_time_arr.push({ value: item, text: item });
      }
    }
  }

  return (
    <div className="search_wrap mt-2" style={{ position: "relative" }} >
      <span style={{ position: "absolute", left: 5 }}>
        <button className="btn-s2 btn-dark" onClick={()=>{myProps.openAddGoldPopup({});}}>양분주기</button>
      </span>
      <SelectBoxBar
        valueTextArr={[
          { value: "", text: "전체" },
          { value: "now", text: "현재" },
          { value: "select", text: "선택" },
        ]}
        value={myProps.show_group_time.sort}
        on_change={(inData: any) => {
          let change_group_time = {
            ...myProps.show_group_time,
            sort: inData.value,
          };
          if (inData.value != "select") {
            strFunc.set_storage("change_group_time_sort", inData.value);
          }
          if (inData.value == "") {
            change_group_time.select_time_arr = [];
          } else if (inData.value == "now") {
            let now_date_h = parseInt(DateFunc.get_date_json(new Date()).h);
            let select_time_str = "";
            if (myProps.able_time_arr_ref) {
              for (let i = 0; i < myProps.able_time_arr_ref.current.length; i++) {
                let item = myProps.able_time_arr_ref.current[i];
                if (select_time_str == "" && item) {
                  let item_h = parseInt(strFunc.cut_str(item, 2, ""));
                  if (item_h >= now_date_h) {
                    select_time_str = item;
                  }
                }
              }
            }
            change_group_time.select_time_arr = [select_time_str];
          }
          myProps.set_group_time(change_group_time);
        }}
      ></SelectBoxBar>
      <span style={{ position: "absolute", right: 5 }}>
        <select
          className="border"
          value={myProps.show_group_time.select_time_arr.length > 0 ? myProps.show_group_time.select_time_arr[0] : ""}
          onChange={(e: any) => {
            let tmp_sort_str = "select";
            if (e.target.value == "") {
              tmp_sort_str = "";
            }
            myProps.set_group_time({
              ...myProps.show_group_time,
              sort: tmp_sort_str,
              select_time_arr: [e.target.value],
            });
          }} >
          <option value="">전체</option>
          {select_able_time_arr.map((item: any, index: number) => {
            return (
              <option key={index} value={item.value}>{item.text}</option>
            );
          })}
        </select>
        <span className="ml-1" style={{ color: "blue" }}>
          {myProps.infoArr.length} 건
        </span>
      </span>
    </div>
  );
};
export default SearchTimeBox;