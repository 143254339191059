import { useNavigate } from 'react-router-dom';
import right_arrow from "@/img/ico/arrow/right_arrow.png";

function AttendTodayArea(props:any){
   let myProps={
    Style:{},
    comp_main_data:{
      today_attend_stat:null,
    },
    ...props
  };
  let Style=myProps.Style;
  const navigate = useNavigate();

  const go_attend_list_page=(s_state:string)=>{
    let send_data={
      "title_list_sort":"list",
      "s_attend_sort_detail":s_state,
    };
    let view_url="/mobile/manage/plan/list";
    navigate(view_url,
      { state: send_data });
  };

  let default_today_attend_stat={
    "tot":0,
    "attend":0,
    "late":0,
    "absent":0,
  };
  let today_attend_stat={
    ...default_today_attend_stat,
    ...myProps.comp_main_data.today_attend_stat
  };
  
  return (
  <div className={Style.main_box_wrap}>
    <div className={Style.main_box_con}>
      <div className={Style.main_box_title}>
        출결
        <span className={Style.main_box_title_right}>
          <a style={{color:"#373737",cursor:"pointer",fontSize:"12px",fontWeight:300}}
             onClick={()=>{
              go_attend_list_page("");
             }}>
              <img src={right_arrow} style={{ height: "15px",marginBottom:4 }} alt="" />
             </a>
        </span>
      </div>
      <div className={Style.attend_tot_wrap}>
        <div className={Style.attend_tot_item} onClick={()=>{go_attend_list_page("");}}>
          <div className={Style.attend_tot_item_title}>{today_attend_stat.tot}명</div>
          <div className={Style.attend_tot_item_sub_text}>전체</div>
        </div>
        <div className={Style.attend_tot_item} onClick={()=>{go_attend_list_page("attend");}}>
          <div className={Style.attend_tot_item_title} style={{color:"#5CB600"}}>{today_attend_stat.attend}명</div>
          <div className={Style.attend_tot_item_sub_text}>출석</div>
        </div>
        <div className={Style.attend_tot_item} onClick={()=>{go_attend_list_page("late");}}>
          <div className={Style.attend_tot_item_title} style={{color:"#EDCB40"}}>{today_attend_stat.late}명</div>
          <div className={Style.attend_tot_item_sub_text}>지각</div>
        </div>
        <div className={Style.attend_tot_item} onClick={()=>{go_attend_list_page("absent");}}>
          <div className={Style.attend_tot_item_title} style={{color:"#E6494E"}} >{today_attend_stat.absent}명</div>
          <div className={Style.attend_tot_item_sub_text}>결석</div>
        </div>
      </div>
    </div>
  </div>
  );
};
export default AttendTodayArea;