import { useSelector, useDispatch } from 'react-redux';
import LoadingComponent from "@/pcomponents/common/content/loading";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import StuNameBarArea from "@/pages/mobile/manage/counsel_todo/write/area/stu_name_bar";
import WriteArea from "@/pages/mobile/manage/counsel_todo/write/area/write_area";
import FileFormArea from "@/pages/mobile/manage/counsel_todo/write/area/file_area";
import BottomArea from "@/pages/mobile/manage/counsel_todo/write/area/bottom_area";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudentPopup from "@/pages/mobile/manage/counsel_todo/popup/find_stu";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

function WriteCounselTodoPopup(props: any) {
  let myProps = {
    select_mcomp_seq: "",
    select_mcomp_api_key:"",
    select_mcomp_user_seq:"",
    isUpdate: false,
    row_data: {},
    stu_seq: "",
    stu_name: "",
    list: (inData: any) => { },
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data = UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq: myProps.select_mcomp_seq,
    default_mcomp_api_key: myProps.select_mcomp_api_key,
    default_mcomp_user_seq: myProps.select_mcomp_user_seq,
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  let select_arr_stu_grade: any = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let select_arr_a_kind: any = XcolumnFunc.getSortByTableKey({ 'table': 'counsel_todo', 'key': 'a_kind' });
  let select_arr_eval_score = [
    { "value": "100", "text": "A" },
    { "value": "80", "text": "B" },
    { "value": "60", "text": "C" },
    { "value": "40", "text": "D" },
    { "value": "20", "text": "E" },
  ];
  const [isUpdate, setIsUpdate] = useState(myProps.isUpdate);
  const [pageData, setPageData] = useState({
    "write_btn_text": "등록하기",
    "confirm_msg": "등록 하시겠습니까?",
  });
  const [is_display_load, set_is_display_load] = useState(false);

  let default_row_data = {
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_stu_seq": myProps.stu_seq,
    "a_stu_name": myProps.stu_name,
    "a_sort1": "",
    "a_sort2": "",
    "a_kind": "학습",

    "a_counsel_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_reserve_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_writer_seq": iam_mcomp_user_seq,
    "a_writer": user.user_name,
    "a_par_id": "",
    "a_par_seq": "",
    "a_counseler_seq": iam_mcomp_user_seq,
    "a_counseler": user.user_name,
    "a_title": "상담",
    "a_content": "",
    "a_feedback": "",
    "a_eval_score": "",
    "a_reference": "",
    "a_is_success": "1",
    "a_state": "complete",
  };
  const [row_data, set_row_data] = useState({
    ...default_row_data,
    ...myProps.row_data
  });

  const [refresh, set_refresh] = useState(false);
  const FileFormAreaRef = useRef<any>(null);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_stu",
    title: "학생찾기",
    y: "0",
    width: "500px",
    height: "100%",
    is_border_radius: false,
    has_header: false,
  });

  useEffect(() => {
    if (isUpdate) {
      if (strFunc.is_empty(row_data.a_seq)) {
        alert("키가 없습니다.");
        myProps.closePopup();
      }
      setPageData({
        "write_btn_text": "수정하기",
        "confirm_msg": "수정 하시겠습니까?",
      });
      list();
    } else {
      //set_row_data(default_row_data);
      setPageData({
        "write_btn_text": "등록하기",
        "confirm_msg": "등록 하시겠습니까?",
      });
    }
  }, []);

  const list = () => {
    if(strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(row_data.a_seq)){
      return false;
    }
    let row_pri_val=row_data.a_ymd+","+row_data.a_seq;
    let list_form_json_data={
      api_key:comp_api_key,
      api_user_seq:iam_mcomp_user_seq,
      s_pri_arr:[row_pri_val],
      "s_addon_file":"1",
      "s_addon_user":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if(response.data["data"]["info_arr"].length>0){
            set_row_data(response.data["data"]["info_arr"][0]);
          }else{
            alert("데이터가 없습니다.");
            myProps.closePopup();
          }
        } else {
          alert(response.data["msg"]);
          myProps.closePopup();
        }
      });
  };

  const goWrite = (e: any) => {
    if (row_data.a_stu_seq == "") {
      alert("원생 선택이 필요합니다.");
      return false;
    }
    if (row_data.a_title == "") {
      alert("제목 입력이 필요합니다.");
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wrow_data = { ...row_data };
    if (!isUpdate) {
      wrow_data["a_ymd"] = strFunc.str_replace("-", "", wrow_data["a_reserve_date"]);
    }
    let formJsonData = {
      "data_arr": [wrow_data],
      "is_default_val": "1",
      "is_update": "",
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq
    };
    if (isUpdate) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/write', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let file_input = FileFormAreaRef.current.get_file_input();
          if (file_input && file_input.value == "") {
            alert("저장 하였습니다.");
            myProps.list({});
            myProps.closePopup();
          } else {
            go_write_file({
              "row_data": response.data["data"][0]
            });
          }

        } else {
          alert(response.data["msg"]);
        }
      });

  };

  const go_write_file = (inData: any) => {
    let opt_obj = {
      row_data: {},
      ...inData
    };
    let pri_val = opt_obj.row_data.a_ymd + "," + opt_obj.row_data.a_seq;
    set_is_display_load(true);
    FileFormAreaRef.current.goWrite({
      file_row_data: {
        "a_par_id": "counsel_todo",
        "a_par_seq": pri_val
      },
    });
  };

  const goDelete = () => {
    if (strFunc.is_empty(row_data.a_seq)) {
      alert("키가 없습니다.");
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    if (!isUpdate) {
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let wrow_data = { ...row_data };
    let formJsonData = {
      "data_arr": [wrow_data],
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/delete', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 하였습니다.");
          myProps.list({});
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const open_find_stu_popup = () => {
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "find_stu",
    });
  };
  const callback_find_stu = (inData: any) => {
    let opt_obj = {
      "data": [],
      ...inData
    };
    if (opt_obj.data.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    }
    if (opt_obj.data.length != 1) {
      alert("한명만 선택 해주세요.");
      return false;
    }

    let select_stu_info = opt_obj.data[0];
    let change_row_data = {
      ...row_data,
      "a_stu_seq": select_stu_info.a_seq,
      "a_stu_name": select_stu_info.a_user_name,
    };
    set_row_data(change_row_data);
    set_refresh(!refresh);
  };
  let header_simple_title = "상담관리";

  return (
    <div>
      <LoadingComponent
        is_display={is_display_load}
        text={"저장중.."}
      ></LoadingComponent>
      <div style={{ marginBottom: 80 }}>
        <StuNameBarArea
          row_data={row_data}
          info_arr={[row_data]}
          isUpdate={isUpdate}
          select_arr_stu_grade={select_arr_stu_grade}
          open_find_stu_popup={open_find_stu_popup}
          select_idx={0}
          set_select_idx={(inData: any) => {

          }}
        ></StuNameBarArea>
        <WriteArea
          row_data={row_data}
          set_row_data={set_row_data}
          select_arr_a_kind={select_arr_a_kind}
          select_arr_eval_score={select_arr_eval_score}
        ></WriteArea>
        <FileFormArea
          ref={FileFormAreaRef}
          select_mcomp_seq={myProps.select_mcomp_seq}
          row_data={row_data}
          set_row_data={(inData: any) => {
            set_row_data(inData);
            set_refresh(!refresh);
          }}
          callback={(inData: any) => {
            set_is_display_load(false);
            myProps.list({});
            myProps.closePopup();
          }}
        ></FileFormArea>
        <BottomArea
          row_data={row_data}
          go_delete={goDelete}
          go_write={goWrite}
          go_cancel={() => {
            myProps.closePopup();
          }}
          isUpdate={isUpdate}
          user_grade={iam_user_grade}
          write_text={pageData.write_btn_text}
        ></BottomArea>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius}
          has_header={popupData.has_header} >
          {popupData.sort === "find_stu" &&
            <FindStudentPopup
              callback={callback_find_stu}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default WriteCounselTodoPopup;