import AddAttendPage from "@/pcomponents/common/content/attend/add_attend";
import { useSelector, useDispatch } from 'react-redux';

function AddAttendPopup(props: any) {
  let myProps = {
    attend_sort: "in",
    attend_sort_detail: "attend",
    refresh_data: () => { },
    closePopup: () => { },
    ...props
  };
  let user=useSelector((state:any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  return (
    <div>
      <div className="text-center" style={{height:"40px",lineHeight:"40px",background:"#E4EFE9",position:"relative"}} >
        <span style={{color:"#7A9E1D"}}>출석 체크</span>
        <button className="btn btn-gray" onClick={myProps.closePopup}
          style={{ position: "absolute", right: 5, top: 5, background: "#7A9E1D" }}
        >X</button>
      </div>
      <AddAttendPage
        attend_sort={myProps.attend_sort}
        attend_sort_detail={myProps.attend_sort_detail}
        mcomp_stu_seq={iam_mcomp_user_seq}
        user_data={{
          iam_mcomp_seq: iam_mcomp_seq,
          iam_mcomp_user_seq: iam_mcomp_user_seq,
          comp_api_key: comp_api_key,
        }}
        call_back={()=>{
          myProps.refresh_data();
          myProps.closePopup();
        }}
      ></AddAttendPage>
    </div>
  );
};
export default AddAttendPopup;