import notice_ico_green2_100 from "@/img/mobile/home/notice_ico_green2_100.png";
import strFunc from "@/lib/lyg/string";
import { text } from "stream/consumers";

function ButtonArea(props:any){
   let myProps={
    "sort":"refresh",//refresh,option
    button_style:{},
    text:"갱신",
    badge_num:0,
    onclick:()=>{},
    ...props
  };
  
  const get_button_icon_tag=()=>{
    let button_icon_tag:any="";
    let svg_style={
      display:"inline-block"
    };
    if(myProps.sort=="refresh"){
      button_icon_tag=(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none" style={svg_style}>
          <path fillRule="evenodd" clipRule="evenodd" d="M7.36331 14.499C10.6123 14.499 13.2462 11.8282 13.2462 8.53356C13.2462 5.23894 10.6123 2.56813 7.36331 2.56813C4.1143 2.56813 1.48045 5.23894 1.48045 8.53356C1.48045 11.8282 4.1143 14.499 7.36331 14.499ZM7.36331 16.0002C11.43 16.0002 14.7266 12.6573 14.7266 8.53356C14.7266 4.40983 11.43 1.06689 7.36331 1.06689C3.29667 1.06689 0 4.40983 0 8.53356C0 12.6573 3.29667 16.0002 7.36331 16.0002Z" fill="#40AD8C"/>
          <path d="M7.36328 0H15.7785V4.26667L7.36328 8.53333V0Z" fill="white"/>
          <path d="M11.1207 8.52656L13.1006 4.87684L16.8 7.40805" stroke="#40AD8C" strokeWidth="1.4"/>
        </svg>
      );
    }else if(myProps.sort=="option"){
      button_icon_tag=(
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style={svg_style}>
          <path d="M15.8766 9.882C15.9136 9.594 15.9414 9.306 15.9414 9C15.9414 8.694 15.9136 8.406 15.8766 8.118L17.8289 6.633C18.0047 6.498 18.051 6.255 17.9399 6.057L16.0894 2.943C15.9784 2.745 15.7286 2.673 15.525 2.745L13.2212 3.645C12.74 3.285 12.2219 2.988 11.6575 2.763L11.3059 0.378C11.2782 0.162 11.0839 0 10.8525 0H7.15155C6.92024 0 6.72593 0.162 6.69818 0.378L6.34658 2.763C5.78218 2.988 5.26404 3.294 4.78291 3.645L2.47905 2.745C2.26624 2.664 2.02567 2.745 1.91464 2.943L0.0641489 6.057C-0.0561333 6.255 -0.000618345 6.498 0.175179 6.633L2.12745 8.118C2.09044 8.406 2.06268 8.703 2.06268 9C2.06268 9.297 2.09044 9.594 2.12745 9.882L0.175179 11.367C-0.000618345 11.502 -0.0468809 11.745 0.0641489 11.943L1.91464 15.057C2.02567 15.255 2.27549 15.327 2.47905 15.255L4.78291 14.355C5.26404 14.715 5.78218 15.012 6.34658 15.237L6.69818 17.622C6.72593 17.838 6.92024 18 7.15155 18H10.8525C11.0839 18 11.2782 17.838 11.3059 17.622L11.6575 15.237C12.2219 15.012 12.74 14.706 13.2212 14.355L15.525 15.255C15.7378 15.336 15.9784 15.255 16.0894 15.057L17.9399 11.943C18.051 11.745 18.0047 11.502 17.8289 11.367L15.8766 9.882ZM9.00204 12.15C7.21632 12.15 5.76368 10.737 5.76368 9C5.76368 7.263 7.21632 5.85 9.00204 5.85C10.7878 5.85 12.2404 7.263 12.2404 9C12.2404 10.737 10.7878 12.15 9.00204 12.15Z" fill="#40AD8C"/>
        </svg>
      );
    }else if(myProps.sort=="push"){
      button_icon_tag=(
        <div style={{...svg_style,width:18}} >
          <img src={notice_ico_green2_100} alt="notice_ico_green2_100" />
        </div>
      );
    }
    return button_icon_tag;
  };

  let button_style:any={
    position:"relative",
    display:"inline-block",
    width:41,
    height:41,
    borderRadius:"41px",
    textAlign:"center",
    background:"#fff",
    color:"#3FAC96",
    fontSize:"9px",
    fontWeight:500,
    lineHeight:"15px",
    cursor:"pointer",
    paddingTop:6,
    ...myProps.button_style
  };

  let badge_num_style:any={
    position:"absolute",
    top:-5,
    right:-5,
    display:"inline-block",
    width:16,
    height:16,
    textAlign:"center",
    borderRadius:"16px",
    background:"#FF0000",
    color:"#fff",
    fontSize:"9px",
    fontWeight:500,
    lineHeight:"16px",
  };

  return (
  <div style={button_style} onClick={myProps.onclick}>
    {get_button_icon_tag()}<br />
    {myProps.text}
    {!strFunc.is_empty(myProps.badge_num)&&
      <span style={badge_num_style}>
        {myProps.badge_num}
      </span>
    }
  </div>
  );
};
export default ButtonArea;
