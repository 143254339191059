import DatePicker from "react-datepicker";
import DateFunc from '@/lib/lyg/date_func';
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    let select_month_obj = new Date(myProps.listOpt.s_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_start_date": change_date_str,
      "s_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    myProps.list({
      "now_page": "1",
      [key]: date_str,
    });
  };

  return (
    <div>
      <div style={{ position: "relative", textAlign: "center", marginTop: 10 }}>
        <button className="mr-1" onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div className="ml-1" style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_start_date != "" ? new Date(myProps.listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div> ~
        <div className="ml-1" style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_end_date != "" ? new Date(myProps.listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="ml-1" onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
      </div>
    </div>
  );
};
export default SearchArea;