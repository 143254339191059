import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import { useState,useRef,useEffect } from 'react';
import carrot_smile_400 from "@/img/mobile/main/mobile_icon150.png";
import love_ico_50 from "@/img/mobile/char/love_ico_50.png";
import smile_shadow_40 from "@/img/mobile/main/mobile_icon150.png";

function IntroMain(){
  const navigate = useNavigate();
  useEffect(()=>{
    setTimeout(()=>{
      goHome();
    },200);
  },[]);

  const goHome=()=>{
    navigate("/mobile");
  };

  const [intervalCnt,setIntervalCnt]=useState(0);
  const intervalCntRef=useRef<number>(0);
  const colorNum=useRef<number>(0);
  const colorState=useRef<number>(-1);

  const intervalRef = useRef<any>();

  const smile_animation_loop=()=>{
    
    colorNum.current=colorNum.current+(5*colorState.current);
    if(colorNum.current<0){
      colorState.current=1;
    }else if(colorNum.current>=100){
      colorState.current=-1;
    }
    intervalCntRef.current=intervalCntRef.current+50;
    setIntervalCnt(intervalCntRef.current);
  };

  useEffect(() => {
    intervalRef.current=smile_animation_loop;
    const tick:any=()=>{
      intervalRef.current();
    }

    const timer = setInterval(tick, 50);
    return () => clearInterval(timer);
  }, []);

  let main_wrap_style={
    width:"100%",
    height:"100%",
    display: "flex",
    justifyContent: "center",
    background:"#c2cd74"//74
  };

  let carrot_img_style:any={
    position: "absolute",
    left:0,
    top:300,
    width:80,
    height:80,
    zIndex:10,
  };
  let love_img_style2:any={
    width:21,
    height:21,
    position: "absolute",
    top:292,
    right:colorNum.current/20+10,
    rotate:40-colorNum.current/3+"deg",
    zIndex:20,
  };
  let shadow_style:any={
    position: "absolute",
    left:12,
    top:343,
    width:50,
    height:40,
    zIndex:1,
  };
  let hi_style:any={
    position: "absolute",
    left:25,
    top:260,
    fontSize:20,
    zIndex:1,
  };

  return (
    <MobileLayout is_home_body={true}
      has_header={false}
      has_footer={false}>
      
      <div style={main_wrap_style} onClick={()=>{goHome();}}>
        <div style={{width:80,height:80,position:"relative"}}>
          {intervalCntRef.current>500&&
            <span style={hi_style}>Hi ~ </span>
          }
          <img src={carrot_smile_400} style={carrot_img_style} />
          <img src={love_ico_50} style={love_img_style2} />
          <img src={smile_shadow_40} style={shadow_style} />
        </div>
      </div>

    </MobileLayout>
  );
}

export default IntroMain;