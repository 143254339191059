//import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";

class AttendStateFunc {
  static get_attend_data_by_user_info(user_info: any) {
    let attend_in_info = null;
    if (user_info.attend_in_arr && user_info.attend_in_arr.length > 0) {
      attend_in_info = user_info.attend_in_arr[0];
    }
    let attend_out_info = null;
    if (user_info.attend_out_arr && user_info.attend_out_arr.length > 0) {
      attend_out_info = user_info.attend_out_arr[0];
    }
    let attend_data = this.get_attend_data_by_in_out_info({
      in_info: attend_in_info,
      out_info: attend_out_info
    });

    return attend_data;
  }
  static get_attend_data_by_in_out_info(inData: any) {
    let opt_obj = {
      in_info: null,
      out_info: null,
      ...inData
    };
    let attend_data: any = {
      "text": "미등원",
      "value": "",
      "color": "#707070",
      "out_text": "",
      "out_value": "",
      "out_color": "",
      "attend_in_info": opt_obj.in_info,
      "attend_out_info": opt_obj.out_info,
    };
    let attend_in_info = opt_obj.in_info;
    let attend_out_info = opt_obj.out_info;

    let attend_color_arr = [
      { "value": "attend", "text": "등원", color: "#53a500" },
      { "value": "late", "text": "등원(지각)", color: "#eabf0b" },
      { "value": "absent", "text": "결석", color: "#f26b6f" },
      { "value": "out", "text": "하원", color: "#7461ff" },
    ];

    if (attend_in_info != null) {
      for (let i = 0; i < attend_color_arr.length; i++) {
        if (attend_in_info["a_sort_detail"] == attend_color_arr[i]["value"]) {
          attend_data = {
            ...attend_data,
            ...attend_color_arr[i]
          };
        }
      }
    }
    if (attend_out_info != null) {
      for (let i = 0; i < attend_color_arr.length; i++) {
        if (attend_out_info["a_sort_detail"] == attend_color_arr[i]["value"]) {
          attend_data = {
            ...attend_data,
            ...{
              "out_text": attend_color_arr[i]["text"],
              "out_value": attend_color_arr[i]["value"],
              "out_color": attend_color_arr[i]["color"],
            }
          };
        }
      }
    }

    return attend_data;
  };
  static get_in_out_attend_info_data(inData: any) {
    let opt_obj = {
      attend_info_arr: [],
      ...inData
    };
    let in_out_info_data = {
      in_info: null,
      out_info: null,
    };

    for (let i = 0; i < opt_obj["attend_info_arr"].length; i++) {
      let attend_info = opt_obj["attend_info_arr"][i];
      if (in_out_info_data["in_info"] == null) {
        if (attend_info["a_sort"] == "in") {
          in_out_info_data["in_info"] = attend_info;
        }
      }
      if (in_out_info_data["out_info"] == null) {
        if (attend_info["a_sort"] == "out") {
          in_out_info_data["out_info"] = attend_info;
        }
      }
    }

    return in_out_info_data;
  }

}
export default AttendStateFunc;