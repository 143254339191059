import strFunc from "@/lib/lyg/string";
import ButtonArea from "./btn";
import { Link,useNavigate } from 'react-router-dom';

function TopTitleArea(props:any){
   let myProps={
    Style:{},
    user:{},
    get_comp_main_data_by_ajax:()=>{},
    comp_main_data:{},
    ...props
  };
  let user=myProps.user;
  const navigate = useNavigate();
  let Style=myProps.Style;

  let comp_name="";
  if(!strFunc.is_empty(user.select_mcomp_link)){
    if(!strFunc.is_empty(user.select_mcomp_link.comp_name)){
      comp_name=user.select_mcomp_link.comp_name;
    }
  }

  let second_title_style={
    fontSize:"22px",
    lineHeight:"30px",
    fontWeight:500,
  };

  let unread_push_cnt=0;
  if(myProps.comp_main_data&&myProps.comp_main_data.push_unread_cnt){
    unread_push_cnt=myProps.comp_main_data.push_unread_cnt;
  }
  
  return (
    <div className={Style.top_title_wrap}>
      <div className={Style.top_title_con}>
        <div className={Style.top_title_greet_wrap}>
          <div style={{color:"#454545",fontWeight:500}}>{comp_name!=""?comp_name:""}</div>
          <div>안녕하세요 {myProps.user.user_name}님,</div>
          <div style={second_title_style}>오늘의 학원정보에요</div>
        </div>
        <div className={Style.top_title_btn_wrap}>
          {/* <ButtonArea onclick={()=>{myProps.get_comp_main_data_by_ajax();}}></ButtonArea> */}
          <ButtonArea sort={"push"} text={"알림"}  
            onclick={()=>{navigate("/mobile/push/list");}}
            badge_num={unread_push_cnt}></ButtonArea>
          <ButtonArea sort={"option"} text={"설정"} 
            button_style={{marginLeft:8}}
            onclick={()=>{navigate("/mobile/setting");}}></ButtonArea>
        </div>
      </div>
    </div>
  );
};
export default TopTitleArea;