import { useSelector, useDispatch } from 'react-redux';
import PointProgress from "./point_progress";
import DateFunc from '@/lib/lyg/date_func';
import option_ico_green_100 from "@/img/mobile/home/option_ico_green_100.png";
import notice_ico_green_100 from "@/img/mobile/home/notice_ico_green_100.png";
import { useNavigate } from 'react-router-dom';
import strFunc from '@/lib/lyg/string';
import AttendArea from "./attend";

function TopNameArea(props: any) {
  let myProps = {
    Style: {},
    unread_data: {
      chat: 0,
      home_work: 0,
      plan: 0,
      sche: 0,
      push: 0,
    },
    char_opt: {
      a_gold: 0
    },
    attend_arr: [],
    lecture_arr:[],
    desk_use_arr: [],
    open_add_attend_popup: (inData:any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  let Style = myProps.Style;
  let unread_data = myProps.unread_data;
  let char_opt = myProps.char_opt;

  let now_date_json = DateFunc.get_date_json(new Date());
  let day_str = DateFunc.get_day_str_by_day_num(now_date_json.day);
  let lv_num = 1;
  let lv_exp = 0;
  let lv_exp_max = 500;
  if (char_opt.char_user_info != undefined) {
    lv_num = char_opt.char_user_info["a_level"];
    lv_exp = char_opt.char_user_info["a_experience_level"];
    lv_exp_max = char_opt.char_user_info["a_experience_level_max"];
  }

  return (
    <div className={Style.top_name_wrap + " noto_sans"}>
      <div className={Style.top_name_date}>
        {now_date_json.Y}년 {now_date_json.m}월 {now_date_json.d}일 {day_str}
      </div>
      <div className={Style.top_name_title}>
        {user.user_name}님의  씨앗이
      </div>

      <div className={Style.top_progress_wrap}>
        <PointProgress
          Style={Style}
          is_progress_bar={true}
          max_num={lv_exp_max}
          point={lv_exp}
          title={"Lv. " + lv_num}
        ></PointProgress>
      </div>
      <div>
        양분
        <span className="mx-1" style={{ color: "#a3a328" }}>
          {strFunc.comma(char_opt["a_gold"])}
        </span>
        점
      </div>
      <div className={Style.top_name_comp_name}>
        {user.select_mcomp_link != null &&
          user.select_mcomp_link.comp_name
        }
      </div>
      <span className={Style.top_name_right_btn_area}>
        <div>
          <a onClick={() => { navigate("/mobile/push/list") }} className={Style.top_name_right_btn} >
            <img src={notice_ico_green_100} className="ml-2" style={{ height: 27 }} />
            {(myProps.unread_data.push != 0) &&
              <span className={Style.top_name_option_btn_badg_cnt}>
                {myProps.unread_data.push}
              </span>
            }
          </a>
          <a onClick={() => { navigate("/mobile/setting") }} className={Style.top_name_right_btn} >
            <img src={option_ico_green_100} className="ml-2" style={{ height: 27 }} />
          </a>
        </div>
        <AttendArea
          attend_arr={myProps.attend_arr}
          lecture_arr={myProps.lecture_arr}
          desk_use_arr={myProps.desk_use_arr}
          user={user}
          open_add_attend_popup={myProps.open_add_attend_popup}
        ></AttendArea>
      </span>
    </div>
  );
}
export default TopNameArea;