import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useLocation, useNavigate } from "react-router";
import Style from "./css/style.module.css";
import TopArea from "./area/top_area";
import TimeArea from "./area/time_area";
import BottomButtonArea from "./area/bottom_btn";
import BottomContentArea from "./area/bottom_con";
import FileFormArea from "./area/file_area";
import FileAudioArea from "./area/file_audio";
import FileVideoArea from "./area/file_video";

function TodoViewArea(props: any) {
  let myProps = {
    todo_info: {},
    is_go_start:false,
    is_show_top: true,
    user_data:{
      iam_mcomp_seq:"",
      iam_mcomp_user_seq:"",
      comp_api_key:"",
    },
    on_go_back: (inData:any) => { },
    on_go_write_page: (inData:any) => { },
    back_btn_title: "뒤로",
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const TimeAreaRef = useRef<any>(null);
  const FileFormAreaRef = useRef<any>(null);
  const FileAudioAreaRef = useRef<any>(null);
  const FileVideoAreaRef = useRef<any>(null);
  const [todo_info, set_todo_info] = useState(myProps.todo_info);
  const [refresh, set_refresh] = useState(false);
  const [is_refreshed, set_is_refreshed] = useState(false);
  let is_go_start = myProps.is_go_start;

  let iam_mcomp_seq = "";
  let iam_mcomp_user_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  if(!strFunc.is_empty(myProps.user_data.iam_mcomp_user_seq)){
    iam_mcomp_seq=myProps.user_data.iam_mcomp_seq;
    iam_mcomp_user_seq=myProps.user_data.iam_mcomp_user_seq;
    comp_api_key=myProps.user_data.comp_api_key;
  }

  let is_iam_write = false;
  if (todo_info.a_writer_mcomp_seq == user.comp_seq && todo_info.a_writer_mcomp_user_seq == user.user_seq) {
    is_iam_write = true;
  } else if (todo_info.a_writer_mcomp_seq == iam_mcomp_seq && todo_info.a_writer_mcomp_user_seq == iam_mcomp_user_seq) {
    is_iam_write = true;
  }

  useEffect(() => {
    if (strFunc.is_empty(todo_info)) {
      goBackUrl();
    } else {
      goRefresh();
      if (is_go_start) {
        onStart();
      }
    }
  }, [user]);

  const completeOne = () => {
    if (!confirm("완료처리 하시겠습니까?")) {
      return false;
    }
    if (user.user_seq == "") {
      return false;
    }
    if (strFunc.is_empty(todo_info.a_seq)) {
      alert("데이터가 적절하지 않습니다.");
      return false;
    }
    let row_pri_val = todo_info.a_ymd + "," + todo_info.a_seq;
    let del_form_data = {
      "todo_seq_arr": [row_pri_val]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/complete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
          goBackUrl();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goRefresh = () => {
    let row_pri_val = todo_info.a_ymd + "," + todo_info.a_seq;
    let form_json_data = {
      "s_pri_arr": [row_pri_val],
      "s_addon_excute_arr": "1",
      "s_addon_mcomp_title": "1",
      "s_addon_file_arr": "1",
      "s_addon_par_content": "1",
      "s_addon_elapse_time": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let tmp_todo_info = response.data["data"]["info_arr"][0];

            set_todo_info(tmp_todo_info);
            set_is_refreshed(true);
          } else {
            alert("조회 내용이 없습니다.");
            goBackUrl();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goWriteTodoInfo = (inData: any) => {
    let opt_obj = {
      todo_info: {},
      ...inData
    };
    let w_todo_info = {
      ...todo_info,
      ...opt_obj.todo_info
    };
    let form_json_data = {
      data_arr: [w_todo_info],
      is_update: "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_todo_info(w_todo_info);
          set_refresh(!refresh);
        } else {
          alert(response.data["msg"]);
          goBackUrl();
        }
      });
  };

  const onUpdateTime = () => {
    let row_pri_val = todo_info.a_ymd + "," + todo_info.a_seq;
    let up_form_data = {
      "todo_seq_arr": [row_pri_val]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/update_time', up_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          goRefresh();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const onStart = () => {
    let row_pri_val = todo_info.a_ymd + "," + todo_info.a_seq;
    let up_form_data = {
      "todo_seq_arr": [row_pri_val]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/start', up_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          goRefresh();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const onStop = () => {
    let row_pri_val = todo_info.a_ymd + "," + todo_info.a_seq;
    let up_form_data = {
      "todo_seq_arr": [row_pri_val]
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/end', up_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {

        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delTodoInfo = () => {
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: [todo_info],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제되었습니다.");
          goBackUrl();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goBackUrl = () => {
    myProps.on_go_back();
  };

  const goWritePage = () => {
    myProps.on_go_write_page();
  };

  return (
    <div>
      {myProps.is_show_top &&
      <TopArea
        Style={Style}
        todo_info={todo_info}
        on_click_back={() => { goBackUrl(); }}
        back_btn_title={myProps.back_btn_title} ></TopArea>
      }
      {is_refreshed &&
        <div className="noto_sans">
          <TimeArea
            ref={TimeAreaRef}
            Style={Style}
            todo_info={todo_info}
            set_todo_info={set_todo_info}
            completeOne={completeOne}
            goWriteTodoInfo={goWriteTodoInfo}
            goRefresh={goRefresh}
            onUpdateTime={onUpdateTime}
            onStart={onStart}
            onStop={onStop}
          ></TimeArea>
          <BottomButtonArea
            Style={Style}
            todo_info={todo_info}
            completeOne={completeOne}
            goWriteTodoInfo={goWriteTodoInfo}
            goBackUrl={goBackUrl}
          ></BottomButtonArea>
          <BottomContentArea
            Style={Style}
            todo_info={todo_info}
          ></BottomContentArea>
          {/* <FileFormArea
            ref={FileFormAreaRef}
            row_data={todo_info}
            set_row_data={set_todo_info}
            callback={()=>{goRefresh();}}
            user_data={myProps.user_data}
          ></FileFormArea>
          <FileAudioArea
            ref={FileAudioAreaRef}
            row_data={todo_info}
            set_row_data={set_todo_info}
            callback={()=>{goRefresh();}}
            user_data={myProps.user_data}
          ></FileAudioArea>
          <FileVideoArea
            ref={FileVideoAreaRef}
            row_data={todo_info}
            set_row_data={set_todo_info}
            callback={()=>{goRefresh();}}
            user_data={myProps.user_data}
          ></FileVideoArea> */}
        </div>
      }

      {is_iam_write &&
        <div style={{ textAlign: "center", margin: 5 }}>
          <button className="btn btn-dark" onClick={() => { goWritePage(); }}>수정</button>
          <button className="btn btn-red ml-1" onClick={() => { delTodoInfo(); }}>삭제</button>
        </div>
      }
    </div>
  );
};
export default TodoViewArea;