import strFunc from "@/lib/lyg/string";

function HomeWorkStateInputText(props: any) {
  let myProps = {
    daily_info: {},
    name: "",
    onChange: (inData: any) => { },
    onClick: (inData: any) => { },
    info: {},

    select_homework_state_arr: [],
    ...props
  };
  let name = myProps.name;
  let value = myProps.daily_info[name] ? myProps.daily_info[name] : "";
  let select_homework_state_arr=myProps.select_homework_state_arr;
  let select_input_style: any = {};
  if(value=="1"){
    select_input_style["color"] = "#a2b854";
  }else if(value=="2"){
    select_input_style["color"] = "#db6c58";
  }else if(value=="3"){
    select_input_style["color"] = "#eab358";
  }
  //선택
  let select_input_render: any = "";
  select_input_render = (
    <select
      className="select_none_arrow border"
      value={myProps.daily_info[name]}
      onChange={(e: any) => {
        let change_row_data: any = {};
        myProps.onChange({
          name: name,
          value: e.target.value,
          change_row_data: change_row_data
        });
      }}
      style={{ width: "30px", textAlign: "center", paddingLeft: 2,marginLeft:5,...select_input_style,border:0 }}
    >
      <option value=""></option>
      {select_homework_state_arr &&
        select_homework_state_arr.map((item: any, idx: number) => {
          return (<option key={idx} value={item.value}>{item.text}</option>)
        })
      }
    </select>
  );

  return (
    <>
      {select_input_render}
    </>
  );
};
export default HomeWorkStateInputText;