import { useState,useEffect } from 'react';
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import Style from "./css/style.module.css";
import CardBox from "./card_box";
import ListRow from "./list_row";
import UserGroupDataFunc from "@/pcomponents/common/content/list/tab1/func/group_data";

function PlanCardListArea(props:any){
   let myProps={
    info_arr:[],
    select_seq_arr:[],
    set_select_seq_arr:(inData:any)=>{},
    list_sort:"card",//list
    tab_id:"",
    select_date:"",
    show_group_time:{},
    set_group_time:(inData: any) => { },
    able_time_arr_ref:null,
    openAttendPopup:(inData:any)=>{},
    openAttendModifyPopup:(inData:any)=>{},
    openViewTodoStuPopup:(inData:any)=>{},
    go_write_plan_page:(inData:any)=>{},
    openViewStuPopup:(inData:any)=>{},
    deleteAttend:(inData:any)=>{},
    ...props
  };

  //[{text:"",value:"",is_open:true,arr:[]}]
  const [group_data_arr,set_group_data_arr]=useState<any>([]);
  const [refresh,set_refresh]=useState(false);

  let now_d_json = DateFunc.get_date_json(new Date());
  let base_date_str = myProps.select_date;
  let tmp_start_date_json = DateFunc.get_date_json(new Date(base_date_str));
  let pre_date_change_num = -1;
  if (tmp_start_date_json.day == 1) {
    //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
    pre_date_change_num = -3;
  }
  let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(base_date_str), 'day', pre_date_change_num), "Y-m-d");

  useEffect(()=>{
    set_group_data_arr(get_card_group_data_arr());
  },[myProps.info_arr]);
  
  const get_card_group_data_arr=()=>{
    let group_data_arr=UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date:myProps.select_date,
      info_arr:myProps.info_arr,
      tab_id:myProps.tab_id,
    });
    set_group_time_data({group_data_arr:group_data_arr});
    let card_group_arr:any=[];//[{text:"",value:"",is_open:true,arr:[]}]
    for(let i=0;i<group_data_arr.length;i++){
      let group_data=group_data_arr[i];
      card_group_arr.push({
        ...group_data,
        text:group_data["title"],
        arr:group_data.info_arr,
        value:i,
        is_open:true,
      });
    }

    return card_group_arr;
  };

  const on_select_one=(inData:any)=>{
    let opt_obj={
      is_check:false,
      info:null,
      ...inData
    };

    if(strFunc.is_empty(opt_obj.info)){
      return false;
    }
    let u_seq=opt_obj.info["a_seq"];
    let tmp_select_seq_arr=myProps.select_seq_arr;
    if(opt_obj.is_check){
      if(strFunc.str_in_array(u_seq,tmp_select_seq_arr)==-1){
        tmp_select_seq_arr.push(u_seq);
      }
      myProps.set_select_seq_arr(tmp_select_seq_arr);
    }else{
      //빼기
      let new_select_seq_arr=[];
      for(let i=0;i<tmp_select_seq_arr.length;i++){
        let u_seq2=tmp_select_seq_arr[i];
        if(u_seq==u_seq2){
          
        }else{
          new_select_seq_arr.push(u_seq2);
        }
      }
      myProps.set_select_seq_arr(new_select_seq_arr);
    }
  };

  const onGroupAllCheck=(inData:any)=>{
    let opt_obj={
      is_check:false,
      arr:[],
      ...inData
    };
    let tmp_select_seq_arr=myProps.select_seq_arr;
    if(opt_obj.is_check){
      for(let i=0;i<opt_obj.arr.length;i++){
        let u_seq=opt_obj.arr[i]["a_seq"];
        if(!strFunc.is_empty(u_seq)){
          if(strFunc.str_in_array(u_seq,tmp_select_seq_arr)==-1){
            tmp_select_seq_arr.push(u_seq);
          }
        }
      }
      myProps.set_select_seq_arr(tmp_select_seq_arr);
    }else{
      //빼기
      let new_select_seq_arr=[];
      for(let i=0;i<tmp_select_seq_arr.length;i++){
        let u_seq=tmp_select_seq_arr[i];
        let is_exist=false;
        for(let j=0;j<opt_obj.arr.length;j++){
          let u_seq2=opt_obj.arr[j]["a_seq"];
          if(u_seq==u_seq2){
            is_exist=true;
          }
        }
        if(is_exist==false){
          new_select_seq_arr.push(u_seq);
        }
      }
      myProps.set_select_seq_arr(new_select_seq_arr);
    }
  };

  const get_card_tags=(info_arr:[])=>{
    let card_tags:any="";
    if(myProps.list_sort=="card"){
      card_tags=info_arr.map((item:any,idx:number)=>{
        let is_check=false;
        if(strFunc.str_in_array(item.a_seq,myProps.select_seq_arr)!=-1){
          is_check=true;
        }
        return (
          <CardBox key={idx} 
            Style={Style} 
            info={item} 
            is_check={is_check}
            base_date_str={base_date_str}
            pre_date_str={pre_date_str}
            on_select_one={on_select_one}
            openAttendPopup={myProps.openAttendPopup}
            openAttendModifyPopup={myProps.openAttendModifyPopup}
            openViewTodoStuPopup={myProps.openViewTodoStuPopup}
            go_write_plan_page={myProps.go_write_plan_page}
            openViewStuPopup={myProps.openViewStuPopup}
            deleteAttend={myProps.deleteAttend}
            ></CardBox>
        );
      });
    }else{
      card_tags=info_arr.map((item:any,idx:number)=>{
        let is_check=false;
        if(strFunc.str_in_array(item.a_seq,myProps.select_seq_arr)!=-1){
          is_check=true;
        }
        return (
          <ListRow key={idx} 
            Style={Style} 
            info={item} 
            is_check={is_check}
            on_select_one={on_select_one}
            openAttendPopup={myProps.openAttendPopup}
            deleteAttend={myProps.deleteAttend}></ListRow>
        );
      });
    }
    return card_tags;
  };

  const set_group_time_data=(inData:any)=>{
    let opt_obj={
      group_data_arr:[],
      ...inData
    };
    let able_time_arr:any=[];
    let group_data_arr=opt_obj.group_data_arr;
    for(let i=0;i<group_data_arr.length;i++){
      let group_data=group_data_arr[i];
      if(strFunc.str_in_array(group_data["start_time_str"],able_time_arr)==-1){
        able_time_arr.push(group_data["start_time_str"]);
      }
    }

    if(myProps.able_time_arr_ref){
      myProps.able_time_arr_ref.current=able_time_arr;
    }
  };

  const get_card_group_tags=()=>{
    let card_group_tags:any=group_data_arr.map((item:any,idx:number)=>{
      let group_info=item;
      
      if(myProps.show_group_time.sort=="now"){
        if(group_info["end_time_str"]){
          let end_time_h=parseInt(strFunc.uncomma(strFunc.cut_str(group_info["end_time_str"],2,"")));
          let now_h=parseInt(now_d_json.h);
          if(end_time_h<now_h){
            return "";
          }
        }
      }else if(myProps.show_group_time.sort=="select"){
        if(strFunc.str_in_array(group_info["start_time_str"],myProps.show_group_time.select_time_arr)==-1){
          return "";
        }
      }

      return (
        <div key={idx} className={Style.card_group_item}>
          <div className={Style.card_group_item_title}>
            <label>
              <input type="checkbox" className="mr-1" onClick={
                (e:any)=>{
                  onGroupAllCheck({
                    is_check:e.target.checked,
                    arr:item.arr,
                  });
                }
              } />
              {item.text}
              <span className="ml-1">
                ({item.arr.length})
              </span>
            </label>
            <span className={Style.card_group_item_title_right}>
              <button className={Style.group_item_up_down_btn}
                onClick={()=>{
                  let tmp_group_data_arr=group_data_arr;
                  tmp_group_data_arr[idx]["is_open"]=!item.is_open;
                  set_group_data_arr(tmp_group_data_arr);
                  set_refresh(!refresh);
                }}>
                {item.is_open?"▲":"▼"}
              </button>
            </span>
          </div>
          {item.is_open&&
            <div className={Style.card_box_list_wrap}>
              {get_card_tags(item.arr)}
            </div>
          }
        </div>
      );
    });

    if(strFunc.is_empty(card_group_tags)){
      card_group_tags=(
        <div style={{lineHeight:"40px",textAlign:"center",color:"#ccc",fontSize:"15px"}}>
          내용이 없습니다.
        </div>
      );
    }

    return card_group_tags;
  };
  
  return (
  <div className={Style.card_group_wrap}>
    {get_card_group_tags()}
  </div>
  );
};
export default PlanCardListArea;