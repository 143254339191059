import Style from "./css/style.module.css";
import CardBox from "./card_box";
import strFunc from "@/lib/lyg/string";
import clock_ico from "@/img/mobile/ico/clock_ico.png";
import video_ico from "@/img/mobile/ico/video_ico.png";
import { useSelector, useDispatch } from 'react-redux';

function CardListArea(props:any){
  let myProps={
    info_arr:[],
    on_click_card_box:(inData:any)=>{},
    group_sort:"a_sort",//a_sort, a_subject
    default_group_key:"homework",//study
    ...props
  };
  let user=useSelector((state:any) => state.user);
  
  const get_value_group_idx=(value:any,sort_group_arr:any[])=>{
    for(let i=0;i<sort_group_arr.length;i++){
      if(sort_group_arr[i]["value"]==value){
        return i;
      }
    }
    return -1;
  };

  const get_sort_group_info_arr=()=>{
    let sort_group_arr:any=[
      {"value":"study","title":"학습","arr":[],"ico":video_ico},
      {"value":"homework","title":"숙제","arr":[],"ico":clock_ico},
    ];
    // if(myProps.group_sort=="a_subject"){
    //   sort_group_arr=[
    //     {"value":"기타","title":"마이플랜","arr":[],"ico":clock_ico},
    //   ];
    // }
    let group_sort=myProps.group_sort;

    for(let i=0;i<myProps.info_arr.length;i++){
      let info=myProps.info_arr[i];
      let group_value=info[group_sort];
      if(group_value==""){
        group_value="기타";
      }
      let value_group_idx=get_value_group_idx(group_value,sort_group_arr);
      if(value_group_idx==-1){
        sort_group_arr.push({
          "value":group_value,"title":group_value,"arr":[],"ico":clock_ico
        });
        value_group_idx=sort_group_arr.length-1;
      }
      sort_group_arr[value_group_idx]["arr"].push(info);
    }
    //공백그룹삭제
    let tmp_sort_group_arr=[];
    for(let i=0;i<sort_group_arr.length;i++){
      if(sort_group_arr[i]["arr"].length>0){
        tmp_sort_group_arr.push(sort_group_arr[i]);
      }
    }
    sort_group_arr=tmp_sort_group_arr;

    if(tmp_sort_group_arr.length==0){
      if(myProps.default_group_key=="homework"){
        sort_group_arr.push({"value":"homework","title":"숙제","arr":[],"ico":clock_ico});
      }else{
        sort_group_arr.push({"value":"study","title":"학습","arr":[],"ico":video_ico},);
      }
    }

    return sort_group_arr;
  };

  const get_card_box_tags_by_arr=(todo_arr:[])=>{
    let card_box_tags:any=todo_arr.map((item:any,idx:number)=>{
      return (
        <CardBox
          Style={Style}
          key={idx}
          info={item}
          on_click_card_box={myProps.on_click_card_box}
          user={user}
        ></CardBox>
      );
    });
    if(strFunc.is_empty(card_box_tags)){
      card_box_tags=(
        <div style={{lineHeight:"60px",textAlign:"center",fontSize:"14px",width:"100%",border:"1px solid #ddd"}}>
          내용이 없습니다.
        </div>
      );
    }
    return card_box_tags;
  };

  const get_sort_group_tags=()=>{
    let group_arr=get_sort_group_info_arr();
    let sort_group_tags=group_arr.map((item:any,idx:number)=>{
      return (
        <div key={idx} className={Style.card_group_wrap}>
          <div className={Style.card_group_title}>
            {item["title"]}
            {item["arr"].length>0&&
            <span className={Style.card_group_title_right_span}>
              {item["arr"].length}건
            </span>
            }
          </div>
          <div className={Style.card_group_list_wrap}>
            {get_card_box_tags_by_arr(item["arr"])}
          </div>
        </div>
      );
    });
    return sort_group_tags;
  };

  return (
    <div className={Style.list_wrap}>
      {get_sort_group_tags()}
    </div>
  );
}
export default CardListArea;