import Style from "./css/style.module.css";
import DetailListArea from "./deail_list";

function SupplyDetailArea(props: any) {
  let myProps = {
    corp_info: {},
    trade_info_arr: [],
    count_info: {},
    stu_info: {},
    ...props
  };
  let corp_info = myProps.corp_info;
  let stu_info = myProps.stu_info;
  let trade_tot_info = {
    in_debt: 0,
    in_money: 0,
    out_debt: 0,
    out_money: 0,
    remain_money: 0,
    ...stu_info.trade_tot_info
  };

  return (
    <div>
      <div className={Style.detail_title}>청구안내</div>
      <div className={Style.detail_box_wrap}>
        <div>
          {corp_info.a_company_name} 학원비 상세내역
        </div>
        <div className="mt-2">
          <DetailListArea
            Style={Style}
            trade_info_arr={myProps.trade_info_arr}
            count_info={myProps.count_info}
            trade_tot_info={trade_tot_info}
          ></DetailListArea>
        </div>
      </div>
    </div>
  );
};
export default SupplyDetailArea;