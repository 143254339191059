import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import Style from "./css/style.module.css";
import CardBoxArea from "./card_box";

function CardListArea(props: any) {
  let myProps = {
    info_arr: [],
    set_info_arr: (inData: any) => { },
    select_seq_arr: [],
    set_select_seq_arr: (inData: any) => { },
    go_save_by_ajax: (inData: any) => { },
    ...props
  };
  let select_stu_grade_option_arr=XcolumnFunc.getSortByTableKey({'table':'user','key':'a_stu_grade2'});
  let select_subject_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});

  const set_inf_of_box=(inData:any)=>{
    let opt_obj={
      row_num:0,
      info:{},
      ...inData
    };
    let info=opt_obj.info;
    let row_num=opt_obj.row_num;

    let new_info_arr = [...myProps.info_arr];
    new_info_arr[inData.row_num] = inData.info;
    myProps.set_info_arr(new_info_arr);
  };

  const get_card_box_tags = () => {
    let card_box_tags = myProps.info_arr.map((item: any, idx: number) => {
      return (
        <CardBoxArea key={idx}
          Style={Style}
          row_num={idx}
          info={item}
          set_info={(inData: any) => { 
            set_inf_of_box({
              info: inData,
              row_num: idx
            });
          }}
          select_seq_arr={myProps.select_seq_arr}
          set_select_seq_arr={myProps.set_select_seq_arr}
          select_stu_grade_option_arr={select_stu_grade_option_arr}
          select_subject_option_arr={select_subject_option_arr}
          go_save_by_ajax={props.go_save_by_ajax}
        ></CardBoxArea>
      );
    });

    if (strFunc.is_empty(card_box_tags)) {
      card_box_tags = (
        <div >
          <div style={{textAlign:"center",lineHeight:"30px",color:"#666",fontSize:"14px"}}>
            데이터가 없습니다.
          </div>
        </div>
      );
    }

    return card_box_tags;
  };

  return (
    <div className={Style.card_list_wrap}>
      {get_card_box_tags()}
    </div>
  );
};
export default CardListArea;