import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import CorpInfoArea from "./area/corp_area";
import TitleSupplyArea from "./area/title_supply";
import SupplyDetailArea from "./area/supply_detail";
import PayButtonArea from "./area/pay_btn";

function StuOneSupplyArea(props: any) {
  let myProps = {
    stu_seq: "",
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },
    on_click_pay: (inData: any) => { },//{supply_money:0}
    ...props
  };

  let iam_mcomp_seq = myProps.user_data.iam_mcomp_seq;
  let iam_mcomp_user_seq = myProps.user_data.iam_mcomp_user_seq;
  let comp_api_key = myProps.user_data.comp_api_key;

  const [select_date, set_select_date] = useState(DateFunc.get_date_format(new Date(), "Y-m-d"));
  const [stu_info, set_stu_info] = useState({
    a_seq: "",
    a_user_name: "",
    trade_tot_info: {
      in_debt: 0,
      in_money: 0,
      out_debt: 0,
      out_money: 0,
      remain_money: 0,
    },
  });
  const [corp_info, set_corp_info] = useState({
    a_seq: "",
    a_company_name: "",
    a_represent_name: "",
    a_company_tel: "",
    a_company_busin_num: "",
  });
  const [trade_info_arr, set_trade_info_arr] = useState([]);
  const [count_info, set_count_info] = useState({
    a_money: 0,
    row_view_remain_money: 0,
    tot: 0,
  });
  const [supply_info_arr, set_supply_info_arr] = useState([]);
  let trade_tot_info: any = {
    in_debt: 0,
    in_money: 0,
    out_debt: 0,
    out_money: 0,
    remain_money: 0,
  };
  if (stu_info.trade_tot_info) {
    trade_tot_info = {
      ...trade_tot_info,
      ...stu_info.trade_tot_info
    };
  }
  let select_date_json = DateFunc.get_date_json(new Date(select_date));
  let supply_name=select_date_json.m+" 월 학원비";

  useEffect(() => {
    get_stu_info_by_ajax();
    get_corp_info_by_ajax();
    get_trade_list_by_ajax();
    get_supply_list_by_ajax();
  }, []);

  const get_stu_info_by_ajax = () => {
    if (strFunc.is_empty(myProps.stu_seq)) {
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let form_json_data: any = {
      "s_pri_arr": [myProps.stu_seq],
      s_addon_trade_tot: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      api_key: comp_api_key,
      api_user_seq: iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',
      form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_stu_info(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  const get_corp_info_by_ajax = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let form_json_data: any = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      api_key: comp_api_key,
      api_user_seq: iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/corp/corp/list',
      form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_corp_info(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  const get_trade_list_by_ajax = () => {
    if (strFunc.is_empty(myProps.stu_seq)) {
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let this_start_date = DateFunc.get_date_format(new Date(select_date), "Y-m-01");
    let this_end_date = DateFunc.get_date_format(new Date(select_date), "Y-m-t");

    let form_json_data: any = {
      "order_id": "a_create_date DESC",
      "s_date_type": "a_date",
      "s_start_date": this_start_date,
      "s_end_date": this_end_date,
      "s_cust_par_id": "user",
      "s_cust_par_seq": [myProps.stu_seq],
      "s_money_sum": "1",
      api_key: comp_api_key,
      api_user_seq: iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/trade/trade_simple/list',
      form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"] = [...response.data["data"]["info_arr"].reverse()];
          set_trade_info_arr(response.data["data"]["info_arr"]);
          set_count_info(response.data["data"]["count_info"]);
        } else {

        }
      });
  };
  const get_supply_list_by_ajax = () => {
    if (strFunc.is_empty(myProps.stu_seq)) {
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let this_start_date = DateFunc.get_date_format(new Date(select_date), "Y-m-01");
    let this_end_date = DateFunc.get_date_format(new Date(select_date), "Y-m-t");

    let form_json_data: any = {
      "order_id": "a_create_date DESC",
      "s_date_type": "a_date",
      "s_start_date": this_start_date,
      "s_end_date": this_end_date,
      "s_stu_seq": [myProps.stu_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      api_key: comp_api_key,
      api_user_seq: iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/trade/class_supply/list',
      form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_supply_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div className="p-2 noto_sans">
      <TitleSupplyArea
        select_date={select_date}
        supply_name={supply_name}
        stu_info={stu_info}
        corp_info={corp_info}
      ></TitleSupplyArea>
      <div className="mt-5">
        <CorpInfoArea
          corp_info={corp_info}
        ></CorpInfoArea>
      </div>
      <div className="mt-5">
        <SupplyDetailArea
          corp_info={corp_info}
          trade_info_arr={trade_info_arr}
          count_info={count_info}
          stu_info={stu_info}
        ></SupplyDetailArea>
      </div>
      <div className="mt-5">
        <PayButtonArea
          on_click_pay={() => {
            myProps.on_click_pay({
              supply_money: trade_tot_info.remain_money,
              supply_seq_arr: [],
              supply_name: supply_name,
            });
          }}
        ></PayButtonArea>
      </div>
    </div>
  );
};
export default StuOneSupplyArea;