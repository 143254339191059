import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import strFunc from "@/lib/lyg/string";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {
      s_grade: "",
      s_user_name_like: "",
      s_school_name_like: "",
      s_stu_lecture_seq: "",
    },
    list: (inData: any) => { },
    user: {},
    ...props
  };

  let user = myProps.user;
  let is_manager = false;
  if (user.select_mcomp_link && user.select_mcomp_link["a_user_grade"]) {
    if (user.select_mcomp_link["a_user_grade"] == "master"
      || user.select_mcomp_link["a_user_grade"] == "teacher") {
      is_manager = true;
    }
  }

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);

  useEffect(() => {
    get_lecture_select_arr();
  }, []);

  const get_lecture_select_arr = () => {
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let tmp_list_form = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', tmp_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
          if (response.data["data"]["info_arr"].length == 0) {
            strFunc.set_storage("s_stu_lecture_seq", "");
          }
        } else {

        }
      });
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    myProps.list({
      "now_page": "1",
      ...{ [name]: value }
    });
  };

  return (
    <div style={{ height: 40, fontSize: 20 }}>
      {is_manager &&
        <select className="search_input" name="s_grade" value={myProps.listOpt.s_grade} onChange={(e: any) => {
          myProps.list({ "now_page": "1", "s_grade": e.target.value });
        }}>
          <option value="student">학생</option>
          <option value="parent">학부모</option>
          <option value="teacher">선생님</option>
          <option value="master">관리자</option>
        </select>
      }
      <input type="text" className="search_input ml-1" value={myProps.listOpt.s_user_name_like} placeholder="이름"
        name="s_user_name_like"
        onChange={(e: any) => {
          myProps.list({ "now_page": "1", "s_user_name_like": e.target.value });
        }}
        autoComplete="off"
        style={{ width: 70 }}
      />
      <input type="text" className="search_input ml-1" value={myProps.listOpt.s_school_name_like} placeholder="학교"
        name="s_school_name_like"
        onChange={(e: any) => {
          myProps.list({ "now_page": "1", "s_school_name_like": e.target.value });
        }}
        autoComplete="off"
        style={{ width: 60 }}
      />
      {is_manager&&
      <select className="search_input ml-1" name="s_stu_lecture_seq" value={myProps.listOpt.s_stu_lecture_seq}
        onChange={(inData: any) => {
          handleChangeInput(inData);
        }} style={{ width: 70 }}>
        <option value="">수업</option>
        {
          lectrue_select_arr.map((item: any, idx: number) => {
            return (
              <option value={item.a_seq} key={idx}>{item.a_name}</option>
            );
          })
        }
      </select>
      }
      <button className="btn btn-dark ml-1"
        onClick={() => { myProps.list({ "now_page": "1" }); }}>검색</button>
    </div>
  );
};
export default SearchArea;