import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import MobileScript from "@/lib/mobile/web_script";
import { useState, useRef, useEffect } from 'react';
import Style from "./css/style.module.css";
import smile_img from "@/img/mobile/char/carrot_smile_400.png";
import shadow_img from "@/img/mobile/char/smile_shadow_40.png";
import talkbox_img from "@/img/mobile/char/talk_balloon_r_400.png";
import carrot_img from "@/img/mobile/char/question_carrot_200.png";

function LoginIntro(){
  const navigate = useNavigate();
  const intervalRef = useRef<any>();
  const smileTop = useRef<number>(40);
  const smileGravity=0.4;
  const smilePower=useRef<number>(0);
  const [bottom_text,set_bottom_text]=useState((
    <span>나와 함께 성장하는 <br />씨앗이에요!</span>
  ));
  const [isShowTalkbox,setIsShowTalkbox] = useState(false);

  const [smileTopNum,setSmileTopNum]=useState(0);

  const smile_animation_loop=()=>{
    smilePower.current+=smileGravity;
    smileTop.current+=smilePower.current;
    if(smileTop.current>40){
      smilePower.current=-4;
    }
    setSmileTopNum(smileTop.current);
  };

  useEffect(()=>{
    smileTop.current=0;
    intervalRef.current=smile_animation_loop;
  },[]);

  useEffect(() => {
    const tick:any=()=>{
      intervalRef.current();
    }

    const timer = setInterval(tick, 50);
    return () => clearInterval(timer);
  }, []);

  const onNextClick=()=>{
    set_bottom_text((
      <span>씨앗이 다 자라게 되면 <br />어떤 일이 벌어질까요?</span>
    ));
    setIsShowTalkbox(true);
    if(isShowTalkbox){
      navigate("/mobile");
    }
  };

  let win_w=(window.innerWidth || document.documentElement.clientWidth);
  let smile_css={
    width:120,
    height:120,
    top:smileTop.current,
    left:(win_w/2)-60
  };
  let shadow_w=70-(40-smileTop.current);
  let shadow_h=40-(30-smileTop.current);
  let shadow_css={
    width:shadow_w,
    top:142-(shadow_h/2),
    left:(win_w/2)-(shadow_w/2)-2
  };

  return (
    <MobileLayout has_header={false} has_footer={false} is_home_body={true}>
      <div className={Style.intro_wrap} onClick={()=>{onNextClick();}} >
        <div className={Style.intro_img_wrap} >
          <img className={Style.intro_img_char} src={smile_img} style={smile_css} />
          <img className={Style.intro_img_shadow} src={shadow_img} style={shadow_css}></img>
          {isShowTalkbox&&
            <>
            <img src={talkbox_img} style={{
              position:"absolute",
              left:(win_w/2)+40,
              top:-25,
              width:120
            }}></img>
            <img src={carrot_img} style={{
              position:"absolute",
              left:(win_w/2)+65,
              top:-5,
              width:80,
              rotate:"37deg"
            }}></img>
            </>
          }
          <div className={Style.intro_bottom_text} >
            {bottom_text}
          </div>
        </div>
      </div>
    </MobileLayout>
  );
}

export default LoginIntro;