import { useState, useRef, useEffect, useMemo, useCallback,forwardRef,useImperativeHandle } from 'react';
import Style from "../css/style.module.css";
import getXcolumnJson from "./xcolumn/list";
import strFunc from '@/lib/lyg/string';

const StudySectionList = forwardRef((props:any, ref) => {
  const myProps={
    
    ...props
  };
  
  const xColumnArr=getXcolumnJson();
  const [section_arr,set_section_arr]=useState([]);
  const [study_arr,set_study_arr]=useState<any>([]);

  const setInitData=(inOptObj:any)=>{
    if(inOptObj==undefined){
      inOptObj={};
    }
    let optObj={
      "section_arr":[],
      "study_arr":[],
      ...inOptObj
    };
    if(optObj.section_arr){
      set_section_arr(optObj.section_arr);
    }
    if(optObj.study_arr){
      set_study_arr(optObj.study_arr);
    }
  };

  const onChangeStudyCheck=(study_seq:string,is_checked:boolean)=>{
    let tmp_study_arr:any=[...study_arr];
    for(let i=0;i<tmp_study_arr.length;i++){
      let study_info=tmp_study_arr[i];
      if(study_info.a_seq==study_seq){
        tmp_study_arr[i].is_checked=is_checked;
      }
    }
    set_study_arr(tmp_study_arr);
  };
  const onChangeSectionCheck=(select_seq:string,is_checked:boolean)=>{
    let tmp_study_arr:any=[...study_arr];
    for(let i=0;i<tmp_study_arr.length;i++){
      let study_info=tmp_study_arr[i];
      if(study_info["a_section_seq"]==select_seq){
        tmp_study_arr[i].is_checked=is_checked;
      }
    }
    set_study_arr(tmp_study_arr);
  }
  const onChangeAllCheck=(is_checked:boolean)=>{
    let tmp_study_arr:any=[...study_arr];
    for(let i=0;i<tmp_study_arr.length;i++){
      tmp_study_arr[i].is_checked=is_checked;
    }
    set_study_arr(tmp_study_arr);
  };

  const get_select_study_list=()=>{
    let select_study_list=[];
    for(let i=0;i<study_arr.length;i++){
      if(study_arr[i].is_checked){
        select_study_list.push(study_arr[i]);
      }
    }
    return select_study_list;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData,get_select_study_list
  }));

  const getStudyArrBySection=(section_seq:string)=>{
    let show_study_list:any=[];
    if(section_seq=="empty"){
      for(let i=0;i<study_arr.length;i++){
        let study_info=study_arr[i];
        let tmp_is_exist=false;
        for(let j=0;j<section_arr.length;j++){
          let section_info=section_arr[j];
          if(study_info["a_section_seq"]==section_info["a_seq"]){
            tmp_is_exist=true;
          }
        }
        if(tmp_is_exist==false){
          show_study_list.push(study_info);
        }
      }
    }else{
      for(let i=0;i<study_arr.length;i++){
        let study_info=study_arr[i];
        if(study_info["a_section_seq"]==section_seq){
          show_study_list.push(study_info);
        }
      }
    }
    return show_study_list;
  };
  
  const getStudyListTableBySection=(section_seq:string)=>{
    let show_study_list=getStudyArrBySection(section_seq);
    let study_row_tags=show_study_list.map((item:any,idx:number)=>{
      return (
        <tr key={idx}>
          {xColumnArr.x_column_list_arr.map((col_data:any,col_idx:number)=>{
            let key=col_data["key"];
            let val_str:any="";
            if(item[key]){
              val_str=item[key];
              if(xColumnArr.select_arr[key]){
                for(let i=0;i<xColumnArr.select_arr[key].length;i++){
                  if(xColumnArr.select_arr[key][i]["value"]==val_str){
                    val_str=xColumnArr.select_arr[key][i]["text"];
                  }
                }
              }
            }
            let style_str:any={
              maxWidth:col_data["width"],
              overFlow:"hidden",
            };
            if(key=="check_box"){
              val_str=(<input type="checkbox" checked={item.is_checked?true:false} onChange={(e:any)=>{onChangeStudyCheck(item.a_seq,e.target.checked);}}/>);
              style_str={textAlign:"center"};
            }
            return  (
              <td key={col_idx}>
                <div style={style_str}>
                  {val_str}
                </div>
              </td>
            );
          })}
        </tr>
      );
    });
    if(strFunc.is_empty(study_row_tags)){
      study_row_tags=(
        <tr>
          <td colSpan={99}>
            <div style={{width:"100%",textAlign:"center",height:30,lineHeight:"30px"}}>내용이 없습니다.</div>
          </td>
        </tr>
      );
    }
    let colgroup_row_tags=xColumnArr.x_column_list_arr.map((col_data:any,col_idx:number)=>{
      return (
        <col key={col_idx} width={col_data["width"]+"px"}></col>
      );
    });
    let thead_row_tags=xColumnArr.x_column_list_arr.map((col_data:any,col_idx:number)=>{
      let title_str=col_data["name"];
      let style_str:any={
        maxWidth:col_data["width"],
        overFlow:"hidden",
      };
      if(col_data["key"]=="check_box"){
        title_str=(<input type="checkbox" onChange={(e:any)=>{onChangeSectionCheck(section_seq,e.target.checked);}} />);
      }
      return (
        <th key={col_idx} >
          <div style={style_str}>
            {title_str}
          </div>
        </th>
      );
    });
    let study_table_tag=(
      <table>
        <colgroup>
          {colgroup_row_tags}
        </colgroup>
        <thead>
          <tr>
            {thead_row_tags}
          </tr>
        </thead>
        <tbody>
          {study_row_tags}
        </tbody>
      </table>
    );
    return study_table_tag;
  };
  const getStudySectionTags=()=>{
    let section_tags=section_arr.map((item:any,idx:number)=>{
      let study_table_tag=getStudyListTableBySection(item.a_seq);
      return (
        <div key={idx} className={Style.section_item}>
          <div className={Style.section_item_title} >{item.a_title}</div>
          <div className={Style.study_list_wrap+"  list-table-div"} >
            {study_table_tag}
          </div>
        </div>
      );
    });
    let tmp_empty_section=getStudyArrBySection("empty");
    let empty_section_tag:any="";
    if(tmp_empty_section.length>0){
      let empty_study_table_tag:any=getStudyListTableBySection("empty");
      empty_section_tag=(
        <div className={Style.section_item}>
          <div className={Style.section_item_title} >소속없음</div>
          <div className={Style.study_list_wrap+"  list-table-div"} >
            {empty_study_table_tag}
          </div>
      </div>
      );
    }

    return (
      <>
        {section_tags}
        {empty_section_tag}
      </>
    );
  };

  let study_section_tags:any=getStudySectionTags();

  return (
    <div>
      <div className="px-2 py-2">
        <label>
          <input type="checkbox" 
            onChange={(e:any)=>{onChangeAllCheck(e.target.checked);}}/> 전체체크
        </label>
      </div>
      {study_section_tags}
    </div>
  );
});

export default StudySectionList;
