import strFunc from "@/lib/lyg/string";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import DateFunc from '@/lib/lyg/date_func';

class PlanListFunc{
  static get_user_info_arr_ordered_by_oder_id(info_arr:any,order_id:string){
    let key_arr=[];
    let key_val_arr:any={};
    let info_arr_len=info_arr.length;
    
    if(order_id=="attend"){
      for(let i=0;i<info_arr_len;i++){
        let info=info_arr[i];
        if(info.attend_in_arr&&info.attend_in_arr.length>0){
          let key=strFunc.str_replace(":","",info.attend_in_arr[0]["a_time"]);
          if(strFunc.str_in_array(key,key_arr)==-1){
            key_arr.push(key);
            key_val_arr[key]=[];
          }
          key_val_arr[key].push(info);
        }else{
          let etc_key="000000";
          if(key_val_arr[etc_key]==undefined){
            key_arr.push(etc_key);
            key_val_arr[etc_key]=[];
          }
          key_val_arr[etc_key].push(info);
        }
      }
      key_arr.sort();
      key_arr.reverse();
      
      let new_info_arr:any=[];
      for(let i=0;i<key_arr.length;i++){
        let key=key_arr[i];
        if(key_val_arr[key]){
          new_info_arr=[
            ...new_info_arr,
            ...key_val_arr[key]
          ];
        }
      }
      info_arr=new_info_arr;
    }
    return info_arr;
  };
  static get_info_arr_by_filter(inData: any) {
    let opt_obj = {
      info_arr: [],
      list_opt: {
        s_addon_todo_start_date: "",
        s_addon_todo_s_state: "",//complete,ing,not_complete
        s_addon_todo_s_todo_sort: "",//study,homework
      },
      ...inData
    };
    let info_arr = opt_obj["info_arr"];
    let base_date_str = opt_obj["base_date_str"];
    let pre_date_str = opt_obj["pre_date_str"];

    let new_info_arr = [];
    let info_arr_len = info_arr.length;
    for (let i = 0; i < info_arr_len; i++) {
      let info = info_arr[i];
      let td_sort_data_json = this.get_td_sot_data_arr({
        info: info,
        base_date_str: base_date_str,
        pre_date_str: pre_date_str,
      });
      info.td_sort_data_json = td_sort_data_json;

      let is_add_info = true;
      if (opt_obj["list_opt"]["s_addon_todo_s_state"] != "") {
        is_add_info = false;
        let todo_percent = info.td_sort_data_json["study"]["percent"];
        let todo_count = info.td_sort_data_json["study"]["count"];
        if (opt_obj["list_opt"]["s_addon_todo_s_todo_sort"] == "homework") {
          todo_percent = info.td_sort_data_json["pre_homework"]["percent"];
          todo_count = info.td_sort_data_json["pre_homework"]["count"];
        }
        if (opt_obj["list_opt"]["s_addon_todo_s_state"] == "complete") {
          if (todo_percent >= 100) {
            is_add_info = true;
          }
        } else if (opt_obj["list_opt"]["s_addon_todo_s_state"] == "ing") {
          if (todo_percent > 0 && todo_percent < 100) {
            is_add_info = true;
          }
        } else {
          if (todo_percent >= 0 && todo_percent < 100 && todo_count > 0) {
            is_add_info = true;
          }
        }
      }
      if (is_add_info) {
        new_info_arr.push(info);
      }
    }
    info_arr = new_info_arr;

    return info_arr;
  };
  static get_td_sot_data_arr = (inData: any) => {
    let opt_obj = {
      info: {},
      base_date_str: "",
      pre_date_str: "",
      ...inData,
    };
    let info = opt_obj.info;
    let base_date_str = opt_obj.base_date_str;
    let pre_date_str = opt_obj.pre_date_str;


    let td_sort_data_json: any = {
      "study": {
        "sort": "study",
        "title": "학습",
        "percent": 0,
        "count": 0,
        "success_cnt": 0,
        "elapse_time_sec": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "homework": {
        "sort": "homework",
        "title": "숙제",
        "percent": 0,
        "count": 0,
        "success_cnt": 0,
        "elapse_time_sec": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
      "pre_homework": {
        "sort": "pre_homework",
        "title": "어제숙제",
        "percent": 0,
        "count": 0,
        "success_cnt": 0,
        "elapse_time_sec": 0,
        "per_arr": [],
        "todo_info_arr": [],
      },
    };
    let todo_info_arr = [];
    if (info.todo_arr) {
      todo_info_arr = info.todo_arr;
    }

    let todo_info_arr_len = todo_info_arr.length;
    for (let key in td_sort_data_json) {
      for (let i = 0; i < todo_info_arr_len; i++) {
        let todo_info = todo_info_arr[i];
        if (key == "pre_homework") {
          if (todo_info["a_reserve_start_date"].substring(0, 10) >= pre_date_str
            && todo_info["a_reserve_start_date"].substring(0, 10) < base_date_str) {
            if (todo_info["a_sort"] == "homework") {
              td_sort_data_json[key]["count"]++;
              td_sort_data_json[key]["elapse_time_sec"] += parseInt(strFunc.uncomma(todo_info.elapse_time_sec));
              let row_per: any = TodoStateFunc.get_percent_by_todo_info(todo_info);
              if (todo_info["a_is_success"] == "1") {
                row_per = 100;
              }
              if (row_per > 100) {
                row_per = 100;
              }
              td_sort_data_json[key]["per_arr"].push(row_per);
              todo_info["percent"] = row_per;
              td_sort_data_json[key]["todo_info_arr"].push(todo_info);
              if (todo_info["a_is_success"] == "1") {
                td_sort_data_json[key]["success_cnt"]++;
              }
            }
          }
        } else if (todo_info["a_reserve_start_date"].substring(0, 10) == base_date_str) {
          let tmp_row_key = todo_info["a_sort"];
          if (tmp_row_key == "video") {
            tmp_row_key = "study";
          }
          if (tmp_row_key == key) {
            td_sort_data_json[key]["count"]++;
            td_sort_data_json[key]["elapse_time_sec"] += parseInt(strFunc.uncomma(todo_info.elapse_time_sec));
            let row_per: any = TodoStateFunc.get_percent_by_todo_info(todo_info);
            if (todo_info["a_is_success"] == "1") {
              row_per = 100;
            }
            if (row_per > 100) {
              row_per = 100;
            }
            td_sort_data_json[key]["per_arr"].push(row_per);
            todo_info["percent"] = row_per;
            td_sort_data_json[key]["todo_info_arr"].push(todo_info);
            if (todo_info["a_is_success"] == "1") {
              td_sort_data_json[key]["success_cnt"]++;
            }
          }
        }
      }
    }
    //평균얻기
    for (let key in td_sort_data_json) {
      let row_sum_per: any = 0;
      let per_arr_len: any = td_sort_data_json[key]["per_arr"].length;
      for (let i = 0; i < per_arr_len; i++) {
        row_sum_per += td_sort_data_json[key]["per_arr"][i];
      }
      if (row_sum_per != 0 && per_arr_len != 0) {
        td_sort_data_json[key]["percent"] = Math.floor(row_sum_per / per_arr_len);
      }
    }

    return td_sort_data_json;
  };
}
export default PlanListFunc;