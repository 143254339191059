//import CheckBoxGreen from "@/pcomponents/common/content/list/element/checkbox_green";
function CheckBoxGreen(props:any){
   let myProps={
     checked:false,
     value:"",
     on_change:(e:any)=>{},
     check_color:"#3FAC96",
    ...props
  };
  
  const get_check_svg_tag=()=>{

    let checkbox_color="#CAD5D2";
    if(myProps.checked){
      checkbox_color=myProps.check_color;
    }

    let svg_tag=(
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM6.03567 7.7338L9.05469 4.72136L9.9821 5.64877L6.03567 9.5952L2.08924 5.64877L3.01666 4.72136L6.03567 7.7338Z" 
          fill={checkbox_color}/>
      </svg>
    );

    return svg_tag;
  };

  return (
  <label>
    {get_check_svg_tag()}
    <input type="checkbox" style={{display:"none"}} 
      checked={myProps.checked}
      value={myProps.value} 
      onChange={myProps.on_change} />
  </label>
  );
};

export default CheckBoxGreen;