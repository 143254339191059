import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchOrderRadio from "@/pcomponents/common/crud/write/radio/radio_arr";
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import SearchArea from './area/search_area';

function FindMultyStaffPopup(props: any) {
  const myProps = {
    closePopup: () => { },
    select_mcomp_seq: "",
    select_mcomp_api_key: "",
    select_mcomp_user_seq: "",
    listOpt: {},
    row_num: 0,
    key: "",
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key: "",
      };
    },//data = { info_arr:[],row_num:0,key:"" };
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data = UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq: myProps.select_mcomp_seq,
    default_mcomp_api_key: myProps.select_mcomp_api_key,
    default_mcomp_user_seq: myProps.select_mcomp_user_seq,
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["teacher", "master", "staff"],
    s_is_login: "1",
    s_user_name_like: "",
    s_addon_comp_link: "1",
    s_search_text_type: "name",
    s_search_text: "",
    s_except_user_seq: "",
    ...myProps.listOpt
  });
  //xcolumn 세팅

  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let tmp_list_opt = { ...listOpt, ...inOptObj };
    setListOpt(tmp_list_opt);
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goCallBackData = () => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key: myProps.key
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = (params: any) => {
    // {
    //   "rowData":rowData,
    //   "idx":idx,
    //   "key":key,
    //   "value":row_val,
    //   "origin_val":origin_val,
    // }
    var key = params.key;
    var render_str = params.value;
    let rowData = params.rowData;

    return render_str;
  };

  return (
    <div>
      <SearchArea
        listOpt={{ ...listOpt }}
        setListOpt={setListOpt}
        xColumnArr={{ ...xColumnArr }}
        list={list}
      ></SearchArea>
      <div className="btn-box-left relative h-8 mt-1">
        <b>총 <span className="text-blue-600">{countInfo.tot}</span> 명</b>
        <span className="px-2">|</span>
        <SearchOrderRadio
          valueTextArr={[
            { "text": "등록순", "value": "a_create_date DESC" },
            { "text": "이름순", "value": "a_user_name" },
          ]}
          value={listOpt.order_id}
          handleInputChange={(e: any) => {
            list({
              "now_page": 1,
              "order_id": e.target.value
            });
          }}
        ></SearchOrderRadio>
        <div className="absolute right-0 top-0 btn-box-right">
          <button className="btn btn-dark" onClick={() => { goCallBackData(); }}>선택</button>
          <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
        </div>
      </div>
      <div className="mt-1">
        <ListComponent
          ref={ListComponentRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_add_idx_num: true,
            is_add_checkbox: true,
            is_fix_width: false,
            onGridReady: () => {
              list({});
            },
            customCellRenderer: cellRenderer,
          }}
        ></ListComponent>
      </div>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
    </div>
  );
}

export default FindMultyStaffPopup;