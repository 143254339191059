function TodoProgressBar(props:any){
   let myProps={
    Style:{},
    percent:0,
    select_stu_info:{},
    ...props
  };
  let Style=myProps.Style;
  let percent=myProps.percent;
  let select_stu_info=myProps.select_stu_info;
  let todo_cnt_data={
    "total":0,
    "done":0
  };
  if(select_stu_info.todo_stat_data){
    percent=select_stu_info.todo_stat_data["total"]["percent"];
    todo_cnt_data["total"]=select_stu_info.todo_stat_data["total"]["count"];
    todo_cnt_data["done"]=select_stu_info.todo_stat_data["total"]["success_cnt"];
  }
  if(percent>100){
    percent=100;
  }
  return (
  <div className={Style.todo_progress_wrap}>
    <span>
      투데이 일정
      {todo_cnt_data["total"]>0&&
        <span className="ml-1">
          ({todo_cnt_data["done"]}/{todo_cnt_data["total"]})
        </span>
      }
    </span>
    <div className={Style.todo_progress_bar_back}>
      <div className={Style.todo_progress_bar} style={{width:percent+"%"}} ></div>
    </div>
    <span>
      {percent}%
    </span>
  </div>
  );
};
export default TodoProgressBar;