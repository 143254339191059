import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import DailyReportCardListArea from "@/pages/mobile/manage/edu/daily_report/common/card_list";

function DailyReportCardListPage() {
  return (
    <MobileLayout has_header={false}
      container_con_style={{ width: "100%", background: "#F1F4F4" }} >
        <HeaderSimple title={"일일보고서"}></HeaderSimple>
        <DailyReportCardListArea></DailyReportCardListArea>
    </MobileLayout>
  );
};
export default DailyReportCardListPage;