import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";

function TitleSupplyArea(props: any) {
  let myProps = {
    select_date: "",
    supply_name:"",
    stu_info: "",
    corp_info: "",
    ...props
  };
  let select_date_json = DateFunc.get_date_json(new Date(myProps.select_date));
  let corp_info = myProps.corp_info;
  let stu_info = myProps.stu_info;
  let trade_tot_info = {
    in_debt: 0,
    in_money: 0,
    out_debt: 0,
    out_money: 0,
    remain_money: 0,
    ...stu_info.trade_tot_info
  };

  return (
    <div className={Style.title_box}>
      <div className={Style.title} >
        {myProps.supply_name}
      </div>
      <div className={Style.title_name} style={{position:"relative"}}>
        {corp_info.a_company_name} 학원
        <span className={Style.right_stu_name}>{stu_info["a_user_name"]} 학생</span>
      </div>
      <div className={Style.supply_money_wrap} >
        <span className={Style.left_money_pre_str}>납부할 금액</span>
        <span className={Style.supply_money}>{strFunc.comma(trade_tot_info.remain_money)}</span>
        <span className="ml-1">원</span>
      </div>
    </div>
  );
};
export default TitleSupplyArea;