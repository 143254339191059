import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useState, useEffect,forwardRef,useImperativeHandle } from 'react';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import YouTubeArea from "@/pcomponents/common/content/video/player/youtube";
import VimeoArea from  "@/pcomponents/common/content/video/player/vimeo";

const VideoBoxArea = forwardRef((props:any, ref) => {
   let myProps={
    Style:{},
    todo_info:{},
    set_todo_info:(inData:any)=>{},
    goWriteTodoInfo:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  const [todo_info,set_todo_info]=useState(myProps.todo_info);
  let video_info=todo_info["video_info"];
  const [is_play,set_is_play]=useState(false);
  const [refresh,set_refresh]=useState(false);
  
  useEffect(() => {
    const timerId=setInterval(setVideoTimeCount,1000);
    return () => clearInterval(timerId);
  },[refresh,todo_info,is_play]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    set_init_data
  }));

  const set_init_data=(inData:any)=>{
    let opt_obj={
      todo_info:myProps.todo_info,
      ...inData
    };
    set_todo_info(opt_obj.todo_info);
  };

  const setVideoTimeCount=()=>{
    if(video_info&&is_play){
      let total_sec=parseInt(strFunc.uncomma(video_info.a_total_sec));
      let complete_sec=parseInt(strFunc.uncomma(video_info.a_complete_sec));
      let watch_sec=parseInt(strFunc.uncomma(todo_info.a_time_sec));
      if(watch_sec>total_sec){
        //watch_sec=total_sec;
      }else{
        watch_sec++;
      }
      let row_todo_info=todo_info;
      if(watch_sec>=total_sec-2){
        watch_sec=total_sec;
      }
      row_todo_info.a_time_sec=watch_sec;
      if(row_todo_info.a_time_sec>total_sec){
        row_todo_info.a_time_sec=total_sec;
      }
      row_todo_info.a_success_time_sec=total_sec;
      if(watch_sec>=complete_sec){
        if(row_todo_info.a_is_success!="1"){
          row_todo_info.a_is_success="1";
          row_todo_info.a_state="complete";
          row_todo_info.a_end_date=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
        }
      }else{
        if(row_todo_info.a_is_success=="1"){
          row_todo_info.a_is_success="";
          row_todo_info.a_state="ing";
          if(strFunc.is_empty(row_todo_info.a_start_date)){
            row_todo_info.a_start_date=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
          }
        }
        if(strFunc.is_empty(row_todo_info.a_start_date_last)){
          row_todo_info.a_start_date_last=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
        }
      }

      if(watch_sec<=total_sec){
        set_todo_info(row_todo_info);
        myProps.set_todo_info(row_todo_info);
        set_refresh(!refresh);
        if(watch_sec%5==0){
          myProps.goWriteTodoInfo({
            todo_info:row_todo_info
          });
        }else if(watch_sec>=complete_sec){
          myProps.goWriteTodoInfo({
            todo_info:row_todo_info
          });
        }else if(row_todo_info.a_state!=todo_info.a_state){
          myProps.goWriteTodoInfo({
            todo_info:row_todo_info
          });
        }
      }
    }
  };

  const get_video_show_data=()=>{
    let complete_sec=parseInt(strFunc.uncomma(video_info.a_complete_sec));
    let watch_sec=parseInt(strFunc.uncomma(todo_info.a_time_sec));
    let percent=0;
    if(watch_sec!=0&&complete_sec!=0){
      percent=Math.ceil((watch_sec/complete_sec)*100);
    }
    let complete_time_json:any=strFunc.secondsToTimeJson(complete_sec);
    let watch_time_json:any=strFunc.secondsToTimeJson(watch_sec);
    let complete_time=complete_time_json.i+":"+complete_time_json.s;
    let watch_time=watch_time_json.i+":"+watch_time_json.s;
    if(complete_time_json.h!="00"){
      complete_time=complete_time_json.h+":"+complete_time_json.i+":"+complete_time_json.s;
    }
    if(watch_time_json.h!="00"){
      watch_time=watch_time_json.h+":"+watch_time_json.i+":"+watch_time_json.s;
    }
    let corse_title="";
    let section_title="";
    if(video_info){
      if(video_info.corse_info){
        corse_title=video_info.corse_info["a_title"];
      }
      if(video_info.section_info){
        section_title=video_info.section_info["a_title"];
      }
    }
    let state_str=TodoStateFunc.get_state_str_by_todo_info(todo_info);
    let percent_color=TodoStateFunc.get_color_of_percent(todo_info);


    return {
      "percent":percent,
      "percent_color":percent_color,
      "corse_title":corse_title,
      "section_title":section_title,
      "title":video_info.a_title,
      "state_str":state_str,
      "complete_time":complete_time,
      "watch_time":watch_time,
    };
  };

  let video_url="";
  let video_url_sort="";
  let init_seek_data={start:0,end:1};
  let video_memo:any="";
  if(video_info!=null){
    video_url=video_info.a_url;
    video_url_sort=video_info.a_url_sort;
    init_seek_data.end=parseInt(strFunc.uncomma(video_info.a_total_sec));
    if(todo_info!=null){
      init_seek_data.start=parseInt(strFunc.uncomma(todo_info.a_time_sec));
      if(init_seek_data.start>=(init_seek_data.end-3)){
        init_seek_data.start=0;
      }
    }
    video_memo=video_info.a_memo;
  }

  let video_show_data=get_video_show_data();

  return (
  <div className={Style.video_wrap}>
    <div className={Style.video_title_wrap}>
      {video_show_data.corse_title}
    </div>
    <div className={Style.video_player_wrap}>
      {video_url_sort=="youtube"&&
        <YouTubeArea 
          video_url={video_url}
          init_seek_data={init_seek_data}
          set_is_play={set_is_play}
        />
      }
      {video_url_sort=="vimeo"&&
        <VimeoArea
          video_url={video_url}
          init_seek_data={init_seek_data}
          set_is_play={set_is_play}
        ></VimeoArea>
      }
    </div>
    <div className={Style.video_progress_bar_wrap}>
      <div className={Style.video_progress_bar_back}></div>
      <div className={Style.video_progress_bar} style={{width:video_show_data.percent+"%"}}></div>
    </div>
    <div className={Style.video_time_wrap}>
      <span style={{color:"#999"}}>
        {video_show_data.section_title}
      </span>
      <span className={Style.video_time_span} >
        {video_show_data.watch_time} / {video_show_data.complete_time}
      </span>
    </div>
    <div className={Style.video_sub_title_wrap}>
      <span className="ml-2" >
        {video_show_data.title}
      </span>
      <span className={Style.video_sub_title_right_state}>
        {video_show_data.percent}% 
        <span className="ml-2" style={{color:video_show_data.percent_color.per}}>
          {video_show_data.state_str}
        </span>
      </span>
    </div>
    <div className={Style.video_memo_wrap}>
      {video_memo}
    </div>
  </div>
  );
});
export default VideoBoxArea;