//import PlusBtn from "@/pcomponents/common/content/list/search/plus_btn";
function PlusBtn(props:any){
  let myProps={
    class_name:"",
    btn_style:{},
    svg_data:{},
    data:{},
    on_click:(inData:any)=>{

    },
    ...props
  };

  let btn_style={
    "display":"inline-block",
    ...myProps.btn_style
  };
  let svg_data={
    width:14,
    height:14,
    box_x:0,
    box_y:0,
    box_w:14,
    box_h:14,
    ...myProps.svg_data
  };
  let viewBox_str=svg_data.box_x+" "+svg_data.box_y+" "+svg_data.box_w+" "+svg_data.box_h;
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={myProps.class_name}
      style={btn_style}
      onClick={()=>{myProps.on_click(myProps.data);}}
      width={svg_data.width} height={svg_data.height} viewBox={viewBox_str} fill="none">
      <path d="M0 6.5H13.5" stroke="black"/>
      <path d="M7 0L7 13.5" stroke="black"/>
    </svg>
  );
};
export default PlusBtn;