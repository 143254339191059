import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import {customHeaderFunc} from '@/pcomponents/common/date_picker/custom_header';
import {CustomInputWrite} from '@/pcomponents/common/date_picker/custom_input';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import getXcolumnJson from "./xcolumn/list";
import strFunc from '@/lib/lyg/string';
import UserAttendRow from "./area/user_attend_row";

function ModifyAttendPopup(props:any) {
  let myProps={
    "userInfoArr":[],
    ...props
  };
  let user=useSelector((state:any) => state.user);
  const xColumnArr=getXcolumnJson();
  const [pageData,setPageData]=useState({
    "write_btn_text":"수정",
    "confirm_msg":"수정 하시겠습니까?",
    ...myProps
  });
  const [user_info_arr,set_user_info_arr]=useState(myProps.userInfoArr);
  const [refresh,set_refresh]=useState(false);

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key="";
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_seq=user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key=user.select_mcomp_link["comp_api_key"];
  }

  useEffect(()=>{
    
  },[]);

  const get_attend_arr_by_user_info_arr=()=>{
    let attend_arr:any=[];
    for(let i=0;i<user_info_arr.length;i++){
      let user_info=user_info_arr[i];
      if(user_info.attend_in_arr&&user_info.attend_in_arr.length>0){
        attend_arr=[
          ...attend_arr,
          ...user_info.attend_in_arr
        ];
      }
      if(user_info.attend_out_arr&&user_info.attend_out_arr.length>0){
        attend_arr=[
          ...attend_arr,
          ...user_info.attend_out_arr
        ];
      }
    }
    return attend_arr;
  };

  const goWrite=(e:any) => {
    if(strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원 로그인 정보 없음.");
      return false;
    }

    if(!confirm(pageData["confirm_msg"])){
      return false;
    }

    let attend_data_arr:any=get_attend_arr_by_user_info_arr();
    if(attend_data_arr.length==0){
      alert("출석데이터가 없습니다.");
      return false;
    }

    let formJsonData={
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
      "data_arr":attend_data_arr,
      "is_update":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/attend/write',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        alert("완료.");
        props.callback(response.data["data"]);
        props.closePopup();
      }else{
        alert(response.data["msg"]);
      }
    });
  };
  
  const get_user_attend_row_tags=()=>{
    let user_attend_row_tags=user_info_arr.map((item:any,idx:number)=>{
      return (
        <UserAttendRow
          key={idx}
          user_idx={idx}
          user_info={item}
          set_user_info={(inData:any)=>{
            let tmp_user_info_arr=user_info_arr;
            tmp_user_info_arr[idx]=inData;
            set_user_info_arr(tmp_user_info_arr);
            set_refresh(!refresh);
          }}
        ></UserAttendRow>
      );
    });
    return user_attend_row_tags;
  };

  return (
    <div>
      <div>
        {get_user_attend_row_tags()}
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-brown" onClick={goWrite}>{pageData["write_btn_text"]}</button>
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ModifyAttendPopup;
