import Style from './css/style.module.css';
import { useNavigate } from 'react-router-dom';
import math_menu_arr from "./data/math_menu_arr";

function MenuArea(props:any){
   let myProps={
    ...props
  };
  const navigate = useNavigate();
  
  const get_menu_box_tags=()=>{
    let menu_box_tags=[];
    for(let i=0;i<math_menu_arr.length;i++){
      let menu=math_menu_arr[i];
      menu_box_tags.push(
        <div key={i} className={Style.menu_box} onClick={()=>navigate(menu.url)}>
          <div className={Style.menu_box_inner}>
            <div className={Style.menu_box_inner_text}>
              {menu.name}
            </div>
          </div>
        </div>
      );
    }
    return menu_box_tags;
  };

  return (
  <div style={{padding:10}}>
    <h3>수학</h3>
    <div className={Style.menu_wrap+" mt-2"}>
      {get_menu_box_tags()}
    </div>
  </div>
  );
};
export default MenuArea;