import Style from "./css/style.module.css";
import { useNavigate } from 'react-router-dom';
import DateFunc from '@/lib/lyg/date_func';
import FileGroupArea from "./group";

function FileSubmitArea(props:any){
   let myProps={
    title:"제출하기",
    back_press_url:"/mobile/home_work",
    "s_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
    file_arr:[],
    list_opt:{},

    s_par_id: "home_work_file",
    is_show_img: true,
    is_show_audio: true,
    is_show_video: true,
    user_data:{
      "iam_mcomp_seq":"",
      "iam_mcomp_user_seq":"",
      "comp_api_key":"",
    },
    ...props
  };
  const navigate = useNavigate();
  let file_arr=myProps.file_arr;

  const go_home_work_file_page=()=>{
    let send_data={
      "back_press_url":myProps.back_press_url,
      "s_date":myProps.s_date,
      "list_opt":myProps.list_opt,

      s_par_id:myProps.s_par_id,
      is_show_img:myProps.is_show_img,
      is_show_audio:myProps.is_show_audio,
      is_show_video:myProps.is_show_video,
      user_data:myProps.user_data,
    };
    let view_url="/mobile/home_work_file";
    navigate(view_url,
      { state: send_data });
  };

  const get_group_file_arr=()=>{
    let group_file_key_arr:any={
      "img":{
        "title":"사진",
        "file_arr":[],
      },
      "audio":{
        "title":"녹음 파일",
        "file_arr":[],
      },
      "video":{
        "title":"비디오 파일",
        "file_arr":[],
      },
    };
    if(myProps.is_show_img==false){
      delete group_file_key_arr["img"];
    }
    if(myProps.is_show_audio==false){
      delete group_file_key_arr["audio"];
    }
    if(myProps.is_show_video==false){
      delete group_file_key_arr["video"];
    }
    for(let i=0;i<file_arr.length;i++){
      let file_info=file_arr[i];
      let a_sort1=file_info["a_sort1"];
      if(group_file_key_arr[a_sort1]==undefined){
        group_file_key_arr[a_sort1]={
          "title":"기타",
          "file_arr":[],
        }
      }
      group_file_key_arr[a_sort1]["file_arr"].push(file_info);
    }

    let group_file_arr=[];
    for(let key in group_file_key_arr){
      group_file_arr.push({
        "key":key,
        ...group_file_key_arr[key]
      }); 
    }
    return group_file_arr;
  };

  const get_group_file_tags=()=>{
    let group_file_arr=get_group_file_arr();

    let group_file_tags=group_file_arr.map((item:any,idx:number)=>{
      return (
        <FileGroupArea
          key={idx}
          Style={Style}
          group_info={item}
        ></FileGroupArea>
      );
    });
    return group_file_tags;
  };

  return (
  <div className={Style.file_group_wrap+" noto_sans"}>
    <div className={Style.file_top_title}>
      {myProps.title}
      <span className={Style.file_top_title_right}>
        <span className={Style.file_top_plus_btn} onClick={()=>{
          go_home_work_file_page();
        }}>+</span>
      </span>
    </div>
    <div className={Style.file_group_list}>
      {get_group_file_tags()}
    </div>
  </div>
  );
};
export default FileSubmitArea;