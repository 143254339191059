import left_arrow_img from "@/img/ico/arrow/white/left_arrow.png";
import right_arrow_img from "@/img/ico/arrow/white/right_arrow.png";
import left_arrow_img2 from "@/img/ico/arrow/left_arrow_black.png";
import right_arrow_img2 from "@/img/ico/arrow/right_arrow_black.png";

function ArrowArea(props:any){
   let myProps={
    on_click:(inData:any)=>{},
    left_right:"left",//right
    arrow_color:"white",//black,white
    ...props
  };

  let button_style={
    height:myProps.height,
    cursor:"pointer"
  };

  let left_arrow_img_str=left_arrow_img;
  let right_arrow_img_str=right_arrow_img;
  if(myProps.arrow_color=="black"){
    left_arrow_img_str=left_arrow_img2;
    right_arrow_img_str=right_arrow_img2;
  }
  
  return (
  <div style={{height:"100%",lineHeight:"122px"}}>
    {myProps.left_right=="left"?
    <img src={left_arrow_img_str} style={button_style} onClick={()=>{myProps.on_click({"sort":"left"});}} />
    :
    <img src={right_arrow_img_str} style={button_style} onClick={()=>{myProps.on_click({"sort":"right"});}} />
    }
  </div>
  );
};
export default ArrowArea;