function AttendSearchBar(props:any){
   let myProps={
    Style:{},
    attend_tot_info:{
      "tot":0,
      "attend":0,
      "late":0,
      "absent":0,
    },
    s_attend_sort_detail:"",
    list:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;



  const get_tot_item_tags=()=>{
    let tot_item_arr=[
      {"name":"전체","tot":0,"color":"#000","key":"tot"},
      {"name":"출석","tot":0,"color":"#5CB600","key":"attend"},
      {"name":"지각","tot":0,"color":"#EDCB40","key":"late"},
      {"name":"결석","tot":0,"color":"#E6494E","key":"absent"},
    ];
    for(let i=0;i<tot_item_arr.length;i++){
      let key=tot_item_arr[i]["key"];
      if(myProps.attend_tot_info[key]){
        tot_item_arr[i]["tot"]=myProps.attend_tot_info[key];
      }
    }
    let tot_item_tags=tot_item_arr.map((item:any,idx:number)=>{
      let tot_p_style={
        color:item["color"]
      };
      let tmp_search_val=item.key;
      if(item.key=="tot"){
        tmp_search_val="";
      }
      let active_color="";
      if(tmp_search_val==myProps.s_attend_sort_detail){
        active_color="#3FAC96";
      }
      return (
        <div key={idx} className={Style.item} onClick={()=>{
          myProps.list({"now_page":"1","s_attend_sort_detail":tmp_search_val});
        }}>
          <p className={Style.attend_bar_item_title}
             style={{color:active_color}}>{item["name"]}</p>
          <p className={Style.attend_bar_item_count} style={tot_p_style}>
            {item["tot"]}명
          </p>
        </div>
      );
    });

    return tot_item_tags;
  };
  
  return (
  <div className={Style.attend_bar_wrap}>
    {get_tot_item_tags()}
  </div>
  );
};
export default AttendSearchBar;