import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect, useMemo, useCallback,forwardRef } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

function RequestPopup(props:any) {
  let myPorps:any={
    corse_info:null,
    closePopup:()=>{},
    callBack:(data:any)=>{},
    ...props
  };
  let user=useSelector((state:any) => state.user);

  const [rowData,setRowData]=useState({
    "a_title":"수강 신청 합니다."
  });

  useEffect(() => {
    
  }, []);

  const onChangeInput=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;

    setRowData({
      ...rowData,
      ...{
        [name]:value
      }
    });
  };

  const goRequestWrite=()=>{
    if(rowData.a_title==""){
      alert("제목 입력 필요.");
      return false;
    }

    let writer_req_form_data:any={
      "corse_seq":myPorps.corse_info.a_seq,
      "title":rowData.a_title,
    };
    let select_user_data=UserFunc.get_select_comp_user(user);
    if(strFunc.is_empty(select_user_data.select_mcomp_seq)){
      return false;
    }
    writer_req_form_data.select_mcomp_seq=select_user_data.select_mcomp_seq;
    writer_req_form_data.select_mcomp_user_seq=select_user_data.select_mcomp_user_seq;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/request/add_request_one',
    writer_req_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myPorps.callBack(response.data["data"]["request_info"]);
        myPorps.closePopup();
      }else{
        alert(response.data["msg"]);
        myPorps.closePopup();
      }
    });
  };

  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>코스명</th>
              <td colSpan={3}>{myPorps.corse_info.a_title}</td>
            </tr>
            <tr>
              <th>신청제목</th>
              <td colSpan={3}>
                <input type="text" className="row-input" name="a_title" value={rowData.a_title} onChange={(e:any)=>{onChangeInput(e);}}  />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{goRequestWrite();}}>신청하기</button>
        <button className="btn btn-line-gray" onClick={()=>{myPorps.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default RequestPopup;
