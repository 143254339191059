import Style from "./css/style.module.css";
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

function StuNameBarArea(props:any){
   let myProps={
    row_data:{},
    info_arr:[],
    select_idx:0,
    set_select_idx:(inData:any)=>{},
    on_left_click:()=>{},
    on_right_click:()=>{},
    ...props
  };
  let row_data=myProps.row_data;
  let select_idx=parseInt(strFunc.uncomma(myProps.select_idx));
  let info_arr=myProps.info_arr;

  let select_user_grade_option_arr=XcolumnFunc.getSortByTableKey({'table':'user','key':'a_stu_grade'});

  const on_left_right_click=(sort:"left"|"right")=>{
    let tmp_select_idx=select_idx;
    if(sort=="left"){
      tmp_select_idx--;
      if(tmp_select_idx<0){
        tmp_select_idx=info_arr.length-1;
      }
    }else{
      tmp_select_idx++;
      if(tmp_select_idx>=info_arr.length){
        tmp_select_idx=0;
      }
    }
    myProps.set_select_idx(tmp_select_idx);
  };

  let stu_grade_name=row_data.a_stu_grade;
  for(let i=0;i<select_user_grade_option_arr.length;i++){
    let row_select=select_user_grade_option_arr[i];
    if(stu_grade_name==row_select.value){
      stu_grade_name=row_select.text;
    }
  }
  let stu_class_name="";
  if(row_data.class_arr&&row_data.class_arr.length>0){
    let tmp_class_name_arr=[];
    for(let i=0;i<row_data.class_arr.length;i++){
      tmp_class_name_arr.push(row_data.class_arr[i]["class_name"]);
    }
    stu_class_name=tmp_class_name_arr.join("/");
  }

  return (
    <div className={Style.header + " noto_sans"} >
      <div className={Style.header_con}>
        <span className={Style.header_left}
          onClick={() => { on_left_right_click("left"); }} >
          <img src={left_arrow_ico} style={{ height: 17 }} />
        </span>
        {row_data.a_user_name}
        <span className="ml-2" style={{color:"#999"}}>
          {select_idx+1}/{info_arr.length}
        </span>
        <span className={Style.header_right}
          onClick={() => { on_left_right_click("right"); }} >
          <img src={right_arrow_ico} style={{ height: 17 }} />
        </span>
      </div>
      <div className={Style.header_stu_info_wrap}>
        <span>{row_data.a_school_name}</span>
        <span>{stu_grade_name}</span>
        {/* <span>{stu_class_name}</span> */}
      </div>
    </div>
  );
};
export default StuNameBarArea;