import { useState, useRef, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function AddAttendPage(props: any) {
  let myProps = {
    mcomp_stu_seq: "",
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },
    attend_sort: "in",
    attend_sort_detail: "attend",
    attend_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    userInfoArr: [],
    call_back: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_seq = myProps.user_data.iam_mcomp_seq;
  let iam_mcomp_user_seq = myProps.user_data.iam_mcomp_user_seq;
  let comp_api_key = myProps.user_data.comp_api_key;
  let mcomp_stu_seq = myProps.mcomp_stu_seq;

  const [pageData, setPageData] = useState({
    "write_btn_text": "출석",
    "confirm_msg": "출석 하시겠습니까?",
  });
  const [row_data, set_row_data] = useState({
    "a_ymd": myProps.attend_date,
    "a_user_seq": "",
    "a_sort": myProps.attend_sort,
    "a_sort_detail": myProps.attend_sort_detail,
    "a_time": DateFunc.get_date_format(new Date(), "h:i:s"),
    "a_user_name": "",
    "a_memo": "",
    "a_writer": user.user_name,
    "a_writer_seq": iam_mcomp_user_seq,
  });
  const [userInfoArr, setUserInfoArr] = useState(myProps.userInfoArr);
  const [pre_attend_arr, set_pre_attend_arr] = useState([]);
  const [is_send_msg, set_is_send_msg] = useState(false);
  let is_delete_attend=false;
  if(pre_attend_arr.length>0){
    if(pre_attend_arr[0]["a_sort_detail"]==myProps.attend_sort_detail){
      is_delete_attend=true;
    }
  }

  let attend_state_arr = [
    { "value": "attend", "text": "등원" },
    { "value": "late", "text": "등원(지각)" },
    { "value": "absent", "text": "결석" },
    { "value": "out", "text": "하원" },
  ];

  useEffect(() => {
    get_user_info_arr_by_ajax();
    let sort_text="등원";
    let attend_state_len = attend_state_arr.length;
      for (let i = 0; i < attend_state_len; i++) {
        let item = attend_state_arr[i];
        if (myProps.attend_sort_detail == item.value) {
          sort_text = item.text;
        }
      }
      setPageData({
        ...pageData,
        ...{
          "write_btn_text": sort_text,
          "confirm_msg": sort_text + " 처리 하시겠습니까?"
        }
      });
  }, []);

  const get_user_info_arr_by_ajax = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(mcomp_stu_seq)) {
      return false;
    }

    let formJsonData = {
      "s_pri_arr": [mcomp_stu_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",

      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setUserInfoArr(response.data["data"]["info_arr"]);
          get_pre_attend_info({
            user_info_arr: response.data["data"]["info_arr"],
          });
        } else {

        }
      });
  };
  const get_pre_attend_info = (inData: any) => {
    let opt_obj = {
      user_info_arr: userInfoArr,
      ...inData
    };
    if (opt_obj.user_info_arr.length == 0) {
      return false;
    }
    if (opt_obj.user_info_arr.length != 1) {
      return false;
    }
    if (strFunc.is_empty(myProps.attend_date)) {
      return false;
    }

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    let formJsonData = {
      "s_ymd": myProps.attend_date,
      "s_user_seq": opt_obj.user_info_arr[0]["a_seq"],
      "s_sort": myProps.attend_sort,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",

      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_pre_attend_arr(response.data["data"]["info_arr"]);
            let pre_attend_info = response.data["data"]["info_arr"][0];
            set_row_data({
              ...row_data,
              ...pre_attend_info,
              a_sort_detail:row_data.a_sort_detail
            });
          }
        } else {

        }
      });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    if (target.type === 'checkbox') {
      value = target.checked ? "1" : "";
    }
    const name = target.name;
    if (name == "a_sort_detail") {
      if(strFunc.is_empty(value)){
        value="attend";
      }
      let sort_text = "등록";
      let attend_state_len = attend_state_arr.length;
      for (let i = 0; i < attend_state_len; i++) {
        let item = attend_state_arr[i];
        if (value == item.value) {
          sort_text = item.text;
        }
      }
      setPageData({
        ...pageData,
        ...{
          "write_btn_text": sort_text,
          "confirm_msg": sort_text + " 처리 하시겠습니까?"
        }
      });
    }

    set_row_data({
      ...row_data,
      ...{ [name]: value }
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    set_row_data({
      ...row_data,
      ...{ [key]: date_str }
    });
  };

  const goWrite = (e: any) => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    if (userInfoArr.length == 0) {
      alert("원생 선택이 없습니다.");
      return false;
    }

    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let attend_data_arr: any = getAttendArrByUser();
    if (attend_data_arr.length == 0) {
      alert("출석데이터가 없습니다.");
      return false;
    }

    let formJsonData = {
      "data_arr": attend_data_arr,
      "is_send_msg": is_send_msg,
      "is_default_val": "1",
      "is_update": "",

      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/add_attend', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.call_back(response.data["data"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDeletePreAttend=()=>{
    if(pre_attend_arr.length==0){
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    if (!confirm(pageData["write_btn_text"]+"을 취소 하시겠습니까?")) {
      return false;
    }

    let del_attend_arr=[...pre_attend_arr];
    let formJsonData = {
      "data_arr": del_attend_arr,
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.call_back(response.data["data"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const getAttendArrByUser = () => {
    let attend_data_arr: any = [];

    let user_len = userInfoArr.length;
    for (let i = 0; i < user_len; i++) {
      let user_info = userInfoArr[i];

      let attend_info = {
        ...row_data,
        "a_user_seq": user_info["a_seq"],
        "a_user_name": user_info["a_user_name"],
      };
      attend_data_arr.push(attend_info);
    }
    return attend_data_arr;
  };

  let select_attend_sort_arr = [];
  for (let i = 0; i < attend_state_arr.length; i++) {
    let row_sort_detaill = attend_state_arr[i];
    if (row_data.a_sort == "in") {
      if (row_sort_detaill.value != "out") {
        select_attend_sort_arr.push(row_sort_detaill);
      }
    } else {
      if (row_sort_detaill.value == "out") {
        select_attend_sort_arr.push(row_sort_detaill);
      }
    }
  }

  return (
    <div>
      <div className="write_table_small mt-1">
        <table>
          <colgroup>
            <col width="110px"></col>
            <col width="*"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>날짜</th>
              <td>
                {/* <DatePicker
                  selected={row_data.a_ymd != "" ? new Date(row_data.a_ymd) : null}
                  onChange={(date: Date) => {
                    onChangeDatePicker("a_ymd", date);
                  }}
                  locale={ko}
                  dateFormat="yyyy-MM-dd"
                  customInput={<CustomInputWrite />}
                  renderCustomHeader={customHeaderFunc}
                /> */}
                {row_data.a_ymd}
              </td>
            </tr>
            <tr>
              <th>
                시간
              </th>
              <td>
                {row_data.a_time}
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td>
                <input type="text" className="row-input" name="a_memo" value={row_data.a_memo} onChange={handleInputChange} placeholder="메모" />
              </td>
            </tr>
            <tr>
              <th>알림전송</th>
              <td>
                <label>
                  <input type="checkbox" checked={is_send_msg} onChange={(e: any) => {
                    set_is_send_msg(e.target.checked);
                  }} />
                  <span style={{ color: is_send_msg ? "green" : "orange", marginLeft: 5 }}>
                    {is_send_msg ? "알림전송" : "알림 미전송"}
                  </span>
                </label>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <SelectBoxBar
                  valueTextArr={select_attend_sort_arr}
                  value={row_data.a_sort_detail}
                  name={"a_sort_detail"}
                  on_change={(inData: any) => {
                    handleInputChange({
                      target: {
                        name: "a_sort_detail",
                        value: inData["value"]
                      }
                    });
                  }}
                ></SelectBoxBar>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        {is_delete_attend==false&&
          <button className="btn btn-brown" onClick={goWrite}>{pageData["write_btn_text"]} 처리</button>
        }
        {is_delete_attend&&
          <button className="btn btn-red ml-1" onClick={goDeletePreAttend}>{pageData["write_btn_text"]} 취소</button>
        }
      </div>
    </div>
  );
};
export default AddAttendPage;