import { useSelector, useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import { useState,useRef,useEffect } from 'react';
import avocado_ico from "@/img/mobile/char/avocado.png";
import notice_ico_100 from "@/img/mobile/home/notice_ico_100.png";
import option_ico_100 from "@/img/mobile/home/option_ico_100.png";
import notice_ico_green_100 from "@/img/mobile/home/notice_ico_green_100.png";
import option_ico_green_100 from "@/img/mobile/home/option_ico_green_100.png";
import side_menu_back from "@/img/mobile/menu/side_menu_back.png";
import {smenu} from "@/pcomponents/mobile/layout/menu/data/menu_arr";

const Header=(props:any)=>{
  let myProps={
    is_home_body:false,
    ...props
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user=useSelector((state:any) => state.user);
  const [isViewSideMenu,setIsVIewSideMenu] = useState(false);
  const [topTitle,setTopTitle]=useState("Plan,I");
  useEffect(()=>{
    setTopTitleAction();
  },[topTitle]);

  const setTopTitleAction=()=>{
    let menu_len=smenu.length;
    for(let i=0;i<menu_len;i++){
      if(smenu[i].url==window.location.pathname){
        setTopTitle(smenu[i].name);
      }
    }
  };

  let notice_src=notice_ico_green_100;
  let option_src=option_ico_green_100;
  let carrot_back_color="#9eac3d";
  if(myProps.is_home_body){
    notice_src=notice_ico_100;
    option_src=option_ico_100;
    carrot_back_color="#fff";
  }
  return (
    <div id="m_header">
      <div className="m_header_con">
        <div className="m_header_left">
          <img src={avocado_ico} style={{
              height:30,rotate:"-15deg",background:carrot_back_color,borderRadius:30,marginLeft:5,padding:2,cursor:"pointer"
            }}
            onClick={()=>{navigate("/mobile");}} />
        </div>
        <div className="m_header_center">
          
        </div>
        <div className="m_header_right">
          <img src={notice_src} style={{height:27,cursor:"pointer"}} onClick={()=>{navigate("/mobile/push/list");}} />
          <a onClick={()=>{navigate("/mobile/setting")}} >
            <img src={option_src} className="ml-2" style={{height:27}} />
          </a>
        </div>
      </div>
      <div className="m_header_title">
          {topTitle}
      </div>
      {isViewSideMenu&&
      <div className="m_header_s_menu">
        <img src={side_menu_back} className="m_header_s_menu_back" />
      </div>
      }
    </div>
  );
};

export default Header;