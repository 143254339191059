import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import { useSelector, useDispatch } from 'react-redux';
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";

function TopTitleArea(props:any){
   let myProps={
    infoArr:[],
    ...props
  };
  let user = useSelector((state: any) => state.user);
  
  const get_total_data=()=>{
    let total_data:any={
      tot:0,
      success_cnt:0,
      study_cnt:0,
      homework_cnt:0,
      per:0,
      per_arr:[],
      per_sum:0,
      time_sec:0,
      time_json:{h:"00",i:"00",s:"00"},
    };
    for(let i=0;i<myProps.infoArr.length;i++){
      let info=myProps.infoArr[i];
      total_data.tot++;
      let percent=TodoStateFunc.get_percent_by_todo_info(info);
      if(info["a_is_success"]=="1"){
        percent=100;
        total_data.success_cnt++;
      }
      if(percent>100){percent=100;}
      total_data.per_arr.push(percent);
      total_data.per_sum+=percent;

      if(info["a_sort"]=="study"){
        total_data.study_cnt++;
      }else if(info["a_sort"]=="homework"){
        total_data.homework_cnt++;
      }
      
      if(info["a_time_sec"]){
        total_data.time_sec+=parseInt(strFunc.uncomma(info["a_time_sec"]));
      }
    }
    total_data.time_json=strFunc.secondsToTimeJson(total_data.time_sec);
    total_data.per=0;
    if(total_data.per_arr.length!=0&&total_data.per_sum!=0){
      total_data.per=Math.floor(total_data.per_sum/total_data.per_arr.length);
    }
    
    return total_data;
  };
  let total_data:any=get_total_data();

  return (
  <div className={Style.title_area+" noto_sans"}>
    <div className={Style.title_area_con} >
      <div className={Style.title_text_wrap}>
        안녕하세요. {user.user_name}님,
        <p className={Style.title_text_sub}>힘내서 같이 공부해요!</p>
      </div>
      <div className={Style.title_stat_box}>
        <div>
          오늘의 플랜 달성률
        </div>
        <div className={Style.title_stat_box_sub_text}>
          {total_data.time_sec!=0?
            <span>
              {total_data.time_json.h}시간 {total_data.time_json.i}분 공부했어요!
            </span>
            :<span>..</span>
          }
          <span className={Style.title_stat_box_sub_text_per_text}>
            {total_data.per}%
          </span>
        </div>
        <div className={Style.per_bar_wrap}>
          <div className={Style.per_bar_back}></div>
          <div className={Style.per_bar_stat} style={{width:total_data.per+"%"}}></div>
        </div>
        <div className={Style.stat_wrap}>
          <div className={Style.stat_wrap_item}>
            학습 {total_data.study_cnt}건
          </div>
          <div className={Style.stat_wrap_item}>
            숙제 {total_data.homework_cnt}건
          </div>
          <div className={Style.stat_wrap_item}>
            완료 {total_data.success_cnt}건
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
export default TopTitleArea;