import { useState } from 'react'
import SearchBtn from "@/pcomponents/common/content/list/search/search_btn";
import PlusBtn from "@/pcomponents/common/content/list/search/plus_btn";
import left_arrow_img from "@/img/ico/arrow/left_arrow.png";

const SearchArea = (props: any) => {
  let myProps = {
    listOpt: {
      'order_id': 'a_create_date DESC',
      'order_type': '',
    },
    list: (inData: any) => { },
    onclick_add_room: () => { },
    on_click_title: () => { },
    ...props,
  };

  const [is_show_search, set_is_show_search] = useState(false);

  let div_style:any={
    position: "relative", 
    fontSize: 15, 
    lineHeight: "40px",
    padding:"0 15px",
    marginTop:5,
    minHeight:40,
    borderBottom: "0.5px solid #dadada80",
    background: "#FFF",
    boxShadow: "0px 1px 5px 0px #0000000d"
  };

  return (
    <div className="noto_sans" style={div_style} >
      <div>
        <span
          style={{fontSize:16,fontWeight:500,cursor:"pointer"}}
          onClick={()=>{myProps.on_click_title();}}
        >
          <img src={left_arrow_img} style={{height:17,paddingBottom:2,marginRight:4}}/>
          플랜톡
        </span>
        <span style={{ position: "absolute", right: 15, top: 0 }} >
          <SearchBtn
            on_click={() => {
              set_is_show_search(!is_show_search);
            }} ></SearchBtn>
          <PlusBtn class_name="ml-3"
            on_click={() => { myProps.onclick_add_room(); }}>+</PlusBtn>
        </span>
      </div>
      {is_show_search &&
        <div>
          <select className="search_input" value={myProps.listOpt.order_id} onChange={(e: any) => {
            myProps.list({ "order_id": e.target.value, "now_page": 1 });
          }}>
            <option value="a_update_date DESC">최신순</option>
            <option value="a_update_date">오래된순</option>
          </select>
        </div>
      }
    </div>
  );
};
export default SearchArea;