import { get_char_img } from "@/pcomponents/common/content/char/data/char_data";
import math_char_img from "@/img/mobile/game/math/math_char.png";

function CharBarArea(props: any) {
  let myProps = {
    Style: {},
    char_user_info: {},
    game_data_ref: null,
    char_bar_ref:null,
    ...props
  };

  let Style = myProps.Style;
  let char_user_info = myProps.char_user_info;
  let game_data_ref = myProps.game_data_ref;
  let char_bar_ref=myProps.char_bar_ref;

  let char_img_src = math_char_img;
  if (char_user_info != null) {
    char_img_src = get_char_img({ id: char_user_info["a_sort_id"] });
  }

  const get_nut_img_tags=()=>{
    let nut_arr=[];
    if(char_bar_ref.current&&char_bar_ref.current.nut_arr){
      nut_arr=char_bar_ref.current.nut_arr;
    }
    let nut_img_tags=nut_arr.map((item:any,idx:number)=>{
      if(idx>10){
        return "";
      }

      let nut_x=item.x+100;

      return (
        <img key={idx} src={item.src} className={Style.top_nut_img} style={{right:nut_x+"px"}} />
      );
    });

    return nut_img_tags;
  };

  return (
    <div className={Style.top_char_wrap}>
      {get_nut_img_tags()}
      <img src={char_img_src} className={Style.top_char_img} />
    </div>
  );
};
export default CharBarArea;