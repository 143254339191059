//import TStuTodoBaseFunc from "@/pcomponents/common/content/tea/tstu_todo/func/base_func";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

class TStuTodoBaseFunc {
  static default_row_data = {
    a_ymd: "",
    a_seq: "",
    a_mcomp_seq: "",
    a_mcomp_stu_seq: "",
    a_main_stu_seq: "",
    a_stu_name: "",
    a_owner_mcomp_seq: "",
    a_owner_mcomp_user_seq: "",
    a_owner_main_user_seq: "",
    a_owner_name: "",
    a_writer_mcomp_seq: "",
    a_writer_mcomp_user_seq: "",
    a_writer_main_user_seq: "",
    a_writer_name: "",
    a_public_sort: "all",
    a_subject_name: "",
    a_reserve_date: "",
    a_start_date: "",
    a_end_date: "",
    a_is_success: "",
    a_success_date: "",
    a_state: "ready",
    a_title: "",
    a_memo: "",
    a_result_memo: "",
  };
  static select_arr_json = {
    a_public_sort: [
      { value: "all", text: "전체" },
      { value: "manage", text: "관리자" },
      { value: "iam", text: "나" },
    ],
  };
  static get_default_row_data(inData: any) {
    let opt_obj = {
      date_str: DateFunc.get_date_format(new Date(), "Y-m-d"),
      user: {},
      iam_info: {},
      row_data: {},
      ...inData
    };
    let user = opt_obj.user;
    let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
    let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
    let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
    let comp_api_key = user_select_data.comp_api_key;
    let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;
    let iam_info = opt_obj.iam_info;
    let subject_name = "";
    if (iam_info && iam_info.a_main_subject) {
      subject_name = iam_info.a_main_subject;
    }
    let new_row_data = {
      ...this.default_row_data,
      a_ymd: strFunc.getNumber2(opt_obj.date_str),
      a_mcomp_seq: iam_mcomp_seq,
      a_owner_mcomp_seq: iam_mcomp_seq,
      a_owner_mcomp_user_seq: iam_mcomp_user_seq,
      a_owner_name: user.user_name,
      a_writer_mcomp_seq: iam_mcomp_seq,
      a_writer_mcomp_user_seq: iam_mcomp_user_seq,
      a_writer_name: user.user_name,
      a_subject_name: subject_name,
      a_reserve_date: opt_obj.date_str,
      ...opt_obj.row_data
    };
    return new_row_data;
  };
}
export default TStuTodoBaseFunc;