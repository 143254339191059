const ChatHeader = (props: any) => {
  let myProps = {
    room_info: {},
    user:{},
    onClickBack: () => { },
    onClickMember: () => { },
    ...props
  };
  return (
    <div className="chat_view_header">
      <div className="chat_view_header_pre_box"  onClick={() => { myProps.onClickBack(); }} >
        <svg xmlns="http://www.w3.org/2000/svg" 
          style={{display:"inline-block"}}
          width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M16.1667 7.54165H4.50374L9.86083 2.18456L8.49999 0.833313L0.833328 8.49998L8.49999 16.1666L9.85124 14.8154L4.50374 9.45831H16.1667V7.54165Z" fill="black"/>
        </svg>
      </div>
      <div className="chat_view_header_title">{myProps.room_info.a_name}</div>
      <div className="chat_view_header_mem_box" onClick={() => { myProps.onClickMember(); }} >
        멤버
      </div>
    </div>
  );
};
export default ChatHeader;