import ChildManageButton from "./area/child_manage_btn";
import { useNavigate } from 'react-router-dom';
function TopTitleArea(props:any){
   let myProps={
    user:{},
    select_comp_stu_row:null,
    Style:{},
    ...props
  };
  let Style=myProps.Style;
  let user=myProps.user;
  let select_comp_stu_row=myProps.select_comp_stu_row;
  const navigate = useNavigate();

  return (
  <div className={Style.top_select_wrap}>
    <div className={Style.con_wrap}>
      <div>
        <div>
          안녕하세요. {user.user_name}님
        </div>
        <div style={{fontSize:22}}>
          {select_comp_stu_row.user_name}님 기록이에요
        </div>
      </div>
      <ChildManageButton
        Style={Style}
        on_click={()=>{
          navigate("/mobile/parent/stu/manage");
        }}
      ></ChildManageButton>
    </div>
  </div>
  );
};
export default TopTitleArea;