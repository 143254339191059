import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Style from "./css/style.module.css";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function BottomTodoArea(props: any) {
  let myProps = {
    "select_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    todo_arr: [],
    list_opt:{},
    ...props
  };
  
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const [open_detail_todo_seq, set_open_detail_todo_seq] = useState("");
  const [is_all_show_detail,set_is_all_show_detail]=useState(false);

  let select_todo_arr: any = [];
  for (let i = 0; i < myProps.todo_arr.length; i++) {
    let todo_info = myProps.todo_arr[i];
    if (todo_info["a_reserve_start_date"].substring(0, 10) == myProps.select_date) {
      select_todo_arr.push(todo_info);
    }
  }

  let iam_mcomp_seq="";
  let iam_mcomp_user_seq = "";
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_seq=user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
  }

  const onClickTodoRow = (inData: any) => {
    let opt_obj = {
      row_num: 0,
      todo_info: {},
      ...inData
    };

    let todo_pri_val = opt_obj.todo_info.a_ymd + "," + opt_obj.todo_info.a_seq;
    //display toggle
    if (open_detail_todo_seq == todo_pri_val) {
      set_open_detail_todo_seq("");
    } else {
      set_open_detail_todo_seq(todo_pri_val);
    }
  };

  const onClickGoDetailPage=(inData: any)=>{
    let opt_obj = {
      row_num: 0,
      todo_info: {},
      is_go_start:false,
      ...inData
    };

    let send_data={
      "todo_info":opt_obj.todo_info,
      "back_press_url":"/mobile/sche",
      "is_go_start":opt_obj.is_go_start,
      "list_opt":myProps.list_opt,
    };
    let view_url="/mobile/todo/todo_view";
    if(opt_obj.todo_info["a_par_id"]=="video_list"){
      view_url="/mobile/todo/todo_view_video";
    }
    navigate(view_url,
      { state: send_data });
  };

  const goTodoWritePage=()=>{
    let send_data={
      "select_date":myProps.select_date,
      "list_opt":myProps.list_opt,
    };
    navigate("/mobile/todo/todo_write",
      { state: send_data });
  };

  const get_todo_tags = () => {
    let todo_tags = select_todo_arr.map((item: any, idx: number) => {
      let state_str = TodoStateFunc.get_state_str_by_todo_info(item);
      let todo_percent = TodoStateFunc.get_percent_by_todo_info(item);
      let sort_str = TodoStateFunc.get_sort_str_by_todo_info(item);
      if(sort_str==""){sort_str="기타";}
      let todo_color = TodoStateFunc.get_color_of_percent(
        item.a_is_success=="1"?100:todo_percent
      );
      let todo_pri_val = item.a_ymd + "," + item.a_seq;
      let todo_row_style: any = {};
      if (open_detail_todo_seq == todo_pri_val) {
        todo_row_style["borderBottom"] = 0;
      }
      let time_sec_json: any = strFunc.secondsToTimeJson(strFunc.uncomma(item.a_time_sec));
      let success_time_json: any = strFunc.secondsToTimeJson(strFunc.uncomma(item.a_success_time_sec));
      let success_amount_str = "";
      if (item.a_success_amount) {
        success_amount_str = strFunc.comma(item.a_success_amount) + " " + item.a_amount_sort;
      }

      let amount_num_str = strFunc.comma(item.a_amount_num);

      let is_show_play_btn=false;
      if(item.a_state!="ing"){
        is_show_play_btn=true;
        if(item.a_state=="complete"){
          is_show_play_btn=false;
        }
        if(item.a_is_success=="1"){
          is_show_play_btn=false;
        }
      }
      if(item["a_par_id"]=="video_list"){
        is_show_play_btn=false;
      }

      let indivisual_plan=false;
      if(item.a_stu_mcomp_seq==user.comp_seq&&item.a_stu_mcomp_user_seq==user.user_seq){
        indivisual_plan=true;
      }

      return (
        <React.Fragment key={idx} >
          <tr className={Style.todo_row}
            style={todo_row_style}
            onClick={() => {
              onClickTodoRow({
                row_num: idx,
                todo_info: item,
              });
            }}>
            <td style={{textAlign:"left"}}>
              {indivisual_plan&&
                <span style={{color:"#0051d1",fontWeight:"600"}} >(개인)</span>
              }
              [{sort_str}]
              {strFunc.cut_str(item.a_title,8,"..")}
            </td>
            <td>
              <span style={{ color: todo_color.per }}>{state_str}</span>
            </td>
            <td>
              {is_show_play_btn&&
                <button className="btn btn-dark" onClick={()=>{
                  onClickGoDetailPage({
                    row_num: idx,
                    todo_info: item,
                    is_go_start:true
                  });
                }}>시작</button>
              }
            </td>
            <td>
              <button className="btn btn-dark" onClick={()=>{
                onClickGoDetailPage({
                  row_num: idx,
                  todo_info: item,
                });
              }}>상세</button>
            </td>
          </tr>
          {(open_detail_todo_seq == todo_pri_val||is_all_show_detail) &&
            <tr style={{ borderTop: 0 }}>
              <td colSpan={99}>
                <table className={Style.detail_table}>
                  <colgroup>
                    <col width={80}></col>
                    <col width={"*"}></col>
                  </colgroup>
                  <tbody>
                    {item.a_success_sort == "time" ?
                      <>
                        <tr>
                          <th>목표시간</th>
                          <td>
                            {success_time_json.h}:{success_time_json.i}:{success_time_json.s} <span className="ml-2" style={{ color: "#ccc" }}>(시:분:초)</span>
                          </td>
                        </tr>
                        <tr>
                          <th>진행시간</th>
                          <td>
                            {time_sec_json.h}:{time_sec_json.i}:{time_sec_json.s}
                          </td>
                        </tr>
                        <tr>
                          <th>분량</th>
                          <td>
                            {success_amount_str}
                            <span className="ml-1" style={{ color: todo_color.per }} >{todo_percent}%</span>
                          </td>
                        </tr>
                      </>
                      :
                      <tr>
                        <th>분량</th>
                        <td>
                          {amount_num_str}/{success_amount_str}
                          <span className="ml-1" style={{ color: todo_color.per }} >{todo_percent}%</span>
                        </td>
                      </tr>
                    }
                    <tr>
                      <th>제목</th>
                      <td>
                        {item.a_subject?"("+item.a_subject+")":""}
                        {item.a_title}
                      </td>
                    </tr>
                    <tr>
                      <th>내용</th>
                      <td>
                        {
                          item.a_content && item.a_content.split('\n').map((line: string, idx: number) => {
                            return (<span key={idx}>{line}<br /></span>)
                          })
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-center mt-1">
                  <button className="btn btn-dark" onClick={()=>{
                    onClickGoDetailPage({
                      row_num: idx,
                      todo_info: item,
                    });
                  }}>상세보기</button>
                </div>
              </td>
            </tr>
          }
        </React.Fragment>
      );
    });

    if (strFunc.is_empty(todo_tags)) {
      todo_tags = (
        <tr>
          <td colSpan={99}>
            <div style={{ lineHeight: "40px", textAlign: "center" }}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }

    return todo_tags;
  };

  return (
    <div className={Style.bottom_wrap}>
      <div className={Style.select_date_div}>
        {myProps.select_date}
        <button className="btn-s2 btn-gray ml-1" onClick={()=>{set_is_all_show_detail(!is_all_show_detail);}}>상세</button>
        <span className={Style.todo_write_btn} >
          {/* <button className="btn-s2 btn-text-under" onClick={goTodoWritePage}>플랜추가</button> */}
        </span>
      </div>
      <table className={Style.todo_table}>
        <colgroup>
          <col width={"*"}></col>
          <col width={50}></col>
          <col width={50}></col>
          <col width={50}></col>
        </colgroup>
        <tbody>
          {get_todo_tags()}
        </tbody>
      </table>
    </div>
  );
}
export default BottomTodoArea;