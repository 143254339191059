import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import Style from "./css/style.module.css";
import { useState, useRef, useEffect } from 'react';
import JoinInputComponent from "./module/input_module";
import stepData from "./data/setp_data";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import MobileScript from "@/lib/mobile/web_script";
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';
import strFunc from "@/lib/lyg/string";

function JoinMain(){
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let init_default_pre_json={};
  if(localStorage.tmp_comp_user_info){
    let tmp_comp_user_info=JSON.parse(localStorage.tmp_comp_user_info);
    init_default_pre_json={
      "a_user_id":localStorage.tmp_comp_id+"_"+tmp_comp_user_info.a_user_id,
      "a_user_name":tmp_comp_user_info.a_user_name,
      "a_user_phone":tmp_comp_user_info.a_user_phone,
      "a_user_grade":tmp_comp_user_info.a_user_grade,
      "pre_comp_user_seq":tmp_comp_user_info.a_seq,
      "pre_comp_id":localStorage.tmp_comp_id,
    };
  }
  const [rowData,setRowData]=useState<any>({
    "a_seq":"",
    "a_user_id":"",
    "a_user_pw":"",
    "a_user_name":"",
    "a_user_grade":"student",
    "a_user_phone":"",

    "pre_comp_user_seq":"",
    "pre_comp_id":"",
    ...init_default_pre_json
  });
  const [joinStep,setJoinStep]=useState(0);//a_user_name,a_user_id,a_user_pw
  useEffect(() => {
    
    check_user_id_by_ajax(rowData.a_user_id);
  }, []);

  const goJoin=()=>{
    if(rowData.a_user_name==""){
      alert("이름 입력이 필요합니다.");
      setJoinStep(0);
      return false;
    }else if(rowData.a_user_id==""){
      alert("아이디 입력이 필요합니다.");
      setJoinStep(0);
      return false;
    }else if(rowData.a_user_pw==""){
      alert("암호 입력이 필요합니다.");
      setJoinStep(0);
      return false;
    }

    let join_form_data={
      "row_data":rowData,
      "pre_comp_user_seq":rowData.pre_comp_user_seq,
      "pre_comp_id":rowData.pre_comp_id,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/basic/user/join',join_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        MobileScript.set_login_token(response.data.data["token_id"]);
        //로그인처리, 로딩 페이지 이동, 메인 페이지 이동
        localStorage.setItem('intoyou_token', response.data.data["token_id"]);
        var client_info=response.data.data["client_info"];
        dispatch(setUser(client_info));
        localStorage.sort_data_arr=JSON.stringify(client_info.sort_data);
        navigate("/mobile/intro");
      }else{
        alert(response.data["msg"]);
        setJoinStep(0);
      }
    });

  };

  const check_user_id_by_ajax=(user_id: string)=>{
    if (strFunc.is_empty(user_id)) {
      return false;
    }
    let check_data_form_json = {
      "s_id": user_id,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', check_data_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          let tmp_is_able_id=true;
          if (response.data["data"]["info_arr"].length > 0) {
            tmp_is_able_id=false;
          }
          if(tmp_is_able_id==false){
            setRowData({
              ...rowData,
              a_user_id:"",
            });
          }
        } else {
          //alert(response.data["msg"]);
        }
      });
  };
  
  const inputPageTags=stepData.map((item:any,idx:number)=>{
    let is_pw_input=false;
    if(item.input_name=="a_user_pw"){
      is_pw_input=true;
    }
    return (
      <div key={idx}>
        {
          joinStep==idx&&
          <JoinInputComponent
            title={item.title}
            sub_title={item.sub_title}
            input_placeholder={item.input_placeholder}
            btn_title={item.btn_title}
            input_name={item.input_name}
            input_value={rowData[item.input_name]}
            is_pw_input={is_pw_input}
            onChangeInput={(name:string,value:string)=>{
              setRowData({
                ...rowData,
                ...{[name]:value}
              });
            }}
            onClickButton={()=>{
              if(stepData.length==(joinStep+1)){
                goJoin();
              }else{
                setJoinStep(joinStep+1);
              }
            }}
          ></JoinInputComponent>
        }
      </div>
    );
  });

  return (
    <MobileLayout has_header={false} has_footer={false} is_home_body={true} is_check_login={false}>
      <div className={Style.join_wrap}>
        <div className={Style.join_con}>
          {inputPageTags}
        </div>
        <div>
          <button className="btn btn-gray" 
            style={{
              position: "absolute",
              top: 20,
              right: 10
            }}
            onClick={()=>{navigate("/mobile/login");}}>뒤로</button>
        </div>
      </div>
    </MobileLayout>
  );
}

export default JoinMain;