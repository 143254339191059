import ItemEvalList from "./eval_list";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import EvalFunc from "@/pcomponents/common/content/eval/func/eval_func";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";

function EvalArea(props: any) {
  let myProps = {
    Style: {},
    user: {},
    info: {},
    user_row_num: 0,
    form_list: [],
    s_form_seq:"",
    is_update:false,
    set_eval_data: (inData: any) => { },
    go_write_eval: (inData: any) => { },
    go_delete:()=>{},
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;

  let user = myProps.user;
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
  }

  let default_eval_info = {
    "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
    "a_seq": "",
    "a_form_seq": myProps.s_form_seq,
    "a_target_id": "study_title",
    "a_target_seq": "",
    "a_target_name": "",
    "a_target_detail": "",
    "a_stu_seq": info.a_seq,
    "a_stu_name": info.a_user_name,
    "a_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "a_subject_name": "",
    "a_title": "",
    "a_average_score": 0,
    "a_item_amout": 0,
    "a_comment": "",
    "a_writer_seq": iam_mcomp_user_seq,
    "a_writer_name": user.user_name,
    "form_detail_arr": [],
    "detail_arr": [],
  };
  let default_detail_row_data = {
    a_ymd: DateFunc.get_date_format(new Date(), "Ymd"),
    a_seq: "",
    a_detail_seq: "",
    a_title: "",
    a_score: 0,
    a_max_score: 100,
    a_score_letter: "",
    a_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    a_order_num: "",
  };
  let eval_info = {
    ...default_eval_info
  };
  if (info.eval_arr && info.eval_arr.length > 0) {
    eval_info = {
      ...default_eval_info,
      ...info.eval_arr[0],
    };
  }

  const get_row_data_by_form_arr_data = (inData: any) => {
    let opt_obj: any = {
      row_data: {},
      form_list: [],
      ...inData,
    };

    let change_row_data = opt_obj.row_data;
    let form_info = null;
    for (let i = 0; i < opt_obj.form_list.length; i++) {
      if (opt_obj.form_list[i].a_seq == opt_obj.row_data.a_form_seq) {
        form_info = opt_obj.form_list[i];
      }
    }
    let detail_arr = [];
    if (form_info != null) {
      change_row_data["a_title"] = form_info["a_title"];
      change_row_data["a_subject_name"] = form_info["a_subject"];
      change_row_data["a_target_id"] = form_info["a_target_id"];
      change_row_data["a_target_seq"] = form_info["a_target_seq"];
      change_row_data["a_target_name"] = form_info["a_target_name"];
      change_row_data["a_target_detail"] = form_info["a_target_detail"];
    }
    if (form_info != null && !strFunc.is_empty(form_info.detail_arr)) {
      for (let i = 0; i < form_info.detail_arr.length; i++) {
        let form_d_info = form_info.detail_arr[i];
        let detail_idx_num = i + 1;
        detail_arr.push({
          ...default_detail_row_data,
          "a_title": form_d_info["a_title"],
          "a_detail_seq": detail_idx_num,
          "a_order_num": detail_idx_num,
          "a_max_score": form_d_info["a_max_score"],
        });
      }
    }
    change_row_data.detail_arr = detail_arr;
    if (form_info != null) {
      change_row_data.form_detail_arr = form_info.detail_arr;
    }

    return change_row_data;
  };
  if (strFunc.is_empty(eval_info.detail_arr)) {
    eval_info = get_row_data_by_form_arr_data({
      row_data: { ...eval_info },
      form_list: myProps.form_list,
    });
  }

  const handleInputChange = (inData: any) => {
    let opt_obj = {
      key: "",
      value: "",
      ...inData
    };
    let value = opt_obj.value;
    const key = opt_obj.key;
    let change_row_data: any = { ...eval_info };
    change_row_data[key] = value;

    if (key == "a_form_seq") {
      change_row_data = get_row_data_by_form_arr_data({
        row_data: change_row_data,
        form_list: myProps.form_list,
      });
    }

    myProps.set_eval_data({
      "eval_info": change_row_data,
      "user_row_num": myProps.user_row_num
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_row_data: any = { ...eval_info };
    change_row_data[key] = date_str;
    myProps.set_eval_data({
      "eval_info": change_row_data,
      "user_row_num": myProps.user_row_num
    });
  };

  const onChangeDetail = (inData: any) => {
    let opt_obj = {
      "row_num": 0,
      "detail_info": {},
      ...inData
    };
    let change_row_data: any = { ...eval_info };
    change_row_data.detail_arr[opt_obj["row_num"]] = opt_obj["detail_info"];
    myProps.set_eval_data({
      "eval_info": change_row_data,
      "user_row_num": myProps.user_row_num
    });
  };

  const get_detail_total_data = () => {
    let total_data: any = {
      "count": 0,
      "average_score": 0,
    };

    if (eval_info.detail_arr && eval_info.detail_arr.length > 0) {
      let total = 0;
      let sum_per_num = 0;
      let detail_arr_len = eval_info.detail_arr.length;
      for (let i = 0; i < detail_arr_len; i++) {
        let detail_info = eval_info.detail_arr[i];
        let a_score = parseInt(strFunc.uncomma(detail_info["a_score"]));
        let a_max_score = parseInt(strFunc.uncomma(detail_info["a_max_score"]));
        let row_per_num: any = 0;
        if (a_score != 0 && a_max_score != 0) {
          row_per_num = (a_score / a_max_score) * 100;
        }
        sum_per_num += row_per_num;
        total++;
      }
      if (total != 0 && sum_per_num != 0) {
        total_data["average_score"] = (sum_per_num / total);
        total_data["average_score"] = parseInt(total_data["average_score"]);
        total_data["count"] = total;
      }
    }

    return total_data;
  };

  const go_write = () => {
    let total_data=EvalFunc.get_detail_total_data_by_detail_arr(eval_info.detail_arr);
    eval_info["a_average_score"] = total_data["average_score"];
    eval_info["a_item_amout"] = total_data["count"];
    myProps.go_write_eval({
      info: eval_info
    });
  };

  const get_eval_tag_list = () => {
    let eval_tag_list: any = "";
    if (eval_info.detail_arr && eval_info.detail_arr.length > 0) {
      eval_tag_list = eval_info.detail_arr.map((item: any, idx: number) => {
        let form_detail_info = {};
        if (eval_info.form_detail_arr && eval_info.form_detail_arr.length > 0) {
          form_detail_info = eval_info.form_detail_arr[idx];
        }
        return (
          <ItemEvalList
            key={idx}
            idx={idx}
            detail_info={item}
            form_d_info={form_detail_info}
            is_update={myProps.is_update}
            onChangeDetail={onChangeDetail}
            Style={Style}
          ></ItemEvalList>
        );
      });
    }

    return eval_tag_list;
  };

  return (
    <div className={Style.eval_wrap}>
      <div className={Style.eval_con}>
        <table className={Style.eval_write_table}>
          <colgroup>
            <col width={80}></col>
            <col width={"*"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>
                과목
              </th>
              <td>
                {eval_info.a_subject_name}
              </td>
            </tr>
            <tr>
              <th>
                평가틀
              </th>
              <td>
                <select value={eval_info.a_form_seq} style={{ border: "1px solid #ddd", width: "98%" }}
                  onChange={(e: any) => {
                    handleInputChange({
                      key: "a_form_seq",
                      value: e.target.value,
                    });
                  }}>
                  <option value="">선택없음</option>
                  {(myProps.form_list && myProps.form_list.length > 0) &&
                    myProps.form_list.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.a_seq}>{item.a_title}</option>
                      );
                    })
                  }
                </select>
              </td>
            </tr>
            <tr>
              <th>
                수업진도
              </th>
              <td>
                {eval_info.a_target_name}
                {eval_info.a_target_detail ? " " + eval_info.a_target_detail : ""}
              </td>
            </tr>
            <tr>
              <th>
                검사일
              </th>
              <td>
                <div style={{ display: "inline-block", width: 120 }}>
                  <DatePicker
                    selected={eval_info.a_date != "" ? new Date(eval_info.a_date) : null}
                    onChange={(date: Date) => {
                      onChangeDatePicker("a_date", date);
                    }}
                    locale={ko}
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputWrite />}
                    renderCustomHeader={customHeaderFunc}
                  />
                </div>
              </td>
            </tr>
            {get_eval_tag_list()}
            <tr>
              <th>코멘트</th>
              <td>
                <TextAreaComponent
                  name={"a_comment"}
                  value={eval_info.a_comment}
                  class_name={Style.card_box_textarea}
                  style={{width:"98%",height:45}}
                  height={40}
                  auto_line_one_height={21}
                  onChange={(e:any)=>{
                    handleInputChange({
                      key:"a_comment",
                      value:e.target.value,
                    });
                  }}
                ></TextAreaComponent>
              </td>
            </tr>
          </tbody>
        </table>
        <div className={Style.card_box_btn_div}>
          <button className="btn btn-dark" onClick={()=>{go_write();}}>저장</button>
          {myProps.is_update&&
            <button className="btn btn-red ml-2" onClick={()=>{myProps.go_delete();}}>제거</button>
          }
        </div>
      </div>
    </div>
  );
};
export default EvalArea;