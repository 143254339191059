import { useNavigate } from 'react-router-dom';

function VideoTabArea(){
  const navigate = useNavigate();
  const movePage=(url:string)=>{
    navigate(url);
  };

  let sub_menu_arr=[
    {"key":"my_list","url":"/mobile/plan/video/my_list","name":"내강의"},
    {"key":"corse_list","url":"/mobile/plan/video/corse_list","name":"모든과정"}
  ];

  const get_sub_menu_tags=()=>{
    let sub_menu_tags=sub_menu_arr.map((item:any,idx:number)=>{
      let snb_class_name="stu_snb_item";
      let compare_url=window.location.pathname;
      if(compare_url=="/mobile/plan/video/my_list_view"){
        compare_url="/mobile/plan/video/my_list";
      }
      if(item.url==compare_url){
        snb_class_name+=" active";
      }
      return (
        <div key={idx} className={snb_class_name} onClick={()=>{movePage(item.url);}} >
        {item.name}
      </div>
      );
    });
    return sub_menu_tags;
  };

  return (
    <div className="stu_snb_area_wrap">
      <div className="stu_snb_area">
        {get_sub_menu_tags()}
      </div>
    </div>
  );
}
export default VideoTabArea;