import menu_data_arr from "./data/menu_data_arr";
import { useNavigate } from 'react-router-dom';

function MenuBoxArrArea(props:any){
   let myProps={
    Style:{},
    ...props
  };
  let Style=myProps.Style;
  const navigate = useNavigate();

  const on_click=(inData:any)=>{
    let opt_obj={
      "key":"",
      "title":"",
      "icon":"",
      "is_url":"",
      "url":"",
      ...inData
    };

    if(opt_obj.is_url=="1"){
      let send_state:any={};
      if(opt_obj["key"]=="plantalk"){
        send_state["has_footer"]=false;
      }
      navigate(opt_obj.url,{state:send_state});
      return false;
    }

  };

  const get_menu_tags=()=>{
    let  menu_tags=menu_data_arr.map((item:any,idx:number)=>{
      return (
        <div className={Style.menu_item} key={idx} onClick={()=>{on_click(item);}}>
          <img src={item.icon} />
          <div>
            {item.title}
          </div>
        </div>
      );
    });
    return menu_tags;
  };

  return (
    <div className={Style.con_wrap}>
      <div className={Style.menu_arr_wrap}>
        {get_menu_tags()}
      </div>
    </div>
  );
};
export default MenuBoxArrArea;