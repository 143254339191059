import strFunc from "@/lib/lyg/string";

function TopInfoArea(props: any) {
  let myProps = {
    row_num: 0,
    info: null,
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    select_stu_grade_arr: [],
    prescribe_arr: [],
    is_show_write: false,
    set_is_show_write: (inData: any) => { },
    is_view_mode: true,
    set_is_view_mode: (inData: any) => { },
    Style: {},
    ...props
  };

  let Style = myProps.Style;
  let info = myProps.info;

  let is_row_check = false;
  if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) != -1) {
    is_row_check = true;
  }

  const on_check_box_change = (e: any) => {
    if (e.target.checked) {
      myProps.check_row_val_arr.push(info["a_seq"]);
      myProps.set_check_row_val_arr(myProps.check_row_val_arr);
    } else {
      let new_check_row_val_arr = [];
      for (let i = 0; i < myProps.check_row_val_arr.length; i++) {
        if (myProps.check_row_val_arr[i] == info["a_seq"]) {

        } else {
          new_check_row_val_arr.push(myProps.check_row_val_arr[i]);
        }
      }
      myProps.set_check_row_val_arr(new_check_row_val_arr);
    }
  };

  let stu_grade_str = "";
  for (let i = 0; i < myProps.select_stu_grade_arr.length; i++) {
    if (info["a_stu_grade"] == myProps.select_stu_grade_arr[i]["value"]) {
      stu_grade_str = myProps.select_stu_grade_arr[i]["text"];
    }
  }
  return (
    <label>
      <input type="checkbox" checked={is_row_check} onChange={on_check_box_change} />
      <span className="ml-1">
        {myProps.info["a_user_name"]}
      </span>
      <span className="ml-1">
        {stu_grade_str}
      </span>
      <span className="ml-1">
        {strFunc.cut_str(myProps.info["a_school_name"], 8, "..")}
      </span>
      {/* {myProps.is_show_write == false &&
        <button className={myProps.is_view_mode ? "btn-s btn-yellowish2 ml-1" : "btn-s btn-gray ml-1"}
          onClick={() => { myProps.set_is_view_mode(!myProps.is_view_mode); }}>
          수정모드
        </button>
      } */}
      {myProps.prescribe_arr.length > 0 &&
        <button className="btn-s btn-gray ml-1" onClick={() => { myProps.set_is_show_write(!myProps.is_show_write); }} >
          {myProps.is_show_write ? "추가닫기" : "추가"}
        </button>
      }
    </label>
  );
};
export default TopInfoArea;