import { text } from "stream/consumers";

function PayButtonArea(props:any){
   let myProps={
    on_click_pay:()=>{},
    ...props
  };
  let pay_btn_style:any={
    "background":"#2c8ce1",
    "color":"#fff",
    textAlign:"center",
    "borderRadius":"5px",
    lineHeight:"50px",
    fontSize:"18px",
    "cursor":"pointer",
  };
  return (
  <div style={pay_btn_style} onClick={myProps.on_click_pay}>
    납부하기
  </div>
  );
};
export default PayButtonArea;