//import popupFunc from "@/lib/lyg/popup";
//popupFunc.openPopup({'url':'target_popup.html','width':1024,'height':420,'pop_name':'name','auto_size':false});
var lygPopup=function(){
    this.init=function(){

    };

    this.isBrowserCheck=function(){
        var agt = navigator.userAgent.toLowerCase();
        if (agt.indexOf("chrome") != -1) return 'chrome';
        if (agt.indexOf("opera") != -1) return 'opera';
        if (agt.indexOf("staroffice") != -1) return 'staroffice';
        if (agt.indexOf("webtv") != -1) return 'webtv';
        if (agt.indexOf("beonex") != -1) return 'beonex';
        if (agt.indexOf("chimera") != -1) return 'chimera';
        if (agt.indexOf("netpositive") != -1) return 'netpositive';
        if (agt.indexOf("phoenix") != -1) return 'phoenix';
        if (agt.indexOf("firefox") != -1) return 'firefox';
        if (agt.indexOf("safari") != -1) return 'safari';
        if (agt.indexOf("skipstone") != -1) return 'skipstone';
        if (agt.indexOf("netscape") != -1) return 'netscape';
        if (agt.indexOf("msie") != -1) { // 익스플로러 일 경우
            var rv = -1;
            if (navigator.appName == 'Microsoft Internet Explorer') {
                var ua = navigator.userAgent;
                var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
            }
            return 'msie'+rv;
        }
        if (agt.indexOf("mozilla/5.0") != -1) return 'mozilla';
    };

    this.openPopup=function(in_opt_json){
        var opt_json={
            'url':'',
            'width':'',
            'height':'',
            'pop_name':'popup',
            'auto_size':false
        };
        for(var key in in_opt_json){
            opt_json[key]=in_opt_json[key];
        }
        var url=opt_json['url'];
        var width=opt_json['width'];
        var height=opt_json['height'];
        var pop_name=opt_json['pop_name'];
        var auto_size=opt_json['auto_size'];
        var _width = width;
        var _Height = height;
        if(_width =="" ) _width = 640;
        if(_Height =="" ) _Height = 640;

        var win_w=(window.innerWidth || document.documentElement.clientWidth);
        var win_h=(window.innerHeight || document.documentElement.clientHeight);
        if(auto_size){
            if(auto_size){
                if(_width>win_w){
                    _width=win_w;
                }
                if(_Height>win_h){
                    _Height=win_h;
                }
            }
        }

        var my_win=window.open(url,pop_name, 'top=' + (win_h/2 - _Height/2)+', left=' + (win_w/2 - _width/2)+', width=' + _width +', height=' + _Height + ', directories=yes, scrollbars=1, status=no, resizable=yes');
        my_win.focus();
        return my_win;
    };

    this.init();
};
var popupFunc=new lygPopup();

export default popupFunc;