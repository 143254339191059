import strFunc from "@/lib/lyg/string";
import GameScoreFunc from "@/pages/mobile/playground/math/common/func/score_func";

function ButtonArea(props: any) {
  let myProps = {
    Style: {},
    game_data_ref: null,
    set_refresh_cnt: (inData: any) => { },
    set_game_data: (inData: any) => { },
    sound_ref: {},
    remove_char_bar_first_one: () => { },
    ...props
  };
  let Style=myProps.Style;
  let game_data_ref=myProps.game_data_ref;
  let sound_ref=myProps.sound_ref;

  let button_data_arr=[
    {name:"1",value:"1"},
    {name:"2",value:"2"},
    {name:"3",value:"3"},
    {name:"4",value:"4"},
    {name:"5",value:"5"},
    {name:"6",value:"6"},
    {name:"7",value:"7"},
    {name:"8",value:"8"},
    {name:"9",value:"9"},
    {name:"지우기",value:"erase",style:{fontSize:"25px"}},
    {name:"0",value:"0"},
    {name:"확인",value:"ok"},
    // {name:".",value:"."},
  ];

  //currentTime
  const go_play_sound=(sound_sort:any)=>{
    if(sound_ref.current[sound_sort]){
      if(sound_ref.current[sound_sort].currentTime!=0){
        sound_ref.current[sound_sort].currentTime=0;
      }
      sound_ref.current[sound_sort].play();
    }
  };

  const on_ok_click=()=>{
    let now_matter=null;
    let matter_now_idx=game_data_ref.current.matter_now_idx;
    if(game_data_ref.current.matter_arr[matter_now_idx]){
      now_matter=game_data_ref.current.matter_arr[matter_now_idx];
    }
    if(now_matter.input_answer==""){
      //답입력 없으면 멈춤
      go_play_sound("error");
    }else{
      myProps.remove_char_bar_first_one();
      game_data_ref.current.matter_now_idx=parseInt(strFunc.uncomma(game_data_ref.current.matter_now_idx))+1;
      if(now_matter!=null){
        //다음문제로 넘기기, 맞았는지 확인
        if(now_matter.input_answer==now_matter.answer){
          game_data_ref.current.matter_correct_cnt=game_data_ref.current.matter_correct_cnt+1;
          go_play_sound("success");
        }else{
          go_play_sound("fail");
        }
        game_data_ref.current.matter_arr[matter_now_idx]=now_matter;
      }
      if(game_data_ref.current.matter_now_idx>=game_data_ref.current.matter_total){
        //게임종료
        game_data_ref.current.state="result";
        game_data_ref.current.score=GameScoreFunc.get_score_by_game_data({
          game_data:game_data_ref.current
        });
        myProps.set_game_data({...game_data_ref.current});
      }
    }
  };

  const button_click=(value:string)=>{
    let now_matter=null;
    let matter_now_idx=game_data_ref.current.matter_now_idx;
    if(game_data_ref.current.matter_arr[matter_now_idx]){
      now_matter=game_data_ref.current.matter_arr[matter_now_idx];
    }
    if(value=="erase"){
      //숫자처리
      if(now_matter!=null){
        now_matter=game_data_ref.current.matter_arr[matter_now_idx];
        //숫자입력
        if(!strFunc.is_empty(now_matter.input_answer)){
          now_matter.input_answer=now_matter.input_answer.slice(0,-1);
        }
        game_data_ref.current.matter_arr[matter_now_idx]=now_matter;
      }
      go_play_sound("error");
    }else if(value=="ok"){
      on_ok_click();
    }else{
      //숫자처리
      if(now_matter!=null){
        now_matter=game_data_ref.current.matter_arr[matter_now_idx];
        //숫자입력
        now_matter.input_answer=now_matter.input_answer+""+value;
        game_data_ref.current.matter_arr[matter_now_idx]=now_matter;
      }
    }
    myProps.set_refresh_cnt(value);
  };

  const get_tr_tags=()=>{
    let tr_data_arr=[];
    let button_data_len=button_data_arr.length;
    for(let i=0;i<button_data_len;i+=3){
      let tr_buttons=[];
      tr_buttons.push(button_data_arr[i]);
      if(button_data_len>i+1){
        tr_buttons.push(button_data_arr[i+1]);
      }
      if(button_data_len>i+2){
        tr_buttons.push(button_data_arr[i+2]);
      }
      tr_data_arr.push(tr_buttons);
    }
    let tr_tags=tr_data_arr.map((tr_data_arr:any, tr_idx:number)=>{
      return (
        <tr key={tr_idx}>
          {tr_data_arr.map((button_data:any, button_idx:number)=>{
            let button_style:any={};
            if(button_data.style){
              button_style=button_data.style;
            }
            return (
              <td key={button_idx}>
                <button
                  className={Style.button_area_button}
                  style={button_style}
                  onClick={()=>{button_click(button_data.value)}}
                >{button_data.name}</button>
              </td>
            );
          })}
        </tr>
      );
    });

    return tr_tags;
  };

  return (
    <div className={Style.button_area_wrap}>
      <table>
        <tbody>
          {get_tr_tags()}
        </tbody>
      </table>
    </div>
  );
};
export default ButtonArea;