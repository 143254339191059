import { useNavigate } from 'react-router-dom';
import { useState,useRef,useEffect } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useDispatch,useSelector } from 'react-redux';
import { setUser,removeUser } from '@/store/user/user';
import MobileScript from "@/lib/mobile/web_script";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import LogoutByAjax from "./func/logout";

declare global {
  interface Window {
    get_login_token_callback(value:string):void;
  }
}

function CheckLoginComponent(props:any){
  let myProps = {
    isGoToMainAtLogin: false,
    "onGoLoginPage": () => { },
    ...props
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state:any) => state.user);

  useEffect(()=>{
    
    checkLogin();
    window.get_login_token_callback=(login_token:string)=>{
      localStorage.intoyou_token=login_token;
      
      checkLoginByToken();
    };
  },[]);

  const checkLogin=()=>{
    if(strFunc.is_empty(localStorage.intoyou_token)){
      localStorage.intoyou_token=MobileScript.get_login_token();
      
      if(MobileScript.checkMobileApp()=="ios"){
        return false;
      }
    }
    checkLoginByToken();
  };
  const checkLoginByToken=()=>{
    //키가있으면 로그인체크
    if(!strFunc.is_empty(localStorage.intoyou_token)){
      let check_form_json:any = {
        token_id: localStorage.intoyou_token,
      };
      if(localStorage.login_token_create_date==DateFunc.get_date_format(new Date(),"Y-m-d h")){
        check_form_json["is_add_client_info"]="";
      }else{
        check_form_json["is_add_client_info"]="1";
      }
      if(strFunc.is_empty(user.user_seq)){
        check_form_json["is_add_client_info"]="1";
      }
      my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/check_login',check_form_json,get_axios_data())
      .then((response) => {
        if(response.data["result"]=="true"){
          localStorage.intoyou_token = response.data.data["token_id"];
          
          if(response.data.data["client_info"]){
            localStorage.login_token_create_date=DateFunc.get_date_format(new Date(),"Y-m-d h");
            var client_info=response.data.data["client_info"];
            if(client_info["comp_sort"]!="main_user"){
              goLogOut();
            }else{
              dispatch(setUser(client_info));
              localStorage.sort_data_arr=JSON.stringify(client_info.sort_data);
              goToMainPage(client_info.comp_sort,client_info);
            }
          }else{
            goToMainPage(user.comp_sort,user);
          }
        }else{
          goLoginPageByUrl();
        }
      })
      .catch(function (error) {
        if(error.code=="ERR_NETWORK"){
          if(confirm("인터넷 연결 오류. 다시 시도 하시겠습니까?")){
            checkLogin();
          }else{
            MobileScript.finishApp();
            goLoginPageByUrl();
          }
        }
      });
    }else{
      goLoginPageByUrl();
    }
  }
  const goToMainPage=(comp_sort:string,client_info:any)=>{
    if(myProps.isGoToMainAtLogin){
      navigate("/mobile");
    }
  };
  const goLoginPageByUrl=()=>{
    myProps.onGoLoginPage();
    if(location.pathname!="/mobile/login"){
      navigate("/mobile/login");
    }
  };

  const goLogOut=()=>{
    LogoutByAjax({
      my_axios:my_axios,
      dispatch:dispatch,
      removeUser:removeUser,
      navigate:navigate,
    });
  };

  return (
    <></>
  );
}

CheckLoginComponent.defaultProps = {
  "isGoToMainAtLogin":false,
  "onGoLoginPage":()=>{},
}

export default CheckLoginComponent;