import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import strFunc from '@/lib/lyg/string';
import { useSelector } from 'react-redux';
import default_game_data_json from "@/pages/mobile/playground/math/common/data/default_game_data";
import LobbyArea from "./lobby";
import GameArea from "./game";
import MathResultArea from "./result";

function GamePage(props:any) {
  let myProps={
    game_data:{},
    title:"더하기",
    back_url:"/mobile/playground",
    background_color:"#EEEEEE",
    text_color:"#000000",
    font_size:"25px",
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  useEffect(() => {
    if (user.user_seq) {
      get_char_opt_by_ajax();
    }
  }, [user.user_seq]);

  const [char_opt, set_char_opt] = useState({
    a_gold: "0",
    a_select_char_seq: "0",
  });
  const [char_user_info, set_char_user_info] = useState<any>(null);
  let default_game_data = {
    ...default_game_data_json.default_game_data
  };
  const [game_data, set_game_data] = useState<any>({
    ...default_game_data,
    ...myProps.game_data
  });
  const set_refresh_game_data = (new_game_data:any) => {
    set_game_data({
      ...default_game_data,
      ...myProps.game_data,
      attempt_cnt:game_data.attempt_cnt,
      ...new_game_data
    });
  };
  const get_char_opt_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let form_json_data = {
      "s_user_seq": user.user_seq,
      "s_addon_select_char": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_char_opt(response.data["data"]["info_arr"][0]);
            if (response.data["data"]["info_arr"][0]["char_user_info"]) {
              set_char_user_info(response.data["data"]["info_arr"][0]["char_user_info"]);
            }
          }
        } else {

        }
      });
  };
  // console.log(char_opt);
  // console.log(char_user_info);
  
  return (
    <div style={{height:"100vh",background:myProps.background_color,color:myProps.text_color,fontSize:myProps.font_size}}>
      <div style={{position:"fixed",left:0,top:0,width:"100%",height:"100%",overflow:"auto"}}>
        <HeaderSimple title={myProps.title} back_url={myProps.back_url}></HeaderSimple>
        <div className="mt-2">
          {game_data.state === "lobby" &&
            <LobbyArea
              game_data={game_data}
              set_game_data={set_game_data}
              char_opt={char_opt}
              char_user_info={char_user_info}
            ></LobbyArea>
          }
          {game_data.state === "game" &&
            <GameArea 
              game_data={game_data}
              set_game_data={set_game_data}
              set_refresh_game_data={set_refresh_game_data}
              char_user_info={char_user_info}></GameArea>
          }
          {game_data.state === "result" &&
            <MathResultArea 
              game_data={game_data}
              set_game_data={set_game_data}
              set_refresh_game_data={set_refresh_game_data}
              char_user_info={char_user_info}
              char_opt={char_opt}
              set_char_opt={set_char_opt}
              get_char_opt_by_ajax={get_char_opt_by_ajax}></MathResultArea>
          }
        </div>
      </div>
    </div>
  );
};
export default GamePage;