import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import TStuTodoCardList from "@/pages/mobile/manage/tea/tstu_todo/common/card_list";

function ManageTStuTodoListPage() {
  return (
    <MobileLayout has_header={false} >
      <HeaderSimple title={"Todo List"}></HeaderSimple>
      <TStuTodoCardList></TStuTodoCardList>
    </MobileLayout>
  );
}
export default ManageTStuTodoListPage;