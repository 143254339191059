import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import MobileScript from "@/lib/mobile/web_script";

function AppDownPage(){
  const navigate = useNavigate();
  
  let installed_app=MobileScript.checkMobileApp();
  let device_sort=MobileScript.checkMobile();

  return (
    <MobileLayout>
      앱다운로드 하기
      <div className="text-center">
      {
        (device_sort=="android"&&installed_app=="other")&&
        <button className="btn-l btn-dark"
          style={{background:"#34a853"}}
          onClick={()=>{window.open("https://play.google.com/store/apps/details?id=com.planiedu.intoyou_android");}}>
            안드로이드앱 이동
        </button>
      }
      {
        (device_sort=="ios"&&installed_app=="other")&&
        <button className="btn btn-dark" 
          onClick={()=>{ window.open("https://itunes.apple.com/app/id6471819210"); }}>아이폰앱 이동</button>
      }
      </div>
      <div className="mt-5 text-center">
        <button className="btn btn-gray" onClick={()=>{navigate("/mobile/setting");}}>뒤로</button>
      </div>
    </MobileLayout>
  );
}

export default AppDownPage;