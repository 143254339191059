import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import ListArea from "./area/list";

function RankGameScorePopup(props: any) {
  let myProps = {
    score_key: "a_experience",
    score_name: "경험치",
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [pageData, setPageData] = useState({
    listOpt: {
      'now_page': 1,
      'num_per_page': 10,
      'order_id': myProps.score_key+' DESC, a_main_user_seq DESC',
      'order_type': '',
      "is_add_idx_info": "1",
      "s_addon_main_user": "1",
      "s_addon_select_char": "1",
    }
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    let tmp_list_opt = { ...pageData.listOpt };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  return (
    <div style={{ width: "100%", height: "100%", background: "#D3DB90", position: "relative" }} >
      <div className="text-center" style={{height:"40px",lineHeight:"40px",background:"#E4EFE9"}} >
        <span style={{color:"#7A9E1D"}}>{myProps.score_name} 랭킹</span>
        <button className="btn btn-gray" onClick={myProps.closePopup}
          style={{ position: "absolute", right: 5, top: 5, background: "#7A9E1D" }}
        >X</button>
      </div>
      <ListArea 
        infoArr={infoArr} 
        listOpt={pageData.listOpt}
        score_key={myProps.score_key}
        score_name={myProps.score_name} ></ListArea>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
      <div className="text-center mt-2">
        <button className="btn btn-dark" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
    </div>
  );
};
export default RankGameScorePopup;