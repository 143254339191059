import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function CardBox(props:any){
   let myProps={
    Style:{},
    idx:0,
    info:null,
    now_date_time:0,
    select_stu_grade_arr:[],
    select_arr_eval_score:[],
    open_counsel_view_popup:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let content_str=strFunc.cut_str(info.a_content,35,"..");
  if(strFunc.is_empty(content_str)){
    content_str="";
  }
  let stu_info=null;
  let stu_school_str="";
  let stu_grade_str="";
  if(info.stu_info){
    stu_info=info.stu_info;
    stu_school_str=strFunc.cut_str(stu_info.a_school_name,5,"..");
    stu_grade_str=stu_info.a_stu_grade;
    for(let i=0;i<myProps.select_stu_grade_arr.length;i++){
      if(myProps.select_stu_grade_arr[i].value==stu_grade_str){
        stu_grade_str=myProps.select_stu_grade_arr[i].text;
      }
    }
  }
  stu_school_str=stu_school_str.substring(0,2);
  let counsel_img_src="";
  let file_len=0;
  if(info.file_arr&&info.file_arr.length>0){
    file_len=info.file_arr.length;
    for(let i=0;i<file_len;i++){
      let tmp_file=info.file_arr[i];
      if(counsel_img_src==""){
        if(tmp_file["is_image"]=="1"){
          counsel_img_src=tmp_file["thum_img_url"];
          if(tmp_file["a_ext"].toLowerCase()=="png"){
            counsel_img_src=tmp_file["img_url"];
          }
        }
      }
    }
  }

  let tmp_select_date_time =0;
  let state_color = "#eab358";
  let state_str="준비";
  if(info.a_reserve_date){
    tmp_select_date_time = new Date(info.a_reserve_date).getTime();
  }
  if (tmp_select_date_time < myProps.now_date_time) {
    state_color = "#db6c58";
  }
  if (info.a_state == "complete") {
    state_color = "#A2B854";
    state_str="완료";
  }
  
  return (
  <div className={Style.card_box_wrap} onClick={()=>{myProps.open_counsel_view_popup({"select_idx":myProps.idx});}}>
    <div className={Style.card_title_wrap}>
      <div className={Style.card_title_img_wrap}>

      </div>
      <div>
        <div className={Style.card_title}>
          {info.a_stu_name}
          <span className="ml-1" style={{color:"#aaa"}}>
            {stu_school_str} {stu_grade_str}
          </span>
        </div>
        <div className={Style.card_sub_title}>
          {info.a_counseler}
          {!strFunc.is_empty(info.a_kind)&&
            <span> | {info.a_kind}</span>
          }
        </div>
      </div>
      <div className={Style.card_title_right}>
        <div>
          {info.a_counsel_date}
        </div>
        <div>
          <span style={{background:state_color,color:"#fff",padding:"2px 3px"}}>
            상태:
            <span className="ml-1" >
              {state_str}
            </span>
          </span>
          <span className="ml-1">
            첨부: {file_len}
          </span>
        </div>
      </div>
    </div>
    {!strFunc.is_empty(info.a_content)&&
    <div className={Style.card_box_content_wrap} >
      {
        info.a_content && info.a_content.split('\n').map( (line:string,idx:number) => {
          return (<span key={idx}>{line}<br/></span>)
        })
      }
    </div>
    }
    {!strFunc.is_empty(info.a_feedback)&&
    <div className={Style.card_box_content_wrap} >
      <div className={Style.card_box_content_title}>피드백</div>
      {
        info.a_feedback && info.a_feedback.split('\n').map( (line:string,idx:number) => {
          return (<span key={idx}>{line}<br/></span>)
        })
      }
    </div>
    }
    {!strFunc.is_empty(info.a_eval_score)&&
    <div className={Style.card_box_content_wrap} >
      <div className={Style.card_box_content_title}>평가</div>
      <SelectBoxBar
        valueTextArr={myProps.select_arr_eval_score}
        value={info.a_eval_score}
      ></SelectBoxBar>
    </div>
    }

    {!strFunc.is_empty(info.a_reference)&&
    <div className={Style.card_box_content_wrap} >
      <div className={Style.card_box_content_title}>참고내용</div>
      {
        info.a_reference && info.a_reference.split('\n').map( (line:string,idx:number) => {
          return (<span key={idx}>{line}<br/></span>)
        })
      }
    </div>
    }
    {counsel_img_src!=""&&
    <div className={Style.card_box_img_wrap}>
      <img src={counsel_img_src} />
    </div>
    }
  </div>
  );
};
export default CardBox;