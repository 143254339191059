import Style from "./css/style.module.css";
import MatterArea from "@/pages/mobile/playground/math/common/matter";
import strFunc from "@/lib/lyg/string";
import GameScoreFunc from "@/pages/mobile/playground/math/common/func/score_func";
import { useEffect } from 'react';
import SaveResultArea from "./save_result";

function MathResultArea(props: any) {
  let myProps = {
    game_data: {},
    set_game_data: (inData: any) => { },
    set_refresh_game_data: (inData: any) => { },
    char_user_info: {},
    char_opt: {},
    set_char_opt: (inData: any) => { },
    get_char_opt_by_ajax: () => { },
    ...props
  };
  let game_data = myProps.game_data;

  let elapse_msec = strFunc.uncomma(game_data.elapse_msec);
  if (!strFunc.is_empty(elapse_msec)) {
    elapse_msec = Math.floor(elapse_msec / 1000);
  }
  let elapse_time_json = strFunc.secondsToTimeJson(elapse_msec);

  let gold_score=0;
  if(game_data.matter_total==game_data.matter_correct_cnt){
    gold_score=0;
    if(game_data.score>0){
      gold_score=Math.round(game_data.score/4);
    }
  }

  useEffect(() => {
    let pre_score=GameScoreFunc.get_score_by_char_opt({
      game_data: game_data,
      char_opt: myProps.char_opt,
    });
    if(pre_score>game_data.score){
      let score_key = GameScoreFunc.get_score_key_of_char_opt({
        game_data: game_data,
      });
      let change_char_opt={
        ...myProps.char_opt,
      };
      change_char_opt[score_key]=game_data.score;
      myProps.set_char_opt(change_char_opt);
    }
  }, []);

  const restart_game = () => {
    myProps.set_game_data({
      ...myProps.game_data,
      state: "lobby",
    });
  };

  const get_result_matter_tags = () => {
    let result_matter_tags = game_data.matter_arr.map((item: any, idx: number) => {

      let row_is_correct=false;
      if(item.answer==item.input_answer){
        row_is_correct=true;
      }

      let idx_span_style:any={color:"gray"};
      if(row_is_correct==false){
        idx_span_style["color"]="red";
      }

      return (
        <div key={idx}>
          <span style={idx_span_style}>
            <span style={{fontWeight:"500"}}>{idx + 1}</span>
            <span className="ml-1" >번 문제</span>
          </span>
          <div>
            <MatterArea
              matter={item}
              is_show_answer={true}
              is_show_correct={true}
            ></MatterArea>
          </div>
        </div>
      );
    });
    return result_matter_tags;
  };

  return (
    <div style={{ padding: 10 }}>
      <div className={Style.top_wrap}>
        학습결과
      </div>
      <div className="mt-2">
        <table>
          <colgroup>
            <col width="200px" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>점수</th>
              <td>
              {strFunc.comma(game_data.score)}
              </td>
            </tr>
            <tr>
              <th>획득양분</th>
              <td>
                {strFunc.comma(gold_score)}
              </td>
            </tr>
            <tr>
              <th>등수</th>
              <td>
                <SaveResultArea
                  game_data={game_data}
                  gold_score={gold_score}
                  char_user_info={myProps.char_user_info}
                  is_show_rank={true}
                  get_char_opt_by_ajax={myProps.get_char_opt_by_ajax}
                ></SaveResultArea>
              </td>
            </tr>
            <tr>
              <th>정답률</th>
              <td>
                {game_data.matter_total === 0
                  ? "0"
                  : Math.floor((game_data.matter_correct_cnt / game_data.matter_total) * 100)}
                %
              </td>
            </tr>
            <tr>
              <th>정답/문제</th>
              <td>
                {game_data.matter_correct_cnt}/{game_data.matter_total} 
              </td>
            </tr>
            <tr>
              <th>학습시간</th>
              <td>
                {elapse_time_json.i}:{elapse_time_json.s}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="text-center mt-3">
        <button className="btn btn-green"
          style={{ width: "200px", height: "50px", fontSize: "20px" }}
          onClick={() => { restart_game(); }} >처음화면</button>
      </div>
      <div style={{ fontSize: "20px", marginTop: 10,paddingLeft:10 }}>
        {get_result_matter_tags()}
      </div>
      <div className="text-center mt-2">
        <button className="btn btn-green"
          style={{ width: "200px", height: "50px", fontSize: "20px" }}
          onClick={() => { restart_game(); }} >처음화면</button>
      </div>
    </div>
  );
};
export default MathResultArea;