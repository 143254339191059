import ParNoticeListPage from "@/pages/mobile/parent/par_notice/list";
import ParTodoHomeWorkList from "@/pages/mobile/parent/plan/homework_list";
import ParTodoStudyList from "@/pages/mobile/parent/plan/study_list";
import AttendStuListPage from "@/pages/mobile/parent/attend/stu_list";
import ParManageStuPage from "@/pages/mobile/parent/student/manage";
import AttendStuCalendarPage from "@/pages/mobile/parent/attend/calendar";
import TimeTableParStuPage from "@/pages/mobile/parent/student/time_table_week";
import SupplyParOneStuPage from "@/pages/mobile/parent/student/supply/one_stu";
import SupplyParPayInitPage from "@/pages/mobile/parent/student/supply/pay_init";

const parentRouterArr:object[] = [
  {
    path: "/mobile/parent/par_notice/list",
    element: <ParNoticeListPage></ParNoticeListPage>,
  },
  {
    path: "/mobile/parent/plan/homework_list",
    element: <ParTodoHomeWorkList></ParTodoHomeWorkList>,
  },
  {
    path: "/mobile/parent/plan/study_list",
    element: <ParTodoStudyList></ParTodoStudyList>,
  },
  {
    path: "/mobile/parent/attend/stu_list",
    element: <AttendStuListPage></AttendStuListPage>,
  },
  {
    path: "/mobile/parent/stu/manage",
    element: <ParManageStuPage></ParManageStuPage>,
  },
  {
    path: "/mobile/parent/attend/calendar",
    element: <AttendStuCalendarPage></AttendStuCalendarPage>,
  },
  {
    path: "/mobile/parent/stu/time_table_week",
    element: <TimeTableParStuPage></TimeTableParStuPage>,
  },
  {
    path: "/mobile/parent/stu/supply_one_stu",
    element: <SupplyParOneStuPage></SupplyParOneStuPage>,
  },
  {
    path: "/mobile/parent/stu/supply_pay_init",
    element: <SupplyParPayInitPage></SupplyParPayInitPage>,
  },
];

export default parentRouterArr;