import ManageTStuTodoListPage from "@/pages/mobile/manage/tea/tstu_todo/list";
import CounselDailyListPage from "@/pages/mobile/manage/counsel_todo/counsel_daily";

const manageRouterArr:object[] = [
  {
    path: "/mobile/manage/tea/tstu_todo/list",
    element: <ManageTStuTodoListPage></ManageTStuTodoListPage>,
  },
  {
    path: "/mobile/manage/counsel_todo/counsel_daily",
    element: <CounselDailyListPage></CounselDailyListPage>,
  },
];

export default manageRouterArr;