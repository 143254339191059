import DateFunc from '@/lib/lyg/date_func';
import TodoWriteArea from "@/pages/mobile/plan/todo/commponent/write";

function TodoWritePopup(props:any) {
  let myProps={
    closePopup: () => { },
    callback: () => { },
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    isUpdate: false,
    rowData: {},
    is_comp_todo: false,
    is_comp_stu: false,
    comp_stu_info: null,
    todo_sort: "",
    ...props
  };

  return (
    <TodoWriteArea
      select_date={myProps.select_date}
      isUpdate={myProps.isUpdate}
      rowData={myProps.rowData}
      is_comp_todo={myProps.is_comp_todo}
      is_comp_stu={myProps.is_comp_stu}
      comp_stu_info={myProps.comp_stu_info}
      todo_sort={myProps.todo_sort}
      goBackUrl={myProps.closePopup}
      callback={myProps.callback}
      back_btn_text={"닫기"}
    ></TodoWriteArea>
  );
}
export default TodoWritePopup;