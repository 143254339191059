import strFunc from "@/lib/lyg/string";
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";

function NoticeBoxArea(props: any) {
  let myProps = {
    info: {},
    Style: {},
    openViewPopup: (inData:any)=>{},
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;

  return (
    <div className={Style.notice_box} 
      onClick={()=>{
        myProps.openViewPopup({row_data:info});
      }}>
      <div className={Style.notice_box_title}>
        <span style={{color:info.a_color}}>{info.a_title}</span>
      </div>
      <div className={Style.notice_box_content}>
        <MultyLineTextView
          text={info.a_content}
        ></MultyLineTextView>
      </div>
      <div className={Style.notice_box_bottom}>
        <span>{strFunc.cut_str(info.a_mcomp_name, 10, "..")}</span>
        <span>|</span>
        <span>{info.a_date}</span>
      </div>
    </div>
  );
};
export default NoticeBoxArea;