import { useEffect } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import MobileScript from "@/lib/mobile/web_script";
import DateFunc from '@/lib/lyg/date_func';

declare global {
  interface Window {
    get_version_str_callback(value:string):void;
  }
}

function CheckAppVersionComponent(){
  
  useEffect(()=>{
    
    check_recent_version_app();
    window.get_version_str_callback=(present_app_ver:string)=>{
      compare_version_by_server(present_app_ver);
    };
  },[]);

  const check_recent_version_app=()=>{
    //하루에 한번만 체크
    let tmp_now_ymdh=DateFunc.get_date_format(new Date(),"Ymdh");
    if(localStorage.version_check_date_last==tmp_now_ymdh){
      return false;
    }
    let present_app_ver=MobileScript.get_version_str();
    if(MobileScript.checkMobileApp()=="ios"){
      return false;
    }
    compare_version_by_server(present_app_ver);
  };

  const compare_version_by_server=(present_app_ver:string)=>{
    let tmp_now_ymdh=DateFunc.get_date_format(new Date(),"Ymdh");
    let form_json_data={};
    let mobile_device=MobileScript.checkMobileApp();
    if(present_app_ver==""){
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/front/main/get_app_version', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          let app_ver_data=response.data.data;
          if(mobile_device=="android"||mobile_device=="ios"){
            if(MobileScript.compareVersions(app_ver_data[mobile_device],present_app_ver)==1){
              if(confirm("최신앱 버전을 받으러 가시겠습니까?")){
                MobileScript.go_store_for_recent_version();
              }
            }
          }
        } else {
          //alert(response.data["msg"]);
        }
        //하루에 한번만 체크
        localStorage.setItem('version_check_date_last',tmp_now_ymdh);
      });
  }

  return (
    <></>
  );
}
export default CheckAppVersionComponent;