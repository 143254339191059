import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import { useState,useRef,useEffect } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import { useSelector } from 'react-redux';
import ChildDataFunc from "@/pcomponents/common/content/parent/func/child_data";
import TopStuSelect from "@/pages/mobile/main/main/area/par_area/area/top_stu_select";

function ParManageStuPage(){
  
  let user = useSelector((state: any) => state.user);
  let group_child_data=ChildDataFunc.get_child_arr_by_login_user(user);
  let comp_stu_row_arr=group_child_data.comp_stu_row_arr;
  let select_stu_row_idx=group_child_data.select_stu_row_idx;
  let select_comp_stu_row=group_child_data.select_comp_stu_row;
  let child_len=0;
  if(comp_stu_row_arr){
    child_len=comp_stu_row_arr.length;
  }
  const [select_stu_info,set_select_stu_info]=useState<any>({});
  
  useEffect(()=>{
    get_main_data_by_ajax();
  },[user]);

  const get_main_data_by_ajax=()=>{
    get_select_stu_info_by_ajax();
  };
  
  const get_select_stu_info_by_ajax=()=>{
    if(strFunc.is_empty(select_comp_stu_row["comp_api_key"])
      ||strFunc.is_empty(select_comp_stu_row["par_mcomp_user_seq"])
      ||strFunc.is_empty(select_comp_stu_row["user_seq"])){
      return false;
    }
    let from_json_data={
      api_key:select_comp_stu_row["comp_api_key"],
      api_user_seq:select_comp_stu_row["par_mcomp_user_seq"],
      "s_pri_arr":[select_comp_stu_row["user_seq"]],
      "s_addon_profile":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',from_json_data,get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        if(response.data["data"]["info_arr"].length>0){
          let tmp_stu_info=response.data["data"]["info_arr"][0];
          set_select_stu_info(tmp_stu_info);
        }
      }
    });
  };

  return (
  <MobileLayout has_header={false} has_footer={false} container_con_style={{width:"100%"}}>
    <HeaderSimple title={"자녀관리"+"("+child_len+"명)"}></HeaderSimple>
    <div className="noto_sans" style={{position:"relative",marginTop:"50px"}}>
      <TopStuSelect
        user={user}
        comp_stu_row_arr={comp_stu_row_arr}
        select_stu_row_idx={select_stu_row_idx}
        select_comp_stu_row={select_comp_stu_row}
        select_stu_info={select_stu_info}
        arrow_color={"black"}
        title_con_style={{color:"#444"}}
      ></TopStuSelect>
    </div>
  </MobileLayout>
  );
};
export default ParManageStuPage;