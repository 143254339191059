import strFunc from "@/lib/lyg/string";

function ListArea(props:any){
   let myProps={
    infoArr:[],
    listOpt:{
      now_page:1,
      num_per_page:10,
    },
    game_sort: "plus",
    game_name: "더하기",
    ...props
  };
  let now_page=parseInt(strFunc.uncomma(myProps.listOpt.now_page));
  let num_per_page=parseInt(strFunc.uncomma(myProps.listOpt.num_per_page));
  let game_sort=myProps.game_sort;
  const get_char_opt_rank_tags=()=>{
    let char_opt_rank_tags=myProps.infoArr.map((item:any,idx:number)=>{
      
      let user_name=item["a_main_user_name"];
      if(item.user_info){
        user_name=item.user_info.a_user_name;
      }
      let char_name="";
      if(item.char_user_info){
        char_name=item.char_user_info.a_name;
      }
      let row_num=idx+1;
      row_num=row_num+((now_page-1)*num_per_page);
      
      let num_color="green";
      if(row_num<=3){
        num_color="blue";
      }else if(row_num<=10){
        num_color="red";
      }else if(row_num<=20){
        num_color="orange";
      }

      let score_color="orange";
      if(item["a_correct_amount"]==item["a_total_amount"]){
        score_color="blue";
      }

      let elapse_time_sec=parseInt(strFunc.uncomma(item["a_elapse_msec"]));
      if(elapse_time_sec>0){
        elapse_time_sec=elapse_time_sec/1000;
      }
      let elapse_time_json=strFunc.secondsToTimeJson(elapse_time_sec);

      return (
        <tr key={idx}>
          <td className="text-center" style={{color:num_color}}>{row_num}</td>
          <td className="text-center">
            {char_name}
          </td>
          <td className="text-center">
            <span style={{color:score_color}}>
              {item["a_correct_amount"]}/{item["a_total_amount"]}
            </span>
          </td>
          <td className="text-center">
            {elapse_time_json["i"]+":"+elapse_time_json["s"]}
          </td>
          <td className="text-center">
            {item["a_create_date"].substring(0,10)}
          </td>
          <td className="text-center">
            <span style={{color:score_color}}>
              {strFunc.comma(item["a_score"])}
            </span>
          </td>
        </tr>
      );
    });

    if(strFunc.is_empty(char_opt_rank_tags)){
      char_opt_rank_tags=(
        <tr>
          <td colSpan={6}>
            <div className="text-center" style={{lineHeight:"40px",color:"#777",fontSize:"20px"}}>
              내용이 없습니다.
            </div>
          </td>
        </tr>
      );
    }

    return char_opt_rank_tags;
  };
  
  return (
  <div className="list-table-div" style={{padding:5}}>
    <table>
      <colgroup>
        <col width={"2%"}></col>
        <col width={"20%"}></col>
        <col width={"10%"}></col>
        <col width={"10%"}></col>
        <col width={"20%"}></col>
        <col width={"10%"}></col>
      </colgroup>
      <thead>
        <tr>
          <th>No.</th>
          <th>씨앗이</th>
          <th>문제</th>
          <th>시간</th>
          <th>날짜</th>
          <th>점수</th>
        </tr>
      </thead>
      <tbody>
        {get_char_opt_rank_tags()}
      </tbody>
    </table>
  </div>
  );
};
export default ListArea;