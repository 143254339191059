import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import strFunc from '@/lib/lyg/string';

function SearchArea(props: any){
  let myProps = {
    listOpt: {},
    setListOpt: (listOpt: any) => {},
    list: (listOpt: any) => {},
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data = UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq: myProps.select_mcomp_seq,
    default_mcomp_api_key: myProps.select_mcomp_api_key,
    default_mcomp_user_seq: myProps.select_mcomp_user_seq,
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  const [listOpt, setListOpt] = useState(myProps.listOpt);
  const [subjectArr, setsubjectArr] = useState<any>([]);
  let subject_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'study_title', 'key': 'a_subject_sort' });
  useEffect(() => {
    list_corse_subject();
  }, []);

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    setListOpt({
      ...listOpt,
      ...{ [name]: value }
    });
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ [name]: value });
  };

  const goSearch = () => {
    myProps.list({ ...listOpt });
  };

  const list_corse_subject = () => {
    let subject_list_form_data:any = {
      "order_id": "a_order_num, a_title",
      "s_is_use": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    subject_list_form_data["api_key"] = comp_api_key;
    subject_list_form_data["api_user_seq"] = iam_mcomp_user_seq;
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_subject/list', subject_list_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setsubjectArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  return (
    <div>
      <div className="text-center">
        <select className="border" name="s_subject_sort" value={listOpt.s_subject_sort}
          onChange={handleInputChange}
          style={{ width: 70 }} >
          <option value="">과목</option>
          {
            subject_option_arr.map((item: any, idx: number) => {
              return (
                <option value={item.value} key={idx}>{item.text}</option>
              );
            })
          }
        </select>
        <select className="border ml-1" name="s_subject_seq" value={listOpt.s_subject_seq}
          onChange={handleInputChange}
          style={{ width: 70 }}>
          <option value="">구분</option>
          {
            subjectArr.map((item: any, idx: number) => {
              return (
                <option value={item.a_seq} key={idx}>{item.a_title}</option>
              );
            })
          }
        </select>

        <span className="ml-1">
          <label>
            <input type="checkbox" name="s_is_progress" value="1"
              checked={listOpt.s_is_progress == "1"}
              onChange={handleInputChange} />
            <span className="ml-1">진도</span>
          </label>
        </span>
        <span className="ml-1">
          <label>
            <input type="checkbox" name="s_is_mapping" value="1"
              checked={listOpt.s_is_mapping == "1"}
              onChange={handleInputChange} />
            <span className="ml-1">교과맵핑</span>
          </label>
        </span>
        <button className="btn btn-dark ml-1" onClick={() => { goSearch(); }} >검색</button>
      </div>
    </div>
  );
};

export default SearchArea;