import LygFile from '@/lib/lyg/file';

function AudioListArea(props: any) {
  let myProps = {
    file_arr: [],
    default_file_row_data: {},
    Style: {},
    goDelete: (inData: any) => { },
    ...props
  };
  let file_arr = myProps.file_arr;
  let default_file_row_data = myProps.default_file_row_data;
  let Style = myProps.Style;

  const get_audio_div_tags = () => {
    let img_file_arr = [];
    for (let i = 0; i < file_arr.length; i++) {
      let file_info = file_arr[i];
      if (file_info["a_sort1"] == default_file_row_data["a_sort1"]
        && file_info["a_sort2"] == default_file_row_data["a_sort2"]
        && file_info["a_sort3"] == default_file_row_data["a_sort3"]
      ) {
        img_file_arr.push(file_info);
      }
    }
    let img_div_tags = img_file_arr.map((item: any, idx: number) => {
      let file_url = item.a_serverurl + "/" + item.a_path + item.a_savename;
      let file_type = LygFile.get_file_type({ext:item.a_ext,file_type:item.a_type});
      let file_sort = LygFile.get_type_sort({ ext: item.a_ext,file_type:file_type, file_sort: "audio" });
      return (
        <div key={idx} className={Style.file_form_item} title={item.a_oriname}>
          <div>
            {item.a_oriname}
          </div>
          {file_sort == "audio" &&
            <audio controls
            >
              <source src={file_url} type={file_type}></source>
              Your browser does not support the audio element.
            </audio>
          }
          {file_sort == "video" &&
            <video controls
            >
              <source src={file_url} type={file_type}></source>
              Your browser does not support the video tag.
            </video >
          }
          <button className="btn-s btn-red px-2" onClick={() => { myProps.goDelete(item); }}
            style={{ position: "absolute", right: 0, top: 0 }}>x</button>
        </div>
      );
    });

    return img_div_tags;
  };

  return (
    <div>
      {get_audio_div_tags()}
    </div>
  );
};
export default AudioListArea;