import { useState, useRef, useEffect } from 'react';

const ChatSendArea = (props: any) => {
  let myProps = {
    room_info: {},
    go_send_msg:(inData:any)=>{
      //inData={msg:""};
    },
    ...props
  };

  const sendInputRef=useRef(null);

  const go_send_msg=()=>{
    let send_msg="";
    if(sendInputRef.current){
      send_msg=(sendInputRef.current as HTMLInputElement).value;
      (sendInputRef.current as HTMLInputElement).value="";
      (sendInputRef.current as HTMLInputElement).focus();
    }
    if(send_msg==""){
      alert("메세지 입력이 없습니다.");
      return false;
    }
    myProps.go_send_msg({
      "msg":send_msg
    });
  };
  return (
    <div className="chat_view_send_wrap">
      <div className="chat_view_send_file_box">
        <svg xmlns="http://www.w3.org/2000/svg"
          style={{display:"inline-block",marginBottom:8}} 
          width="19" height="23" viewBox="0 0 19 23" fill="none">
          <path d="M0 11.0878H19" stroke="#8F8F8F"/>
          <path d="M9.85185 0.609131L9.85185 22.3728" stroke="#8F8F8F"/>
        </svg>
      </div>
      <div className="chat_view_send_input_box">
        <textarea className="chat_view_send_input" ref={sendInputRef} 
          placeholder="내용을 입력하세요."
          onKeyUp={(e)=>{if (e.key === 'Enter') {
            //go_send_msg();
          }}}
          onFocus={()=>{
            let chat_view_msg_wrap=(document.getElementsByClassName("chat_view_msg_wrap")[0] as HTMLElement);
            chat_view_msg_wrap.scrollTop=chat_view_msg_wrap.scrollHeight;
            setTimeout(()=>{
              chat_view_msg_wrap.scrollTop=chat_view_msg_wrap.scrollHeight;
            },200);
          }}
        ></textarea>
      </div>
      <div className="chat_view_send_btn_box" onClick={()=>{go_send_msg();}}>
        전송
      </div>
    </div>
  );
};
export default ChatSendArea;