import search_ico from "@/img/ico/search.png";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

function SearchArea(props: any) {
  let myProps = {
    list_opt: {

    },
    list: (inData: any) => { },
    infoArr: [],
    select_seq_arr: [],
    set_select_seq_arr: (inData: any) => { },
    go_delete_ajax: (inData: any) => { },
    openWritePopup: (inData: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  let select_subject_option_arr=XcolumnFunc.getSortByTableKey({'table':'study_title','key':'a_subject_sort'});
  const [teacher_arr, set_teacher_arr] = useState([]);

  useEffect(() => {
    get_teacher_by_ajax();
  }, [user]);

  const get_teacher_by_ajax = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let list_form_json_data: any = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    list_form_json_data["api_key"] = comp_api_key;
    list_form_json_data["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const handleChangeInput = (e: any) => {
    const target = e.target;
    let value = e.target.value;
    let name = e.target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    myProps.list({
      "now_page": "1",
      ...{ [name]: value }
    });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.list_opt.s_start_date)) {
      myProps.list_opt.s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.list_opt.s_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_start_date": change_date_str,
      "s_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    let change_s_list_json = {
      "now_page": "1",
      [key]: date_str,
    };
    if (key == "s_start_date") {
      change_s_list_json["s_start_date"] = date_str;
    }
    if (key == "s_end_date") {
      change_s_list_json["s_end_date"] = date_str;
    }

    myProps.list(change_s_list_json);
  };

  const onClickCheckAll = (e: any) => {
    let is_checked = e.target.checked;
    if (is_checked == false) {
      myProps.set_select_seq_arr([]);
    } else {
      let new_select_seq_arr = [];
      for (let i = 0; i < myProps.infoArr.length; i++) {
        let tmp_pri_val = myProps.infoArr[i].a_ymd + "," + myProps.infoArr[i].a_seq;
        new_select_seq_arr.push(tmp_pri_val);
      }
      myProps.set_select_seq_arr(new_select_seq_arr);
    }
  };

  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  let start_date_json = DateFunc.get_date_json(new Date(myProps.list_opt.s_start_date));
  let select_day_str = DateFunc.get_day_str_by_day_num(start_date_json.day);
  let is_today_select = false;
  if (now_date_str == myProps.list_opt.s_start_date) {
    is_today_select = true;
  }
  return (
    <div>
      <div className="search_wrap mt-1" >
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.list_opt.s_start_date != "" ? new Date(myProps.list_opt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.list_opt.s_end_date != "" ? new Date(myProps.list_opt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          let tmp_row_y_m_d = DateFunc.get_date_format(new Date(), "Y-m-d");
          myProps.list({
            "now_page": "1",
            "s_start_date": tmp_row_y_m_d,
            "s_end_date": tmp_row_y_m_d,
          });
        }}>오늘</button>
      </div>
      <div className="mt-1" style={{ position: "relative", textAlign: "center" }}>
        학생
        <input type="text"
          className="ml-1"
          value={myProps.list_opt.s_user_name_like}
          name="s_user_name_like"
          onChange={handleChangeInput} style={{ width: 65, paddingLeft: 5 }}
          autoComplete="off"
          placeholder="이름" />

        <SearchLectureSelect
          name="s_stu_lecture_seq"
          value={myProps.list_opt.s_stu_lecture_seq}
          on_change_input={(inData: any) => {
            let name = inData.name;
            let value = inData.value;
            myProps.list({ now_page: 1, [name]: value });
          }}
          wrap_style={{ width: 80, display: "inline-table", marginLeft: 2 }}
          select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
        ></SearchLectureSelect>

        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = myProps.list_opt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {myProps.list_opt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <button className="btn-s btn-gray px-1" onClick={() => { myProps.list({ now_page: 1 }); }}
          style={{ background: "#dfdfdf" }}>
          <img src={search_ico} style={{ height: 15 }} />
        </button>
      </div>
      <div className="text-center mt-1">
        <span>
          선생님
          <select className="border ml-1" name="s_owner_mcomp_user_seq" value={myProps.list_opt.s_owner_mcomp_user_seq}
            onChange={handleChangeInput} style={{ width: 100 }}>
            <option value="">전체</option>
            {teacher_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item["a_seq"]}>
                  {item["a_user_name"]}
                  {item["a_user_nickname"] &&
                    "(" + item["a_user_nickname"] + ")"
                  }
                </option>
              );
            })}
          </select>
          <button className="btn-s btn-dark ml-1"
            onClick={() => {
              myProps.list({ now_page: 1, s_owner_mcomp_user_seq: iam_mcomp_user_seq });
            }}>나</button>
        </span>
        <span className="ml-2">
          과목
          <select className="border ml-1" name="s_subject" value={myProps.list_opt.s_subject}
            onChange={handleChangeInput} style={{ width: 60 }}>
            <option value="">전체</option>
            {select_subject_option_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item["value"]}>
                  {item["text"]}
                </option>
              );
            })}
          </select>
        </span>
      </div>
      <div className="mt-2" style={{ position: "relative" }}>
        <label>
          <input type="checkbox" onChange={onClickCheckAll} />
          <span className="ml-1">전체</span>
        </label>
        <span style={{ position: "absolute", right: 0, top: 0 }}>
          <button className="btn btn-dark" onClick={() => { myProps.openWritePopup({}); }}>등록</button>
          <button className="btn btn-red ml-1" onClick={() => { myProps.go_delete_ajax({}); }}>삭제</button>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;