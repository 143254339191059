import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";

function StuNameBarArea(props:any){
   let myProps={
    Style:{},
    row_data:{},
    info_arr:[],
    select_idx:0,
    set_select_idx:(inData:any)=>{},
    select_stu_grade_arr:[],
    ...props
  };
  let Style=myProps.Style;
  let default_row_data={
    a_user_name:"",
    a_stu_grade:"",
    a_school_name:"",
  };
  let row_data={
    ...default_row_data,
    ...myProps.row_data
  };
  let select_idx=parseInt(strFunc.uncomma(myProps.select_idx));
  let info_arr=myProps.info_arr;

  const on_left_right_click=(sort:"left"|"right")=>{
    let tmp_select_idx=select_idx;
    if(sort=="left"){
      tmp_select_idx--;
      if(tmp_select_idx<0){
        tmp_select_idx=info_arr.length-1;
      }
    }else{
      tmp_select_idx++;
      if(tmp_select_idx>=info_arr.length){
        tmp_select_idx=0;
      }
    }
    myProps.set_select_idx(tmp_select_idx);
  };

  let school_name_str=strFunc.cut_str(row_data.a_school_name,2,"");
  let stu_grade_str=row_data.a_stu_grade;
  if(myProps.select_stu_grade_arr){
    for(let i=0;i<myProps.select_stu_grade_arr.length;i++){
      if(myProps.select_stu_grade_arr[i]["value"]==stu_grade_str){
        stu_grade_str=myProps.select_stu_grade_arr[i]["text"];
      }
    }
  }
  
  return (
    <div className={Style.header} >
      <div className={Style.header_con}>
        <span className={Style.header_left}
          onClick={() => { on_left_right_click("left"); }} >
          <img src={left_arrow_ico} style={{ height: 17 }} />
        </span>
        {row_data.a_user_name}
        <span className="ml-2" style={{color:"#999"}}>
          {school_name_str+" "+stu_grade_str}
        </span>
        <span className={Style.header_right}
          onClick={() => { on_left_right_click("right"); }} >
          <img src={right_arrow_ico} style={{ height: 17 }} />
        </span>
      </div>
    </div>
  );
};
export default StuNameBarArea;