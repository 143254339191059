import { useEffect,useState,forwardRef,useImperativeHandle,useRef  } from 'react';
import noimg_img from "@/img/ico/noimg.png";
import strFunc from '@/lib/lyg/string';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import RequestPopup from "./popup/request_popup";
import { useSelector, useDispatch } from 'react-redux';

const ViewCardComponent=forwardRef((props:any, ref) => {
  let myProps:any={
    "row_data":{},
    "xColumnArr":{
      "x_column_list_arr":[]//[{"key":"a_ymd","name":"board_ymd","width":"100","is_show":"1"}]
    },
    "onClose":()=>{},
    ...props
  };

  let user=useSelector((state:any) => state.user);
  const [row_data,set_row_data]= useState(myProps.row_data);
  const xColumnArr=myProps.xColumnArr;

  const [popupData,setPopupData] = useState({
    isOpen:false,
    sort:"request",
    corse_info:row_data,
    title:"수강신청",
    width:"300px",
    height:"50%",
  });
  
  const openRequestPopup=()=>{
    setPopupData({
      ...popupData,
      corse_info:row_data,
      "isOpen":true,
    });
  };
  

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    set_row_data
  }));

  let content_style={
    border:"1px solid #ccc",
    borderRadius:10,
    color:"#666",
    padding:10,
    minHeight:40
  };

  let section_wrap_style={
    width:"40%",
  };
  let section_head_style:any={
    position:"relative",
    width:"100%",
    height:30,
    fontWeight:"bold",
    fontSize:"18px",
    backgroundColor:"#eee",
    paddingLeft:10
  };
  let video_item_style:any={
    padding:15,
    position:"relative",
  };

  //비디오태그
  const getVideoListTag=(section_seq:number)=>{
    let video_tags:any="";
    let video_arr=[];
    if(row_data.video_arr){
      for(let i=0;i<row_data.video_arr.length;i++){
        if(row_data.video_arr[i]["a_section_seq"]==section_seq){
          video_arr.push(row_data.video_arr[i]);
        }
      }
    }
    video_tags=video_arr.map((item:any,idx:number)=>{
      let video_time_obj:any=strFunc.secondsToTimeJson(strFunc.uncomma(item.a_total_sec));
      let video_time_str=video_time_obj.i+":"+video_time_obj.s;
      if(video_time_obj.h!="00"){
        video_time_str=video_time_obj.h+":"+video_time_obj.i+":"+video_time_obj.s;
      }
      return (
        <li key={idx} style={video_item_style}>
          {idx+1+". "} {item.a_title} 
          <span
            style={{
              position:"absolute",
              right:10,
              top:17,
              fontSize:14,
              color:"#aaa"
            }}>
            {video_time_str}
          </span>
        </li>
      );
    });

    return video_tags;
  };

  //섹션
  let section_tags="";
  if(row_data.section_arr){
    section_tags=row_data.section_arr.map((item:any,idx:number)=>{
      return (
        <div key={idx} style={section_wrap_style}>
          <div style={section_head_style}>
            | {item.a_title}
          </div>
          <ul>
            {getVideoListTag(item.a_seq)}
          </ul>
        </div>
      );
    });
  }

  let is_able_use=false;
  let pre_use_str="";
    if(row_data.use_stu_arr){
      for(let i=0;i<row_data.use_stu_arr.length;i++){
        if(row_data.use_stu_arr[i]["a_stu_seq"]==user.user_seq){
          pre_use_str="(수강중)";
          is_able_use=true;
        }
      }
    }
    if(pre_use_str==""&&row_data.request_arr){
      for(let i=0;i<row_data.request_arr.length;i++){
        if(row_data.request_arr[i]["a_writer_seq"]==user.user_seq){
          pre_use_str="(수강신청중)";
          
        }
      }
    }


  return (
    <div>
      <h4>{row_data.a_title}</h4>
      <div style={content_style}>
        {
          row_data.a_content && row_data.a_content.split('\n').map( (line:string,idx:number) => {
            return (<span key={idx}>{line}<br/></span>)
          })
        }
      </div>
      <div>
        상태 : 
        <span style={{color:"green"}}>{pre_use_str}</span>
      </div>
      <div className="mt-1">
        {section_tags}
      </div>
      <div className="btn-box-center mt-1">
        {is_able_use==false&&
          <button className="btn btn-dark" onClick={()=>{openRequestPopup();}} >신청</button>
        }
        <button className="btn btn-dark" onClick={()=>{myProps.onClose();}}>뒤로</button>
      </div>
      {popupData.isOpen && 
      <LayerPopup closePopup={()=>{setPopupData({...popupData,"isOpen":false});}} title={popupData.title} 
        width={popupData.width} height={popupData.height} >
          {popupData.sort==="request"&&
            <RequestPopup 
             corse_info={popupData.corse_info}
             callback={()=>{myProps.onClose();}} 
             closePopup={()=>{setPopupData({...popupData,"isOpen":false});}}></RequestPopup>
          }
      </LayerPopup>
      }
    </div>
  );
});

export default ViewCardComponent;