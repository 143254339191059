import SelectSlide from "@/pcomponents/common/content/list/select_slide";

function GroupManagerArea(props:any){
   let myProps={
    comp_group_manager_arr:[],
    select_value:"",
    on_click_item:(inData:any)=>{},
    ...props
  };

  const get_group_select_slide_tags=()=>{
    let group_select_slide_tags=myProps.comp_group_manager_arr.map((item:any,idx:number)=>{
      let select_mcomp_arr=[];
      for(let i=0;i<item.u_comp_link_arr.length;i++){
        let u_comp_link=item.u_comp_link_arr[i];
        let link_pri_val = u_comp_link.a_mcomp_seq + "," + u_comp_link.a_mcomp_user_seq;
        let row_name = u_comp_link.comp_name;// + " " + u_comp_link.a_mcomp_user_name;
        select_mcomp_arr.push({
          "value": link_pri_val,
          "text": row_name,
          "thum_src": "",
          "data": u_comp_link
        });
      }
      
      return (
        <div key={idx}>
          <div style={{textAlign:"center",color:"#2f00ff",fontWeight:600}}>
            그룹 학원선택({item.group_name})
          </div>
          <div>
            <SelectSlide
              select_arr={select_mcomp_arr}
              select_value={myProps.select_value}
              on_click_item={myProps.on_click_item}
            ></SelectSlide>
          </div>
        </div>
      );
    });
    return group_select_slide_tags;
  };
  
  return (
  <div>
    {get_group_select_slide_tags()}
  </div>
  );
};
export default GroupManagerArea;