import strFunc from '@/lib/lyg/string';
import { useState,useRef,useEffect } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";

function ViewPopupContent(props:any) {
  let myProps={
    rowData:{
      
    },
    list_refresh:()=>{},
    ...props,
  };
  useEffect(()=>{
    
  },[]);
  
  return (
    <div>
      <div className="view-table-div mt-2">
        <table>
          <colgroup>
            <col width="20%"></col>
            <col width="30%"></col>
            <col width="20%"></col>
            <col width="30%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>구분</th>
              <td>
                {myProps.rowData.a_sort}
              </td>
              <th>작성자</th>
              <td>
                {myProps.rowData.a_writer}
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td colSpan={3} style={{color:myProps.rowData.a_color}}>
                {myProps.rowData.a_title}
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {
                  myProps.rowData.a_board_content && myProps.rowData.a_board_content.split('\n').map( (line:string,idx:number) => {
                    return (<span key={idx}>{line}<br/></span>)
                  })
                }
              </td>
            </tr>
            <tr>
              <th>작성일</th>
              <td>
                {myProps.rowData.a_create_date}
              </td>
              <th>조회수</th>
              <td>
                {strFunc.comma(myProps.rowData.a_read_cnt)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-line-gray" onClick={()=>{props.closePopup();}}>닫기</button>
      </div>
    </div>
  );
}

export default ViewPopupContent;
