import Style from "./css/style.module.css";
import left_arrow_ico from "@/img/ico/arrow/left_arrow_black.png";
import { useNavigate } from 'react-router-dom';

function HeaderSimple(props: any) {
  let myProps = {
    title: "",
    back_url: "/mobile",
    ...props
  };
  const navigate = useNavigate();

  return (
    <div className={Style.header + " noto_sans"} >
      <div className={Style.header_con}>
        <span className={Style.header_left}
          onClick={() => { navigate(myProps.back_url); }} >
          <img src={left_arrow_ico} style={{ height: 17 }} />
        </span>
        {myProps.title}
        {myProps.children}
      </div>
    </div>
  );
};
export default HeaderSimple;