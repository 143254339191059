import strFunc from "@/lib/lyg/string";
import { useState } from 'react';

function SoundOptionArea(props: any) {
  let myProps = {
    ...props
  };

  let default_sound_data = {
    bgm_play:strFunc.get_storage("playgound_bgm_play","1")=="1"?true:false,
    bgm_volume:strFunc.get_storage("playgound_bgm_volume","0.5"),
    effect_play:strFunc.get_storage("playgound_effect_play","1")=="1"?true:false,
    effect_volume:strFunc.get_storage("playgound_effect_volume","1"),
  };
  const [sound_data, set_sound_data] = useState<any>(default_sound_data);

  const on_change_sound_data = (key: string, value: any) => {
    if (key == "bgm_volume" || key == "effect_volume") {

    }
    let change_sound_data = { ...sound_data };
    change_sound_data[key] = value;
    set_sound_data(change_sound_data);

    strFunc.set_storage("playgound_bgm_play", change_sound_data.bgm_play?"1":"2");
    strFunc.set_storage("playgound_bgm_volume", change_sound_data.bgm_volume);
    strFunc.set_storage("playgound_effect_play", change_sound_data.effect_play?"1":"2");
    strFunc.set_storage("playgound_effect_volume", change_sound_data.effect_volume);
  };

  return (
    <div style={{fontSize:"22px"}}>
      <table>
        <colgroup>
          <col width={"200px"}></col>
          <col width="*"></col>
        </colgroup>
        <tbody>
          <tr>
            <th>배경음악</th>
            <td>
              <input type="checkbox" checked={sound_data.bgm_play}
                name="bgm_play"
                onChange={(e: any) => {
                  on_change_sound_data(e.target.name,e.target.checked);
                }} 
                style={{width:"22px",height:"22px"}}/>
            </td>
          </tr>
          <tr>
            <th>배경음악크기</th>
            <td>
              <input type="range" min="0" max="1" step="0.1" value={sound_data.bgm_volume}
                name="bgm_volume"
                onChange={(e: any) => {
                  on_change_sound_data(e.target.name,e.target.value);
                }} />
            </td>
          </tr>
          <tr>
            <th>효과음</th>
            <td>
              <input type="checkbox" checked={sound_data.effect_play}
                name="effect_play"
                onChange={(e: any) => {
                  on_change_sound_data(e.target.name,e.target.checked);
                }} 
                style={{width:"22px",height:"22px"}} />
            </td>
          </tr>
          <tr>
            <th>효과음크기</th>
            <td>
              <input type="range" min="0" max="1" step="0.1" value={sound_data.effect_volume}
                name="effect_volume"
                onChange={(e: any) => {
                  on_change_sound_data(e.target.name,e.target.value);
                }} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default SoundOptionArea;