import carrot_150_img from "@/img/mobile/char/carrot_150.png";
import dig_land_img from "@/img/mobile/main/dig_land100.png";
import Balloon from "./talk_balloon";
import balloon_data_arr from "./data/balloon_data";
import { useState, forwardRef, useImperativeHandle } from 'react';
import {get_char_img} from "@/pcomponents/common/content/char/data/char_data";

const Character = forwardRef((props: any, ref) => {
  let myProps = {
    Style: {},
    x: 0,
    y: 0,
    width: 70,
    height: 70,
    unread_data: {
      chat: 0,
      home_work: 0,
      plan: 0,
      sche: 0,
    },
    char_user_info:null,
    ...props
  };
  let Style = myProps.Style;
  let char_user_info=myProps.char_user_info;
  let char_img_src=carrot_150_img;
  if(char_user_info!=null){
    char_img_src=get_char_img({id:char_user_info["a_sort_id"]});
  }
  let char_wrap_style: any = {
    left: myProps.x,
    top: myProps.y,
    width: myProps.width,
    height: myProps.height,
  };

  let shadow_style = {};

  const [is_show_balloon, set_is_show_balloon] = useState(true);
  const [is_show_char, set_is_show_char] = useState(true);
  const [talk_msg_data, set_talk_msg_data] = useState({
    is_show: false,
    msg: "",
    color: "blue",
  });
  const show_char_talk_msg = (inData: any) => {
    let opt_obj = {
      msg: "",
      color: "blue",
      msec: 2000,
      ...inData
    };
    set_talk_msg_data({
      ...talk_msg_data,
      is_show: true,
      msg: opt_obj["msg"],
      color: opt_obj["color"],
    });
  };

  const get_balloon_tags = () => {
    let balloon_tags = balloon_data_arr.map((item: any, idx: number) => {
      let balloon_x = myProps.width / 2 + item.x;
      let balloon_y = myProps.height / 2 + item.y;
      if (myProps.unread_data[item.badge_id] != undefined) {
        item.badge_num = myProps.unread_data[item.badge_id];
      }

      return (
        <Balloon
          key={idx}
          Style={Style}
          x={balloon_x}
          y={balloon_y}
          width={item.width}
          height={item.height}
          degree={item.degree}
          url={item.url}
          balloon_src={item.balloon_src}
          icon_src={item.icon_src}
          icon_text={item.icon_text}
          title={item.title}
          badge_num={item.badge_num}
        ></Balloon>
      );
    });
    return balloon_tags;
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    show_char_talk_msg
  }));

  return (
    <div className={Style.char_wrap} style={char_wrap_style} >
      {is_show_char &&
        <img src={char_img_src} className={Style.char_img} onClick={() => { set_is_show_balloon(!is_show_balloon); }} />
      }
      <img src={dig_land_img} className={Style.char_shadow_img} style={shadow_style}
        onClick={() => { set_is_show_char(!is_show_char); }} />
      {is_show_balloon &&
        <>
          {/* {get_balloon_tags()} */}
        </>
      }
      {talk_msg_data.is_show &&
        <div style={{
          color: talk_msg_data.color, textAlign: "center", fontSize: 18,
          position: "absolute", bottom: -40, left: "-90%", width: "300%"
        }}
          onClick={() => {
            set_talk_msg_data({
              ...talk_msg_data,
              is_show: false,
            });
          }}>
          {talk_msg_data.msg}
        </div>
      }
    </div>
  );
});
export default Character;