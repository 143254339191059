import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useLocation,useNavigate } from "react-router";
import ViewVideoArea from "@/pages/mobile/plan/todo/commponent/view_video";

function TodoViewVideoPage(){
  const navigate = useNavigate();
  const { state } = useLocation();
  let todo_info=state.todo_info;
  let back_press_url=state.back_press_url?state.back_press_url:"/mobile/home_work";
  
  const goBackUrl=()=>{
    let send_data:any={};
    if(state.list_opt){
      send_data["list_opt"]=state.list_opt;
    }
    navigate(back_press_url,{
      state:send_data
    });
  };

  return (
  <MobileLayout container_style={{background:"#FFF",marginTop:0}} 
    container_con_style={{width:"100%"}} 
    has_header={false} >
      <ViewVideoArea
        todo_info={todo_info}
        on_go_back={()=>{goBackUrl();}}
      ></ViewVideoArea>
  </MobileLayout>
  );
};
export default TodoViewVideoPage;