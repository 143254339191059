import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useLocation, useNavigate } from "react-router";
import TopDateSearchArea from "./area/top_date_search";
import CardListArea from "@/pages/mobile/plan/home_work/area/card_list";
import FileSubmitArea from "@/pages/mobile/plan/home_work_file/out_area/file_submit";

function MyPlanPage() {
  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const { state } = useLocation();
  let state_obj = {
    "list_opt": {
      's_start_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
      's_end_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    },
    ...state
  };
  const [pageData, setPageData] = useState({
    listOpt: {
      'now_page': 1,
      'num_per_page': 100,
      'order_id': 'a_reserve_start_date DESC, a_create_date DESC',
      'order_type': '',
      's_date_type': 'a_reserve_start_date',
      's_start_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
      's_end_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
      "is_add_idx_info": "1",
      "s_addon_file_arr": "1",
      "s_addon_par_content": "1",
      "s_addon_elapse_time": "1",
      "s_todo_sort": "",
      "s_todo_sort_except": "homework",
      "s_user_key": "",
      ...state_obj.list_opt
    }
  });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [plan_study_file_arr, set_plan_study_file_arr] = useState([]);
  const plan_study_file_id = "plan_study_file";

  useEffect(() => {
    
    list({});
  }, [user]);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }

    let tmp_list_opt: any = { ...pageData.listOpt };
    let select_mcomp_link_val_arr = [];
    select_mcomp_link_val_arr.push(user.comp_seq + "," + user.user_seq);//내꺼도

    //업체꺼 등록
    let select_mcomp_link_val = "";
    let s_stu_mcomp_seq = "";
    if (!strFunc.is_empty(user.select_mcomp_link)) {
      select_mcomp_link_val = user.select_mcomp_link.a_mcomp_seq + "," + user.select_mcomp_link.a_mcomp_user_seq;
      s_stu_mcomp_seq = user.select_mcomp_link.a_mcomp_seq;
    }
    if (strFunc.is_empty(select_mcomp_link_val)) {
      return false;
    }
    select_mcomp_link_val_arr.push(select_mcomp_link_val);

    if (strFunc.is_empty(select_mcomp_link_val_arr)) {
      return false;
    }
    tmp_list_opt.s_user_key = select_mcomp_link_val_arr;

    list_plan_study_files();
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };
  const list_plan_study_files = () => {
    let home_work_file_list_opt = {
      'now_page': 1,
      'num_per_page': 1000,
      'order_id': 'a_create_date DESC',
      'order_type': '',
      's_date_type': 'a_create_date',
      's_start_date': pageData.listOpt.s_start_date,
      's_end_date': pageData.listOpt.s_end_date,
      "is_add_idx_info": "1",
      "s_comp_seq": "",
      "s_par_id": "plan_study_file",
      "s_par_seq": "",
      "s_sort1": "",
      "s_sort3": "",
    };

    let s_stu_mcomp_seq = "";
    let s_stu_mcomp_user_seq = "";
    if (!strFunc.is_empty(user.select_mcomp_link)) {
      s_stu_mcomp_seq = user.select_mcomp_link.a_mcomp_seq;
      s_stu_mcomp_user_seq = user.select_mcomp_link.a_mcomp_user_seq;
    }
    if (strFunc.is_empty(s_stu_mcomp_seq)) {
      return false;
    }
    if (strFunc.is_empty(s_stu_mcomp_seq)) {
      return false;
    }
    home_work_file_list_opt["s_comp_seq"] = s_stu_mcomp_seq;
    home_work_file_list_opt["s_sort3"] = s_stu_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', home_work_file_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_plan_study_file_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const on_click_card_box = (inData: any) => {
    let opt_obj = {
      "todo_info": {},
      ...inData
    };

    let send_data = {
      "todo_info": opt_obj.todo_info,
      "back_press_url": "/mobile/my_plan",
      list_opt: pageData.listOpt,
    };
    let view_url = "/mobile/todo/todo_view";
    if (opt_obj.todo_info["a_par_id"] == "video_list") {
      view_url = "/mobile/todo/todo_view_video";
    }
    navigate(view_url,
      { state: send_data });
  };

  const goTodoWritePage = () => {
    let send_data = {
      "select_date": pageData.listOpt.s_start_date,
      "back_press_url": "/mobile/my_plan",
      "is_comp_todo": true,
      "todo_sort": "study",
      list_opt: pageData.listOpt,
    };
    navigate("/mobile/todo/todo_write",
      { state: send_data });
  };

  let card_list_con_wrap_style: any = {
    width: "100%",
    position: "absolute",
    top: 110,
    left: 0,
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: "#fafafa",
    paddingTop: "20px"
  };

  return (
    <MobileLayout container_style={{ background: "#fff", marginTop: 0 }}
      container_con_style={{ width: "100%" }}
      has_header={false} >

      <TopDateSearchArea
        listOpt={pageData.listOpt}
        list={list}
      ></TopDateSearchArea>

      <div style={card_list_con_wrap_style}>
        <div className="text-right noto_sans" style={{ height: 20, lineHeight: "20px", paddingRight: 15 }}>
          <button className="btn btn-yellow2" style={{ fontWeight: 400 }} onClick={() => { goTodoWritePage(); }}>플랜추가</button>
        </div>
        <CardListArea
          info_arr={infoArr}
          on_click_card_box={on_click_card_box}
          default_group_key={"study"}
        ></CardListArea>

        <FileSubmitArea
          title={"학습 제출하기"}
          back_press_url={"/mobile/my_plan"}
          s_date={pageData.listOpt.s_start_date}
          file_arr={plan_study_file_arr}
          list_opt={pageData.listOpt}
          s_par_id={plan_study_file_id}
          is_show_audio={false}
          is_show_video={false}
        ></FileSubmitArea>
      </div>
    </MobileLayout>
  );
};
export default MyPlanPage;