import { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import LygCalendarFunc from '@/lib/lyg/calendar';
import GetCalendarTdDataFunc from "./func/get_cal_td_data";

const CalendarTableArea = forwardRef((props: any, ref) => {
  let myProps = {
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    attend_info_arr: [],
    lecture_arr:[],
    desk_use_arr:[],
    onClickTdTag: (inData: any) => { },
    ...props
  };

  const [select_date, set_select_date] = useState(myProps.select_date);
  const now_date_json = DateFunc.get_date_json(new Date());
  let day_num_list = [0, 1, 2, 3, 4, 5, 6];
  let day_name_list = ["일", "월", "화", "수", "목", "금", "토"];
  let cal_data = LygCalendarFunc.get_cal_data_by_select_date(select_date);
  let pre_month_date = cal_data["pre_month_date"];
  let after_month_date = cal_data["after_month_date"];

  const setInitData = (inData: any) => {
    let opt_obj = {
      select_date: myProps.select_date,
      ...inData
    };
    set_select_date(opt_obj.select_date);
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setInitData
  }));

  const get_col_tags = () => {
    let col_tags = day_num_list.map((item: any, idx: number) => {
      return (
        <col key={idx} width="14%"></col>
      );
    });
    return col_tags;
  };

  const get_th_tags = () => {
    let th_tags = day_num_list.map((item: number, idx: number) => {
      let th_title = day_name_list[item];
      let th_text_class_name = "";
      if (idx == 0) {
        th_text_class_name += " sche_table_th_text_sun";
      } else if (idx == 6) {
        th_text_class_name += " sche_table_th_text_sat";
      }
      return (
        <th key={idx}>
          <div className={th_text_class_name}>{th_title}</div>
        </th>
      );
    });
    return th_tags;
  };

  const get_td_tags = (inData: any) => {
    let opt_obj = {
      "td_week_data_arr": [],
      ...inData
    };

    let td_tags = opt_obj["td_week_data_arr"].map((item: any, idx: number) => {
      let td_tag_class_name = "sche_table_td";
      if (item.date_str == select_date) {
        td_tag_class_name += " sche_table_td_title_select_date";
      }
      if (item.is_this_month
        && cal_data.select_month_json.m == now_date_json.m
        && cal_data.select_month_json.Y == now_date_json.Y
        && item.day == parseInt(now_date_json.d)) {

        td_tag_class_name += " sche_table_td_title_now_date";
      }

      let td_class_name = "sche_table_td_title";
      if (item.is_this_month) {
        td_class_name += " sche_table_td_title_select_month";
      }
      if (item.day_of_week == 0) {
        td_class_name += " sche_table_td_title_sun";
      } else if (item.day_of_week == 6) {
        td_class_name += " sche_table_td_title_sat";
      }

      let select_date_str = item["year"] + "-" + DateFunc.get_digit_str(item["month"]) + "-" + DateFunc.get_digit_str(item["day"]);

      return (
        <td key={idx} className={td_tag_class_name}
          onClick={() => {
            myProps.onClickTdTag({
              ...item,
              select_date_str: select_date_str
            });
            set_select_date(select_date_str);
          }}>
          <div className={td_class_name} style={{ textAlign: "center" }}>
            {item.day}
          </div>
          <div className="sche_table_td_content_wrap">
            {item.content}
          </div>
        </td>
      );
    });
    return td_tags;
  };

  const get_tr_tags = () => {
    let td_data_arr = LygCalendarFunc.get_td_data_arr({ cal_data: cal_data });
    td_data_arr = GetCalendarTdDataFunc.td_data_arr_of_attend({
      td_data_arr: td_data_arr,
      attend_info_arr: myProps.attend_info_arr,
      lecture_arr: myProps.lecture_arr,
      desk_use_arr: myProps.desk_use_arr,
    });
    let td_week_arr = LygCalendarFunc.get_td_week_arr_by_td_data_arr({
      cal_data: cal_data,
      td_data_arr: td_data_arr,
    });

    let tr_tags = td_week_arr.map((item: any, idx: number) => {
      let td_tags = get_td_tags({
        "td_week_data_arr": item
      });
      return (
        <tr key={idx}>
          {td_tags}
        </tr>
      );
    });
    return tr_tags;
  };

  return (
    <div>
      <div>
        <table className="sche_table">
          <colgroup>
            {get_col_tags()}
          </colgroup>
          <thead>
            <tr>
              {get_th_tags()}
            </tr>
          </thead>
          <tbody>
            {get_tr_tags()}
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default CalendarTableArea;