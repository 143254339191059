//import TabArea1 from "@/pcomponents/common/content/list/tab1";
import Style from "./css/style.module.css";

function TabArea1(props:any){
   let myProps={
    tab_data_arr:[
      {value:"lecture",text:"수업"},
      {value:"a_stu_grade",text:"학년순"},
      {value:"attend",text:"출결순"},
    ],//[{value:'',text:''}]
    selected_tab:"",
    set_selected_tab:(inData:any)=>{},
    ...props
  };

  const get_tab_tags=()=>{
    let tab_tags=myProps.tab_data_arr.map((item:any,idx:number)=>{
      let item_class_name=Style.item;
      if(item.value==myProps.selected_tab){
        item_class_name+=" "+Style.active;
      }
      return (
        <div key={idx} className={item_class_name} 
          onClick={()=>{myProps.set_selected_tab(item.value);}}>
          {item.text}
        </div>
      );
    });
    return tab_tags;
  };
  
  return (
  <div className={Style.tab_wrap+" noto_sans"}>
    {get_tab_tags()}
  </div>
  );
};
export default TabArea1;  