import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import UserGroupDataFunc from "@/pcomponents/common/content/list/tab1/func/group_data";
import Style from "./css/style.module.css";
import CardBoxArea from "./card_box";

function DailyListArea(props: any) {
  let myProps = {
    tab_id: "",
    info_arr: [],
    select_date: "",
    set_info_arr: (inData: any) => { },
    list: (inData: any) => { },
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    is_show_checkbox: true,
    is_show_etc_group: false,
    show_group_time:{},
    set_group_time:(inData: any) => { },
    able_time_arr_ref:null,
    save_daily_report_by_stu_arr: (inData: any) => { },
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    open_write_td_data_popup: (inData: any) => { },
    openViewTodoStuPopup: (inData: any) => { },
    open_stu_layer_popup: (inData: any) => { },
    open_find_study_title_popup: (inData: any) => { },
    ...props
  };

  let now_d_json = DateFunc.get_date_json(new Date());
  let select_date_json = DateFunc.get_date_json(new Date(myProps["select_date"]));
  let select_day_str = DateFunc.get_day_str_by_day_num(select_date_json.day);
  let pre_date_change_num = -1;
  if (select_date_json.day == 1) {
    //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
    pre_date_change_num = -3;
  }
  let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(myProps["select_date"]), 'day', pre_date_change_num), "Y-m-d");
  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });
  let select_homework_state_arr = XcolumnFunc.getSortByTableKey({ 'table': 'daily_report', 'key': 'a_homework_state' });

  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;

  const auto_save_data = useRef<any>({
    time_out_ref:null,
    delay_time: 500,
    write_data_arr: [],
  });

  const save_auto_write_daily_info_by_time_out = () => {
    return false;
    if(auto_save_data.current.time_out_ref){
      clearTimeout(auto_save_data.current.time_out_ref);
      auto_save_data.current.time_out_ref=null;
    }
    if(auto_save_data.current.write_data_arr.length==0){
      return false;
    }
    auto_save_data.current.time_out_ref = setTimeout(() => {
      if (auto_save_data.current.write_data_arr.length > 0) {
        myProps.save_daily_report_by_stu_arr({
          report_data_arr: auto_save_data.current.write_data_arr,
        });
      }
      auto_save_data.current.write_data_arr = [];
      auto_save_data.current.time_out_ref = null;
    }, auto_save_data.current.delay_time);
  };

  const go_write_daily_info_arr_auto_by_ajax = (inData: any) => {
    let opt_obj = {
      write_data_arr: [],
      is_reload: false,
      ...inData
    };
    let write_data_arr = opt_obj.write_data_arr;
    if (write_data_arr.length == 0) {
      return false;
    }

    let write_form_json: any = {
      "data_arr": write_data_arr,
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    write_form_json["api_key"] = comp_api_key;
    write_form_json["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/daily_report/write', write_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          auto_save_data.current.write_data_arr = [];
          // set_is_view_mode(true);
          if (opt_obj.is_reload) {
            myProps.list({});
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const on_check_all = (e: any) => {
    if (e.target.checked == true) {
      //추가
      let tmp_check_row_val_arr = myProps.check_row_val_arr;
      for (let i = 0; i < myProps["info_arr"].length; i++) {
        let info = myProps["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) == -1) {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      let tmp_check_row_val_arr: any = [];
      for (let i = 0; i < myProps["info_arr"].length; i++) {
        let info = myProps["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) == -1) {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    }
  };

  const on_change_group_checkbox = (inData: any) => {
    let opt_obj = {
      checked: false,
      info_arr: [],
      ...inData,
    };
    if (strFunc.is_empty(opt_obj["info_arr"])) {
      return false;
    }

    if (opt_obj["checked"]) {
      //추가
      let tmp_check_row_val_arr = myProps.check_row_val_arr;
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) == -1) {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      let tmp_check_row_val_arr: any = [];
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) != -1) {

        } else {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    }
  };

  const set_info_of_daily_report = (inData: any) => {
    let opt_obj = {
      info: {},
      ...inData
    };
    let info = opt_obj.info;

    let tmp_info_arr = myProps.info_arr;
    for (let i = 0; i < tmp_info_arr.length; i++) {
      if (tmp_info_arr[i]["a_seq"] == info["a_seq"]) {
        tmp_info_arr[i] = info;
        //auto save
        auto_save_data.current.write_data_arr=get_add_daily_report_atuo_write_data_arr({
          daily_report_arr: info.daily_report_arr
        });

        //auto check
        let change_check_row_val_arr = myProps.check_row_val_arr;
        if (strFunc.str_in_array(info["a_seq"], change_check_row_val_arr) == -1) {
          change_check_row_val_arr.push(info["a_seq"]);
          myProps.set_check_row_val_arr(change_check_row_val_arr);
        }
      }
    }
    save_auto_write_daily_info_by_time_out();
    myProps.set_info_arr(tmp_info_arr);
  };

  const get_add_daily_report_atuo_write_data_arr = (inData: any) => {
    let opt_obj = {
      daily_report_arr: [],
      ...inData
    };
    let change_write_data_arr = auto_save_data.current.write_data_arr;
    let add_data_arr = opt_obj.daily_report_arr;

    for (let i = 0; i < add_data_arr.length; i++) {
      let add_data = add_data_arr[i];
      let is_exist = false;
      for (let j = 0; j < change_write_data_arr.length; j++) {
        let change_data = change_write_data_arr[j];
        if (!strFunc.is_empty(change_data["a_seq"])
          && change_data["a_ymd"] == add_data["a_ymd"]
          && change_data["a_seq"] == add_data["a_seq"]) {
          is_exist = true;
        } else if (change_data["a_lecture_seq"] == add_data["a_lecture_seq"]
          && change_data["a_stu_seq"] == add_data["a_stu_seq"]
          && change_data["a_writer_seq"] == add_data["a_writer_seq"]
          && change_data["a_date"] == add_data["a_date"]) {
          is_exist = true;
        }
      }
      if (is_exist == false) {
        change_write_data_arr.push(add_data);
      }
    }

    return change_write_data_arr;
  };

  const get_cardbox_tags = (inData: any) => {
    let opt_obj = {
      infoArr: [],
      lecture_info: null,
      lecture_time_str: "",
      ...inData
    };
    let infoArr = opt_obj["infoArr"];
    let lecture_info = opt_obj["lecture_info"];
    let lecture_time_str = opt_obj["lecture_time_str"];
    let cardbox_tags: any = infoArr.map((item: any, idx: number) => {
      return (
        <CardBoxArea 
          key={idx} 
          row_num={idx}
          info={item}
          set_info={(info: any) => {
            set_info_of_daily_report({ info: info });
          }}
          lecture_info={lecture_info}
          select_date={myProps.select_date}
          pre_date_str={pre_date_str}
          list={myProps.list}
          Style={Style}
          lecture_time_str={lecture_time_str}
          check_row_val_arr={myProps.check_row_val_arr}
          set_check_row_val_arr={myProps.set_check_row_val_arr}
          select_stu_grade_arr={select_stu_grade_arr}
          select_homework_state_arr={select_homework_state_arr}
          save_daily_report_by_stu_arr={myProps.save_daily_report_by_stu_arr}
          openAttendPopup={myProps.openAttendPopup}
          deleteAttend={myProps.deleteAttend}
          open_write_td_data_popup={myProps.open_write_td_data_popup}
          openViewTodoStuPopup={myProps.openViewTodoStuPopup}
          open_stu_layer_popup={myProps.open_stu_layer_popup}
          open_find_study_title_popup={myProps.open_find_study_title_popup}
        ></CardBoxArea>
      );
    });

    if (strFunc.is_empty(cardbox_tags)) {
      //cardbox_tags = "";
    }

    return cardbox_tags;
  };

  const set_group_time_data=(inData:any)=>{
    let opt_obj={
      group_data_arr:[],
      ...inData
    };
    let able_time_arr:any=[];
    let group_data_arr=opt_obj.group_data_arr;
    for(let i=0;i<group_data_arr.length;i++){
      let group_data=group_data_arr[i];
      if(strFunc.str_in_array(group_data["start_time_str"],able_time_arr)==-1){
        able_time_arr.push(group_data["start_time_str"]);
      }
    }

    if(myProps.able_time_arr_ref){
      myProps.able_time_arr_ref.current=able_time_arr;
    }
  };

  const get_cardbox_group_tags = () => {
    let group_data_arr = UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date: myProps.select_date,
      info_arr: myProps.info_arr,
      tab_id: myProps.tab_id,
    });
    set_group_time_data({group_data_arr:group_data_arr});

    let cardbox_group_tags = group_data_arr.map((group_info: any, idx: number) => {
      let group_title_str = group_info["title"];
      if (myProps.is_show_etc_group == false) {
        if (group_info["title"] == "기타") {
          return "";
        }
      }
      
      if(myProps.show_group_time.sort=="now"){
        if(group_info["end_time_str"]){
          let end_time_h=parseInt(strFunc.uncomma(strFunc.cut_str(group_info["end_time_str"],2,"")));
          let now_h=parseInt(now_d_json.h);
          if(end_time_h<now_h){
            return "";
          }
        }
      }else if(myProps.show_group_time.sort=="select"){
        if(strFunc.str_in_array(group_info["start_time_str"],myProps.show_group_time.select_time_arr)==-1){
          return "";
        }
      }
      let lecture_info = group_info["lecture_info"];
      if (myProps.tab_id == "stu_grade") {
        let select_grade_obj_arr = strFunc.get_obj_by_key_val_at_obj_arr("value", group_title_str, select_stu_grade_arr);
        if (select_grade_obj_arr.length == 1) {
          group_title_str = select_grade_obj_arr[0]["text"];
        }
      }
      let first_td_tag = (
        <div style={{ display: "inline-block",color:"#059eff",fontWeight:500 }}>
          <label>
            {myProps.is_show_checkbox &&
              <input type="checkbox" onChange={(e: any) => {
                on_change_group_checkbox({
                  checked: e.target.checked,
                  info_arr: group_info["info_arr"]
                });
              }} />
            }
            <span className="ml-1" >
              {group_info["start_time_str"]}
              ~
              {group_info["end_time_str"]}
            </span>
            <span className="ml-1">{strFunc.cut_str(group_title_str.substring(11), 5, "")}</span>
          </label>
        </div>
      );
      return (
        <div key={idx} className={Style.group_box_wrap}>
          <div>
            {first_td_tag}
          </div>
          <div className={Style.card_box_list_wrap}>
            {get_cardbox_tags({
              infoArr: group_info["info_arr"],
              lecture_info: lecture_info,
              lecture_time_str: group_title_str.substring(0, 11),
            })}
          </div>
        </div>
      );
    });

    if (strFunc.is_empty(cardbox_group_tags)) {
      cardbox_group_tags = (
        <div className="text-center" style={{ lineHeight: "50px" }}>
          내용이 없습니다.
        </div>
      );
    }

    return cardbox_group_tags;
  };

  return (
    <div className={Style.group_list_wrap+" noto_sans"}>
      {get_cardbox_group_tags()}
    </div>
  );
};
export default DailyListArea;