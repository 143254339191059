//import LygFile from '@/lib/lyg/file';

class LygFile{
  //LygFile.get_type_sort({ext:"",file_sort:"img"});
  static get_type_sort(inData){
    let opt_obj={
      "ext":"",
      "file_type":"",
      "file_sort":"img",//img,video,audio
      ...inData
    };
    let ext=opt_obj["ext"].toLowerCase();
    let file_type=opt_obj["file_type"];
    let file_sort=inData["file_sort"];

    let video_ext_arr=["mp4","mov","avi","wmv","avchd","flv","f4v","swf","mkv","webm","html5"];
    let audio_ext_arr=["mp3","wav","wma","ogg","flac","aac","m4a"];
    let img_ext_arr=["jpg","jpeg","png","gif","bmp","svg","webp","ico"];
    if(video_ext_arr.indexOf(ext)!=-1){
      file_sort="video";
    }else if(audio_ext_arr.indexOf(ext)!=-1){
      file_sort="audio";
    }else if(img_ext_arr.indexOf(ext)!=-1){
      file_sort="img";
    }
    return file_sort;
  };
  //LygFile.get_file_type({ext:"",file_type:""});
  static get_file_type(inData){
    let opt_obj={
      "ext":"",
      "file_type":"",
      ...inData
    };
    let ext=opt_obj["ext"].toLowerCase();
    let file_type=opt_obj["file_type"];

    if(ext=="mov"){
      file_type="video/mp4";
    }

    return file_type;
  };
}
export default LygFile;