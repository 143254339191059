import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import { useRef } from 'react';

function SearchBarArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    is_show_search_bar: false,
    set_is_show_search_bar: (inData: any) => { },
    go_write_page: (inData: any) => { },
    ...props
  };
  
  let init_all_start_date=DateFunc.get_date_format(DateFunc.get_change_date(new Date(),'year',-1), "Y-01-01");
  let is_show_search_bar = myProps.is_show_search_bar;
  let default_listOpt = {
    "s_stu_name_like": "",
    's_start_date': '',
    's_end_date': '',
  };
  myProps.listOpt = {
    ...default_listOpt,
    ...myProps.listOpt
  };
  const search_time_out_ref=useRef<any>(null);

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleSearchInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;

    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.listOpt.s_start_date)) {
      myProps.listOpt.s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.listOpt.s_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_start_date": change_date_str,
      "s_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    myProps.list({
      "now_page": "1",
      [key]: date_str,
    });
  };

  return (
    <div style={{ borderBottom: "1px solid #ddd", paddingBottom: 5 }}>
      <div className="search_wrap mt-2">
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 80 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_start_date != "" ? new Date(myProps.listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div> ~
        <div style={{ display: "inline-block", "width": 80 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_end_date != "" ? new Date(myProps.listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{ minWidth: 30 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
            "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
          });
        }}>오늘</button>
        <button className="btn-s btn-dark" style={{ minWidth: 35 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
            "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
          });
        }}>이번달</button>
        <button className="btn-s btn-dark" style={{ minWidth: 35 }} onClick={() => {
          myProps.list({
            "now_page": "1",
            "s_start_date": init_all_start_date,
            "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
          });
        }}>전체</button>
      </div>
      <div>
        <div className="search_wrap mt-2">
          <input type="text" className="search_input" value={myProps.listOpt.s_counseler_like}
            name="s_counseler_like"
            onChange={handleSearchInput} style={{ width: 80 }}
            placeholder="상담사" />
          <input type="text" className="search_input" value={myProps.listOpt.s_stu_school_name_like}
            name="s_stu_school_name_like"
            onChange={handleSearchInput} style={{ width: 80 }}
            placeholder="학교" />
        </div>
        <div className="search_wrap mt-2">
          <select className="search_input" name="s_is_success" onChange={handleSearchInput} style={{ width: 60 }} >
            <option value="" >상태</option>
            <option value="1" >완료</option>
            <option value="empty" >미완료</option>
          </select>

          <input type="text" className="search_input" name="s_stu_name_like"
            onChange={handleSearchInput} placeholder="학생" style={{ width: 75 }} />
          <span className="basic_search_item_book_mark"
            onClick={() => {
              let change_s_stu_bookmark = myProps.listOpt.s_stu_bookmark;
              if (change_s_stu_bookmark == "1") {
                change_s_stu_bookmark = "";
              } else {
                change_s_stu_bookmark = "1";
              }
              strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
              myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            }}>
            {myProps.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
          </span>
          <button className="btn btn-dark" onClick={() => { myProps.list({ "now_page": "1" }); }}>검색</button>
          <button className="ml-1"
            style={{ color: "#52B4A0", fontSize: 17 }}
            onClick={() => { myProps.go_write_page({}); }}>추가</button>
        </div>
      </div>
    </div>
  );
};
export default SearchBarArea;