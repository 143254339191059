import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Style from "./css/style.module.css";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import FindHeader from "./area/header";
import SearchArea from "./area/search";
import ListArea from "./area/list";

function FindStudentPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: (inData: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 300,
    'order_id': 'a_user_name',
    'order_type': '',
    "is_add_idx_info": "1",
    s_is_login: "1",
    s_stu_state: "now",
    s_grade: ["student"],
    s_user_name_like: "",
    s_school_name_like: "",
    s_addon_class_user: "1",
    s_addon_stu_grade_name: "1",
    s_addon_counsel_todo_tot: "1",
    s_addon_counsel_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01"),
    s_addon_counsel_end_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', +1), "Y-m-t"),
  });
  const [infoArr, setInfoArr] = useState([]);
  const [select_seq_arr, set_select_seq_arr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [refresh, set_refresh] = useState(false);
  let select_stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inOptObj: any) => {
    let tmp_list_opt={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt);

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }

    tmp_list_opt["s_mcomp_seq"] = iam_mcomp_seq;
    tmp_list_opt["s_mcomp_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/comp_user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_select_seq_arr([]);
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const get_select_info_arr = () => {
    let select_info_arr = [];
    let info_arr_len = infoArr.length;
    for (let i = 0; i < info_arr_len; i++) {
      let info: any = infoArr[i];
      if (strFunc.str_in_array(info.a_seq, select_seq_arr) != -1) {
        select_info_arr.push(info);
      }
    }
    return select_info_arr;
  };

  const go_callback = () => {
    let select_info_arr = get_select_info_arr();
    if (select_info_arr.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    myProps.callback({
      row_num: 0,
      key: "",
      data: select_info_arr,
    });
    myProps.closePopup();
  };

  return (
    <div className={Style.find_stu_wrap + " noto_sans"}>
      <FindHeader
        closePopup={() => { myProps.closePopup(); }}
        go_callback={() => { go_callback(); }}
        Style={Style}
      ></FindHeader>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
        Style={Style}
      ></SearchArea>
      <ListArea
        info_arr={infoArr}
        select_seq_arr={select_seq_arr}
        set_select_seq_arr={(inData: any) => {
          set_select_seq_arr(inData);
          set_refresh(!refresh);
        }}
        select_stu_grade_option_arr={select_stu_grade_option_arr}
        Style={Style}
      ></ListArea>
    </div>
  );
};
export default FindStudentPopup;