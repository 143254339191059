import { useState, useEffect, useRef } from 'react';
import strFunc from "@/lib/lyg/string";
import CharBarFunc from "./func/char_bar";
import Style from "./css/style.module.css";
import TopInfoArea from "./area/top_info";
import CharBarArea from "./area/char_bar";
import MatterArea from "@/pages/mobile/playground/math/common/matter";
import ButtonArea from "./area/button_area";
import SoundArea from "./area/sound";

function GameArea(props: any) {
  let myProps = {
    game_data: {},
    set_game_data: (inData: any) => { },
    set_refresh_game_data: (inData: any) => { },
    char_user_info: {},
    ...props
  };
  let char_user_info = myProps.char_user_info;

  const game_data_ref = useRef(myProps.game_data);
  const char_bar_ref=useRef({
    nut_arr:[],//{x:0,src:""}
  });
  const [refresh_cnt, set_refresh_cnt] = useState(0);

  const sound_ref = useRef<any>({
    gbm: null,
    success: null,
    fail: null,
    error: null,
  });
  let now_matter = null;
  let matter_now_idx = game_data_ref.current.matter_now_idx;
  if (game_data_ref.current.matter_arr[matter_now_idx]) {
    now_matter = game_data_ref.current.matter_arr[matter_now_idx];
  }

  useEffect(() => {
    let start_d_obj = new Date();
    game_data_ref.current.start_msec = start_d_obj.getTime();
    const game_loop = setInterval(game_loop_func, 30);
    char_bar_ref.current=CharBarFunc.init_data({
      char_bar_data: char_bar_ref.current,
      game_data: game_data_ref.current,
    });
    return () => {
      clearInterval(game_loop);
    };
  }, []);

  const game_loop_func = () => {
    game_data_ref.current.elapse_msec = new Date().getTime() - game_data_ref.current.start_msec;
    char_bar_ref.current=CharBarFunc.move_tick({
      char_bar_data: char_bar_ref.current,
    });
    set_refresh_cnt(game_data_ref.current.elapse_msec);
  };

  const remove_char_bar_first_one=()=>{
    char_bar_ref.current=CharBarFunc.remove_num_by_idx({
      char_bar_data: char_bar_ref.current,
      remove_idx: 0,
    });
  };

  return (
    <div>
      <TopInfoArea
        Style={Style}
        char_user_info={char_user_info}
        game_data_ref={game_data_ref}
      ></TopInfoArea>
      <CharBarArea
        Style={Style}
        char_user_info={char_user_info}
        game_data_ref={game_data_ref}
        char_bar_ref={char_bar_ref}
      ></CharBarArea>
      <div className={Style.matter_box_wrap} >
        <MatterArea
          matter={now_matter}
        ></MatterArea>
      </div>
      <div className="mt-3">
        <ButtonArea
          Style={Style}
          game_data_ref={game_data_ref}
          set_refresh_cnt={set_refresh_cnt}
          set_game_data={myProps.set_game_data}
          sound_ref={sound_ref}
          remove_char_bar_first_one={remove_char_bar_first_one}
        ></ButtonArea>
      </div>
      <SoundArea
        sound_ref={sound_ref}
      ></SoundArea>
    </div>
  );
};
export default GameArea;