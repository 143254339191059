//import TopMoveButton from "@/pcomponents/common/content/list/top_button";

function TopMoveButton(props:any){
   let myProps={
    ...props
  };
  
  let button_style:any={
    position:"fixed",
    right:20,
    bottom:80,
    width:40,
    height:40,
    textAlign:"center",
    lineHeight:"40px",
    borderRadius:"20px",
    background:"#3FAC96",
    opacity:0.7,
    zIndex: 99,
  };

  return (
  <button onClick={()=>{
      if(document.getElementsByClassName("m_main_body")){
          (document.getElementsByClassName("m_main_body")[0] as HTMLDivElement).scrollTop=0;
        }
      }} style={button_style}>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 25 15" fill="none" 
      style={{display:"inline-block",marginBottom:8}}>
      <path d="M3.12875 14.5358L12.5 5.75749L21.8713 14.5358L24.75 11.8333L12.5 0.333328L0.25 11.8333L3.12875 14.5358Z" fill="white"/>
    </svg>
  </button>
  );
};
export default TopMoveButton;