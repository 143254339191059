import { forwardRef, useImperativeHandle } from 'react';
import Style from "./css/style.module.css";
import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";
import DateFunc from '@/lib/lyg/date_func';

const AttendListArea = forwardRef((props: any, ref) => {
  let myProps = {
    infoArr: [],
    select_date: "",
    delete_attend_by_ajax: (inData: any) => { },
    ...props
  };
  let now_ymd_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  let is_able_delete = false;
  if (myProps.select_date >= now_ymd_str) {
    is_able_delete = true;
  }

  const get_select_date_data_by_info_arr = () => {
    let select_date_data = {
      in_info: null,
      out_info: null,
    };

    for (let i = 0; i < myProps.infoArr.length; i++) {
      let info = myProps.infoArr[i];
      if (info.a_ymd == myProps.select_date) {
        if (info.a_sort == "in") {
          select_date_data.in_info = info;
        } else if (info.a_sort == "out") {
          select_date_data.out_info = info;
        }
      }
    }

    return select_date_data;
  };

  const get_attend_data_tag = () => {
    let select_date_data = get_select_date_data_by_info_arr();

    let attend_data_tag: any = "";
    if (select_date_data.in_info == null && select_date_data.out_info == null) {
      attend_data_tag = (
        <div className={Style.attend_box} style={{ lineHeight: "40px", color: "#666" }}>
          출석정보가 없습니다.
        </div>
      );
      return attend_data_tag;
    }
    let in_info: any = select_date_data.in_info;
    let out_info: any = select_date_data.out_info;
    let attend_data = AttendStateFunc.get_attend_data_by_in_out_info({
      in_info: in_info,
      out_info: out_info,
    });

    let attend_str = attend_data.text;
    let bar_color = attend_data.color;

    attend_data_tag = (
      <div className={Style.attend_box}>
        <div className={Style.attend_left_bar}
          style={{ background: bar_color }}></div>
        <div className={Style.attend_box_title}>
          출석
        </div>
        <div className="mt-1">
          {in_info != null &&
            <div style={{ position: "relative" }}>
              <div>
                <span style={{ color: attend_data.color }}>{attend_str}</span>
                <span className="ml-2" style={{ color: "#888" }}>{in_info.a_time}</span>
              </div>
              <div>
                {in_info.a_memo}
              </div>
              {is_able_delete &&
                <span style={{ position: "absolute", right: 0, top: 0 }}>
                  <button className="btn-s btn-red"
                    onClick={() => {
                      myProps.delete_attend_by_ajax({
                        attend_info: in_info,
                        sort_name: attend_str
                      });
                    }}>취소</button>
                </span>
              }
            </div>
          }
          {out_info != null &&
            <div style={{ position: "relative",marginTop:5 }}>
              <div>
                <span style={{ color: attend_data.out_color }}>{attend_data.out_text}</span>
                <span className="ml-2" style={{ color: "#888" }}>{out_info.a_time}</span>
              </div>
              <div>
                {out_info.a_memo}
              </div>
              {is_able_delete &&
                <span style={{ position: "absolute", right: 0, top: 0 }}>
                  <button className="btn-s btn-red"
                    onClick={() => {
                      myProps.delete_attend_by_ajax({
                        attend_info: out_info,
                        sort_name: attend_data.out_text
                      });
                    }}>취소</button>
                </span>
              }
            </div>
          }
        </div>
      </div>
    );

    return attend_data_tag;
  };

  return (
    <div className="noto_sans">
      <div className="text-center">
        {myProps.select_date} 출석현황
      </div>
      <div className="mt-2">
        {get_attend_data_tag()}
      </div>
    </div>
  );
});
export default AttendListArea;