import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeUser, setUser } from '@/store/user/user';
import strFunc from "@/lib/lyg/string";
import { Link, useNavigate } from 'react-router-dom';
import popupFunc from "@/lib/lyg/popup";
import person_img from "@/img/ico/person_ico.png";
import DateFunc from '@/lib/lyg/date_func';
import MobileScript from "@/lib/mobile/web_script";

function MyPageComp() {
  let user = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const writeFileForm = useRef(null);
  const [able_id_data, set_able_id_data] = useState({
    is_able: true,
    origin_id: "",
  });

  useEffect(() => {

    get_iam_data_by_ajax();
    if (strFunc.is_empty(user.select_mcomp_link)) {
      alert("학원선택 필요.");
      navigate("/mobile/setting");
    }
  }, [user]);

  const [iam_info, set_iam_info] = useState<any>({
    "a_seq": "",
    "a_user_grade": "",
    "a_user_id": "",
    "a_user_pw": "",
    "a_user_name": "",
    "a_user_nickname": "",
    "a_user_phone": "",
    "profile_thum_url": "",
    "profile_url": "",
    "profile_arr": [],
  });

  const get_iam_data_by_ajax = () => {
    if (strFunc.is_empty(user.select_mcomp_link)) {
      alert("학원 선택 필요.");
      return false;
    }
    let s_iam_data_from_json = {
      "api_key": user.select_mcomp_link.comp_api_key,
      "api_user_seq": user.select_mcomp_link.a_mcomp_user_seq,
      "s_pri_arr": [user.select_mcomp_link.a_mcomp_user_seq],
      "s_addon_profile": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', s_iam_data_from_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let tmp_iam_info = response.data["data"]["info_arr"][0];
            if (tmp_iam_info["profile_arr"] && tmp_iam_info["profile_arr"].length > 0) {
              tmp_iam_info["profile_thum_url"] = tmp_iam_info["profile_arr"][0]["thum_img_url"];
              tmp_iam_info["profile_url"] = tmp_iam_info["profile_arr"][0]["img_url"];
            } else {
              tmp_iam_info["profile_thum_url"] = "";
              tmp_iam_info["profile_url"] = "";
            }
            set_iam_info({
              ...iam_info,
              ...tmp_iam_info,
              "a_user_pw": ""
            });
            set_able_id_data({
              is_able: true,
              origin_id: tmp_iam_info["a_user_id"],
            });
          } else {
            alert("조회없음...");
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goWrite = () => {

    if (iam_info.a_user_name == "") {
      alert("이름 입력이 필요합니다.");
      return false;
    } else if (iam_info.a_user_id == "") {
      alert("아이디 입력이 필요합니다.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    if (strFunc.is_empty(user.select_mcomp_link)) {
      alert("학원 선택 필요.");
      return false;
    }

    let write_form_json_data = {
      "api_key": user.select_mcomp_link.comp_api_key,
      "api_user_seq": user.select_mcomp_link.a_mcomp_user_seq,
      "is_update": "1",
      "data_arr": [iam_info],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/write', write_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          if (fileInput.current && (fileInput.current as HTMLInputElement).value != "") {
            goWriteProfileImg(iam_info);
          } else {
            refreshToken();
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const goWriteProfileImg = (user_info: any) => {
    if (writeFileForm.current == null) {
      refreshToken();
      return false;
    }
    if (strFunc.is_empty(user.select_mcomp_link)) {
      alert("학원 선택 필요.");
      return false;
    }
    var form = writeFileForm.current as HTMLFormElement;
    var form_json_data = new FormData(form);
    let file_data_row = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": user.select_mcomp_link.a_mcomp_seq,
      "a_par_id": "user",
      "a_par_seq": user.select_mcomp_link.a_mcomp_user_seq,
      "a_sort1": "profile",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user_info.a_user_name,
      "a_create_seq": user.select_mcomp_link.a_mcomp_user_seq,
    };
    form_json_data.append("api_key", user.select_mcomp_link.comp_api_key);
    form_json_data.append("api_user_seq", user.select_mcomp_link.a_mcomp_user_seq);
    form_json_data.append("data_arr", JSON.stringify([file_data_row]));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (fileInput.current) {
            (fileInput.current as HTMLInputElement).value = "";
          }
          refreshToken();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const handleChangeInput = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "a_user_phone") {
      value = strFunc.autoHypenPhone(value);
    } else if (name == "a_user_id") {
      checkUserIdByAjax(value);
    }

    set_iam_info({
      ...iam_info,
      [name]: value
    });
  }

  const refreshToken = () => {
    let formJsonData = {
      "token_id": localStorage.intoyou_token
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/login/refresh_token', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          localStorage.intoyou_token = response.data["data"]["token_id"];
          MobileScript.set_login_token(response.data["data"]["token_id"]);
          var client_info = response.data.data["client_info"];
          if(client_info.select_mcomp_link==null){
            client_info.select_mcomp_link="";
          }
          dispatch(setUser(client_info));
          alert("저장됨.");
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDeleteProFile = (fileInfo: any) => {
    if (!confirm("프로필사진을 삭제 하시겠습니까?")) {
      return false;
    }
    if (strFunc.is_empty(user.select_mcomp_link)) {
      alert("학원 선택 필요.");
      return false;
    }
    let form_json_data = {
      "api_key": user.select_mcomp_link.comp_api_key,
      "api_user_seq": user.select_mcomp_link.a_mcomp_user_seq,
      "data_arr": [fileInfo],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          refreshToken();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const checkUserIdByAjax = (user_id: string) => {
    if (strFunc.is_empty(user_id)) {
      return false;
    }
    if (strFunc.is_empty(user.select_mcomp_link)) {
      alert("학원 선택 필요.");
      return false;
    }
    let check_data_form_json = {
      "api_key": user.select_mcomp_link.comp_api_key,
      "api_user_seq": user.select_mcomp_link.a_mcomp_user_seq,
      "s_id": user_id,
      "s_except_user_seq": [user.select_mcomp_link.a_mcomp_user_seq],
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', check_data_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          let tmp_is_able_id = true;
          if (response.data["data"]["info_arr"].length > 0) {
            tmp_is_able_id = false;
          }
          set_able_id_data({
            ...able_id_data,
            is_able: tmp_is_able_id
          });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_comp_link_of_comp=()=>{
    if (strFunc.is_empty(user.select_mcomp_link)) {
      alert("학원 선택 필요.");
      return false;
    }
    if(strFunc.is_empty(user.select_mcomp_link["a_mcomp_seq"])){
      alert("학원키 없음.");
      return false;
    }
    if(strFunc.is_empty(user.select_mcomp_link["a_mcomp_user_seq"])){
      alert("학원 유저키 없음.");
      return false;
    }
    if(confirm("학원연결을 삭제하시겠습니까?") == false){
      return false;
    }
    let del_comp_link_row_data={
      a_mcomp_seq:user.select_mcomp_link["a_mcomp_seq"],
      a_mcomp_user_seq:user.select_mcomp_link["a_mcomp_user_seq"],
    };
    let del_data_form_json = {
      "api_key": user.select_mcomp_link.comp_api_key,
      "api_user_seq": user.select_mcomp_link.a_mcomp_user_seq,
      "data_arr":[del_comp_link_row_data],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user_comp_link/link/delete', del_data_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          let after_select_link_val="";
          for(let i=0;i<user.comp_link_arr.length;i++){
            let tmp_link_row=user.comp_link_arr[i];
            let tmp_link_row_val=tmp_link_row["a_mcomp_seq"]+","+tmp_link_row["a_mcomp_user_seq"];
            if(tmp_link_row_val!=user.select_mcomp_link){
              if(after_select_link_val!=""){
                after_select_link_val=tmp_link_row_val;
              }
            }
          }
          saveSelectMcompLink(after_select_link_val);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const saveSelectMcompLink = (link_pri_val: any) => {
    if (user.user_seq == "") {
      alert("로그인 필요.");
      return false;
    }
    let w_mcomp_link_form = {
      "main_user_seq": user.user_seq,
      "mcomp_linck_pri_val": link_pri_val,
    };
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/user/select_mcomp_link', 
    w_mcomp_link_form,get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          //alert(response.data["msg"]);
          refreshToken();
          setTimeout(() => {
            navigate("/mobile/setting");
          },500);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let comp_name = "";
  if (!strFunc.is_empty(user.select_mcomp_link)) {
    comp_name = user.select_mcomp_link.comp_name;
  }

  return (
    <MobileLayout>
      소속학원: (<span style={{ color: "blue" }}>{comp_name}</span>)
      <div className="view-table-div">
        <table>
          <colgroup>
            <col width={"30%"}></col>
            <col width={"70%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>사진</th>
              <td>
                <div className="text-center">
                  <a onClick={() => {
                    popupFunc.openPopup({ 'url': iam_info.profile_url, 'width': 700, 'height': 420, 'pop_name': 'name', 'auto_size': false });
                  }}>
                    <img src={iam_info["profile_thum_url"] ? iam_info["profile_thum_url"] : person_img} alt="사람" style={{ width: 100 }} />
                  </a>
                  {
                    iam_info.profile_arr.length > 0 &&
                    <button className="btn-s btn-gray" onClick={() => { goDeleteProFile(iam_info.profile_arr[0]); }}>X</button>
                  }
                  <form id="write_form" method="post" ref={writeFileForm} >
                    <input type="file" id="files" className="row-input mt-1" name="input_file[]"
                      ref={fileInput} style={{ width: 200 }} accept="image/*" onChange={(e: any) => { }} />
                  </form>
                </div>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                {iam_info.a_user_name}
              </td>
            </tr>
            {iam_info.a_user_grade != "student" &&
              <tr>
                <th>닉네임</th>
                <td>
                  <input type="text" name="a_user_nickname" value={iam_info.a_user_nickname}
                    onChange={handleChangeInput} placeholder="" autoComplete="off" />
                </td>
              </tr>
            }
            <tr>
              <th>핸드폰</th>
              <td>
                <input type="text" name="a_user_phone" value={iam_info.a_user_phone}
                  onChange={handleChangeInput} placeholder="000-0000-0000" autoComplete="off" />
              </td>
            </tr>
            <tr>
              <th>아이디</th>
              <td>
                {iam_info.a_user_grade != "student" ?
                  <div>
                    <input type="text" name="a_user_id" value={iam_info.a_user_id}
                      onChange={handleChangeInput} placeholder="아이디" autoComplete="off" style={{ width: 100 }} />
                    {able_id_data.origin_id != iam_info.a_user_id &&
                      <span className="ml-2" style={{ fontWeight: 500 }}>
                        {iam_info.a_user_id == "" && "입력필요"}
                        {iam_info.a_user_id != "" &&
                          <span>
                            {able_id_data.is_able ?
                              <span style={{ color: "blue" }}>가능</span>
                              :
                              <span style={{ color: "red" }}>이미있음</span>
                            }
                          </span>
                        }
                      </span>
                    }
                  </div>
                  :
                  iam_info.a_user_id
                }
              </td>
            </tr>
            <tr>
              <th>변경비밀번호</th>
              <td>
                <input type="password" name="a_user_pw" value={iam_info.a_user_pw}
                  onChange={handleChangeInput} placeholder="비밀번호" autoComplete="off" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-dark" onClick={() => { goWrite(); }}>저장</button>
        {iam_info.a_user_grade != "student" &&
          <button className="btn btn-red" onClick={() => { delete_comp_link_of_comp(); }}>학원연결삭제</button>
        }
        <button className="btn btn-dark" onClick={() => { navigate("/mobile/setting"); }}>뒤로</button>
      </div>
    </MobileLayout>
  );
}

export default MyPageComp;