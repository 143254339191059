import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderWrapArea from "@/pcomponents/common/content/layout/header_wrap";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from "./area/search";
import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import TabArea1 from "@/pcomponents/common/content/list/tab1";
import RoomListArea from "@/pcomponents/common/content/chat/room/room_list";

function ChatRoomList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  let user = useSelector((state: any) => state.user);
  let myState={
    has_footer: user.user_grade=="parent"?false:true,
    ...state
  };
  
  let comp_user_key_seq_arr: any = [];
  let iam_a_mcomp_seq = "";
  let iam_mcomp_user_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_a_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
    comp_user_key_seq_arr.push(iam_a_mcomp_seq + "," + iam_mcomp_user_seq);
  }

  const [pageData, setPageData] = useState({
    listOpt: {
      "s_mem_grade": "",
      "s_mem_user_key": comp_user_key_seq_arr,//s_mem_user_key
      "s_add_mem_arr": "1",
      "s_add_title_by_mem_arr": "1",
      "s_add_title_except_user_seq": iam_mcomp_user_seq,
      "s_add_unread_tot": "1",
      "s_add_last_msg": "1",
      "s_add_unread_user_key": comp_user_key_seq_arr,
      "s_addon_mcomp_title": "1",
      "order_id": "a_update_date DESC",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    }
  });

  const [infoArr, setInfoArr] = useState([]);
  const select_user_grade_arr = [
    { value: "", text: "전체" },
    { value: "student", text: "학생" },
    { value: "parent", text: "학부모" },
  ];

  useEffect(() => {
    list({});
  }, [user]);


  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    let tmp_list_opt: any = { ...pageData.listOpt };
    tmp_list_opt.s_mem_user_key = comp_user_key_seq_arr;
    tmp_list_opt.s_add_unread_user_key = comp_user_key_seq_arr;
    tmp_list_opt.s_add_title_except_user_seq = iam_mcomp_user_seq;
    if (tmp_list_opt.s_mem_user_key.length == 0) {
      setInfoArr([]);
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/chat/room/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const onClickView = (inData: any) => {
    let opt_obj = {
      row_data: {},
      row_num: 0,
      ...inData
    };

    navigate('/mobile/chat/chat_view',
      { state: { room_info: inData.row_data } });
  };

  let is_teacher_grade = false;
  if (strFunc.str_in_array(user.user_grade, ["teacher", "master", "staff"]) != -1) {
    is_teacher_grade = true;
  }

  return (
    <MobileLayout
      has_header={false}
      has_footer={myState.has_footer}
      container_style={{ marginTop: is_teacher_grade?100:66 }}>
      <HeaderWrapArea>
        <SearchArea
          listOpt={pageData.listOpt}
          list={list}
          onclick_add_room={() => {
            navigate("/mobile/chat/chat_room_write");
          }}
          on_click_title={()=>{
            navigate("/mobile");
          }}
        ></SearchArea>
        {is_teacher_grade &&
          <TabArea1
            tab_data_arr={select_user_grade_arr} selected_tab={pageData.listOpt.s_mem_grade}
            set_selected_tab={(inData: any) => { list({ "now_page": "1", "s_mem_grade": inData }); }}
          ></TabArea1>
        }
      </HeaderWrapArea>
      <div style={{ padding: "0 15px" }}>
        <RoomListArea
          infoArr={infoArr}
          onClickView={onClickView}
        ></RoomListArea>
      </div>
    </MobileLayout>
  );
}

export default ChatRoomList;