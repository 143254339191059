import { useState,useRef,useEffect } from 'react';
import Style from "./css/style.module.css";
import plantalk_ico from "@/img/mobile/main/plantalk_brown.png";
import { useNavigate } from 'react-router-dom';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';

function HomeWorkBoxArea(props:any){
  let myProps={
    ...props
  };
  const navigate = useNavigate();
  let user=useSelector((state:any) => state.user);
  const [todo_arr,set_todo_arr]=useState([]);

  useEffect(()=>{
    
    get_todo_arr_by_ajax();
  },[user]);

  const get_todo_arr_by_ajax = ()=>{
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }

    let select_mcomp_link_val="";
    let s_stu_mcomp_seq="";
    if (!strFunc.is_empty(user.select_mcomp_link)) {
      select_mcomp_link_val=user.select_mcomp_link.a_mcomp_seq+","+user.select_mcomp_link.a_mcomp_user_seq;
      s_stu_mcomp_seq=user.select_mcomp_link.a_mcomp_seq;
    }
    if(strFunc.is_empty(select_mcomp_link_val)){
      return false;
    }

    let list_form_json_data={
      //"s_stu_seq":user.user_seq,
      "s_user_key":select_mcomp_link_val,
      // "s_todo_sort":"homework",
      's_date_type':'a_reserve_start_date',
      's_start_date':DateFunc.get_date_format(new Date(),"Y-m-d"),
      's_end_date':DateFunc.get_date_format(new Date(),"Y-m-d"),
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
      "max_limit_num":"4",
      "order_id":"a_create_date DESC",
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/todo/todo/list',list_form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_todo_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const get_home_work_tags=()=>{
    let home_work_tags=todo_arr.map((item:any,idx:number)=>{
      return (
        <div key={idx} className={Style.home_work_list_row}>
          <div className={Style.home_work_list_row_front_text}>
            양분+10
          </div>
          <span className="noto_sans">
            {strFunc.cut_str(item.a_title,8)}
          </span>
        </div>
      );
    });
    return home_work_tags;
  };

  return (
    <div className={Style.home_work_wrap}>
      <div className={Style.home_work_title_div+" noto_sans"}>
        <img src={plantalk_ico} style={{width:20,position:"absolute",left:5,top:5}}/>
        {todo_arr.length>0
        ?
        <>새로운 플랜이 도착했어요.</>
        :
        <>플랜이 없습니다.</>}
        <span className={Style.home_work_title_more} onClick={()=>{navigate("/mobile/home_work");}}>더보기</span>
      </div>
      {todo_arr.length>0&&
      <div className={Style.home_work_list_wrap}>
        {get_home_work_tags()}
      </div>
      }
    </div>
  );
}
export default HomeWorkBoxArea;