import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import RadioListArea from "@/pcomponents/common/crud/write/radio/radio_arr";
import DateFunc from '@/lib/lyg/date_func';

function TStuTodoViewArea(props: any) {
  let myProps = {
    row_data: {},
    refresh_data: (inData:any) => { },
    ...props
  };
  let row_data = myProps.row_data;
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const save_stu_todo_by_ajax = (inData:any) => {
    let opt_obj={
      change_row_data:{},
      ...inData
    };
    let change_row_data={
      a_ymd: row_data.a_ymd,
      a_seq: row_data.a_seq,
      ...opt_obj.change_row_data,
    };
    let form_json_data = {
      data_arr:[change_row_data],
      is_update:"1"
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.refresh_data({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  
  return (
    <div>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width="30%"></col>
            <col width="70%"></col>
          </colgroup>
          <tbody>
            <tr>
              <th>완료</th>
              <td>
                <RadioListArea
                  valueTextArr={[
                    { value: "1", text: "완료" },
                    { value: "", text: "미완료" }
                  ]}
                  value={row_data.a_is_success}
                  handleInputChange={(e: any) => {
                    let confirm_msg="완료 처리 하시겠습니까?";
                    if(e.target.value==""){
                      confirm_msg="미완료 처리 하시겠습니까?";
                    }
                    let change_row_data:any={
                      a_is_success:"",
                    };
                    if(e.target.value=="1"){
                      change_row_data["a_is_success"]="1";
                      change_row_data["a_success_date"]=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
                      change_row_data["a_success_mcomp_user_seq"]=iam_mcomp_user_seq;
                      change_row_data["a_success_main_user_seq"]=user.user_seq;
                      change_row_data["a_success_user_name"]=user.user_name;
                    }
                    if(confirm(confirm_msg)){
                      save_stu_todo_by_ajax({
                        change_row_data:change_row_data
                      });
                    }
                  }}
                ></RadioListArea>
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td>{row_data["a_title"]}</td>
            </tr>
            <tr>
              <th>메모</th>
              <td>{row_data["a_memo"]}</td>
            </tr>
            <tr>
              <th>학생</th>
              <td>{row_data["a_stu_name"]}</td>
            </tr>
            <tr>
              <th>선생님</th>
              <td>{row_data["a_owner_name"]}</td>
            </tr>
            <tr>
              <th>완료자</th>
              <td>{row_data["a_success_user_name"]}</td>
            </tr>
            <tr>
              <th>예정일</th>
              <td>{row_data["a_reserve_date"]}</td>
            </tr>
            <tr>
              <th>완료일</th>
              <td>{row_data["a_success_date"]}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TStuTodoViewArea;