import strFunc from "@/lib/lyg/string";
import { default_daily_report_last_info } from "@/pcomponents/common/content/edu/daily_report_last/data/default_data";
import Style from "./css/style.module.css";

function DailyLastArea(props: any) {
  let myProps = {
    last_daily_report: {},
    ...props
  };
  let last_daily_report = {
    ...default_daily_report_last_info,
    ...myProps.last_daily_report
  };

  let a_textbook_study_num="";
  if(!strFunc.is_empty(last_daily_report["a_textbook_study_num"])){
    a_textbook_study_num=last_daily_report["a_textbook_study_num"]+".";
  }

  let a_textbook_study_det_num="";
  if(!strFunc.is_empty(last_daily_report["a_textbook_study_det_num"])){
    a_textbook_study_det_num=last_daily_report["a_textbook_study_det_num"]+".";
  }

  return (
    <div className={Style.daily_wrap}>
      <div className={Style.daily_row_wrap}>
        <div className={Style.daily_row_item}>
          <div className={Style.item_title}>본</div>
          <input type="text" readOnly className={Style.input_view}
            value={last_daily_report["a_textbook"]} />
        </div>
        <div className={Style.daily_row_item}>
          <div className={Style.item_title}>대</div>
          <input type="text" readOnly className={Style.input_view}
            value={a_textbook_study_num+last_daily_report["a_textbook_study"]} />
        </div>
        <div className={Style.daily_row_item}>
          <div className={Style.item_title}>숙</div>
          <input type="text" readOnly className={Style.input_view}
            value={last_daily_report["a_homework"]} />
        </div>
      </div>
      <div className={Style.daily_row_wrap}>
        <div className={Style.daily_row_item}>
          <div className={Style.item_title}>부</div>
          <input type="text" readOnly className={Style.input_view}
            value={last_daily_report["a_subtextbook"]} />
        </div>
        <div className={Style.daily_row_item}>
          <div className={Style.item_title}>소</div>
          <input type="text" readOnly className={Style.input_view}
            value={a_textbook_study_det_num+last_daily_report["a_textbook_study_det"]} />
        </div>
        <div className={Style.daily_row_item}>
          <div className={Style.item_title}>M</div>
          <input type="text" readOnly className={Style.input_view}
            value={last_daily_report["a_memo"]} />
        </div>
      </div>
    </div>
  );
};
export default DailyLastArea;