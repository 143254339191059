import Style from "./style/style.module.css";
import ListSortTab from "./list_sort";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function TitleBarArea(props: any) {
  let myProps = {
    title: "맞춤플랜",
    is_show_list_sort: true,
    list_sort: "card",//card,list
    set_list_sort: (inData: any) => { },
    ...props
  };

  return (
    <div className={Style.bar_wrap + " noto_sans"}>
      {myProps.title}
      <span className={Style.bar_right_span}>
        {myProps.is_show_list_sort &&
          <SelectBoxBar
            valueTextArr={[
              { value: "card", text: "카드" },
              { value: "list", text: "리스트" }
            ]}
            value={myProps.list_sort}
            name="list_sort"
            on_change={(inData: any) => {
              myProps.set_list_sort(inData.value);
            }}
          ></SelectBoxBar>
        }
      </span>
    </div>
  );
};
export default TitleBarArea;