import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import CounselTodoListArea from "@/pages/mobile/manage/counsel_todo/common/list";

function ManageCounselTodoListPage(){

  return (
  <MobileLayout 
    has_header={false}
    container_con_style={{ width: "100%" }}>
      <HeaderSimple title={"상담관리"}></HeaderSimple>
      <CounselTodoListArea></CounselTodoListArea>
  </MobileLayout>
  );
};
export default ManageCounselTodoListPage;