import GamePage from "@/pages/mobile/playground/math/common/page";

function MathMinusBasicPage() {
  let game_data={
    sort: "minus",
    level: 1,
    matter_total:10
  };
  return (
    <GamePage
      game_data={game_data}
      title="빼기"
      back_url="/mobile/playground"
    ></GamePage>
  );
};
export default MathMinusBasicPage;