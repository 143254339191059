import Character from "./char";
import { useState,useRef, forwardRef, useImperativeHandle } from 'react';

const CenterCharacter = forwardRef((props: any, ref) => {
  let myProps = {
    Style: {},
    unread_data: {
      chat: 0,
      home_work: 0,
      plan: 0,
      sche: 0,
    },
    char_user_info:null,
    ...props
  };
  let Style = myProps.Style;
  let win_w = (window.innerWidth || document.documentElement.clientWidth);
  let center_x = (win_w / 2) - (80 / 2);

  const unread_data = myProps.unread_data;

  const CharacterRef=useRef<any>(null);

  const show_char_talk_msg = (inData: any) => {
    if(CharacterRef.current){
      CharacterRef.current.show_char_talk_msg(inData);
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    show_char_talk_msg
  }));

  return (
    <div className={Style.center_char_wrap}>
      <div className={Style.center_char_con}>
        <Character
          ref={CharacterRef}
          Style={Style}
          x={center_x}
          unread_data={unread_data}
          char_user_info={myProps.char_user_info}
        ></Character>
      </div>
    </div>
  );
});
export default CenterCharacter;