import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useSelector } from 'react-redux';
import StuOneWeekTimeTable from "@/pcomponents/common/content/edu/lecture/time_table/stu_one_week";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

function TimeTableStuWeekPage() {
  let user = useSelector((state: any) => state.user);
  let iam_user_data=UserFunc.get_iam_mcomp_data({user:user});

  let user_data = {
    iam_mcomp_seq: iam_user_data.iam_mcomp_seq,
    iam_mcomp_user_seq: iam_user_data.iam_mcomp_user_seq,
    comp_api_key: iam_user_data.comp_api_key,
  };

  return (
    <MobileLayout container_con_style={{ width: "100%" }}>
      {user_data.iam_mcomp_user_seq &&
        <StuOneWeekTimeTable
          user_data={user_data}
          stu_seq={user_data.iam_mcomp_user_seq}
          is_show_time_split={true}
        ></StuOneWeekTimeTable>
      }
    </MobileLayout>
  );
};
export default TimeTableStuWeekPage;