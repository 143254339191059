function StateBoxArea(props:any){
   let myProps={
    Style:{},
    title:"출석",
    percent:0,
    ...props
  };
  let Style=myProps.Style;
  
  return (
  <div className={Style.study_state_box}>
    <div className={Style.study_state_box_title_wrap}>
      {myProps.title}
    </div>
    <div className={Style.study_state_percent_wrap}>
      <table style={{width:"100%"}}>
        <colgroup>
          <col width={"90%"}></col>
          <col width={"10%"}></col>
        </colgroup>
        <tbody>
          <tr>
            <td>
              <div className={Style.study_state_percent_bar_back}>
                <div className={Style.study_state_percent_bar} style={{width:myProps.percent+"%"}}></div>
              </div>
            </td>
            <td className="text-center">
              {myProps.percent}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
};
export default StateBoxArea;