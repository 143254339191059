import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get_char_img } from "@/pcomponents/common/content/char/data/char_data";
import CharNameArea from "./area/name_area";

function ViewCharPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback: () => { },
    char_user_info: {},
    is_select_char: false,
    ...props
  };
  let char_user_info = myProps.char_user_info;
  let is_select_char = myProps.is_select_char;
  let user = useSelector((state: any) => state.user);
  const [char_opt, set_char_opt] = useState({
    a_gold: "0",
    a_select_char_seq: "0",
  });

  useEffect(() => {
    get_char_opt_by_ajax();
  }, [user]);

  const get_char_opt_by_ajax = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }

    let form_json_data = {
      "s_user_seq": user.user_seq,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_char_opt(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  const go_select_char_user_info_by_ajax = () => {
    if (strFunc.is_empty(char_user_info["a_main_user_seq"])) {
      alert("로그인 회원키 없음.");
      return false;
    }
    if (strFunc.is_empty(char_user_info["a_seq"])) {
      alert("씨앗 키 없음.");
      return false;
    }

    if (!confirm("선택 하시겠습니까?")) {
      return false;
    }

    let up_char_opt_row = {
      "a_main_user_seq": char_user_info["a_main_user_seq"],
      "a_select_char_seq": char_user_info["a_seq"],
    };
    let form_json_data = {
      "data_arr": [up_char_opt_row],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          myProps.callback();
          myProps.closePopup();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let img_src = get_char_img({ "id": char_user_info["a_sort_id"] });

  return (
    <div style={{ width: "100%", height: "100%", background: "#D3DB90", position: "relative" }} >
      <div className="text-center" style={{ height: "40px", lineHeight: "40px", background: "#E4EFE9" }} >
        <span style={{ color: "#7A9E1D" }}>씨앗 보기</span>
        <button className="btn btn-gray" onClick={myProps.closePopup}
          style={{ position: "absolute", right: 5, top: 5, background: "#7A9E1D" }}
        >X</button>
      </div>
      <div className="text-center mt-2" >
        <h5>씨앗점수:
          <span className="ml-1" style={{ color: "blue" }}>{char_opt.a_gold}</span>
        </h5>
      </div>
      <div className="text-center" style={{ padding: 20 }}>
        <div>
          <img src={img_src} style={{ height: "200px" }} />
        </div>
        <CharNameArea
          char_user_info={char_user_info}
          char_opt={char_opt}
          user={user}
          refresh_data={()=>{
            get_char_opt_by_ajax();
            myProps.callback();
          }}
        ></CharNameArea>
      <div className="mt-2" >
        레벨 {char_user_info["a_level"]}
      </div>
      <div className="mt-2">
        {is_select_char ?
          <span style={{ "color": "blue" }}>선택됨</span>
          :
          <span style={{ "color": "gray" }}>미선택</span>
        }
      </div>
      <div className="mt-2" >
        {is_select_char == false &&
          <button className="btn btn-gray"
            style={{ background: "green" }}
            onClick={go_select_char_user_info_by_ajax}>선택</button>
        }
        <button className="btn btn-gray ml-2" onClick={myProps.closePopup}>닫기</button>
      </div>
    </div>
    </div >
  );
};
export default ViewCharPopup;