//import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDark } from '@/store/dark/dark';
import Header from "./header";
import Footer from "./footer";
import FooterManage from "./footer_manage";
import CheckLoginComponent from '@/pcomponents/mobile/check/login_check';
import MobileScript from "@/lib/mobile/web_script";
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    callBackOnBackPressedApp():void;
  }
}
const MobileLayout=(props:any)=>{
  let myProps={
    children:"",
    has_header:true,
    has_footer:true,
    is_home_body:false,
    is_check_login:true,
    isGoToMainAtLogin:false,
    container_con_style:{},
    container_style:{},
    ...props
  };
  const dispatch = useDispatch();
  let user = useSelector((state: any) => state.user);
  let dark=useSelector((state:any) => state.dark);
  const navigate = useNavigate();

  useEffect(()=>{
    setDarkClass();
    window.callBackOnBackPressedApp=()=>{
      if("/mobile/login"==window.location.pathname||"/mobile"==window.location.pathname){
        MobileScript.finishApp();
      }else{
        if(window.location.pathname!="/mobile"){
          navigate("/mobile");
        }
      }
    };
  },[dark]);

  const setDarkClass=()=>{
    if(dark.dark==""){
      if(localStorage.theme === 'dark' ){
        dispatch(setDark("dark"));
      }else{
        dispatch(setDark("light"));
      }
    }else if(dark.dark=="dark"){

    }else if(dark.dark=="light"){

    }
  };

  let home_body_class="";
  if(myProps.is_home_body){
    home_body_class=" is_home_body";
  }

  //내용스타일
  let container_style={};
  if(myProps.has_header==false){
    container_style={
      ...container_style,
      marginTop: 0,
    };
  }

  if(myProps.has_footer==false){
    container_style={
      ...container_style,
      paddingBottom: 0,
    };
  }

  container_style={
    ...container_style,
    ...myProps.container_style
  };

  return (
    <div id="main_wrap" className={dark.dark} >
      <div className={"m_main_body"+home_body_class}>
        {/* IOS상단여백가리기 */}
        <div className="m_main_body_top_empty"></div>
        {myProps.is_check_login&&
          <CheckLoginComponent isGoToMainAtLogin={myProps.isGoToMainAtLogin}></CheckLoginComponent>
        }
        {myProps.has_header&&
          <Header is_home_body={myProps.is_home_body}
            ></Header>
        }
        <div id="m_container" style={container_style}>
          <div className="m_container_con" style={myProps.container_con_style}>
            {myProps.children}
          </div>
        </div>
        {myProps.has_footer&&
          <Footer is_home_body={myProps.is_home_body}
            user={user}
            ></Footer>
        }
      </div>
    </div>
  );
};

export default MobileLayout;