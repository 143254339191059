import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import default_row_info from "./data/data";
import ReportWriteComponent from "../component/write";

function ReportWriteArea(props: any) {
  let myProps = {
    stu_info: {},
    select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    set_is_show_write: (inData: any) => { },
    list: (inData: any) => { },
    set_write_info: (inData: any) => { },
    Style: {},
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  let tmp_default_row_info={
    ...default_row_info,
    a_ymd: strFunc.str_replace("-", "", myProps.select_date),
    a_date: myProps.select_date,
    a_stu_seq: myProps["stu_info"]["a_seq"],
    a_stu_name: myProps["stu_info"]["a_user_name"],
    a_writer_seq: iam_mcomp_user_seq,
    a_writer: user.user_name,
  };
  if(myProps.stu_info.last_prescribe_arr&&myProps.stu_info.last_prescribe_arr.length>0){
    let last_study_prescribe=myProps.stu_info.last_prescribe_arr[0];
    tmp_default_row_info["a_subject"]=last_study_prescribe["a_subject"];
    tmp_default_row_info["a_progress_main"]=last_study_prescribe["a_progress_main"];
    tmp_default_row_info["a_progress_sub"]=last_study_prescribe["a_progress_sub"];
    tmp_default_row_info["a_exam_main_num"]=last_study_prescribe["a_exam_main_num"];
    tmp_default_row_info["a_homework"]=last_study_prescribe["a_homework"];
    tmp_default_row_info["a_comment"]=last_study_prescribe["a_comment"];
  }
  const [row_data, set_row_data] = useState(tmp_default_row_info);
  const [refresh, set_refresh] = useState(false);

  useEffect(() => {
    let init_row_data={
      ...row_data,
      a_date:myProps.select_date,
    };
    set_row_data(init_row_data);
    myProps.set_write_info(init_row_data);
  }, [myProps.select_date]);
  useEffect(() => {
    
  }, []);

  const goWrite = () => {
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }
    let tmp_row_data = row_data;

    if(strFunc.is_empty(tmp_row_data["a_seq"])){
      tmp_row_data["a_ymd"]=strFunc.str_replace("-","",tmp_row_data["a_date"]);
    }
    if(strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원 선택 필요.");
      return false;
    }
    let w_report_form_json:any = {
      "data_arr": [tmp_row_data],
      "is_default_val": "1",
    };
    w_report_form_json["api_key"]=comp_api_key;
    w_report_form_json["api_user_seq"]=iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/write', w_report_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.set_is_show_write(false);
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div>
      <ReportWriteComponent
        row_data={row_data}
        set_row_data={(inData: any) => {
          set_row_data(inData);
          myProps.set_write_info(inData);
          set_refresh(!refresh);
        }}
        manage_area={(
          <div className="text-center">
            <button className="btn-s btn-sky" onClick={() => { goWrite(); }} >등록</button>
          </div>
        )}
      ></ReportWriteComponent>
    </div>
  );
};
export default ReportWriteArea;