//import UserGroupDataFunc from "@/pcomponents/common/content/list/tab1/func/group_data";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";

class UserGroupDataFunc {
  static get_cardbox_group_data_arr(inData:any) {
    let opt_obj={
      select_date:"",
      info_arr:[],
      tab_id:"",
      ...inData,
    };
    let select_date=opt_obj["select_date"];
    if(strFunc.is_empty(select_date)){
      select_date=DateFunc.get_date_format(new Date(),"Y-m-d");
    }
    let tab_id=opt_obj["tab_id"];
    let info_arr=opt_obj["info_arr"];
    let group_data_arr: any = [];//[{title:"","info_arr":[]}]
    if (tab_id == "") {
      group_data_arr.push({
        "title": "기타", "info_arr": info_arr,
      });
    } else if (tab_id == "class") {
      group_data_arr = this.get_class_group_arr(info_arr);
    } else if (tab_id == "stu_grade") {
      for (let i = 0; i < info_arr.length; i++) {
        let info = info_arr[i];
        let stu_grade = "기타";
        if (!strFunc.is_empty(info["a_stu_grade"])) {
          stu_grade = info["a_stu_grade"];
        }
        let group_row_idx = this.get_group_arr_idx_of_title(stu_grade, group_data_arr);
        if (group_row_idx == -1) {
          group_data_arr.push({
            "title": stu_grade,
            "info_arr": [info],
          });
        } else {
          group_data_arr[group_row_idx]["info_arr"].push(info);
        }
      }
    } else if (tab_id == "school") {
      for (let i = 0; i < info_arr.length; i++) {
        let info = info_arr[i];
        let school_name = "기타";
        if (!strFunc.is_empty(info["a_school_name"])) {
          school_name = info["a_school_name"];
        }
        let group_row_idx = this.get_group_arr_idx_of_title(school_name, group_data_arr);
        if (group_row_idx == -1) {
          group_data_arr.push({
            "title": school_name,
            "info_arr": [info],
          });
        } else {
          group_data_arr[group_row_idx]["info_arr"].push(info);
        }
      }
    } else if (tab_id == "lecture") {
      group_data_arr = this.get_lecture_time_group_arr(info_arr,select_date);
    }

    group_data_arr = this.get_ordered_by_title_name(group_data_arr);

    return group_data_arr;
  };
  static get_class_group_arr(info_arr: any) {
    let group_arr: any = [];
    let class_group_arr: any = {};//{"class_seq":{"class_info":null,"info_arr":[]}}
    let info_arr_len = info_arr.length;
    let no_class_seq_arr = [];
    for (let i = 0; i < info_arr_len; i++) {
      let info = info_arr[i];
      if (info.class_arr && info.class_arr.length > 0) {
        let class_info = info.class_arr[0];
        if (class_info.class_info) {
          class_info = class_info.class_info;
          if (class_group_arr[class_info["a_seq"]] == undefined) {
            class_group_arr[class_info["a_seq"]] = {
              "class_info": class_info,
              "info_arr": [],
            };
          }
          class_group_arr[class_info["a_seq"]]["info_arr"].push(info);
        } else {
          no_class_seq_arr.push(info["a_seq"]);
        }
      } else {
        no_class_seq_arr.push(info["a_seq"]);
      }
    }

    for (let key in class_group_arr) {
      let class_group = class_group_arr[key];
      group_arr.push({
        "title": class_group["class_info"]["a_name"],
        "info_arr": class_group["info_arr"],
      });
    }
    if (no_class_seq_arr.length > 0) {
      let no_class_info_arr = [];
      for (let i = 0; i < info_arr_len; i++) {
        let info = info_arr[i];
        if (strFunc.str_in_array(info["a_seq"], no_class_seq_arr) != -1) {
          no_class_info_arr.push(info);
        }
      }
      group_arr.push({
        "title": "기타",
        "info_arr": no_class_info_arr,
      });
    }

    return group_arr;
  };

  static get_group_arr_idx_of_title(title: string, group_arr: []) {
    for (let i = 0; i < group_arr.length; i++) {
      if (group_arr[i]["title"] == title) {
        return i;
      }
    }
    return -1;
  }

  static get_ordered_by_title_name(group_data_arr: any) {
    let title_str_arr = [];
    for (let i = 0; i < group_data_arr.length; i++) {
      if (group_data_arr[i]["title"] != "기타") {
        title_str_arr.push(group_data_arr[i]["title"]);
      }
    }
    title_str_arr.push("기타");

    title_str_arr.sort();

    let new_group_data_arr = [];
    for (let i = 0; i < title_str_arr.length; i++) {
      for (let j = 0; j < group_data_arr.length; j++) {
        if (group_data_arr[j]["title"] == title_str_arr[i]) {
          new_group_data_arr.push(group_data_arr[j]);
        }
      }
    }
    return new_group_data_arr;
  };

  static get_lecture_time_group_arr(info_arr: any,select_date:string) {
    let group_arr: any = [];//[{"title":"","info_arr":[]}]
    let info_arr_len = info_arr.length;
    let now_d_json = DateFunc.get_date_json(new Date(select_date));

    let lecture_time_group_arr: any = {};//{time_str:{title:"",time_str:"",info_arr:[]}}
    for (let i = 0; i < info_arr_len; i++) {
      let info = info_arr[i];
      let key_str = "etc";
      let is_etc_row = false;
      if (info["lecture_arr"] && info["lecture_arr"].length > 0) {
        for (let lec_i = 0; lec_i < info["lecture_arr"].length; lec_i++) {
          let lecture_info = info["lecture_arr"][lec_i];
          if (lecture_info["stu_union_time_arr"] && lecture_info["stu_union_time_arr"].length > 0) {
            for (let time_i = 0; time_i < lecture_info["stu_union_time_arr"].length; time_i++) {
              let union_time = lecture_info["stu_union_time_arr"][time_i];

              if (union_time["a_stu_seq"] == info["a_seq"]) {
                if (union_time["a_day_num"] == now_d_json["day"]) {
                  let start_time_str=strFunc.str_pad({ "str": union_time["a_start_h"], "pad_length": 2 }) + ":" + strFunc.str_pad({ "str": union_time["a_start_m"], "pad_length": 2 });
                  let end_time_str=strFunc.str_pad({ "str": union_time["a_end_h"], "pad_length": 2 }) + ":" + strFunc.str_pad({ "str": union_time["a_end_m"], "pad_length": 2 });
                  let time_str = start_time_str+"~"+end_time_str;
                  key_str = time_str + "," + lecture_info["a_seq"];
                  let title_str = time_str + " " + lecture_info["a_name"];
                  if (lecture_time_group_arr[key_str] == undefined) {
                    lecture_time_group_arr[key_str] = {
                      title: title_str,
                      time_str: time_str,
                      start_time_str:start_time_str,
                      end_time_str:end_time_str,
                      lecture_info:lecture_info,
                      info_arr: [],
                    };
                  }

                  lecture_time_group_arr[key_str]["info_arr"].push(info);
                } else {
                  is_etc_row = true;
                }
              }
            }
          } else {
            is_etc_row = true;
          }
        }
      } else {
        is_etc_row = true;
      }
      if (is_etc_row) {
        key_str="etc";
        if (lecture_time_group_arr[key_str] == undefined) {
          lecture_time_group_arr[key_str] = {
            title: "기타",
            time_str: key_str,
            lecture_info:null,
            info_arr: [],
          };
        }
        //이미있는 유저인지 확인
        let tmp_is_exist_pre=false;
        for(var key in lecture_time_group_arr){
          for(let i=0;i<lecture_time_group_arr[key]["info_arr"].length;i++){
            if(lecture_time_group_arr[key]["info_arr"][i]["a_seq"]==info["a_seq"]){
              tmp_is_exist_pre=true;
            }
          }
        }
        if(tmp_is_exist_pre==false){
          lecture_time_group_arr[key_str]["info_arr"].push(info);
        }
      }
    }

    for (let key in lecture_time_group_arr) {
      let group_info = lecture_time_group_arr[key];
      group_arr.push({
        "title": group_info["title"],
        "time_str": group_info["time_str"],
        "start_time_str":group_info["start_time_str"],
        "end_time_str":group_info["end_time_str"],
        "info_arr": group_info["info_arr"],
        "lecture_info":group_info["lecture_info"],
      });
    }

    return group_arr;
  };
}
export default UserGroupDataFunc;