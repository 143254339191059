import { useSelector } from 'react-redux';
import { useEffect,useState } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import CheckAppVersionComponent from "@/pcomponents/mobile/check/version_check";
import checkScriptVersion from "@/pcomponents/mobile/check/version_check_script";
import MobileScript from "@/lib/mobile/web_script";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useNavigate } from 'react-router-dom';
import love_ico_50_img from "@/img/mobile/char/love_ico_50.png";
import CheckLoginComponent from "@/pcomponents/mobile/check/login_check";
import StuArea from "./area/stu_area";
import ParentArea from "./area/par_area";
import ManageArea from "./area/manage_area";

declare global {
  interface Window {
    get_fcm_token_callback(value:string):void;
  }
}

function Main() {
  let user = useSelector((state: any) => state.user);
  useEffect(() => {
    savePushToken();
    window.get_fcm_token_callback=(push_token)=>{
      //alert("PushToekn:"+push_token);
      savePushTokenCallBack(push_token);
    };
    checkScriptVersion();
  }, [user.user_seq]);

  const navigate = useNavigate();
  let user_grade="";
  if(user.select_mcomp_link&&user.select_mcomp_link["a_user_grade"]){
    user_grade=user.select_mcomp_link["a_user_grade"];//student,parent,manager
  }

  const savePushToken = () => {
    let fcm_token = MobileScript.get_fcm_token();
    let app_sort = MobileScript.checkMobileApp();
    if (strFunc.is_empty(fcm_token)) {
      return false;
    }
    let tmp_now_ymdh = DateFunc.get_date_format(new Date(), "Ymdh");
    if (localStorage.token_update_date_last == tmp_now_ymdh) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/user/save_token', {
      toekn: fcm_token,
      app_sort: app_sort,
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        //시간당 한번씩만 하도록 체크
        localStorage.setItem('token_update_date_last', tmp_now_ymdh);
      }
    });
  };
  const savePushTokenCallBack = (push_token:string) => {
    let fcm_token = push_token;
    let app_sort = MobileScript.checkMobileApp();
    if (strFunc.is_empty(fcm_token)) {
      return false;
    }
    let tmp_now_ymdh = DateFunc.get_date_format(new Date(), "Ymdh");
    if (localStorage.token_update_date_last2 == tmp_now_ymdh) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/user/save_token', {
      toekn: fcm_token,
      app_sort: app_sort,
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        //시간당 한번씩만 하도록 체크
        localStorage.setItem('token_update_date_last2', tmp_now_ymdh);
      }
    });
  };
  
  const get_main_tag=()=>{
    let main_tag:any="";
    if(strFunc.is_empty(user.user_seq)){
      main_tag=(
        <div>
          <div className="text-center mt-10">
            <span style={{color:"green",fontWeight:"bold"}}>로딩중..</span>
          </div>
          <div className="text-center" style={{position:"fixed",bottom:10,width:"100%"}}>
            <button className="btn btn-sky" onClick={()=>{navigate("/mobile");}}>홈</button>
            <button className="btn btn-sky ml-1" onClick={()=>{navigate("/mobile/setting");}}>설정</button>
          </div>
        </div>
      );
      // return main_tag;
    }
    if(user_grade=="student"){
      main_tag=(
        <StuArea></StuArea>
      );
    }else if(user_grade=="parent"){
      main_tag=(
        <ParentArea></ParentArea>
      );
    }else if(user_grade=="master"||user_grade=="teacher"||user_grade=="super"){
      main_tag=(
        <ManageArea></ManageArea>
      );
    }else{
      main_tag=(
        <StuArea></StuArea>
      );
    }
    return main_tag;
  };

  return (
    <>
      <CheckAppVersionComponent></CheckAppVersionComponent>
      <CheckLoginComponent></CheckLoginComponent>
      {get_main_tag()}
    </>
  );
}

export default Main;