import Style from "./css/style.module.css";
import { useRef, forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from "@/lib/lyg/string";
import no_img_ico from "@/img/ico/no_img_ico.png";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

const FileFormArea = forwardRef((props: any, ref) => {
  let myProps = {
    select_mcomp_seq: "",
    row_data: {},
    set_row_data: (inData: any) => { },
    callback: (inData: any) => { },
    ...props
  };
  let row_data = myProps.row_data;
  const writeFileForm = useRef<any>(null);
  const fileInput = useRef<any>(null);
  let default_file_input_data = {
    "url": no_img_ico,
    "name": "",
  };
  const [file_input_data, set_file_input_data] = useState(default_file_input_data);
  let file_arr: any = [];
  if (row_data.file_arr && row_data.file_arr.length > 0) {
    file_arr = row_data.file_arr;
  }

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data=UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq:myProps.select_mcomp_seq
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  useEffect(() => {
    
  }, []);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    get_file_input, get_file_form,
    goWrite
  }));

  const get_file_input = () => {
    return fileInput.current;
  };

  const get_file_form = () => {
    return writeFileForm.current;
  };

  const goWrite = (inData: any) => {
    let opt_obj = {
      file_row_data: {},
      ...inData
    };
    let default_file_row_data = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": iam_mcomp_seq,
      "a_par_id": "counsel_todo",
      "a_par_seq": "",
      "a_sort1": "",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": iam_mcomp_user_seq,
    };
    let file_row_data = {
      ...default_file_row_data,
      ...opt_obj.file_row_data
    };
    if (file_row_data.a_par_seq == "" || file_row_data.a_par_id == "") {
      alert("파일 부모 정보가 올바르지 않습니다.");
      return false;
    }

    if (fileInput.current == null) {
      return false;
    }
    if (writeFileForm.current == null) {
      return false;
    }

    if (fileInput.current.value == "") {
      alert("파일 선택이 필요합니다.");
      return false;
    }

    let file_row_arr: any = [];
    for (let i = 0; i < fileInput.current.files.length; i++) {
      file_row_arr.push({
        ...file_row_data
      });
    }

    var form = writeFileForm.current;
    var form_json_data = new FormData(form);
    form_json_data.append("data_arr", JSON.stringify(file_row_arr));
    form_json_data.append("authorization", localStorage.intoyou_token);

    my_axios.post(process.env.REACT_APP_FILE_SERVER_URL + '/api/comp/basic/file/file/receive', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.callback(response.data["data"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDelete = (file_info: any) => {
    if (strFunc.is_empty(file_info)) {
      alert("선택없음.");
      return false;
    }
    let tmp_delete_row = {
      "a_ymd": file_info["a_ymd"],
      "a_seq": file_info["a_seq"],
    };
    if (!confirm("파일을 삭제하시겠습니까?")) {
      return false;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/delete', {
      data_arr: [tmp_delete_row],
    },get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert("삭제되었습니다.");
        //파일안보이게
        let new_file_arr = [];
        for (let i = 0; i < row_data.file_arr.length; i++) {
          let tmp_info = row_data.file_arr[i];
          if (file_info["a_ymd"] == tmp_info["a_ymd"]
            && file_info["a_seq"] == tmp_info["a_seq"]) {

          } else {
            new_file_arr.push(tmp_info);
          }
        }
        myProps.set_row_data({
          ...myProps.row_data,
          ...{
            file_arr: new_file_arr
          }
        });
      } else {
        alert(response.data["msg"]);
      }
    });
  }

  const get_img_div_tags = () => {
    let img_div_tags = file_arr.map((item: any, idx: number) => {
      let is_image = item.is_image;
      let img_url = "";
      if (is_image == "1") {
        img_url = item.img_url;
      }

      return (
        <div key={idx} className={Style.file_form_item} title={item.a_oriname}>
          {is_image == "1" ?
            <img src={img_url} alt={item.a_oriname} />
            :
            item.a_oriname
          }
          <button className="btn-s btn-red px-2" onClick={() => { goDelete(item); }}
            style={{ position: "absolute", right: 0, top: 0 }}>x</button>
        </div>
      );
    });
    return img_div_tags;
  };

  return (
    <div className={Style.file_area_wrap + " noto_sans"} >
      <h4 style={{ fontSize: 15, marginTop: 10,fontWeight:400 }}>사진추가</h4>
      <div className={Style.file_list_wrap}>
        <div className={Style.file_form_wrap}>
          {get_img_div_tags()}

          <div className={Style.file_form_item}>
            <span style={{ textAlign: "center", cursor: "pointer" }} 
              onClick={(e:any)=>{
                e.target.parentElement.parentElement.querySelector(".input_file")?.click();
              }}>
              <img src={file_input_data.url} alt={file_input_data.name}
                style={{ cursor: "pointer", maxWidth: 60, maxHeight: 60, flexShrink: 0 }} />
              <p>
                {file_input_data.name}
              </p>
            </span>
            <form id="write_form" method="post" ref={writeFileForm} onSubmit={() => { return false; }} >
              <input type="file" className="input_file" name="input_file[]"
                ref={fileInput} multiple hidden accept="image/*" onChange={(e: any) => {
                  if (e.target.files.length > 0) {
                    let file = e.target.files[0];
                    set_file_input_data({
                      url: URL.createObjectURL(file),
                      name: file.name
                    });
                  }
                }} />
            </form>
            {file_input_data.name != "" &&
              <span className="btn-s btn-red px-2"
                style={{ position: "absolute", right: 0, top: 0 }}
                onClick={() => {
                  if (fileInput.current) {
                    fileInput.current.value = "";
                  }
                  set_file_input_data(default_file_input_data);
                }}>x</span>
            }
          </div>
        </div>
      </div>
    </div>
  );
});
export default FileFormArea;