import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import { useState, useRef, useEffect } from 'react';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import TStuTodoBaseFunc from "@/pcomponents/common/content/tea/tstu_todo/func/base_func";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/mobile/manage/basic/student/popup/find_multy";
import WriteArea from "./area/write";

function TStuWriteArea(props: any) {
  let myProps = {
    row_data: {},
    is_update: false,
    callback: () => { },
    close:()=>{},
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;

  const [is_update, set_is_update] = useState(myProps.is_update);
  const [pageData, setPageData] = useState({
    "write_btn_text": "등록하기",
    "confirm_msg": "등록 하시겠습니까?",
  });
  let default_row_data = TStuTodoBaseFunc.get_default_row_data({ user: user });
  const [row_data, set_row_data] = useState({
    ...default_row_data,
    ...myProps.row_data
  });

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_stu",
    title: "학생찾기",
    y: "0",
    width: "500px",
    height: "100%",
    is_border_radius: false,
    has_header: false,
  });

  useEffect(() => {
    if (is_update) {
      if (strFunc.is_empty(row_data.a_seq)) {
        alert("키가 없습니다.");
        myProps.callback();
      }
      setPageData({
        "write_btn_text": "수정하기",
        "confirm_msg": "수정 하시겠습니까?",
      });
    } else {
      setPageData({
        "write_btn_text": "등록하기",
        "confirm_msg": "등록 하시겠습니까?",
      });
    }
  }, []);

  const goWrite = () => {
    if (row_data.a_stu_seq == "") {
      alert("원생 선택이 필요합니다.");
      return false;
    }
    if (row_data.a_title == "") {
      alert("내용 입력이 필요합니다.");
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    if (!confirm(pageData["confirm_msg"])) {
      return false;
    }

    let wrow_data = { ...row_data };
    if (!is_update) {
      wrow_data["a_ymd"] = strFunc.str_replace("-", "", wrow_data["a_reserve_date"]);
    }
    let formJsonData = {
      "data_arr": [wrow_data],
      "is_default_val": "1",
      "is_update": "",
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq
    };
    if (is_update) {
      formJsonData["is_default_val"] = "";
      formJsonData["is_update"] = "1";
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/write', formJsonData,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.callback();
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  const open_find_stu_popup = () => {
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "find_stu",
    });
  };
  const callback_find_stu = (inData: any) => {
    let opt_obj = {
      "info_arr": [],
      ...inData
    };
    if (opt_obj.info_arr.length == 0) {
      alert("선택이 필요합니다.");
      return false;
    }
    if (opt_obj.info_arr.length != 1) {
      alert("한명만 선택 해주세요.");
      return false;
    }

    let select_stu_info = opt_obj.info_arr[0];
    let change_row_data = {
      ...row_data,
      "a_mcomp_stu_seq": select_stu_info.a_seq,
      "a_main_stu_seq": "",
      "a_stu_name": select_stu_info.a_user_name,
      stu_info: select_stu_info
    };
    set_row_data(change_row_data);
  };
  

  return (
    <div>
      <div>
        <WriteArea
          row_data={row_data}
          set_row_data={set_row_data}
          is_update={is_update}
          open_find_stu_popup={open_find_stu_popup}
        ></WriteArea>
      </div>
      <div className="mt-2 text-center">
        <button className="btn btn-blue" onClick={()=>{
          goWrite();
        }}>저장</button>
        <button className="btn btn-dark ml-1" onClick={myProps.close} >닫기</button>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius}
          has_header={popupData.has_header} >
          {popupData.sort === "find_stu" &&
            <FindMultyStudentPopup
              select_mcomp_seq={iam_mcomp_seq}
              callBackData={callback_find_stu}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default TStuWriteArea;