import strFunc from "@/lib/lyg/string";
import TopInfoArea from "./area/top_info";
import ReportMain from "./report";
import { useState, useEffect } from 'react';

function CardBoxArea(props: any) {
  let myProps = {
    row_num: 0,
    info: {},
    set_info:(info:any)=>{},
    select_date:"",
    list:(inData: any)=>{},
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    Style: {},
    select_stu_grade_arr: [],
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let prescribe_arr = [];
  if (info.prescribe_arr) {
    prescribe_arr = info.prescribe_arr;
  }

  const [is_show_write, set_is_show_write] = useState(false);
  const [is_view_mode,set_is_view_mode]=useState(false);
  const [refresh,set_refresh]=useState(false);

  return (
    <div className={Style.card_box}>
      <div>
        <TopInfoArea
          row_num={myProps.row_num}
          info={myProps.info}
          check_row_val_arr={myProps.check_row_val_arr}
          set_check_row_val_arr={myProps.set_check_row_val_arr}
          Style={Style}
          select_stu_grade_arr={myProps.select_stu_grade_arr}
          prescribe_arr={prescribe_arr}
          is_show_write={is_show_write}
          set_is_show_write={(inData:any)=>{
            set_is_show_write(inData);
            set_refresh(!refresh);
          }}
          is_view_mode={is_view_mode}
          set_is_view_mode={set_is_view_mode}
        ></TopInfoArea>
      </div>
      <div>
        <ReportMain
          row_num={myProps.row_num}
          info={myProps.info}
          set_info={myProps.set_info}
          select_date={myProps.select_date}
          list={myProps.list}
          is_show_write={is_show_write}
          set_is_show_write={(inData:any)=>{
            set_is_show_write(inData);
            set_refresh(!refresh);
          }}
          is_view_mode={is_view_mode}
          set_is_view_mode={set_is_view_mode}
          Style={Style}
        ></ReportMain>
      </div>
    </div>
  );
};
export default CardBoxArea;