//import RoomListArea from "@/pcomponents/common/content/chat/room/room_list";
import avocado_img from "@/img/mobile/char/avocado.png";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';

const RoomListArea = (props: any) => {
  let myProps: any = {
    infoArr: [],
    onClickView: (inData: any) => {
      // {
      // row_data:{},
      // row_num:0,
      // }
    },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let now_ymd = DateFunc.get_date_format(new Date(), "Y-m-d");

  let list_row_tags = myProps.infoArr.map((info: any, info_idx: number) => {
    let new_tag: any = (
      <div>
        <span className="msg_new" style={{ opacity: 0 }}>0</span>
      </div>
    );
    if (info.unread_chat_tot != 0) {
      new_tag = (
        <div>
          <span className="msg_new" >{strFunc.comma(info.unread_chat_tot)}</span>
        </div>
      );
    }

    let title_icon_img_src = avocado_img;

    let title_str = info.member_title;
    //내이름제거
    title_str = title_str.replace("," + user.user_name, "");
    title_str = title_str.replace(user.user_name + ",", "");
    title_str=strFunc.cut_str(title_str,10,"..");
    //학원명추가
    if(info.mcomp_title){
      title_str="["+strFunc.cut_str(info.mcomp_title,6,"..")+"] "+title_str;
    }
    //명수추가
    title_str += "(" + info.mem_arr.length + "명)";

    return (
      <div className="list_board_row" key={info_idx} >
        <div className="list_board_icon_box" onClick={() => {
          myProps.onClickView({ row_data: info, row_num: info_idx });
        }}>
          <img src={title_icon_img_src} style={{ height: 25 }} />
        </div>
        <div className="list_board_title_wrap" onClick={() => {
          myProps.onClickView({ row_data: info, row_num: info_idx });
        }}>
          <div className="list_board_title_div">
            {title_str}
          </div>
          <div className="list_board_sub_title_div">
            {info.last_msg &&
              <span>
                <span style={{ color: "blue" }}>{info.last_msg.a_writer} :</span>
                {strFunc.cut_str(info.last_msg.a_content,10,"..")}
              </span>
            }
          </div>
        </div>
        <div className="list_board_opt_wrap">
          <div className="list_board_opt_top">{new_tag}</div>
          <div className="list_board_opt_bottom">
            {
              now_ymd == info.a_update_date.substring(0, 10) ?
                <span>{DateFunc.getTimeFormatOfPretty(info.a_update_date)}</span>
                :
                <span>{info.a_update_date.substring(0, 10)}</span>
            }
          </div>
        </div>
      </div>
    );
  });
  if (strFunc.is_empty(list_row_tags)) {
    list_row_tags = (
      <div className="list_board_empty_row">
        내용이 없습니다.
      </div>
    );
  }

  return (
    <div className="list_board_div">
      {list_row_tags}
    </div>
  );
};
export default RoomListArea;