import search_ico from "@/img/ico/search.png";
import { useState, useRef } from 'react';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

function SearchArea(props: any) {
  let myProps = {
    Style: {},
    listOpt: {

    },
    setListOpt: (inData: any) => { },
    stu_class_info_arr: [],
    list: (inData: any) => { },
    openSelectAttendPopup: (attend_sort: string, attend_sort_detail: string) => { },
    go_write_plan_page: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  const [is_show_search_detail, set_is_show_search_detail] = useState(false);
  const search_time_out_ref=useRef<any>(null);
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    
    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.listOpt.s_addon_attend_start_date)) {
      myProps.listOpt.s_addon_attend_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.listOpt.s_addon_attend_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_addon_attend_start_date": change_date_str,
      "s_addon_attend_end_date": change_date_str,
      "s_addon_todo_start_date": change_date_str,
      "s_addon_todo_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    let change_s_list_json = {
      "now_page": "1",
      [key]: date_str,
    };
    if (key == "s_addon_attend_start_date") {
      change_s_list_json["s_addon_todo_start_date"] = date_str;
    }
    if (key == "s_addon_attend_end_date") {
      change_s_list_json["s_addon_todo_end_date"] = date_str;
    }

    myProps.list(change_s_list_json);
  };
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  let start_date_json = DateFunc.get_date_json(new Date(myProps.listOpt.s_addon_attend_start_date));
  let select_day_str = DateFunc.get_day_str_by_day_num(start_date_json.day);
  let is_today_select = false;
  if (now_date_str == myProps.listOpt.s_addon_attend_start_date) {
    is_today_select = true;
  }
  return (
    <div>
      <div className="search_wrap mt-2" >
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_addon_attend_start_date != "" ? new Date(myProps.listOpt.s_addon_attend_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_attend_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <span className="ml-1" style={{ fontSize: "12px" }}>
          {select_day_str}
          {is_today_select && "(오늘)"}
        </span>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          let tmp_row_y_m_d = DateFunc.get_date_format(new Date(), "Y-m-d");
          myProps.list({
            "now_page": "1",
            "s_addon_attend_start_date": tmp_row_y_m_d,
            "s_addon_attend_end_date": tmp_row_y_m_d,
            "s_addon_todo_start_date": tmp_row_y_m_d,
            "s_addon_todo_end_date": tmp_row_y_m_d,
          });
        }}>오늘</button>
      </div>
      <div className={Style.search_wrap} style={{ position: "relative" }}>
        <input type="text" value={myProps.listOpt.s_user_name_like}
          name="s_user_name_like"
          onChange={handleChangeInput} style={{ width: 65,height:28, paddingLeft: 3 }}
          autoComplete="off"
          placeholder="이름" />

        <SearchLectureSelect
          name="s_stu_lecture_seq"
          value={myProps.listOpt.s_stu_lecture_seq}
          on_change_input={(inData: any) => {
            let name = inData.name;
            let value = inData.value;
            myProps.list({ now_page: 1, [name]: value });
          }}
          wrap_style={{ width: 80, display: "inline-table",marginLeft:2 }}
          select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
        ></SearchLectureSelect>
        <button className="btn-s btn-dark ml-1"
          style={{ background: "#53a500", padding: "0 4px" }}
          onClick={() => {
            myProps.openSelectAttendPopup("in", "attend");
          }}>등원</button>
        {/* <button className="btn-s btn-dark ml-1"
          style={{ background: "#EABF0B", padding: "0 4px" }}
          onClick={() => {
            myProps.openSelectAttendPopup("in", "late");
          }}>지각</button> */}
        <button className="btn-s btn-dark ml-1"
          style={{ background: "#f26b6f", padding: "0 4px" }}
          onClick={() => {
            myProps.openSelectAttendPopup("in", "absent");
          }}>결석</button>
        <button className="btn-s btn-dark ml-1"
          style={{ background: "#7461ff", padding: "0 8px" }}
          onClick={() => {
            myProps.openSelectAttendPopup("out", "out");
          }}>하원</button>
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = myProps.listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {myProps.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>
        <button className="btn-s btn-gray px-1" onClick={() => { set_is_show_search_detail(!is_show_search_detail); }}
          style={{ background: "#dfdfdf" }}>
          <img src={search_ico} style={{ height: 15 }} />
        </button>

        <button className={Style.add_plan_btn + " ml-1"}
          onClick={() => { myProps.go_write_plan_page({}); }}>+추가</button>
      </div>
      {is_show_search_detail &&
        <div style={{ paddingBottom: 10 }}>
          <div className="search_wrap mt-2">
            <input type="text" className="ml-1" value={myProps.listOpt.s_school_name_like}
              name="s_school_name_like"
              autoComplete="off"
              onChange={handleChangeInput} style={{ width: 60 }}
              placeholder="학교" />
            <select className="ml-1 border" value={myProps.listOpt.s_class_seq}
              name="s_class_seq"
              onChange={handleChangeInput} style={{ width: 80, fontSize: 13 }}>
              <option value="">클래스</option>
              {
                myProps.stu_class_info_arr.map((item: any, idx: number) => {
                  return (
                    <option key={idx} value={item.a_seq}>{item.a_name}</option>
                  );
                })
              }
            </select>
          </div>
        </div>
      }
    </div>
  );
};
export default SearchArea;