import sky_nut_img from "@/img/mobile/game/math/sky_nut.png";
import yellow_nut_img from "@/img/mobile/game/math/yellow_nut.png";

class CharBarFunc
{
  static init_data(inData:any){
    let opt_obj={
      char_bar_data:{},
      game_data:{},
      ...inData
    };
    let char_bar_data=opt_obj.char_bar_data;
    let game_data=opt_obj.game_data;
    let new_nut_arr=[];
    let matter_arr=[];
    if(game_data&&game_data.matter_arr){
      matter_arr=game_data.matter_arr;
    }
    let split_px=80;
    for(let i=0;i<matter_arr.length;i++){
      let matter=matter_arr[i];
      let row_nut_x=split_px*i;
      let row_num_src=sky_nut_img;
      if(i%2==0){
        row_num_src=yellow_nut_img;
      }
      let nut_obj={
        x:row_nut_x,
        src:row_num_src,
      };
      new_nut_arr.push(nut_obj);
    }
    char_bar_data.nut_arr=new_nut_arr;

    return char_bar_data;
  };

  static move_tick(inData:any){
    let opt_obj={
      char_bar_data:{},
      ...inData
    };
    let char_bar_data=opt_obj.char_bar_data;
    let nut_arr=[];
    if(char_bar_data.nut_arr){
      nut_arr=char_bar_data.nut_arr
    }
    if(nut_arr.length>0){
      let first_x=nut_arr[0].x;
      let split_px=80;
      if(first_x>0){
        first_x=first_x-2;
        for(let i=0;i<nut_arr.length;i++){
          nut_arr[i].x=first_x+(i*split_px);
        }
      }
    }

    char_bar_data.nut_arr=nut_arr;
    return char_bar_data;
  };

  static remove_num_by_idx(inData:any){
    let opt_obj={
      remove_idx:0,
      char_bar_data:{},
      ...inData
    };
    let char_bar_data=opt_obj.char_bar_data;
    let remove_idx=opt_obj.remove_idx;
    let nut_arr=[];
    if(char_bar_data.nut_arr){
      nut_arr=char_bar_data.nut_arr
    }

    let new_nut_arr:any=[];
    for(let i=0;i<nut_arr.length;i++){
      if(i!=remove_idx){
        new_nut_arr.push(nut_arr[i]);
      }
    }

    char_bar_data.nut_arr=new_nut_arr;
    return char_bar_data;
  };
}
export default CharBarFunc;