import strFunc from '@/lib/lyg/string';

function ManageButtonArea(props: any) {
  let myProps = {
    detail_info:{},
    go_save_map_detail_by_ajax:()=>{},
    go_success_map_detail_by_ajax:(inData:any)=>{},
    go_delete_map_detail_by_ajax:()=>{},
    go_back_page:()=>{},
    ...props
  };
  let detail_info=myProps.detail_info;

  return (
    <div className="mt-1 text-center">
      <button className="btn btn-sky"
        style={{ width: "80px" }}
        onClick={() => { myProps.go_save_map_detail_by_ajax({}); }}>저장</button>
      {strFunc.is_empty(detail_info["a_is_success"]) &&
        <button className="btn btn-sky ml-1"
          style={{ width: "80px" }}
          onClick={() => { myProps.go_success_map_detail_by_ajax({ is_success: true }); }}>완료하기</button>
      }
      {detail_info["a_is_success"] == "1" &&
        <button className="btn btn-red ml-1"
          style={{ width: "80px" }}
          onClick={() => { myProps.go_success_map_detail_by_ajax({ is_success: false }); }}>완료취소</button>
      }
      {!strFunc.is_empty(detail_info["a_seq"]) &&
        <button className="btn btn-red ml-1"
          style={{ width: "80px" }}
          onClick={() => { myProps.go_delete_map_detail_by_ajax(); }}>삭제</button>
      }
      <button className="btn btn-dark ml-1"
        style={{ width: "80px" }}
        onClick={myProps.go_back_page}>뒤로</button>
    </div>
  );
};
export default ManageButtonArea;