import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderWrapArea from "@/pcomponents/common/content/layout/header_wrap";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Style from "./css/style.module.css";
import TitleBarArea from "@/pcomponents/common/content/list/title_bar";
import AttendSearchBar from "./area/attend_bar";
import SearchTimeBox from "./area/search_time_box";
import SearchArea from "./area/search";
import PlanCardListArea from "./area/card_list";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import AddAttendPopup from "@/pages/mobile/manage/plan/popup/add_attend";
import ModifyAttendPopup from "../popup/modify_attend";
import ViewTodoStuPopup from "../popup/view_todo_stu";
import ViewStuInfoPopup from "../popup/view_stu_info";
import TodoWritePopup from "@/pages/mobile/plan/todo/popup/todo_write";
import AddGoldStuPopup from "@/pages/mobile/manage/basic/char/popup/add_gold_stu";
import getXcolumnJson from "./xcolumn/list";
import TopMoveButton from "@/pcomponents/common/content/list/top_button";
import { useLocation, useNavigate } from "react-router-dom";
import PlanListFunc from "./func/plan_list_func";

function ManagePlanListPage() {
  const xColumnArr = getXcolumnJson();
  let user = useSelector((state: any) => state.user);
  const { state } = useLocation();
  let state_obj = {
    title_list_sort: "card",
    s_attend_sort_detail: "",
    ...state
  };
  let now_y_m_d = DateFunc.get_date_format(new Date(), "Y-m-d");
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 300,
    'order_id': 'a_stu_grade, a_user_name',
    'order_type': '',
    "is_add_idx_info": "1",
    s_is_login: "1",
    s_stu_state: "now",
    s_grade: ["student"],
    s_user_name_like: "",
    s_school_name_like: "",
    s_class_seq: "",
    //s_addon_link_user: "1",
    s_addon_comp_link: "1",
    s_addon_comp_chat_opt_by_comp_link:"1",
    s_addon_attend: "1",
    s_addon_attend_start_date: now_y_m_d,
    s_addon_attend_end_date: now_y_m_d,
    s_attend_sort_detail: state_obj.s_attend_sort_detail,
    s_attend_start_date: now_y_m_d,
    s_attend_end_date: now_y_m_d,
    s_addon_todo: "1",
    s_addon_todo_start_date: now_y_m_d,
    s_addon_todo_end_date: now_y_m_d,
    s_addon_todo_s_todo_sort: "",
    s_addon_stu_grade_name: "1",
    s_addon_home_work_file: "1",
    s_addon_home_work_file_start_date: now_y_m_d,
    s_addon_home_work_file_end_date: now_y_m_d,
    s_addon_plan_study_file: "1",
    s_addon_plan_study_file_start_date: now_y_m_d,
    s_addon_plan_study_file_end_date: now_y_m_d,
    s_addon_lecture_time: "1",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
  });

  const [selected_tab, set_selected_tab] = useState("lecture");
  const [list_sort, set_list_sort] = useState(state_obj.title_list_sort);//list,card
  const [infoArr, setInfoArr] = useState([]);
  const [select_seq_arr, set_select_seq_arr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [stu_class_info_arr, set_stu_class_info_arr] = useState([]);
  const [show_group_time, set_group_time] = useState({
    sort: strFunc.get_storage("change_group_time_sort", ""),//"":전체,now::현재~,select:선택
    select_time_arr: [],//[13:00,14:00,..]
  });
  const able_time_arr_ref = useRef<any>([]);//[13:00,14:00,..]
  const [refresh, set_refresh] = useState(false);

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "add_attend",//add_attend,modify_attend,view_todo,view_stu,add_gold_stu
    attend_sort: "in",//in,out
    attend_sort_detail: "",
    attend_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
    userInfoArr: [],
    stu_info: {},

    isUpdate: false,
    rowData: {},
    todo_sort: "",
    select_date: "",
    comp_stu_info: {},
    main_stu_seq_arr: [],

    title: "팝업",
    width: "500px",
    height: "100%",
    y: "0",
    is_border_radius: false,
    select_idx: 0,
    stu_info_arr: [],
  });

  useEffect(() => {
    list({});
    get_stu_class_list_by_ajax();
  }, [user]);

  const list = (inData: any) => {
    let tmp_list_opt: any = { 
      ...listOpt, 
      ...inData
     };
     setListOpt(tmp_list_opt);
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }
    tmp_list_opt={...tmp_list_opt};
    tmp_list_opt["s_mcomp_seq"] = iam_mcomp_seq;
    tmp_list_opt["s_mcomp_user_seq"] = iam_mcomp_user_seq;
    tmp_list_opt["s_addon_home_work_file_start_date"] = tmp_list_opt["s_addon_todo_start_date"];
    tmp_list_opt["s_addon_home_work_file_end_date"] = tmp_list_opt["s_addon_todo_end_date"];
    tmp_list_opt["s_addon_plan_study_file_start_date"] = tmp_list_opt["s_addon_todo_start_date"];
    tmp_list_opt["s_addon_plan_study_file_end_date"] = tmp_list_opt["s_addon_todo_end_date"];
    tmp_list_opt["s_addon_attend_start_date"] = tmp_list_opt["s_addon_todo_start_date"];
    tmp_list_opt["s_addon_attend_end_date"] = tmp_list_opt["s_addon_todo_end_date"];

    //하루전꺼부터 조회
    let tmp_start_date = tmp_list_opt["s_addon_todo_start_date"];
    let tmp_start_date_json = DateFunc.get_date_json(new Date(tmp_start_date));
    let pre_date_change_num = -1;
    if (tmp_start_date_json.day == 1) {
      //일요일이면 3일뒤로이동(금요일부터 보이게한다.)
      pre_date_change_num = -3;
    }
    let pre_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(tmp_start_date), 'day', pre_date_change_num), "Y-m-d");
    tmp_list_opt["s_addon_todo_start_date"] = pre_date_str;
    tmp_list_opt["s_addon_home_work_file_start_date"] = pre_date_str;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/comp_user/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_select_seq_arr([]);
          response.data["data"]["info_arr"] = PlanListFunc.get_user_info_arr_ordered_by_oder_id(
            response.data["data"]["info_arr"],
            tmp_list_opt.order_id,
          );
          let info_arr = PlanListFunc.get_info_arr_by_filter({
            info_arr: response.data["data"]["info_arr"],
            list_opt: tmp_list_opt,
            base_date_str: tmp_list_opt["s_addon_todo_end_date"],
            pre_date_str: pre_date_str
          });
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const get_stu_class_list_by_ajax = () => {
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      alert("학원 로그인 정보 없음.");
      return false;
    }
    let tmp_list_opt = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
      "order_id": "a_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/class/class/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_stu_class_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const deleteAttend = (dataArr: any, confirm_msg: string) => {
    if (strFunc.is_empty(comp_api_key) || strFunc.is_empty(iam_mcomp_user_seq)) {
      alert("학원 로그인 정보 없음.");
      return false;
    }
    if (strFunc.is_empty(confirm_msg)) {
      confirm_msg = "취소 하시겠습니까?";
    }
    if (!confirm(confirm_msg)) {
      return false;
    }
    let del_form_data = {
      "api_key": comp_api_key,
      "api_user_seq": iam_mcomp_user_seq,
      "data_arr": dataArr
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/attend/delete', del_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {

        }
      });
  };

  const get_select_info_arr = () => {
    let selected_row: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      if (strFunc.str_in_array(info["a_seq"], select_seq_arr) != -1) {
        selected_row.push(info);
      }
    }
    return selected_row;
  };

  const openSelectAttendPopup = (attend_sort: string, attend_sort_detail: string) => {
    let pop_title = "등원";
    if (attend_sort == "out") {
      pop_title = "하원";
    }
    let selected_row: any = get_select_info_arr();
    openAttendPopup(selected_row, attend_sort, attend_sort_detail);
  };

  const go_write_plan_page = (inData: any) => {
    let opt_obj = {
      "is_update": false,
      "rowData": null,
      "stu_info_arr": get_select_info_arr(),
      "todo_sort": "homework",
      "select_date": listOpt.s_addon_attend_start_date,
      ...inData
    };
    let stu_info_arr: any = opt_obj["stu_info_arr"];
    if (opt_obj["is_update"]) {
      if (strFunc.is_empty(opt_obj["rowData"])) {
        alert("수정할 데이터 없음.");
        return false;
      }
    } else {
      if (stu_info_arr.length == 0) {
        alert("학생 선택이 필요합니다.");
        return false;
      }
      if (stu_info_arr.length != 1) {
        alert("한명만 선택해 주세요.");
        return false;
      }
    }

    let comp_stu_info = stu_info_arr[0];
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "write_todo",
      isUpdate: opt_obj["is_update"],
      rowData: opt_obj["rowData"],
      todo_sort: opt_obj["todo_sort"],
      select_date: opt_obj["select_date"],
      comp_stu_info: comp_stu_info,
    });
  };

  const openAttendPopup = (selected_row: [], attend_sort: string, attend_sort_detail: string) => {
    let pop_title = "등원";
    if (attend_sort == "out") {
      pop_title = "하원";
    }
    if (xColumnArr.select_arr.a_sort_detail) {
      for (let i = 0; i < xColumnArr.select_arr.a_sort_detail.length; i++) {
        let item = xColumnArr.select_arr.a_sort_detail[i];
        if (item.value == attend_sort_detail) {
          pop_title = item.text;
        }
      }
    }

    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let attend_date = listOpt.s_addon_attend_start_date;
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "add_attend",
      "title": pop_title,
      attend_sort: attend_sort,
      attend_sort_detail: attend_sort_detail,
      attend_date: attend_date,
      userInfoArr: selected_row,
    });
  };
  const openAttendModifyPopup = (selected_row: []) => {
    if (selected_row.length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    let pop_title = "출석시간 수정";
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "modify_attend",
      "title": pop_title,
      userInfoArr: selected_row,
    });
  };
  const openViewTodoStuPopup = (inData: any) => {
    let opt_obj = {
      stu_info: null,
      select_date: listOpt.s_addon_todo_start_date,
      ...inData
    };
    let stu_info = opt_obj["stu_info"];
    if (strFunc.is_empty(stu_info)) {
      alert("선택이 없습니다.");
      return false;
    }
    let pop_title = "맞춤플랜 보기";
    let select_idx = 0;
    for (let i = 0; i < infoArr.length; i++) {
      if (infoArr[i]["a_seq"] == stu_info["a_seq"]) {
        select_idx = i;
      }
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "view_todo",
      "title": pop_title,
      stu_info_arr: infoArr,
      select_idx: select_idx,
      select_date: opt_obj["select_date"],
    });
  };
  const openViewStuPopup = (stu_info: any) => {
    if (strFunc.is_empty(stu_info)) {
      alert("선택이 없습니다.");
      return false;
    }
    let pop_title = "학생 상세보기";
    let select_idx = 0;
    for (let i = 0; i < infoArr.length; i++) {
      if (infoArr[i]["a_seq"] == stu_info["a_seq"]) {
        select_idx = i;
      }
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "view_stu",
      "title": pop_title,
      stu_info_arr: infoArr,
      select_idx: select_idx
    });
  };
  const openAddGoldPopup = (inData:any) => {
    let opt_obj={
      select_info_arr:[],
      ...inData
    };
    let select_info_arr=opt_obj["select_info_arr"];
    let main_stu_seq_arr:any=[];
    if(select_info_arr.length==0){
      for(let i=0;i<infoArr.length;i++){
        let info:any=infoArr[i];
        if(strFunc.str_in_array(info["a_seq"],select_seq_arr)!=-1){
          if(info.comp_link_info){
            if(!strFunc.is_empty(info.comp_link_info.a_main_user_seq)){
              main_stu_seq_arr.push(info.comp_link_info.a_main_user_seq);
            }
          }
          select_info_arr.push(info);
        }
      }
    }
    if(select_info_arr.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    if(main_stu_seq_arr.length==0){
      alert("학생이 앱로그인이 필요합니다.");
      return false;
    }
    let pop_title = "학생 상세보기";

    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "add_gold_stu",
      "title": pop_title,
      main_stu_seq_arr: main_stu_seq_arr,
    });
  };

  const get_attend_tot_info_by_info_arr = () => {
    let attend_tot_info = {
      "tot": 0,
      "attend": 0,
      "late": 0,
      "absent": 0,
      "out": 0,
    };
    let info_arr_len = infoArr.length;
    for (let i = 0; i < info_arr_len; i++) {
      let info: any = infoArr[i];
      attend_tot_info["tot"]++;

      let is_exist_attend = false;
      if (info.attend_in_arr && info.attend_in_arr.length > 0) {
        let attend_info = info.attend_in_arr[0];
        if (attend_info["a_sort_detail"] == "late") {
          attend_tot_info["late"]++;
        } else if (attend_info["a_sort_detail"] == "absent") {
          attend_tot_info["absent"]++;
        } else {
          attend_tot_info["attend"]++;
        }
        is_exist_attend = true;
      }
      if (info.attend_out_arr && info.attend_out_arr.length > 0) {
        attend_tot_info["out"]++;
        is_exist_attend = true;
      }
      if (is_exist_attend == false) {
        attend_tot_info["absent"]++;
      }
    }
    return attend_tot_info;
  };
  let attend_tot_info = get_attend_tot_info_by_info_arr();

  return (
    <MobileLayout
      has_header={false}
      container_style={{ marginTop: "200px" }}
      container_con_style={{ width: "100%" }} >
      <HeaderWrapArea>
        <div className="noto_sans" >
          <TitleBarArea list_sort={list_sort} set_list_sort={set_list_sort}></TitleBarArea>
          <AttendSearchBar Style={Style} attend_tot_info={attend_tot_info}
            s_attend_sort_detail={listOpt.s_attend_sort_detail}
            list={list}
          ></AttendSearchBar>
          <SearchArea
            Style={Style}
            listOpt={listOpt}
            setListOpt={setListOpt}
            stu_class_info_arr={stu_class_info_arr}
            list={list}
            openSelectAttendPopup={openSelectAttendPopup}
            go_write_plan_page={go_write_plan_page}
          ></SearchArea>
          <SearchTimeBox
            listOpt={listOpt}
            list={list}
            show_group_time={show_group_time}
            set_group_time={set_group_time}
            able_time_arr_ref={able_time_arr_ref}
            infoArr={infoArr}
            openAddGoldPopup={openAddGoldPopup}
          ></SearchTimeBox>
        </div>
      </HeaderWrapArea>

      <div className="noto_sans" >
        <PlanCardListArea
          info_arr={infoArr}
          select_seq_arr={select_seq_arr}
          set_select_seq_arr={(inData: any) => {
            set_select_seq_arr(inData);
            set_refresh(!refresh);
          }}
          list_sort={list_sort}
          tab_id={selected_tab}
          select_date={listOpt.s_addon_attend_start_date}
          show_group_time={show_group_time}
          set_group_time={set_group_time}
          able_time_arr_ref={able_time_arr_ref}
          openAttendPopup={openAttendPopup}
          openAttendModifyPopup={openAttendModifyPopup}
          openViewTodoStuPopup={openViewTodoStuPopup}
          go_write_plan_page={go_write_plan_page}
          openViewStuPopup={openViewStuPopup}
          deleteAttend={deleteAttend}
        ></PlanCardListArea>
        <div style={{ fontFamily: "nanum_slow", marginTop: 5 }}>
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        </div>
      </div>
      <TopMoveButton></TopMoveButton>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "add_attend" &&
            <AddAttendPopup attend_sort={popupData.attend_sort} attend_sort_detail={popupData.attend_sort_detail}
              attend_date={popupData.attend_date}
              userInfoArr={popupData.userInfoArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></AddAttendPopup>
          }
          {popupData.sort === "modify_attend" &&
            <ModifyAttendPopup
              userInfoArr={popupData.userInfoArr}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ModifyAttendPopup>
          }
          {popupData.sort === "view_todo" &&
            <ViewTodoStuPopup
              select_idx={popupData.select_idx}
              info_arr={popupData.stu_info_arr}
              select_date={popupData.select_date}
              go_write_page={go_write_plan_page}
              callback={() => { list({ "now_page": "1" }); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewTodoStuPopup>
          }
          {popupData.sort == "view_stu" &&
            <ViewStuInfoPopup
              select_idx={popupData.select_idx}
              info_arr={popupData.stu_info_arr}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewStuInfoPopup>
          }
          {popupData.sort == "write_todo" &&
            <TodoWritePopup
              isUpdate={popupData.isUpdate}
              rowData={popupData.rowData}
              todo_sort={popupData.todo_sort}
              select_date={popupData.select_date}
              comp_stu_info={popupData.comp_stu_info}
              is_comp_todo={true}
              is_comp_stu={true}
              callback={() => { list({ "now_page": "1" }); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></TodoWritePopup>
          }
          {popupData.sort == "add_gold_stu" &&
            <AddGoldStuPopup
              main_stu_seq_arr={popupData.main_stu_seq_arr}
              refresh_data={() => { list({ "now_page": "1" }); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></AddGoldStuPopup>
          }
        </LayerPopup>
      }
    </MobileLayout>
  );
};
export default ManagePlanListPage;