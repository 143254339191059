import strFunc from "@/lib/lyg/string";
import { useNavigate } from 'react-router-dom';
import DateFunc from '@/lib/lyg/date_func';

function CounselBox(props:any){
   let myProps={
    Style:{},
    info:{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  const navigate = useNavigate();

  let is_success=info["a_is_success"];
  let item_class_name=Style.counsel_today_list_item;
  if(is_success=="1"){
    item_class_name+=" "+Style.is_active;
  }

  const go_counsel_list_page=()=>{
    let send_data={
      "s_start_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
      "s_end_date":DateFunc.get_date_format(new Date(),"Y-m-d"),
      "back_press_url":"/mobile",
    };
    let view_url="/mobile/manage/counsel_todo/list";
    navigate(view_url,
      { state: send_data });
  };

  return (
    <div className={item_class_name} onClick={()=>{go_counsel_list_page();}}>
      <div className={Style.counsel_box_img_wrap}>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none">
          <path d="M10.8894 0H2.375C1.06875 0 0 1.08 0 2.4V21.6C0 22.92 1.06875 24 2.375 24H16.625C17.9312 24 19 22.92 19 21.6V8.196C19 7.56 18.7506 6.948 18.2994 6.504L12.5638 0.708C12.1244 0.252 11.5188 0 10.8894 0ZM9.5 9.6C10.8062 9.6 11.875 10.68 11.875 12C11.875 13.32 10.8062 14.4 9.5 14.4C8.19375 14.4 7.125 13.32 7.125 12C7.125 10.68 8.19375 9.6 9.5 9.6ZM14.25 19.2H4.75V18.516C4.75 17.544 5.32 16.68 6.19875 16.296C7.20813 15.852 8.32438 15.6 9.5 15.6C10.6756 15.6 11.7919 15.852 12.8012 16.296C13.68 16.68 14.25 17.544 14.25 18.516V19.2Z" fill="white"/>
        </svg>
      </div>
      <div className={Style.counsel_box_stu_name}>
        {info.a_stu_name}
      </div>
      <div className={Style.counsel_box_time}>
        {is_success=="1"?
          <span>{strFunc.cut_str(info.a_content,6,"..")}</span>
          :
          <span>준비</span>
        }
      </div>
    </div>
  );
};
export default CounselBox;