import MobileLayout from "@/pcomponents/mobile/layout/layout";

function AttendStuListPage(){
  
  return (
  <MobileLayout has_footer={false}>
  
  </MobileLayout>
  );
};
export default AttendStuListPage;