import { useState, useRef, useEffect} from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import SearchArea from './area/search_area';

function FindStudyTitlePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    listOpt: {
      s_except_seq: "",
    },
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key: "",
      };
    },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data = UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq: myProps.select_mcomp_seq,
    default_mcomp_api_key: myProps.select_mcomp_api_key,
    default_mcomp_user_seq: myProps.select_mcomp_user_seq,
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  const xColumnArr = getXcolumnJson();
  const ListComponentRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_except_seq: "",
    s_subject_sort: "",
    s_subject_seq: "",
    s_is_progress: "",
    s_is_mapping: "",
    s_addon_subject_title:"1",
    s_addon_section:"1",
    s_addon_study:"1",
    s_addon_map:"1",
    s_addon_map_s_stu_seq:"",
    ...myProps.listOpt
  });

  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {

  }, []);

  const setXcolumnArrByListOpt = () => {
    let x_column_list_arr_len = xColumnArr.x_column_list_arr.length;
    for (let i = 0; i < x_column_list_arr_len; i++) {
      let x_column = xColumnArr.x_column_list_arr[i];
      if (x_column["key"] == "row_view_counsel_cnt") {
        if (myProps.listOpt["s_addon_counsel_todo_tot"] == "1") {
          xColumnArr.x_column_list_arr[i]["is_show"] = "1";
        }
      }
    }
  };
  setXcolumnArrByListOpt();

  const list = (inOptObj: any) => {
    let change_list_opt = {
      ...listOpt,
      ...inOptObj
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    change_list_opt["s_addon_map_s_stu_seq"] = iam_mcomp_user_seq;

    change_list_opt["api_key"] = comp_api_key;
    change_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    setListOpt(change_list_opt);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goCallBackData = () => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }

    let new_selected_row:any = [];
    let is_exist_map=false;
    for(let i=0;i<selected_row.length;i++){
      let row=selected_row[i];
      if(row.map_arr&&row.map_arr.length>0){
        is_exist_map=true;
      }else{
        new_selected_row.push(row);
      }
    }
    if(is_exist_map){
      alert("이미 있는 맵핑은 선택안됩니다.");
      return false;
    }
    selected_row=new_selected_row;
    if (selected_row.length === 0) {
      alert("이미 있는 맵핑은 선택안됩니다.");
      return false;
    }

    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key: myProps.key
    });
    myProps.closePopup();
  };

  const cellRenderer = (params: any) => {
    // {
    //   "rowData":rowData,
    //   "idx":idx,
    //   "key":key,
    //   "value":row_val,
    //   "origin_val":origin_val,
    // }
    var key = params.key;
    var render_str = params.value;
    let rowData = params.rowData;

    if(key=="row_view_section"){
      let section_num=0;
      let study_num=0;
      if(rowData.section_arr){
        section_num=rowData.section_arr.length;
      }
      if(rowData.study_arr){
        study_num=rowData.study_arr.length;
      }
      render_str=section_num+"/"+study_num;
    }else if(key=="row_view_mapping"){
      if(rowData.map_arr&&rowData.map_arr.length>0){
        render_str=(
          <div className="text-center" style={{color:"blue"}}>
            사용
          </div>
        );
      }
    }

    return render_str;
  };

  return (
    <div>
      <SearchArea
        select_mcomp_seq={myProps.select_mcomp_seq}
        listOpt={{ ...listOpt }}
        setListOpt={setListOpt}
        xColumnArr={{ ...xColumnArr }}
        list={list}
      ></SearchArea>
      <div className="btn-box-right mt-2">
        <button className="btn btn-dark" onClick={() => { goCallBackData(); }}>선택</button>
        <button className="btn btn-line-gray" onClick={() => { myProps.closePopup(); }}>닫기</button>
      </div>
      <div className="mt-1">
        <ListComponent
          ref={ListComponentRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_add_idx_num: false,
            is_add_checkbox: true,
            is_fix_width: false,
            td_align: "center",
            onGridReady: () => {
              list({});
            },
            customCellRenderer: cellRenderer,
          }}
        ></ListComponent>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      </div>
    </div>
  );
};
export default FindStudyTitlePopup;