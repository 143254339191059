import carrot_ico from "@/img/mobile/main/carrot_ico.png";
import strFunc from "@/lib/lyg/string";

function PointProgress(props:any){
  let myProps={
    Style:{},
    title:"양분",
    max_num:100,
    point:0,
    is_progress_bar:false,
    ...props
  };
  let Style=myProps.Style;
  let point_percent=0;
  let point=parseInt(strFunc.uncomma(myProps.point));
  let max_num=parseInt(strFunc.uncomma(myProps.max_num));
  if(point!=0&&max_num!=0){
    point_percent=(point/max_num)*100;
  }

  return (
    <div className={Style.point_progress}>
      <img src={carrot_ico} className={Style.point_progress_icon} />
      <span className={Style.point_progress_text}>{myProps.title}</span>
      {myProps.is_progress_bar&&
      <div className={Style.point_progress_bar_wrap}>
        <div className={Style.point_progress_bar} style={{width:point_percent+"%"}}></div>
      </div>
      }
    </div>
  );
}
export default PointProgress;