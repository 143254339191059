import Style from "./css/style.module.css";
import cloud1_img from "@/img/mobile/main/cloud1.png";
import main_mid_back_img from "@/img/mobile/main/main_mid_back.png";
import weed1_img from "@/img/mobile/main/weed1.png";
import { useState, useRef, useEffect } from 'react';
import LygMath from '@/lib/lyg/math';

function BackGroundArea(props: any) {
  let myProps = {
    ...props
  };

  const cloud_data_ref = useRef([
    { x: -5, y: 140, width: 80 },
    { x: 100, y: 60, width: 70 },
    { x: 270, y: 100, width: 150 },
  ]);
  const [refresh,set_refresh]=useState(false);

  useEffect(() => {
    const animation_loop = setInterval(animationInterval, 2000);
    return () => {
      clearInterval(animation_loop);
    };
  }, [refresh]);

  const animationInterval = () => {
    for(let i=0;i<cloud_data_ref.current.length;i++){
      cloud_data_ref.current[i]["x"]+=LygMath.random(0,3);
      var win_w=(window.innerWidth || document.documentElement.clientWidth);
      if(cloud_data_ref.current[i]["x"]>win_w){
        cloud_data_ref.current[i]["x"]=-cloud_data_ref.current[i]["width"];
        cloud_data_ref.current[i]["y"]=LygMath.random(50,120);
      }
    }
    set_refresh(!refresh);
  };

  let weed_data_arr = [
    { x: 10, y: 300, right: 0, width: 20, opacity: 0 },
    { x: 0, y: 300, right: 80, width: 20, opacity: 0.5 },
    { x: 60, y: 380, right: 0, width: 20, opacity: 0.5 },
    { x: 0, y: 380, right: 10, width: 20, opacity: 0 },
    { x: -10, y: 500, right: 0, width: 20, opacity: 0 },
    { x: 0, y: 510, right: 180, width: 20, opacity: 0 },
  ];

  const get_cloud_tags = () => {
    let cloud_tags = cloud_data_ref.current.map((item: any, idx: number) => {
      let cloud_style: any = {
        left: item.x,
        top: item.y,
        width: item.width
      };

      return (
        <img key={idx} src={cloud1_img} className={Style.main_cloud} style={cloud_style} onClick={animationInterval} />
      );
    });

    return cloud_tags;
  };

  const get_weed_tags = () => {
    let weed_tags = weed_data_arr.map((item: any, idx: number) => {
      let weed_style: any = {
        top: item.y,
        width: item.width
      };
      if (item.right != 0) {
        weed_style.right = item.right;
      } else {
        weed_style.left = item.x;
      }
      if (item.opacity != 0) {
        weed_style.opacity = item.opacity;
      }

      return (
        <img key={idx} src={weed1_img} className={Style.main_weed} style={weed_style} />
      );
    });
    return weed_tags;
  };

  return (
    <div className={Style.back_wrap}>
      <div className={Style.back_sky_area}>
        {get_cloud_tags()}
      </div>
      <img className={Style.main_mid_back_img} src={main_mid_back_img}></img>
      {get_weed_tags()}
    </div>
  );
}
export default BackGroundArea;