import LygMath from '@/lib/lyg/math';

class MinusFunc
{
  static get_matter=(inData:any)=>{
    let opt_obj = {
      level:1,
      default_matter:{},
      ...inData
    };
    let default_matter=opt_obj.default_matter;
    let level=opt_obj.level;
    let matter:any = {
      ...default_matter,
      level: level,
      val_arr:[],
      answer:0,
    };
    let val_arr=[];
    let a_num=LygMath.random(1,20);
    if(opt_obj.level==1){
      a_num=LygMath.random(1,20);
    }else{
      a_num=LygMath.random(1,Math.pow(10,level));
    }
    val_arr.push(a_num);
    let b_num=LygMath.random(1,a_num);
    val_arr.push(b_num);
    let answer=a_num-b_num;

    matter["val_arr"]=val_arr;
    matter["answer"]=answer;

    return matter;
  };
}
export default MinusFunc;