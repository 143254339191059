import { useSelector, useDispatch } from 'react-redux';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudyTitlePopup from "@/pages/mobile/plan/study_map/popup/find_study_title";
import WriteStudyMapDetailPopup from "@/pages/mobile/plan/study_map/popup/write_detail";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search";
import ListArea from "./area/list";

function StudyMapListArea(props: any) {
  let myProps = {
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 20,
    'order_id': 'a_update_date DESC',
    'order_type': '',
    "is_add_idx_info": "1",
    "s_start_date": "",
    "s_end_date": "",
    "s_stu_seq": "",
    "s_addon_study_title": "1",
    // "s_addon_study_detail": "1",
    "s_addon_detail": "1",
    "s_addon_user_info": "1",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [select_seq_arr, set_select_seq_arr] = useState([]);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "find_study_title",//write_study_map_detail

    map_pri_val: "",

    title: "팝업",
    width: "500px",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inData: any) => {
    let tmp_list_opt: any = {
      ...listOpt,
      ...inData
    };
    setListOpt(tmp_list_opt);
    
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    tmp_list_opt["s_stu_seq"] = iam_mcomp_user_seq;

    tmp_list_opt = { ...tmp_list_opt };
    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_select_seq_arr([]);
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const go_add_study_map_popup = (inData: any) => {
    let opt_obj = {
      ...inData
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "find_study_title",
      title: "스터디 찾기",
    });
  };

  const call_back_find_study_title = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData
    };
    let title_info_arr = opt_obj["info_arr"];
    if (title_info_arr.length === 0) {
      alert("선택없음.");
      return false;
    }

    if (strFunc.is_empty(comp_api_key)) {
      alert("학원 선택 필요.");
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    if (!confirm("교과맵핑 " + title_info_arr.length + "개를 등록 하시겠습니까?")) {
      return false;
    }

    //맵핑 등록
    let add_map_row_arr: any = [];
    for (let i = 0; i < title_info_arr.length; i++) {
      let title_info = title_info_arr[i];
      let a_total_cnt = 0;
      if (title_info.study_arr && title_info.study_arr.length > 0) {
        a_total_cnt = title_info.study_arr.length;
      }
      let start_ymd = DateFunc.get_date_format(new Date(), "Y-m-d");
      add_map_row_arr.push({
        "a_study_title_seq": title_info["a_seq"],
        "a_stu_seq": iam_mcomp_user_seq,
        "a_stu_name": user.user_name,
        "a_total_cnt": a_total_cnt,
        "a_start_date": start_ymd,
      });
    }
    let form_json_data: any = {
      data_arr: add_map_row_arr,
      is_default_val: "1",
    };
    form_json_data["api_key"] = comp_api_key;
    form_json_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_map/write', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("맵핑 등록 완료.");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_open_study_map_detail_popup = (inData: any) => {
    let opt_obj={
      map_pri_val: "",
      ...inData
    };
    if(strFunc.is_empty(opt_obj["map_pri_val"])){
      alert("선택없음.");
      return false;
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      title: "교과맵핑 상세",
      sort: "write_study_map_detail",
      map_pri_val: opt_obj["map_pri_val"],
    });
  };

  return (
    <div>
      <SearchArea
        listOpt={listOpt}
        list={list}
        go_add_study_map_popup={go_add_study_map_popup}
      ></SearchArea>
      <div className="mt-2">
        <ListArea
          infoArr={infoArr}
          select_seq_arr={select_seq_arr}
          set_select_seq_arr={set_select_seq_arr}
          go_open_study_map_detail_popup={go_open_study_map_detail_popup}
        ></ListArea>
        <div className="mt-2">
          <Paging
            total_rec={countInfo["tot"]}
            now_page={listOpt["now_page"]}
            num_per_page={listOpt["num_per_page"]}
            onChangePage={(now_page: number) => { list({ "now_page": now_page }); }}
            onChangeNumPerPage={(num_per_page: number) => { list({ now_page: "1", "num_per_page": num_per_page }); }}
          ></Paging>
        </div>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "find_study_title" &&
            <FindStudyTitlePopup
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callBackData={call_back_find_study_title}
            ></FindStudyTitlePopup>
          }
          {popupData.sort === "write_study_map_detail" &&
            <WriteStudyMapDetailPopup
              map_pri_val={popupData.map_pri_val}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              callback_refresh_data={() => { list({}); }}
            ></WriteStudyMapDetailPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default StudyMapListArea;