import strFunc from "@/lib/lyg/string";
import CardBoxArea from "./card_box";
import Style from "./css/style.module.css";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import UserGroupDataFunc from "@/pcomponents/common/content/list/tab1/func/group_data";

function CardListArea(props: any) {
  let myProps = {
    tab_id: "",
    info_arr: [],
    select_date: "",
    set_info_arr: (inData: any) => { },
    list: (inData: any) => { },
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    ...props
  };
  let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  const on_change_group_checkbox = (inData: any) => {
    let opt_obj = {
      checked: false,
      info_arr: [],
      ...inData,
    };
    if (strFunc.is_empty(opt_obj["info_arr"])) {
      return false;
    }

    if (opt_obj["checked"]) {
      //추가
      let tmp_check_row_val_arr = myProps.check_row_val_arr;
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) == -1) {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    } else {
      //제거
      let tmp_check_row_val_arr: any = [];
      for (let i = 0; i < opt_obj["info_arr"].length; i++) {
        let info = opt_obj["info_arr"][i];
        if (strFunc.str_in_array(info["a_seq"], myProps.check_row_val_arr) != -1) {

        } else {
          tmp_check_row_val_arr.push(info["a_seq"]);
        }
      }
      myProps.set_check_row_val_arr(tmp_check_row_val_arr);
    }
  };

  const get_cardbox_tags = (infoArr: []) => {
    let cardbox_tags: any = infoArr.map((item: any, idx: number) => {
      return (
        <CardBoxArea
          key={idx}
          info={item}
          set_info={(info: any) => {
            let tmp_info_arr = myProps.info_arr;
            for (let i = 0; i < tmp_info_arr.length; i++) {
              if (tmp_info_arr[i]["a_seq"] == info["a_seq"]) {
                tmp_info_arr[i] = info;
              }
            }
            myProps.set_info_arr(tmp_info_arr);
          }}
          row_num={idx}
          select_date={myProps.select_date}
          list={myProps.list}
          Style={Style}
          check_row_val_arr={myProps.check_row_val_arr}
          set_check_row_val_arr={myProps.set_check_row_val_arr}
          select_stu_grade_arr={select_stu_grade_arr}
        ></CardBoxArea>
      );
    });

    if (strFunc.is_empty(cardbox_tags)) {
      cardbox_tags = (
        <div className="text-center" style={{ lineHeight: "50px" }}>
          내용이 없습니다.
        </div>
      );
    }

    return cardbox_tags;
  };

  const get_cardbox_group_tags = () => {
    let group_data_arr = UserGroupDataFunc.get_cardbox_group_data_arr({
      select_date: myProps.select_date,
      info_arr: myProps.info_arr,
      tab_id: myProps.tab_id,
    });

    let cardbox_group_tags = group_data_arr.map((group_info: any, idx: number) => {
      let group_title_str = group_info["title"];
      if (myProps.tab_id == "stu_grade") {
        let select_grade_obj_arr = strFunc.get_obj_by_key_val_at_obj_arr("value", group_title_str, select_stu_grade_arr);
        if (select_grade_obj_arr.length == 1) {
          group_title_str = select_grade_obj_arr[0]["text"];
        }
      }
      return (
        <div key={idx} className="mt-1">
          <div className="noto_sans">
            <label>
              <input type="checkbox" className={Style.card_group_checkbox} onChange={(e: any) => {
                on_change_group_checkbox({
                  checked: e.target.checked,
                  info_arr: group_info["info_arr"]
                });
              }} />
              {group_title_str}
              <span className="ml-1" style={{color:"#777"}}>
                ({group_info["info_arr"].length})
              </span>
            </label>
          </div>
          <div className={Style.card_list_wrap + " mt-1 noto_sans"} >
            {get_cardbox_tags(group_info["info_arr"])}
          </div>
        </div>
      );
    });

    if (strFunc.is_empty(cardbox_group_tags)) {
      cardbox_group_tags = (
        <div className="text-center" style={{ lineHeight: "50px" }}>
          내용이 없습니다.
        </div>
      );
    }

    return cardbox_group_tags;
  };

  return (
    <div className={Style.card_group_list_wrap}>
      {get_cardbox_group_tags()}
    </div>
  );
};
export default CardListArea;