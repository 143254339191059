import strFunc from "@/lib/lyg/string";
import ReportItemArea from "./report_item";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

function ReportListArea(props:any){
   let myProps={
    prescribe_arr:[],
    set_prescribe_arr:(inData:any)=>{},
    is_view_mode:true,
    Style:{},
    list:(inData:any)=>{},
    ...props
  };

  useEffect(() => {
    
  }, []);

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const goUpdate = (select_info_arr:[]) => {
    if(select_info_arr.length==0){
      alert("내용이 없습니다.");
      return false;
    }
    for(let i=0;i<select_info_arr.length;i++){
      if(strFunc.is_empty(select_info_arr[i]["a_seq"])){
        alert("데이터가 올바르지 않습니다.");
        return false;
      }
    }
    if(strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원 선택 필요.");
      return false;
    }
    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }
    let w_report_form_json:any = {
      "data_arr": select_info_arr,
      "is_update": "1",
    };
    w_report_form_json["api_key"]=comp_api_key;
    w_report_form_json["api_user_seq"]=iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/write', w_report_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const goDelete = (select_info_arr:[]) => {
    if(select_info_arr.length==0){
      alert("내용이 없습니다.");
      return false;
    }
    for(let i=0;i<select_info_arr.length;i++){
      if(strFunc.is_empty(select_info_arr[i]["a_seq"])){
        alert("데이터가 올바르지 않습니다.");
        return false;
      }
    }
    if(strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원 선택 필요.");
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }
    let w_report_form_json:any = {
      "data_arr": select_info_arr,
    };
    w_report_form_json["api_key"]=comp_api_key;
    w_report_form_json["api_user_seq"]=iam_mcomp_user_seq;
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/study_prescribe/delete', w_report_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_report_tags=()=>{
    let report_tags=myProps.prescribe_arr.map((item:any,idx:number)=>{
      return (
        <ReportItemArea
          key={idx}
          info={item}
          row_num={idx}
          set_info={(info:any)=>{
            let tmp_prescribe_arr=myProps.prescribe_arr;
            tmp_prescribe_arr[idx]=info;
            myProps.set_prescribe_arr(tmp_prescribe_arr);
          }}
          is_view_mode={myProps.is_view_mode}
          Style={myProps.Style}
          goUpdate={goUpdate}
          goDelete={goDelete}
        ></ReportItemArea>
      );
    });
    return report_tags;
  };
  
  return (
  <div>
    {get_report_tags()}
  </div>
  );
};
export default ReportListArea;