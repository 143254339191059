import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search";
import NoticeListArea from "./area/notice_list";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewPopupContent from "./popup/view";
import strFunc from '@/lib/lyg/string';
import { useState, useRef, useEffect } from 'react'
import DateFunc from '@/lib/lyg/date_func';

function NoticeMain() {
  const [pageData, setPageData] = useState({
    listOpt: {
      'now_page': 1,
      'num_per_page': 10,
      'order_id': 'a_create_date DESC',
      'order_type': '',
      's_date_type': 'a_create_date',
      's_start_date': '',
      's_end_date': '',
      'sc': {},
      "is_add_idx_info": "1",
      "s_id": "app_notice",
    }
  });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  useEffect(() => {
    
    list({});
  }, []);

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "view",
    rowData: {},
    title: "보기",
    width: "100%",
    height: "80%",
    marginTop: "0px",
  });

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    let tmp_list_opt = { ...pageData.listOpt };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/basic/board_app/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const openViewPopup = (inData:any) => {
    let opt_obj={
      row_data:{},
      row_num:0,
      ...inData
    };
    setPopupData({
      ...popupData,
      "rowData": opt_obj.row_data,
      "isOpen": true,
      "sort": "view",
    });
  };

  return (
    <MobileLayout>
      <SearchArea
        listOpt={pageData.listOpt}
        list={list}
      ></SearchArea>
      <NoticeListArea
        infoArr={infoArr}
        onClickView={openViewPopup}
      ></NoticeListArea>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "view" &&
            <ViewPopupContent
              rowData={popupData.rowData}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              list_refresh={() => { list({}); }}
            >
            </ViewPopupContent>
          }
        </LayerPopup>
      }
    </MobileLayout>
  );
}

export default NoticeMain;