import strFunc from "@/lib/lyg/string";
import ParentListArea from "./par_area";

function StuArea(props:any){
   let myProps={
    row_data:{},
    ...props
  };
  let row_data=myProps["row_data"];
  
  let class_arr=[];
  if(row_data.class_arr&&row_data.class_arr.length>0){
    class_arr=row_data.class_arr;
  }
  let stu_supply_discount=row_data["a_stu_supply_discount"];
  
  return (
  <div className="noto_sans" style={{fontSize:"14px"}} >
    <div className="write-table-div">
      <table>
        <colgroup>
          <col width={80}></col>
          <col width={"*"}></col>
        </colgroup>
        <tbody>
          <tr>
            <th>아이디</th>
            <td>
              {row_data.a_user_id}
            </td>
          </tr>
          <tr>
            <th>클래스</th>
            <td>
              {class_arr.map((item:any,idx:number)=>{
                let class_info:any={};
                let is_supply=false;
                if(item.class_info){
                  class_info=item.class_info;
                }
                let supply_sort="미청구";
                if(class_info.a_is_supply_money=="1"){
                  is_supply=true;
                  supply_sort="청구";
                }
                let supply_money=0;
                let sum_money=0;
                let discount_money=0;
                if(item.class_info){
                  supply_money=parseInt(strFunc.uncomma(item.class_info["a_supply_money"]));
                }
                if(row_data.a_stu_supply_discount){
                  discount_money=parseInt(strFunc.uncomma(row_data.a_stu_supply_discount));
                }
                if(item.a_supply_discount){
                  discount_money=parseInt(strFunc.uncomma(item.a_supply_discount));
                }
                sum_money=supply_money-discount_money;
                return (
                  <div key={idx}>
                    {item.class_name} 
                    <span className="ml-1" style={{color:"#999"}} >{supply_sort}</span>
                    {is_supply&&
                      <span className="ml-1">
                        <span style={{color:"#00f"}}>{strFunc.comma(sum_money)}</span>원
                        {!strFunc.is_empty(discount_money)&&
                          <span className="ml-1">(할인: {strFunc.comma(discount_money)}원)</span>
                        }
                      </span>
                    }
                  </div>
                );
              })}
            </td>
          </tr>
          <tr>
            <th>출결번호</th>
            <td>
              {strFunc.getNumber2(row_data.a_stu_num)}
            </td>
          </tr>
          <tr>
            <th>핸드폰</th>
            <td>
              {strFunc.autoHypenPhone(row_data.a_user_phone)}
              {/* <a href={"tel:"+row_data.a_user_phone}>
                {strFunc.autoHypenPhone(row_data.a_user_phone)}
              </a> */}
            </td>
          </tr>
          <tr>
            <th>학부모</th>
            <td>
              <ParentListArea
                row_data={row_data}
              ></ParentListArea>
            </td>
          </tr>
          <tr>
            <th>메모</th>
            <td>
              {
                row_data.a_memo && row_data.a_memo.split('\n').map( (line:string,idx:number) => {
                  return (<span key={idx}>{line}<br/></span>)
                })
              }
            </td>
          </tr>
          <tr>
            <th>청구일</th>
            <td>
              {row_data.a_stu_supply_day}일
            </td>
          </tr>
          <tr>
            <th>기본할인</th>
            <td>
              {strFunc.comma(stu_supply_discount)}원
            </td>
          </tr>
          <tr>
            <th>현금영수증</th>
            <td>
              {row_data.a_cash_receipt_sort}
            </td>
          </tr>
          <tr>
            <th>소득공제용번호</th>
            <td>
              {row_data.a_cash_receipt_num}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  );
};
export default StuArea;