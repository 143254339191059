import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useLocation,useNavigate } from "react-router";
import ViewVideoArea from "@/pages/mobile/plan/video/common/view";

function MyVideoView(){
  const navigate = useNavigate();
  const { state } = useLocation();
  let row_data=state.row_data;
  return (
    <MobileLayout>
      {row_data&&
      <ViewVideoArea
        row_data={row_data}
        on_go_back={()=>{navigate("/mobile/plan/video/my_list");}}
        back_btn_title={"강의실로"}
      ></ViewVideoArea>
      }
    </MobileLayout>
  );
}

export default MyVideoView;