import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useNavigate } from 'react-router-dom';
import MenuArea from "./area/menu";

function PlayGroundMainPage(props:any){
   let myProps={
    ...props
  };
  const navigate = useNavigate();
  
  return (
    <MobileLayout>
      <MenuArea></MenuArea>
    </MobileLayout>
  );
};
export default PlayGroundMainPage;