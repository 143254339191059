import left_arrow from "@/img/ico/arrow/left_arrow.png";
import right_arrow from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

function SearchArea(props:any){
  let myProps={
    select_date:"",
    set_select_date:(inData:any)=>{},
    ...props
  };

  const goPrevAfterMonth=(sort:"prev"|"after")=>{
    let select_month_obj=new Date(myProps.select_date.substring(0,7)+"-01");
    let change_date_str="";
    if(sort=="prev"){
      change_date_str=DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj,'month',-1),"Y-m-d");
    }else{
      change_date_str=DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj,'month',+1),"Y-m-d");
    }
    myProps.set_select_date(change_date_str);
  };

  const handleDateChange=(e:any)=>{
    let name=e.target.name;
    let value=e.target.value;

    let year_str=myProps.select_date.substring(0,4);
    let month_str=myProps.select_date.substring(5,7);
    if(name=="s_year"){
      year_str=value;
    }else if(name=="s_month"){
      month_str=strFunc.str_pad({"str":value,"pad_length":2});
    }
    let change_date_str=year_str+"-"+month_str+"-01";
    myProps.set_select_date(change_date_str);
  };

  let year_num_arr=[];
  let select_year_num=parseInt(myProps.select_date.substring(0,4));
  for(let i=select_year_num-50;i<=select_year_num+10;i++){
    year_num_arr.push(i);
  }
  const year_options_tag=year_num_arr.map((item,idx)=>{
    return (
      <option value={item} key={idx}>{item}</option>
    );
  });

  let month_num_arr=[];
  for(let i=1;i<=12;i++){
    month_num_arr.push(i);
  }
  const month_options_tag=month_num_arr.map((item:any,idx:number)=>{
    let month_val=strFunc.str_pad({"str":item,"pad_length":2});
    return (
      <option value={month_val} key={idx}>{item}</option>
    );
  });

  return (
    <div className="sche_search_wrap">
      <div className="sche_search_con">
        <button className="sche_search_arrow_btn" onClick={()=>{goPrevAfterMonth("prev");}}>
          <img src={left_arrow}></img>
        </button>
        <span className="sche_search_ym_span" >
          <select name="s_year" value={myProps.select_date.substring(0,4)} className="sche_search_select_input"
            onChange={handleDateChange}>
            {year_options_tag}
          </select>년
          <select name="s_month" value={myProps.select_date.substring(5,7)} className="sche_search_select_input"
            onChange={handleDateChange}>
            {month_options_tag}
          </select>월
        </span>
        <button className="sche_search_arrow_btn" onClick={()=>{goPrevAfterMonth("after");}}>
          <img src={right_arrow}></img>
        </button>
      </div>
    </div>
  );
}
export default SearchArea;