import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect,useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import strFunc from "@/lib/lyg/string";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import getXcolumnJson from "./xcolumn/list";
import SearchArea from "./area/search";

function ChatRoomWrite() {
  const navigate = useNavigate();
  let user = useSelector((state: any) => state.user);
  
  let iam_mcomp_seq="";
  let iam_mcomp_user_seq="";
  let comp_api_key="";
  let is_manager=false;
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_seq=user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key=user.select_mcomp_link["comp_api_key"];
    if(user.select_mcomp_link["a_user_grade"]=="master"
      ||user.select_mcomp_link["a_user_grade"]=="teacher"){
      is_manager=true;
    }
  }

  const xColumnArr=getXcolumnJson();
  let default_list_opt:any={
    'now_page': 1,
    'num_per_page': 100,
    "order_id": "a_user_name",
    "s_is_login":"1",
    "s_grade":["teacher","master"],
    "s_stu_state":"now",
    "s_mcomp_seq":"",
    "s_mcomp_user_seq":"",
    s_stu_lecture_seq:"",
    s_user_name_like:"",
    s_school_name_like:"",
    s_addon_comp_link:"1",
    s_addon_class_user:"1",
  };
  if(is_manager){
    default_list_opt["s_grade"]="student";
  }else{
    xColumnArr.x_column_list_arr=[
      {"key":"a_user_name","name":"이름","width":"70","is_show":"1"},
      {"key":"a_user_grade","name":"구분","width":"70","is_show":"1"},
      {"key":"row_view_is_app","name":"어플","width":"50","is_show":"1"},
    ];
  }
  const [pageData, setPageData] = useState({
    listOpt: default_list_opt
  });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const ListComponentRef=useRef<any>(null);

  useEffect(() => {
    //list({});
  }, [user]);


  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }

    let tmp_list_opt:any = { ...pageData.listOpt };
    tmp_list_opt["s_mcomp_seq"]=iam_mcomp_seq;
    tmp_list_opt["s_mcomp_user_seq"]=iam_mcomp_user_seq;
    if(strFunc.is_empty(tmp_list_opt["s_mcomp_seq"])
      ||strFunc.is_empty(tmp_list_opt["s_mcomp_user_seq"])){
        return false;
    }
    tmp_list_opt["s_except_user_seq"]=[tmp_list_opt["s_mcomp_user_seq"]];
    if(tmp_list_opt["s_grade"]!="student"){
      tmp_list_opt["s_stu_state"]="";
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/comp_user/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"]=get_join_user_order_by_info_arr(response.data["data"]["info_arr"]);
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  //가입유저 위로 오더
  const get_join_user_order_by_info_arr=(in_info_arr:any[])=>{
    let joined_arr=[];
    let etc_arr=[];
    let info_arr_len=in_info_arr.length;
    for(let i=0;i<info_arr_len;i++){
      let info=in_info_arr[i];
      if(info.comp_link_info){
        joined_arr.push(info);
      }else{
        etc_arr.push(info);
      }
    }
    let result_info_arr=[
      ...joined_arr,
      ...etc_arr
    ];
    return result_info_arr;
  };

  const make_room_by_select=()=>{
    let selected_row=ListComponentRef.current.getSelectedRows();
    if(selected_row.length==0){
      alert("선택이 필요합니다.");
      return false;
    }

    if(strFunc.is_empty(comp_api_key)){
      alert("학원선택이 없습니다.");
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_seq)){
      alert("학원선택이 없습니다.2");
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원선택이 없습니다.3");
      return false;
    }

    if(!confirm("채팅방을 만드시겠습니까?")){
      return false;
    }

    let formJsonData={
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
      "mcomp_seq":iam_mcomp_seq,
      "user_data_arr":selected_row,
      "is_one_on_one":"",
      "room_name":"",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/room/add_room',formJsonData,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        //response.data["data"]["room_info"]
        navigate('/mobile/chat/chat_view', 
              { state: {room_info:response.data["data"]["room_info"]} });
      }else{
        alert(response.data["msg"]);
      }
    });

  };

  const customCellRenderer= useCallback( (params:any) => {
    /*
      {
        "rowData":rowData,
        "idx":idx,
        "key":key,
        "value":row_val
      }
      */
    var key=params.key;
    var render_str=params.value;
    if(key=="row_view_class_name"){
      if(params.rowData.class_arr){
        let class_name_arr=[];
        for(let i=0;i<params.rowData.class_arr.length;i++){
          class_name_arr.push(params.rowData.class_arr[i]["class_name"]);
        }
        render_str=class_name_arr.join(" / ");;
      }
    }else if(key=="row_view_school_grade"){
      let stu_grade_str="";
      if(xColumnArr.select_arr["a_stu_grade"]){
        for(let i=0;i<xColumnArr.select_arr["a_stu_grade"].length;i++){
          let tmp_row_opt=xColumnArr.select_arr["a_stu_grade"][i];
          if(tmp_row_opt["value"]==params.rowData.a_stu_grade){
            stu_grade_str=tmp_row_opt["text"];
          }
        }
      }
      render_str=(
        <div className="text-center">
          <p>{params.rowData.a_school_name}</p>
          <p>{stu_grade_str}</p>
        </div>
      );
    }else if(key=="a_stu_num"){
      render_str=strFunc.getNumber2(render_str);
    }else if(key=="row_view_is_app"){
      if(params.rowData.comp_link_info){
        render_str=(<div className="text-green-500">가입</div>);
      }
      if(params.rowData.comp_link_info){
        render_str=(<div className="text-gray-500" title={"로그아웃"}>가입</div>);
        if(params.rowData.comp_link_info.main_u_info&&params.rowData.comp_link_info.main_u_info.a_push_token){
          render_str=(<div className="text-green-500">가입</div>);
        }
      }
    }else if(key=="a_user_name"){
      let row_user_name=render_str;
      if(params.rowData.a_user_grade!="student"){
        if(!strFunc.is_empty(params.rowData.a_user_nickname)){
          row_user_name=params.rowData.a_user_nickname;
        }
      }
      render_str=strFunc.cut_str(row_user_name,4,"..");
      if(params.rowData.comp_link_info){
        if(params.rowData.comp_link_info.main_u_info&&params.rowData.comp_link_info.main_u_info.a_push_token){
          render_str=(<span className="text-green-500" >{render_str}</span>);
        }else{
          render_str=(<span className="text-gray-500" >{render_str}</span>);
        }
      }
    }else if(key=="a_school_name"){
      render_str=strFunc.cut_str(render_str,6,"..");
    }
    
    return render_str;
  }, []);

  return (
    <MobileLayout
      has_header={false}
      container_style={{ marginTop: 0 }}>
      <div className="text-right mb-2 pt-1" style={{position:"relative"}}>
        <button className="btn btn-dark" 
          style={{position:"absolute",left:0,top:5}}
          onClick={()=>{
            navigate("/mobile/chat/room_list");
          }}>뒤로</button>
        <button className="btn btn-blue" onClick={()=>{
          make_room_by_select();
        }}>선택 방만들기</button>
      </div>
      <SearchArea
        listOpt={pageData.listOpt}
        user={user}
        list={list}
      ></SearchArea>
      {infoArr.length} 명
      <ListComponent
        ref={ListComponentRef}
        xColumnArr={xColumnArr}
        gridOpt={{
          is_add_idx_num:false,
          is_add_checkbox:true,
          td_align:"center",
          height:"52vh",
          onGridReady:()=>{
            list({});
          },
          customCellRenderer:customCellRenderer,
        }}
      ></ListComponent>
      <Paging now_page={pageData.listOpt.now_page}
        num_per_page={pageData.listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
    </MobileLayout>
  );
}

export default ChatRoomWrite;