import { useSelector, useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import { useState,useRef } from 'react';

function SearchArea(props: any) {
  let myProps = {
    listOpt: {

    },
    setListOpt:(inData: any) => { },
    list: (inData: any) => { },
    is_show_etc_group: false,
    set_is_show_etc_group: (inData: boolean) => { },
    show_group_time: {},
    set_group_time: (inData: any) => { },
    able_time_arr_ref: null,
    infoArr: [],
    save_daily_report_by_stu_arr: (inData: any) => { },
    delete_daily_report_by_stu_arr: (inData: any) => { },
    openAttendBySelect: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const [attend_btn_sort, set_attend_btn_sort] = useState("attend");
  const search_time_out_ref=useRef<any>(null);

  let attend_search_arr = [
    { value: "attend", text: "등원", background: "#53a500" },
    { value: "late", text: "지각", background: "#eab358" },
    { value: "absent", text: "결석", background: "#F26B6F" },
    // {value:"out",text:"하원"},
  ];

  const go_search_list=(inData:any)=>{
    if(search_time_out_ref.current){
      clearTimeout(search_time_out_ref.current);
    }
    search_time_out_ref.current=setTimeout(()=>{
      myProps.list(inData);
    },200);
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    
    myProps.setListOpt({ ...myProps.listOpt, [name]: value });
    go_search_list({ now_page: 1, [name]: value });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.listOpt.s_addon_daily_report_start_date)) {
      myProps.listOpt.s_addon_daily_report_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.listOpt.s_addon_daily_report_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_addon_daily_report_start_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    let change_s_list_json = {
      "now_page": "1",
      [key]: date_str,
    };
    if (key == "s_addon_daily_report_start_date") {
      change_s_list_json["s_addon_daily_report_start_date"] = date_str;
    }
    if (key == "s_addon_todo_end_date") {
      change_s_list_json["s_addon_todo_end_date"] = date_str;
    }

    myProps.list(change_s_list_json);
  };
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
  let start_date_json = DateFunc.get_date_json(new Date(myProps.listOpt.s_addon_daily_report_start_date));
  let select_day_str = DateFunc.get_day_str_by_day_num(start_date_json.day);
  let is_today_select = false;
  if (now_date_str == myProps.listOpt.s_addon_daily_report_start_date) {
    is_today_select = true;
  }

  let select_able_time_arr = [];
  if (myProps.able_time_arr_ref) {
    for (let i = 0; i < myProps.able_time_arr_ref.current.length; i++) {
      let item = myProps.able_time_arr_ref.current[i];
      if (!strFunc.is_empty(item)) {
        select_able_time_arr.push({ value: item, text: item });
      }
    }
  }

  return (
    <div style={{ background: "#fff", paddingBottom: 10 }}>
      <div className="search_wrap" >
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.listOpt.s_addon_daily_report_start_date != "" ? new Date(myProps.listOpt.s_addon_daily_report_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_daily_report_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <span className="ml-1" style={{ fontSize: "12px" }}>
          {select_day_str}
          {is_today_select && "(오늘)"}
        </span>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{ minWidth: 30, fontWeight: 500 }} onClick={() => {
          let tmp_row_y_m_d = DateFunc.get_date_format(new Date(), "Y-m-d");
          myProps.list({
            "now_page": "1",
            "s_addon_daily_report_start_date": tmp_row_y_m_d,
          });
        }}>오늘</button>
      </div>
      <div className="search_wrap mt-2" >
        <input type="text" value={myProps.listOpt.s_user_name_like}
          name="s_user_name_like"
          onChange={handleChangeInput} style={{ width: 65, paddingLeft: 5 }}
          autoComplete="off"
          placeholder="이름" />

        <SearchLectureSelect
          name="s_stu_lecture_seq"
          value={myProps.listOpt.s_stu_lecture_seq}
          on_change_input={(inData: any) => {
            let name = inData.name;
            let value = inData.value;
            myProps.list({ now_page: 1, [name]: value });
          }}
          wrap_style={{ width: 80, display: "inline-table", marginLeft: 2 }}
          select_title_style={{ lineHeight: "28px", height: 28, overflow: "hidden" }}
        ></SearchLectureSelect>
        <span className="basic_search_item_book_mark"
          onClick={() => {
            let change_s_stu_bookmark = myProps.listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {myProps.listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </span>

        <span className="ml-2">
          출석
          <select className="border" value={attend_btn_sort}
            onChange={(e: any) => { set_attend_btn_sort(e.target.value); }}>
            <option value="attend">등원</option>
            <option value="absent">결석</option>
            <option value="out">하원</option>
          </select>
          <button className="btn-m btn-gray mx-1"
            onClick={() => {
              let tmp_in_out = "in";
              if (attend_btn_sort == "out") {
                tmp_in_out = "out";
              }
              myProps.openAttendBySelect({
                in_out: tmp_in_out,
                sort_detail: attend_btn_sort,
              });
            }} >선택적용</button>
        </span>
      </div>
      <div className="search_wrap mt-2" style={{ position: "relative" }} >
        <SelectBoxBar
          valueTextArr={[
            { value: "", text: "전체" },
            { value: "now", text: "현재" },
            { value: "select", text: "선택" },
          ]}
          value={myProps.show_group_time.sort}
          on_change={(inData: any) => {
            let change_group_time = {
              ...myProps.show_group_time,
              sort: inData.value,
            };
            if (inData.value != "select") {
              strFunc.set_storage("change_group_time_sort", inData.value);
            }
            if (inData.value == "") {
              change_group_time.select_time_arr = [];
            } else if (inData.value == "now") {
              let now_date_h = parseInt(DateFunc.get_date_json(new Date()).h);
              let select_time_str = "";
              if (myProps.able_time_arr_ref) {
                for (let i = 0; i < myProps.able_time_arr_ref.current.length; i++) {
                  let item = myProps.able_time_arr_ref.current[i];
                  if (select_time_str == "" && item) {
                    let item_h = parseInt(strFunc.cut_str(item, 2, ""));
                    if (item_h >= now_date_h) {
                      select_time_str = item;
                    }
                  }
                }
              }
              change_group_time.select_time_arr = [select_time_str];
            }
            myProps.set_group_time(change_group_time);
          }}
        ></SelectBoxBar>
        <span style={{position:"absolute",left:10}}>
          <span style={{ color: "blue" }}>
            {myProps.infoArr.length} 건
          </span>
        </span>
        <span style={{ position: "absolute", right: 5 }}>
          <select
            className="border"
            value={myProps.show_group_time.select_time_arr.length > 0 ? myProps.show_group_time.select_time_arr[0] : ""}
            onChange={(e: any) => {
              let tmp_sort_str = "select";
              if (e.target.value == "") {
                tmp_sort_str = "";
              }
              myProps.set_group_time({
                ...myProps.show_group_time,
                sort: tmp_sort_str,
                select_time_arr: [e.target.value],
              });
            }} >
            <option value="">전체</option>
            {select_able_time_arr.map((item: any, index: number) => {
              return (
                <option key={index} value={item.value}>{item.text}</option>
              );
            })}
          </select>
          <button className={"btn-s2 ml-1 btn-" + (myProps.is_show_etc_group == "1" ? "sky" : "dark")}
            onClick={() => {
              let change_is_show_etc_group = (myProps.is_show_etc_group == "1" ? "" : "1");
              strFunc.set_storage("daily_report_is_show_etc_group", change_is_show_etc_group);
              myProps.set_is_show_etc_group(change_is_show_etc_group);
            }}
          >기타</button>
        </span>
      </div>
      <div className="search_wrap mt-2" style={{ position: "relative" }} >
        <span style={{ position: "absolute", right: 5 }}>
          <button className="btn-s btn-yellowish2"
            onClick={(e:any)=>{
              myProps.save_daily_report_by_stu_arr({});
            }}>선택수정</button>
          <button className="btn-s btn-red ml-1" onClick={(e:any)=>{
            myProps.delete_daily_report_by_stu_arr();
          }}>선택삭제</button>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;