import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { Link,useNavigate } from 'react-router-dom';
import Style from "../main/css/style.module.css";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useState,useRef,useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '@/store/user/user';
import MobileScript from "@/lib/mobile/web_script";
import strFunc from '@/lib/lyg/string';

function InviteMain(){
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginData,setLoginData]=useState({
    invite_code:"",
  });
  const [isCompLogin,setIsCompLogin]=useState(false);

  useEffect(()=>{
    
  },[]);

  const onChangInput=(e:any)=>{
    setLoginData({
      ...loginData,
      ...{[e.target.name]:e.target.value}
    });
  };

  const goLogin=()=>{
    if(loginData.invite_code==""){
      alert("초대코드 입력 필요.");
      return false;
    }

    let login_form_data={
      invite_code:loginData.invite_code,
    };

    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/basic/user/login_by_invite',login_form_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        if(response.data.data["token_id"]){
          localStorage.setItem('intoyou_token', response.data.data["token_id"]);
          var client_info=response.data.data["client_info"];
          dispatch(setUser(client_info));
          localStorage.sort_data_arr=JSON.stringify(client_info.sort_data);
          savePushToken();
          navigate("/mobile");
        }else if(response.data.data["comp_user_info"]){
          localStorage.tmp_comp_user_info=JSON.stringify(response.data.data["comp_user_info"]);
          localStorage.tmp_comp_id=response.data.data["mcomp_info"].a_id;
          navigate("/mobile/join");
        }
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const savePushToken=()=>{
    
    let fcm_token=MobileScript.get_fcm_token();
    if(strFunc.is_empty(fcm_token)){
      return false;
    }
    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/basic/user/save_token',{
      toekn: fcm_token,
    },get_axios_data()).then((response) => {
      if(response.data["result"]==="true"){

      }
    });
    
  };

  return (
    <MobileLayout has_header={false} has_footer={false} is_home_body={true} is_check_login={false} >
      <div className={Style.login_wrap}>
        <div className={Style.login_con}>
          <h1 style={{textAlign:"center",marginBottom:5}}>초대코드 입력</h1>
          <div className={Style.login_input_wrap}>
            <input type="text" placeholder="초대코드" autoComplete="off" 
              value={loginData.invite_code} name="invite_code" onChange={onChangInput} />
          </div>
          <div>
            <button className={Style.login_btn} onClick={()=>{goLogin();}} >로그인</button>
          </div>
          <div className={Style.login_bottom_btn_wrap}>
            <div className={Style.login_bottom_btn} onClick={()=>{navigate("/mobile/login");}}>
              뒤로
            </div>
          </div>
        </div>
      </div>
    </MobileLayout>
  );
}

export default InviteMain;