import StuArea from "@/pages/mobile/main/main/area/stu_area";

function MainCharPage(props:any){
   let myProps={
    ...props
  };
  
  return (
  <StuArea></StuArea>
  );
};
export default MainCharPage;