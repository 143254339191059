import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListSearchComponent from "../corse_list/area/list_search";
import ListCardComponent from "./area/list_area";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import VideoTabArea from "../common/tab";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import strFunc from "@/lib/lyg/string";

function MyVideoList(){
  let user=useSelector((state:any) => state.user);
  const navigate = useNavigate();
  const xColumnArr=getXcolumnJson();
  const [pageData, setPageData] = useState({
    listOpt:{
      ...xColumnArr.list_opt,
      "s_addon_title_img":"1",
      "s_addon_section":"1",
      "s_addon_video":"1",
      "s_addon_use_stu":"1",
      "s_addon_request":"1",
      "s_category_seq":"",
      "select_mcomp_seq":"",
      "select_mcomp_user_seq":"",
      "s_stu_seq":"",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [categoryInfoArr, setCategoryInfoArr] = useState([]);
  const [viewSort,setViewSort]=useState("list");//list,view
  const ListSearchComponentRef=useRef<any>(null);
  const ListCardComponentRef=useRef<any>(null);

  useEffect(() => {
    
    list({});
    list_category();
  }, [user.user_seq]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }
    let tmp_listOpt={...pageData.listOpt};
    let select_user_data=UserFunc.get_select_comp_user(user);
    if(strFunc.is_empty(select_user_data.select_mcomp_seq)){
      return false;
    }
    tmp_listOpt.select_mcomp_seq=select_user_data.select_mcomp_seq;
    tmp_listOpt.select_mcomp_user_seq=select_user_data.select_mcomp_user_seq;
    tmp_listOpt.s_stu_seq=select_user_data.select_mcomp_user_seq;
    
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/corse/list',tmp_listOpt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
        if(ListCardComponentRef.current){
          ListCardComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
        }
      }else{
        
      }
    });
  };

  const list_category = ()=>{
    let category_form_json={
      "select_mcomp_seq":"",
      "select_mcomp_user_seq":"",
      "s_iam_use_category":"1",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    let select_user_data=UserFunc.get_select_comp_user(user);
    if(strFunc.is_empty(select_user_data.select_mcomp_seq)){
      return false;
    }
    category_form_json.select_mcomp_seq=select_user_data.select_mcomp_seq;
    category_form_json.select_mcomp_user_seq=select_user_data.select_mcomp_user_seq;
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/plan/video/category/list',category_form_json,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setCategoryInfoArr(response.data["data"]["info_arr"]);
        if(ListSearchComponentRef.current){
          ListSearchComponentRef.current.setCategoryInfoArr(response.data["data"]["info_arr"]);
        }
      }else{
        
      }
    });
  };

  return (
    <MobileLayout>
      <VideoTabArea></VideoTabArea>
      <div className="stu_con_wrap">
        {viewSort=="list"&&
          <div>
            <ListSearchComponent
              ref={ListSearchComponentRef}
              categoryInfoArr={categoryInfoArr}
              listOpt={pageData.listOpt}
              list={(inOpt:any)=>{list(inOpt);}}
            ></ListSearchComponent>
            <ListCardComponent
              ref={ListCardComponentRef}
              xColumnArr={xColumnArr}
              onClickRow={(inOptObj:any)=>{
                navigate('/mobile/plan/video/my_list_view', 
                  { state: {row_data:inOptObj.data} });
              }}
              is_show_iam_ing={false}
            ></ListCardComponent>
            <Paging now_page={pageData.listOpt.now_page}
                  num_per_page={pageData.listOpt.num_per_page}
                  total_rec={countInfo.tot} 
                  onChangePage={(now_page:number)=>{list({now_page:now_page});}}></Paging>
          </div>
        }
      </div>
    </MobileLayout>
  );
}

export default MyVideoList;