import { useEffect,useState,forwardRef,useImperativeHandle } from 'react';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";

const MemberListArea = forwardRef((props:any, ref) => {
   let myProps={
    is_active:false,
    user:{},
    room_info:{},
    comp_api_key:"",
    room_mcomp_seq:"",
    iam_mcomp_user_seq:"",
    mem_info_arr:[],
    mem_now_arr:[],
    go_out_room:()=>{},
    open_invite_user_popup:()=>{},
    close:()=>{},
    list_member:()=>{},
    ...props
  };

  let comp_api_key=myProps.comp_api_key;
  let iam_mcomp_user_seq=myProps.iam_mcomp_user_seq;
  const [etcOpenIdx,setEtcOpenIdx] =useState(-1);

  useEffect(()=>{
    
  },[]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    
  }));

  let is_iam_owner=false;
  for(let i=0;i<myProps.mem_info_arr.length;i++){
    if(myProps.mem_info_arr[i]["a_user_seq"]==myProps.iam_mcomp_user_seq){
      if(myProps.mem_info_arr[i]["a_is_owner"]){
        is_iam_owner=true;
      }
    }
  }

  //강퇴
  const forceExitRoomUser=(memInfo:any)=>{
    if(memInfo==null){
      return false;
    }
    if(!confirm("강퇴 하시겠습니까?")){
      return false;
    }
    if (strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)) {
      alert("로그인 정보가 없습니다.");
      return false;
    }
    let out_room_opt={
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
      "room_seq":memInfo.a_room_seq,
      "mcomp_seq":memInfo.a_user_mcomp_seq,
      "user_seq":memInfo.a_user_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/room/out_room',out_room_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list_member();
        setEtcOpenIdx(-1);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  //방장주기
  const setOwnerRoomUser=(memInfo:any)=>{
    if(memInfo==null){
      return false;
    }
    if(!confirm("방장을 주시겠습니까?")){
      return false;
    }
    if (strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)) {
      alert("로그인 정보가 없습니다.");
      return false;
    }
    let up_data_row={
      a_room_seq:memInfo.a_room_seq,
      a_user_mcomp_seq:memInfo.a_user_mcomp_seq,
      a_user_seq:memInfo.a_user_seq,
      a_is_owner:"1",
    };
    let mem_w_form={
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
      "data_arr":[up_data_row],
      "is_update":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/chat/member/write',mem_w_form,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        myProps.list_member();
        setEtcOpenIdx(-1);
      }else{
        alert(response.data["msg"]);
      }
    });
  };

  const get_mem_list_tags=()=>{
    let mem_list_tags=myProps.mem_info_arr.map((item:any,idx:number)=>{
      let mem_item_class_name="chat_view_mem_list_item";

      for(let i=0;i<myProps.mem_now_arr.length;i++){
        if(myProps.mem_now_arr[i]["user_seq"]==item.a_user_seq
          &&myProps.mem_now_arr[i]["comp_seq"]==item.a_user_mcomp_seq){
          mem_item_class_name+=" active";
        }
      }

      let iam_str:any="";
      let row_is_iam=false;
      if(item.a_user_seq==myProps.iam_mcomp_user_seq
        &&item.a_user_mcomp_seq==myProps.room_mcomp_seq){
          iam_str=<span style={{color:"#f3ff81"}}>●</span>;
          row_is_iam=true;
      }

      let owner_str="";
      if(item.a_is_owner=="1"){
        owner_str="👑";
      }

      let mem_name=item.a_user_name;
      if(item.user_info&&item.user_info["a_user_grade"]!="student"){
        if(!strFunc.is_empty(item.user_info["a_user_nickname"])){
          mem_name=item["user_info"]["a_user_nickname"];
        }
      }

      return (
        <div key={idx} className={mem_item_class_name}>
          {idx+1}. {mem_name} {iam_str} {owner_str}
          {(is_iam_owner&&row_is_iam==false)&&
          <div className="chat_user_list_row_right">
            {etcOpenIdx==idx &&
              <span>
                <button className="btn-s btn-dark" onClick={()=>{forceExitRoomUser(item);}} >강퇴</button>
                <button className="btn-s btn-dark m-1" onClick={()=>{setOwnerRoomUser(item);}} >방장주기</button>
              </span>
            }
            <button className="btn-s btn-gray"
              style={{padding:"0 6px",paddingBottom:3}}
              onClick={()=>{toggleOpenIdx(idx);}} >..</button>
          </div>
          }
        </div>
      );
    });
    return mem_list_tags;
  };

  const toggleOpenIdx=(idx:number)=>{
    if(etcOpenIdx==idx){
      setEtcOpenIdx(-1);
    }else{
      setEtcOpenIdx(idx);
    }
  };

  let mem_wrap_class_name="chat_view_mem_wrap";
  if(myProps.is_active){
    mem_wrap_class_name+=" active";
  }

  return (
  <div className={mem_wrap_class_name}>
    <div className="chat_view_mem_con">
      <button className="btn btn-dark" 
        style={{position:"absolute",top:0,right:0,width:50,height:45}}
        onClick={()=>{myProps.close();}}>X</button>
      <h5 style={{lineHeight:"42px"}}>멤버리스트</h5>
      <div className="chat_view_mem_list_wrap">
        {get_mem_list_tags()}
      </div>
    </div>
    <div className='chat_view_mem_bottom_wrap'>
      <button className="btn btn-dark" onClick={()=>{myProps.go_out_room();}}>나가기</button>
      <button className="btn btn-dark ml-1" onClick={()=>{myProps.open_invite_user_popup();}}>초대</button>
    </div>
  </div>
  );
});
export default MemberListArea;