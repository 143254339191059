import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useRef, useEffect } from 'react';
import CommentPartArea from "@/pcomponents/common/content/comment/part_area";
import ImgFileWriteList from "@/pages/mobile/manage/basic/file/frame/img/write_list";
import { month_prescribe_select_arr } from "@/pcomponents/common/content/edu/month_prescribe/data/default_data";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import DailyLastArea from "@/pages/mobile/manage/edu/month_prescribe/common/write/area/daily_last";

function CardBoxArea(props: any) {
  let myProps = {
    info: {},
    row_num: 0,
    select_stu_grade_option_arr: [],
    Style: {},
    openOnePopup: (inData: any) => { },
    list: (inData: any) => { },
    last_daily_report: {},
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let select_stu_grade_option_arr = myProps.select_stu_grade_option_arr;
  let row_num_str = myProps.row_num + 1;

  let stu_name_str = info.a_stu_name;
  let stu_total_code = "";
  let stu_grade_str = "";
  let stu_school_name = "";
  if (info.stu_info) {
    stu_name_str = info.stu_info["a_user_name"];
    stu_total_code=info.stu_info["a_stu_total_code"];
    stu_grade_str = info.stu_info["a_stu_grade"];
    stu_school_name = info.stu_info["a_school_name"];
    if (select_stu_grade_option_arr) {
      for (let i = 0; i < select_stu_grade_option_arr.length; i++) {
        if (select_stu_grade_option_arr[i]["value"] == stu_grade_str) {
          stu_grade_str = select_stu_grade_option_arr[i]["text"];
        }
      }
    }
  }
  stu_school_name = stu_school_name.substring(0, 2);

  let week_name_str = "";
  if (info["week_i"] == -1) {
    week_name_str = "전달";
  } else {
    let week_i_num: any = parseInt(strFunc.uncomma(info.week_i));
    week_i_num++;
    week_name_str = info["month_num"] + "월 " + week_i_num + "주";
  }

  let row_pri_val = info["a_ymd"] + "," + info["a_seq"];
  let comment_init_data = {
    writer_seq: info.a_writer_seq,
    writer_name: info.a_writer_name,
    par_id: "month_prescribe",
    par_seq: row_pri_val,
    par_locate: "",
    par_locate_name: "",
    s_start_date: info["a_reserve_date"],
    s_end_date: DateFunc.get_date_format(new Date(), "Y-12-31"),
  };

  const ImgFileWriteListRef = useRef<any>(null);
  let img_file_init_list_opt = {
    "s_start_date": DateFunc.get_date_format(new Date(info["a_reserve_date"]), "Y-01-01"),
    "s_par_id": "month_prescribe",
    "s_par_seq": row_pri_val,
    "s_sort1": "a_lack",
    "s_sort2": "",
    "s_sort3": "",
  };

  useEffect(() => {
    if (ImgFileWriteListRef.current) {
      ImgFileWriteListRef.current.set_init_data({
        listOpt: img_file_init_list_opt,
        is_init_list: false,
        info_arr: info.file_arr ? info.file_arr : []
      });
    }
  }, [info]);

  let comment_list = [];
  if (info.comment_arr) {
    comment_list = info.comment_arr;
  }

  let card_box_wrap_style: any = {};

  if (comment_list.length > 0) {
    card_box_wrap_style["background"] = "#ffeeee";
    let comment_last_idx = comment_list.length - 1;
    let comment_last_info = comment_list[comment_last_idx];
    if (comment_last_info["a_qna_sort"] == "a") {
      card_box_wrap_style["background"] = "#fffaf3";
    }
  }

  if (info["a_is_success"] == "1") {
    card_box_wrap_style["background"] = "#f2fff2";
  }

  return (
    <div className={Style.card_box_wrap} style={card_box_wrap_style} >
      <div>
        [{row_num_str}]
        <span className="ml-1" style={{ color: "blue", fontWeight: "bold", fontSize: "14px" }}>
          {stu_name_str}
        </span>
        {stu_total_code &&
          <span className="ml-1" style={{ color: "#3fac96" }}>
            {stu_total_code}
          </span>
        }
        <span className="ml-1" style={{ color: "#777" }}>
          {stu_school_name} {stu_grade_str}
        </span>
      </div>
      <div className="text-center" style={{ color: "#777" }}>
        <span style={{ color: "#fd00ff", fontWeight: "bold" }}>
          {info["a_subject_name"]}
        </span>
        <span className="ml-2">
          {info["a_writer_name"]}
        </span>
        <span className="ml-2">
          {info["a_reserve_date"]} {week_name_str}
        </span>
        {info["a_is_reserve"]=="1"&&
          <span className="btn-s btn-dark ml-1">예약</span>
        }
      </div>
      <div>
        <div className={Style.card_box_row_title}>
          1. 진도전략
        </div>
        <div className={Style.card_box_row_content}>
          <MultyLineTextView text={info["a_progress_reason"]}></MultyLineTextView>
        </div>
        <div className={Style.card_box_row_title}>
          2. 현재 진도
        </div>
        <DailyLastArea
          last_daily_report={myProps.last_daily_report}
        ></DailyLastArea>
        <div className={Style.card_box_row_content}>
          {/* <MultyLineTextView text={info["a_progress"]}></MultyLineTextView> */}
        </div>
        <div className={Style.card_box_row_title}>
          3. 성장한 부분
        </div>
        <div className={Style.card_box_row_content}>
          <MultyLineTextView text={info["a_grow"]}></MultyLineTextView>
        </div>
        <div className={Style.card_box_row_title}>
          <span style={{ background: "#fff59c", paddingRight: 5 }}>4. 발견</span>
        </div>
        <div className="write_table_base table_border mt-1">
          <table>
            <colgroup>
              <col width={"30%"}></col>
              <col width={"70%"}></col>
            </colgroup>
            <tbody>
              <tr>
                <th>숙제 수행도</th>
                <td>
                  <SelectBoxBar
                    valueTextArr={month_prescribe_select_arr["a_homework_degree"]}
                    value={info.a_homework_degree}
                    name={"a_homework_degree"}
                    on_change={(inData: any) => {

                    }}
                    style_type={"wide"}
                  ></SelectBoxBar>
                </td>
              </tr>
              <tr>
                <th>이해점수</th>
                <td>
                  <SelectBoxBar
                    valueTextArr={month_prescribe_select_arr["a_understand_degree"]}
                    value={info.a_understand_degree}
                    name={"a_understand_degree"}
                    on_change={(inData: any) => {

                    }}
                    style_type={"wide"}
                  ></SelectBoxBar>
                </td>
              </tr>
              <tr>
                <th>하루학습량</th>
                <td>
                  <MultyLineTextView text={info["a_study_amount"]}></MultyLineTextView>
                </td>
              </tr>
              <tr>
                <th>수업태도</th>
                <td>
                  <SelectBoxBar
                    valueTextArr={month_prescribe_select_arr["a_lesson_attitude"]}
                    value={info.a_lesson_attitude}
                    name={"a_lesson_attitude"}
                    on_change={(inData: any) => {

                    }}
                    style_type={"wide"}
                  ></SelectBoxBar>
                </td>
              </tr>
              <tr>
                <th>학습정서(감정)</th>
                <td>
                  <MultyLineTextView text={info.a_study_emotion}></MultyLineTextView>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="text-left pl-2" style={{ fontWeight: "600" }}>새로운 발견 (상황중심기술)</div>
                  <div className="text-left mt-1 pl-2" style={{ minHeight: 20 }}>
                    <MultyLineTextView text={info.a_lack}></MultyLineTextView>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <ImgFileWriteList
            ref={ImgFileWriteListRef}
            is_view={false}
            listOpt={img_file_init_list_opt}
            is_save_btn={false}
            is_select_save={true}
            is_init_list={false}
            info_arr={info.file_arr ? info.file_arr : []}
            callback_write={() => {
              myProps.list({});
            }}
            delete_callback={() => {
              myProps.list({});
            }}
          ></ImgFileWriteList>
        </div>
        <CommentPartArea
          info_arr={comment_list}
          list={myProps.list}
          is_show_toggle={false}
          is_show_detail={true}
          is_send_write_push={true}
          title_str={"질문"}
          wrap_style={{ width: "100%" }}
          init_data={{
            ...comment_init_data,
            par_locate: "a_lack",
            par_locate_name: "질문",
          }}
        ></CommentPartArea>
        <div className={Style.card_box_row_title}
          style={{ color: "#fff", background: "#2d8372", position: "relative" }} >
          5. 학습 처방
          <span style={{ position: "absolute", right: 5 }} >
            {info["a_is_request_counsel"] == "1" &&
              <span style={{ color: strFunc.is_empty(info.a_request_counsel_seq) ? "yellow" : "#90fe86" }}>
                상담요청
                {(info.counsel_arr && info.counsel_arr.length > 0) &&
                  "(" + info.counsel_arr.length + ")"
                }
                <span className="ml-1"
                  style={{ color: strFunc.is_empty(info.a_request_counsel_seq) ? "#ddd" : "#fff" }} >
                  {info["a_request_counsel_writer"]}
                </span>
              </span>
            }
            {(info["a_is_success_check"] == "1" && info["a_is_success"] == "1") &&
              <span className="ml-2" style={{ color: "yellow" }}>
                완료확인
              </span>
            }
          </span>
        </div>
        <div className={Style.card_box_row_content}>
          <MultyLineTextView text={info["a_content"]}></MultyLineTextView>
        </div>
        {(info["a_is_success_check"] == "1" && info["a_is_success"] == "1") &&
          <div className={Style.card_box_row_content}>
            <MultyLineTextView text={info["a_success_check_msg"]}></MultyLineTextView>
          </div>
        }
        <CommentPartArea
          info_arr={comment_list}
          list={myProps.list}
          is_show_toggle={false}
          is_show_detail={true}
          is_send_write_push={true}
          title_str={"질문"}
          wrap_style={{ width: "100%" }}
          init_data={{
            ...comment_init_data,
            par_locate: "a_content",
            par_locate_name: "질문",
          }}
        ></CommentPartArea>
      </div>
      <div className="text-center mt-1">
        <button className="btn btn-line-gray"
          onClick={() => {
            myProps.openOnePopup({
              sort: "write",
              isUpdate: true,
              data_arr: [info],
            });
          }}>수정</button>
        <button className="btn btn-line-gray ml-1"
          onClick={() => {
            myProps.openOnePopup({
              sort: "view",
              isUpdate: true,
              data_arr: [info],
            });
          }}>보기</button>
      </div>
    </div>
  );
};
export default CardBoxArea;