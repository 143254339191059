import MobileScript from "@/lib/mobile/web_script";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";

function LogoutByAjax(inData:any){
  let opt_obj={
    my_axios:null,
    dispatch:null,
    removeUser:null,
    navigate:null,
    ...inData,
  };
  opt_obj.my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/basic/user/login/logout',{ 
    token_id: localStorage.intoyou_token,
    app_sort:MobileScript.checkMobileApp()
  },get_axios_data()).then((response:any) => {
    opt_obj.dispatch(opt_obj.removeUser());
    localStorage.removeItem("intoyou_token");
    localStorage.removeItem('token_update_date_last');
    localStorage.removeItem('token_update_date_last2');
    localStorage.removeItem('version_check_date_last');
    MobileScript.set_login_token("");
    opt_obj.navigate("/mobile/login");
  });
};
export default LogoutByAjax;