import { Link,useNavigate } from 'react-router-dom';
import SvgIcon from "../manage_footer/svg/icon";

const StuFooter=(props:any)=>{
  let myProps={
    is_home_body:false,
    user:{},
    ...props
  };
  const navigate = useNavigate();
  let foot_menu_arr=[
    {key:"home","text":"홈","url":"/mobile"},
    {key:"my_plan","text":"MY플랜","url":"/mobile/my_plan"},
    {key:"home_work","text":"과제","url":"/mobile/home_work"},
    {key:"chat","text":"플랜톡","url":"/mobile/chat/room_list"},
    {key:"sche","text":"스케줄","url":"/mobile/sche"},
  ];

  const goPage=(url:string)=>{
    navigate(url);
  };

  let foot_menu_key="";
  for(let i=0;i<foot_menu_arr.length;i++){
    if(foot_menu_arr[i].url==window.location.pathname){
      foot_menu_key=foot_menu_arr[i].key;
    }
  }
  const foot_menu_tags=foot_menu_arr.map((item:any,idx:number)=>{
    let active_class_name="";
    let is_active=false;
    let active_fill_color="#3FAC96";
    if(myProps.is_home_body){
      active_fill_color="#ffffff";
    }
    if(item.key==foot_menu_key){
      active_class_name=" active";
      is_active=true
    }
    return (
      <a className={"m_footer_manage_item"+active_class_name} key={idx} 
        onClick={()=>{goPage(item.url);}}>
        <SvgIcon
          icon_key={item.key}
          is_active={is_active}
          active_fill_color={active_fill_color}
        ></SvgIcon>
        <br />
        {item.text}
      </a>
    );
  });

  return (
    <div id="m_footer">
      <div className="m_footer_con noto_sans">
        {foot_menu_tags}
      </div>
    </div>
  );
};

export default StuFooter;