import { useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import TodoViewPopup from "@/pages/mobile/plan/todo/popup/view";
import ViewTodoVideoPopup from "@/pages/mobile/plan/todo/popup/view_video";
import ChildDataFunc from "@/pcomponents/common/content/parent/func/child_data";
import CardListArea from "@/pages/mobile/parent/plan/common/list/card_list";
import SearchArea from "@/pages/mobile/parent/plan/common/list/search";
import FileSubmitArea from "@/pages/mobile/plan/home_work_file/out_area/file_submit";

function TodoListArea(props:any) {
  let myProps={
    s_todo_sort:"homework",//study
    plan_study_file_id:"home_work_file",
    s_todo_sort_except:"",//homework
    back_press_url:"/mobile/parent/plan/homework_list",
    ...props,
  };
  let user = useSelector((state: any) => state.user);
  let group_child_data = ChildDataFunc.get_child_arr_by_login_user(user);
  let comp_stu_row_arr = group_child_data.comp_stu_row_arr;
  let select_stu_row_idx = group_child_data.select_stu_row_idx;
  let select_comp_stu_row = group_child_data.select_comp_stu_row;
  let init_list_opt={
    "s_todo_sort": myProps.s_todo_sort,
    "s_todo_sort_except":myProps.s_todo_sort_except,
  };
  if(myProps.s_todo_sort=="study"){
    init_list_opt.s_todo_sort="";
  }
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 100,
    'order_id': 'a_reserve_start_date DESC, a_create_date DESC',
    'order_type': '',
    's_date_type': 'a_reserve_start_date',
    's_start_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    's_end_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    "is_add_idx_info": "1",
    "s_addon_file_arr": "1",
    "s_addon_par_content": "1",
    "s_user_key": "",
    "s_addon_elapse_time": "1",
    ...init_list_opt,
  });

  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [home_work_file_arr, set_home_work_file_arr] = useState([]);
  const plan_study_file_id = myProps.plan_study_file_id;

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "todo_view",//todo_view, todo_write
    isUpdate: false,
    rowData: {},
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },

    title: "팝업",
    width: "500px",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  useEffect(() => {
    list({});
  }, [user]);

  const list = (inOptObj: any) => {
    let tmp_list_opt = { ...listOpt, ...inOptObj };
    setListOpt(tmp_list_opt);

    let select_mcomp_link_val = "";
    if (!strFunc.is_empty(select_comp_stu_row) && select_comp_stu_row.user_seq) {
      select_mcomp_link_val = select_comp_stu_row.mcomp_seq + "," + select_comp_stu_row.user_seq;
    }
    if (strFunc.is_empty(select_mcomp_link_val)) {
      return false;
    }
    tmp_list_opt.s_user_key = select_mcomp_link_val;
    list_home_work_files();

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/todo/todo/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const list_home_work_files = () => {
    let home_work_file_list_opt = {
      'now_page': 1,
      'num_per_page': 1000,
      'order_id': 'a_create_date DESC',
      'order_type': '',
      's_date_type': 'a_create_date',
      's_start_date': listOpt.s_start_date,
      's_end_date': listOpt.s_end_date,
      "is_add_idx_info": "1",
      "s_comp_seq": "",
      "s_par_id": plan_study_file_id,
      "s_par_seq": "",
      "s_sort1": "",
      "s_sort3": "",
    };

    let s_stu_mcomp_seq = "";
    let s_stu_mcomp_user_seq = "";
    if (!strFunc.is_empty(select_comp_stu_row) && select_comp_stu_row.user_seq) {
      s_stu_mcomp_seq = select_comp_stu_row.mcomp_seq;
      s_stu_mcomp_user_seq = select_comp_stu_row.user_seq;
    }
    if (strFunc.is_empty(s_stu_mcomp_seq)) {
      return false;
    }
    if (strFunc.is_empty(s_stu_mcomp_user_seq)) {
      return false;
    }
    home_work_file_list_opt["s_comp_seq"] = s_stu_mcomp_seq;
    home_work_file_list_opt["s_sort3"] = s_stu_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', home_work_file_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_home_work_file_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const open_todo_view_popup = (inData: any) => {
    let opt_obj = {
      todo_info: {},
      ...inData
    };
    let pop_title = "보기";
    let popup_sort="todo_view";
    if(opt_obj.todo_info["a_par_id"]=="video_list"){
      popup_sort="todo_view_video";
    }

    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: popup_sort,
      rowData: opt_obj["todo_info"],
      isUpdate: true,
      user_data: {
        iam_mcomp_seq: select_comp_stu_row.mcomp_seq,
        iam_mcomp_user_seq: select_comp_stu_row.user_seq,
        comp_api_key: select_comp_stu_row.comp_api_key,
      },
      "title": pop_title,
    });
  };

  return (
    <div>
      <SearchArea
        listOpt={listOpt}
        list={list}
      ></SearchArea>
      <CardListArea
        info_arr={infoArr}
        on_click_card_box={(inData: any) => {
          open_todo_view_popup(inData);
        }}
        list={list}
        group_sort="a_sort"
        default_group_key={myProps.s_todo_sort}
      ></CardListArea>
      <FileSubmitArea
        title={"과제 제출하기"}
        back_press_url={myProps.back_press_url}
        s_date={listOpt.s_start_date}
        file_arr={home_work_file_arr}
        list_opt={listOpt}
        s_par_id={plan_study_file_id}
        is_show_audio={false}
        is_show_video={false}
        user_data={{
          "iam_mcomp_seq": select_comp_stu_row ? select_comp_stu_row["mcomp_seq"] : "",
          "iam_mcomp_user_seq": select_comp_stu_row ? select_comp_stu_row["user_seq"] : "",
          "comp_api_key": select_comp_stu_row ? select_comp_stu_row["comp_api_key"] : "",
        }}
      ></FileSubmitArea>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
          title={popupData.title}
          has_header={false}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "todo_view" &&
            <TodoViewPopup
              todo_info={popupData.rowData}
              user_data={popupData.user_data}
              on_go_write_page={() => {
                setPopupData({ ...popupData, "sort": "todo_write" });
              }}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></TodoViewPopup>
          }
          {popupData.sort === "todo_view_video" &&
            <ViewTodoVideoPopup
              todo_info={popupData.rowData}
              user_data={popupData.user_data}
              callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewTodoVideoPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default TodoListArea;