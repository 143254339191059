import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';

function BottomButtonArea(props: any) {
  let myProps = {
    Style: {},
    todo_info: {},
    completeOne: () => { },
    goWriteTodoInfo: (inData: any) => { },
    goBackUrl: () => { },
    ...props
  };
  let Style = myProps.Style;
  let todo_info = myProps.todo_info;
  let amount_key = "a_amount_num";
  let amount_num = 0;
  let max_amount = 0;
  if (todo_info.a_success_sort == "time") {
    amount_key = "a_time_sec";
    max_amount = parseInt(strFunc.uncomma(todo_info["a_success_time_sec"]));
    todo_info[amount_key] = myProps.elapse_time_sec;
  } else if (todo_info.a_success_sort == "amount") {
    max_amount = parseInt(strFunc.uncomma(todo_info["a_success_amount"]));
  }
  amount_num = todo_info[amount_key];

  const update_up_down_amount = (up_down: "up" | "down") => {
    amount_num = parseInt(strFunc.uncomma(amount_num));
    let change_amount = amount_num;
    if (up_down == "up") {
      change_amount = amount_num + 1;
    } else {
      change_amount = amount_num - 1;
    }
    update_amount_of_todo_info(change_amount);
  };

  const update_amount_of_todo_info = (change_amount: number) => {
    todo_info[amount_key] = change_amount;
    if (amount_num > 0) {
      if (todo_info["a_is_success"] == "1") {
        todo_info["a_state"] = "stop";
        todo_info["a_is_success"] = "";
      }
    }

    if (todo_info["a_start_date"].substring(0, 10) == "0000-00-00") {
      todo_info["a_start_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
    }

    if (change_amount >= max_amount) {
      todo_info["a_state"] = "complete";
      todo_info["a_is_success"] = "1";
      todo_info["a_end_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      if (todo_info["a_state"] == "ing") {
        myProps.onStop();
      }
    }

    myProps.goWriteTodoInfo({
      todo_info: todo_info
    });
  };

  const get_action_button = () => {
    let btn_tag: any = "";
    if (todo_info.a_is_success == "1") {
      btn_tag = (
        <div className={Style.bottom_btn+" "+Style.active} onClick={() => {
          if (!confirm("완료를 취소 하시겠습니까?")) {
            return false;
          }
          myProps.goWriteTodoInfo({
            todo_info: {
              a_is_success: "",
              a_state: "stop",
            },
          });
        }} >
          완료취소
        </div>
      );
    } else {
      if (amount_num < max_amount) {
        btn_tag = (
          <div className={Style.bottom_btn+" "+Style.active} onClick={()=>{update_up_down_amount("up");}} >
            +1개 완료
          </div>
        );
      } else {
        btn_tag = (
          <div className={Style.bottom_btn+" "+Style.active} onClick={myProps.completeOne} >
            완료하기
          </div>
        );
      }
    }

    return btn_tag;
  };

  return (
    <div className={Style.bottom_btn_wrap}>
      <div className={Style.bottom_btn} onClick={myProps.goBackUrl}>
        목록으로
      </div>
      {get_action_button()}
    </div>
  );
};
export default BottomButtonArea;