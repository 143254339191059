import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderWrapArea from "@/pcomponents/common/content/layout/header_wrap";
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import { useState,useRef,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import TitleBarArea from "@/pcomponents/common/content/list/title_bar";
import SearchArea from "./area/search";
import ListCardArea from "./area/list_card";
import EvalFunc from "@/pcomponents/common/content/eval/func/eval_func";

function ManageEvalListPage(){
  let user=useSelector((state:any) => state.user);
  let now_y_m_d=DateFunc.get_date_format(new Date(), "Y-m-d");
  const [pageData, setPageData] = useState({
    listOpt:{
      'now_page':1,
      'num_per_page':300,
      'order_id':'a_user_name',
      'order_type':'',
      "is_add_idx_info":"1",
      s_is_login:"1",
      s_stu_state:"now",
      s_grade: ["student"],
      s_user_name_like:"",
      s_school_name_like:"",
      s_class_seq:"",
      "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
      s_addon_class_user: "1",
      s_addon_link_user: "1",

      s_addon_eval: "1",
      s_addon_eval_s_form_seq: "",
      s_addon_eval_s_start_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_eval_s_end_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
      s_addon_eval_detail: "1",
      s_addon_eval_form: "1",

      s_is_except_eval_user: "",
      s_except_user_seq: "",
    }
  });

  const [list_sort,set_list_sort]=useState("card");//list
  const [infoArr, setInfoArr] = useState([]);
  const [show_info_idx,set_show_info_idx]=useState(0);
  const [form_list, set_form_list] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [stu_class_info_arr,set_stu_class_info_arr]=useState([]);
  const [refresh,set_refresh]=useState(false);

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key="";
  if(user.select_mcomp_link&&user.select_mcomp_link["a_mcomp_user_seq"]){
    iam_mcomp_seq=user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq=user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key=user.select_mcomp_link["comp_api_key"];
  }

  useEffect(()=>{
    
    list({});
    get_eval_forms_by_ajax();
    get_stu_class_list_by_ajax();
  },[user]);

  const list = (inOptObj:any)=>{
    if(inOptObj!=undefined){
      let tmpPageData:any=pageData;
      for(let key in tmpPageData.listOpt){
        if(inOptObj[key]!=undefined){
          tmpPageData.listOpt[key]=inOptObj[key];
        }
      }
      setPageData({...tmpPageData});
    }

    let tmp_list_opt:any={...pageData.listOpt};
    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_seq)){
      return false;
    }
    
    tmp_list_opt["s_mcomp_seq"]=iam_mcomp_seq;
    tmp_list_opt["s_mcomp_user_seq"]=iam_mcomp_user_seq;

    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/basic/comp_user/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        setInfoArr(response.data["data"]["info_arr"]);
        setCountInfo(response.data["data"]["count_info"]);
      }else{
        
      }
    });
  };

  const get_eval_forms_by_ajax = () => {
    let s_form_json:any = {
      "s_addon_detail": "1",
      "s_is_use": "1",
      "order_id": "a_title",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    
    s_form_json["api_key"]=comp_api_key;
    s_form_json["api_user_seq"]=iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval_form/list', s_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_form_list(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_stu_class_list_by_ajax=()=>{
    if(strFunc.is_empty(comp_api_key)||strFunc.is_empty(iam_mcomp_user_seq)){
      alert("학원 로그인 정보 없음.");
      return false;
    }
    let tmp_list_opt={
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq,
      "order_id":"a_name",
      "is_need_count":"",
      "is_need_info_arr":"1",
      "is_no_limit":"1",
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/edu/class/class/list',tmp_list_opt,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_stu_class_info_arr(response.data["data"]["info_arr"]);
      }else{
        
      }
    });
  };

  const write_multy_eval = () => {
    //infoArr
    let eval_data_arr=[];
    for(let i=0;i<infoArr.length;i++){
      let info:any=infoArr[i];
      if(info.eval_arr&&info.eval_arr.length>0){
        let eval_info=info.eval_arr[0];
        if(eval_info.detail_arr){
          let tmp_total_data=EvalFunc.get_detail_total_data_by_detail_arr(eval_info.detail_arr);
          eval_info["a_average_score"]=tmp_total_data["average_score"];
          eval_info["a_item_amout"]=tmp_total_data["count"];
        }
        eval_data_arr.push(eval_info);
      }
    }

    if (eval_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }

    let eval_w_form_json = {
      "data_arr": eval_data_arr,
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval/add_with_detail', eval_w_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_write_eval = (inData: any) => {
    let opt_obj = {
      info: null,
      ...inData
    };

    if (!confirm("저장 하시겠습니까?")) {
      return false;
    }

    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }

    let eval_w_form_json = {
      "data_arr": [opt_obj["info"]],
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval/write', eval_w_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          write_detail_by_ajax(response.data["data"][0], opt_obj["info"]["detail_arr"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const write_detail_by_ajax = (eval_info: any, detail_arr: any) => {
    let detail_form_json:any = {
      "detail_arr": detail_arr,
      "eval_seq": eval_info["a_ymd"] + "," + eval_info["a_seq"],
    };

    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }
    
    detail_form_json["api_key"]=comp_api_key;
    detail_form_json["api_user_seq"]=iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/evald/add_of_eval', detail_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("성공");
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_delete = (inData: any) => {
    let opt_obj = {
      "info_arr": [],
      ...inData
    };
    if (opt_obj["info_arr"].length == 0) {
      alert("선택이 없습니다.");
      return false;
    }
    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    if(strFunc.is_empty(comp_api_key)){
      return false;
    }
    if(strFunc.is_empty(iam_mcomp_user_seq)){
      return false;
    }

    let del_form_json = {
      "data_arr": opt_obj["info_arr"],
      "api_key":comp_api_key,
      "api_user_seq":iam_mcomp_user_seq
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/eval/delete', del_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제되었습니다.");
          list({ "now_page": "1" });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
  <MobileLayout 
    has_header={false}
    container_style={{ marginTop: 150 }}
    container_con_style={{ width: "100%",background:"#F1F4F4" }} >
    <HeaderWrapArea>
      <TitleBarArea title={"수업평가서 ("+(show_info_idx+1)+"/"+infoArr.length+")"} 
        list_sort={list_sort} set_list_sort={set_list_sort}></TitleBarArea>
      <SearchArea
        list_opt={pageData.listOpt}
        form_list={form_list}
        list={list}
        stu_class_info_arr={stu_class_info_arr}
      ></SearchArea>
    </HeaderWrapArea>
    <ListCardArea
      user={user}
      list_sort={list_sort}
      info_arr={infoArr}
      show_info_idx={show_info_idx}
      set_show_info_idx={(inData:any)=>{
        set_show_info_idx(inData);
        set_refresh(!refresh);
      }}
      form_list={form_list}
      select_form_seq={pageData.listOpt.s_addon_eval_s_form_seq}
      go_write_eval={go_write_eval}
      go_delete={(inData: any) => { go_delete(inData); }}
      setInfoArr={(inData: any) => {
        setInfoArr(inData);
        set_refresh(!refresh);
      }}
    ></ListCardArea>
  </MobileLayout>
  );
};
export default ManageEvalListPage;