import React,{ forwardRef,useImperativeHandle,useState,useRef,useEffect } from 'react';
import ChatRowTag from "./row";

const ChatListArea = forwardRef((props:any, ref) => {
  let myProps = {
    room_info: {},
    mem_info_arr:[],
    chat_info_arr: [],
    user:{},
    iam_mcomp_user_seq:"",
    ...props
  };

  const [chat_info_arr,set_chat_info_arr]=useState(myProps.chat_info_arr);
  const chat_view_msg_wrap_ref=useRef<any>();

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    setScrollBottom,set_chat_info_arr
  }));
  
  const setScrollBottom=()=>{
    if(chat_view_msg_wrap_ref.current){
      (chat_view_msg_wrap_ref.current as HTMLElement).scrollTop=(chat_view_msg_wrap_ref.current as HTMLElement).scrollHeight;
    }
  };

  let pre_create_date_str="";
  let pre_title_a_writer_seq="";
  const getChatRowTags=()=>{
    return myProps.chat_info_arr.map((chat_info:any,idx:number)=>{
      let is_display_title=true;
      if(pre_title_a_writer_seq==chat_info.a_writer_seq){
        is_display_title=false;
      }
      pre_title_a_writer_seq=chat_info.a_writer_seq;
      
      let chat_row_tag=(
        <ChatRowTag
          is_display_title={is_display_title}
          room_info={myProps.room_info}
          mem_info_arr={myProps.mem_info_arr}
          chat_info={chat_info}
          user={myProps.user}
          iam_mcomp_user_seq={myProps.iam_mcomp_user_seq}
          pre_create_date_str={pre_create_date_str}
        ></ChatRowTag>
      );
      pre_create_date_str=chat_info.a_create_date.substring(0,10);
      return (
        <React.Fragment key={idx} >
          {chat_row_tag}
        </React.Fragment>
      );
    });
  };
  return (
    <div className="chat_view_msg_wrap" ref={chat_view_msg_wrap_ref}>
      {getChatRowTags()}
    </div>
  );
});
export default ChatListArea;