import attend_img from "@/img/ico/parent_menu/attend.png";
import eval_check_img from "@/img/ico/parent_menu/eval_check.png";
import homework_img from "@/img/ico/parent_menu/homework.png";
import option_img from "@/img/ico/parent_menu/option.png";
import par_notice_img from "@/img/ico/parent_menu/par_notice.png";
import pay_img from "@/img/ico/parent_menu/pay.png";
import plan_img from "@/img/ico/parent_menu/plan.png";
import talk_img from "@/img/ico/parent_menu/talk.png";

let menu_data_arr:any=[
  {"key":"par_notice","title":"알림장","icon":par_notice_img,"is_url":"1","url":"/mobile/parent/par_notice/list"},
  {"key":"myplan","title":"맞춤플랜","icon":plan_img,"is_url":"1","url":"/mobile/parent/plan/study_list"},
  {"key":"homework","title":"과제","icon":homework_img,"is_url":"1","url":"/mobile/parent/plan/homework_list"},
  {"key":"plantalk","title":"플랜톡","icon":talk_img,"is_url":"1","url":"/mobile/chat/room_list"},

  {"key":"attend","title":"출결","icon":attend_img,"is_url":"1","url":"/mobile/parent/attend/calendar"},
  {"key":"time_table","title":"시간표","icon":eval_check_img,"is_url":"1","url":"/mobile/parent/stu/time_table_week"},
  {"key":"supply","title":"결제","icon":pay_img,"is_url":"1","url":"/mobile/parent/stu/supply_one_stu"},
  {"key":"option","title":"알림/설정","icon":option_img,"is_url":"1","url":"/mobile/setting"},
];
export default menu_data_arr;