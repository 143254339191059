import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import RowBoxArea from "./row_box";

function ListArea(props:any){
   let myProps={
    infoArr:[],
    go_open_study_map_detail_popup:(inData:any)=>{},
    ...props
  };
  let infoArr=myProps.infoArr;
  
  const get_row_box_tags=()=>{
    let row_box_tags:any=infoArr.map((item:any,idx:number)=>{
      return(
        <RowBoxArea
          key={idx}
          row_num={idx}
          info={item}
          Style={Style}
          go_open_study_map_detail_popup={myProps.go_open_study_map_detail_popup}
        ></RowBoxArea>
      );
    });

    if(strFunc.is_empty(row_box_tags)){
      row_box_tags=(
        <div className="text-center" style={{height:40,border:"1px solid #ddd",lineHeight:"40px"}}>
          내용이 없습니다.
        </div>
      );
    }

    return row_box_tags;
  };

  return (
  <div className={Style.row_box_wrap} >
    {get_row_box_tags()}
  </div>
  );
};
export default ListArea;