import strFunc from "@/lib/lyg/string";
import TStuTodoBaseFunc from "@/pcomponents/common/content/tea/tstu_todo/func/base_func";
import DateFunc from '@/lib/lyg/date_func';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';

function CardBoxArea(props: any) {
  let myProps = {
    Style: {},
    row_num: 0,
    info: {},
    set_info: (inData: any) => { },
    select_seq_arr: [],
    set_select_seq_arr: (inData: any) => { },
    select_stu_grade_option_arr: [],
    select_subject_option_arr: [],
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let row_pri_val=info.a_ymd+","+info.a_seq;
  let stu_name = info.a_stu_name;
  let stu_grade_str = "";
  if (info.stu_info) {
    stu_name = info.stu_info.a_user_name;
    stu_grade_str = info.stu_info.a_stu_grade;
    for (let i = 0; i < myProps.select_stu_grade_option_arr.length; i++) {
      if (myProps.select_stu_grade_option_arr[i].value == stu_grade_str) {
        stu_grade_str = myProps.select_stu_grade_option_arr[i].text;
      }
    }
  }

  let is_checked_row = false;
  if (strFunc.str_in_array(row_pri_val, myProps.select_seq_arr) != -1) {
    is_checked_row = true;
  }

  const onChangeCheckRow = (e: any) => {
    let row_is_checked = e.target.checked;
    if (row_is_checked) {
      myProps.set_select_seq_arr([
        ...myProps.select_seq_arr,
        info.a_ymd+","+info.a_seq
      ]);
    } else {
      let new_checked_arr = [];
      for (let i = 0; i < myProps.select_seq_arr.length; i++) {
        let tmp_pri_val=info.a_ymd+","+info.a_seq;
        if (myProps.select_seq_arr[i] != tmp_pri_val) {
          new_checked_arr.push(myProps.select_seq_arr[i]);
        }
      }
      myProps.set_select_seq_arr(new_checked_arr);
    }
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_row_data = {
      ...myProps.info,
      ...{ [key]: date_str }
    };
    myProps.set_info(change_row_data);
    myProps.go_save_by_ajax({
      write_info_arr: [change_row_data],
    });
  };

  const onChangeInput = (e: any) => {
    let key = e.target.name;
    let value = e.target.value;

    if (e.target.type == "checkbox") {
      value = e.target.checked ? "1" : "";
    }

    let change_row_data = {
      ...myProps.info,
      ...{ [key]: value }
    };

    if(key=="a_is_success"){
      if(value=="1"){
        change_row_data["a_success_date"]=DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      }
    }

    myProps.set_info(change_row_data);

    if(key=="a_is_success"||key=="a_subject_name"||key=="a_public_sort"){
      myProps.go_save_by_ajax({
        write_info_arr: [change_row_data],
      });
    }
  };

  let card_box_style:any={};
  if(info.a_is_success=="1"){
    card_box_style={backgroundColor:"#e0ffdc"};
  }
  if(is_checked_row){
    card_box_style["border"]="1px solid #007bff";
  }

  return (
    <div className={Style.card_box} style={card_box_style}>
      <div className={Style.box_row}>
        <span>
          <label>
            <input type="checkbox" checked={is_checked_row} name="row_check_box" value="1" 
              onChange={onChangeCheckRow} />
            <span className="ml-1">
              {stu_name + " " + stu_grade_str}
            </span>
          </label>
        </span>
        <span>
          <div style={{ display: "inline-block" }}>
            <DatePicker
              selected={info.a_reserve_date != "" ? new Date(info.a_reserve_date) : null}
              onChange={(date: Date) => {
                onChangeDatePicker("a_reserve_date", date);
              }}
              locale={ko}
              dateFormat="yyyy-MM-dd"
              customInput={<CustomInputWrite />}
              renderCustomHeader={customHeaderFunc}
            />
          </div>
        </span>
        <span>
          <select className={Style.input_select} style={{ width: 70 }}
            name="a_subject_name" value={info.a_subject_name} onChange={onChangeInput} >
              <option value="">선택</option>
            {myProps.select_subject_option_arr.map((item: any, index: number) => {
              return (
                <option key={index} value={item.value}>{item.text}</option>
              );
            })}
          </select>
        </span>
        <span>
          {info.a_owner_name}
        </span>
        <button className="btn-s btn-dark" 
          onClick={()=>{
            myProps.go_save_by_ajax({
              write_info_arr: [info],
            });
          }} >저장</button>
      </div>
      <div className={Style.box_row}>
        <span className={Style.box_row_content}>
          <input type="text" className={Style.content_input}
            value={info.a_title}
            name={"a_title"}
            onChange={onChangeInput} 
            autoComplete="off" />
        </span>
        <span className={Style.box_row_right_area}>
          <label>
            <input type="checkbox" checked={info.a_is_success == "1" ? true : false} value={"1"}
              name="a_is_success"
              onChange={(e:any)=>{
                let confirm_msg = "완료 처리 하시겠습니까?";
                if (e.target.checked==false) {
                  confirm_msg = "미완료 처리 하시겠습니까?";
                }
                if (confirm(confirm_msg)) {
                  onChangeInput(e);
                }
              }} />
            <span className="ml-1">
              완료
            </span>
          </label>
        </span>
      </div>
      <div className={Style.box_row}>
        <span className={Style.box_row_content}>
          <input type="text" className={Style.content_input}
            value={info.a_memo}
            name={"a_memo"}
            onChange={onChangeInput} 
            autoComplete="off" />
        </span>
        <span className={Style.box_row_right_area}>
          <select className={Style.input_select} style={{ width: 70 }}
            name="a_public_sort" value={info.a_public_sort} onChange={onChangeInput} >
            {TStuTodoBaseFunc.select_arr_json.a_public_sort.map((item: any, index: number) => {
              return (
                <option key={index} value={item.value}>{item.text}</option>
              );
            })}
          </select>
        </span>
      </div>
    </div>
  );
};
export default CardBoxArea;