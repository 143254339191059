import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';

function TitleArea(props: any) {
  let myProps = {
    supply_name:"학원비",
    stu_info: {},
    corp_info: {},
    supply_money: 0,
    ...props
  };
  let corp_info = myProps.corp_info;
  let stu_info = myProps.stu_info;

  return (
    <div>
      <div className={Style.title}>결제방법선택</div>
      <div className={Style.supply_title}>
        {myProps.supply_name}
      </div>
      <div className={Style.title_name} style={{ position: "relative" }}>
        {corp_info.a_company_name} 학원
        <span className={Style.right_stu_name}>{stu_info["a_user_name"]} 학생</span>
      </div>
      <div className={Style.supply_money_wrap} >
        <span className={Style.left_money_pre_str}>납부할 금액</span>
        <span className={Style.supply_money}>{strFunc.comma(myProps.supply_money)}</span>
        <span className="ml-1">원</span>
      </div>
    </div>
  );
};
export default TitleArea;