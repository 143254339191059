import DateFunc from '@/lib/lyg/date_func';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ListArea from "./area/list";
import WriteArea from "./area/write";
import Style from "./css/style.module.css";
import strFunc from '@/lib/lyg/string';
import LoadingComponent from "@/pcomponents/common/content/loading";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";

function CommentPartArea(props: any) {
  let myProps = {
    select_mcomp_seq:"",
    select_mcomp_api_key:"",
    select_mcomp_user_seq:"",
    info_arr: [],
    is_init_list: false,
    is_show_toggle: true,
    is_show_detail: false,
    is_send_write_push:false,
    title_str: "",
    wrap_style:{},
    init_data: {},
    list: (inData: any) => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data=UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq:myProps.select_mcomp_seq,
    default_mcomp_api_key: myProps.select_mcomp_api_key,
    default_mcomp_user_seq: myProps.select_mcomp_user_seq,
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;
  
  let init_wrap_style={
    width: "95%",
    position: "relative",
    padding:4,
    border:"1px solid #ddd",
    borderRadius:"4px",
    ...myProps.wrap_style
  };
  const [is_show_detail, set_is_show_detail] = useState(myProps.is_show_detail);
  const [is_show_write, set_is_show_write] = useState(false);
  let default_init_data={
    writer_seq: "",
    writer_name: "",
    par_id: "",
    par_seq: "",
    par_locate: "",
    par_locate_name: "",
    s_start_date: DateFunc.get_date_format(new Date(), "Y-01-01"),
    s_end_date: DateFunc.get_date_format(new Date(), "Y-12-31"),
    ...myProps.init_data
  };
  const [pageData, setPageData] = useState<any>({
    listOpt: {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      order_id: "a_date, a_create_date",
    }
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [refresh, set_refresh] = useState(false);
  const [loading_data, setLoadingData] = useState({
    is_display: false,
    text: "로딩중.."
  });

  if(myProps.is_init_list==false){
    useEffect(() => {
      set_info_arr_by_props();
    }, [myProps.info_arr]);
  }else{
    useEffect(() => {
      set_info_arr_by_props();
    }, [myProps.init_data]);
  }
  

  const set_info_arr_by_props = () => {
    if (myProps.is_init_list) {
      list({});
    } else {
      let tmp_info_arr: any = [];
      if (myProps.info_arr) {
        for (let i = 0; i < myProps.info_arr.length; i++) {
          let info = myProps.info_arr[i];
          if (info["a_par_seq"] == default_init_data["par_seq"]
              &&info["a_par_locate"] == default_init_data["par_locate"]) {
            tmp_info_arr.push(info);
          }
        }
      }
      setInfoArr(tmp_info_arr);
    }
  };
  
  const refresh_data = () => {
    if (myProps.is_init_list) {
      myProps.list({});
      list({});
    } else {
      myProps.list({});
      list({});
    }
  };

  const list = (inOptObj: any) => {
    let tmp_list_opt = {
      ...pageData.listOpt,
      s_start_date: default_init_data.s_start_date,
      s_end_date: default_init_data.s_end_date,
      s_par_id:default_init_data.par_id,
      s_par_seq:default_init_data.par_seq,
      s_par_locate: default_init_data.par_locate,
      ...inOptObj
    };
    setPageData({
      ...pageData,
      listOpt:{
        ...pageData.listOpt,
        ...tmp_list_opt
      },
    });
    if(strFunc.is_empty(tmp_list_opt.s_par_id)){
      return false;
    }
    if(strFunc.is_empty(tmp_list_opt.s_par_seq)){
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/list', tmp_list_opt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const delete_by_ajax = (inData: any) => {
    let opt_obj = {
      "data_arr": [],
      ...inData
    };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }

    if (opt_obj["data_arr"].length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    let del_data_arr = [];
    for (let i = 0; i < opt_obj["data_arr"].length; i++) {
      let row_data = opt_obj["data_arr"][i];
      if (!strFunc.is_empty(row_data["a_seq"])) {
        del_data_arr.push(row_data);
      }
    }
    if (del_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    if (!confirm("삭제 하시겠습니까?")) {
      return false;
    }

    let del_form_json:any = {
      "data_arr": del_data_arr
    };
    del_form_json["api_key"] = comp_api_key;
    del_form_json["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/delete', del_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setLoadingData({
            is_display: true,
            text: "삭제되었습니다."
          });
          setTimeout(() => {
            setLoadingData({
              is_display: false,
              text: ""
            });
          }, 1200);
          refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const update_by_ajax = (inData: any) => {
    let opt_obj = {
      "data_arr": [],
      ...inData
    };

    if (opt_obj["data_arr"].length == 0) {
      alert("선택이 없습니다.");
      return false;
    }

    let up_data_arr = [];
    for (let i = 0; i < opt_obj["data_arr"].length; i++) {
      let row_data = opt_obj["data_arr"][i];
      if (!strFunc.is_empty(row_data["a_seq"])) {
        up_data_arr.push(row_data);
      }
    }
    if (up_data_arr.length == 0) {
      alert("데이터가 없습니다.");
      return false;
    }

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let up_form_json:any = {
      "data_arr": up_data_arr,
      "is_update": "1"
    };
    up_form_json["api_key"] = comp_api_key;
    up_form_json["api_user_seq"] = iam_mcomp_user_seq;
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/comment/comment/write', up_form_json,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setLoadingData({
            is_display: true,
            text: "수정되었습니다."
          });
          setTimeout(() => {
            setLoadingData({
              is_display: false,
              text: ""
            });
          }, 1200);
          refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  let un_view_cnt = 0;
  for (let i = 0; i < infoArr.length; i++) {
    if (infoArr[i]["a_is_view_writer"] == "1") {

    } else {
      un_view_cnt++;
    }
  }

  return (
    <>
      <LoadingComponent
        is_display={loading_data.is_display}
        text={loading_data.text}></LoadingComponent>
      {myProps.is_show_toggle &&
        <span style={{ cursor: "pointer", marginLeft: 5 }} onClick={() => { set_is_show_detail(!is_show_detail); }} >
          ※
          {un_view_cnt != 0 &&
            <span>({un_view_cnt})</span>
          }
        </span>
      }
      {is_show_detail &&
        <div className="mt-1" style={init_wrap_style}>
          <div className="text-center">
            <span className="mr-1">
              {myProps.title_str}
            </span>
            {is_show_write == false &&
              <button className="btn-s btn-gray"
                onClick={() => { set_is_show_write(!is_show_write); }}>+</button>
            }
          </div>
          {is_show_write &&
            <div className="mt-1">
              <WriteArea
                select_mcomp_seq={myProps.select_mcomp_seq}
                user={user}
                writer_seq={default_init_data.writer_seq}
                writer_name={default_init_data.writer_name}
                par_id={default_init_data.par_id}
                par_seq={default_init_data.par_seq}
                par_locate={default_init_data.par_locate}
                par_locate_name={default_init_data.par_locate_name}
                is_send_write_push={myProps.is_send_write_push}
                infoArr={infoArr}
                close_write={() => { set_is_show_write(false); }}
                list={() => {
                  refresh_data();
                }}
              ></WriteArea>
            </div>
          }
          <div>
            <ListArea
              infoArr={infoArr}
              setInfoArr={(inData: any) => {
                setInfoArr(inData);
                set_refresh(!refresh);
              }}
              list={list}
              writer_seq={default_init_data.writer_seq}
              writer_name={default_init_data.writer_name}
              user={user}
              Style={Style}
              delete_by_ajax={delete_by_ajax}
              update_by_ajax={update_by_ajax}
            ></ListArea>
          </div>
        </div>
      }
    </>
  );
};
export default CommentPartArea;