import StudyMapWriteDetailArea from "@/pages/mobile/plan/study_map/common/write_detail";

function WriteStudyMapDetailPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    callback_refresh_data: (inData:any) => { },
    map_pri_val: "",
    ...props
  };

  return (
    <div>
      <StudyMapWriteDetailArea
        close_func={myProps.closePopup}
        close_text="닫기"
        callback_refresh_data={myProps.callback_refresh_data}
        map_pri_val={myProps.map_pri_val}
      ></StudyMapWriteDetailArea>
    </div>
  );
};
export default WriteStudyMapDetailPopup;