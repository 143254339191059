function BackCircle(props:any){
   let myProps={
    Style:{},
    circle_data:{
      x:0,y:0,w:400,h:400,borderRadius:400,border:90
    },
    ...props
  };
  let Style=myProps.Style;
  let circle_data=myProps.circle_data;
  let circle_style={
    left:circle_data.x+"px",
    top:circle_data.y+"px",
    width:circle_data.w+"px",
    height:circle_data.h+"px",
    borderRadius:circle_data.borderRadius+"px",
    border:circle_data.border+"px solid #FFF"
  };
  
  return (
  <div className={Style.back_circle} 
    style={circle_style}></div>
  );
};
export default BackCircle;