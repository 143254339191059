import strFunc from "@/lib/lyg/string";
import Style from "./css/style.module.css";
import CardBox from "./area/card_box";
import ListItem from "./area/list_item";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import TopMoveButton from "@/pcomponents/common/content/list/top_button";

function ListCardArea(props:any){
   let myProps={
    user:{},
    list_sort:"card",
    info_arr:[],
    show_info_idx:0,
    set_show_info_idx:(inData:any)=>{},
    form_list:[],
    select_form_seq:"",
    go_write_eval:(inData:any)=>{},
    go_delete:(inData:any)=>{},
    setInfoArr:(inData:any)=>{},
    ...props
  };
  let info_arr=myProps.info_arr;
  let show_info_idx=myProps.show_info_idx;
  let select_stu_grade_arr=XcolumnFunc.getSortByTableKey({'table':'user','key':'a_stu_grade'});

  let row_data:any={};
  if(show_info_idx>=0&&show_info_idx<info_arr.length){
    row_data=info_arr[show_info_idx];
  }
  let select_form_info:any=null;
  if(!strFunc.is_empty(myProps.select_form_seq)){
    for(let i=0;i<myProps.form_list.length;i++){
      if(myProps.form_list[i]["a_seq"]==myProps.select_form_seq){
        select_form_info=myProps.form_list[i];
      }
    }
  }

  const get_card_box_tags=()=>{
    let card_box_tags:any="";
    if(myProps.list_sort=="card"){
      if(info_arr.length==0){
        
      }else{
        let eval_info:any={};
        let is_update=false;
        if(row_data.eval_arr&&row_data.eval_arr.length>0){
          eval_info=row_data.eval_arr[0];
          if(!strFunc.is_empty(eval_info.a_seq)){
            is_update=true;
          }
        }
        card_box_tags=(
          <CardBox
            user={myProps.user}
            info={row_data}
            info_arr={info_arr}
            show_info_idx={show_info_idx}
            set_show_info_idx={myProps.set_show_info_idx}
            form_list={myProps.form_list}
            s_form_seq={myProps.select_form_seq}
            select_stu_grade_arr={select_stu_grade_arr}
            Style={Style}
            is_update={is_update}
            go_write_eval={myProps.go_write_eval}
            go_delete={()=>{myProps.go_delete({"info_arr":[eval_info]});}}
            set_eval_data={(inData:any)=>{
              let opt_obj={
                "eval_info":{},
                "user_row_num":0,
                ...inData
              };
              
              let change_info_arr=myProps.info_arr;
              if(change_info_arr[opt_obj["user_row_num"]].eval_arr==undefined){
                change_info_arr[opt_obj["user_row_num"]].eval_arr=[];
              }
              if(change_info_arr[opt_obj["user_row_num"]].eval_arr.length==0){
                change_info_arr[opt_obj["user_row_num"]].eval_arr.push(opt_obj["eval_info"]);
              }else{
                change_info_arr[opt_obj["user_row_num"]].eval_arr[0]=opt_obj["eval_info"];
              }
              myProps.setInfoArr(change_info_arr);
            }}
          ></CardBox>
        );
      }
      
    }else{
      card_box_tags=info_arr.map((item:any,idx:number)=>{
        let eval_info:any={};
        let is_update=false;
        if(item.eval_arr&&item.eval_arr.length>0){
          eval_info=item.eval_arr[0];
          if(!strFunc.is_empty(eval_info.a_seq)){
            is_update=true;
          }
        }
        return (
          <ListItem
            key={idx}
            user={myProps.user}
            info={item}
            form_list={myProps.form_list}
            s_form_seq={myProps.select_form_seq}
            select_stu_grade_arr={select_stu_grade_arr}
            Style={Style}
            is_update={is_update}
          ></ListItem>
        );
      });
    }

    if(strFunc.is_empty(card_box_tags)){
      card_box_tags=(
        <div style={{lineHeight:"50px",textAlign:"center",color:"#999",background:"#fff"}}>
          내용이 없습니다.
        </div>
      );
    }

    return card_box_tags;
  };
  
  return (
  <div className={Style.card_list_wrap+" noto_sans"}>
    <div className={Style.card_list_con}>
      <div className={Style.card_list}>
        {get_card_box_tags()}
      </div>
    </div>
    <TopMoveButton></TopMoveButton>
  </div>
  );
};
export default ListCardArea;