function FileGroupArea(props:any){
   let myProps={
    Style:{},
    group_info:{},
    ...props
  };
  let Style=myProps.Style;
  let group_info=myProps.group_info;
  
  return (
  <div className={Style.file_group_list_item}>
    {group_info.title} ({group_info.file_arr.length})
  </div>
  );
};
export default FileGroupArea;