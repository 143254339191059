import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import left_arrow_ico from "@/img/ico/arrow/left_arrow.png";
import right_arrow_ico from "@/img/ico/arrow/right_arrow.png";
import strFunc from "@/lib/lyg/string";

function SearchArea(props: any) {
  let myProps = {
    list_opt: {

    },
    list: (inData: any) => { },
    form_list:[],
    stu_class_info_arr:[],
    ...props
  };

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;
    myProps.list({
      "now_page": "1",
      ...{ [name]: value }
    });
  };

  const goPrevAfter = (sort: "prev" | "after") => {
    if (strFunc.is_empty(myProps.list_opt.s_addon_eval_s_start_date)) {
      myProps.list_opt.s_addon_eval_s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let select_month_obj = new Date(myProps.list_opt.s_addon_eval_s_start_date);
    let change_date_str = "";
    if (sort == "prev") {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', -1), "Y-m-d");
    } else {
      change_date_str = DateFunc.get_date_format(DateFunc.get_change_date(select_month_obj, 'day', +1), "Y-m-d");
    }
    myProps.list({
      "now_page": "1",
      "s_addon_eval_s_start_date": change_date_str,
      "s_addon_eval_s_end_date": change_date_str,
    });
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");

    myProps.list({
      "now_page": "1",
      [key]: date_str,
    });
  };

  return (
    <div style={{borderBottom:"1px solid #E0E5E5",padding:"10px 0",background:"#fff"}}>
      <div className="search_wrap">
        <button onClick={() => { goPrevAfter("prev"); }}>
          <img src={left_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.list_opt.s_addon_eval_s_start_date != "" ? new Date(myProps.list_opt.s_addon_eval_s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_eval_s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div> ~
        <div style={{ display: "inline-block", "width": 90 }}>
          <DatePicker
            wrapperClassName="w-24 pl-1"
            selected={myProps.list_opt.s_addon_eval_s_end_date != "" ? new Date(myProps.list_opt.s_addon_eval_s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_addon_eval_s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button onClick={() => { goPrevAfter("after"); }}>
          <img src={right_arrow_ico} style={{ height: 25, paddingBottom: 5 }} />
        </button>
        <button className="btn-s btn-dark" style={{minWidth:30,fontWeight:500}} onClick={()=>{
          myProps.list({
            "now_page": "1",
            "s_addon_eval_s_start_date": DateFunc.get_date_format(new Date(),"Y-m-d"),
            "s_addon_eval_s_end_date": DateFunc.get_date_format(new Date(),"Y-m-d"),
          });
        }}>오늘</button>
        <button className="btn-s btn-dark" style={{minWidth:35,fontWeight:500}} onClick={()=>{
          myProps.list({
            "now_page": "1",
            "s_addon_eval_s_start_date": DateFunc.get_date_format(new Date(),"Y-m-01"),
            "s_addon_eval_s_end_date": DateFunc.get_date_format(new Date(),"Y-m-t"),
          });
        }}>이번달</button>
      </div>
      <div className="search_wrap mt-2">
        <select className="search_input"
          name="s_addon_eval_s_form_seq" value={myProps.list_opt.s_addon_eval_s_form_seq}
          onChange={handleChangeInput} style={{ width: 70 }} >
          <option value="">평가틀</option>
          {
            myProps.form_list.map((item:any,idx:number)=>{
              return (
                <option key={idx} value={item.a_seq}>{item.a_title}</option>
              );
            })
          }
        </select>
        <input type="text" value={myProps.list_opt.s_user_name_like}
          name="s_user_name_like"
          onChange={handleChangeInput} style={{ width: 50 }}
          placeholder="이름" autoComplete="off" />
        
        {/* <input type="text" className="ml-1" value={myProps.list_opt.s_school_name_like}
          name="s_school_name_like"
          onChange={handleChangeInput} style={{ width: 60 }}
          placeholder="학교" autoComplete="off" /> */}
        <select className="ml-1 border" value={myProps.list_opt.s_class_seq}
          name="s_class_seq"
          onChange={handleChangeInput} style={{ width: 70,fontSize:13 }}>
          <option value="">클래스</option>
          {
            myProps.stu_class_info_arr.map((item:any,idx:number)=>{
              return ( 
                <option key={idx} value={item.a_seq}>{item.a_name}</option>
              );
            })
          }
        </select>
        <span className="basic_search_item_book_mark"
            onClick={() => {
              let change_s_stu_bookmark = myProps.list_opt.s_stu_bookmark;
              if (change_s_stu_bookmark == "1") {
                change_s_stu_bookmark = "";
              } else {
                change_s_stu_bookmark = "1";
              }
              strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
              myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
            }}>
            {myProps.list_opt.s_stu_bookmark == "1" ? "★" : "☆"}
          </span>
        <button className="btn-s btn-dark ml-1" style={{minWidth:40,background:"#3FAC96"}} onClick={() => { myProps.list(); }}>검색</button>
      </div>
    </div>
  );
};
export default SearchArea;