import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";
import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import PlanListFunc from "../func/list_func";
import strFunc from "@/lib/lyg/string";

function ListRow(props:any){
   let myProps={
    Style:{},
    info:{},
    is_check:false,
    on_select_one:(inData:any)=>{},
    openAttendPopup:(inData:any)=>{},
    deleteAttend:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let attend_data=AttendStateFunc.get_attend_data_by_user_info(info);
  let todo_stat_data=TodoStateFunc.get_sort_percent_by_user_info(info);
  let row_per_color_json=TodoStateFunc.get_color_of_percent(todo_stat_data["total"]["percent"]);
  
  const go_attend_action=(inData:any)=>{
    let opt_obj={
      "value":"",
      ...inData
    };
    let value=opt_obj["value"];

    if(value=="out"){
      if(attend_data.attend_out_info!=null){
        myProps.deleteAttend([attend_data.attend_out_info]);
      }else{
        myProps.openAttendPopup([info],"out",value);
      }
    }else{
      if(attend_data.attend_in_info!=null){
        if(value==attend_data.attend_in_info.a_sort_detail){
          myProps.deleteAttend([attend_data.attend_in_info]);
        }else{
          myProps.openAttendPopup([info],"in",value);
        }
      }else{
        myProps.openAttendPopup([info],"in",value);
      }
    }
  };

  const get_attend_btn_tags=()=>{
    let attend_btn_data_arr=PlanListFunc.get_attend_btn_data_arr(attend_data);

    let attend_btn_tags=attend_btn_data_arr.map((item:any,idx:number)=>{
      return (
        <button key={idx} className={Style.list_row_attend_btn} 
          onClick={()=>{
            go_attend_action({value:item.value});
          }}
          style={{color:item.color,background:item.background}}>
          {item.text}
        </button>
      );
    });

    return attend_btn_tags;
  };

  return (
  <div className={Style.list_row}>
    <div className={Style.list_row_title_wrap}>
      <label>
        <input type="checkbox" checked={myProps.is_check} 
          onChange={(e:any)=>{myProps.on_select_one({
            is_check:e.target.checked,
            info:myProps.info
          });}}/>
        <span className="ml-1">{info.a_user_name}</span>
      </label>
    </div>
    <div className={Style.list_row_percent_wrap} style={{color:row_per_color_json.per}}>
      {todo_stat_data["total"]["percent"]}%
    </div>
    <div className={Style.list_row_btn_wrap}>
      {get_attend_btn_tags()}
    </div>
  </div>
  );
};
export default ListRow;