import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import TodoListArea from "@/pages/mobile/parent/plan/common/list";

function ParTodoHomeWorkList() {

  return (
    <MobileLayout has_header={false} has_footer={false}>
      <HeaderSimple title={"과제"}></HeaderSimple>
      <TodoListArea
        s_todo_sort="homework"
        plan_study_file_id="home_work_file"
        s_todo_sort_except=""
        back_press_url="/mobile/parent/plan/homework_list"
      ></TodoListArea>
    </MobileLayout>
  );
};
export default ParTodoHomeWorkList;