import AttendStateFunc from "@/pcomponents/common/content/attend/state/func/state_func";
import PlanListFunc from "../../func/list_func";
function AttendBoxArea(props:any){
   let myProps={
    Style:{},
    info:{},
    openAttendPopup:(inData:any)=>{},
    openAttendModifyPopup:(inData:any)=>{},
    deleteAttend:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let attend_data=AttendStateFunc.get_attend_data_by_user_info(info);
  let is_exist_attend=false;
  if(info.attend_in_arr&&info.attend_in_arr.length>0){
    is_exist_attend=true;
  }
  if(attend_data.attend_out_info!=null){
    is_exist_attend=true;
  }

  const go_attend_action=(inData:any)=>{
    let opt_obj={
      "value":"",
      ...inData
    };
    let value=opt_obj["value"];

    if(value=="out"){
      if(attend_data.attend_out_info!=null){
        myProps.deleteAttend([attend_data.attend_out_info]);
      }else{
        myProps.openAttendPopup([info],"out",value);
      }
    }else{
      if(attend_data.attend_in_info!=null){
        if(value==attend_data.attend_in_info.a_sort_detail){
          myProps.deleteAttend([attend_data.attend_in_info]);
        }else{
          myProps.openAttendPopup([info],"in",value);
        }
      }else{
        myProps.openAttendPopup([info],"in",value);
      }
    }
  };

  const get_attend_btn_tags=()=>{
    let attend_btn_data_arr=PlanListFunc.get_attend_btn_data_arr(attend_data);
    let attend_btn_tags=attend_btn_data_arr.map((item:any,idx:number)=>{
      return (
        <button key={idx} className={Style.card_box_attend_btn} 
          onClick={()=>{go_attend_action({value:item.value});}}
          style={{color:item.color,background:item.background}}>
          {item.text}
        </button>
      );
    });

    return attend_btn_tags;
  };
  
  return (
  <div className={Style.card_box_attend_wrap}>
    <div className={Style.card_box_attend_title}>
      출결
      {is_exist_attend&&
        <span className={Style.card_box_attend_title_right}
          onClick={()=>{myProps.openAttendModifyPopup([myProps.info]);}}
          >시간변경</span>
      }
    </div>
    <div className={Style.card_box_attend_btn_wrap}>
      {get_attend_btn_tags()}
    </div>
  </div>
  );
};
export default AttendBoxArea;