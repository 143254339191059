function ChildManageButton(props:any){
   let myProps={
    Style:{},
    on_click:(inData:any)=>{},
    ...props
  };
  let Style=myProps.Style;
  let btn_con_style:any={
    position:"relative",
    width:"100%",
    height:"100%",
    display:"flex",
  };
  let back_circle_style:any={
    position:"absolute",
    top:0,
    bottom:0,
    left:0,
    right:0,
    borderRadius:"75px",
    opacity:0.1,
    background:"#fff",
    zIndex:1,
  };
  let circle_style:any={
    position:"absolute",
    width:"86%",
    height:"86%",
    left:"7%",
    top:"7%",
    borderRadius:"75px",
    background:"#9499AD",
    fontSize:13,
    textAlign:"center",
    display:"flex",
    justifyContent:"center",
    alignSelf:"center",
    padding:8,
  };

  return (
  <div className={Style.top_child_manage_btn}>
    <div style={btn_con_style} onClick={()=>{myProps.on_click();}}>
      <div style={back_circle_style}></div>
      <div style={circle_style} >
        <div style={{lineHeight:"23px"}}>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19" fill="none" style={{display:"inline-block"}}>
              <path d="M13.7187 9.1458C14.5605 9.1458 15.243 8.46335 15.243 7.6215C15.243 6.77965 14.5605 6.0972 13.7187 6.0972C12.8768 6.0972 12.1944 6.77965 12.1944 7.6215C12.1944 8.46335 12.8768 9.1458 13.7187 9.1458Z" fill="white"/>
              <path d="M9.14585 9.1458C9.9877 9.1458 10.6702 8.46335 10.6702 7.6215C10.6702 6.77965 9.9877 6.0972 9.14585 6.0972C8.304 6.0972 7.62155 6.77965 7.62155 7.6215C7.62155 8.46335 8.304 9.1458 9.14585 9.1458Z" fill="white"/>
              <path d="M15.6934 11.4323H7.17114C6.97367 11.4323 6.83856 11.6401 6.9321 11.8168C7.79472 13.4485 9.48877 14.5501 11.4323 14.5501C13.3757 14.5501 15.0698 13.4485 15.9324 11.8168C16.0155 11.6401 15.8804 11.4323 15.6934 11.4323ZM22.8021 8.66772C22.5423 7.09839 21.3887 5.82005 19.8817 5.37316C19.3309 4.20915 18.5514 3.19064 17.6057 2.34881C15.9636 0.883401 13.8018 0 11.4323 0C9.06266 0 6.90092 0.883401 5.25883 2.34881C4.30268 3.19064 3.52321 4.21954 2.98278 5.37316C1.4758 5.82005 0.322182 7.088 0.0623577 8.66772C0.0207858 8.88598 0 9.11462 0 9.35366C0 9.5927 0.0207858 9.82134 0.0623577 10.0396C0.322182 11.6089 1.4758 12.8873 2.98278 13.3342C3.52321 14.4878 4.30268 15.5063 5.23805 16.3377C6.88014 17.8135 9.05226 18.7073 11.4323 18.7073C13.8122 18.7073 15.9844 17.8135 17.6368 16.3377C18.5722 15.5063 19.3517 14.4774 19.8921 13.3342C21.3887 12.8873 22.5423 11.6193 22.8021 10.0396C22.8437 9.82134 22.8645 9.5927 22.8645 9.35366C22.8645 9.11462 22.8437 8.88598 22.8021 8.66772ZM18.7073 11.4323C18.6034 11.4323 18.5099 11.4115 18.4059 11.4011C18.1981 12.0974 17.8967 12.7418 17.5121 13.3342C16.213 15.3192 13.9785 16.6287 11.4323 16.6287C8.88598 16.6287 6.65149 15.3192 5.35237 13.3342C4.96783 12.7418 4.66644 12.0974 4.45858 11.4011C4.35465 11.4115 4.26111 11.4323 4.15718 11.4323C3.01396 11.4323 2.07859 10.4969 2.07859 9.35366C2.07859 8.21043 3.01396 7.27507 4.15718 7.27507C4.26111 7.27507 4.35465 7.29585 4.45858 7.30625C4.66644 6.60992 4.96783 5.96556 5.35237 5.37316C6.65149 3.3881 8.88598 2.07859 11.4323 2.07859C13.9785 2.07859 16.213 3.3881 17.5121 5.37316C17.8967 5.96556 18.1981 6.60992 18.4059 7.30625C18.5099 7.29585 18.6034 7.27507 18.7073 7.27507C19.8505 7.27507 20.7859 8.21043 20.7859 9.35366C20.7859 10.4969 19.8505 11.4323 18.7073 11.4323Z" fill="white"/>
            </svg>
          </div>
          자녀관리
        </div>
      </div>
    </div>
  </div>
  );
};
export default ChildManageButton;