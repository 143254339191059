import { useState } from 'react';
import strFunc from "@/lib/lyg/string";
import { useSelector } from 'react-redux';
import MatterFunc from "@/pages/mobile/playground/math/common/func/matter_func";
import GameScoreFunc from "@/pages/mobile/playground/math/common/func/score_func";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import RankGameScorePopup from "@/pages/mobile/char/char/popup/rank_game_score";
import GameHistoryPopup from "@/pages/mobile/char/char/popup/game_history";
import SoundOptionArea from "./sound_opt";

function LobbyArea(props: any) {
  let myProps = {
    game_data: {},
    set_game_data: (inData: any) => { },
    char_opt: {},
    char_user_info: {},
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let game_data = myProps.game_data;
  let char_opt = myProps.char_opt;
  let my_gold=0;
  if(char_opt&&char_opt.a_gold){
    my_gold=char_opt.a_gold;
  }
  let pre_score = GameScoreFunc.get_score_by_char_opt({
    game_data: game_data,
    char_opt: myProps.char_opt,
  });
  let score_key = GameScoreFunc.get_score_key_of_char_opt({
    game_data: game_data,
  });
  let score_name = GameScoreFunc.get_score_name_of_char_opt({
    game_data: game_data,
  });

  const [is_show_opt, set_is_show_opt] = useState(false);
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "rank_score",//rank_score,game_history
    title: "점수랭킹",
    y: "0",
    width: "100%",
    height: "100%",
    is_border_radius: false,
  });

  const get_level_box_tags = () => {
    let level_arr = [1, 2, 3, 4, 5];

    let level_box_tags = level_arr.map((item: any, idx: number) => {
      let level_box_style: any = {
        display: "inline-block",
        width: 50,
        height: 50,
        border: "1px solid",
        textAlign: "center",
        lineHeight: "50px",
        fontSize: 20,
        cursor: "pointer",
      };
      let is_active = false;
      if (game_data.level === item) {
        is_active = true;
        level_box_style.background = "#0f0";
      }

      return (
        <div key={idx} style={level_box_style}
          onClick={() => {
            myProps.set_game_data({
              ...game_data,
              level: item
            });
          }}>
          {item}
        </div>
      );
    });
    return level_box_tags;
  };

  const start_game = () => {
    let reset_game_data: any = {
      score: 0,
      matter_arr: [],
      matter_now_idx: 0,
      matter_correct_cnt: 0,
      matter_remain_cnt: 0,
      start_msec: 0,
      elapse_msec: 0,
    };
    let change_game_data: any = {
      ...game_data,
      ...reset_game_data,
      state: "game"
    };
    let matter_arr = MatterFunc.get_matter_arr_by_opt({
      game_data: game_data
    });

    change_game_data["matter_arr"] = matter_arr;

    myProps.set_game_data(change_game_data);
  };

  const open_score_popup = () => {
    setPopupData({
      ...popupData,
      title: score_name+"랭킹",
      "isOpen": true,
      sort: "rank_score",
    });
  };

  const open_game_history_popup = () => {
    if(strFunc.is_empty(user.user_seq)){
      alert("로그인 정보 없음.");
      return false;
    }
    setPopupData({
      ...popupData,
      title: score_name+"기록",
      "isOpen": true,
      sort: "game_history",
    });
  };

  const open_game_history_all_popup = () => {
    if(strFunc.is_empty(user.user_seq)){
      alert("로그인 정보 없음.");
      return false;
    }
    setPopupData({
      ...popupData,
      title: score_name+"기록",
      "isOpen": true,
      sort: "game_history_all",
    });
  };
  

  return (
    <div>
      <div className="text-center mt-5" >내기록</div>
      <div className="text-center" style={{ color: "#0005ff" }} >
        {pre_score}
      </div>
      <div className="text-center mt-5" >양분</div>
      <div className="text-center" style={{ color: "#0005ff" }} >
        {strFunc.comma(my_gold)}
      </div>
      {/* <div className="text-center mt-5" >난이도</div>
    <div className="mt-2" style={{display:"flex",gap:10,justifyContent:"center"}}>
      {get_level_box_tags()}
    </div> */}

      <div className="text-center mt-8">
        <button className="btn btn-dark"
          style={{ width: 200, height: 90, fontSize: 30, background: "#00A99E" }}
          onClick={() => {
            start_game();
          }}
        >
          Start
        </button>
      </div>
      <div className="mt-2 text-center">
        <button className="btn btn-dark" onClick={() => { set_is_show_opt(!is_show_opt); }}>옵션</button>
        <button className="btn btn-dark ml-2" onClick={() => { open_score_popup(); }}>점수랭킹</button>
        <button className="btn btn-dark ml-2" onClick={() => { open_game_history_popup(); }}>내기록</button>
        <button className="btn btn-dark ml-2" onClick={() => { open_game_history_all_popup(); }}>기록</button>
      </div>
      {is_show_opt &&
        <div style={{ marginTop: "50px" }}>
          <SoundOptionArea></SoundOptionArea>
        </div>
      }
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius}
          has_header={false} content_div_style={{ padding: 0 }}>
          {popupData.sort === "rank_score" &&
            <RankGameScorePopup
              score_key={score_key}
              score_name={score_name}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></RankGameScorePopup>
          }
          {popupData.sort === "game_history" &&
            <GameHistoryPopup
              game_sort={game_data.sort}
              game_name={score_name}
              s_main_user_seq={user.user_seq}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></GameHistoryPopup>
          }
          {popupData.sort === "game_history_all" &&
            <GameHistoryPopup
              game_sort={game_data.sort}
              game_name={score_name}
              s_main_user_seq={""}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></GameHistoryPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default LobbyArea;