import MobileLayout from "@/pcomponents/mobile/layout/layout";
import { useState,useRef,useEffect } from 'react';
import Style from "./css/style.module.css";
import { useSelector, useDispatch } from 'react-redux';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";
import strFunc from "@/lib/lyg/string";
import BackGroundArea from "./area/background";
import TopTitleArea from "./area/top_title";
import TodoTodayArea from  "./area/todo_today";
import CounselTodayArea from "./area/counsel_today";
import AttendTodayArea from "./area/attend_today";
import TodoChartArea from "./area/todo_chart";
import MenuArrArea from "./area/menu_arr";
import TeacherStuTodoArea from "./area/tstu_todo";

function ManageArea(props:any){
   let myProps={
    ...props
  };
  
  let user=useSelector((state:any) => state.user);
  const [comp_main_data,set_comp_main_data]=useState<any>({
    today_todo_stat:null,
    todo_stat_arr:[],
    today_eval_stat:null,
    today_plantalk_stat:{},
    today_counsel_stat:{},
    today_counsel_list:[],
    today_attend_stat:{},
    month_prescribe_stat:{},
    chat_unread_cnt:0,
    push_unread_cnt:0,
  });

  useEffect(()=>{
    get_comp_main_data_by_ajax();
  },[user]);

  const get_comp_main_data_by_ajax=()=>{
    if(strFunc.is_empty(user.select_mcomp_link)){
      return false;
    }
    if(strFunc.is_empty(user.select_mcomp_link)){
      return false;
    }
    let list_form_json_data={
      "s_mcomp_seq":user.select_mcomp_link.a_mcomp_seq,
    };
    my_axios.post( process.env.REACT_APP_API_URL+'/api/mobile/front/main/get_comp_main_data',list_form_json_data,get_axios_data())
    .then((response) => {
      if(response.data["result"]==="true"){
        set_comp_main_data({
          comp_main_data,
          ...response.data["data"]
        });
      }else{
        
      }
    });
  };

  return (
  <MobileLayout container_style={{ background: "linear-gradient(180deg, #4BC0B9 0%, #3FAC8B 78.12%)" }}
    container_con_style={{ width: "100%" }}
    is_home_body={false}
    has_header={false}
    has_footer={true}>
    
    <BackGroundArea></BackGroundArea>
    <div className={Style.main_wrap+" noto_sans"}>
      <TopTitleArea
        Style={Style}
        user={user}
        get_comp_main_data_by_ajax={get_comp_main_data_by_ajax}
        comp_main_data={comp_main_data}
      ></TopTitleArea>
      <TodoTodayArea
        Style={Style}
        comp_main_data={comp_main_data}
      ></TodoTodayArea>
      {/* <MenuArrArea
        Style={Style}
        comp_main_data={comp_main_data}
      ></MenuArrArea> */}
      <AttendTodayArea
        Style={Style}
        comp_main_data={comp_main_data}
      ></AttendTodayArea>
      <CounselTodayArea
        Style={Style}
        comp_main_data={comp_main_data}
      ></CounselTodayArea>
      <TeacherStuTodoArea
        Style={Style}
        user={user}
      ></TeacherStuTodoArea>
      <div style={{minHeight:1,paddingBottom:30}}></div>
    </div>
  </MobileLayout>
  );
};
export default ManageArea;