import {
  createBrowserRouter,
} from "react-router-dom";

import MobileDefault from "@/pages/mobile/main/main";
import MobileRouterArr from "./mobile/index";

const router = createBrowserRouter([
  ...MobileRouterArr,
  {
    path: "*",
    element: <MobileDefault></MobileDefault>,
  },
]);

export default router;