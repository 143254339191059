import DateFunc from '@/lib/lyg/date_func';
import my_axios,{get_axios_data} from "@/pcomponents/common/axios";

const checkScriptVersion=()=>{
  
  if(localStorage.script_version_create_date==DateFunc.get_date_format(new Date(),"Y-m-d h")){
    return false;
  }
  my_axios.post( process.env.REACT_APP_API_URL+'/api/comp/version/version/script_version',{},get_axios_data())
  .then((response) => {
    if(response.data["result"]=="true"){
      if(localStorage.script_version!=response.data.data){
        localStorage.script_version=response.data.data;
        localStorage.script_version_create_date=DateFunc.get_date_format(new Date(),"Y-m-d h");
        window.location.reload();
      }
      //시간차이
      if(response.data.now_time){
        let now_d:any=new Date();
        let server_time_gap=Math.round(
          (parseInt(response.data.now_time)-now_d.getTime())/1000
        );
        localStorage.server_time_gap=server_time_gap;
      }
    }
  });
};

export default checkScriptVersion;
