import strFunc from '@/lib/lyg/string';

function DeskUseListArea(props:any){
   let myProps={
    select_date: "",
    desk_use_arr:[],
    ...props
  };
  let desk_use_arr:any=[];
  for(let i=0;i<myProps.desk_use_arr.length;i++){
    let desk_use=myProps.desk_use_arr[i];
    if(desk_use["a_date"]==myProps.select_date){
      desk_use_arr.push(desk_use);
    }
  }

  const get_desk_use_list_tags=()=>{
    let desk_use_list_tags:any=desk_use_arr.map((desk_use:any,idx:number)=>{
      let start_hi=strFunc.HourMinuteToTime(desk_use["a_start_h"],desk_use["a_start_m"]);
      let end_hi=strFunc.HourMinuteToTime(desk_use["a_end_h"],desk_use["a_end_m"]);
      let desk_name="";
      if(desk_use["a_name"]){
        desk_name=desk_use["a_name"];
      }
      let room_name="";
      if(desk_use["edu_room_info"]){
        room_name=desk_use["edu_room_info"]["a_name"];
      }
      let use_sec=parseInt(strFunc.uncomma(desk_use["a_target_time_sec"]));
      let use_min=Math.floor(use_sec/60);
      let desk_use_str=room_name+" "+desk_name+" ("+start_hi+"~"+end_hi+") "+use_min+"분";
      return (
        <div key={idx}>
          {desk_use_str}
        </div>
      );
    });
    return desk_use_list_tags;
  };

  let wrap_style:any={
    width:"98%",
    margin:"0 auto",
    padding:"8px",
    boxShadow:"2px 2px 5px 5px #efefef",
    marginBottom:"20px",
  };
  
  return (
  <div>
    {desk_use_arr.length>0&&
    <div className="mt-2" style={wrap_style}>
      <div>좌석</div>
      {get_desk_use_list_tags()}
    </div>
    }
  </div>
  );
};
export default DeskUseListArea;