import MobileLayout from "@/pcomponents/mobile/layout/layout";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeUser, setUser } from '@/store/user/user';
import strFunc from "@/lib/lyg/string";
import { Link, useNavigate } from 'react-router-dom';
import MobileScript from "@/lib/mobile/web_script";
import Style from "./css/style.module.css";

function LeaveUserPage() {
  let user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [row_data,set_row_data]=useState({
    is_agree:false,
    user_pw:"",
  });

  useEffect(() => {
    
  }, [user]);
  
  const leave_user_by_ajax=()=>{
    if(!row_data.is_agree){
      alert("안내사항 동의가 필요합니다.");
      return false;
    }
    if (strFunc.is_empty(user.user_seq)) {
      alert("로그인 필요.");
      return false;
    }
    if (strFunc.is_empty(row_data["user_pw"])) {
      alert("암호 입력 필요.");
      return false;
    }
    if(!confirm("회원 탈퇴를 하시겠습니까?")){
      return false;
    }
    let form_json_data={
      "user_seq":user.user_seq,
      "is_need_pw":"1",
      "user_pw":row_data.user_pw,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/basic/user/delete_one', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          dispatch(removeUser());
          localStorage.removeItem("intoyou_token");
          localStorage.removeItem('token_update_date_last');
          localStorage.removeItem('token_update_date_last2');
          localStorage.removeItem('version_check_date_last');
          MobileScript.set_login_token("");
          navigate("/mobile/login");
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <MobileLayout>
      <div className={Style.leave_info_wrap}>
        <h5 className={Style.leave_info_title} >1. 내정보 및 서비스 이용기록 삭제</h5>
        <div>
          <p>-유저의 정보 및 어플이용 내역이 삭제됩니다.</p>
          <p>-유저 이름, 핸드폰번호 정보가 삭제 됩니다.</p>
          <p>-푸시 이용내역, 맞춤플랜 사용이력, 채팅기록, 로그인이력이 삭제됩니다.</p>
        </div>
        <h5 className={Style.leave_info_title}>2. 소속학원의 정보 삭제 불가</h5>
        <div>
          <p>-소속 학원에 등록된 유저 정보(이름,핸드폰번호,아이디)는 남아있습니다.</p>
          <p>-소속 학원에 등록된 맞춤플랜 이용내역, 동영상강의 이용내역, 로그인이력, 납부내역, 
            출결이력, 상담이력 등은 남아있습니다.</p>
        </div>
        <h5 className={Style.leave_info_title}>3. 아이디 복구</h5>
        <div>
          <p>-아이디 복구는 아직 지원되지 않습니다.</p>
          <p>-추후에 제공 예정.</p>
        </div>
        <div className={Style.leave_info_agree_wrap}>
          <label>
            <input type="checkbox" checked={row_data.is_agree} onChange={(e:any)=>{
              set_row_data({
                ...row_data,
                "is_agree":e.target.checked
              });
            }} /> 안내사항을 모두 이해하였으며, 이에 동의합니다.
          </label>
        </div>
      </div>
      <div className="view-table-div" style={{marginTop:10}}>
        <table>
          <colgroup>
            <col width={"20%"}></col>
            <col width={"80%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>비밀번호</th>
              <td>
                <input type="password" value={row_data.user_pw} placeholder="비밀번호" onChange={(e:any)=>{
                  set_row_data({
                    ...row_data,
                    "user_pw":e.target.value
                  });
                }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-box-center mt-2">
        <button className="btn btn-red" onClick={() => { leave_user_by_ajax(); }}>회원탈퇴</button>
        <button className="btn btn-dark" onClick={() => { navigate("/mobile/setting/mypage"); }}>뒤로</button>
      </div>
    </MobileLayout>
  );
}

export default LeaveUserPage;