import { useNavigate } from 'react-router-dom';
import SvgIcon from "./svg/icon";

const FooterManage=(props:any)=>{
  let myProps={
    is_home_body:false,
    ...props
  };
  const navigate = useNavigate();
  let foot_menu_arr=[
    {key:"home","text":"홈","url":"/mobile"},
    {key:"plan","text":"맞춤플랜","url":"/mobile/manage/plan/list"},
    {key:"chat","text":"플랜톡","url":"/mobile/chat/room_list"},
    //{key:"eval","text":"수업평가서","url":"/mobile/manage/eval/list"},
    // {key:"study_prescribe","text":"학습처방","url":"/mobile/manage/study_prescribe/card_list"},
    {key:"month_prescribe","text":"학습처방","url":"/mobile/manage/month_prescribe/card_list"},
    // {key:"study_diary","text":"학습일지","url":"/mobile/manage/study_diary/card_list"},
    {key:"counsel_todo","text":"일일&상담","url":"/mobile/manage/counsel_todo/counsel_daily"},
  ];

  const goPage=(url:string)=>{
    navigate(url);
  };

  let foot_menu_key="";
  for(let i=0;i<foot_menu_arr.length;i++){
    if(foot_menu_arr[i].url==window.location.pathname){
      foot_menu_key=foot_menu_arr[i].key;
    }
  }
  const foot_menu_tags=foot_menu_arr.map((item:any,idx:number)=>{
    let active_class_name="";
    let is_active=false;
    if(item.key==foot_menu_key){
      active_class_name=" active";
      is_active=true
    }
    return (
      <a className={"m_footer_manage_item"+active_class_name} key={idx} 
        onClick={()=>{goPage(item.url);}}>
        <SvgIcon
          icon_key={item.key}
          is_active={is_active}
        ></SvgIcon>
        <br />
        {item.text}
      </a>
    );
  });
  return (
    <div id="m_footer">
      <div className="m_footer_con noto_sans">
        {foot_menu_tags}
      </div>
    </div>
  );
};

export default FooterManage;