import Style from "./css/style.module.css";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputWrite } from '@/pcomponents/common/date_picker/custom_input';
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import TimeInputComponent from "@/pcomponents/common/crud/write/time_input";

function WriteArea(props: any) {
  let myProps = {
    row_data: {},
    set_row_data: (inData: any) => { },
    select_arr_a_kind: [],
    select_arr_eval_score: [],
    ...props
  };
  let row_data = myProps.row_data;

  const handleChangeInput = (e: any) => {
    let value = e.target.value;
    let name = e.target.name;

    let change_row_data = {
      ...myProps.row_data,
      ...{ [name]: value }
    };
    if (name == "a_is_success") {
      if (value == "1") {
        change_row_data["a_state"] = "complete";
      } else {
        change_row_data["a_state"] = "ready";
      }
    }

    myProps.set_row_data(change_row_data);
  };

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_row_data = {
      ...myProps.row_data,
      ...{ [key]: date_str }
    };
    myProps.set_row_data(change_row_data);
  };

  let state_color = "#eab358";
  if (row_data.a_is_success == "1") {
    state_color = "#A2B854";
  }

  return (
    <div className="noto_sans">
      <div className={Style.content_wrap}>
        <div className={Style.content_con}>
          <div className={Style.content_title}>구분</div>
          <SelectBoxBar
            valueTextArr={myProps.select_arr_a_kind}
            value={row_data.a_kind}
            name={"a_kind"}
            on_change={(inData: any) => {
              handleChangeInput({
                target: {
                  name: inData.name,
                  value: inData.value,
                }
              });
            }}
          ></SelectBoxBar>
          <div className={Style.content_title}>상담내용</div>
          <TextAreaComponent
            class_name={"row-input"}
            name={"a_content"}
            value={row_data.a_content}
            onChange={(e: any) => {
              handleChangeInput(e);
            }}
            placeholder={"내용을 입력하세요."}
          ></TextAreaComponent>
          <div className={Style.content_title}>피드백</div>
          <TextAreaComponent
            class_name={"row-input"}
            name={"a_feedback"}
            value={row_data.a_feedback}
            onChange={(e: any) => {
              handleChangeInput(e);
            }}
            placeholder={"피드백."}
          ></TextAreaComponent>
          <div className={Style.content_title}>평가</div>
          <SelectBoxBar
            valueTextArr={myProps.select_arr_eval_score}
            value={row_data.a_eval_score}
            name={"a_eval_score"}
            on_change={(inData: any) => {
              handleChangeInput({
                target: {
                  name: inData.name,
                  value: inData.value,
                }
              });
            }}
          ></SelectBoxBar>
          <div className={Style.content_title}>참고내용</div>
          <TextAreaComponent
            class_name={"row-input"}
            name={"a_reference"}
            value={row_data.a_reference}
            onChange={(e: any) => {
              handleChangeInput(e);
            }}
            placeholder={"참고내용."}
          ></TextAreaComponent>
          <div className="write_table_small">
            <table>
              <colgroup>
                <col width="20%" />
                <col width="30%" />
                <col width="20%" />
                <col width="30%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>상담일</th>
                  <td>
                    <div style={{ display: "inline-block", marginLeft: 5, marginRight: 5 }}>
                      <DatePicker
                        selected={row_data.a_counsel_date != "" ? new Date(row_data.a_counsel_date) : null}
                        onChange={(date: Date) => {
                          onChangeDatePicker("a_counsel_date", date);
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputWrite />}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </div>
                  </td>
                  <th>예정일</th>
                  <td>
                    <div style={{ display: "inline-block", marginLeft: 5 }}>
                      <DatePicker
                        selected={row_data.a_reserve_date != "" ? new Date(row_data.a_reserve_date) : null}
                        onChange={(date: Date) => {
                          onChangeDatePicker("a_reserve_date", date);
                        }}
                        locale={ko}
                        dateFormat="yyyy-MM-dd"
                        customInput={<CustomInputWrite />}
                        renderCustomHeader={customHeaderFunc}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>상태</th>
                  <td>
                    <select name="a_is_success" value={row_data.a_is_success} onChange={handleChangeInput}
                      style={{ border: "1px solid #ccc" }}>
                      <option value="1">완료</option>
                      <option value="">미완료</option>
                    </select>
                  </td>
                  <th>예정시간</th>
                  <td>
                    <TimeInputComponent
                      name={"a_reserve_time"}
                      value={row_data.a_reserve_time}
                      on_change={(inData: any) => {
                        handleChangeInput({
                          target: {
                            value: inData.value,
                            name: "a_reserve_time",
                          }
                        });
                      }}
                    ></TimeInputComponent>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  );
};
export default WriteArea;