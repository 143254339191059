import TodoStateFunc from "@/pcomponents/common/content/todo/state/state_func";
import DateFunc from '@/lib/lyg/date_func';
import strFunc from "@/lib/lyg/string";
import down_arrow_ico from "@/img/ico/arrow/down_arrow.png";

function CardBox(props:any){
  let myProps={
    Style:{},
    info:[],
    on_click_card_box:(inData:any)=>{},
    user:{},
    ...props
  };
  let Style=myProps.Style;
  let info=myProps.info;
  let user=myProps.user;

  let state_str=TodoStateFunc.get_state_str_by_todo_info(info);
  let percent=TodoStateFunc.get_percent_by_todo_info(info);
  let bar_percent=percent;
  if(bar_percent>100){
    bar_percent=100;
  }
  let subject_name=info["a_subject"];
  let box_title=strFunc.cut_str(info["a_title"],12,"..");
  if(info["a_par_id"]=="video_list"){
    subject_name+="(동영상)";
  }
  let reserve_date_str=DateFunc.get_date_format(new Date(info["a_reserve_start_date"]),"m-d");
  let elapse_time_sec=(info.elapse_time_sec==undefined)?info.a_time_sec:info.elapse_time_sec;
  let elapse_time_json:any=strFunc.secondsToTimeJson(elapse_time_sec);
  if(elapse_time_json.h!="00"){
    reserve_date_str+=" "+elapse_time_json.h+":"+elapse_time_json.i+":"+elapse_time_json.s;
  }else{
    reserve_date_str+=" "+elapse_time_json.i+":"+elapse_time_json.s;
  }

  let card_box_style:any={};
  let check_ico_style:any={};
  if(state_str=="완료"){
    card_box_style["background"]="#e5efe9";
    check_ico_style["background"]="#abc118";
  }

  let indivisual_plan=false;
  if(info.a_stu_mcomp_seq==user.comp_seq&&info.a_stu_mcomp_user_seq==user.user_seq){
    indivisual_plan=true;
  }

  return (
    <div className={Style.card_box} style={card_box_style} onClick={()=>{
        myProps.on_click_card_box({
          "todo_info":info
        });
      }}>
      <div className={Style.card_box_title}>
        {box_title}
        {indivisual_plan&&
          <span style={{color:"#0051d1",fontWeight:"600"}} >(개인) </span>
        }
        {subject_name!=""&&
          <span style={{color:"#aaa"}}> [{subject_name}] </span>
        }
      </div>
      <div className={Style.card_box_date_wrap}>
        {reserve_date_str}
      </div>
      <div className={Style.card_box_sub_text}>
        {strFunc.cut_str(info.a_content,10,"..")}
        <span className={Style.card_box_success_check} style={check_ico_style}>
          <img src={down_arrow_ico} className={Style.card_box_success_check_ico} />
        </span>
      </div>
      <div className={Style.card_box_state_bar_wrap}>
        <div className={Style.card_box_state_bar_back}></div>
        <div className={Style.card_box_state_bar} style={{width:bar_percent+"%"}}></div>
      </div>
      <div className={Style.card_box_state_str_wrap}>
        <span className={Style.card_box_state_str}>
          {(percent>0&&percent!=100)&&
            <span className={Style.card_box_state_per_num}>{percent}%</span>
          }
          {state_str}
        </span>
      </div>
    </div>
  );
}
export default CardBox;