import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import { useSelector } from 'react-redux';
import ChildDataFunc from "@/pcomponents/common/content/parent/func/child_data";
import { useLocation, useNavigate } from "react-router";
import StuPayInitArea from "@/pcomponents/common/content/supply/pay_init";

function SupplyParPayInitPage() {
  let user = useSelector((state: any) => state.user);
  let group_child_data = ChildDataFunc.get_child_arr_by_login_user(user);
  let comp_stu_row_arr = group_child_data.comp_stu_row_arr;
  let select_stu_row_idx = group_child_data.select_stu_row_idx;
  let select_comp_stu_row = group_child_data.select_comp_stu_row;

  let user_data = {
    iam_mcomp_seq: select_comp_stu_row ? select_comp_stu_row.mcomp_seq : "",
    iam_mcomp_user_seq: select_comp_stu_row ? select_comp_stu_row.par_mcomp_user_seq : "",
    comp_api_key: select_comp_stu_row ? select_comp_stu_row.comp_api_key : "",
  };
  const { state } = useLocation();
  let my_state_data = {
    supply_money: 0,
    supply_seq_arr: [],
    supply_name: "학원비",
    ...state
  };

  return (
    <MobileLayout has_header={false} has_footer={false} container_con_style={{ width: "100%" }}>
      <HeaderSimple title={"납부하기"} back_url={"/mobile/parent/stu/supply_one_stu"}></HeaderSimple>
      {user_data.iam_mcomp_user_seq &&
        <div className="mt-2">
          <StuPayInitArea
            stu_seq={select_comp_stu_row.user_seq}
            user_data={user_data}
            supply_money={my_state_data.supply_money}
            supply_seq_arr={my_state_data.supply_seq_arr}
            supply_name={my_state_data.supply_name}
          ></StuPayInitArea>
        </div>
      }
    </MobileLayout>
  );
};
export default SupplyParPayInitPage;