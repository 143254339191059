import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CharBox from "./char_box";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import ViewCharPopup from "./popup/view_char";

function CharListArea(props: any) {
  let myProps = {
    char_opt:{},
    refresh_data: (inData: any) => { },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const [char_user_info_arr, set_char_user_info_arr] = useState([]);
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "view_char",
    title: "씨앗보기",
    char_user_info: {},
    y: "0",
    width: "100%",
    height: "100%",
    is_border_radius: false,
  });

  useEffect(() => {
    get_char_user_info_arr();
  }, [user]);

  const get_char_user_info_arr = () => {
    if (strFunc.is_empty(user.user_seq)) {
      return false;
    }
    let form_json_data = {
      "s_user_seq": user.user_seq,
      "order_id": "a_seq",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_user/list', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_char_user_info_arr(response.data["data"]["info_arr"]);
          }
        } else {

        }
      });
  };

  const open_view_char_popup = (inData: any) => {
    let opt_obj = {
      char_user_info: {},
      is_select_char:false,
      ...inData,
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      sort: "view_char",
      char_user_info: opt_obj["char_user_info"],
      is_select_char:opt_obj["is_select_char"],
    });
  };

  const get_char_box_tags = () => {
    let char_box_tags = char_user_info_arr.map((item: any, idx: number) => {
      let is_select_char=false;
      if(myProps.char_opt["a_select_char_seq"]==item["a_seq"]){
        is_select_char=true;
      }
      return (
        <CharBox
          key={idx}
          char_user_info={item}
          is_select_char={is_select_char}
          open_view_char_popup={open_view_char_popup}
        ></CharBox>
      );
    });
    return char_box_tags;
  };

  let char_list_wrap_style: any = {
    display: "flex",
    gap: 8,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  };

  return (
    <div className="mt-2">
      <div style={{ color: "#7A9E1D" }}>씨앗</div>
      <div style={char_list_wrap_style}  >
        {get_char_box_tags()}
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius}
          has_header={false} content_div_style={{ padding: 0 }}>
          {popupData.sort === "view_char" &&
            <ViewCharPopup
              char_user_info={popupData.char_user_info}
              is_select_char={popupData.is_select_char}
              callback={()=>{
                myProps.refresh_data();
                get_char_user_info_arr();
              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></ViewCharPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default CharListArea;