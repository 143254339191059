//import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import strFunc from "@/lib/lyg/string";

class UserFunc {
  /*
  let user_select_data=UserFunc.get_iam_mcomp_data({user:user});
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  */
  static get_iam_mcomp_data = (inData: any) => {
    let opt_obj = {
      user: {},
      select_mcomp_seq: "",
      default_mcomp_api_key: "",
      default_mcomp_user_seq: "",
      ...inData
    };
    let user = opt_obj.user;
    let select_mcomp_seq = opt_obj.select_mcomp_seq;

    let iam_mcomp_user_seq = opt_obj.default_mcomp_user_seq;
    let iam_mcomp_user_name = user.user_name;
    let iam_mcomp_seq = select_mcomp_seq;
    let iam_mcomp_user_grade = "";
    let comp_api_key = opt_obj.default_mcomp_api_key;
    
    if (!strFunc.is_empty(select_mcomp_seq)) {
      let is_exist=false;
      for (let i = 0; i < user.comp_link_arr.length; i++) {
        let tmp_comp_link = user.comp_link_arr[i];
        if (tmp_comp_link["a_mcomp_seq"] == select_mcomp_seq) {
          iam_mcomp_seq = tmp_comp_link["a_mcomp_seq"];
          iam_mcomp_user_seq = tmp_comp_link["a_mcomp_user_seq"];
          iam_mcomp_user_name = tmp_comp_link["a_mcomp_user_name"];
          iam_mcomp_user_grade = tmp_comp_link["a_user_grade"];
          comp_api_key = tmp_comp_link["comp_api_key"];
          is_exist=true;
        }
      }
      if(is_exist==false&&user.group_comp_link_arr){
        for(let i=0;i<user.group_comp_link_arr.length;i++){
          let tmp_comp_link = user.group_comp_link_arr[i];
          if (tmp_comp_link["a_mcomp_seq"] == select_mcomp_seq) {
            iam_mcomp_seq = tmp_comp_link["a_mcomp_seq"];
            iam_mcomp_user_seq = tmp_comp_link["a_mcomp_user_seq"];
            iam_mcomp_user_name = tmp_comp_link["a_mcomp_user_name"];
            iam_mcomp_user_grade = tmp_comp_link["a_user_grade"];
            comp_api_key = tmp_comp_link["comp_api_key"];
          }
        }
      }
    }else{
      if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
        iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
        iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
        iam_mcomp_user_name = user.select_mcomp_link["a_mcomp_user_name"];
        iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
        comp_api_key = user.select_mcomp_link["comp_api_key"];
      }
    }

    let result_data={
      iam_mcomp_user_seq: iam_mcomp_user_seq,
      iam_mcomp_user_name: iam_mcomp_user_name,
      iam_mcomp_seq: iam_mcomp_seq,
      iam_mcomp_user_grade: iam_mcomp_user_grade,
      comp_api_key: comp_api_key
    };

    return result_data;
  };
  static get_select_comp_user(user: any) {
    let select_mcomp_link_val = "";
    let select_mcomp_seq = "";
    let select_mcomp_user_seq = "";
    if (!strFunc.is_empty(user.select_mcomp_link)) {
      select_mcomp_link_val = user.select_mcomp_link.a_mcomp_seq + "," + user.select_mcomp_link.a_mcomp_user_seq;
      select_mcomp_seq = user.select_mcomp_link.a_mcomp_seq;
      select_mcomp_user_seq = user.select_mcomp_link.a_mcomp_user_seq;
    }

    return {
      select_mcomp_link_val: select_mcomp_link_val,
      select_mcomp_seq: select_mcomp_seq,
      select_mcomp_user_seq: select_mcomp_user_seq
    };
  }
}
export default UserFunc;