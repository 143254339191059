import strFunc from "@/lib/lyg/string";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import LygMath from '@/lib/lyg/math';

function BuyExpArea(props: any) {
  let myProps = {
    char_opt: {
      a_gold: "0",
      a_select_char_seq: "0",
    },
    refresh_data: (inData: any) => { },
    ...props
  };
  let char_opt = myProps.char_opt;
  let is_exist_select = false;
  //a_main_user_seq
  if (!strFunc.is_empty(char_opt["a_select_char_seq"])) {
    if (!strFunc.is_empty(char_opt["char_user_info"])) {
      is_exist_select = true;
    }
  }
  let user = useSelector((state: any) => state.user);
  useEffect(() => {
    
  }, [user]);

  const go_add_exp_by_ajax = (inData: any) => {
    let opt_obj = {
      gold: 0,
      ...inData
    };
    if (is_exist_select == false) {
      alert("선택 씨앗이 없습니다.");
      return false;
    }
    if (strFunc.is_empty(opt_obj["gold"])) {
      alert("양분 선택 필요.");
      return false;
    }
    let my_gold = parseInt(strFunc.uncomma(char_opt["a_gold"]));
    let use_gold = parseInt(strFunc.uncomma(opt_obj["gold"]));
    if (use_gold > my_gold) {
      alert("양분이 부족합니다.");
      return false;
    }

    // if (!confirm("경험치를 구매하시겠습니까?")) {
    //   return false;
    // }

    let form_json_data = {
      "row_data": {
        a_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
        a_main_user_seq: char_opt["a_main_user_seq"],
        a_user_name: user.user_name,
        a_in_out: "out",//in,out
        a_msg: "경험치구매",

        a_par_mcomp_seq: char_opt["a_main_user_seq"],
        a_par_id: "buy_exp",
        a_par_seq: "",
        a_amount: use_gold,
      },
      "is_able_duplicate": "1",
      "is_add_history": "",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/add_gold_one', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let min_exp = use_gold;
          let max_exp = use_gold;
          if (use_gold == 20) {
            min_exp = 10;
            max_exp = 30;
          }
          let add_exp_num = LygMath.random(min_exp, max_exp);
          go_add_exp_by_ajax2({
            add_exp_num: add_exp_num
          });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_add_exp_by_ajax2 = (inData: any) => {
    let opt_obj = {
      add_exp_num: 0,
      ...inData,
    };
    if (strFunc.is_empty(opt_obj["add_exp_num"])) {
      alert("추가경험치가 0입니다.");
      return false;
    }
    let form_json_data = {
      "char_seq": char_opt["a_select_char_seq"],
      "add_exp_num": opt_obj["add_exp_num"],
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_user/add_exp', form_json_data,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //alert("경험치가 " + opt_obj["add_exp_num"] + " 올랐습니다.");
          myProps.refresh_data();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div className="text-center">
      <div className="mt-2">
        경험치 구매
      </div>
      {is_exist_select == false &&
        <div style={{ color: "orange" }}>
          선택된 씨앗이 없습니다.
        </div>
      }
      {is_exist_select &&
        <div style={{ display: is_exist_select ? "" : "none" }}>
          <div>
            {char_opt["char_user_info"]["a_name"]}
          </div>
          <div>
            레벨 {char_opt["char_user_info"]["a_level"]}
          </div>
          <div>
            {strFunc.comma(char_opt["char_user_info"]["a_experience_level"])}/
            {strFunc.comma(char_opt["char_user_info"]["a_experience_level_max"])}
          </div>
          <div className="mt-2">
            <button className="btn btn-dark"
              onClick={() => {
                go_add_exp_by_ajax({ "gold": 20 });
              }}
              style={{ background: "green" }}
            >경험치 구매 (20G)</button>
          </div>
        </div>
      }
    </div>
  );
};
export default BuyExpArea;