import MobileLayout from "@/pcomponents/mobile/layout/layout";

function ChatMain(){
  return (
    <MobileLayout>
      플랜톡
    </MobileLayout>
  );
}

export default ChatMain;