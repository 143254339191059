import strFunc from "@/lib/lyg/string";

function ParentListArea(props:any){
   let myProps={
    row_data:{},
    ...props
  };
  let row_data=myProps.row_data;
  let par_link_arr:any=[];
  if(row_data.link_user_arr&&row_data.link_user_arr.length>0){
    for(let i=0;i<row_data.link_user_arr.length;i++){
      let link_info=row_data.link_user_arr[i];
      if(link_info["a_type"]=="parent"){
        par_link_arr.push(link_info);
      }
    }
  }

  const get_par_list_tags=()=>{
    let par_list_tags=par_link_arr.map((item:any,idx:number)=>{
      let par_phone_str="";
      let par_info=null;
      let par_id="";
      if(item.par_info){
        par_info=item.par_info;
      }
      if(par_info!=null){
        if(!strFunc.is_empty(par_info["a_user_phone"])){
          par_phone_str=par_info["a_user_phone"];
        }
        par_id=par_info["a_user_id"];
      }
      return (
        <div key={idx}>
          {item.a_user_name} 
          {!strFunc.is_empty(par_phone_str)&&
            <span className="ml-1" style={{color:"#7f7f7f"}}>{par_phone_str}</span>
          }
          <span className="ml-1">( {par_id} )</span>
        </div>
      );
    });
    return par_list_tags;
  };
  
  return (
  <div>
    {get_par_list_tags()}
  </div>
  );
};
export default ParentListArea;