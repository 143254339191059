//import SearchBtn from "@/pcomponents/common/content/list/search/search_btn";
function SearchBtn(props:any){
   let myProps={
    class_name:"",
    btn_style:{},
    svg_data:{},
    data:{},
    on_click:(inData:any)=>{

    },
    ...props
  };

  let btn_style={
    "display":"inline-block",
    ...myProps.btn_style
  };
  let svg_data={
    width:19,
    height:19,
    box_x:0,
    box_y:0,
    box_w:19,
    box_h:19,
    ...myProps.svg_data
  };
  let viewBox_str=svg_data.box_x+" "+svg_data.box_y+" "+svg_data.box_w+" "+svg_data.box_h;
  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      className={myProps.class_name}
      style={btn_style}
      onClick={()=>{myProps.on_click(myProps.data);}}
      width={svg_data.width} height={svg_data.height} viewBox={viewBox_str} fill="none">
      <circle cx="9.02584" cy="9.01752" r="6.1816" transform="rotate(13.9374 9.02584 9.01752)" stroke="black"/>
      <line x1="18.3659" y1="17.7286" x2="13.3659" y2="12.7286" stroke="black"/>
    </svg>
  );
};
export default SearchBtn;