import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import strFunc from "@/lib/lyg/string";

function SaveResultArea(props: any) {
  let myProps = {
    game_data: {},
    gold_score: 0,
    char_user_info: {},
    is_show_rank:false,
    get_char_opt_by_ajax: () => { },
    ...props
  };
  let game_data = myProps.game_data;
  let char_user_info = myProps.char_user_info;

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const [rank_num, set_rank_num] = useState(0);

  useEffect(() => {
    if (user.user_seq) {
      save_result_by_ajax();
    }
  }, [user.user_seq]);

  const save_result_by_ajax=()=>{
    if(strFunc.is_empty(user.user_seq)){
      return false;
    }
    if(strFunc.is_empty(game_data.sort)){
      return false;
    }

    let form_json_data:any = {
      "game_data": game_data,
      "main_user_seq":user.user_seq,
      "main_user_name":user.user_name,
      "char_seq":"",
      "mcomp_seq":iam_mcomp_seq,
      "mcomp_user_seq":iam_mcomp_user_seq,
    };
    if(char_user_info!=null){
      form_json_data["char_seq"]=char_user_info["a_seq"];
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_score/add_score', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //alert("저장되었습니다.");
          if(myProps.is_show_rank){
            get_rank_by_ajax();
          }
          if(myProps.gold_score>0){
            save_gold_score_by_ajax();
          }else{
            myProps.get_char_opt_by_ajax();
          }
        } else {
          //alert(response.data["msg"]);
        }
      });
  };

  const save_gold_score_by_ajax=()=>{
    let form_json_data = {
      "row_data": {
        a_main_user_seq: user.user_seq,
        a_user_name: user.user_name,
        a_in_out: "in",
        a_msg: "수학",
        a_par_mcomp_seq: user.comp_seq,
        a_par_id: game_data.sort,
        a_par_seq: "",
        a_amount: myProps.gold_score,
      },
      "is_able_duplicate": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/add_gold_one', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          myProps.get_char_opt_by_ajax();
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const get_rank_by_ajax=()=>{
    let form_json_data={
      s_rank_sort:game_data.sort,
      s_rank_score:game_data.score,
      "is_need_count":"1",
      "is_need_info_arr":"",
      "is_no_limit":"1",
    };
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/mobile/char/char_opt/list', form_json_data, get_axios_data())
    .then((response) => {
      if (response.data["result"] === "true") {
        let my_rank_num=parseInt(response.data["data"]["count_info"]["tot"]);
        my_rank_num=my_rank_num+1;
        set_rank_num(my_rank_num);
      } else {
        //alert(response.data["msg"]);
      }
    });
  };

  return (
    <>
    {(myProps.is_show_rank&&rank_num!=0)&&
      <span>{rank_num} 등</span>
    }
    </>
  );
};
export default SaveResultArea;