function TopInfoArea(props: any) {
  let myProps = {
    info: {},
    Style: {},
    tab_id: "",
    set_tab_id: (inData: any) => { },
    go_delete_study_map: () => { },
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;
  let tab_id = myProps.tab_id;

  const go_back_page = () => {
    myProps.set_tab_id("section");
  };

  return (
    <div className={Style.top_info_wrap}>
      <span className={Style.top_info_title}>
        {info["study_title"]}
        <span className="ml-1">
          ({info["a_success_cnt"]}/{info["a_total_cnt"]})
        </span>
      </span>
      {tab_id != "section" &&
        <button className="btn btn-dark"
          onClick={() => {
            go_back_page();
          }}
          style={{ position: "absolute", left: 10, top: 0 }}
        >뒤로</button>
      }
      {/* {tab_id == "section" &&
        <button className="btn btn-red"
          onClick={() => {
            myProps.go_delete_study_map();
          }}
          style={{ position: "absolute", right: 10, top: 0 }}
        >제거</button>
      } */}
    </div>
  );
};
export default TopInfoArea;