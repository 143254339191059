import notice_ico_green_100 from "@/img/mobile/home/notice_ico_green_100.png";
import homework_black from "@/img/mobile/home/homework_black.png";
import DateFunc from '@/lib/lyg/date_func';

const NoticeListArea = (props: any) => {
  let myProps: any = {
    infoArr: [],
    onClickView: (inData: any) => {
      // {
      // row_data:{},
      // row_num:0,
      // }
    },
    ...props
  };
  let now_ymd = DateFunc.get_date_format(new Date(), "Y-m-d");
  let pre_7day_ymd=DateFunc.get_change_date(new Date(),'day',-7).getTime();

  let list_row_tags = myProps.infoArr.map((info: any, info_idx: number) => {
    let new_tag:any=(
      <div>
        <span className="msg_new" style={{opacity:0}}>N</span>
      </div>
    );
    if(info.a_create_date){
      let create_date_ymd=new Date(info.a_create_date).getTime();
      if(pre_7day_ymd<=create_date_ymd){
        new_tag=(
          <div>
            <span className="msg_new">N</span>
          </div>
        );
      }
    }

    let notice_icon_img_src=homework_black;
    if(info.a_is_top=="1"){
      notice_icon_img_src=notice_ico_green_100;
    }

    let title_str=info.a_title;
    if(info.a_sort){
      title_str="["+info.a_sort+"] "+title_str;
    }

    return (
      <div className="list_board_row" key={info_idx} >
        <div className="list_board_icon_box" onClick={()=>{
            myProps.onClickView({row_data:info,row_num:info_idx});
          }}>
          <img src={notice_icon_img_src} style={{ height: 25 }} />
        </div>
        <div className="list_board_title_wrap" onClick={()=>{
            myProps.onClickView({row_data:info,row_num:info_idx});
          }}>
          <div className="list_board_title_div" style={{color:info.a_color}}>
            {title_str}
          </div>
          <div className="list_board_sub_title_div">
            작성자:{info.a_writer}
          </div>
        </div>
        <div className="list_board_opt_wrap">
          <div className="list_board_opt_top">{new_tag}</div>
          <div className="list_board_opt_bottom">
            {
              now_ymd == info.a_create_date.substring(0, 10) ?
                <span>{DateFunc.getTimeFormatOfPretty(info.a_create_date)}</span>
                :
                <span>{info.a_create_date.substring(0, 10)}</span>
            }
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="list_board_div">
      {list_row_tags}
    </div>
  );
};
export default NoticeListArea;