function StuListArea(props: any) {
  let myProps = {
    main_stu_info_arr: [],
    set_main_stu_info_arr: (inData: any) => { },
    Style: {},
    ...props
  };
  let Style = myProps.Style;

  const remove_stu_item=(inData:any)=>{
    let opt_obj={
      u_seq:"",
      ...inData
    };
    
    let new_main_stu_info_arr:any=[];
    for(let i=0;i<myProps.main_stu_info_arr.length;i++){
      let main_stu_info=myProps.main_stu_info_arr[i];
      if(main_stu_info.a_seq!=opt_obj.u_seq){
        new_main_stu_info_arr.push(main_stu_info);
      }
    }
    myProps.set_main_stu_info_arr(new_main_stu_info_arr);
  };

  const get_stu_info_tags = () => {
    let stu_info_tags = myProps.main_stu_info_arr.map((main_stu_info: any, idx: number) => {
      return (
        <span key={idx} className={Style.stu_box}>
          <span>
            {main_stu_info["a_user_name"]}
          </span>
          <button className="btn-s btn-red ml-1"
            onClick={()=>{
              remove_stu_item({u_seq:main_stu_info.a_seq});
            }}
            style={{height:"25px",lineHeight:"25px"}}>X</button>
        </span>
      );
    });
    return stu_info_tags;
  };

  return (
    <div className={Style.stu_list_wrap}>
      {get_stu_info_tags()}
    </div>
  );
};
export default StuListArea;