const stepData=[
  {
    type:"input",
    title:"이름이 뭔가요?",
    sub_title:"선생님과 친구들에게 소개할 이름을 적어주세요.",
    input_placeholder:"이름을 입력하세요.",
    btn_title:"계속 >",
    is_pw_input:false,
    input_name:"a_user_name",
  },
  {
    type:"input",
    title:"아이디를 적어주세요.",
    sub_title:"로그인 할 아이디를 적어주세요.",
    input_placeholder:"아이디를 입력하세요.",
    btn_title:"계속 >",
    is_pw_input:false,
    input_name:"a_user_id",
  },
  {
    type:"input",
    title:"비밀번호를 잊지 마세요!",
    sub_title:"계정에 로그인할 비밀번호를 적어주세요.",
    input_placeholder:"비밀번호를 입력하세요.",
    btn_title:"계속 >",
    is_pw_input:true,
    input_name:"a_user_pw",
  },
];
export default stepData;